import Rest from './../../../plugins/Rest'
import i18n from './../../../plugins/i18next'
import clone from 'lodash.clone'

const state = {
    modal: {
        fields: {
            name: '',
            city: '',
            postCode: '',
            address: '',
            phoneNumber: '',
            email: '',
            isDisabled: '',
        },
        errors: {
            stepOne: {
                validation: true,
                name: '',
                city: '',
                postCode: '',
                address: '',
            },
        },
        steps: '',
    },
    request: {
        populate: [],
        searchFields: 'name address city phoneNumber email',
        select: '',
        where: { isDeleted: false },
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: 'name',
    },
}

const getters = {
    getBrancheModal: state => state.modal.fields,
    getBranchError: state => state.modal.errors,
    getBranchesTable: state => state.table,
}

const actions = {
    async fetchBranchesTable({ commit, dispatch }, payload) {
        try {
            let response = await new Rest(
                'GET',
                `contacts/${payload.id}/branches`
            )
                .select(state.request.select)
                .limit(state.table.limit)
                .page(state.table.page)
                .search(state.request.searchFields, state.table.search)
                .where(state.request.where)
                .populate(state.request.populate)
                .sort(state.table.sort || state.table.defaultSort)
                .send()
            commit('setBranchesTable', response.data)
            console.log(response.data)
            if (payload.next) payload.next()
        } catch (error) {
            console.error(error)
        }
    },
    async createBranch({ state, commit, dispatch }, payload) {
        try {
            await new Rest('POST', `contacts/${payload.id}/branches`)
                .setBody(state.modal.fields)
                .send()
            await dispatch('fetchBranchesTable', { id: payload.id })
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (error) {
            console.error(error)
            commit('setErrorsBranches', error)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async updateBranch({ state, commit, dispatch }, payload) {
        if (state.modal.errors.stepOne.validation) {
            try {
                await new Rest(
                    'PUT',
                    `contacts/${payload.contactId}/branches/${state.modal.fields._id}`
                )
                    .setBody(state.modal.fields)
                    .send()
                await dispatch('fetchBranchesTable', { id: payload.contactId })
                commit('setSnackbars', { type: 'success' })
                return true
            } catch (err) {
                console.error(err)
                commit('setErrorsBranches', err)
                commit('setSnackbars', { type: 'error' })
            }
        }
    },
}

const mutations = {
    setBranchesTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
    clearBranchModal(state, payload) {
        state.modal.fields = {}
    },
    clearBranchErrors() {
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step]) {
                state.modal.errors[step][key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
    setErrorsBranches(state, payload) {
        for (const step in state.modal.errors) {
            for (const y in state.modal.errors[step]) {
                state.modal.errors[step][y] = ''
                state.modal.errors[step].validation = true
            }
            for (const x in payload.response.data.errors) {
                let number = /\d+/
                state.modal.errors[step][
                    x.replace(`branches.${x.match(number)}.`, '')
                ] = `${i18n.t('global:errorFields')}!`
                state.modal.errors[step].validation = false
            }
        }
    },
    setBranchModal(state, payload) {
        let obj = payload ? clone(payload) : clone(state.details)
        state.modal.fields = obj
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
