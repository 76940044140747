<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="getAlertStep.step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getAlertErrors.stepOne.validation,
                                ]"
                                editable
                                step="1"
                                >{{
                                    $t('alerts:detailsAlerts')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getAlertErrors.stepTwo.validation,
                                ]"
                                editable
                                step="2"
                                >{{
                                    $t('alerts:assignPersons')
                                }}</v-stepper-step
                            >
                            <!-- $t('alerts:detailsAlerts') -->
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <Separator
                                            :text="$t('alerts:addNewAlerts')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <v-menu
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                            v-model="datePicker"
                                            ><template
                                                v-slot:activator="{
                                                    on,
                                                    attrs,
                                                }"
                                            >
                                                <v-text-field
                                                    data-cy="alertDate"
                                                    v-model="
                                                        getAlertModal.alertDate
                                                    "
                                                    clearable
                                                    @click:clear="
                                                        getAlertModal.alertDate = null
                                                    "
                                                    :label="`${$t(
                                                        'alerts:alertDate'
                                                    )}*`"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    :error-messages="
                                                        getAlertErrors.stepOne
                                                            .fields.alertDate
                                                    "
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                data-cy="datePicker"
                                                first-day-of-week="1"
                                                :min="
                                                    new Date()
                                                        .toISOString()
                                                        .substr(0, 10)
                                                "
                                                v-model="
                                                    getAlertModal.alertDate
                                                "
                                                @input="data1 = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-menu
                                            ref="timer"
                                            v-model="timePicker"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    data-cy="alertTime"
                                                    v-model="
                                                        getAlertModal.alertTime
                                                    "
                                                    :label="`${$t(
                                                        'alerts:alertTimie'
                                                    )}*`"
                                                    :disabled="
                                                        !getAlertModal.alertDate
                                                    "
                                                    prepend-icon="mdi-clock-outline"
                                                    readonly
                                                    :error-messages="
                                                        getAlertErrors.stepOne
                                                            .fields.alertTime
                                                    "
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-time-picker
                                                data-cy="timePicker"
                                                v-if="timePicker"
                                                v-model="
                                                    getAlertModal.alertTime
                                                "
                                                 :min="getAlertModal.alertDate == $moment().format(
                                                        'YYYY-MM-DD'
                                                    ) ? $moment().format(`HH:mm`) : null"
                                                full-width
                                                format="24hr"
                                                @click:minute="
                                                    $refs.timer.save(
                                                        getAlertModal.alertTime
                                                    )
                                                "
                                            ></v-time-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <v-textarea
                                            data-cy="alertMessage"
                                            :label="$t('alerts:contents')"
                                            rows="1"
                                            :maxlength="maxlength"
                                            v-model="getAlertModal.description"
                                            :error-messages="
                                                this.getAlertModal.description
                                                    .length >= maxlength
                                                    ? `${$t(
                                                          'alerts:maxLength'
                                                      )}`
                                                    : ''
                                            "
                                            no-resize
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="4" offset="8">
                                        <v-select
                                            :label="$t('alerts:priority')"
                                            :items="
                                                getRegistryDictionaries.alerts
                                                    ? getRegistryDictionaries
                                                          .alerts.priority
                                                    : []
                                            "
                                            return-object
                                            v-model="getAlertModal.priority"
                                            item-value="field"
                                        >
                                            <template v-slot:item="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                            <template v-slot:selection="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                            <template slot="append-outer">
                                                <v-tooltip top>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                        }"
                                                    >
                                                        <v-icon
                                                            class="mt-4 cursor-pointer"
                                                            color="#bbb"
                                                            size="15"
                                                            @click="
                                                                getAlertModal.priority =
                                                                    ''
                                                            "
                                                            v-on="on"
                                                            >mdi-window-close</v-icon
                                                        >
                                                    </template>
                                                    <span>{{
                                                        $t('alerts:clear')
                                                    }}</span>
                                                </v-tooltip>
                                            </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12"
                                        ><div class="border--green">
                                            <p class="ma-0 ml-5 primary--text">
                                                {{ $t('alerts:alertOptions') }}
                                            </p>
                                        </div></v-col
                                    >
                                </v-row>
                                <v-row>
                                    <v-col
                                        cols="5"
                                        class="d-flex flex-wrap mt-2"
                                    >
                                        <v-col class="pa-0" cols="12"
                                            ><span class="textGrey--text">
                                                <v-icon
                                                    >mdi-calendar-refresh-outline</v-icon
                                                >
                                                {{ $t('alerts:repeat') }}</span
                                            >
                                        </v-col>
                                        <v-col cols="4" md="4" class="pb-0">
                                            <v-text-field
                                                v-model="
                                                    getAlertModal.repetitionNumber
                                                "
                                                :error-messages="
                                                    getAlertErrors.stepOne
                                                        .fields.repetitionNumber
                                                "
                                                @change="
                                                    getAlertModal.repetitionNumber ==
                                                    ''
                                                        ? (getAlertModal.repetitionTime =
                                                              '')
                                                        : getAlertModal.repetitionNumber &&
                                                          (getAlertModal.repetitionTime =
                                                              time[0])
                                                "
                                                @keyup="
                                                    checkNumber(
                                                        $event,
                                                        'repetitionNumber'
                                                    )
                                                "
                                                :label="$t('alerts:number')"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="8" md="8" class="pb-0">
                                            <v-select
                                                :items="time"
                                                item-text="name"
                                                v-model="
                                                    getAlertModal.repetitionTime
                                                "
                                                :disabled="
                                                    !getAlertModal.repetitionNumber
                                                "
                                                :label="
                                                    $t('alerts:periodOfTime')
                                                "
                                            >
                                                <template slot="append-outer">
                                                    <v-tooltip top>
                                                        <template
                                                            v-slot:activator="{
                                                                on,
                                                            }"
                                                        >
                                                            <v-icon
                                                                class="mt-4 cursor-pointer"
                                                                size="15"
                                                                color="#bbb"
                                                                @click="
                                                                    ;(getAlertModal.repetitionTime =
                                                                        ''),
                                                                        (getAlertModal.repetitionNumber =
                                                                            '')
                                                                "
                                                                v-on="on"
                                                                >mdi-window-close</v-icon
                                                            >
                                                        </template>
                                                        <span>{{
                                                            $t('alerts:clear')
                                                        }}</span>
                                                    </v-tooltip>
                                                </template>
                                            </v-select></v-col
                                        >
                                    </v-col>

                                    <v-col
                                        cols="5"
                                        class="d-flex flex-wrap pb-0 mt-2"
                                    >
                                        <v-col class="pa-0" cols="12"
                                            ><span class="textGrey--text">
                                                <v-icon>mdi-bell</v-icon>

                                                {{
                                                    $t('alerts:notifyBefore')
                                                }}</span
                                            >
                                        </v-col>
                                        <v-col cols="4" md="4" class="pb-0"
                                            ><v-text-field
                                                v-model="
                                                    getAlertModal.reminderNumber
                                                "
                                                @change="
                                                    getAlertModal.reminderNumber ==
                                                    ''
                                                        ? (getAlertModal.reminderTime =
                                                              '')
                                                        : getAlertModal.reminderNumber
                                                "
                                                @keyup="
                                                    checkNumber(
                                                        $event,
                                                        'reminderNumber'
                                                    )
                                                "
                                                :error-messages="
                                                    getAlertErrors.stepOne
                                                        .fields.reminderNumber
                                                "
                                                :label="$t('alerts:number')"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="8" md="8" class="pb-0">
                                            <v-select
                                                :items="time"
                                                item-text="name"
                                                :disabled="
                                                    !getAlertModal.reminderNumber
                                                "
                                                v-model="
                                                    getAlertModal.reminderTime
                                                "
                                                :label="
                                                    $t('alerts:periodOfTime')
                                                "
                                                ><template slot="append-outer">
                                                    <v-tooltip top>
                                                        <template
                                                            v-slot:activator="{
                                                                on,
                                                            }"
                                                        >
                                                            <v-icon
                                                                color="#bbb"
                                                                class="mt-4 cursor-pointer"
                                                                @click="
                                                                    ;(getAlertModal.reminderTime =
                                                                        ''),
                                                                        (getAlertModal.reminderNumber =
                                                                            '')
                                                                "
                                                                size="15"
                                                                v-on="on"
                                                                >mdi-window-close</v-icon
                                                            >
                                                        </template>
                                                        <span>{{
                                                            $t('alerts:clear')
                                                        }}</span>
                                                    </v-tooltip>
                                                </template></v-select
                                            ></v-col
                                        > </v-col
                                    ><v-col
                                        ><v-checkbox
                                            v-model="getAlertModal.active"
                                            :label="$t('alerts:active')"
                                        ></v-checkbox
                                    ></v-col>
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content step="2">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <Separator
                                            :text="$t('alerts:assignTo')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="3" md="2" class="mt-5">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    small
                                                    elevation="0"
                                                    v-on="on"
                                                    @click="toMe()"
                                                    color="white"
                                                    class="textGrey--text"
                                                >
                                                    <v-icon
                                                        color="textGrey"
                                                        size="35"
                                                        >mdi-account</v-icon
                                                    >{{
                                                        $t('alerts:toMe')
                                                    }}</v-btn
                                                >
                                            </template>
                                            <span>{{ $t('alerts:toMe') }}</span>
                                        </v-tooltip>
                                    </v-col>
                                    <v-col cols="3" md="2" class="mt-5">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    small
                                                    v-on="on"
                                                    elevation="0"
                                                    @click="toAllUsers()"
                                                    color="white"
                                                    class="textGrey--text"
                                                >
                                                    <v-icon
                                                        color="textGrey"
                                                        class="mr-2"
                                                        size="35"
                                                        >mdi-account-group</v-icon
                                                    >{{
                                                        $t('alerts:allUsers')
                                                    }}</v-btn
                                                >
                                            </template>
                                            <span>{{
                                                $t('alerts:allUsers')
                                            }}</span>
                                        </v-tooltip></v-col
                                    >

                                    <v-col cols="12" offset="1" md="7">
                                        <v-autocomplete
                                            data-cy="assignTo"
                                            :label="$t('alerts:to')"
                                            item-value="_id"
                                            :items="
                                                value === 'users'
                                                    ? getUsersList
                                                    : getAllDepartments
                                            "
                                            :error-messages="
                                                getAlertErrors.stepTwo.fields.to
                                            "
                                            return-object
                                            v-model="getAlertModal.to[value]"
                                            multiple
                                            class="box-autocomplete"
                                            :filter="filter"
                                        >
                                            <template v-slot:selection="data">
                                                <div
                                                    v-if="value === 'users'"
                                                    class="mr-1"
                                                >
                                                    {{
                                                        ` ${data.item.name} ${data.item.lastname},`
                                                    }}
                                                </div>
                                                <div class="mr-1" v-else>
                                                    {{ `${data.item.name},` }}
                                                </div>
                                            </template>
                                            <template v-slot:item="data">
                                                <v-list-item-avatar
                                                    v-if="value === 'users'"
                                                >
                                                    <v-avatar
                                                        size="35"
                                                        color="secondary"
                                                        class="mx-2"
                                                    >
                                                        <span
                                                            v-if="
                                                                !data.item
                                                                    .picture
                                                            "
                                                            class="white--text central__avatarFont"
                                                        >
                                                            {{
                                                                avatarNick(
                                                                    data.item
                                                                        .name,
                                                                    data.item
                                                                        .lastname
                                                                )
                                                            }}
                                                        </span>
                                                        <img
                                                            :src="`${data.item.picture}`"
                                                            v-else
                                                        />
                                                    </v-avatar>
                                                </v-list-item-avatar>
                                                <v-list-item-avatar v-else>
                                                    <v-avatar
                                                        size="35"
                                                        color="#445D75"
                                                    >
                                                        <span
                                                            class="white--text central__avatarFont"
                                                        >
                                                            {{
                                                                data.item.shortName.toUpperCase()
                                                            }}
                                                        </span>
                                                    </v-avatar>
                                                </v-list-item-avatar>
                                                <!-- === === CONTENT === === -->
                                                <!-- === USERS ===  -->
                                                <v-list-item-content
                                                    v-if="value === 'users'"
                                                >
                                                    <v-list-item-title>
                                                        {{ data.item.lastname }}
                                                        {{ data.item.name }}
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        {{ data.item.job }}
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                                <!-- === DEPARTMENTS === -->
                                                <v-list-item-content v-else>
                                                    <v-list-item-title>
                                                        {{ data.item.name }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                            <v-tabs
                                                slot="prepend-item"
                                                centered
                                                class="elevation-1 select--fields"
                                            >
                                                <v-tab
                                                    @click="value = 'users'"
                                                    >{{
                                                        $t('alerts:users')
                                                    }}</v-tab
                                                >
                                                <v-tab
                                                    @click="
                                                        value = 'departments'
                                                    "
                                                    >{{
                                                        $t('alerts:departments')
                                                    }}</v-tab
                                                >
                                            </v-tabs>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-5">
                                    <v-col cols="12" md="12"
                                        ><div class="border--green">
                                            <p class="ma-0 ml-5 primary--text">
                                                {{ $t('alerts:alertsTo') }}
                                            </p>
                                        </div></v-col
                                    >
                                    <v-col class="py-1" cols="12" md="6">
                                        <div class="list-box">
                                            <span class="list-box__label">{{
                                                $t('alerts:users')
                                            }}</span>
                                            <div class="list-box__content">
                                                <div
                                                    v-for="(item,
                                                    index) in getAlertModal.to
                                                        .users"
                                                    :key="index"
                                                    class="d-flex align-center mx-2 list-box__users"
                                                >
                                                    <v-avatar
                                                        size="40"
                                                        color="secondary"
                                                    >
                                                        <img
                                                            v-if="item.picture"
                                                            :src="item.picture"
                                                            alt="John"
                                                        />
                                                        <span
                                                            class="white--text body-2"
                                                            v-else
                                                            >{{ item.name[0]
                                                            }}{{
                                                                item.lastname[0]
                                                            }}</span
                                                        >
                                                    </v-avatar>
                                                    <div>
                                                        <span
                                                            class="mx-2 body-2"
                                                            >{{
                                                                item.name
                                                            }}</span
                                                        >
                                                        <span class="body-2">{{
                                                            item.lastname
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col class="py-1" cols="12" md="6">
                                        <div class="list-box">
                                            <span class="list-box__label">{{
                                                $t('alerts:departments')
                                            }}</span>
                                            <div class="list-box__content">
                                                <div
                                                    v-for="(item,
                                                    index) in getAlertModal.to
                                                        .departments"
                                                    :key="index"
                                                    class="d-flex align-center mx-2 list-box__users"
                                                >
                                                    <v-avatar
                                                        size="40"
                                                        color="primary"
                                                    >
                                                        <span
                                                            class="white--text body-2"
                                                            >{{
                                                                item.shortName
                                                            }}</span
                                                        >
                                                    </v-avatar>
                                                    <div>
                                                        <span
                                                            class="mx-2 body-2"
                                                            >{{
                                                                item.name
                                                            }}</span
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    data() {
        return {
            data1: '',
            value: 'users',
            datePicker: false,
            timePicker: '',
            date: '',
            editable: true,
            maxlength: 100,
            time: [
                {
                    name: this.$t('alerts:minutes'),
                    value: 'minutes',
                },

                {
                    name: this.$t('alerts:hours'),
                    value: 'hours',
                },
                {
                    name: this.$t('alerts:days'),
                    value: 'days',
                },
                {
                    name: this.$t('alerts:weeks'),
                    value: 'weeks',
                },
                {
                    name: this.$t('alerts:months'),
                    value: 'months',
                },
                {
                    name: this.$t('alerts:years'),
                    value: 'years',
                },
            ],
        }
    },

    computed: {
        ...mapGetters([
            'getAlertErrors',
            'getAlertModal',
            'getProfileDetails',
            'getUsersList',
            'getAllDepartments',
            'getAlertStep',
            'getRegistryDictionaries',
        ]),
    },
    methods: {
        checkNumber(e, field) {
            let convert = Number(e.target.value)
            if (!convert) {
                this.getAlertErrors.stepOne.fields[field] = this.$t(
                    'alerts:reqNumber'
                )
                this.getAlertModal[field] = ''
            } else {
                this.getAlertErrors.stepOne.fields[field] = ''
            }
        },
        avatarNick(name, lastname) {
            let nick = name[0] + lastname[0]
            return nick.toUpperCase()
        },
        toAllUsers() {
            this.getAlertModal.to.users = this.getUsersList
        },
        toMe() {
            this.getAlertModal.to.users = [
                {
                    _id: this.getProfileDetails._id,
                    name: this.getProfileDetails.name,
                    lastname: this.getProfileDetails.lastname,
                    mail: this.getProfileDetails.mail,
                },
            ]
        },
        filter(item, queryText, itemText) {
            if (item.lastname)
                return (
                    item.name.toLowerCase().includes(queryText.toLowerCase()) ||
                    item.lastname
                        .toLowerCase()
                        .includes(queryText.toLowerCase())
                )
            else if (item.fullName) {
                return item.fullName
                    .toLowerCase()
                    .includes(queryText.toLowerCase())
            } else
                return item.name.toLowerCase().includes(queryText.toLowerCase())
        },
    },
}
</script>
