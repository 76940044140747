<template>
    <div class="d-flex justify-end buttons--details">
        <v-tooltip left v-if="$route.name !== 'registries'">
            <template v-slot:activator="{ on }">
                <v-btn
                    fab
                    dark
                    v-on="on"
                    v-if="add()"
                    small
                    class="buttons--add margin--1 mr-2"
                    @click="$emit('add-from-template')"
                >
                    <v-icon small>mdi-file-table-box-multiple</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('attachments:addFromTemplate') }}</span>
        </v-tooltip>
        <v-tooltip left>
            <template v-slot:activator="{ on }">
                <v-btn
                    fab
                    dark
                    v-on="on"
                    small
                    v-if="add()"
                    class="buttons--add margin--1 mr-2"
                    @click="$emit('add-from-storage')"
                >
                    <v-icon small>mdi-safe-square-outline</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('attachments:addFromStorage') }}</span>
        </v-tooltip>
        <v-tooltip left>
            <template v-slot:activator="{ on }">
                <v-btn
                    data-cy="add"
                    fab
                    dark
                    v-on="on"
                    small
                    v-if="add()"
                    class="buttons--add margin--1"
                    @click="$emit('add-file', { type: 'create' })"
                >
                    <v-icon small>mdi-plus</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('attachments:addFile') }}</span>
        </v-tooltip>
    </div>
</template>
<script>
export default {
    i18nOptions: { namespaces: ['attachments'] },
    methods: {
        add() {
            return this.checkPermissions('UPDATE')
        },
    },
}
</script>
