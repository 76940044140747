<template>
    <div class="price">
        <label>{{ label }}</label>
        <money
        
            class="input-effect"
            :value="value"
            :readonly="readonly"
            :disabled="disabled"
            v-bind="[money, $props, $attrs]"
            v-on:input="updateValue($event)"
        ></money>
        <div class="money-error-message">{{errorMessage}}</div>
        <span class="focus-border"></span>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            default: 0,
        },
        label: {
            default: '',
        },
        readonly: {
            default: false,
        },

        disabled: {
            default: false,
        },
        precision:{
            default:2
        },
        errorMessage: {
            default: ''
        }
    },
    data() {
        return {
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: ' PLN',
                precision: this.precision,
                masked: false,
            },
        }
    },
    methods: {
        updateValue(value) {
            this.$emit('input', value)
        },

    },
}
</script>
<style lang="scss" scoped>
.money-error-message {
    color: red;
    font-size: 0.65rem;
}
</style>