<template>
    <v-container class="d-flex justify-space-between px-2 py-0">
        <div class="mobileDetailsViewHeader">
            <div class="d-flex align-end">
                <div class="central__title">
                    <router-link tag="div" :to="`/${registryUrl}`">
                        <h1 class="font-weight-light cursor-pointer mt-1 mr-3">
                            {{ registryTitle }}
                        </h1>
                    </router-link>
                </div>
                <div
                    slot="head"
                    v-bind:class="{ 'mb-1': !this.isTablet() }"
                    class="d-flex headerMobile"
                >
                    <span class="mx-2 grey--text central__index">{{
                        recordIndex
                    }}</span>
                    <v-divider class="mb-2" vertical></v-divider>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <span
                                class="central__status"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon
                                    color="secondary"
                                    class="central__status__arrow"
                                    >mdi-menu-down</v-icon
                                >{{ status.name }}</span
                            >
                        </template>
                        <v-list>
                            <v-list-item
                                class="cursor-pointer"
                                v-for="(item, index) in registryStatus"
                                :key="index"
                                @click="
                                    status = item
                                    $emit('status-changed', item)
                                "
                            >
                                <v-list-item-title>{{
                                    item.name
                                }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </div>
        </div>
        <Avatar
            v-if="avatarData"
            :name="avatarData.name"
            :lastname="avatarData.lastname"
            :tooltip="'Autor'"
            :phone="avatarData.phone || ''"
            :email="avatarData.mail || ''"
            :picture="avatarData.picture || ''"
        />
    </v-container>
</template>
<script>
import Avatar from './../../../components/Global/Avatar/Avatar'
/**
 * Central Table
 * @displayName Registry Details Header
 */
export default {
    props: {
        registryTitle: {
            required: true,
        },
        recordIndex: {
            type: String,
            required: false,
        },
        registryUrl: {
            type: String,
            required: false,
        },
        registryStatus: {
            type: Array,
            required: true,
        },
        actualStatus: {
            type: Object,
            required: true,
        },
        avatarData: {
            type: Object,
        },
    },
    components: {
        Avatar,
    },
    data() {
        return {
            status: this.actualStatus,
        }
    },
    watch: {
        actualStatus: function (newVal) {
            this.status = newVal
        },
    },
}
</script>
