import Rest from './../../../plugins/Rest'
import clone from 'lodash.clone'
import i18n from './../../../plugins/i18next'
import validation from './../../../plugins/validation'
import moment from 'moment'
import clearSearch from './../../../plugins/clearSearch'
const state = {
    modal: {
        fields: {
            email: '',
            newsletterKind: '',
            description: '',
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {
                    email: '',
                    newsletterKind: '',
                },
            },
        },
        step: 1,
    },
    request: {
        populate: [
            {
                localField: 'currentMenager',
                foreignField: '_id',
                from: 'users',
                as: 'currentMenager',
            },
        ],

        searchFields: ['index'],
        defaultSearchFields: ['index'],
        select:
            '_id index email createdAt newsletterKind description status folders',
        where: {
            isDeleted: false,
        },
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: '-createdAt',
        loading: false,
    },
    input: {
        items: [],
        search: '',
        limit: 4,
        sort: 'createdAt',
        defaultSort: 'createdAt',
    },
    dictFields: {
        newsletterKind: '',
    },
    statuses: [],
    details: {},
    registry: {},
}

const getters = {
    getNewsletterModal: state => state.modal.fields,
    getNewsletterError: state => state.modal.errors,
    getNewsletterStep: state => state.modal,
    getNewsletterTable: state => state.table,
    getNewsletterStatuses: state => state.statuses,
    getNewsletterSearchFields: state => state.request,
    getNewsletterDetails: state => state.details,
    getNewsletterRegistry: state => state.registry,
    getNewsletterInput: state => state.input,
}

const actions = {
    async fetchNewsletterTable({ commit, dispatch }) {
        state.table.loading = true
        try {
            let response = await new Rest('GET', 'newsletter')
                .select(state.request.select)
                .limit(state.table.limit)
                .page(state.table.page)
                .where(state.request.where)
                .populate(state.request.populate)
                .search(state.request.searchFields, state.table.search)
                .sort(state.table.sort || state.table.defaultSort)
                .send()

            dispatch('fetchNewsletterRegistry')
            commit('setNewsletterTable', response.data)
        } catch (err) {
            console.error(err)
        }
        state.table.loading = false
    },

    async fetchNewsletterRegistry({ commit, dispatch }, payload) {
        let registry = await new Rest('GET', 'registries')
            .select('currentMenager name')
            .where({ url: 'newsletter' })
            .populate([
                {
                    path: 'currentMenager',
                    select: 'name lastname mail status phone picture',
                },
            ])
            .send()

        commit('setNewsletterRegistry', registry.data.items)
    },

    async createNewsletter({ commit, dispatch }, payload) {
        try {
            await new Rest('POST', 'newsletter')
                .setBody(state.modal.fields)
                .send()
            commit('clearNewsletterModal')
            commit('clearNewsletterErrors')
            dispatch('fetchNewsletterTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            //HANDLE ERRORS
            if (err) {
                commit('setSnackbars', { type: 'error' })
                validation.setErrors(state.modal.errors, err)
            }
        }
    },

    async fetchNewsletter({ state, commit, dispatch }, payload) {
        try {
            let leasing = await new Rest('GET', `newsletter/${payload.id}`)
                .populate([
                    {
                        path: 'createdBy',
                        select: 'name lastname mail status phone picture',
                    },
                    {
                        path: 'driver',
                        select: 'name lastname ',
                    },
                    {
                        path: 'leasingStuff.car',
                        select: 'vin isDeleted leasing model typeOfCar',
                    },
                    {
                        path: 'leasingStuff.device',
                        select: 'sn isDeleted model leasing',
                        populate: {
                            path: 'model',
                        },
                    },
                ])
                .select('-folders')
                .send()

            let statusesReq = new Rest('GET', `registries/newsletter/statuses`)
                .select('name _id isDeleted color state')
                .where({ isDeleted: false })
                .send()

            let { 0: response, 1: statuses } = await Promise.all([
                leasing,
                statusesReq,
            ])
            dispatch('fetchNewsletterRegistry')

            commit('setNewsletterDetails', response.data)
            commit('setNewsletterStatuses', statuses.data.items)

            if (payload.next) payload.next()
        } catch (err) {
            console.error(err)
        }
    },

    async updateNewsletter({ commit, dispatch }, payload) {
        try {
            let response = await new Rest('PUT', `newsletter/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            dispatch('fetchNewsletter', { id: payload.id })
            commit('clearNewsletterModal')
            commit('clearNewsletterErrors')
            return true
        } catch (err) {
            //HANDLE ERRORS
            validation.setErrors(state.modal.errors, err)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async updateNewsletterStatus({ commit, dispatch }, payload) {
        commit('setNewsletterModal')
        commit('setNewsletterStatus', payload.status)
        try {
            let response = await new Rest('PUT', `newsletter/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            commit('setSnackbars', { type: 'success' })
        } catch (err) {
            console.error(err)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async deleteNewsletter({ commit, dispatch }, payload) {
        try {
            await new Rest('DELETE', `newsletter/${payload.id}`).send()
            dispatch('fetchNewsletterTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            console.error(err)
        }
    },
    async fetchNewsletterInput({ commit, dispatch }) {
        try {
            let response = await new Rest('GET', 'newsletter')
                .select('leasingNumber')
                .limit(state.input.limit)
                .search(state.request.searchFields, state.input.search)
                .where(state.request.where)
                .populate(state.request.populate)
                .sort(state.input.sort || state.input.defaultSort)
                .send()

            commit('setNewsletterInput', response.data)
        } catch (err) {
            console.error(err)
        }
    },
}

const mutations = {
    setNewsletterTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
    setNewsletterRegistry(state, payload) {
        state.registry = payload[0]
    },
    setNewsletterSearch(state, payload) {
        state.request.searchFields = payload
    },
    setNewsletterDetails(state, payload) {
        state.details = payload
    },
    setNewsletterStatuses(state, payload) {
        state.statuses = payload
    },
    setNewsletterStatus(state, payload) {
        state.modal.fields.status = payload
    },
    setNewsletterModal(state, payload) {
        let obj = payload ? payload : clone(state.details)
        state.modal.fields = obj
    },
    clearNewsletterModal(state, payload) {
        state.modal.step = 1
        state.modal.fields = {
            email: '',
            newsletterKind: '',
            description: '',
        }
    },
    clearNewsletterErrors(state) {
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step].fields) {
                state.modal.errors[step].fields[key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
    clearNewsletterTable(state) {
        clearSearch.clearSearchTable(state)
    },
    setNewsletterInput(state, payload) {
        state.input.items = payload.items || state.input.items
        if (payload.search != undefined) state.input.search = payload.search
        state.input.limit = payload.limit || state.input.limit
        if (payload.sort != undefined) state.input.sort = payload.sort
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
