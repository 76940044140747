<template>
    <v-container class="py-0 p-relative">
        <v-row @paste="onPasteEvent">
            <v-col cols="12" lg="3" md="5" sm="12" class="details__attachments__folders pa-3">
                <v-divider vertical></v-divider>
                <FolderList @open-folder-modal="openFolderModal" @remove-folder-modal="folderRemoveModal"
                    @clearSearch="resetSearch" />
            </v-col>
            <v-col cols="12" lg="9" md="7" sm="12" class="pa-3">
                <v-row>

                    <v-col cols="12" class="pa-0 pl-3">
                        <div class="transparentBox">
                        </div>
                        <div class="hide">
                            <div class="hide__inner">
                                <div class="hide__inner--keyboard--button"><span>CTRL</span>

                                </div>
                                <div class="hide__inner--keyboard--button"><span>+</span>

                                </div>
                                <div class="hide__inner--keyboard--button"><span>V</span></div>
                                <div class="hide__inner--keyboard--button"><span>
                                        <v-icon small>mdi-file-document-multiple</v-icon>
                                    </span></div>
                            </div>
                        </div>
                        <FileTable :headers="headers" :items="getFilesItems">
                            <FileColumns slot="columns" :items="getFilesItems" @update-file="openFileModal"
                                @remove-file-modal="fileRemoveModal" />
                        </FileTable>
                    </v-col>
                    <v-col cols="12" lg="3" xl="2" md="4" sm="5" class="ml-auto">
                        <v-text-field :label="$t('global:search')" v-model="search" single-line ref="search"
                            hide-details @input="searchFile(search)">
                            <template v-slot:prepend>
                                <v-icon @click="setFocus()" color="secondary">mdi-magnify</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <Buttons @add-file="openFileModal" @add-from-storage="openStorageModal"
                        @add-from-template="openTemplateModal" />
                </v-row>
            </v-col>
        </v-row>
        <Modal :open="files.modal.open" :title="files.modal.title" @close="closeFileModal">
            <FileContentEdit v-if="this.files.modal.action == 'edit'" slot="AEContent" />
            <FileContent v-else slot="AEContent" @open-folder-modal="openFolderModal" />
            <FilesButtons :action="files.modal.action" slot="buttons" @closeModal="closeFileModal" />
        </Modal>

        <Modal :open="folders.modal.open" :title="folders.modal.title" :height="'320'" :width="'450'"
            @close="closeFolderModal">
            <FolderContent slot="AEContent" />
            <FolderButtons :action="folders.modal.action" slot="buttons" @closeModal="closeFolderModal" />
        </Modal>

        <Modal :open="storage.modal.open" :title="storage.modal.title" :height="'320'" :width="'450'"
            @close="closeStorageModal">
            <StorageContent slot="AEContent" />
            <StorageButtons slot="buttons" :action="storage.modal.action" @closeModal="closeStorageModal" />
        </Modal>

        <Modal :open="template.modal.open" :title="template.modal.title" :height="'320'" :width="'450'"
            @close="closeTemplateModal">
            <TemplateContent slot="AEContent" />
            <TemplateButtons slot="buttons" :action="template.modal.action" @closeModal="closeTemplateModal" />
        </Modal>

        <ConfirmModal :openConfirm="folders.remove.open" @close-confirm="closeConfirmModal">
            <div slot="main">
                <ConfirmContent />
            </div>
            <div slot="buttons">
                <ConfirmFolderButtons :removeId="folders.remove.id" @close-confirm="closeConfirmModal" />
            </div>
        </ConfirmModal>

        <ConfirmModal :openConfirm="files.remove.open" @close-confirm="closeConfirmModal">
            <div slot="main">
                <ConfirmContent />
            </div>
            <div slot="buttons">
                <ConfirmFilesButtons :removeId="files.remove.id" @close-confirm="closeConfirmModal" />
            </div>
        </ConfirmModal>
    </v-container>
</template>
<script>
import FolderList from './Folders/List'
import FolderContent from './Folders/Modal/Content'
import FolderButtons from './Folders/Modal/Buttons'
import FileTable from './Files/Table'
import FileColumns from './Files/Columns'
import FileContent from './Files/Modal/Content'
import FileContentEdit from './Files/Modal/EditContent'
import FilesButtons from './Files/Modal/Buttons'
import StorageContent from './Storage/Modal/Content'
import StorageButtons from './Storage/Modal/Buttons'
import TemplateContent from './Template/Modal/Content'
import TemplateButtons from './Template/Modal/Buttons'
import Buttons from './Buttons'
import ConfirmContent from './ConfirmContent'
import ConfirmFolderButtons from './Folders/ConfirmButtons'
import ConfirmFilesButtons from './Files/ConfirmButtons'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    i18nOptions: { namespaces: ['global'] },
    components: {
        FolderList,
        FileTable,
        FileColumns,
        Buttons,
        FileContent,
        FolderContent,
        FolderButtons,
        ConfirmContent,
        ConfirmFolderButtons,
        FilesButtons,
        FileContentEdit,
        ConfirmFilesButtons,
        StorageContent,
        StorageButtons,
        TemplateContent,
        TemplateButtons,
    },
    data() {
        return {
            pastedStorage: [],
            search: '',
            attachmentsStatus: '',
            files: {
                modal: {
                    open: false,
                    title: '',
                    action: '',
                },
                remove: {
                    open: false,
                    id: '',
                },
            },
            folders: {
                modal: {
                    open: false,
                    title: '',
                    action: '',
                },
                remove: {
                    open: false,
                    id: '',
                },
            },
            storage: {
                modal: {
                    open: false,
                    title: '',
                    action: '',
                },
            },
            template: {
                modal: {
                    open: false,
                    title: '',
                    action: '',
                },
            },
            headers: [
                {
                    text: this.$t('global:fileName'),
                    value: 'fileName',
                    width: '30%',
                },
                {
                    text: this.$t('global:description'),
                    value: 'description',
                    width: '40%',
                },
                {
                    text: this.$t('global:status'),
                    value: 'status',
                    width: '15%',
                },
                {
                    text: '',
                    value: 'actions',
                    width: '15%',
                    sortable: false,
                },
            ],
        }
    },
    methods: {
        ...mapActions([
            'fetchFolders',
            'fetchFiles',
            'fetchStorage',
            'fetchTemplates',
            'fetchRegistryStatus',
            'fetchPart',
            'fetchModel',
            'fetchDevice',
            'fetchOperationLock',
            'fetchPriceList',
            'fetchDepartment',
            'fetchBusinessDocument',
            'fetchContact',
            'fetchOffer',
            'fetchCar',
            'fetchUser',
            'fetchTenders',
            'fetchServicePrices',
            'fetchContract',
            'fetchLastRentInfo',
            'fetchEnforcement',
            'createFile',
        ]),
        ...mapMutations([
            'clearFolderModal',
            'setFoldersModal',
            'clearFilesModal',
            'setFileModal',
            'setCurrentFolder',
            'clearTemplates',
            'setPastedFile',
            'setSnackbars',
        ]),
        async savePastedFile() {
            this.disabled = true
            let success = await this.createFile({
                routeId: this.$route.params.id,
                routeName: this.$route.name,
            })
            return success
        },

        async onPasteEvent(pasteEvent, callback) {
            if (pasteEvent.clipboardData == false) {
                if (typeof callback == 'function') {
                    callback(undefined)
                }
            }

            var items = pasteEvent.clipboardData.items

            if (items == undefined) {
                if (typeof callback == 'function') {
                    callback(undefined)
                }
            }

            let blob = []
            for (var i = 0; i < items.length; i++) {
                // if (items[i].type.indexOf('image') == -1) {
                //     this.setSnackbars({
                //         type: 'error',
                //         text: `Dostępne tylko dla plików graficznych`,
                //     })
                // }
                // if (items[i].type.indexOf('image') == -1) continue

                blob.push(items[i].getAsFile())
            }

            if (blob.length > 0) {
                const currentFolderId = this.getCurrentFolder
                const payload = {
                    files: blob,
                    folder: currentFolderId,
                    status: 'CURRENT',
                }
                this.setPastedFile(payload)
                const success = await this.savePastedFile()

                let filesNamesString = ''
                if (success) {
                    for (let i = 0; i < blob.length; i++) {
                        filesNamesString += blob[i].name + ' '
                    }
                    this.setSnackbars({
                        type: 'success',
                        text: `Dodano plik: ${filesNamesString}`,
                    })
                }
            }
        },

        async attachmentStatus() {
            let response = await this.fetchRegistryStatus({
                where: { url: this.$route.name },
                select: 'statusFiles',
            })
            return (this.getFilesModalFields.status =
                response[0].statusFiles || 'DRAFT')
        },

        openFileModal(payload) {
            if (payload.type == 'edit') {
                this.setFileModal(payload.item)
            }
            if (payload.type == 'create') {
                this.attachmentStatus()
                this.setCurrentFolder()
            }
            this.files.modal.title =
                payload.type == 'edit'
                    ? this.$t('global:edit')
                    : this.$t('global:addition')
            this.files.modal.action = payload.type
            this.files.modal.open = true
        },
        openStorageModal() {
            this.storage.modal.title = this.$t('global:addition')
            this.storage.modal.action = 'create'
            this.fetchStorage({ limit: 1000 })
            this.storage.modal.open = true
        },
        openFolderModal(payload) {
            if (payload.type == 'edit') {
                this.setFoldersModal(payload.item)
            }
            this.folders.modal.title =
                payload.type == 'create'
                    ? this.$t('attachments:additionFolder')
                    : this.$t('global:edit')

            this.folders.modal.action = payload.type
            this.folders.modal.open = true
        },
        openTemplateModal() {
            this.template.modal.title = this.$t('global:addition')
            this.template.modal.action = 'create'
            this.fetchTemplates(this.$route.name)
            this.template.modal.open = true
        },
        closeFolderModal() {
            this.clearFolderModal()
            this.folders.modal.open = false
        },
        folderRemoveModal(payload) {
            this.folders.remove.id = payload.id
            this.folders.remove.open = true
        },
        closeConfirmModal() {
            this.folders.remove.open = false
            this.files.remove.open = false
        },
        async closeFileModal() {
            this.clearFilesModal()
            this.files.modal.open = false

            const routeName = this.$route.name
            const routeId = this.$route.params.id

            if (routeName === 'parts') {
                await this.fetchPart({ id: routeId })
            }
            if (routeName === 'models') {
                await this.fetchModel({ id: routeId })
            }
            if (routeName === 'devices') {
                await this.fetchDevice({ id: routeId })
            }
            if (routeName === 'operation-lock') {
                await this.fetchOperationLock({ id: routeId })
            }
            if (routeName === 'price-list') {
                await this.fetchPriceList({ id: routeId })
            }
            if (routeName === 'departments') {
                await this.fetchDepartment({ id: routeId })
            }
            if (routeName === 'business-document') {
                await this.fetchBusinessDocument({ id: routeId })
            }
            if (routeName === 'contacts') {
                await this.fetchContact({ id: routeId })
            }
            if (routeName === 'leasings') {
                await this.fetchLeasing({ id: routeId })
            }
            if (routeName === 'offers') {
                await this.fetchOffer({ id: routeId })
            }
            if (routeName === 'cars') {
                await this.fetchCar({ id: routeId })
            }
            if (routeName === 'users') {
                await this.fetchUser({ id: routeId })
            }
            if (routeName === 'tenders') {
                await this.fetchTenders({ id: routeId })
            }
            if (routeName === 'service-prices') {
                await this.fetchServicePrices({ id: routeId })
            }
            if (routeName === 'contracts') {
                await this.fetchContract({ id: routeId })
            }
            if (routeName === 'rent-info') {
                await this.fetchLastRentInfo({ id: routeId })
            }
            if (routeName === 'enforcements') {
                await this.fetchEnforcement({ id: routeId })
            }
        },
        fileRemoveModal(payload) {
            this.files.remove.id = payload.id
            this.files.remove.open = true
        },
        closeStorageModal() {
            this.storage.modal.open = false
        },
        searchFile(text) {
            let rows = document.querySelectorAll('.attachmentsTr')
            let filter = text.toUpperCase()
            for (let i = 0; i < rows.length; i++) {
                if (rows[i].textContent.toUpperCase().indexOf(filter) > -1)
                    rows[i].style.display = ''
                else rows[i].style.display = 'none'
            }
        },

        closeTemplateModal() {
            this.clearTemplates()
            this.template.modal.open = false
        },
        setFocus() {
            this.$refs.search.focus()
        },
        resetSearch() {
            this.search = ''
        },
    },
    computed: {
        ...mapGetters([
            'getFoldersItems',
            'getFilesItems',
            'getFilesModalFields',
            'getCurrentFolder',
        ]),
    },
    async beforeMount() {
        await this.fetchFolders({
            routeName: this.$route.name,
            routeId: this.$route.params.id,
        })
    },
}
</script>
<style lang="scss" scoped>
.transparentBox {

    position: absolute;
    left: 25%;
    top: 0%;
    width: 75%;
    height: 100%;


}

.transparentBox:hover+.hide {
    display: block;
    pointer-events: none;
}

.hide {
    display: none;
    position: absolute;
    left: 25%;
    top: 0%;
    width: 75%;
    height: 90%;


    &__inner {
        display: flex;
        position: absolute;
        left: 40%;
        top: 51%;


        &--keyboard--button {
            margin-right: 10px;
            padding: 3px;
            font-size: 0.55em;
            font-weight: bold;
            color: #111;
            opacity: 0.9;
            border: 1px solid rgba(0, 0, 0, .2);
            border-radius: 5px;


        }

        &--keyboard--button:nth-of-type(1)>span {

            padding-right: 13px;

        }

        &--keyboard--button:nth-of-type(2)>span,
        &--keyboard--button:nth-of-type(3)>span {
            padding: 8px;

        }

        &--keyboard--button:nth-of-type(4)>span {
            padding: 10px;

        }




    }

}
</style>