<template>
    <div>
        <v-btn
            @click="action == 'create' ? save() : edit()"
            :disabled="disabled"
            class="buttons buttons--add"
        >
            {{ $t('global:save') }}
        </v-btn>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: ['action', 'target'],

    data() {
        return {
            disabled: false,
        }
    },
    methods: {
        ...mapActions(['createRentDevice', 'updateRentDevice']),
        async save() {
            this.disabled = true
            let success = await this.createRentDevice({
                contractId: this.target,
                id: this.$route.params.id
            })
            if (success) this.$emit('closeModal')
            this.disabled = false
        },
        async edit() {
            this.disabled = true
            let success = await this.updateRentDevice({
                id: this.target._id,
                contractId: this.$get(this.target,'contract[0]._id',null),
                rentInfoId:this.$route.params.id
            })
            if (success) this.$emit('closeModal')
            this.disabled = false
        },
    },
    updated() {},
}
</script>
