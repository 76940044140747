<template>
    <div>
        <div class="taskDriver__box--content-wrapper-avatar active-class">
            <v-avatar size="20"
                >{{ services[0].technician.name[0]
                }}{{ services[0].technician.lastname[0] }}</v-avatar
            >

            <div
                v-if="services"
                class="taskDriver__box--content-wrapper-tasks-wrapper"
            >
                <div
                    @click="openService(service)"
                    v-for="service in services"
                    :key="service._id"
                    class="
                        taskDriver__box--content-wrapper-tasks-wrapper-single
                    "
                >
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <div v-if="serviceType(service) === 'PILNE'">
                                <v-avatar
                                    size="16"
                                    color="#F44336"
                                    v-bind="attrs"
                                    v-on="on"
                                    >P</v-avatar
                                >
                            </div>
                            <div v-if="serviceType(service) === 'WDROŻENIE'">
                                <v-avatar
                                    size="16"
                                    color="blue"
                                    v-bind="attrs"
                                    v-on="on"
                                    >W</v-avatar
                                >
                            </div>

                            <div v-if="serviceType(service) === 'INNE'">
                                <v-icon
                                    x-small
                                    color="rgba(189, 189, 189)"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-checkbox-blank-circle
                                </v-icon>
                            </div>
                        </template>
                        <span>{{ service.index }}</span>
                    </v-tooltip>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TaskDriverSingle',
    props: ['technicEmail', 'services'],
    methods: {
        serviceType(service) {
            if (service.realizationCheckboxes.urgent) {
                return 'PILNE'
            }
            if (service.realizationCheckboxes.installation) {
                return 'WDROŻENIE'
            } else {
                return 'INNE'
            }
        },
        openService(service) {
            const currentRoute = this.$router.history.current.params.id
            if (service._id !== currentRoute) {
                this.$router.push('/service/' + service._id)
            }
        },
    },
}
</script>

<style lang="sass" scoped>
.taskDriver__box
    &--content-wrapper
        display: grid
        grid-template-columns: repeat(auto-fit, minmax(1px, 1fr))
        &-tasks-wrapper-single
            padding: 2px
            .urgent_avatar
                background-color: red !important
            .deploy_avatar
                background-color: #00bfff !important
        &-avatar
            .v-avatar
                font-size: 11px
                color: white
                background-color: rgb(122, 208, 191)

        &-tasks-wrapper
            display: grid
            grid-template-columns: 1fr
</style>
