<template>
    <tbody>
        <tr v-for="(item, index) in items" :key="index">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <td class="truncate pa-2" v-on="on">
                        <div
                            class="cursor-pointer mb-1 word--wrap"
                            @click="link(item._id)"
                        >
                            {{ item.name }}
                        </div>
                        <span
                            v-if="item.phoneNumber"
                            class="caption d-block primary--text"
                        >
                            <v-icon size="12" color="primary">mdi-phone</v-icon>
                            {{ item.phoneNumber }}
                        </span>
                        <span
                            v-if="item.email"
                            class="caption d-block d-flex grey--text"
                        >
                            <v-icon size="12" color="primary">mdi-email-fast</v-icon>
                            <div class="pl-1" v-html="mailTo(item.email)"></div>
                        </span>
                    </td>
                    <!-- <td class="d-flex align-center justify-end">
                <v-btn small router :to="`/contacts/${item._id}`" icon>
                    <v-icon color="secondary" small
                        >mdi-arrow-right-thick</v-icon
                    >
                </v-btn>
            </td> -->
                </template>
                <span>{{ $t('global:shows') }}</span>
            </v-tooltip>
        </tr>
    </tbody>
</template>
<script>
export default {
    props: ['items'],
    data() {
        return {
            fullPath: '/contacts/',
        }
    },

    methods: {
        mailTo(mail) {
            return mail ? `<a href="mailto:${mail}">${mail}</a>` : ''
        },
        link(id) {
            const path = `/contacts/${id}`
            if (this.$route.path !== path) {
                this.$router.push(path)
            }
        },
    },
}
</script>
