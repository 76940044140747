<template>
    <tbody>
        <tr
            v-for="item in setFilesItems"
            :key="item._id"
            :class="`${isStatusArchive(item) ? 'disabled' : ''}`"
            class="attachmentsTr"
        >
            <td class="truncate">
                <v-icon small class="fileIcon">{{ setIcon(item) }}</v-icon>
                <span>{{ item.name }}</span>
                <!-- <v-tooltip
                    :disabled="editedFiles.includes(item._id) ? false : true"
                    right
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-badge
                            :value="
                                editedFiles.includes(item._id) ? true : false
                            "
                            class="badge_attachments"
                            color="yellow"
                        >
                            <v-icon
                                slot="badge"
                                icon
                                style="z-index: 1;"
                                v-bind="attrs"
                                v-on="on"
                                @click="
                                    editedFiles.includes(item._id)
                                        ? refresh()
                                        : null
                                "
                                >mdi-exclamation-thick</v-icon
                            >
                        </v-badge></template
                    >
                    <span>{{ $t('attachments:updatedWarning') }}</span>
                </v-tooltip> -->
            </td>
            <td class="truncate" data-cy-column="description">
                {{ item.description }}
            </td>
            <td class="truncate" data-cy-column="status">
                {{ statusName(item.status) }}
            </td>
            <td
                class="right--text d-flex"
                style="align-items: center; justify-content: flex-end;"
            >
                <!-- === === VIEW === === -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            icon
                            small
                            @click="viewFile(item)"
                            v-if="!item.url && read()"
                        >
                            <v-icon color="secondary" small>mdi-magnify</v-icon>
                        </v-btn>
                        <v-btn
                            v-on="on"
                            icon
                            small
                            @click="viewURL(item.url) && read()"
                            v-else
                        >
                            <v-icon color="secondary" small>mdi-magnify</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('attachments:preview') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            icon
                            small
                            v-if="update()"
                            @click="
                                $emit('update-file', { item, type: 'edit' })
                            "
                        >
                            <v-icon color="secondary" small>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('attachments:editFile') }}</span>
                </v-tooltip>
                <v-menu bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on">
                            <v-icon color="iconGray" small
                                >mdi-dots-vertical</v-icon
                            >
                        </v-btn>
                    </template>

                    <v-list dense>
                        <!-- === === WEBDAV === === -->
                        <v-list-item
                            @click="webdav(item)"
                            v-if="update()"
                            :disabled="isMicrosoftFile(item.fakeName, item.url)"
                        >
                            <v-list-item-icon class="mr-0">
                                <v-icon small>mdi-file-edit</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    $t('attachments:editFile')
                                }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <!-- === === FILE VERSIONING === ===  -->

                        <v-menu open-on-hover left offset-x>
                            <template v-slot:activator="{ on, attrs }">
                                <v-list-item
                                    v-bind="attrs"
                                    v-on="on"
                                    :disabled="
                                        isMicrosoftFile(item.fakeName, item.url)
                                    "
                                    @click="fileVersioning(item)"
                                >
                                    <v-list-item-icon class="mr-0">
                                        <v-icon small>mdi-source-branch</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{
                                            $t('attachments:fileVersioning')
                                        }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                            <v-list dense>
                                <v-list-item
                                    v-for="version in item.versions"
                                    :key="version._id"
                                >
                                    <v-list-item-icon class="mr-0"
                                        ><v-tooltip bottom>
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-icon
                                                    :color="
                                                        item.fakeName ===
                                                        version.fakeName
                                                            ? 'green'
                                                            : 'black'
                                                    "
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    @click="
                                                        setAsBase(
                                                            item,
                                                            version.fakeName
                                                        )
                                                    "
                                                    small
                                                    >mdi-file-check-outline</v-icon
                                                ></template
                                            ><span>{{
                                                item.fakeName ===
                                                version.fakeName
                                                    ? $t(
                                                          'attachments:baseVersion'
                                                      )
                                                    : $t(
                                                          'attachments:setVersionAsBase'
                                                      )
                                            }}</span></v-tooltip
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            ><v-tooltip bottom>
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                    ><v-btn
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        @click="
                                                            viewFile(version)
                                                        "
                                                        small
                                                        text
                                                        ><span class="mx-2">{{
                                                            `${version.updatedBy.name} ${version.updatedBy.lastname}`
                                                        }}</span
                                                        >{{
                                                            $moment(
                                                                version.updatedAt
                                                            ).format(
                                                                'YYYY-MM-DD HH:mm'
                                                            )
                                                        }}</v-btn
                                                    ></template
                                                ><span>{{
                                                    $t('attachments:preview')
                                                }}</span></v-tooltip
                                            ></v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>

                        <!-- === === COPY === === -->
                        <v-menu open-on-hover left offset-x>
                            <template v-slot:activator="{ on, attrs }">
                                <v-list-item
                                    data-cy="copy"
                                    v-bind="attrs"
                                    v-on="on"
                                    v-if="update()"
                                >
                                    <v-list-item-icon class="mr-0">
                                        <v-icon small>mdi-chevron-left</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{
                                            $t('attachments:copyTo')
                                        }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                            <v-list dense>
                                <v-list-item
                                    data-cy="folderToCopyTo"
                                    v-for="folder in getFoldersItems"
                                    :key="folder._id"
                                    :disabled="isCurrentFolder(folder._id)"
                                    @click="
                                        copyToFolder({
                                            fileID: item._id,
                                            folderID: folder._id,
                                            additional: {
                                                routeName: $route.name,
                                                routeId: $route.params.id,
                                            },
                                        })
                                    "
                                >
                                    <v-list-item-title>{{
                                        folder.name
                                    }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>

                        <!-- === === MOVE === === -->

                        <v-menu open-on-hover left offset-x>
                            <template v-slot:activator="{ on, attrs }">
                                <v-list-item
                                    data-cy="move"
                                    v-if="update()"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-list-item-icon class="mr-0">
                                        <v-icon small>mdi-chevron-left</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{
                                            $t('attachments:moveTo')
                                        }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                            <v-list dense>
                                <v-list-item
                                    data-cy="folderToMoveTo"
                                    v-for="folder in getFoldersItems"
                                    :key="folder._id"
                                    :disabled="isCurrentFolder(folder._id)"
                                    @click="
                                        moveToFolder({
                                            fileID: item._id,
                                            folderID: folder._id,
                                            additional: {
                                                routeName: $route.name,
                                                routeId: $route.params.id,
                                            },
                                        })
                                    "
                                >
                                    <v-list-item-title>{{
                                        folder.name
                                    }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>

                        <!-- === === STORAGE === === -->
                        <v-list-item @click="addToStorage(item)">
                            <v-list-item-icon class="mr-0">
                                <v-icon small>mdi-safe-square-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    $t('attachments:storage')
                                }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <!-- === === DOWNLOAD === === -->
                        <v-list-item
                            @click="download(item)"
                            :disabled="item.url ? true : false"
                        >
                            <v-list-item-icon class="mr-0">
                                <v-icon small>mdi-download</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    $t('attachments:download')
                                }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <!-- === === REMOVE === === -->
                        <v-list-item
                            v-if="remove()"
                            @click="
                                $emit('remove-file-modal', {
                                    id: item._id,
                                })
                            "
                        >
                            <v-list-item-icon class="mr-0">
                                <v-icon small>mdi-delete</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    $t('attachments:remove')
                                }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </td>
        </tr>
    </tbody>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import JWT from 'jsonwebtoken'
export default {
    props: ['items'],
    data() {
        return {
            statuses: [
                {
                    name: this.$t('attachments:current'),
                    value: 'CURRENT',
                },
                {
                    name: this.$t('attachments:archive'),
                    value: 'ARCHIVE',
                },
                {
                    name: this.$t('attachments:draft'),
                    value: 'DRAFT',
                },
                {
                    name: this.$t('attachments:confidential'),
                    value: 'CONFIDENTIAL',
                },
            ],
            // editedFiles: [],
        }
    },

    computed: {
        ...mapGetters([
            'getFoldersItems',
            'getProfileDetails',
            'getCurrentFolder',
            'getFilesRegistryMenager',
            'getSessionId',
        ]),
        setFilesItems() {
            if (
                this.getProfileDetails.isSuperAdmin ||
                this.getProfileDetails.isAdmin ||
                this.getFilesRegistryMenager == this.getProfileDetails._id
            )
                return this.items
            else {
                return this.items.filter(item => {
                    if (['DRAFT', 'CONFIDENTIAL'].includes(item.status)) {
                        // FUNKCJA DO POPRAWY
                        if (
                            item.createdBy == this.getProfileDetails._id ||
                            item.createdBy._id == this.getProfileDetails._id
                        )
                            return item
                    } else return item
                })
            }
        },
    },

    methods: {
        ...mapActions([
            'moveToFolder',
            'copyToFolder',
            'copyToUserStorage',
            'fetchFiles',
            'updateFile',
        ]),
        ...mapMutations(['setAddFromAttachments', 'setFileModal']),
        async toDataURL(url) {
            return fetch(url)
                .then(response => {
                    return response.blob()
                })
                .then(blob => {
                    return URL.createObjectURL(blob)
                })
        },
        read() {
            return this.checkPermissions('READ')
        },
        update() {
            return this.checkPermissions('UPDATE')
        },
        remove() {
            return this.checkPermissions('DELETE')
        },

        async download(file) {
            try {
                const token = JWT.sign(
                    {
                        login: this.getProfileDetails.login,
                        _id: this.getProfileDetails._id,
                        file: file.fakeName,
                    },
                    process.env.VUE_APP_ATTACHMENT_SECRET,
                    { expiresIn: process.env.VUE_APP_ATTACHMENT_EXPIRED }
                )
                let fileLink = document.createElement('a')
                fileLink.href = await this.toDataURL(
                    `${process.env.VUE_APP_WEBDAVAPI_URL}/${process.env.VUE_APP_WEBDAV_PATH}/${file.fakeName}?nt=${token}`
                )
                fileLink.setAttribute('download', file.name)
                document.body.appendChild(fileLink)
                fileLink.click()
                document.body.removeChild(fileLink)
            } catch (err) {
                console.error(err)
            }
        },

        async viewFile(file) {
            try {
                let fileLink = document.createElement('a')
                let ext = file.fakeName.split('.').pop()
                let msExts = ['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx']
                let otherFormats = ['heic']
                const token = JWT.sign(
                    {
                        login: this.getProfileDetails.login,
                        _id: this.getProfileDetails._id,
                        file: file.fakeName,
                    },
                    process.env.VUE_APP_ATTACHMENT_SECRET,
                    { expiresIn: process.env.VUE_APP_ATTACHMENT_EXPIRED }
                )

                if (msExts.includes(ext)) {
                    fileLink.href = `https://view.officeapps.live.com/op/view.aspx?src=${process.env.VUE_APP_WEBDAVAPI_URL}/${process.env.VUE_APP_WEBDAV_PATH}/${file.fakeName}?nt=${token}`
                } else if (otherFormats.includes(ext)) {
                    await this.download(file)
                } else {
                    fileLink.href = `${process.env.VUE_APP_WEBDAVAPI_URL}/${process.env.VUE_APP_WEBDAV_PATH}/${file.fakeName}?nt=${token}`
                }
                fileLink.target = '_blank'
                document.body.appendChild(fileLink)
                fileLink.click()
                document.body.removeChild(fileLink)
            } catch (err) {
                console.error(err)
            }
        },

        viewURL(url) {
            let fileLink = document.createElement('a')
            url.split('://').length < 2
                ? (fileLink.href = `http://${url}`)
                : (fileLink.href = url)
            fileLink.target = '_blank'
            document.body.appendChild(fileLink)
            fileLink.click()
            document.body.removeChild(fileLink)
        },

        statusName(status) {
            let statusToFind = this.statuses.filter(
                item => item.value == status
            )
            if (statusToFind[0]) return statusToFind[0].name
        },
        setIcon(item) {
            let ext = item.fakeName.split('.').pop()
            if (['doc', 'docx'].includes(ext)) return 'mdi-file-word'
            else if (['xls', 'xlsx'].includes(ext)) return 'mdi-file-excel'
            else if (['ppt', 'pptx'].includes(ext)) return 'mdi-file-powerpoint'
            else if (item.url) return 'mdi-open-in-new'
            else return 'mdi-file'
        },
        async addToStorage(item) {
            await this.setAddFromAttachments({
                file: item,
                id: this.getProfileDetails,
                from: 'attachments/data',
            })
            await this.copyToUserStorage()
        },

        async webdav(file) {
            // this.editedFiles.push(file._id)
            let ext = file.fakeName.split('.').pop()
            let prefix = ''
            if (['doc', 'docx'].includes(ext)) prefix = 'ms-word:ofe|u|'
            else if (['xls', 'xlsx'].includes(ext)) prefix = 'ms-excel:ofe|u|'
            else if (['ppt', 'pptx'].includes(ext))
                prefix = 'ms-powerpoint:ofe|u|'
            let folder = this.getFoldersItems.find(folder =>
                folder.files.some(f => f.fakeName === file.fakeName)
            )
            if (!folder) folder = this.getCurrentFolder
            else folder = folder._id

            let response = await new this.Rest(
                'GET',
                `webdav/${this.$route.name}/${this.$route.params.id}/${folder}/${file.fakeName}`
            ).send()

            if (prefix != '') {
                try {
                    const token = JWT.sign(
                        {
                            login: this.getProfileDetails.login,
                            _id: this.getProfileDetails._id,
                            file: response.data.file,
                        },
                        process.env.VUE_APP_ATTACHMENT_SECRET,
                        { expiresIn: process.env.VUE_APP_ATTACHMENT_EXPIRED }
                    )
                    let fileLink = document.createElement('a')
                    fileLink.href = `${prefix}${process.env.VUE_APP_WEBDAVAPI_URL}/${process.env.VUE_APP_WEBDAV_PATH}/${response.data.file}?nt=${token}`
                    fileLink.target = '_blank'
                    document.body.appendChild(fileLink)
                    fileLink.click()
                    document.body.removeChild(fileLink)
                } catch (err) {
                    console.error(err)
                }
            }
            await this.refresh()
            // TUTAJ MA SIE MODAL OTWIERAĆ
        },

        isMicrosoftFile(fakeName, url) {
            let ext, msExts
            if (fakeName) {
                ext = fakeName.split('.').pop()
                msExts = ['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx']
            }
            if (url) return true
            else if (fakeName && msExts.includes(ext)) return false
            else return true
        },

        isCurrentFolder(id) {
            if (id == this.getCurrentFolder) return true
            else return false
        },

        async refresh() {
            await this.fetchFiles({
                routeName: this.$route.name,
                routeId: this.$route.params.id,
                folderID: this.getCurrentFolder,
            })
            // this.editedFiles = []
        },

        fileVersioning(file) {},

        async setAsBase(file, verName) {
            console.log(verName)
            file.fakeName = verName
            this.setFileModal(file)
            await this.updateFile({
                routeId: this.$route.params.id,
                routeName: this.$route.name,
            })
        },
    },
}
</script>

<style lang="sass" scoped>
.fileIcon
    margin-right: 18px
.badge_attachments
    position: absolute
    top: 28px
    left: 36px
    z-index: 10
</style>
