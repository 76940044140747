import Rest from './../../../plugins/Rest'
import clone from 'lodash.clonedeep'
import moment from 'moment'
import i18n from '../../../plugins/i18next'
import merge from 'lodash.merge'
import validation from './../../../plugins/validation'
import clearSearch from './../../../plugins/clearSearch'
import { fillEmptyFields } from './implementationService'
import cloneDeep from 'lodash.clonedeep'
const state = {
    modal: {
        fields: {
            settleTheTransportCheckboxValue: false,
            device: null,
            clientDescription: '',
            logisticianDescription: '',
            contractor: null,
            preferredTechnician: null,
            expectedServiceDate: '',
            expectedServiceTime: '',
            technician: null,
            plannedServiceDate: '',
            plannedServiceTime: '',
            realization: {
                survey: 0,
                repair: 0,
                installation: 0,
                diagnostics: 0,
                itService: 0,
                startDate: null,
                counters: {
                    monoDeviceEnd: null,
                    monoDeviceStart: null,
                    colorDeviceStart: null,
                    colorDeviceEnd: null,
                },
                payment: {},
                requirements: {},
                signature: {
                    confirmService: '',
                    signature: {},
                    date: '',
                },
                dedicatedPrice: 0,
                requirementsCompleted: {},
            },
            realizationCheckboxes: {
                survey: false,
                repair: false,
                installation: false,
                diagnostics: false,
                itService: false,
                firstInstallation: false,
                repairWorkshop: false,
                remoteService: false,
                continuation: false,
                complaint: false,
                urgent: false,
                costInformation: false,
                reception: false,
                supply: false,
            },
            transportConfig: {
                settleFromTheBaseAutomatically: true,
                settleReturn: false,
                settleFromThePreviousPoint: false,
                kilometersToBeSettled: '',
                typeOfAccountingKilometers: 'Stawka za 1 km transportu',
                transportCostToBeSettled: '',
                getGoogleMapValue: '',
            },
            kilometersToBeSettled: '',
            transportCostToBeSettled: '',
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {},
            },
            stepTwo: {
                validation: true,
                fields: { clientDescription: '' },
            },
            stepThree: {
                validation: true,
                fields: { clientDescription: '' },
            },
        },
        implemantationError: {
            countersError: false,
            monoDeviceEnd: null,
            colorDeviceEnd: null,
            carEnd: null,
            rateOneKmTransport: false,
            minRateTransport: false,
            staticRateTransport: false,
        },
        step: 1,
        wasChanged: false,
    },
    request: {
        searchFields: [
            'index',
            'technician',
            'technician.name join technician.lastname',
            'device.model.manufacturer join device.model.model',
            'client.name',
            'device.sn',
            'device.manufacturer',
            'claimNumber',
            'status.name',
        ],
        defaultSearchFields: [
            'index',
            'technician',
            'technician.name join technician.lastname',
            'device.model.manufacturer join device.model.model',
            'client.name',
            'device.sn',
            'device.manufacturer',
            'claimNumber',
            'status.name',
        ],
        populate: [
            {
                localField: 'device',
                foreignField: '_id',
                from: 'devices',
                as: 'device',
            },
            {
                localField: 'device.model',
                foreignField: '_id',
                from: 'models',
                as: 'device.model',
            },
            {
                localField: 'technician',
                foreignField: '_id',
                from: 'users',
                as: 'technician',
            },
        ],
        select:
            '_id claimNumber continuedFrom index contractor realization  device realizationCheckboxes createdAt technician index client status plannedServiceDate plannedServiceTime expectedServiceDate expectedServiceTime isDeleted folders createdByHelpDeskUsers getGoogleMapValue estimates',
        where: {
            isDeleted: false,
        },
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        sort: '',
        pages: 1,
        defaultSort: '-createdAt',
        loading: false,
        isArchive: false,
    },
    statuses: [],
    details: {},
    registry: {},
    dictFields: { servicesDictionary: '' },
    filterCounter: {},
    prevIntervention: {},
}

const getters = {
    getServicesRegistry: state => state.registry,
    getServicesTable: state => state.table,
    getServiceError: state => state.modal.errors,
    getServiceStep: state => state.modal,
    getServiceStatuses: state => state.statuses,
    getServicesSearchFields: state => state.request,
    getServiceModal: state => state.modal.fields,
    getServiceDetails: state => state.details,
    getFilterCounter: state => state.filterCounter,
    getImplementationServiceWasChanged: state => state.modal.wasChanged,
    getServiceImplementationError: state => state.modal.implemantationError,
    getPrevIntervention: state => state.prevIntervention,
}

const actions = {
    async fetchServiceRegistry({ commit, dispatch }, payload) {
        let registry = await new Rest('GET', 'registries')
            .select('currentMenager name availableStatuses icon')
            .where({ url: 'service' })
            .populate([
                {
                    path: 'currentMenager',
                    select: 'name lastname mail status phone picture',
                },
            ])
            .send()

        commit('setServiceStatuses', registry.data.items[0].availableStatuses)
        commit('setServiceRegistry', registry.data.items)
    },
    async fetchAllServices({ commit, dispatch }, payload) {
        state.table.loading = true
        state.table.isArchive = true
        try {
            let response = await new Rest('GET', 'service')
                .select(state.request.select)
                .limit(state.table.limit)
                .page(state.table.page)
                .populate(state.request.populate)
                .search(state.request.searchFields, state.table.search)
                .sort(state.table.sort || state.table.defaultSort)
                .send()

            commit('setServiceTable', response.data)
        } catch (err) {
            console.error(err)
        }
        state.table.loading = false
    },

    async fetchServiceTable(
        { dispatch, commit, getters, rootGetters },
        payload
    ) {
        state.table.loading = true
        state.table.isArchive = false
        let sort, where

        if (
            rootGetters['getProfileDetails'].specialRoleName.includes(
                'technic'
            ) &&
            !rootGetters['getProfileDetails'].specialRoleName.includes(
                'logistic'
            ) &&
            !rootGetters['getProfileDetails'].specialRoleName.includes(
                'logisticDirector'
            )
        ) {
            sort = 'status.name -plannedServiceDate'
            if (!rootGetters['getProfileDetails']._id) {
                commit('setSnackbars', {
                    type: 'error',
                    text: 'Błąd wczytywania id technika',
                })
            }
            where = {
                isDeleted: false,
                'technician._id': rootGetters['getProfileDetails']._id,
                'status.name': { $ne: 'Anulowany' },
                ...state.request.where,
            }
        }

        try {
            let response = await new Rest('GET', 'service')
                .select(state.request.select)
                .limit(state.table.limit)
                .page(state.table.page)
                .search(state.request.searchFields, state.table.search)
                .where(where || state.request.where)
                .populate(state.request.populate)
                .sort(state.table.sort || sort || state.table.defaultSort)
                .addParams({ isService: true })
                .send()

            response.data.items = response.data.items.map(item => {
                return {
                    ...item,
                    checkIsLoading: false,
                }
            })

            commit('setServiceTable', response.data)
            await dispatch('countServiceFilters')
            await dispatch('fetchServiceRegistry')
        } catch (err) {
            console.error(err)
        }
        state.table.loading = false
    },

    async countServiceFilters({ commit, dispatch }, payload) {
        let filters = await new Rest('GET', 'service/countBy')
            .setParams({
                filters: {
                    'realization.requirements.invoice': true,
                    'realization.requirements.claim': true,
                    'realization.requirements.valuation': true,
                    'realization.requirements.continuation': true,
                    'realization.requirements.expertise': true,
                },
            })
            .send()

        commit('setFilterCounter', filters.data)
    },

    async fetchService({ state, commit, dispatch }, payload) {
        commit('clearTransportsErrors')

        try {
            commit('cleanPrevIntervention')
            let serviceReq = new Rest('GET', `service/${payload.id}`)
                .select('-folders')
                .populate({
                    path:
                        'device createdBy technician preferredTechnician createdByHelpDeskUsers',
                    populate: {
                        path: 'model',
                        populate: { path: 'servicePrice ' },
                    },
                })

            let statusesReq = new Rest('GET', `registries/service/statuses`)
                .select('name _id color isDeleted state')
                .where({ isDeleted: false })
            let { 1: statuses, 0: response } = await Promise.all([
                serviceReq.send(),
                statusesReq.send(),
            ])

            let date
            if (
                response.data.realization &&
                response.data.realization.startDate
            )
                date = new Date(response.data.realization.startDate)
            if (!date) date = new Date()
            if (
                response.data.technician &&
                !response.data.carRegistrationNumber
            ) {
                let resp = await new Rest('GET', '/cars')
                    .select('registrationNumber')
                    .where({
                        driver:
                            response.data.technician._id ||
                            response.data.technician,
                    })
                    .send()
                if (resp.data.items.length > 0)
                    response.data.carRegistrationNumber =
                        resp.data.items[0].registrationNumber
            }

            // NADPISANIE W PRZYPADKU BRAKU LICZNIKÓW Z BACKENDU
            if (!response.data.realization.counters) {
                response.data.realization.counters = {
                    monoDeviceEnd: '',
                    monoDeviceStart: '',
                    colorDeviceStart: '',
                    colorDeviceEnd: '',
                }
            }

            commit('setServiceDetails', response.data)

            commit('setServicesError', response.data.device.model.servicePrice)

            try {
                let prevSrv = await new Rest(
                    'GET',
                    `service/${response.data.device._id}/previous`
                )
                    .populate({
                        path: 'technician',
                    })
                    .addParams({ date: date, routeId: payload.id })
                    .send()
                commit('setPrevIntervention', prevSrv.data)
            } catch (err) {
                console.error(err.response.data)
            }

            commit('setServiceStatuses', statuses.data.items)

            if (!state.registry || Object.entries(state.registry) === 0)
                await dispatch('fetchServiceRegistry')
            if (payload.next) payload.next()
        } catch (err) {
            //HANDLE ERRORS
            console.error(err)
        }
    },

    async createService({ commit, dispatch }, payload) {
        try {
            if (state.modal.fields.technician) {
                let response = await new Rest('GET', '/cars')
                    .select('registrationNumber')
                    .where({
                        driver:
                            state.modal.fields.technician._id ||
                            state.modal.fields.technician,
                    })
                    .send()
                if (response.data.items.length > 0)
                    state.modal.fields.carRegistrationNumber =
                        response.data.items[0].registrationNumber
            }
            const res = await new Rest(
                'GET',
                `/devices/${state.modal.fields.device._id}`
            )
                .select('client')
                .send()
            state.modal.fields.client = res.data.client
            // state.modal.fields.device = state.modal.fields.device._id
            let service = await new Rest('POST', 'service')
                .setBody(state.modal.fields)
                .send()
            commit('clearServiceModal')
            commit('clearServiceErrors')

            commit('setSnackbars', { type: 'success' })
            if (service && service.data) {
                return service.data
            }
            return true
        } catch (err) {
            //HANDLE ERRORS

            console.error(err.response.data)
            validation.setErrors(state.modal.errors, err)
            commit('setSnackbars', { type: 'error' })
        }
    },

    async updateService({ commit, dispatch }, payload) {
        try {
            let carRegRemoved =
                state.details.carRegistrationNumber &&
                !state.modal.fields.carRegistrationNumber
            let carRegChanged =
                state.modal.fields.carRegistrationNumber !==
                state.details.carRegistrationNumber
            let technicianChanged
            if (state.modal.fields.technician && state.details.technician)
                technicianChanged =
                    state.modal.fields.technician?._id !==
                    state.details.technician?._id
            else if (state.modal.fields.technician) technicianChanged = true
            if (technicianChanged && !carRegRemoved && !carRegChanged) {
                let response = await new Rest('GET', '/cars')
                    .select('registrationNumber')
                    .where({
                        driver:
                            state.modal.fields.technician?._id ||
                            state.modal.fields.technician,
                    })
                    .send()
                if (response.data.items.length > 0)
                    state.modal.fields.carRegistrationNumber =
                        response.data.items[0].registrationNumber
                else state.modal.fields.carRegistrationNumber = ''
            }
            if (carRegRemoved) state.modal.fields.carRegistrationNumber = ''
            // const {
            //     carEnd,
            //     carStart,
            //     colorDeviceEnd,
            //     colorDeviceStart,
            //     monoDeviceEnd,
            //     monoDeviceStart,
            // } = state.modal.fields.realization.counters
            // const error = i18n.t('service:implementationCountersError')
            // if (
            //     carEnd < carStart ||
            //     colorDeviceEnd < colorDeviceStart ||
            //     monoDeviceEnd < monoDeviceStart
            // ) {
            //     state.modal.implemantationError.countersError = error
            //     throw Error(error)
            // } else {
            //     state.modal.implemantationError.countersError = ''
            // }
            if (state.modal.implemantationError.countersError) {
                commit('setSnackbars', { type: 'error' })
                return false
            }
            const res = await new Rest(
                'GET',
                `/devices/${state.modal.fields.device?._id}`
            )
                .select('client')
                .send()
            state.modal.fields.client = res.data.client

            let updated = await new Rest('PUT', `service/${payload.id}`)
                .setBody(state.modal.fields)
                .send()

            dispatch('fetchService', { id: payload.id })
            commit('setSnackbars', { type: 'success' })
            if (updated) return updated.data
            else return false
        } catch (err) {
            console.error(err)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async saveSignatureModal({ state, commit, dispatch }, payload) {
        state.modal.fields.realization.signature.signature =
            payload.signature.signature

        try {
            await new Rest('PUT', `service/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            dispatch('fetchService', { id: payload.id })
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (error) {
            commit('setSnackbars', { type: 'error' })
        }
    },

    async updateServiceStatus({ commit, dispatch }, payload) {
        commit('setServiceModal')
        commit('setServiceStatus', payload.status)
        try {
            let response = await new Rest('PUT', `service/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            commit('setSnackbars', { type: 'success' })
        } catch (err) {
            console.error(err)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async deleteService({ commit, dispatch }, payload) {
        try {
            await new Rest('DELETE', `service/${payload.id}`).send()
            dispatch('fetchServiceTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            console.error(err)
        }
    },
    async setServiceStep({ commit, dispatch }, payload) {
        commit('SET_SERVICE_STEP', payload)
    },
}

const mutations = {
    setPrevIntervention(state, payload) {
        console.log(payload)
        state.prevIntervention = payload
    },
    cleanPrevIntervention(state) {
        state.prevIntervention = ''
    },
    setServiceRegistry(state, payload) {
        state.registry = payload[0]
    },

    setServiceTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
        for (let service of state.table.items) {
            service.device.client = service.client
        }
    },

    setServiceDetails(state, payload) {
        state.prevIntervention = {}
        state.details = payload
        state.details.device.client = state.details.client
    },
    setServiceStatus(state, payload) {
        state.modal.fields.status = payload
    },
    setServiceModal(state, payload) {
        let obj = payload ? payload : cloneDeep(state.details)

        state.modal.fields = obj

        fillEmptyFields(state.modal.fields)

        if (!state.modal.fields.realization.counters) {
            state.modal.fields.realization.counters = {
                monoDeviceEnd: '',
                monoDeviceStart: '',
                colorDeviceStart: '',
                colorDeviceEnd: '',
            }
        }

        if (!state.modal.fields.realization.payment)
            state.modal.fields.realization.payment = {}
        if (!state.modal.fields.realization.requirements)
            state.modal.fields.realization.requirements = {}
        if (!state.modal.fields.realization.signature)
            state.modal.fields.realization.signature = {
                confirmService: '',
                signature: {},
            }
        state.modal.fields.expectedServiceDate = state.modal.fields
            .expectedServiceDate
            ? moment(obj.expectedServiceDate).format('YYYY-MM-DD')
            : ''
        state.modal.fields.plannedServiceDate = state.modal.fields
            .plannedServiceDate
            ? moment(obj.plannedServiceDate).format('YYYY-MM-DD')
            : ''
        state.modal.fields.realization.startDate = state.modal.fields
            .realization.startDate
            ? moment(obj.realization.startDate).format('YYYY-MM-DD')
            : ''
        state.modal.fields.realization.endDate = state.modal.fields.realization
            .endDate
            ? moment(obj.realization.endDate).format('YYYY-MM-DD')
            : ''
        state.modal.fields.realization.payment.externalDate = state.modal.fields
            .realization.payment.externalDate
            ? moment(obj.realization.payment.externalDate).format('YYYY-MM-DD')
            : ''
        state.modal.fields.realization.payment.internalDate = state.modal.fields
            .realization.payment.internalDate
            ? moment(obj.realization.payment.internalDate).format('YYYY-MM-DD')
            : ''
        // Zakomentowane bo źle przypisywało datę przy otwieraniu modala
        // state.modal.fields.invoiceDate = state.modal.fields.invoiceDate
        //     ? moment(obj.realization.invoiceDate).format('YYYY-MM-DD')
        //     : ''
    },

    setServicesError(state, payload) {
        if (payload.rateOneKmTransport === undefined) {
            state.modal.implemantationError.rateOneKmTransport = true
        }
        if (payload.minRateTransport === undefined) {
            state.modal.implemantationError.minRateTransport = true
        }
        if (payload.staticRateTransport === undefined) {
            state.modal.implemantationError.staticRateTransport = true
        }
    },

    clearServiceErrors(state) {
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step].fields) {
                state.modal.errors[step].fields[key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },

    clearServiceModal(state, payload) {
        state.modal.step = 1
        state.modal.fields = {
            createdByHelpDeskUsers: null,
            device: '',
            clientDescription: '',
            logisticianDescription: '',
            contractor: null,
            preferredTechnician: null,
            expectedServiceDate: '',
            expectedServiceTime: '',
            technician: null,
            plannedServiceDate: '',
            plannedServiceTime: '',
            realization: {
                startDate: null,
            },
            realizationCheckboxes: {
                survey: false,
                repair: false,
                installation: false,
                diagnostics: false,
                itService: false,
                firstInstallation: false,
                repairWorkshop: false,
                remoteService: false,
                continuation: false,
                complaint: false,
                urgent: false,
                costInformation: false,
            },
            isClientOperationLocked: false,
            transportConfig: {
                settleFromTheBaseAutomatically: true,
                settleReturn: false,
                settleFromThePreviousPoint: false,
                kilometersToBeSettled: 0,
                typeOfAccountingKilometers: 'Stawka za 1 km transportu',
                transportCostToBeSettled: 0,
            },
            model: '',
        }
    },

    setServiceSearch(state, payload) {
        state.request.searchFields = payload
    },

    clearServiceTable(state, payload) {
        clearSearch.clearSearchTable(state)
        if (payload && payload.includes('technic')) {
            state.table.sort = 'status.name'
        }
        if (payload && payload.includes('logistic')) {
            state.table.sort = ''
        }
    },

    setServiceStatuses(state, payload) {
        state.statuses = payload
    },

    setServiceWhere(state, payload) {
        state.request.where = payload
    },

    setFilterCounter(state, payload) {
        state.filterCounter = payload
    },

    setImplementationServiceWasChanged(state, payload) {
        state.modal.wasChanged = payload
    },
    clearTransportsErrors(state) {
        state.modal.implemantationError.minRateTransport = false
        state.modal.implemantationError.rateOneKmTransport = false
        state.modal.implemantationError.staticRateTransport = false
    },

    setKmAndCostInServiceDetails(state, payload) {
        state.modal.fields.kilometersToBeSettled = payload.kilometersToBeSettled
        state.modal.fields.transportCostToBeSettled =
            payload.transportCostToBeSettled
    },
    SET_SERVICE_STEP(state, payload) {
        state.modal.step = payload
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
