<template>
    <v-container class="px-0">
        <v-card class="details__main">
            <v-tabs
                show-arrows
                height="60"
                class="details__tabs"
                centered
                v-model="active"
                icons-and-text
            >
                <v-tab href="#information" v-if="informationTabs">
                    {{ $t('global:information') }}
                    <v-icon size="18">mdi-book-information-variant</v-icon>
                </v-tab>

                <v-tab-item id="information">
                    <!-- @slot Use this slot to edit informations content-->
                    <slot name="information"></slot>
                </v-tab-item>
                <!-- @slot Use this slot to add extra tabs content-->
                <v-tab
                    v-for="item in items"
                    :key="item.id"
                    :href="`#${item.id}`"
                >
                    {{ item.name }}
                    <v-badge
                        v-if="item.id === 'parts' && getServicrPartsBadge"
                        color="orange darken-1"
                        class="badge--details"
                        offset-x="-10"
                        offset-y="-12"
                        content=""
                    >
                    </v-badge>
                    <v-badge
                        v-if="item.id === 'estimates'"
                        color="orange darken-1"
                        class="badge--details"
                        offset-x="-10"
                        offset-y="-12"
                        content=""
                    >
                    </v-badge>
                    <v-icon size="18">{{ item.icon }}</v-icon>
                </v-tab>
                <slot name="tabItems"></slot>
                <v-tab
                    href="#attachments"
                    v-if="attachmentsTabs"
                    @click="files()"
                >
                    {{
                        this.$route.name == 'registries'
                            ? $t('global:templates')
                            : $t('global:attachments')
                    }}
                    <v-badge
                        v-if="getRegistryFilesCount"
                        color="#19aa8d"
                        class="badge--details"
                        offset-x="-10"
                        offset-y="-12"
                    >
                    </v-badge>
                    <v-icon size="18">mdi-paperclip</v-icon>
                </v-tab>
                <v-tab-item id="attachments">
                    <!-- @slot Use this slot to edit attachments content-->
                    <slot name="attachments" v-if="active == 'attachments'">
                        <Attachments />
                    </slot>
                </v-tab-item>
                <v-tab href="#alerts" v-if="alertsTabs" @click="alerts()">
                    {{ $t('global:alerts') }}
                    <v-badge
                        v-if="getAlertsCount"
                        color="secondary"
                        class="badge--details"
                        offset-x="-10"
                        offset-y="-12"
                    >
                    </v-badge>
                    <v-icon size="18">mdi-bell-ring</v-icon>
                </v-tab>
                <v-tab-item id="alerts">
                    <!-- @slot Use this slot to alerts tasks content-->
                    <slot name="alerts">
                        <Alerts />
                    </slot>
                </v-tab-item>
                <!-- WYŁĄCZENIE ZADAŃ W APLIKACJI -->
                <!-- <v-tab href="#tasks" v-if="tasksTabs" @click="notes()">
                    {{ $t('global:tasks') }}
                    <v-badge
                        v-if="getTasksCount"
                        color="secondary"
                        class="badge--details"
                        offset-x="-10"
                        offset-y="-12"
                    >
                    </v-badge>
                    <v-icon size="18">mdi-format-list-checks</v-icon>
                </v-tab> -->
                <!-- <v-tab-item id="tasks" v-if="active == 'tasks'"> -->
                <!-- @slot Use this slot to edit tasks content-->
                <!-- <slot name="tasks">
                        <Tasks />
                    </slot> -->
                <!-- </v-tab-item> -->
                <v-tab href="#notes" v-if="notesTabs" @click="notes()">
                    {{ $t('global:notes')
                    }}<v-badge
                        v-if="getNotesCount"
                        color="secondary"
                        class="badge--details"
                        offset-x="-10"
                        offset-y="-12"
                    >
                    </v-badge>
                    <v-icon size="18">mdi-calendar-multiple</v-icon>
                </v-tab>
                <v-tab-item id="notes">
                    <!-- @slot Use this slot to edit note content-->
                    <slot name="notes">
                        <Notes />
                    </slot>
                </v-tab-item>
                <v-tab href="#logs" v-if="logsTabs" @click="logs()">
                    {{ $t('global:logs') }}
                    <v-icon size="18">mdi-folder-cog</v-icon>
                </v-tab>
                <v-tab-item id="logs">
                    <!-- @slot Use this slot to edit logs content-->
                    <slot name="logs">
                        <Logs />
                    </slot>
                </v-tab-item>
            </v-tabs>
        </v-card>
    </v-container>
</template>
<script>
import Attachments from './Tabs/Attachments/Attachments'
import Alerts from './Tabs/Alerts/Central'
//  WYŁĄCZENIE ZADAŃ W APLIKACJI -
// import Tasks from './Tabs/Tasks/Central'
import Notes from './Tabs/Notes/Central'
import Logs from './Tabs/Logs/Central'
import { mapActions, mapGetters } from 'vuex'
/**
 * Tabs main views
 * @displayName Tabs main views
 */
export default {
    props: {
        /**
         * Props to hidden Tasks tabs
         */
        // WYŁĄCZENIE ZADAŃ W APLIKACJI
        // tasksTabs: {
        //     type: Boolean,
        //     default: true,
        // },
        /**
         * Props to hidden Alerts tabs
         */
        alertsTabs: {
            type: Boolean,
            default: true,
        },
        logsTabs: {
            type: Boolean,
            default: true,
        },
        notesTabs: {
            type: Boolean,
            default: true,
        },
        attachmentsTabs: {
            type: Boolean,
            default: true,
        },
        informationTabs: {
            type: Boolean,
            default: true,
        },
        /**
         * Props to hidden Tasks tabs
         */
        // WYŁĄCZENIE ZADAŃ W APLIKACJI
        // tasksTabs: {
        //     type: Boolean,
        //     default: true,
        // },
        /**
         * Props to active tabs
         */
        items: Array,
        /**
         * Props to active tabs
         */
        activeTab: {
            type: String,
            default: 'information',
        },
    },
    i18nOptions: { namespaces: ['global'] },
    data() {
        return {
            active: this.activeTab,
        }
    },
    methods: {
        ...mapActions([
            'fetchAlertsCount',
            'fetchNotesCount',
            'fetchRegistryFilesCount',
            'fetchAlertsTable',
            'fetchNotesTable',
            // WYŁĄCZENIE ZADAŃ W APLIKACJI
            // 'fetchTasksCount',
            // 'fetchTasksTable',
            'fetchLogs',
            // 'fetchServicePartsTab',
        ]),
        async alerts() {
            if (this.alertsTabs) {
                await Promise.all([
                    this.fetchAlertsCount({ id: this.$route.params.id }),
                    this.fetchAlertsTable({ id: this.$route.params.id }),
                ])
            }
        },
        // WYŁĄCZENIE ZADAŃ W APLIKACJI
        // async tasks() {
        //     await Promise.all([
        //         this.fetchTasksCount({ id: this.$route.params.id }),
        //         this.fetchTasksTable({ id: this.$route.params.id }),
        //     ])
        // },
        async notes() {
            if (this.notesTabs) {
                await Promise.all([
                    this.fetchNotesCount({ id: this.$route.params.id }),
                    this.fetchNotesTable({ id: this.$route.params.id }),
                ])
            }
        },
        async files() {
            await this.fetchRegistryFilesCount({
                url: this.$route.name,
                id: this.$route.params.id,
            })
        },
        async logs() {
            await this.fetchLogs({
                id: this.$route.params.id,
                url: this.$route.name,
            })
        },
        // async parts() {
        //     if (this.$route.name !== 'registries') {
        //         await this.fetchServicePartsTab({
        //             id: this.$route.params.id,
        //             badge: true,
        //         })
        //     }
        // },
        async all() {
            try {
                await Promise.all([
                    this.alerts(),
                    this.files(),
                    this.notes(),
                    // WYŁĄCZENIE ZADAŃ W APLIKACJI
                    // this.tasks(),
                    this.logs(),
                    // this.parts(),
                ])
            } catch (e) {
                console.log(e)
            }
        },
    },
    components: {
        Attachments,
        Alerts,
        // WYŁĄCZENIE ZADAŃ W APLIKACJI
        // Tasks,
        Notes,
        Logs,
    },
    computed: {
        ...mapGetters([
            'getAlertsCount',
            'getNotesCount',
            'getRegistryFilesCount',
            // WYŁĄCZENIE ZADAŃ W APLIKACJI
            // 'getTasksCount',
            'getLogsItems',
            'getServicrPartsBadge',
        ]),
        attachmentsName() {
            if (this.$route.name == 'registries')
                return this.$t('global:templates')
            else return this.$t('global:attachments')
        },
    },
    async created() {
        await this.all()
    },
}
</script>
