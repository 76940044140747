<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper
                    v-model="getRentSettlementErrors.step"
                    class="elevation-0"
                >
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () =>
                                        getRentSettlementErrors.stepOne
                                            .validation,
                                ]"
                                editable
                                step="1"
                                >{{ $t('global:basicInfo') }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content class="pb-0 pt-4" step="1">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            :text="$t($t('global:basicInfo'))"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <v-combobox
                                            :disabled="isThatLastSettlement()"
                                            color="red"
                                            v-model="
                                                getRentSettlementModal.device
                                            "
                                            :label="`${$t(
                                                'rent-contracts:rent-annex.device.sn'
                                            )}`"
                                            hint="Zacznij pisać..."
                                            :items="devices"
                                            item-text="sn"
                                            return-object
                                            ref="selectDevice"
                                            @keyup="findDevices($event)"
                                            @change="onChange"
                                            readonly
                                            append-icon=""
                                        >
                                        </v-combobox>
                                    </v-col>
                                </v-row>
                                <v-row
                                    v-if="getRentSettlementModal.device.sn"
                                    style="min-height: 100px;"
                                >
                                    <v-col cols="4">
                                        <v-text-field
                                            :disabled="isThatLastSettlement()"
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-annex.device.model.manufacturer'
                                                )
                                            "
                                            v-model="
                                                getRentSettlementModal.device
                                                    .manufacturer
                                            "
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            :disabled="isThatLastSettlement()"
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-annex.device.model.model'
                                                )
                                            "
                                            v-model="
                                                getRentSettlementModal.device
                                                    .model.model
                                            "
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            :disabled="isThatLastSettlement()"
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-annex.device.model.deviceType'
                                                )
                                            "
                                            v-model="
                                                getRentSettlementModal.device
                                                    .model.deviceType
                                            "
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row style="min-height: 100px;" v-else>
                                </v-row>
                                <v-row>
                                    <v-col cols="3">
                                        <v-text-field
                                            :disabled="isThatLastSettlement()"
                                            readonly
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-annex.prevMonoCounter'
                                                )
                                            "
                                            @keypress="integralNumbers"
                                            v-model="
                                                getRentSettlementModal.prevMonoCounter
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="3" id="CurrentCounters">
                                        <v-text-field
                                            :disabled="isThatLastSettlement()"
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-annex.currentMonoCounter'
                                                )
                                            "
                                            @keypress="integralNumbers"
                                            v-model="
                                                getRentSettlementModal.currentMonoCounter
                                            "
                                            autofocus
                                            @keydown.tab.prevent="
                                                goToNextField(
                                                    $refs.currentColorCounter
                                                )
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field
                                            :disabled="isThatLastSettlement()"
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-annex.monthlyMono'
                                                )
                                            "
                                            readonly
                                            @keypress="integralNumbers"
                                            v-model="
                                                getRentSettlementModal.monthlyMono
                                            "
                                            :error-messages="
                                                getRentSettlementErrors.stepOne
                                                    .fields.monthlyMono
                                            "
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="3">
                                        <v-text-field
                                            :disabled="isThatLastSettlement()"
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-annex.monthlyMonoCost'
                                                )
                                            "
                                            readonly
                                            :value="
                                                cost(
                                                    getRentSettlementModal.monthlyMono,
                                                    getRentSettlementPrice
                                                        .device.monoPrice
                                                ) + ' PLN'
                                            "
                                        >
                                            <template slot="append">
                                                <v-tooltip
                                                    top
                                                    v-if="
                                                        getRentSettlementModal
                                                            .rentDevices
                                                            .monoBundle > 0 ||
                                                        (getRentDeviceTable
                                                            .items[0] &&
                                                            getRentDeviceTable
                                                                .items[0]
                                                                .monoBundle > 0)
                                                    "
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                        }"
                                                    >
                                                        <v-icon
                                                            class="cursor-pointer"
                                                            size="20"
                                                            color="lightgrey"
                                                            v-on="on"
                                                            >mdi-information</v-icon
                                                        >
                                                    </template>
                                                    <span>{{
                                                        $t(
                                                            'rent-contracts:rent-settlement.packageInformation'
                                                        )
                                                    }}</span>
                                                </v-tooltip>
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="3">
                                        <v-text-field
                                            :disabled="isThatLastSettlement()"
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-annex.prevColorCounter'
                                                )
                                            "
                                            @keypress="integralNumbers"
                                            v-model="
                                                getRentSettlementModal.prevColorCounter
                                            "
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="3" id="CurrentCounters">
                                        <v-text-field
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-annex.currentColorCounter'
                                                )
                                            "
                                            @keypress="integralNumbers"
                                            v-model="
                                                getRentSettlementModal.currentColorCounter
                                            "
                                            @keydown.tab.prevent="
                                                goToNextField(
                                                    $refs.currentScanCounter
                                                )
                                            "
                                            :disabled="isThatLastSettlement()"
                                            ref="currentColorCounter"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field
                                            :disabled="isThatLastSettlement()"
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-annex.monthlyColor'
                                                )
                                            "
                                            readonly
                                            @keypress="integralNumbers"
                                            v-model="
                                                getRentSettlementModal.monthlyColor
                                            "
                                            :error-messages="
                                                getRentSettlementErrors.stepOne
                                                    .fields.monthlyColor
                                            "
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="3">
                                        <v-text-field
                                            :disabled="isThatLastSettlement()"
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-annex.monthlyColorCost'
                                                )
                                            "
                                            readonly
                                            :value="
                                                cost(
                                                    getRentSettlementModal.monthlyColor,
                                                    getRentSettlementPrice
                                                        .device.colorPrice
                                                ) + ' PLN'
                                            "
                                            ><template
                                                slot="append"
                                                v-if="
                                                    getRentSettlementModal
                                                        .rentDevices
                                                        .colorBundle > 0 ||
                                                    (getRentDeviceTable
                                                        .items[0] &&
                                                        getRentDeviceTable
                                                            .items[0]
                                                            .colorBundle > 0)
                                                "
                                            >
                                                <v-tooltip top>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                        }"
                                                    >
                                                        <v-icon
                                                            class="cursor-pointer"
                                                            size="20"
                                                            v-on="on"
                                                            color="lightgrey"
                                                            >mdi-information</v-icon
                                                        >
                                                    </template>
                                                    <span>{{
                                                        $t(
                                                            'rent-contracts:rent-settlement.packageInformation'
                                                        )
                                                    }}</span>
                                                </v-tooltip>
                                            </template></v-text-field
                                        >
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="3">
                                        <v-text-field
                                            :disabled="isThatLastSettlement()"
                                            readonly
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-annex.prevScanCounter'
                                                )
                                            "
                                            @keypress="integralNumbers"
                                            v-model="
                                                getRentSettlementModal.prevScanCounter
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="3" id="CurrentCounters">
                                        <v-text-field
                                            :disabled="isThatLastSettlement()"
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-annex.currentScanCounter'
                                                )
                                            "
                                            @keypress="integralNumbers"
                                            v-model="
                                                getRentSettlementModal.currentScanCounter
                                            "
                                            ref="currentScanCounter"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field
                                            :disabled="isThatLastSettlement()"
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-annex.monthlyCosts'
                                                )
                                            "
                                            @keypress="integralNumbers"
                                            readonly
                                            v-model="
                                                getRentSettlementModal.monthlyScan
                                            "
                                            :error-messages="
                                                getRentSettlementErrors.stepOne
                                                    .fields.monthlyScan
                                            "
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="3">
                                        <v-text-field
                                            :disabled="isThatLastSettlement()"
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-annex.monthlyScanCost'
                                                )
                                            "
                                            readonly
                                            :value="
                                                cost(
                                                    getRentSettlementModal.monthlyScan,
                                                    getRentSettlementPrice
                                                        .device.scanPrice
                                                ) + ' PLN'
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <div
                                            class="accounted--font mt-5"
                                            v-if="
                                                getRentSettlementModal.rentSettlementsInstance &&
                                                getRentSettlementModal
                                                    .rentSettlementsInstance
                                                    .createdBy
                                            "
                                        >
                                            <div
                                                v-if="isSettlementManualMode()"
                                            >
                                                <span>{{
                                                    `${$t(
                                                        'rent-contracts:rent-annex.accounted'
                                                    )}:`
                                                }}</span>
                                                <span class="mx-1"
                                                    >{{
                                                        `${$get(
                                                            getProfileDetails,
                                                            'name',
                                                            ''
                                                        )} ${$get(
                                                            getProfileDetails,
                                                            'lastname',
                                                            ''
                                                        )}`
                                                    }}
                                                </span>
                                                <span class="mx-1">{{
                                                    `- ${$moment().format(
                                                        `HH:mm`
                                                    )}`
                                                }}</span>
                                                <span class="mx-1">{{
                                                    `${$moment().format(
                                                        `DD/MM/YYYY`
                                                    )}`
                                                }}</span>
                                            </div>
                                            <div
                                                v-if="!isSettlementManualMode()"
                                            >
                                                <span>{{
                                                    `${$t(
                                                        'rent-contracts:rent-annex.accounted'
                                                    )}:`
                                                }}</span>

                                                <span class="mx-1"
                                                    >{{
                                                        `${$get(
                                                            getRentSettlementModal,
                                                            'rentSettlementsInstance.createdBy.name',
                                                            ''
                                                        )} ${$get(
                                                            getRentSettlementModal,
                                                            'rentSettlementsInstance.createdBy.lastname',
                                                            ''
                                                        )}`
                                                    }}
                                                </span>
                                                <span class="mx-1">{{
                                                    `- ${$moment(
                                                        getRentSettlementModal
                                                            .rentSettlementsInstance
                                                            .createdAt
                                                    ).format(`HH:mm`)}`
                                                }}</span>
                                                <span class="mx-1">{{
                                                    `${$moment(
                                                        getRentSettlementModal
                                                            .rentSettlementsInstance
                                                            .createdAt
                                                    ).format(`DD/MM/YYYY`)}`
                                                }}</span>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    props: { edit: { type: Boolean }, lastSettlementInfo: '', currentMode: '' },
    data() {
        return {
            direction: 'left',
            fab: false,
            transition: 'slide-x-reverse-transition',
            monthlyMonoError: '',
            monthlyColorError: '',
            monthlyScanError: '',
            devices: [],
        }
    },
    computed: {
        ...mapGetters([
            'getRentSettlementErrors',
            'getRentSettlementModal',
            'getDevicesTable',
            'getRentSettlementPrice',
            'getRentDeviceTable',
            'getProfileDetails',
        ]),
    },

    methods: {
        ...mapActions(['setIsSettlementedManually']),

        isSettlementManualMode() {
            if (this.getCurrentMode() === 'SETTLEMENT_MANUAL') {
                return true
            }

            return false
        },
        getCurrentMode() {
            return this.currentMode
        },
        isThatLastSettlement() {
            if (this.getCurrentMode() == 'SETTLEMENT_MANUAL') {
                return false
            }

            const lastSettlementId = this.lastSettlementInfo?._id
            if (
                lastSettlementId &&
                lastSettlementId ===
                    this.getRentSettlementModal.rentSettlementsInstance?._id
            ) {
                return false
            } else {
                return true
            }
        },
        findDevices(e) {
            e.preventDefault()
            if (e.target.value && e.target.value.length > 3) {
                this.devices = this.getDevicesTable.items
            } else {
                this.devices = []
            }
        },
        onChange() {
            this.devices = []
        },
        integralNumbers($event) {
            // CALL MANUAL SETTING UP
            this.setIsSettlementedManually()
            if ($event.target.value[0] == 0)
                $event.target.value = $event.target.value.slice(1)
            let keyCode = $event.keyCode ? $event.keyCode : $event.which
            if (keyCode < 48 || keyCode > 57) $event.preventDefault()
        },
        goToNextField(ref) {
            ref.focus()
        },
        cost(param1, param2) {
            let res = (param1 * param2).toFixed(2)
            return res
        },
    },
    watch: {
        'getRentSettlementModal.prevColorCounter': function (val) {
            this.getRentSettlementModal.monthlyColor =
                this.getRentSettlementModal.currentColorCounter > val
                    ? this.getRentSettlementModal.currentColorCounter - val
                    : 0
        },
        'getRentSettlementModal.prevMonoCounter': function (val) {
            this.getRentSettlementModal.monthlyMono =
                this.getRentSettlementModal.currentMonoCounter > val
                    ? this.getRentSettlementModal.currentMonoCounter - val
                    : 0
        },
        'getRentSettlementModal.prevScanCounter': function (val) {
            this.getRentSettlementModal.monthlyScan =
                this.getRentSettlementModal.currentScanCounter > val
                    ? this.getRentSettlementModal.currentScanCounter - val
                    : 0
        },
        'getRentSettlementModal.currentColorCounter': function (val) {
            this.getRentSettlementModal.monthlyColor =
                this.getRentSettlementModal.prevColorCounter < val
                    ? val - this.getRentSettlementModal.prevColorCounter
                    : 0
        },
        'getRentSettlementModal.currentMonoCounter': function (val) {
            this.getRentSettlementModal.monthlyMono =
                this.getRentSettlementModal.prevMonoCounter < val
                    ? val - this.getRentSettlementModal.prevMonoCounter
                    : 0
        },
        'getRentSettlementModal.currentScanCounter': function (val) {
            this.getRentSettlementModal.monthlyScan =
                this.getRentSettlementModal.prevScanCounter < val
                    ? val - this.getRentSettlementModal.prevScanCounter
                    : 0
        },
    },
}
</script>
<style scoped lang="scss">
#CurrentCounters {
    background: #eaecd1;
    position: relative;
}

.SeedComponent {
    width: max-content;
    border-radius: 50%;
    position: absolute;
    right: -10px;
    top: -10px;
}

.accounted--font {
    font-size: 12px;
    color: grey;
}
</style>
