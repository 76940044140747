<template>
    <v-container style="height: 100%;" class="pt-0">
        <v-row style="height: 100%;">
            <v-col style="height: 100%;" cols="12" class="pa-0">
                <v-stepper style="height: 100%;" class="elevation-0">
                    <template style="height: 100%;">
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getRentAnnexErrors.stepOne.validation,
                                ]"
                                editable
                                step="1"
                                >{{ $t('global:basicInfo') }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getRentAnnexErrors.stepTwo.validation,
                                ]"
                                editable
                                step="2"
                                >{{
                                    $t('rent-contracts:rent-info.contractInfo')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () =>
                                        getRentAnnexErrors.stepThree.validation,
                                ]"
                                editable
                                step="3"
                                >Ustawienia</v-stepper-step
                            >

                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () =>
                                        getRentAnnexErrors.stepThree.validation,
                                ]"
                                editable
                                step="4"
                                >{{
                                    $t('rent-contracts:rent-info.notes')
                                }}</v-stepper-step
                            >
                        </v-stepper-header>
                        <v-stepper-items
                            class="itemsContent"
                            style="height: 100%;"
                        >
                            <v-stepper-content
                                style="
                                    elevation: 0;
                                    display: flex;
                                    flex-direction: column;
                                "
                                class="pb-0 pt-4"
                                step="1"
                            >
                                <v-row>
                                    <Separator
                                        :text="$t($t('global:basicInfo'))"
                                    />
                                </v-row>
                                <v-col>
                                    <v-row>
                                        <v-col cols="5">
                                            <v-text-field
                                                dense
                                                v-model="
                                                    getRentAnnexModal.title
                                                "
                                                :label="`${$t(
                                                    'rent-contracts:rent-info.title'
                                                )}`"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="3" offset="1">
                                            <v-menu
                                                v-model="datepickerStart"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                nudge-bottom="45"
                                                min-width="290px"
                                            >
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                >
                                                    <v-text-field
                                                        v-model="
                                                            getRentAnnexModal.startDate
                                                        "
                                                        dense
                                                        :label="
                                                            $t(
                                                                'rent-contracts:rent-info.startDate'
                                                            )
                                                        "
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        prepend-icon="mdi-calendar"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-model="
                                                        getRentAnnexModal.startDate
                                                    "
                                                    @input="
                                                        datepickerStart = false
                                                    "
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-menu
                                                v-model="datepickerExpiration"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                nudge-bottom="45"
                                                min-width="290px"
                                            >
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                >
                                                    <v-text-field
                                                        v-model="
                                                            getRentAnnexModal.expirationDate
                                                        "
                                                        dense
                                                        :label="
                                                            $t(
                                                                'rent-contracts:rent-info.expirationDate'
                                                            )
                                                        "
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        prepend-icon="mdi-calendar"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-model="
                                                        getRentAnnexModal.expirationDate
                                                    "
                                                    @input="
                                                        datepickerExpiration = false
                                                    "
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col>
                                    <v-row>
                                        <v-col cols="3">
                                            <v-text-field
                                                :disabled="contractNoDisabled"
                                                :readonly="!isManager()"
                                                v-model="
                                                    getLastValueRentInfo.contractNo
                                                "
                                                :label="`${$t(
                                                    'rent-contracts:rent-info.contractNo'
                                                )}*`"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-text-field
                                                v-model="
                                                    getLastValueRentInfo.externalContractNo
                                                "
                                                :label="`${$t(
                                                    'rent-contracts:rent-info.externalContractNo'
                                                )}*`"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-text-field
                                                v-model="
                                                    getRentAnnexModal.contractAnnexNo
                                                "
                                                :label="`${$t(
                                                    'rent-contracts:rent-annex.currentContractAnnexNo'
                                                )}*`"
                                                :error-messages="
                                                    getRentAnnexErrors.stepOne
                                                        .fields.contractAnnexNo
                                                "
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col class="my-2">
                                    <v-row>
                                        <v-col cols="9">
                                            <v-combobox
                                                dense
                                                v-model="
                                                    getRentAnnexModal.contractor
                                                "
                                                :label="`${$t(
                                                    'rent-contracts:rent-info.contractor'
                                                )}*`"
                                                hint="Zacznij pisać..."
                                                @keyup="
                                                    setContacts(
                                                        $event.target.value
                                                    )
                                                "
                                                @input="assignID()"
                                                :hide-no-data="hideContacts"
                                                :items="getContactsInput.items"
                                                item-text="name"
                                                item-value="_id"
                                                return-object
                                            >
                                            </v-combobox>
                                        </v-col>
                                        <v-col>
                                            <v-checkbox
                                                class="push-top"
                                                v-model="purchaserCheckbox"
                                                :label="`${$t(
                                                    'rent-contracts:rent-info.buyer'
                                                )}`"
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="9">
                                            <v-combobox
                                                :label="`${$t(
                                                    'rent-contracts:rent-info.representative'
                                                )}`"
                                                v-model="
                                                    getRentAnnexModal.representative
                                                "
                                                @keyup="
                                                    setRepresentatives(
                                                        $event.target.value
                                                    )
                                                "
                                                :item-text="text"
                                                return-object
                                                append-outer-icon="mdi-account-plus"
                                                item-value="_id"
                                                :items="
                                                    getRepresentativeInput.items
                                                "
                                                dense
                                                ><template
                                                    slot="selection"
                                                    slot-scope="data"
                                                >
                                                    {{ data.item.name }} •
                                                    {{ data.item.lastname }} •
                                                    {{ data.item.email }} •
                                                    {{ data.item.phoneNumber }}
                                                </template>
                                                <template
                                                    slot="item"
                                                    slot-scope="data"
                                                >
                                                    {{ data.item.name }} •
                                                    {{ data.item.lastname }} •
                                                    {{ data.item.email }} •
                                                    {{ data.item.phoneNumber }}
                                                </template>
                                                <template slot="append-outer">
                                                    <v-menu
                                                        :close-on-content-click="
                                                            false
                                                        "
                                                        :close-on-click="false"
                                                        @input="
                                                            v =>
                                                                v ||
                                                                clearMiniModal()
                                                        "
                                                        max-width="300"
                                                        min-width="300"
                                                        origin="center"
                                                        v-model="showMenu"
                                                    >
                                                        <template
                                                            v-slot:activator="{
                                                                on: menu,
                                                            }"
                                                        >
                                                            <v-tooltip top>
                                                                <template
                                                                    v-slot:activator="{
                                                                        on: tooltip,
                                                                    }"
                                                                >
                                                                    <v-btn
                                                                        small
                                                                        icon
                                                                    >
                                                                        <v-icon
                                                                            v-on="{
                                                                                ...menu,
                                                                                ...tooltip,
                                                                            }"
                                                                            @click="
                                                                                showMenu = true
                                                                            "
                                                                            >mdi-plus</v-icon
                                                                        >
                                                                    </v-btn>
                                                                </template>
                                                                <span>{{
                                                                    $t(
                                                                        'global:addRepresentative'
                                                                    )
                                                                }}</span>
                                                            </v-tooltip>
                                                        </template>
                                                        <v-card>
                                                            <v-container>
                                                                <v-row>
                                                                    <v-col
                                                                        cols="12"
                                                                    >
                                                                        <h3>
                                                                            {{
                                                                                `${$t(
                                                                                    'global:additionRepresentative'
                                                                                )}`
                                                                            }}
                                                                        </h3>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col
                                                                        cols="12"
                                                                    >
                                                                        <v-text-field
                                                                            dense
                                                                            v-model="
                                                                                getRepresentativeModal.name
                                                                            "
                                                                            :label="`${$t(
                                                                                'global:representativeName'
                                                                            )}`"
                                                                        >
                                                                        </v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col
                                                                        cols="12"
                                                                    >
                                                                        <v-text-field
                                                                            dense
                                                                            v-model="
                                                                                getRepresentativeModal.lastname
                                                                            "
                                                                            :label="`${$t(
                                                                                'global:representativeLastname'
                                                                            )}`"
                                                                        >
                                                                        </v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col
                                                                        cols="12"
                                                                    >
                                                                        <v-text-field
                                                                            dense
                                                                            
                                                                            v-model="
                                                                                getRepresentativeModal.email
                                                                            "
                                                                            :label="`${$t(
                                                                                'global:representativeEmail'
                                                                            )}`"
                                                                        >
                                                                        </v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col
                                                                        cols="12"
                                                                    >
                                                                        <v-text-field
                                                                            dense
                                                                            v-model="
                                                                                getRepresentativeModal.phoneNumber
                                                                            "
                                                                            :label="`${$t(
                                                                                'global:representativePhoneNumber'
                                                                            )}`"
                                                                        >
                                                                        </v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col
                                                                        md="12"
                                                                        class="py-0 my-0 d-flex justify-end"
                                                                    >
                                                                        <v-btn
                                                                            class="buttons buttons--cancel mr-2"
                                                                            @click="
                                                                                clearMiniModal()
                                                                            "
                                                                            >{{
                                                                                $t(
                                                                                    'cancel'
                                                                                )
                                                                            }}</v-btn
                                                                        >
                                                                        <v-btn
                                                                            class="buttons buttons--add"
                                                                            :disabled="
                                                                                disabled
                                                                            "
                                                                            @click="
                                                                                additionRepresentative()
                                                                            "
                                                                            >{{
                                                                                $t(
                                                                                    'add'
                                                                                )
                                                                            }}</v-btn
                                                                        >
                                                                    </v-col>
                                                                </v-row>
                                                            </v-container>
                                                        </v-card>
                                                    </v-menu>
                                                </template>
                                            </v-combobox>
                                        </v-col>
                                        <v-col cols="9">
                                            <v-combobox
                                                v-if="!purchaserCheckbox"
                                                dense
                                                v-model="
                                                    getLastValueRentInfo.contractorBuyer
                                                "
                                                :label="`Kontrahent (nabywca)`"
                                                hint="Zacznij pisać..."
                                                @keyup="
                                                    setContacts(
                                                        $event.target.value
                                                    )
                                                "
                                                @input="assignID()"
                                                :hide-no-data="hideContacts"
                                                :items="getContactsInput.items"
                                                item-text="name"
                                                item-value="_id"
                                                return-object
                                            >
                                            </v-combobox>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col class="mt-15">
                                    <v-row style="display: flex;">
                                        <v-col cols="6">
                                            <v-select
                                                v-model="
                                                    getRentAnnexModal.contractType
                                                "
                                                :items="
                                                    getRegistryDictionaries.contractType
                                                        ? getRegistryDictionaries.contractType
                                                        : []
                                                "
                                                dense
                                                :label="`${$t(
                                                    'rent-contracts:rent-info.contractType'
                                                )}*`"
                                                return-object
                                                item-value="field"
                                                ><template v-slot:item="data">
                                                    <span
                                                        v-if="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                    >
                                                        <v-chip
                                                            class="mx-2"
                                                            :color="
                                                                data.item
                                                                    .backgroundColor
                                                            "
                                                            small
                                                            label
                                                            :text-color="
                                                                data.item
                                                                    .textColor
                                                                    ? data.item
                                                                          .textColor
                                                                    : '#4d4d4d'
                                                            "
                                                        >
                                                            {{
                                                                data.item.field
                                                            }}
                                                        </v-chip>
                                                    </span>
                                                    <span
                                                        class="truncate-list"
                                                        v-else
                                                    >
                                                        {{ data.item.field }}
                                                    </span>
                                                </template>
                                                <template
                                                    v-slot:selection="data"
                                                >
                                                    <span
                                                        v-if="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                    >
                                                        <v-chip
                                                            class="mx-2"
                                                            :color="
                                                                data.item
                                                                    .backgroundColor
                                                            "
                                                            small
                                                            label
                                                            :text-color="
                                                                data.item
                                                                    .textColor
                                                                    ? data.item
                                                                          .textColor
                                                                    : '#4d4d4d'
                                                            "
                                                        >
                                                            {{
                                                                data.item.field
                                                            }}
                                                        </v-chip>
                                                    </span>
                                                    <span
                                                        class="truncate-list"
                                                        v-else
                                                    >
                                                        {{ data.item.field }}
                                                    </span>
                                                </template>
                                            </v-select>
                                        </v-col>

                                        <v-col cols="6">
                                            <v-select
                                                v-model="
                                                    getRentAnnexModal.customerCarer
                                                "
                                                :label="
                                                    $t(
                                                        'rent-contracts:rent-info.customerCarer'
                                                    )
                                                "
                                                dense
                                                :items="getUsersList"
                                                item-value="_id"
                                                return-object
                                            >
                                                <template
                                                    v-slot:selection="selection"
                                                >
                                                    <span>{{
                                                        selection.item.name
                                                    }}</span>
                                                    <span class="ml-1">{{
                                                        selection.item.lastname
                                                    }}</span>
                                                </template>
                                                <template v-slot:item="item">
                                                    <span>{{
                                                        item.item.name
                                                    }}</span>
                                                    <span class="ml-1">{{
                                                        item.item.lastname
                                                    }}</span>
                                                </template>
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-stepper-content>
                            <v-stepper-content class="pb-0 pt-4" step="2">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            :text="
                                                $t(
                                                    'rent-contracts:rent-info.contractInfo'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="3">
                                        <v-checkbox
                                            :disabled="
                                                getAnnexStatus() === 'Aktywny'
                                            "
                                            v-model="
                                                getRentAnnexModal.startBundle
                                            "
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-info.promotionalPeriod'
                                                )
                                            "
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            :disabled="
                                                !getRentAnnexModal.startBundle ||
                                                getAnnexStatus() === 'Aktywny'
                                            "
                                            v-model="
                                                getRentAnnexModal.numberOfPromotionalPeriods
                                            "
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-info.numberOfPromotionalPeriod'
                                                )
                                            "
                                            :error-messages="
                                                getRentAnnexErrors.stepTwo
                                                    .fields
                                                    .numberOfPromotionalPeriods
                                            "
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>

                                <!-- <v-col cols="12" class="py-0">
                                    <v-row>
                                        <v-col cols="12" class="py-0">
                                            <v-checkbox v-model="
                                                getRentAnnexModal.startBundle
                                            " :label="
    $t(
        'rent-contracts:rent-info.startBundle'
    )
"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4">
                                            <v-text-field :disabled="
                                                !getRentAnnexModal.startBundle
                                            " @keypress="integralNumbers" v-model="
    getRentAnnexModal.monoBundle
" :label="`${$t(
    'rent-contracts:rent-info.monoBundle'
)}`">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="3">
                                            <money-field :disabled="
                                                !getRentAnnexModal.startBundle
                                            " class="margin--input" :readonly="false" v-model="
    getRentAnnexModal.monoBundleCost
" :label="`${$t(
    'rent-contracts:rent-info.monoBundleCost'
)}`"></money-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4">
                                            <v-text-field :disabled="
                                                !getRentAnnexModal.startBundle
                                            " @keypress="integralNumbers" v-model="
    getRentAnnexModal.colorBundle
" :label="`${$t(
    'rent-contracts:rent-info.colorBundle'
)}`">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="3">
                                            <money-field :disabled="
                                                !getRentAnnexModal.startBundle
                                            " class="margin--input" :readonly="false" v-model="
    getRentAnnexModal.colorBundleCost
" :label="`${$t(
    'rent-contracts:rent-info.colorBundleCost'
)}`"></money-field>
                                        </v-col>
                                    </v-row>
                                </v-col> -->
                                <!-- Zakomentowane wg zadania SER-242 -->
                                <!-- <v-col cols="12" class="py-0">
                                    <v-row>
                                        <v-col cols="12" class="py-0">
                                            <v-checkbox
                                                v-model="
                                                    getRentAnnexModal.constMonthlyBundle
                                                "
                                                :label="
                                                    $t(
                                                        'rent-contracts:rent-info.constMonthlyBundle'
                                                    )
                                                "
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4">
                                            <v-text-field
                                                :disabled="
                                                    !getRentAnnexModal.constMonthlyBundle
                                                "
                                                @keypress="integralNumbers"
                                                v-model="
                                                    getRentAnnexModal.constMonoBundle
                                                "
                                                :label="`${$t(
                                                    'rent-contracts:rent-info.constMonoBundle'
                                                )}`"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="3">
                                            <money-field
                                                :disabled="
                                                    !getRentAnnexModal.constMonthlyBundle
                                                "
                                                class="margin--input"
                                                :readonly="false"
                                                v-model="
                                                    getRentAnnexModal.constMonoBundlePrice
                                                "
                                                :label="`${$t(
                                                    'rent-contracts:rent-info.constMonoBundlePrice'
                                                )}`"
                                            ></money-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4">
                                            <v-text-field
                                                :disabled="
                                                    !getRentAnnexModal.constMonthlyBundle
                                                "
                                                @keypress="integralNumbers"
                                                v-model="
                                                    getRentAnnexModal.constColorBundle
                                                "
                                                :label="`${$t(
                                                    'rent-contracts:rent-info.constColorBundle'
                                                )}`"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="3">
                                            <money-field
                                                class="margin--input"
                                                :disabled="
                                                    !getRentAnnexModal.constMonthlyBundle
                                                "
                                                :readonly="false"
                                                v-model="
                                                    getRentAnnexModal.constColorBundlePrice
                                                "
                                                :label="`${$t(
                                                    'rent-contracts:rent-info.constColorBundlePrice'
                                                )}`"
                                            ></money-field>
                                        </v-col>
                                    </v-row>
                                </v-col> -->
                            </v-stepper-content>
                            <v-stepper-content class="pb-0 pt-4" step="3">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator :text="'Ustawienia'" />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="3">
                                        <v-text-field
                                            dense
                                            ref="accountingDay"
                                            @keypress="integralNumbers"
                                            v-model="
                                                getRentAnnexModal.accountingDay
                                            "
                                            :label="`${$t(
                                                'rent-contracts:rent-info.accountingDay'
                                            )}`"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col offset="1" cols="3">
                                        <v-text-field
                                            v-model="
                                                getRentAnnexModal.paymentDeadline
                                            "
                                            dense
                                            :label="`${$t(
                                                'rent-contracts:rent-info.paymentDeadline'
                                            )}`"
                                            prepend-icon="mdi-calendar"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-select
                                            v-model="
                                                getRentAnnexModal
                                                    .representativeSelectedEmail
                                            "
                                            :label="`${$t(
                                                'rent-contracts:represnetativeEmail'
                                            )}`"
                                            @keyup="
                                                setRepresentatives(
                                                    $event.target.value
                                                )
                                            "
                                        
                                            item-text="email"
                                            item-value="email"
                                            :items="
                                                getRepresentativeInputEmails()
                                            "
                                            dense
                                        >
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <v-select
                                            v-model="
                                                getRentAnnexModal.responseTime
                                            "
                                            :items="
                                                getRegistryDictionaries.responseTime
                                                    ? getRegistryDictionaries.responseTime
                                                    : []
                                            "
                                            :label="`${$t(
                                                'rent-contracts:rent-info.responseTime'
                                            )}`"
                                            return-object
                                            item-value="field"
                                            ><template v-slot:item="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                            <template v-slot:selection="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="3">
                                        <money-field
                                            class="margin--input"
                                            :disabled="false"
                                            :readonly="false"
                                            v-model="
                                                getRentAnnexModal.implementationCost
                                            "
                                            :label="`${$t(
                                                'rent-contracts:rent-info.implementationCost'
                                            )}`"
                                        ></money-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="3">
                                        <v-text-field
                                            dense
                                            v-model="
                                                getRentAnnexModal.BIDProject
                                            "
                                            :label="`${$t(
                                                'rent-contracts:rent-info.BIDProject'
                                            )}`"
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content class="pb-0 pt-4" step="4">
                                <div class="simple-grid">
                                    <div class="row1">
                                        <Separator
                                            :text="
                                                $t(
                                                    'rent-contracts:rent-info.notes'
                                                )
                                            "
                                        />
                                    </div>
                                    <div class="row2">
                                        <v-textarea
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-info.notes'
                                                )
                                            "
                                            auto-grow
                                            v-model="getRentAnnexModal.notes"
                                        >
                                        </v-textarea>
                                    </div>
                                </div>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { GlobalActions } from '../../../../../../plugins/constsTypes'

export default {
    i18nOptions: { namespaces: ['global'] },
    data() {
        return {
            hideContacts: true,
            disabled: false,
            showMenu: false,
            step: 1,
            editable: true,
            checked: false,
            datepickerStart: false,
            datepickerExpiration: false,
            startContractNo: '',
            GlobalActions: GlobalActions,
            contractNoDisabled: false,
            purchaserCheckbox: true,
            autoGrowHack: false,

            valid: true,
        }
    },
    props: ['action'],
    methods: {
        ...mapActions([
            'fetchContactsInputs',
            'createRepresentative',
            'fetchRepresentativesInput',
        ]),
        ...mapMutations(['setContactsInput']),
        text: item => item.name + item.lastname,
        setContacts(search) {
            if (search.length >= 4) {
                this.setContactsInput({ search })
                this.fetchContactsInputs()
                this.hideContacts = false
            } else {
                this.getContactsInput.items = []
                this.hideContacts = true
            }
        },
        assignID() {
            if (
                this.getRentAnnexModal.contractor &&
                this.getRentAnnexModal.contractor._id
            ) {
                this.getRentAnnexModal.representative = null
                this.fetchRepresentativesInput({
                    id: this.getRentAnnexModal.contractor._id,
                })
            }
        },
        async additionRepresentative() {
            this.disabled = true
            let success = await this.createRepresentative({
                id: this.getRentAnnexModal.contractor._id,
            })
            if (success) {
                this.clearMiniModal()
                this.assignID()
            }

            this.disabled = false
        },
        clearMiniModal() {
            this.showMenu = false
        },
        integralNumbers($event) {
            if ($event.target.value[0] == 0)
                $event.target.value = $event.target.value.slice(1)

            let keyCode = $event.keyCode ? $event.keyCode : $event.which
            if (keyCode < 48 || keyCode > 57) $event.preventDefault()
        },
        isManager() {
            const { _id, isAdmin, isSuperAdmin } = this.getProfileDetails
            return (
                _id === this.getRentAnnexRegistry.currentMenager._id ||
                isAdmin ||
                isSuperAdmin
            )
        },
        getAnnexStatus() {
            return this.getLastValueRentInfo.annex.status.name
        },
        getRepresentativeInputEmails() {
            const represntativesWithEmail = this.getRepresentativeInput.items.filter(
                r => Boolean(r.email)
            )
            return represntativesWithEmail || [];
        },
    },
    computed: {
        ...mapGetters([
            'getLastValueRentInfo',
            'getProfileDetails',
            'getRentAnnexRegistry',
            'getUsersList',
            'getRentAnnexModal',
            'getRentAnnexErrors',
            'getRegistryDictionaries',
            'getContactsInput',
            'getRepresentativeInput',
            'getRepresentativeModal',
        ]),
        getRepresenativeEmail: {
            get() {
                if (this.getRentAnnexModal.representative?.email) {
                    return this.getRentAnnexModal.representative?.email
                } else {
                    return 'Brak podanego e-mail'
                }
            },
        },
        
    },
    watch: {
        'getRentAnnexModal.accountingDay': function (val) {
            if (val == 0) this.$refs.accountingDay.reset()
            if (val > 31 || val < 1) this.getRentAnnexModal.accountingDay = null
        },
        'getRentAnnexModal.contractor': function (el) {
            const customerCarer = this.$get(el, 'clientGuardian._id', null)
            if (customerCarer) {
                this.getRentAnnexModal.customerCarer = customerCarer
            }
        },
    },
    mounted() {
        this.startContractNo = this.getRentAnnexModal.contractNo
        if (this.action == GlobalActions.update) {
            this.contractNoDisabled = true
        }
    },
}
</script>
<style lang="scss" scoped>
.simple-grid {
    display: grid;
    grid-template-rows: 40px 500px;
}
</style>