import Rest from './../../../plugins/Rest'
import validation from './../../../plugins/validation'
import { clone } from 'lodash'
import moment from 'moment'
import store from '../../../store/index'
import sendMail from './../../../plugins//sendInfoMail'
import clearSearch from './../../../plugins/clearSearch'
const state = {
    modal: {
        fields: {
            reason: '',
            contractor: '',
            description: '',
            responsible: '',
            to: {
                users: [],
                departments: [],
            },
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {
                    reason: '',
                    contractor: '',
                    responsible: '',
                    to: '',
                },
            },
        },
        step: 1,
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: '-createdAt',
        loading: false,
    },
    request: {
        unwindExeptions: ['responsible'],
        populate: [
            {
                localField: 'contractor',
                foreignField: '_id',
                from: 'contacts',
                as: 'contractor',
            },
            {
                localField: 'createdBy',
                foreignField: '_id',
                from: 'users',
                as: 'createdBy',
            },
            // {
            //     from: 'users',
            //     localField: 'to.users',
            //     foreignField: '_id',
            //     as: 'to.users',
            // },
            // {
            //     from: 'departments',
            //     localField: 'to.departments',
            //     foreignField: '_id',
            //     as: 'to.departments',
            // },
            {
                localField: 'responsible',
                foreignField: '_id',
                from: 'users',
                as: 'responsible',
            },
        ],
        // responsible responsible.name responsible.lastname responsible.phone responsible.mail
        searchFields: ['index', 'contractor.name'],
        defaultSearchFields: ['index', 'contractor.name'],
        select:
            '_id to.users.name to.users.lastname to.department reason createdAt responsible updatedAt status createdBy index contractor folders isDeleted',
        where: {
            isDeleted: false,
        },
    },
    dictFields: {
        reason: '',
    },
    statuses: [],
    details: {},
    registry: {},
}

const getters = {
    getOperationLockModal: state => state.modal.fields,
    getOperationLockRegistry: state => state.registry,
    getOperationLockTable: state => state.table,
    getOperationLockSearchFields: state => state.request,
    getOperationLockErrors: state => state.modal.errors,
    getOperationLockStep: state => state.modal,
    getOperationLockStatuses: state => state.statuses,
    getOperationLockDetails: state => state.details,
}

const actions = {
    async sendOperationLockInfo() {
        const users = []
        for (let user of state.details.responsible)
            users.push(` ${user.name} ${user.lastname}`)
        let payload = {
            case: 'operationLock',
            subject: 'Entelli - informacja o blokadzie obsługi',
            route: 'operation-lock',
            details: {
                contractor: state.details.contractor.name,
                responsible: users.toString(),
                reason: state.details.reason.field,
            },
        }

        sendMail.sendInformationMail(state, payload)
    },
    async fetchOperationLockTable({ commit, dispatch }) {
        state.table.loading = true
        try {
            let response = await new Rest('GET', 'operation-lock')
                .select(state.request.select)
                .limit(state.table.limit)
                .page(state.table.page)
                .where(state.request.where)
                .populate(state.request.populate)
                .unwindExeptions(state.request.unwindExeptions)
                .search(state.request.searchFields, state.table.search)
                .sort(state.table.sort || state.table.defaultSort)
                .send()

            dispatch('fetchOperationLockRegistry')
            commit('setOperationLockTable', response.data)
        } catch (error) {
            console.error(error)
        }
        state.table.loading = false
    },
    async createOperationLock({ commit, dispatch }, payload) {
        try {
            await new Rest('POST', 'operation-lock')
                .setBody(state.modal.fields)
                .send()
            commit('clearOperationLockModal')
            commit('clearOperationLockErrors')
            dispatch('fetchOperationLockTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            //HANDLE ERRORS
            if (err) {
                commit('setSnackbars', { type: 'error' })
                validation.setErrors(state.modal.errors, err)
            }
        }
    },

    async updateOperationLock({ commit, dispatch }, payload) {
        try {
            await new Rest('PUT', `operation-lock/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            dispatch('fetchOperationLock', { id: payload.id })
            commit('clearOperationLockModal')
            commit('clearOperationLockErrors')
            return true
        } catch (err) {
            //HANDLE ERRORS
            validation.setErrors(state.modal.errors, err)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async fetchOperationLock({ state, commit, dispatch }, payload) {
        dispatch('fetchOperationLockRegistry')
        try {
            let operationLock = await new Rest(
                'GET',
                `operation-lock/${payload.id}`
            )
                .populate([
                    { path: 'contractor' },
                    { path: 'createdBy' },
                    { path: 'to.departments' },
                    { path: 'to.users' },
                    { path: 'responsible', select: 'name lastname phone mail' },
                ])
                .select(
                    '_id reason responsible createdAt updatedAt status description index contractor createdBy'
                )
                .send()

            let statusesReq = new Rest(
                'GET',
                `registries/operation-lock/statuses`
            )
                .select('name _id isDeleted color state')
                .where({ isDeleted: false })
                .send()

            let { 0: response, 1: statuses } = await Promise.all([
                operationLock,
                statusesReq,
            ])
            commit('setOperationLockDetails', response.data)
            commit('setOperationLockStatuses', statuses.data.items)
            if (payload.next) payload.next()
        } catch (err) {
            console.error(err)
        }
    },

    async fetchOperationLockRegistry({ commit, dispatch }, payload) {
        let registry = await new Rest('GET', 'registries')
            .select('currentMenager name')
            .where({ url: 'operation-lock' })
            .populate([
                {
                    path: 'currentMenager',
                    select: 'name lastname mail status phone picture',
                },
            ])
            .send()

        commit('setOperationLockRegistry', registry.data.items)
    },

    async updateOperationLockStatus({ commit, dispatch }, payload) {
        commit('setOperationLockModal')
        commit('setOperationLockStatus', payload.status)
        try {
            await new Rest('PUT', `operation-lock/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            commit('setSnackbars', { type: 'success' })
        } catch (err) {
            console.error(err)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async deleteOperationLock({ commit, dispatch }, payload) {
        try {
            await new Rest('DELETE', `operation-lock/${payload.id}`).send()
            dispatch('fetchOperationLockTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            console.error(err)
        }
    },

    async checkIsClientOperationLocked({ commit, dispatch }, payload) {
        // console.log(payload)
        try {
            let response = await new Rest(
                'GET',
                `operation-lock/checkIsClientOperationLocked/${payload.id}`
            ).send()
            return response
        } catch (error) {
            console.error(error)
        }
    },
}

const mutations = {
    setOperationLockTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
    setOperationLockDetails(state, payload) {
        state.details = payload
    },
    setOperationLockStatuses(state, payload) {
        state.statuses = payload
    },
    setOperationLockModal(state, payload) {
        let obj = payload ? payload : clone(state.details)
        state.modal.fields = obj
        store.commit('setRepresentativesInput', {
            items: obj?.contractor?.representatives,
        })
        state.modal.fields.representative = obj?.contractor?.representatives?.find(
            e => e._id === obj.representative
        )
        state.modal.fields.expirationDate = obj.expirationDate
            ? moment(obj.expirationDate).format('YYYY-MM-DD')
            : ''
    },
    setOperationLockStatus(state, payload) {
        state.modal.fields.status = payload
    },
    setOperationLockRegistry(state, payload) {
        state.registry = payload[0]
    },
    clearOperationLockModal() {
        state.modal.step = 1
        state.modal.fields = {
            reason: '',
            contractor: '',
            description: '',
            responsible: '',
            to: {
                users: [],
                departments: [],
            },
        }
    },
    clearOperationLockErrors(state) {
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step].fields) {
                state.modal.errors[step].fields[key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
    clearOperationLockTable(state) {
        clearSearch.clearSearchTable(state)
    },
    setOperationLockSearch(state, payload) {
        state.request.searchFields = payload
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
