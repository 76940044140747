import Rest from './../../../plugins/Rest'
import clone from 'lodash.clone'

const state = {
    modal: {
        fields: {
            monitoringDevice: false,
            OSP: { contractNo: '' },
            contractsNumber: '',
            BID: '',
            asset: '',
            leasingAgreement: '',
            comment: '',
            dedicatedPrice: false,
            survey: 0,
            installation: 0,
            itService: 0,
            repair: 0,
            diagnostics: 0,
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {},
            },
            stepTwo: {
                validation: true,
                fields: {},
            },
        },
        step: 1,
    },
    details: {},
    request: {
        populate: [],

        select: '',
        where: {
            isDeleted: false,
        },
    },
}

const getters = {
    getDeviceAnother: state => state.modal.fields,
    getDeviceAnotherDetails: state => state.details,
    getDeviceAnotherError: state => state.modal.errors,
}

const actions = {
    async fetchAnother({ state, commit }, payload) {
        try {
         
            let response = await new Rest(
                'GET',
                `/devices/another/${payload.id}`
            )
                // .populate(state.request.populate)
                .populate([
                    {
                        path: 'anotherDevice.contractsNumber',
                        select: 'number',
                    },
                    {
                        path: 'anotherDevice.leasingAgreement',
                        select: 'leasingNumber',
                    },
                ])
                .send()
       
            commit('clearAnotherDetails')
            commit('clearAnotherModal')
            commit('setAnotherDetails', response.data)
            if (payload.next) payload.next()
        } catch (err) {
            console.error(err)
        }
    },
    async anotherUpdate({ state, commit, dispatch }, payload) {
        try {
            let response = await new Rest(
                'PUT',
                `/devices/another/${payload.id}`
            )
                .setBody(state.modal.fields)
                .send()
            dispatch('fetchAnother', { id: payload.id })
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            //HANDLE ERRORS
            validation.setErrors(state.modal.errors, err)
            commit('setSnackbars', { type: 'error' })
        }
    },
}

const mutations = {
    setAnotherDetails(state, payload) {
        if (payload) state.details = payload
    },
    clearAnotherDetails(state){
        state.details={}
    },
    setAnotherModal(state, payload) {
        let obj = payload ? payload : clone(state.details)
        state.modal.fields = obj
    },
    clearAnotherModal(state) {
        state.modal.fields = {
            monitoringDevice: false,
            OSP: { contractNo: '' },
            leasingNumber: '',
            BID: '',
            asset: '',
            leasingAgreement: '',
            comment: '',
            dedicatedPrice: false,
            survey: {
                standard: 0,
                dedicated: 0,
            },
            installation: {
                standard: 0,
                dedicated: 0,
            },
            itService: {
                standard: 0,
                dedicated: 0,
            },
            repair: {
                standard: 0,
                dedicated: 0,
            },
            diagnostics: {
                standard: 0,
                dedicated: 0,
            },
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
