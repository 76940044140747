<template>
    <v-container class="d-flex justify-space-between px-2 py-0 mt-0">
        <div class="central__title">
            <h1
                @click="$emit('clearSearch'), clearSearchText()"
                class="font-weight-light cursor-pointer mt-1"
            >
                {{ title || '' }}
            </h1>
        </div>
        <div></div>
        <Avatar
            v-if="avatarData"
            :name="avatarData.name"
            :lastname="avatarData.lastname"
            :tooltip="$t('global:moduleManager')"
            :phone="avatarData.phone || ''"
            :email="avatarData.mail || ''"
            :picture="avatarData.picture || ''"
        />
    </v-container>
</template>
<script>
import Avatar from './../../Global/Avatar/Avatar'
import { mapGetters } from 'vuex'
/**
 * Central Table
 * @displayName  Overlay Header
 */
export default {
    props: {
        /**
         * Main view / Title
         */
        title: {
            required: true,
        },
        avatarData: {
            type: Object,
        },
    },
    components: {
        Avatar,
    },
    methods: {
        clearSearchText() {
            this.getSearch.text = ''
        },
    },
    computed: {
        ...mapGetters(['getSearch']),
    },
}
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 1440px) {
    .vertical-line {
        margin-left: 5px;
        margin-top: 7px;
        height: 18px;
        border-left: 1px solid #111;
    }
}
</style>
