<template>
    <div class="taskDriver__box">
        <v-sheet
            elevation="5"
            rounded
            shaped
            width="200"
        >
            
                <div class="taskDriver__box--date">
                    {{ dateFormat(date) }}
                </div>
                <v-divider
                    class="taskDriver__box--divider"
                    color="black"
                ></v-divider>
                <div class="taskDriver__box--content">
                    <div
                        v-if="services.size >= 1"
                        class="taskDriver__box--content-wrapper"
                    >
                        <div
                            v-for="[technicEmail, serviceData] in services"
                            :key="technicEmail"
                        >
                            <task-driver-single
                                :technicEmail="technicEmail"
                                :services="serviceData"
                            ></task-driver-single>
                        </div>
                    </div>
                    <div
                        v-if="services.size === 0"
                        class="taskDriver__box--content-wrapper"
                    >
                        <div class="taskDriver__box--content-wrapper--notasks">
                            <p>X</p>
                        </div>
                    </div>
                </div>
            
        </v-sheet>
    </div>
</template>

<script>
import moment from 'moment'
import TaskDriverSheet from './TaskDriver.vue'
import TaskDriverSingle from './TaskDriverSingle.vue'
export default {
    components: { TaskDriverSingle },
    name: TaskDriverSheet,
    props: ['date', 'services'],
    methods: {
        dateFormat(date) {
            date = moment(date).format('DD/MM').toString()
            return date
        },
    },
}
</script>

<style lang="sass" scoped>
.v-sheet
    color: white
    align-items: stretch
    height: 100%
    background-color: rgb(245, 245, 245)
.taskDriver__box
    height: 100%
    &--divider
        margin-bottom: 8px
    &--date
        font-weight: 500
        background-color:  rgb(68, 93, 117)
        padding: 5px
    &--content-wrapper
        display: grid
        grid-template-columns: repeat(auto-fit, minmax(1px, 1fr))
        &--notasks
            color: rgba(189, 189, 189)
            font-size: 70px
            font-weight: 500
</style>