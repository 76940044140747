
<template>
    <v-snackbar
        :timeout="2500"
        v-model="getSnackbars.snackbar"
        :color="getSnackbars.type"
        right
        top
        class="snackbars"
    >
        <v-icon v-if="getSnackbars.type === 'error'" color="white"
            >mdi-close-circle</v-icon
        >
        <v-icon v-if="getSnackbars.type === 'success'" color="white"
            >mdi-check-circle</v-icon
        >
        <v-icon v-if="getSnackbars.type === 'warning'" color="white"
            >mdi-alert-circle</v-icon
        >
        <div>
            <span
                data-cy="snackbarError"
                v-if="getSnackbars.type === 'error'"
                class="snackbars__text"
                >{{
                    !getSnackbars.text ? $t('global:error') : getSnackbars.text
                }}</span
            >
            <span
                data-cy="snackbarSuccess"
                v-if="getSnackbars.type === 'success'"
                class="snackbars__text"
                >{{
                    !getSnackbars.text
                        ? $t('global:success')
                        : getSnackbars.text
                }}</span
            >
            <span
                data-cy="snackbarWarning"
                v-if="getSnackbars.type === 'warning'"
                class="snackbars__text"
                >{{
                    !getSnackbars.text
                        ? $t('global:warning')
                        : getSnackbars.text
                }}</span
            >
        </div>
        <v-btn class="snackbars__btn" dark icon text @click="closeSnackbars()">
            <v-icon size="15">mdi-close</v-icon>
        </v-btn>
    </v-snackbar>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
export default {
    props: ['text', 'timeout'],
    i18nOptions: { namespaces: ['global'] },
    data: () => ({}),
    methods: {
        ...mapMutations(['showSnackbars', 'closeSnackbars']),
    },
    computed: {
        ...mapGetters(['getSnackbars']),
    },
}
</script>