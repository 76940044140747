import qs from 'qs'
import axios from 'axios'

class Rest {
    /**
     *
     * @param {String} type GET, PUT, POST, DELETE
     * @param {String} url
     */
    constructor(type, url) {
        this.type = type
        this.url = url ? (url[0] == '/' ? url : `/${url}`) : '/'
        this.body = {}
        this.params = {}
        this.config = {}
    }

    /**
     * async function, executes Rest request
     * @returns {Promise} Promise<axiosResponse>
     */
    async send(body) {
        if (!this.type)
            throw new Error("REST object's TYPE property is not defined")
        if (this.type.toUpperCase() == 'GET')
            return await axios.get(
                `${this.url}?${qs.stringify(this.params, { encode: false })}`
            )
        if (this.type.toUpperCase() == 'POST')
            return await axios.post(
                `${this.url}?${qs.stringify(this.params, { encode: false })}`,
                body || this.body,
                this.config
            )
        if (this.type.toUpperCase() == 'PUT')
            return await axios.put(
                `${this.url}?${qs.stringify(this.params, { encode: false })}`,
                body || this.body
            )
        if (this.type.toUpperCase() == 'DELETE')
            return await axios.delete(
                `${this.url}?${qs.stringify(this.params, { encode: false })}`
            )
        
        return
    }

    /**
     * Sets body property of Rest request
     * @param {Object} obj
     */
    setBody(obj) {
        this.body = obj || {}
        return this
    }

    /**
     * Sets params (query string) of Rest request
     * @param {Object} obj
     */
    setParams(obj) {
        this.params = obj || {}
        return this
    }

    addParams(obj) {
        Object.assign(this.params, obj)
        return this
    }

    populate(arr) {
        this.params.populate = arr
        return this
    }

    unwindExeptions(arr) {
        this.params.unwindExeptions = arr
        return this
    }

    select(str) {
        if (this.params.select)
            this.params.select += str ? ` ${str.trim()}` : ''
        else this.params.select = str ? str.trim() : ''
        return this
    }

    sort(str) {
        if (this.params.sort) this.params.sort += str ? ` ${str.trim()}` : ''
        else this.params.sort = str ? str.trim() : ''
        return this
    }

    limit(number) {
        this.params.limit = !isNaN(number) ? parseInt(number) : 0
        return this
    }

    page(number) {
        this.params.page = !isNaN(number) ? parseInt(number) : 0
        return this
    }

    /**
     * Specify request filtering
     * e.g
     * rest.where({
     *     field: 'value',
     *     field2: '[$gte] value2'
     *     field3: '[$operator] value3'
     * })
     * @param {Object} obj
     */
    where(obj) {
        this.params.where = obj
        return this
    }

    /**
     * Specify requst search
     * e.g
     * rest.search({
     *     fields: 'field1 field2 field3',
     *     value: 'value'
     * })
     * @param {Object} obj
     */
    search(fields, value) {
        this.params.search = {}
        if (fields instanceof Array) {
            this.params.search.fields = fields.join(' ')
            this.params.search.value = value
        } else if (typeof fields == 'string') {
            this.params.search.fields = fields
            this.params.search.value = value
        } else if (fields instanceof Object) this.params.search = fields
        return this
    }

    additional(obj) {
        this.params.additional = obj
        return this
    }

    setConfig(obj) {
        this.config = obj || {}
        return this
    }

    toString() {
        return `${this.type}`
    }
}

export default Rest
