<template>
    <div>
        <v-tooltip right>
            <template v-slot:activator="{ on }">
                <v-btn
                    v-on="on"
                    fab
                    @click="$emit('filter-contract-after-date')"
                    :color="color"
                    small
                >
                    <v-icon color="white" size="18">{{ icon }}</v-icon>
                </v-btn>
            </template>
            <span>{{ tooltip }}</span>
        </v-tooltip>
    </div>
</template>

<script>
export default {
    props: {
        color: {type: String},
        icon: { type: String },
        tooltip: { type: String}
    },
    data() {
        return {

        }
    },
    methods: {},
  
}
</script>

<style lang="scss">
</style>