var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0 pb-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0 pb-0",model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"editable":"","step":"1","rules":[
                                () =>
                                    _vm.getFilesModalErrors.stepTwo.validation,
                            ]}},[_vm._v(_vm._s(_vm.$t('global:information')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"data-cy":"folderName","label":_vm.$t('global:folderName'),"items":_vm.getFoldersItems,"disabled":"","item-value":"_id","item-text":"name","error-messages":_vm.getFilesModalErrors.stepTwo
                                                .folder},model:{value:(_vm.getFilesModalFields.folder),callback:function ($$v) {_vm.$set(_vm.getFilesModalFields, "folder", $$v)},expression:"getFilesModalFields.folder"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"data-cy":"fileName","label":_vm.$t('global:fileName'),"error-messages":_vm.getFilesModalErrors.stepTwo.name},model:{value:(_vm.getFilesModalFields.name),callback:function ($$v) {_vm.$set(_vm.getFilesModalFields, "name", $$v)},expression:"getFilesModalFields.name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.getFilesModalFields.url)?_c('v-text-field',{attrs:{"data-cy":"url","label":_vm.$t('global:url')},model:{value:(_vm.getFilesModalFields.url),callback:function ($$v) {_vm.$set(_vm.getFilesModalFields, "url", $$v)},expression:"getFilesModalFields.url"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"data-cy":"status","label":_vm.$t('global:status'),"items":_vm.statuses,"item-text":"name","item-value":"value"},model:{value:(_vm.getFilesModalFields.status),callback:function ($$v) {_vm.$set(_vm.getFilesModalFields, "status", $$v)},expression:"getFilesModalFields.status"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","nudge-bottom":"45","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t(
                                                        'attachments:archiveDate'
                                                    ),"readonly":""},model:{value:(
                                                    _vm.getFilesModalFields.archiveDate
                                                ),callback:function ($$v) {_vm.$set(_vm.getFilesModalFields, "archiveDate", $$v)},expression:"\n                                                    getFilesModalFields.archiveDate\n                                                "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datepicker),callback:function ($$v) {_vm.datepicker=$$v},expression:"datepicker"}},[_c('v-date-picker',{attrs:{"no-title":"","first-day-of-week":"1"},on:{"input":function($event){_vm.datepicker = false}},model:{value:(
                                                _vm.getFilesModalFields.archiveDate
                                            ),callback:function ($$v) {_vm.$set(_vm.getFilesModalFields, "archiveDate", $$v)},expression:"\n                                                getFilesModalFields.archiveDate\n                                            "}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"data-cy":"Description","label":_vm.$t(
                                                'attachments:attachmentDescription'
                                            ),"no-resize":"","rows":"1"},model:{value:(
                                            _vm.getFilesModalFields.description
                                        ),callback:function ($$v) {_vm.$set(_vm.getFilesModalFields, "description", $$v)},expression:"\n                                            getFilesModalFields.description\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"data-cy":"comment","label":_vm.$t('attachments:comment'),"no-resize":"","rows":"1"},model:{value:(
                                            _vm.getFilesModalFields.comment
                                        ),callback:function ($$v) {_vm.$set(_vm.getFilesModalFields, "comment", $$v)},expression:"\n                                            getFilesModalFields.comment\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"attachments--info"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('attachments:createdBy'))+" "+_vm._s(`${_vm.$get( _vm.getFilesModalFields, 'createdBy.name', '' )} ${_vm.$get( _vm.getFilesModalFields, 'createdBy.lastname', '' )}`))]),_c('span',{staticClass:"mx-1"},[_vm._v("/")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('attachments:createdAt'))+" "+_vm._s(_vm.$moment( _vm.getFilesModalFields.createdAt ).format(`DD-MM-YYYY HH:mm`)))])])])],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }