import Rest from './../../../plugins/Rest'
import clone from 'lodash.clone'
import validation from './../../../plugins/validation'
// import i18n from '../../../plugins/i18next'
import clearSearch from './../../../plugins/clearSearch'
const state = {
    modal: {
        fields: {
            name: '',
            lastName: '',
            login: '',
            password: null,
            passwordAgain: null,
            loginTries: 0,
            email: '',
            contact: null,
            comments: '',
            isAdmin: false,
            isActive: true,
            isBanned: false,
            phoneNumber: ''
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {
                    name: '',
                    lastName: '',
                    contact: '',
                    login: '',
                    password: '',
                    passwordAgain: '',
                    phoneNumber: ''
                },
            },
        },
        steps: 1,
    },
    request: {
        unwindExeptions: ['responsible'],
        populate: [
            {
                localField: 'contact',
                foreignField: '_id',
                from: 'contacts',
                as: 'contact',
            },
        ],
        searchFields: ['name join lastName', 'contact.name'],
        defaultSearchFields: ['name join lastName', 'contact.name'],
        select:
            '_id createdAt name  lastName login isBanned isAdmin devices status contact comments loginTries folders phoneNumber',
        where: {
            isDeleted: false,
        },
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: '-createdAt',
        loading: false,
    },
    searchFields: [],
    req: {
        stepOne: [],
    },
    registry: {},
    details: {},
    statuses: [],
    dictFields: {
        priorityType: '',
    },
}

const getters = {
    getNotifyUsersModal: state => state.modal.fields,
    getNotifyUsersErrors: state => state.modal.errors,
    getNotifyUsersDetails: state => state.details,
    getNotifyUsersStep: state => state.modal.fields,
    getNotifyUsersStatuses: state => state.statuses,
    getNotifyUsersTable: state => state.table,
    getNotifyUsersSearchFields: state => state.request,
    getNotifyUsersRegistry: state => state.registry,
}

const actions = {
    async fetchNotifyUsersRegistry({ commit }) {
        let registry = await new Rest('GET', 'registries')
            .select('currentMenager name')
            .where({ url: 'help-desk-users' })
            .populate([
                {
                    path: 'currentMenager',
                    select: 'name lastname mail status phone picture',
                },
            ])
            .send()
        commit('setNotifyUsersRegistry', registry.data.items)
    },

    async fetchNotifyUsersTable({ commit, dispatch }) {
        state.table.loading = true
        try {
            let table = await new Rest('GET', 'help-desk-users')
                .select(state.request.select)
                .limit(state.table.limit)
                .page(state.table.page)
                .where(state.request.where)
                .populate(state.request.populate)
                .unwindExeptions(state.request.unwindExeptions)
                .search(state.request.searchFields, state.table.search)
                .sort(state.table.sort || state.table.defaultSort)
                .send()
            dispatch('fetchNotifyUsersRegistry')
            commit('setNotifyUsersTable', table.data)
        } catch (err) {
            console.error(err)
        }
        state.table.loading = false
    },
    async fetchNotifyUsers({ commit, dispatch }, payload) {
        // console.log(state.request.select)
        try {
            let Info = await new Rest('GET', `help-desk-users/${payload.id}`)
                .select(state.request.select)
                .populate({ path: 'contact' })
                .send()

            let statusesReq = new Rest(
                'GET',
                `registries/help-desk-users/statuses`
            )
                .select('name _id isDeleted color state')
                .where({ isDeleted: false })
                .send()

            let { 0: response, 1: statuses } = await Promise.all([
                Info,
                statusesReq,
            ])

            dispatch('fetchNotifyUsersRegistry')
      
            commit('setNotifyUsersDetails', response.data)
            commit('setNotifyUsersStatuses', statuses.data.items)

            if (payload.next) payload.next()
        } catch (err) {
            console.error(err)
        }
    },
    async createNotifyUsers({ commit, dispatch, state }) {
        try {
            await new Rest('POST', 'help-desk-users')
                .setBody(state.modal.fields)
                .send()
            commit('clearNotifyUsersModal')
            commit('clearNotifyUsersErrors')
            dispatch('fetchNotifyUsersTable')
            commit('setSnackbars', { type: 'success' })

            return true
        } catch (err) {
            //HANDLE ERRORS
            if (err) {
                // console.log(err.response)
                commit('setSnackbars', { type: 'error' })
                validation.setErrors(state.modal.errors, err)
            }
        }
    },
    async updateNotifyUsers({ commit, dispatch }, payload) {
        try {
            await new Rest('PUT', `help-desk-users/${payload.id}`)
                .setBody(state.modal.fields)

                .send()
            dispatch('fetchNotifyUsers', { id: payload.id })
            commit('clearNotifyUsersModal')
            commit('clearNotifyUsersErrors')
            return true
        } catch (err) {
            //HANDLE ERRORS
            validation.setErrors(state.modal.errors, err)
            commit('setSnackbars', { type: 'error' })
        }
    },

    async updateNotifyUsersStatus({ commit }, payload) {
        commit('setNotifyUsersModal')
        commit('setNotifyUsersStatus', payload.status)
        try {
            await new Rest('PUT', `help-desk-users/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            commit('setSnackbars', { type: 'success' })
        } catch (err) {
            commit('setSnackbars', { type: 'error' })
        }
    },

    async deleteNotifyUsers({ commit, dispatch }, payload) {
        try {
            await new Rest('DELETE', `help-desk-users/${payload.id}`).send()
            dispatch('fetchNotifyUsersTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            console.error(err)
        }
    },
}

const mutations = {
    setNotifyUsersTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
    setNotifyUsersSearch(state, payload) {
        state.request.searchFields = payload
    },
    setNotifyUsersDetails(state, payload) {
        state.details = payload
    },
    setNotifyUsersStatuses(state, payload) {
        state.statuses = payload
    },
    setNotifyUsersStatus(state, payload) {
        state.modal.fields.status = payload
    },
    setNotifyUsersModal(state, payload) {
        let obj = payload ? payload : clone(state.details)
        state.modal.fields = obj
    },
    setNotifyUsersRegistry(state, payload) {
        state.registry = payload[0]
    },
    clearNotifyUsersModal(state, payload) {
        state.modal.step = 1
        state.modal.fields = {
            name: '',
            lastName: '',
            login: '',
            password: null,
            passwordAgain: null,
            email: '',
            contact: null,
            comments: '',
            isAdmin: false,
            isActive: true,
            isBanned: false,
            loginTries: 0,
            phoneNumber: ''
        }
    },
    clearNotifyUsersErrors(state) {
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step].fields) {
                state.modal.errors[step].fields[key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
    clearNotifyUsersTable(state) {
        clearSearch.clearSearchTable(state)
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
