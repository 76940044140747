<template>
    <div id="printButtons" class="printOrder--buttons no-print">
        <v-btn @click="toPDF()" fab small class="buttons--add no-print" icon>
            <v-icon size="20">mdi-download</v-icon>
        </v-btn>
        <v-btn @click="printDoc()" fab small class="buttons--add no-print" icon>
            <v-icon size="20">mdi-printer</v-icon>
        </v-btn>
        <v-menu
            v-model="emailMenu"
            :close-on-content-click="false"
            :nudge-width="500"
            offset-x
            :max-width="500"
            ><template v-slot:activator="{ on }">
                <v-btn v-on="on" fab small class="buttons--add no-print" icon>
                    <v-icon size="20">mdi-email-fast</v-icon>
                </v-btn></template
            ><v-card class="pa-4" height="150">
                <v-combobox
                    @input.native="representativeMail = $event.srcElement.value"
                    @input="representativeMail = $event"
                    dense
                    return-object
                    item-value="_id"
                    :label="$t('service:emailAddress')"
                    :items="contractors"
                    ><template v-slot:selection="data">
                        <div
                            class="mr-1"
                            v-if="!data.item.name && !data.item.lastname"
                        >
                            {{ data.item }}
                        </div>
                        <div class="mr-1" v-else>
                            {{ ` ${data.item.name} ${data.item.lastname}` }}
                        </div>
                        <span class="mr-1" v-if="data.item.email">
                            {{ ` • ${data.item.email} ` }}
                        </span>
                    </template>
                    <template v-slot:item="data">
                        <div class="mr-1">
                            {{ ` ${data.item.name} ${data.item.lastname}` }}
                        </div>
                        <span class="mr-1" v-if="data.item.email">
                            {{ ` • ${data.item.email} ` }}
                        </span>
                    </template>
                    <template slot="prepend">
                        <v-icon color="#19aa8d" class="mt-1" size="20"
                            >mdi-email</v-icon
                        >
                    </template>
                </v-combobox>
                <v-progress-linear
                    class="ma-2"
                    v-if="emailLoading"
                    color="#445D75"
                    indeterminate
                    rounded
                    height="6"
                >
                </v-progress-linear>
                <v-btn
                    @click="sendServicePrint()"
                    dark
                    class="buttons buttons--add no-print"
                    style="position: absolute; right: 30px; bottom: 20px;"
                    >{{ $t('global:send') }}</v-btn
                >
            </v-card></v-menu
        >
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import * as html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

export default {
    data: () => ({
        emailMenu: false,
        representativeMail: '',
        message: '',
        emailLoading: false,
    }),
    props: {
        refProps: {
            type: Object,
        },
    },
    computed: {
        ...mapGetters(['getServiceDetails']),
        contractors: {
            get() {
                let representatives = this.$get(
                    this.getServiceDetails,
                    'device.client.representatives',
                    []
                )
                return representatives.filter(
                    representative => representative.email
                )
            },
        },
    },
    methods: {
        ...mapMutations(['setSnackbars']),
        printDoc() {
            window.print()
        },
        async toPDF() {
            let doc = new jsPDF('p', 'pt', 'A4')
            await this.createSinglePage(
                doc,
                this.refProps.protocol.$el.firstChild
            )
            if (
                this.$get(this.getServiceDetails, 'parts.partsTable') &&
                this.getServiceDetails.parts.partsTable.length > 0
            ) {
                doc.addPage()
                await this.createSinglePage(
                    doc,
                    this.refProps.parts.$el.firstChild
                )
            }
            doc.save('zlecenie_serwisu.pdf')
        },
        async createSinglePage(doc, pageInfo) {
            try {
                const canvas = await html2canvas(pageInfo, { scale: 3 })
                // JPEG HERE MOST IMPORTANT
                var img = canvas.toDataURL('image/jpeg')
                const imgProps = doc.getImageProperties(img)
                const pdfWidth = doc.internal.pageSize.getWidth()
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
                doc.addImage(img, 'JPEG', 0, 0, pdfWidth, pdfHeight)
                return doc
            } catch (err) {
                console.log(err)
            }
        },
        genCode(len) {
            const chars =
                '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
            let code = ''
            for (let i = 0; i < len; i++) {
                let index = Math.floor(Math.random() * chars.length)
                code += chars[index]
            }
            return code
        },
        async sendServicePrint() {
            this.emailLoading = true
            if (typeof this.representativeMail == 'string')
                this.representativeMail = this.representativeMail
            else this.representativeMail = this.representativeMail.email

            try {
                let doc = new jsPDF('p', 'pt', 'A4')
                await this.createSinglePage(
                    doc,
                    this.refProps.protocol.$el.firstChild
                )
                if (
                    this.$get(this.getServiceDetails, 'parts.partsTable') &&
                    this.getServiceDetails.parts.partsTable.length > 0
                ) {
                    doc.addPage()
                    await this.createSinglePage(
                        doc,
                        this.refProps.parts.$el.firstChild
                    )
                }
                let form = new FormData()
                form.append('case', 'pdf')
                form.append(
                    'mail',
                    `${
                        this.representativeMail || this.representativeMail.email
                    }`
                )
                form.append('code', `${this.genCode(8)}`)
                form.append('doc', doc.output('blob'))
                let res = await new this.Rest('POST', '/service/sendOrder')
                    .setBody(form)
                    .send()
                if (res.status === 200) {
                    this.emailLoading = false
                    this.setSnackbars({
                        type: 'success',
                        text: `Wysłano pomyślnie!`,
                    })
                    const sleep = m => new Promise(r => setTimeout(r, m))
                    await sleep(1000)
                    this.emailMenu = false
                }
                return true
            } catch (error) {
                this.emailLoading = false
                this.setSnackbars({
                    type: 'error',
                    text: `Błąd wysyłania email`,
                })
            }
        },
    },
}
</script>
<style lang="sass" scoped>
.printOrder--buttons
    position: fixed
    bottom: 20px
    right: 10%
    button
        margin: 5px

@media print
    .no-print, .no-print *
        display: none !important
</style>
