<template>
    <tbody>
        <template
            v-for="(item, index) in contracts"
            :class="`${isStatusClosed(item) ? 'disabled' : ''}`"
        >
            <tr
                :class="`${isStatusClosed(item) ? 'disabled' : ''}`"
                :key="index"
            >
                <!-- === === INDEX === === -->
                <td class="truncate">{{ item.index }}</td>
                <!-- === === CONTRACT_NR === === -->
                <td class="truncate">
                    {{
                        $get(item, 'contractNo') ||
                        $get(item, 'externalContractNo', $t('global:empty'))
                    }}
                </td>
                <!-- === === CONTRACTOR === === -->
                <td class="truncate">
                    <DoubleLine
                        :firtstLine="
                            $get(
                                item,
                                'annex.contractor.name',
                                $t('global:empty')
                            )
                        "
                        :secondLine="findRepresentative(item)"
                    />
                </td>

                <!-- === === EXPIRATIONDATE === === -->
                <td class="truncate">
                    <DoubleLine
                        :firtstLine="
                            $get(item, 'annex.expirationDate', false)
                                ? $moment(item.annex.expirationDate).format(
                                      `DD-MM-YYYY`
                                  )
                                : $t('global:empty')
                        "
                    />
                </td>
                <!-- === === CUSTOMER_CARER === === -->
                <td class="truncate">
                    {{
                        `${$get(
                            item,
                            'annex.customerCarer.name',
                            $t('global:empty')
                        )} ${$get(item, 'annex.customerCarer.lastname', '')}`
                    }}
                </td>
                <!-- === === ACCOUUNTINGDAY === === -->

                <td class="truncate">
                    {{
                        item.annex && item.annex.accountingDay
                            ? item.annex.accountingDay
                            : $t('global:empty')
                    }}
                </td>
                <!-- === === STATUS === === -->
                <td>
                    <v-chip
                        :color="
                            $get(
                                item,
                                'annex.status.color.background',
                                '#555555'
                            )
                        "
                        :style="`color: ${$get(
                            item,
                            'annex.status.color.font',
                            '#FFFFFF'
                        )}`"
                        dark
                        label
                        small
                        >{{
                            $get(item, 'annex.status.name', $t('users:empty'))
                        }}</v-chip
                    >
                </td>
                <td class="d-flex align-center justify-end">
                    <v-icon small v-if="hasFile(item.folders)"
                        >mdi-paperclip</v-icon
                    >
                    <SettlementButton :item="item.annex" />
                    <v-menu bottom v-if="remove()">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon small v-bind="attrs" v-on="on">
                                <v-icon
                                    color="iconGray"
                                    @click="onRemove(item)"
                                    small
                                    >mdi-dots-vertical</v-icon
                                >
                            </v-btn>
                        </template>
                        <v-list  dense>
                            <!-- === === CSV === === -->
                            <CSVFileButtons :item="item.annex" />

                            <!-- === === REMOVE === === -->
                            <v-list-item @click="openConfirmModal(item._id)">
                                <v-list-item-icon class="mr-0">
                                    <v-icon color="red" small
                                        >mdi-delete</v-icon
                                    >
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Usuń</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-btn
                        v-if="!item.createLoading"
                        @click="clickNextPageLoading(item)"
                        small
                        router
                        :to="`/rent-info/${item._id}`"
                        icon
                    >
                        <v-icon color="secondary" small
                            >mdi-arrow-right-thick</v-icon
                        >
                    </v-btn>

                    <Spinner v-if="item.createLoading" />
                </td>
            </tr>
        </template>
        <ConfirmModal
            :openConfirm="openConfirm"
            @close-confirm="closeConfirmModal"
        >
            <div slot="main"><ConfirmContent /></div>
            <div slot="buttons">
                <div class="d-flex justify-center">
                    <v-btn
                        color="rgb(117, 117, 117)"
                        width="110"
                        height="40"
                        @click="closeConfirmModal()"
                        class="white--text mx-2"
                        >{{ $t('global:cancel') }}</v-btn
                    >
                    <v-btn
                        width="110"
                        height="40"
                        color="#445d75"
                        class="white--text mx-2"
                        @click="deleteItem()"
                        >{{ $t('global:remove') }}</v-btn
                    >
                </div>
            </div>
        </ConfirmModal>
    </tbody>
</template>
<script>
import ConfirmContent from '../ConfirmModal/ConfirmContent.vue'
import SettlementButton from '../Tabs/Rent-annex/Table/Buttons/SettlementButton.vue'
import { mapGetters, mapActions } from 'vuex'
import CSVFileButtons from '../Tabs/Rent-annex/Table/Buttons/CSVFileButtons.vue'
import Spinner from '../../../Global/Table/Spinner.vue'
export default {
    props: ['items', 'isExpanded', 'expand', 'headers'],
    data: () => ({
        openConfirm: false,
        removeItemID: '',
    }),
    components: {
        ConfirmContent,
        SettlementButton,
        CSVFileButtons,
        Spinner,
    },
    computed: {
        ...mapGetters(['getProfileDetails']),
        contracts() {
            return this.items.map(record => {
                return {
                    ...record,
                    createLoading: false,
                }
            })
        },
    },
    methods: {
        clickNextPageLoading(item) {
            item.createLoading = true
        },

        onRemove(item) {
            this.removeItemID = item._id
        },
        onExpand(item) {
            let element = document.getElementById(item._id)
            if (!this.isExpanded(item)) {
                element.style.transform = 'rotate(180deg)'
            } else {
                element.style.transform = 'rotate(0deg)'
            }

            this.expand(item, !this.isExpanded(item))
        },
        findRepresentative(item) {
            const id = item.representative
            if (id) {
                const reprezentative = item.contractor?.representatives?.find(
                    e => e._id === id
                )
                return `${this.$get(
                    reprezentative,
                    'name',
                    'Brak'
                )} ${this.$get(reprezentative, 'lastname', '')} ${
                    reprezentative && reprezentative.email ? '•' : ''
                } ${this.$get(reprezentative, `email`, '')} ${
                    reprezentative && reprezentative.phoneNumber ? '•' : ''
                } ${this.$get(reprezentative, 'phoneNumber', '')}`
            }
            return ''
        },
        ...mapActions(['deleteRentInfo']),
        openConfirmModal(id) {
            this.removeItemID = id
            this.openConfirm = true
        },
        closeConfirmModal() {
            this.openConfirm = false
        },
        async deleteItem() {
            let result = await this.deleteRentInfo({ id: this.removeItemID })
            if (result) this.openConfirm = false
        },
        remove() {
            return this.checkPermissions('DELETE')
        },
    },
}
</script>
<style lang="scss" scoped>
.create-loading {
    height: 30% !important;
}
</style>
