<template>
    <div>
        <v-btn
            @click="action == 'create' ? save() : edit()"
            :disabled="disabled"
            class="buttons buttons--add"
        >
            {{ $t('global:save') }}
        </v-btn>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        action: {
            type: String,
        },
    },
    data() {
        return {
            disabled: false,
        }
    },
    computed: {
        ...mapGetters(['getRentAnnexDetails', 'getLastValueRentInfo']),
    },
    methods: {
        ...mapActions([
            'createRentAnnex',
            'updateRentAnnex',
            'fetchRentAnnexTable',
            'fetchLastRentInfo'
        ]),
        async save() {
            this.disabled = true
            let success = await this.createRentAnnex({
                id: this.$route.params.id,
            })
            if (success) {
                await this.fetchRentAnnexTable({id:this.$route.params.id})
                await this.fetchLastRentInfo({id:this.$route.params.id})
                this.$emit('closeModal')
            }
            this.disabled = false
        },
        async edit() {
            this.disabled = true
            let success = await this.updateRentAnnex({
                id: this.$route.params.id,
            })
            if (success) {
                 await this.fetchRentAnnexTable({id:this.$route.params.id})
                await this.fetchLastRentInfo({id:this.$route.params.id})
                this.$emit('closeModal')
            }
            this.disabled = false
        },
    },
}
</script>
