import Rest from './Rest'
import store from '../store'
import i18n from './i18next'
const sendInformationMail = async (state, payload) => {
    const departmentsUsers = state.details.to.departments.filter(
        e => e.users && e.users.length > 0
    )
    const mailBody = {
        _id: state.details._id,
        case: payload.case,
        subject: payload.subject,
        mail: [
            ...state.details.to.users.map(el => el.mail),
            ...departmentsUsers.flatMap(el => el.users).map(user => user.mail),
        ],
        details: payload.details,
        // details: {
        //     createdBy: `${state.details.createdBy.name} ${state.details.createdBy.lastname}`,
        //     title: state.details.title,
        //     info: state.details.info,
        // },
    }

    try {
        await new Rest('POST', `/${payload.route}/sendMail`)
            .setBody(mailBody)
            .send()
        store.commit('setSnackbars', {
            type: 'success',
            text: `${i18n.t('global:emailSend')}!`,
        })
    } catch (err) {
        console.error(err.response)
    }
}

export default { sendInformationMail }
