<template>
    <tbody>
        <tr
            v-for="(item, index) in items"
            :key="index"
            :class="`${isStatusClosed(item) ? 'disabled' : ''}`"
        >
            <!-- === === DEVICES === === -->
            <td class="truncate font-12 bg--settlements">
                <DoubleLine
                    :firtstLine="`${$get(
                        item,
                        'rentDevices.device.model.manufacturer',
                        ''
                    )} ${$get(item, 'rentDevices.device.model.model', '')}`"
                    :secondLine="`${$get(item, 'rentDevices.device.sn', '')}`"
                />
            </td>
            <!-- === === RENT === === -->
            <td class="truncate font-12 bg--settlements">
                {{
                    item.rentDevices &&
                    item.rentDevices.rent &&
                    item.rentDevices.rent !== 0
                        ? `${item.rentDevices.rent.toFixed(2)} ${currency}`
                        : '-'
                }}
            </td>

            <!-- === === MONO_PRINT_NUMBER_AND_PRICE === === -->
            <td class="truncate font-12 bg--settlements">
                <DoubleLine
                    :firtstLine="item.monthlyMono"
                    :secondLine="
                        (item.monthlyMono * item.rentDevices.monoPrice).toFixed(
                            2
                        ) + currency
                    "
                />
            </td>
            <!-- === === MONO_PACKAGE === === -->

            <td class="truncate font-12 bg--settlements">
                <DoubleLine
                    :firtstLine="$get(item, 'rentDevices.monoBundle') || '-'"
                    :secondLine="
                        +item.rentDevices.monoBundleCost !== 0
                            ? `${(+item.rentDevices.monoBundleCost).toFixed(
                                  2
                              )} PLN`
                            : '-'
                    "
                />
            </td>
            <!-- === === COLOR_PRINT_NUMBER_AND_PRICE === === -->
            <td class="truncate font-12 bg--settlements">
                <DoubleLine
                    :firtstLine="item.monthlyColor"
                    :secondLine="
                        (
                            item.monthlyColor * item.rentDevices.colorPrice
                        ).toFixed(2) + currency
                    "
                />
            </td>
            <!-- === === MONO_PACKAGE === === -->
            <td class="truncate font-12 bg--settlements">
                <DoubleLine
                    :firtstLine="$get(item, 'rentDevices.colorBundle') || '-'"
                    :secondLine="
                        +item.rentDevices.colorBundleCost !== 0
                            ? `${(+item.rentDevices.colorBundleCost).toFixed(
                                  2
                              )} PLN`
                            : '-'
                    "
                />
            </td>
            <!-- === === SCAN_NUMBER_AND_PRICE === === -->
            <td class="truncate font-12 bg--settlements">
                <DoubleLine
                    :firtstLine="item.monthlyScan"
                    :secondLine="
                        (item.monthlyScan * item.rentDevices.scanPrice).toFixed(
                            2
                        ) + currency
                    "
                />
            </td>
            <!-- === === MONITORING_PRICE === === -->
            <td class="truncate font-12 bg--settlements">
                <!-- item.overallPrice && item.overallPrice !== 0
                        ? `${item.overallPrice.toFixed(2)} PLN`
                        : '-' -->
                {{
                    Number($get(item, 'rentDevices.monitoringCost', 0)).toFixed(
                        2
                    ) + currency
                }}
            </td>
            <!-- === === OVERALL_PRICE === === -->
            <td class="truncate font-12 bg--settlements">
                <!-- item.overallPrice && item.overallPrice !== 0
                        ? `${item.overallPrice.toFixed(2)} PLN`
                        : '-' -->
                {{ calcSummary(item) }}
            </td>
            <!-- === === SETTLEMENT_TYPE === === -->
            <!-- {{
                item.settlementType
            }} -->
            <td class="truncate font-12 bg--settlements">
                <SettlementTypeIcon :item="item" />
                <SettlementIcon
                    v-if="item.rentDevices.monoBundle > 0"
                    :iconType="monoBundleResult(item)"
                />
                <SettlementIcon
                    v-if="item.rentDevices.colorBundle > 0"
                    :iconType="colorBundleResult(item)"
                />
            </td>
            <!-- === === BUTTONS === === -->
            <td class="d-flex align-center justify-end bg--settlements">
                <v-btn
                    v-if="false"
                    small
                    icon
                    @click="$emit('confirm-modal', item._id)"
                >
                    <v-icon color="red" small>mdi-delete</v-icon>
                </v-btn>
                <!-- === === TODO ZOSTAWIĆ WŁĄCZONY PRZYCISK TYLKO DLA AKTYWNEGO ZESTAWIENIA === === -->
                <v-btn small icon @click="$emit('settlement-modal', item._id)">
                    <v-icon color="secondary" small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                    small
                    @click="openExternalWindow(item.rentDevices.device._id)"
                    icon
                >
                    <v-icon color="secondary" small
                        >mdi-arrow-right-thick</v-icon
                    >
                </v-btn>
            </td>
        </tr>
        <tr class="font-bold">
            <td class="bg--settlements"></td>
            <td class="bg--settlements">
                {{ summary(items, 'rentDevices.rent').toFixed(2) + currency }}
            </td>
            <td class="bg--settlements">
                <DoubleLine
                    black
                    :firtstLine="summary(items, 'monthlyMono')"
                    :secondLine="
                        summary(items, 'monthlyMono', 'monoPrice').toFixed(2) +
                        currency
                    "
                />
            </td>
            <td class="bg--settlements"></td>
            <td class="bg--settlements">
                <DoubleLine
                    black
                    :firtstLine="summary(items, 'monthlyColor')"
                    :secondLine="
                        summary(items, 'monthlyColor', 'colorPrice').toFixed(
                            2
                        ) + currency
                    "
                />
            </td>
            <td class="bg--settlements"></td>
            <td class="bg--settlements">
                <DoubleLine
                    black
                    :firtstLine="summary(items, 'monthlyScan')"
                    :secondLine="
                        summary(items, 'monthlyScan', 'scanPrice').toFixed(2) +
                        currency
                    "
                />
            </td>
            <td class="bg--settlements">
                {{
                    summary(items, 'rentDevices.monitoringCost').toFixed(2) +
                    currency
                }}
            </td>
            <td class="bg--settlements">
                {{ summary(items, 'overallPrice').toFixed(2) + currency }}
            </td>
            <td class="bg--settlements"></td>
            <td class="bg--settlements d-flex align-center justify-end">
                <v-btn
                    @click="openInvoiceModal"
                    small
                    dark
                    class="buttons--preview"
                    icon
                >
                    <v-icon small>mdi-eye</v-icon>
                </v-btn>
            </td>
        </tr>

        <ConfirmModal
            :openConfirm="openConfirm"
            @close-confirm="closeInvoiceModal"
        >
            <div slot="main">
                <ConfirmContent
                    v-if="isInvoiceAvailable"
                    :isInvoiceAvailable="isInvoiceAvailable"
                    :type="type"
                />
                <InvoiceChooseConfirmInvoiceNotExist
                    v-if="!isInvoiceAvailable"
                    :isInvoiceAvailable="isInvoiceAvailable"
                    :type="type"
                />
            </div>
            <div slot="buttons">
                <div class="d-flex justify-center">
                    <v-btn
                        v-if="isInvoiceAvailable"
                        color="#19aa8d"
                        width="100"
                        height="40"
                        @click="openExistingInvoice()"
                        class="white--text mx-2"
                    >
                        <span>Podgląd</span>
                    </v-btn>
                    <v-btn
                        color="rgb(117, 117, 117)"
                        width="100"
                        height="40"
                        @click="closeInvoiceModal()"
                        class="white--text mx-2"
                    >
                        <span>NIE</span>
                    </v-btn>
                    <v-btn
                        v-if="isInvoiceAvailable"
                        width="100"
                        height="40"
                        color="#445d75"
                        class="white--text mx-2"
                        @click="openConfirmNext()"
                        >TAK</v-btn
                    >
                    <v-btn
                        v-if="!isInvoiceAvailable"
                        width="100"
                        height="40"
                        color="#445d75"
                        class="white--text mx-2"
                        @click="generateNewInovice()"
                        >TAK</v-btn
                    >
                </div>
            </div>
        </ConfirmModal>
        <ConfirmModal
            :openConfirm="confrimNext"
            @close-confirm="closeConfirmNext"
        >
            <div slot="main">
                <ConfirmContentNext
                    :isInvoiceAvailable="isInvoiceAvailable"
                    :type="type"
                />
            </div>
            <div slot="buttons">
                <div class="d-flex justify-center">
                    <v-btn
                        v-if="isInvoiceAvailable"
                        color="rgb(117, 117, 117)"
                        width="100"
                        height="40"
                        @click="closeConfirmNext()"
                        class="white--text mx-2"
                    >
                        <span>NIE</span>
                    </v-btn>
                    <v-btn
                        width="100"
                        height="40"
                        color="#445d75"
                        class="white--text mx-2"
                        @click="generateNewInovice()"
                        >TAK</v-btn
                    >
                </div>
            </div>
        </ConfirmModal>
    </tbody>
</template>
<script>
import SettlementTypeIcon from './../../../Rent-annex/Tabs/Rent-devices/Table/Utils/SettlementTypeIcon.vue'
import SettlementIcon from './Utils/SettlementIcon.vue'
import DoubleLine from '../../../../../../../components/Global/Table/DoubleLine'
import ConfirmContent from './InvoiceChooseConfirm.vue'
import ConfirmContentNext from './InvoiceChooseConfirmNext.vue'
import InvoiceChooseConfirmInvoiceNotExist from './InvoiceChooseConfirmInvoiceNotExist.vue'

import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    props: ['items', 'items2', 'settlement', 'lastSettlementId'],
    data: () => ({
        openConfirm: false,
        removeItem: '',
        currency: ' PLN',
        monoBundle: null,
        colorBundle: null,
        type: '',
        isInvoiceAvailable: false,
        confrimNext: false,
    }),
    components: {
        DoubleLine,
        SettlementIcon,
        SettlementTypeIcon,
        ConfirmContent,
        ConfirmContentNext,
        InvoiceChooseConfirmInvoiceNotExist,
    },
    computed: {
        ...mapGetters(['getSelectedSettlementToPdf', 'getLastValueRentInfo']),
    },
    methods: {
        ...mapActions([
            'setSelectedSettlementToPdf',
            'createInvoice',
            'fetchRentSettlementInstanceTable',
            'findLastInvoiceForSettlement',
        ]),

        openExternalWindow(deviceId) {
            if (deviceId) {
                const win = window.open(
                    `#/devices/${deviceId}`,
                    '_blank'
                    // 'height=900, width=1400'
                )
                win.onload = e => {
                    const timeOut = setTimeout(() => {
                        e.currentTarget.onbeforeunload = () => {}
                        e.currentTarget.onunload = () => {}
                        clearTimeout(timeOut)
                    }, 500)
                }
            }
        },
        summary(items, value, price) {
            const arr = []
            items.forEach(element => {
                if (price) {
                    arr.push(
                        this.$get(element, value, 0) *
                            element.rentDevices[price]
                    )
                } else {
                    arr.push(this.$get(element, value, 0))
                }
            })

            return arr.reduce((total, amount) => total + amount, 0)
        },

        monoBundleResult(item) {
            if (item.currentMonoCounter > item.rentDevices.monoBundle) {
                return 'MONO+'
            } else if (item.currentMonoCounter < item.rentDevices.monoBundle) {
                return 'MONO'
            } else return ''
        },

        colorBundleResult(item) {
            if (item.currentColorCounter > item.rentDevices.colorBundle) {
                return 'COLOR+'
            } else if (
                item.currentcolorCounter < item.rentDevices.colorBundle
            ) {
                return 'COLOR'
            } else return ''
        },

        calcSummary(settlement) {
            const rentDevice = settlement.rentDevices
            const monthlyMono = settlement.monthlyMono
            const monthlyColor = settlement.monthlyColor
            const monthlyScan = settlement.monthlyScan

            if (rentDevice) {
                const monoBundleSize = this.$get(rentDevice, 'monoBundle', 0)
                const monoBundleCost = this.$get(
                    rentDevice,
                    'monoBundleCost',
                    0
                )
                const monoSinglePrice = this.$get(rentDevice, 'monoPrice', 0)
                const colorBundleSize = this.$get(rentDevice, 'colorBundle', 0)
                const colorBundleCost = this.$get(
                    rentDevice,
                    'colorBundleCost',
                    0
                )
                const colorSinglePrice = this.$get(rentDevice, 'colorPrice', 0)
                const scanSinglePrice = this.$get(rentDevice, 'scanPrice', 0)
                const monitoringCost = this.$get(
                    rentDevice,
                    'monitoringCost',
                    0
                )
                let wynik = 0
                if (rentDevice.rent) {
                    wynik += rentDevice.rent
                }
                // MONO
                if (monthlyMono <= monoBundleSize) {
                    wynik += monoBundleCost
                } else {
                    wynik +=
                        monoBundleCost +
                        (monthlyMono - monoBundleSize) * monoSinglePrice
                }
                //COLOR
                if (monthlyColor <= colorBundleSize) {
                    wynik += colorBundleCost
                } else {
                    wynik +=
                        colorBundleCost +
                        (monthlyColor - colorBundleSize) * colorSinglePrice
                }
                // SCAN
                if (monthlyScan > 0) {
                    wynik += monthlyScan * scanSinglePrice
                }
                if (monitoringCost) {
                    wynik += monitoringCost
                }

                return wynik.toFixed(2) + this.currency
            }
            return ''
        },
        goToSettlement(type) {
            let routeData = this.$router.resolve({
                name: 'anexDocumentGenerator',
                query: {
                    id: this.$route.params.id,
                
                },
                params: {
                    test: '', // or anything you want
                },
            })

            window.open(routeData.href, '_blank')
        },
        openConfirmNext() {
            this.confrimNext = true
        },
        closeConfirmNext() {
            this.confrimNext = false
        },
        async openInvoiceModal() {
            this.openConfirm = true
        },
        async closeInvoiceModal() {
            this.openConfirm = false
        },
        openSelectEmailMessageModal() {
            this.openEmailMessageContentConfirm = true
        },

        closeEmailMessageContentConfirm() {
            this.openEmailMessageContentConfirm = false
        },

        async openExistingInvoice() {
            const lastExistingInvoice = await this.findLastInvoiceForSettlement(
                { index: this.settlement.index }
            )
            const data = {
                settlementTableData: lastExistingInvoice,
            }

            const client = {
                client: this.getLastValueRentInfo,
            }

            sessionStorage.setItem(
                'selectedSettlementToPdf',
                JSON.stringify(data)
            )
            sessionStorage.setItem(
                'clientSettlementToPdf',
                JSON.stringify(client)
            )

            const devices = {
                devices: this.items,
            }

            sessionStorage.setItem(
                'devicesSettlementToPdf',
                JSON.stringify(devices)
            )

            this.goToSettlement('preview')
            this.openConfirm = false
        },
        async generateNewInovice() {
            await this.createInvoice({
                content: this.settlement,
            })
            const lastExistingInvoice = await this.findLastInvoiceForSettlement(
                { index: this.settlement.index }
            )

            const data = {
                settlementTableData: lastExistingInvoice,
            }

            await this.fetchRentSettlementInstanceTable({
                id: this.$route.params.id,
            })

            sessionStorage.setItem(
                'selectedSettlementToPdf',
                JSON.stringify(data)
            )

            const client = {
                client: this.getLastValueRentInfo,
            }

            sessionStorage.setItem(
                'clientSettlementToPdf',
                JSON.stringify(client)
            )

            const devices = {
                devices: this.items,
            }

            sessionStorage.setItem(
                'devicesSettlementToPdf',
                JSON.stringify(devices)
            )

            this.goToSettlement('preview')
            this.openConfirm = false
            this.isInvoiceAvailable = await this.checkIsInvoiceAvailable()
        },
        async checkIsInvoiceAvailable() {
            const lastExistingInvoice = await this.findLastInvoiceForSettlement(
                { index: this.settlement.index }
            )

            if (lastExistingInvoice) {
                return true
            } else {
                return false
            }
        },
    },
    async mounted() {
        this.isInvoiceAvailable = await this.checkIsInvoiceAvailable()
        await this.fetchRentSettlementInstanceTable({
            id: this.$route.params.id,
        })
    },
}
</script>
<style lang="sass">
.bg--settlements
    background-color: #f6f7e6
.font-12
    font-size: 12px !important

.font-bold
    font-weight: 700
</style>
