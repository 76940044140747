import Rest from './../../../plugins/Rest'
import clone from 'lodash.clone'
import i18n from './../../../plugins/i18next'
import validation from './../../../plugins/validation'
import moment from 'moment'
import clearSearch from './../../../plugins/clearSearch'
const state = {
    modal: {
        fields: {
            model: '',
            typeOfCar: '',
            registrationNumber: '',
            dateOfFirstRegistration: '',
            registrationIDNumber: '',
            insuranceUpTo: '',
            dateOfTheNextInspection: '',
            vin: '',
            yearOfProduction: '',
            tireSize: '',
            warrantyUntil: '',
            property: '',
            driver: '',
            description: '',
            createdBy: '',
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {
                    model: '',
                    registrationNumber: '',
                    registrationIDNumber: '',
                    vin: '',
                },
            },
            stepTwo: {
                validation: true,
                fields: {
                    dateOfFirstRegistration: '',
                    insuranceUpTo: '',
                    dateOfTheNextInspection: '',
                    yearOfProduction: '',
                    warrantyUntil: '',
                },
            },
        },
        step: 1,
    },
    request: {
        populate: [
            {
                localField: 'driver',
                foreignField: '_id',
                from: 'users',
                as: 'driver',
            },
            {
                localField: 'currentMenager',
                foreignField: '_id',
                from: 'users',
                as: 'currentMenager',
            },
        ],
        searchFields: ['model', 'driver.name'],
        defaultSearchFields: ['model', 'driver.name'],
        select:
            'isDeleted _id model registrationNumber driver dateOfTheNextInspection insuranceUpTo yearOfProduction status folders vin typeOfCar leasing',
        where: {
            isDeleted: false,
        },
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: '-createdAt',
        loading: false,
    },
    statuses: [],
    details: {},
    registry: {},
    dictFields: { model: '', typeOfCar: '', property: '' },
}

const getters = {
    getCarModal: state => state.modal.fields,
    getCarError: state => state.modal.errors,
    getCarStep: state => state.modal,
    getCarsTable: state => state.table,
    getCarStatuses: state => state.statuses,
    getCarsSearchFields: state => state.request,
    getCarDetails: state => state.details,
    getCarsRegistry: state => state.registry,
}

const actions = {
    async fetchCarsTable({ commit }) {
        state.table.loading = true
        try {
            let table = new Rest('GET', 'cars')
                .select(state.request.select)
                .limit(state.table.limit)
                .page(state.table.page)
                .search(state.request.searchFields, state.table.search)
                .where(state.request.where)
                .populate(state.request.populate)
                .sort(state.table.sort || state.table.defaultSort)
                .send()

            let registryReq = new Rest('GET', 'registries')
                .select('currentMenager name')
                .where({ url: 'cars' })
                .populate([
                    {
                        path: 'currentMenager',
                        select: 'name lastname mail status phone picture',
                    },
                ])
                .send()
            let { 0: response, 1: registry } = await Promise.all([
                table,
                registryReq,
            ])
            commit('setCarsTable', response.data)
            commit('setCarsRegistry', registry.data.items)
        } catch (err) {
            console.error(err)
        }
        state.table.loading = false
    },
    async createCar({ commit, dispatch }, payload) {
        try {
            await new Rest('POST', 'cars').setBody(state.modal.fields).send()
            commit('clearCarsModal')
            commit('clearCarErrors')
            dispatch('fetchCarsTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            //HANDLE ERRORS
            if (err) {
                commit('setSnackbars', { type: 'error' })
                validation.setErrors(state.modal.errors, err)
            }
        }
    },

    async fetchCar({ state, commit }, payload) {
        try {
            let car = await new Rest('GET', `cars/${payload.id}`)
                .populate([
                    {
                        path: 'createdBy',
                        select: 'name lastname mail status phone picture',
                    },
                    {
                        path: 'driver',
                        select: 'name lastname ',
                    },
                    {
                        path: 'leasing',
                        select: 'leasingNumber',
                    },
                ])
                .select('-folders')
                .send()

            let statusesReq = new Rest('GET', `registries/cars/statuses`)
                .select('name _id isDeleted color state')
                .where({ isDeleted: false })
                .send()

            let registryReq = new Rest('GET', 'registries')
                .select('currentMenager name')
                .where({ url: 'cars' })
                .populate([
                    {
                        path: 'currentMenager',
                        select: 'name lastname mail status phone picture',
                    },
                ])
                .send()
            let { 0: response, 1: statuses, 2: registry } = await Promise.all([
                car,
                statusesReq,
                registryReq,
            ])
         
            commit('setCarDetails', response.data)
            commit('setCarStatuses', statuses.data.items)
            commit('setCarsRegistry', registry.data.items)

            if (payload.next) payload.next()
        } catch (err) {
            console.error(err)
        }
    },
    async updateCar({ commit, dispatch }, payload) {
        try {
            let response = await new Rest('PUT', `cars/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            dispatch('fetchCar', { id: payload.id })
            commit('clearCarsModal')
            commit('clearCarErrors')
            return true
        } catch (err) {
            //HANDLE ERRORS
            validation.setErrors(state.modal.errors, err)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async updateCarStatus({ commit, dispatch }, payload) {
        commit('setCarModal')
        commit('setCarStatus', payload.status)
        try {
            let response = await new Rest('PUT', `cars/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            commit('setSnackbars', { type: 'success' })
        } catch (err) {
            console.error(err)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async deleteCar({ commit, dispatch }, payload) {
        try {
            await new Rest('DELETE', `cars/${payload.id}`).send()
            dispatch('fetchCarsTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            console.error(err)
        }
    },
}

const mutations = {
    setCarsTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
    clearCarsModal(state, payload) {
        state.modal.step = 1
        state.modal.fields = {
            model: '',
            typeOfCar: '',
            registrationNumber: '',
            dateOfFirstRegistration: '',
            registrationIDNumber: '',
            insuranceUpTo: '',
            dateOfTheNextInspection: '',
            vin: '',
            yearOfProduction: '',
            tireSize: '',
            warrantyUntil: '',
            property: '',
            driver: '',
            description: '',
        }
    },
    setCarsSearch(state, payload) {
        state.request.searchFields = payload
    },
    setCarDetails(state, payload) {
        state.details = payload
    },
    setCarStatus(state, payload) {
        state.modal.fields.status = payload
    },
    setCarStatuses(state, payload) {
        state.statuses = payload
    },
    setCarModal(state, payload) {
        let obj = payload ? payload : clone(state.details)
        state.modal.fields = obj
        state.modal.fields.dateOfFirstRegistration = obj.dateOfFirstRegistration
            ? moment(obj.dateOfFirstRegistration).format('YYYY-MM-DD')
            : ''
        state.modal.fields.insuranceUpTo = obj.insuranceUpTo
            ? moment(obj.insuranceUpTo).format('YYYY-MM-DD')
            : ''
        state.modal.fields.dateOfTheNextInspection = obj.dateOfTheNextInspection
            ? moment(obj.dateOfTheNextInspection).format('YYYY-MM-DD')
            : ''
        state.modal.fields.warrantyUntil = obj.warrantyUntil
            ? moment(obj.warrantyUntil).format('YYYY-MM-DD')
            : ''
        state.modal.fields.yearOfProduction = obj.yearOfProduction
            ? moment(obj.yearOfProduction).format('YYYY-MM-DD')
            : ''
    },

    setCarsRegistry(state, payload) {
        state.registry = payload[0]
    },
    clearCarErrors(state) {
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step].fields) {
                state.modal.errors[step].fields[key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
    clearCarsTable(state) {
        clearSearch.clearSearchTable(state)
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
