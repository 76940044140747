<template>
    <div>
        <v-btn
            @click="action == 'create' ? save() : edit()"
            class="buttons buttons--add"
            :disabled="disabled"
        
        >
            <span v-if="disabled" class="body-2 white--text"
                >{{ setProgress }}%</span
            >
            <span v-else>{{ $t('global:save') }}</span>
        </v-btn>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        action: String,
    },
    data() {
        return {
            disabled: false,
        }
    },
    computed: {
        ...mapGetters(['getProgressFiles']),
        setProgress: {
            get() {
                let result =
                    Math.round(
                        (this.getProgressFiles.sentSize * 100) /
                            this.getProgressFiles.totalSize
                    ) || 0
                if (result > 100) return 100
                else return result
            },
        },
    },
    methods: {
        ...mapActions(['createFile', 'updateFile']),
        async save() {
            this.disabled = true
            let success = await this.createFile({
                routeId: this.$route.params.id,
                routeName: this.$route.name,
            })
            if (success) this.$emit('closeModal')
            this.disabled = false
        },
        async edit() {
            this.disabled = true
            let success = await this.updateFile({
                routeId: this.$route.params.id,
                routeName: this.$route.name,
            })
            if (success) this.$emit('closeModal')
            this.disabled = false
        },
    },
}
</script>
