var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"p-relative"},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"12"}},[_c('RegistryHeader',{attrs:{"title":_vm.getRentInfoRegistry.name,"avatarData":_vm.getRentInfoRegistry.currentMenager},on:{"clearSearch":_vm.clearTable}}),_c('Table',{attrs:{"title":_vm.getRentInfoRegistry.name,"items":_vm.getRentInfoTable.items,"headers":_vm.headers,"length":_vm.getRentInfoTable.pages,"page":_vm.getRentInfoTable.page,"defaultFields":_vm.getRentInfoSearchFields.searchFields,"loading":_vm.getRentInfoTable.loading},on:{"search":_vm.setSearch,"search-fields":_vm.setSearchFields,"change-page":_vm.setPage,"sort":_vm.setSort,"clear-table":_vm.clearTable},scopedSlots:_vm._u([{key:"columns",fn:function({ isExpanded, expand }){return [_c('Columns',{attrs:{"headers":_vm.headers,"items":_vm.getRentInfoTable.items,"isExpanded":isExpanded,"expand":expand}})]}}])}),_c('Modal',{attrs:{"title":_vm.$t('rent-contracts:rent-info.adding'),"open":_vm.open},on:{"close":_vm.closeModal}},[_c('AEContent',{attrs:{"slot":"AEContent"},slot:"AEContent"}),_c('Buttons',{attrs:{"slot":"buttons","action":'create'},on:{"closeModal":function($event){_vm.open = false}},slot:"buttons"})],1),_c('AlertModalAnnex',{attrs:{"open":_vm.openAlertModal,"onClose":_vm.onCloseAlert,"annexCnt":_vm.annexCnt,"nameSpaceI18n":"rent-contracts"}}),_c('div',{staticClass:"buttons--switch d-flex ml-2"},[_c('v-switch',{attrs:{"color":"success","value":"success"},model:{value:(_vm.bottomSwitch),callback:function ($$v) {_vm.bottomSwitch=$$v},expression:"bottomSwitch"}}),(_vm.bottomSwitch)?_c('RaportButtons',{staticClass:"ml-1 mt-2",attrs:{"buttonType":'todaySettlements',"disabled":_vm.getTodayRent && _vm.getTodayRent.length > 0
                            ? false
                            : true},on:{"raport-function":function($event){return _vm.showUnsettled()}}}):_vm._e(),(_vm.bottomSwitch)?_c('FilterContractBeforeDateBtn',{staticClass:"ml-1 mt-2",attrs:{"color":'error',"icon":'mdi-exclamation-thick',"tooltip":'Nierozliczone przed ' +
                        _vm.getCurrentDay() +
                        ' dniem miesiąca'},on:{"filter-contract-before-date":function($event){return _vm.filterContractBeforeDate()}}}):_vm._e(),(_vm.bottomSwitch)?_c('FilterContractAfterDateBtn',{staticClass:"ml-1 mt-2",attrs:{"color":'#6571B1',"icon":'mdi-currency-usd',"tooltip":'Do rozliczenia po ' +
                        _vm.getCurrentDay() +
                        ' dniu miesiąca'},on:{"filter-contract-after-date":function($event){return _vm.filterContractAfterDate()}}}):_vm._e(),(_vm.bottomSwitch)?_c('DownloadRepresentativesButton',{staticClass:"ml-2 mt-2",attrs:{"buttonType":'todaySettlements',"disabled":false},on:{"open-agree-modal":function($event){return _vm.openCSVRepresentativeModal()}}}):_vm._e()],1),_c('div',{staticClass:"d-flex justify-end buttons--action"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(_vm.add())?_c('v-btn',_vm._g({staticClass:"buttons--add margin--1",attrs:{"data-cy":"addEntry","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.addRentInfo()}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('rent-contracts:addRentInfo')))])])],1)],1)],1),_c('ConfirmModal',{attrs:{"width":'500',"openConfirm":_vm.openDownloadRepresentativeCSV}},[_c('div',{attrs:{"slot":"main"},slot:"main"},[_c('DownloadRepresentativeModalContent')],1),_c('div',{attrs:{"slot":"buttons"},slot:"buttons"},[_c('CSVRepresenetativeModalButtons',{attrs:{"data":_vm.getDataToCSVRepresentativeModal()},on:{"close-csv-representative-modal":_vm.closeCSVRepresentativeModal}})],1)]),_c('v-overlay',{attrs:{"value":_vm.getCSVFileLoading},nativeOn:{"dblclick":function($event){return _vm.closeOnClick.apply(null, arguments)}}},[_c('div',[_c('div',{staticStyle:{"display":"grid","justify-items":"center"}},[_c('span',{staticStyle:{"font-size":"1.3em"}},[_vm._v(" Trwa rozliczanie z pliku CSV ")]),_c('v-progress-circular',{attrs:{"size":20,"width":2,"indeterminate":"","color":"white"}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }