import Rest from '../../plugins/Rest'

const state = {
    modal: {
        fields: {},
        errors: {
            stepOne: {
                validation: true,
            },
        },
        steps: '',
    },
    request: {
        populate: [
            {
                path: 'user',
                select: 'name lastname loginTries',
            },
        ],
        searchFields: ['name'],
        select: 'user methodsActivity',
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: '',
    },
    statistics: {},
    userActivityData: {},
    usersTable: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 9,
        pages: 1,
        defaultSort: '-lastLoginTry',
    },
}

const getters = {
    getUsersActivityTable: state => state.table,
    getUsersActivityErrors: state => state.modal.errors,
    getActivityStatistics: state => state.statistics,
    getUserActivityData: state => state.userActivityData,
    getUsersLoginTable: state => state.usersTable,
    getUsersLoginSearchField: state => state.request.searchFields,
}

const actions = {
    async fetchLoginTable({ state, commit }, payload) {
        let usersReq = new Rest('GET', '/activity/users')
            .select(state.request.select)
            .limit(state.table.limit)
            .page(state.table.page)
            .where({ date: payload.date })
            .populate(state.request.populate)
            .sort(state.table.sort || state.table.defaultSort)
            .send()
        let statisticsReq = new Rest('GET', '/activity/statistics')
            .addParams({ date: payload.date })
            .send()
        let { 0: users, 1: statistics } = await Promise.all([
            usersReq,
            statisticsReq,
        ])
        commit('setLoginStatistics', statistics.data)
        commit('setLoginTable', users.data)
    },
    async fetchUserActivityData({ state, commit }, payload) {
        let statistics = await new Rest('GET', '/activity/statistics')
            .addParams({ date: payload.date })
            .send()
        let user = await new Rest('GET', `/activity/users/${payload.id}`)
            .addParams({ date: payload.date })
            .populate(state.request.populate)
            .send()
        commit('setLoginStatistics', statistics.data)
        commit('setUserActivityData', user.data)
    },
    async fetchUsersManagment({ state, commit }, payload) {
        let users = await new Rest('GET', 'users')
            .select('name lastname loginTries isBanned lastLoginTry')
            .limit(state.usersTable.limit)
            .page(state.usersTable.page)
            .search(state.request.searchFields, state.usersTable.search)
            .sort(state.usersTable.sort || state.usersTable.defaultSort)
            .send()
        commit('setUsersLoginTable', users.data)
    },
    async updateUserManagment({ state, commit }, payload) {
        await new Rest('PUT', `/users/${payload._id}`).setBody(payload).send()
    },
}

const mutations = {
    setLoginTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
    setLoginStatistics(state, payload) {
        state.statistics = payload
    },
    setUserActivityData(state, payload) {
        state.userActivityData = payload
    },
    setUsersLoginTable(state, payload) {
        state.usersTable.items = payload.items || state.usersTable.items
        state.usersTable.total = payload.total || state.usersTable.total
        if (payload.search != undefined)
            state.usersTable.search = payload.search
        state.usersTable.page = payload.page || state.usersTable.page
        state.usersTable.limit = payload.limit || state.usersTable.limit
        state.usersTable.pages = Math.ceil(
            state.usersTable.total / state.usersTable.limit
        )
        if (payload.sort != undefined) state.usersTable.sort = payload.sort
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
