<template>
    <v-container style="height: 100%;" class="pt-0">
        <v-row style="height: 100%;">
            <v-col style="height: 100%;" cols="12" class="pa-0">
                <v-stepper
                    style="height: 100%;"
                    v-model="getRentInfoErrors.step"
                    class="elevation-0"
                >
                    <template style="height: 100%;">
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getRentInfoErrors.stepOne.validation,
                                ]"
                                editable
                                step="1"
                                >{{ $t('global:basicInfo') }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getRentInfoErrors.stepTwo.validation,
                                ]"
                                editable
                                step="2"
                                >{{
                                    $t('rent-contracts:rent-info.contractInfo')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () =>
                                        getRentInfoErrors.stepThree.validation,
                                ]"
                                editable
                                step="3"
                                >Ustawienia</v-stepper-step
                            >

                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () =>
                                        getRentInfoErrors.stepThree.validation,
                                ]"
                                editable
                                step="4"
                                >{{
                                    $t('rent-contracts:rent-info.notes')
                                }}</v-stepper-step
                            >
                        </v-stepper-header>
                        <v-stepper-items
                            class="itemsContent"
                            style="height: 100%;"
                        >
                            <v-stepper-content
                                style="
                                    height: 100%;
                                    display: flex;
                                    flex-direction: column;
                                "
                                class="pb-0 pt-4"
                                step="1"
                            >
                                <Separator :text="$t($t('global:basicInfo'))" />
                                <v-row>
                                    <v-col>
                                        <v-row>
                                            <v-col cols="5">
                                                <v-text-field
                                                    dense
                                                    v-model="
                                                        getRentInfoModal.annex
                                                            .title
                                                    "
                                                    :label="`${$t(
                                                        'rent-contracts:rent-info.title'
                                                    )}`"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="3" offset="1">
                                                <v-menu
                                                    v-model="datepickerStart"
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    transition="scale-transition"
                                                    nudge-bottom="45"
                                                    min-width="290px"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs,
                                                        }"
                                                    >
                                                        <v-text-field
                                                            v-model="
                                                                getRentInfoModal
                                                                    .annex
                                                                    .startDate
                                                            "
                                                            dense
                                                            :label="
                                                                $t(
                                                                    'rent-contracts:rent-info.startDate'
                                                                )
                                                            "
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            prepend-icon="mdi-calendar"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="
                                                            getRentInfoModal
                                                                .annex.startDate
                                                        "
                                                        @input="
                                                            datepickerStart = false
                                                        "
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-menu
                                                    v-model="
                                                        datepickerExpiration
                                                    "
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    transition="scale-transition"
                                                    nudge-bottom="45"
                                                    min-width="290px"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs,
                                                        }"
                                                    >
                                                        <v-text-field
                                                            v-model="
                                                                getRentInfoModal
                                                                    .annex
                                                                    .expirationDate
                                                            "
                                                            dense
                                                            :label="
                                                                $t(
                                                                    'rent-contracts:rent-info.expirationDate'
                                                                )
                                                            "
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            prepend-icon="mdi-calendar"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="
                                                            getRentInfoModal
                                                                .annex
                                                                .expirationDate
                                                        "
                                                        @input="
                                                            datepickerExpiration = false
                                                        "
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="3">
                                                <v-text-field
                                                    :disabled="
                                                        contractNoDisabled
                                                    "
                                                    :readonly="!isManager()"
                                                    v-model="
                                                        getRentInfoModal.contractNo
                                                    "
                                                    :label="`${$t(
                                                        'rent-contracts:rent-info.contractNo'
                                                    )}*`"
                                                    :error-messages="
                                                        getRentInfoErrors
                                                            .stepOne.fields
                                                            .contractNo
                                                    "
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-text-field
                                                    v-model="
                                                        getRentInfoModal.externalContractNo
                                                    "
                                                    :label="`${$t(
                                                        'rent-contracts:rent-info.externalContractNo'
                                                    )}*`"
                                                    :error-messages="
                                                        getRentInfoErrors
                                                            .stepOne.fields
                                                            .externalContractNo
                                                    "
                                                >
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="9">
                                                <v-combobox
                                                    dense
                                                    :error-messages="
                                                        getRentInfoErrors
                                                            .stepOne.fields
                                                            .contractor
                                                    "
                                                    v-model="
                                                        getRentInfoModal.annex
                                                            .contractor
                                                    "
                                                    :label="`${$t(
                                                        'rent-contracts:rent-info.contractor'
                                                    )}*`"
                                                    hint="Zacznij pisać..."
                                                    @keyup="
                                                        setContacts(
                                                            $event.target.value
                                                        )
                                                    "
                                                    @input="assignID()"
                                                    :hide-no-data="hideContacts"
                                                    :items="
                                                        getContactsInput.items
                                                    "
                                                    item-text="name"
                                                    item-value="_id"
                                                    return-object
                                                >
                                                </v-combobox>
                                            </v-col>

                                            <v-col>
                                                <v-checkbox
                                                    class="push-top"
                                                    v-model="purchaserCheckbox"
                                                    :label="`${$t(
                                                        'rent-contracts:rent-info.buyer'
                                                    )}`"
                                                ></v-checkbox>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="9">
                                                <v-combobox
                                                    :label="`${$t(
                                                        'rent-contracts:rent-info.representative'
                                                    )}`"
                                                    v-model="
                                                        getRentInfoModal.annex
                                                            .representative
                                                    "
                                                    @keyup="
                                                        setRepresentatives(
                                                            $event.target.value
                                                        )
                                                    "
                                                    :item-text="text"
                                                    return-object
                                                    append-outer-icon="mdi-account-plus"
                                                    item-value="_id"
                                                    :items="
                                                        getRepresentativeInput.items
                                                    "
                                                    dense
                                                    ><template
                                                        slot="selection"
                                                        slot-scope="data"
                                                    >
                                                        <span
                                                            >{{
                                                                data.item.name
                                                            }}
                                                            {{
                                                                data.item
                                                                    .lastname
                                                            }}
                                                            <span
                                                                v-if="
                                                                    $get(
                                                                        data.item,
                                                                        'email',
                                                                        ''
                                                                    )
                                                                "
                                                            >
                                                                •
                                                                {{
                                                                    $get(
                                                                        data.item,
                                                                        'email',
                                                                        ''
                                                                    )
                                                                }}</span
                                                            >
                                                            <span
                                                                v-if="
                                                                    $get(
                                                                        data.item,
                                                                        'phoneNumber',
                                                                        ''
                                                                    )
                                                                "
                                                            >
                                                                •
                                                                {{
                                                                    $get(
                                                                        data.item,
                                                                        'phoneNumber',
                                                                        ''
                                                                    )
                                                                }}</span
                                                            ></span
                                                        >
                                                    </template>
                                                    <template
                                                        slot="item"
                                                        slot-scope="data"
                                                    >
                                                        <span
                                                            >{{
                                                                data.item.name
                                                            }}
                                                            {{
                                                                data.item
                                                                    .lastname
                                                            }}
                                                            <span
                                                                v-if="
                                                                    $get(
                                                                        data.item,
                                                                        'email',
                                                                        ''
                                                                    )
                                                                "
                                                            >
                                                                •
                                                                {{
                                                                    $get(
                                                                        data.item,
                                                                        'email',
                                                                        ''
                                                                    )
                                                                }}</span
                                                            >
                                                            <span
                                                                v-if="
                                                                    $get(
                                                                        data.item,
                                                                        'phoneNumber',
                                                                        ''
                                                                    )
                                                                "
                                                            >
                                                                •
                                                                {{
                                                                    $get(
                                                                        data.item,
                                                                        'phoneNumber',
                                                                        ''
                                                                    )
                                                                }}</span
                                                            ></span
                                                        >
                                                    </template>
                                                    <template
                                                        slot="append-outer"
                                                    >
                                                        <v-menu
                                                            :close-on-content-click="
                                                                false
                                                            "
                                                            :close-on-click="
                                                                false
                                                            "
                                                            @input="
                                                                v =>
                                                                    v ||
                                                                    clearMiniModal()
                                                            "
                                                            max-width="300"
                                                            min-width="300"
                                                            origin="center"
                                                            v-model="showMenu"
                                                        >
                                                            <template
                                                                v-slot:activator="{
                                                                    on: menu,
                                                                }"
                                                            >
                                                                <v-tooltip top>
                                                                    <template
                                                                        v-slot:activator="{
                                                                            on: tooltip,
                                                                        }"
                                                                    >
                                                                        <v-btn
                                                                            small
                                                                            icon
                                                                        >
                                                                            <v-icon
                                                                                v-on="{
                                                                                    ...menu,
                                                                                    ...tooltip,
                                                                                }"
                                                                                @click="
                                                                                    showMenu = true
                                                                                "
                                                                                >mdi-plus</v-icon
                                                                            >
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>{{
                                                                        $t(
                                                                            'global:addRepresentative'
                                                                        )
                                                                    }}</span>
                                                                </v-tooltip>
                                                            </template>
                                                            <v-card>
                                                                <v-container>
                                                                    <v-row>
                                                                        <v-col
                                                                            cols="12"
                                                                        >
                                                                            <h3>
                                                                                {{
                                                                                    `${$t(
                                                                                        'global:additionRepresentative'
                                                                                    )}`
                                                                                }}
                                                                            </h3>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row>
                                                                        <v-col
                                                                            cols="12"
                                                                        >
                                                                            <v-text-field
                                                                                dense
                                                                                v-model="
                                                                                    getRepresentativeModal.name
                                                                                "
                                                                                :label="`${$t(
                                                                                    'global:representativeName'
                                                                                )}`"
                                                                            >
                                                                            </v-text-field>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row>
                                                                        <v-col
                                                                            cols="12"
                                                                        >
                                                                            <v-text-field
                                                                                dense
                                                                                v-model="
                                                                                    getRepresentativeModal.lastname
                                                                                "
                                                                                :label="`${$t(
                                                                                    'global:representativeLastname'
                                                                                )}`"
                                                                            >
                                                                            </v-text-field>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row>
                                                                        <v-col
                                                                            cols="12"
                                                                        >
                                                                            <v-text-field
                                                                                dense
                                                                                v-model="
                                                                                    getRepresentativeModal.email
                                                                                "
                                                                                :label="`${$t(
                                                                                    'global:representativeEmail'
                                                                                )}`"
                                                                            >
                                                                            </v-text-field>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row>
                                                                        <v-col
                                                                            cols="12"
                                                                        >
                                                                            <v-text-field
                                                                                dense
                                                                                v-model="
                                                                                    getRepresentativeModal.phoneNumber
                                                                                "
                                                                                :label="`${$t(
                                                                                    'global:representativePhoneNumber'
                                                                                )}`"
                                                                            >
                                                                            </v-text-field>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row>
                                                                        <v-col
                                                                            md="12"
                                                                            class="py-0 my-0 d-flex justify-end"
                                                                        >
                                                                            <v-btn
                                                                                class="buttons buttons--cancel mr-2"
                                                                                @click="
                                                                                    clearMiniModal()
                                                                                "
                                                                                >{{
                                                                                    $t(
                                                                                        'cancel'
                                                                                    )
                                                                                }}</v-btn
                                                                            >
                                                                            <v-btn
                                                                                class="buttons buttons--add"
                                                                                :disabled="
                                                                                    disabled
                                                                                "
                                                                                @click="
                                                                                    additionRepresentative()
                                                                                "
                                                                                >{{
                                                                                    $t(
                                                                                        'add'
                                                                                    )
                                                                                }}</v-btn
                                                                            >
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-container>
                                                            </v-card>
                                                        </v-menu>
                                                    </template>
                                                </v-combobox>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="9">
                                                <v-combobox
                                                    v-if="!purchaserCheckbox"
                                                    dense
                                                    :error-messages="
                                                        getRentInfoErrors
                                                            .stepOne.fields
                                                            .contractor
                                                    "
                                                    v-model="
                                                        getRentInfoModal.annex
                                                            .contractorBuyer
                                                    "
                                                    :label="`Kontrahent (nabywca)`"
                                                    hint="Zacznij pisać..."
                                                    @keyup="
                                                        setContacts(
                                                            $event.target.value
                                                        )
                                                    "
                                                    @input="assignID()"
                                                    :hide-no-data="hideContacts"
                                                    :items="
                                                        getContactsInput.items
                                                    "
                                                    item-text="name"
                                                    item-value="_id"
                                                    return-object
                                                >
                                                </v-combobox>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>

                                <v-row style="display: flex;">
                                    <v-col cols="6">
                                        <v-select
                                            v-model="
                                                getRentInfoModal.annex
                                                    .contractType
                                            "
                                            :items="
                                                getRegistryDictionaries.contractType
                                                    ? getRegistryDictionaries.contractType
                                                    : []
                                            "
                                            dense
                                            :label="`${$t(
                                                'rent-contracts:rent-info.contractType'
                                            )}*`"
                                            return-object
                                            item-value="field"
                                            :error-messages="
                                                getRentInfoErrors.stepOne.fields
                                                    .contractType
                                            "
                                            ><template v-slot:item="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                            <template v-slot:selection="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </v-col>

                                    <v-col cols="6">
                                        <v-select
                                            v-model="
                                                getRentInfoModal.annex
                                                    .customerCarer
                                            "
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-info.customerCarer'
                                                )
                                            "
                                            dense
                                            :items="getUsersList"
                                            item-value="_id"
                                            return-object
                                        >
                                            <template
                                                v-slot:selection="selection"
                                            >
                                                <span>{{
                                                    selection.item.name
                                                }}</span>
                                                <span class="ml-1">{{
                                                    selection.item.lastname
                                                }}</span>
                                            </template>
                                            <template v-slot:item="item">
                                                <span>{{
                                                    item.item.name
                                                }}</span>
                                                <span class="ml-1">{{
                                                    item.item.lastname
                                                }}</span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content class="pb-0 pt-4" step="2">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            :text="
                                                $t(
                                                    'rent-contracts:rent-info.contractInfo'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="3">
                                        <v-checkbox
                                            v-model="
                                                getRentInfoModal.annex
                                                    .startBundle
                                            "
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-info.promotionalPeriod'
                                                )
                                            "
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            :disabled="
                                                !getRentInfoModal.annex
                                                    .startBundle
                                            "
                                            v-model="
                                                getRentInfoModal.annex
                                                    .numberOfPromotionalPeriods
                                            "
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-info.numberOfPromotionalPeriod'
                                                )
                                            "
                                            :error-messages="
                                                getRentInfoErrors.stepTwo.fields
                                                    .numberOfPromotionalPeriods
                                            "
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>

                                <!-- <v-col cols="12" class="py-0">
                                    <v-row>
                                        <v-col cols="12" class="py-0">
                                            <v-checkbox
                                                v-model="
                                                    getRentInfoModal.annex
                                                        .startBundle
                                                "
                                                :label="
                                                    $t(
                                                        'rent-contracts:rent-info.startBundle'
                                                    )
                                                "
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4">
                                            <v-text-field
                                                :disabled="
                                                    !getRentInfoModal.annex
                                                        .startBundle
                                                "
                                                @keypress="integralNumbers"
                                                v-model="
                                                    getRentInfoModal.annex
                                                        .monoBundle
                                                "
                                                :label="`${$t(
                                                    'rent-contracts:rent-info.monoBundle'
                                                )}`"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="3">
                                            <money-field
                                                :disabled="
                                                    !getRentInfoModal.annex
                                                        .startBundle
                                                "
                                                class="margin--input"
                                                :readonly="false"
                                                v-model="
                                                    getRentInfoModal.annex
                                                        .monoBundleCost
                                                "
                                                :label="`${$t(
                                                    'rent-contracts:rent-info.monoBundleCost'
                                                )}`"
                                            ></money-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4">
                                            <v-text-field
                                                :disabled="
                                                    !getRentInfoModal.annex
                                                        .startBundle
                                                "
                                                @keypress="integralNumbers"
                                                v-model="
                                                    getRentInfoModal.annex
                                                        .colorBundle
                                                "
                                                :label="`${$t(
                                                    'rent-contracts:rent-info.colorBundle'
                                                )}`"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="3">
                                            <money-field
                                                class="margin--input"
                                                :disabled="
                                                    !getRentInfoModal.annex
                                                        .startBundle
                                                "
                                                :readonly="false"
                                                v-model="
                                                    getRentInfoModal.annex
                                                        .colorBundleCost
                                                "
                                                :label="`${$t(
                                                    'rent-contracts:rent-info.colorBundleCost'
                                                )}`"
                                            ></money-field>
                                        </v-col>
                                    </v-row>
                                </v-col> -->
                                <!-- Zakomentowane wg zadania SER-242 -->
                                <!-- <v-col cols="12" class="py-0">
                                    <v-row>
                                        <v-col cols="12" class="py-0">
                                            <v-checkbox
                                                v-model="
                                                    getRentInfoModal.annex
                                                        .constMonthlyBundle
                                                "
                                                :label="
                                                    $t(
                                                        'rent-contracts:rent-info.constMonthlyBundle'
                                                    )
                                                "
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4">
                                            <v-text-field
                                                :disabled="
                                                    !getRentInfoModal.annex
                                                        .constMonthlyBundle
                                                "
                                                @keypress="integralNumbers"
                                                v-model="
                                                    getRentInfoModal.annex
                                                        .constMonoBundle
                                                "
                                                :label="`${$t(
                                                    'rent-contracts:rent-info.constMonoBundle'
                                                )}`"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="3">
                                            <money-field
                                                :disabled="
                                                    !getRentInfoModal.annex
                                                        .constMonthlyBundle
                                                "
                                                class="margin--input"
                                                :readonly="false"
                                                v-model="
                                                    getRentInfoModal.annex
                                                        .constMonoBundlePrice
                                                "
                                                :label="`${$t(
                                                    'rent-contracts:rent-info.constMonoBundlePrice'
                                                )}`"
                                            ></money-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4">
                                            <v-text-field
                                                :disabled="
                                                    !getRentInfoModal.annex
                                                        .constMonthlyBundle
                                                "
                                                @keypress="integralNumbers"
                                                v-model="
                                                    getRentInfoModal.annex
                                                        .constColorBundle
                                                "
                                                :label="`${$t(
                                                    'rent-contracts:rent-info.constColorBundle'
                                                )}`"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="3">
                                            <money-field
                                                :disabled="
                                                    !getRentInfoModal.annex
                                                        .constMonthlyBundle
                                                "
                                                class="margin--input"
                                                :readonly="false"
                                                v-model="
                                                    getRentInfoModal.annex
                                                        .constColorBundlePrice
                                                "
                                                :label="`${$t(
                                                    'rent-contracts:rent-info.constColorBundlePrice'
                                                )}`"
                                            ></money-field>
                                        </v-col>
                                    </v-row>
                                </v-col> -->
                            </v-stepper-content>
                            <v-stepper-content class="pb-0 pt-4" step="3">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator :text="'Ustawienia'" />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="3">
                                        <v-text-field
                                            dense
                                            ref="accountingDay"
                                            @keypress="integralNumbers"
                                            v-model="
                                                getRentInfoModal.annex
                                                    .accountingDay
                                            "
                                            :label="`${$t(
                                                'rent-contracts:rent-info.accountingDay'
                                            )}`"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col offset="1" cols="3">
                                        <v-text-field
                                            v-model="
                                                getRentInfoModal.annex
                                                    .paymentDeadline
                                            "
                                            dense
                                            :label="`${$t(
                                                'rent-contracts:rent-info.paymentDeadline'
                                            )}`"
                                            prepend-icon="mdi-calendar"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                  
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <v-select
                                            v-model="
                                                getRentInfoModal.annex
                                                    .responseTime
                                            "
                                            :items="
                                                getRegistryDictionaries.responseTime
                                                    ? getRegistryDictionaries.responseTime
                                                    : []
                                            "
                                            :label="`${$t(
                                                'rent-contracts:rent-info.responseTime'
                                            )}`"
                                            return-object
                                            item-value="field"
                                            ><template v-slot:item="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                            <template v-slot:selection="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="3">
                                        <money-field
                                            class="margin--input"
                                            :disabled="false"
                                            :readonly="false"
                                            v-model="
                                                getRentInfoModal.annex
                                                    .implementationCost
                                            "
                                            :label="`${$t(
                                                'rent-contracts:rent-info.implementationCost'
                                            )}`"
                                        ></money-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="3">
                                        <v-text-field
                                            dense
                                            v-model="
                                                getRentInfoModal.annex
                                                    .BIDProject
                                            "
                                            :label="`${$t(
                                                'rent-contracts:rent-info.BIDProject'
                                            )}`"
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content class="pb-0 pt-4" step="4">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            :text="
                                                $t(
                                                    'rent-contracts:rent-info.notes'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-textarea
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-info.notes'
                                                )
                                            "
                                            v-model="
                                                getRentInfoModal.annex.notes
                                            "
                                            no-resize
                                            rows="5"
                                            auto-grow
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { numberRules } from '../../../../validators/formValidation'
export default {
    i18nOptions: { namespaces: ['global'] },
    data() {
        return {
            hideContacts: true,
            disabled: false,
            showMenu: false,
            step: 1,
            editable: true,
            checked: false,
            contractNoDisabled: false,
            datepickerStart: false,
            datepickerExpiration: false,
            startContractNo: '',
            purchaserCheckbox: true,
            datepickerDeadline: false,
            numberRules: numberRules,
        }
    },
    props: ['action'],
    methods: {
        ...mapActions([
            'fetchContactsInputs',
            'createRepresentative',
            'fetchRepresentativesInput',
        ]),
        ...mapMutations(['setContactsInput']),
        text: item => item.name + item.lastname,
        setContacts(search) {
            if (search.length >= 4) {
                this.setContactsInput({ search })
                this.fetchContactsInputs()
                this.hideContacts = false
            } else {
                this.getContactsInput.items = []
                this.hideContacts = true
            }
        },
        assignID() {
            if (
                this.getRentInfoModal.annex.contractor &&
                this.getRentInfoModal.annex.contractor._id
            ) {
                this.getRentInfoModal.annex.representative = null
                this.fetchRepresentativesInput({
                    id: this.getRentInfoModal.annex.contractor._id,
                })
            }
        },
        async additionRepresentative() {
            this.disabled = true
            let success = await this.createRepresentative({
                id: this.getRentInfoModal.annex.contractor._id,
            })
            if (success) {
                this.clearMiniModal()
                this.assignID()
            }

            this.disabled = false
        },
        clearMiniModal() {
            this.showMenu = false
        },
        integralNumbers($event) {
            if ($event.target.value[0] == 0)
                $event.target.value = $event.target.value.slice(1)
            let keyCode = $event.keyCode ? $event.keyCode : $event.which
            if (keyCode < 48 || keyCode > 57) $event.preventDefault()
        },
        isManager() {
            const { _id, isAdmin, isSuperAdmin } = this.getProfileDetails

            return (
                // _id === this.getRentInfoRegistry.currentMenager._id
                this.getRentInfoRegistry.availableMenagers.find(
                    el => el._id === _id
                ) ||
                isAdmin ||
                isSuperAdmin
            )
        },

        getRepresentativeInputEmails() {
            const represntativesWithEmail = this.getRepresentativeInput.items.filter(
                r => Boolean(r.email)
            )
            return represntativesWithEmail || [];
        },

     
    },
    computed: {
        ...mapGetters([
            'getProfileDetails',
            'getRentInfoRegistry',
            'getUsersList',
            'getRentInfoModal',
            'getRentInfoErrors',
            'getRegistryDictionaries',
            'getContactsInput',
            'getRepresentativeInput',
            'getRepresentativeModal',
            'getRentAnnexModalRepresentative',
        ]),

        modalAnnexRepresentativeEmail: {
            get() {
              
                if (this.getRentInfoModal.annex?.representative?.email) {
                    return this.getRentInfoModal.annex.representative.email
                } else {
                    return ''
                }
            },
        },
    },
    watch: {
        'getRentInfoModal.annex.accountingDay': function (val) {
            if (val == 0) this.$refs.accountingDay.reset()
            if (val > 31 || val < 1)
                this.getRentInfoModal.annex.accountingDay = null
        },
        'getRentInfoModal.annex.contractor': function (el) {
            const customerCarer = this.$get(el, 'clientGuardian._id', null)
            if (customerCarer && !this.getRentInfoModal?.annex?.customerCarer) {
                this.getRentInfoModal.annex.customerCarer = customerCarer
            }
        },
        'getRentInfoModal.externalContractNo': function (el) {
            if (el.length > 0) {
                this.getRentInfoModal.contractNo = null
                this.contractNoDisabled = true
            } else {
                if (!this.action == 'edit' || this.isManager()) {
                    this.contractNoDisabled = false
                }
                this.getRentInfoModal.contractNo = this.startContractNo
            }
        },
    },
    mounted() {
        this.startContractNo = this.getRentInfoModal.contractNo
        if (this.action == 'edit' && !this.isManager()) {
            this.contractNoDisabled = true
        }
    },
}
</script>
<style lang="scss">
.itemsContent {
    & * .v-stepper__wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}
</style>
