<template>
    <TableDetails
        :headers="headers"
        :items="getLogsTable.items"
        :length="getLogsTable.pages"
        :page="getLogsTable.page"
        @change-page="setPage"
        @sort="setSort"
        @search="search"
    >
        <Columns slot="columns" :items="getLogsTable.items" />
    </TableDetails>
</template>

<script>
import Columns from './Columns'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import i18next from 'i18next'
export default {
    i18nOptions: { namespaces: ['global'] },
    data() {
        return {
            length: 10,
            headers: [
                {
                    text: this.$t('global:date'),
                    value: 'date',
                    width: '8%',
                },
                {
                    text: this.$t('global:fieldName'),
                    value: 'fieldName',
                    width: '22%',
                },
                {
                    text: this.$t('global:textField'),
                    value: 'textField',
                    width: '52%',
                },
                {
                    text: this.$t('global:worker'),
                    value: 'worker',
                    width: '12%',
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: '6%',
                    sortable: false,
                },
            ],
            items: [
                {
                    logDate: '2020-08-18T08:42:59.323Z',
                    logTime: '2020-08-18T08:42:59.323Z',
                    fieldName: 'Załączniki / Załadowano załącznik',
                    text: 'Główny/citrus-color-cut-2208836.jpg',
                    worker: 'Konto Administratora',
                },
            ],
            json: {},
        }
    },

    components: {
        Columns,
    },

    computed: {
        ...mapGetters(['getLogsTable', 'getProfileDetails']),
    },

    methods: {
        ...mapMutations(['setLogsTable']),
        ...mapActions(['fetchLogs']),
        search(value) {
            value = value ? this.translate(value) : ''
            this.setLogsTable({
                search: value,
            })
            this.fetchLogs({ id: this.$route.params.id, url: this.$route.name })
        },
        setPage(page) {
            this.setLogsTable({ page })
            this.fetchLogs({ id: this.$route.params.id, url: this.$route.name })
        },
        setSort(sort) {
            this.setLogsTable({ sort })
            this.fetchLogs({ id: this.$route.params.id, url: this.$route.name })
        },
        translate(value) {
            return (
                this.buildRegex(this.getKeyByValue(this.json, value)) || value
            )
        },
        getKeyByValue(object, value) {
            return Object.keys(object).filter(key =>
                String(object[key]).toLowerCase().includes(value.toLowerCase())
            )
        },
        buildRegex(listOfWords) {
            if (listOfWords.length > 0) {
                let regex = '(?:[\s]|^)('
                for (let word of listOfWords) {
                    regex += word + '|'
                }
                regex = regex.slice(0, -1)
                regex += ')(?=[\s]|$)'
                return regex
            } else return null
        },
    },
    mounted() {
        let lang = this.getProfileDetails.language
        let logsJson = i18next.getResourceBundle(lang,'logs');
        this.json = i18next.getResourceBundle(lang,this.$route.name);
        this.json = {...this.json,...logsJson}
    },
}
</script>
