import Rest from './../../../plugins/Rest'
import validation from './../../../plugins/validation'
import { clone } from 'lodash'
import moment from 'moment'
import store from '../../../store/index'
import clearSearch from './../../../plugins/clearSearch'
const state = {
    modal: {
        fields: {
            contractor: '',
            representative: '',
            notes: '',
            tenderUrl: '',
            time: '',
            deadline: '',
            tenderNo: '',
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {
                    tenderNo: '',
                    deadline: '',
                    contractor: '',
                },
            },
            stepTwo: {
                validation: true,
                fields: {},
            },
        },
        step: 1,
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: '-createdAt',
        loading: false,
    },
    request: {
        populate: [
            {
                localField: 'contractor',
                foreignField: '_id',
                from: 'contacts',
                as: 'contractor',
            },
            {
                localField: 'createdBy',
                foreignField: '_id',
                from: 'users',
                as: 'createdBy',
            },
        ],
        searchFields: ['index', 'createdBy.name'],
        defaultSearchFields: ['index', 'createdBy.name'],
        select:
            '_id representative createdAt deadline status time tenderNo notes index tenderUrl folders isDeleted contractor createdBy',
        where: {
            isDeleted: false,
        },
    },
    dictFields: {},
    statuses: [],
    details: {},
    registry: {},
}

const getters = {
    getTendersModal: state => state.modal.fields,
    getTendersRegistry: state => state.registry,
    getTendersTable: state => state.table,
    getTendersSearchFields: state => state.request,
    getTendersErrors: state => state.modal.errors,
    getTendersStep: state => state.modal,
    getTendersStatuses: state => state.statuses,
    getTendersDetails: state => state.details,
}

const actions = {
    async fetchTendersTable({ commit, dispatch }) {
        state.table.loading = true
        try {
            let table = new Rest('GET', 'tenders')
                .select(state.request.select)
                .limit(state.table.limit)
                .page(state.table.page)
                .where(state.request.where)
                .populate(state.request.populate)
                .search(state.request.searchFields, state.table.search)
                .sort(state.table.sort || state.table.defaultSort)
                .send()

            let response = await table
            dispatch('fetchTendersRegistry')
            commit('setTendersTable', response.data)
        } catch (error) {
            console.error(error)
        }
        state.table.loading = false
    },
    async createTenders({ commit, dispatch }, payload) {
        try {
            await new Rest('POST', 'tenders').setBody(state.modal.fields).send()
            commit('clearTendersModal')
            commit('clearTendersErrors')
            dispatch('fetchTendersTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            //HANDLE ERRORS
            if (err) {
                commit('setSnackbars', { type: 'error' })
                validation.setErrors(state.modal.errors, err)
            }
        }
    },

    async updateTenders({ commit, dispatch }, payload) {
        try {
            await new Rest('PUT', `tenders/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            dispatch('fetchTenders', { id: payload.id })
            commit('clearTendersModal')
            commit('clearTendersErrors')
            return true
        } catch (err) {
            //HANDLE ERRORS
            validation.setErrors(state.modal.errors, err)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async fetchTenders({ state, commit, dispatch }, payload) {
        try {
            let tender = await new Rest('GET', `tenders/${payload.id}`)
                .populate(['contractor', 'createdBy'])
                .select(
                    '_id representative createdAt deadline status time tenderNo notes index tenderUrl contractor'
                )
                .send()

            let statusesReq = new Rest('GET', `registries/tenders/statuses`)
                .select('name _id isDeleted color state')
                .where({ isDeleted: false })
                .send()

            let { 0: response, 1: statuses } = await Promise.all([
                tender,
                statusesReq,
            ])
            dispatch('fetchTendersRegistry')
            commit('setTendersDetails', response.data)
            commit('setTendersStatuses', statuses.data.items)

            if (payload.next) payload.next()
        } catch (err) {
            console.error(err)
        }
    },

    async fetchTendersRegistry({ commit, dispatch }, payload) {
        let registry = await new Rest('GET', 'registries')
            .select('currentMenager name')
            .where({ url: 'tenders' })
            .populate([
                {
                    path: 'currentMenager',
                    select: 'name lastname mail status phone picture',
                },
            ])
            .send()

        commit('setTendersRegistry', registry.data.items)
    },

    async updateTendersStatus({ commit, dispatch }, payload) {
        commit('setTendersModal')
        commit('setTendersStatus', payload.status)
        try {
            await new Rest('PUT', `tenders/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            commit('setSnackbars', { type: 'success' })
        } catch (err) {
            console.error(err)
            commit('setSnackbars', { type: 'error' })
        }
    },

    async deleteTenders({ commit, dispatch }, payload) {
        try {
            await new Rest('DELETE', `tenders/${payload.id}`).send()
            dispatch('fetchTendersTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            console.error(err)
        }
    },
}

const mutations = {
    setTendersTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
    setTendersDetails(state, payload) {
        state.details = payload
    },
    setTendersStatuses(state, payload) {
        state.statuses = payload
    },
    setTendersModal(state, payload) {
        let obj = payload ? payload : clone(state.details)
        state.modal.fields = obj
        store.commit('setRepresentativesInput', {
            items: obj?.contractor?.representatives,
        })
        state.modal.fields.representative = obj?.contractor?.representatives?.find(
            e => e._id === obj.representative
        )
        state.modal.fields.deadline = obj.deadline
            ? moment(obj.deadline).format('YYYY-MM-DD')
            : ''
    },
    setTendersStatus(state, payload) {
        state.modal.fields.status = payload
    },
    setTendersRegistry(state, payload) {
        state.registry = payload[0]
    },
    clearTendersModal() {
        state.modal.step = 1
        state.modal.fields = {
            contractor: '',
            representative: '',
            notes: '',
            tenderUrl: '',
            time: '',
            deadline: '',
            tenderNo: '',
        }
    },
    clearTendersErrors(state) {
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step].fields) {
                state.modal.errors[step].fields[key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
    clearTendersTable(state) {
        clearSearch.clearSearchTable(state)
    },
    setTendersSearch(state, payload) {
        state.request.searchFields = payload
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
