import Rest from './../../plugins/Rest'
import i18n from './../../plugins/i18next'
import validation from '../../plugins/validation'
const state = {
    modal: {
        logo: '',
        fields: {
            applicationLink: '',
            companyName: '',
            companyAddress: '',
            companyEmail: '',
            companyPhone: '',
            adminEmail: '',
            secretariatEmail: '',
            smtpSerwer: '',
            smtpPort: '',
            authenticationName: '',
            authenticationPassword: '',
            licensesNumber: '',
            sessionTime: '',
            city: '',
            logo: {},
            website: '',
            AVGAmount: '',
            clientID: '',
            clientSecret: '',
            defaultValue: '',
            loginAttempts: '',
            severalDevices: false,
            companyServiceEmail: '',
            nip: '',
            bdo: '',
            bankName: '',
            bankAccountNumber: '',
            invoiceSchema: '',
            emailServiceEstimatesHost: '',
            emailServiceEstimatesPort: '',
            emailServiceEstimatesLogin: '',
            emailServiceEstimatesPass: '',
            emailServiceRentSettlementHost: '',
            emailServiceRentSettlementPort: '',
            emailServiceRentSettlementLogin: '',
            emailServiceRentSettlementPass: '',
        },
        errors: {
            stepOne: {
                validation: true,
            },
            stepTwo: {
                validation: true,
                fields: {
                    sessionTime: '',
                    AVGAmount: '',
                    defaultValue: '',
                },
            },
        },
        steps: '',
    },
    request: {
        populate: [],
        searchFields: 'name',
        select: '',
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: '',
    },
    details: {
        applicationLink: '',
        companyName: '',
        companyAddress: '',
        companyEmail: '',
        companyPhone: '',
        adminEmail: '',
        secretariatEmail: '',
        smtpSerwer: '',
        smtpPort: '',
        logo: {},
        authenticationName: '',
        authenticationPassword: '',
        licensesNumber: '',
        companyServiceEmail: '',
        nip: '',
        bdo: '',
        bankName: '',
        bankAccountNumber: '',
        invoiceSchema: '',
        emailServiceEstimatesHost: '',
        emailServiceEstimatesPort: '',
        emailServiceEstimatesLogin: '',
        emailServiceEstimatesPass: '',
        emailServiceRentSettlementHost: '',
        emailServiceRentSettlementPort: '',
        emailServiceRentSettlementLogin: '',
        emailServiceRentSettlementPass: '',
    },
}

const getters = {
    getSettingsModal: state => state.modal.fields,
    getSettingsErrors: state => state.modal.errors,
    getSettingsDetails: state => state.details,
    getStateDetailsLogo: state => state.picture,
}

const actions = {
    async saveSettings({ state, commit, dispatch }) {
        if (!state.details) {
            try {
                await new Rest('POST', 'settings')
                    .setBody(state.modal.fields)
                    .send()

                commit('setSnackbars', { type: 'success' })
                commit('clearSettingsModal')

                return true
            } catch (err) {
                //HANDLE ERRORS
                if (err) {
                    validation.setErrors(err)
                    commit('setSnackbars', { type: 'error' })
                }
            }
        } else {
            let success = await dispatch('updateSettings', {
                id: state.modal.fields._id,
            })
            if (success) {
                dispatch('fetchSettings', { type: 'add' })
                return true
            }
        }
    },

    async sendLogo({ state, commit, dispatch }, payload) {
        try {
            let response = await new Rest(
                'PUT',
                `settings/logo/${state.details._id}`
            )
                .setBody(state.modal.logo.formData)
                .send()

            if (response.status === 200) {
                commit('setLogoImage', response.data.data)
                // dispatch('updateSettings', {
                //     id: state.details._id,
                // })
                // dispatch('fetchSettings', { type: 'edit' })
                window.location.reload(true)
            }
        } catch (err) {
            console.error(err)
        }
    },

    async updateSettings({ state, commit, dispatch }, payload) {
        try {
            let response = await new Rest('PUT', `settings/${payload.id}`)
                .setBody(state.modal.fields)
                .send()

            commit('clearSettingsModal')
            commit('setSnackbars', { type: 'success' })
            // commit('clearSettingsErrors')
            return true
        } catch (err) {
            //HANDLE ERRORS
            if (err.response.data.errors?.licensesNumber)
                commit('setSnackbars', {
                    type: 'error',
                    text: `${i18n.t('superAdmin:mustBeNumber')}!`,
                })
            else if (err.response.data.errors.sessionTime) {
                if (err.response.data.errors.sessionTime?.kind == 'min') {
                    commit('setSnackbars', {
                        type: 'error',
                        text: `${i18n.t('superAdmin:sessionTimeShort')}!`,
                    })
                }
                commit('setSnackbars', {
                    type: 'error',
                    text: `${i18n.t('superAdmin:sessionMustBeNumber')}!`,
                })
            } else if (err.response.data.errors.loginAttempts?.kind == 'min')
                commit('setSnackbars', {
                    type: 'error',
                    text: `${i18n.t('superAdmin:minLoginAttempts')}!`,
                })
            else {
                validation.setErrors(state.modal.errors, err)
                commit('setSnackbars', { type: 'error' })
            }
            return false
        }
    },

    async fetchSettings({ commit, dispatch }, payload) {
        try {
            let response = await new Rest('GET', `settings`).send()

            if (payload.type == 'add') {
                commit('setSettingsDetails', response.data[0])
            } else if (payload.type == 'edit') {
                commit('setSettingsModal', response.data[0])
            }
            if (payload.next) {
                payload.next()
            }
        } catch (err) {
            //HANDLE ERRORS
            console.error(err)
        }
    },
}

const mutations = {
    setSettingsDetails(state, payload) {
        state.details = payload
    },
    setLogo(state, payload) {
        state.modal.logo = payload
    },
    setLogoImage(state, payload) {
        let imageBuffer = new Uint8Array(payload)
        let image = new Blob([imageBuffer], { type: 'image/png' })
        state.details.logo = {
            blob: image,
            url: window.URL.createObjectURL(image),
        }
    },
    setSettingsModal(state, payload) {
        state.modal.fields = payload
    },
    clearSettingsModal(state, payload) {
        state.modal.step = 1
        state.modal.fields = {
            applicationLink: '',
            companyName: '',
            companyAddress: '',
            companyEmail: '',
            companyPhone: '',
            adminEmail: '',
            secretariatEmail: '',
            smtpSerwer: '',
            smtpPort: '',
            authenticationName: '',
            authenticationPassword: '',
            licensesNumber: '',
            sessionTime: '',
            city: '',
            logo: {},
            website: '',
            AVGAmount: '',
            clientID: '',
            clientSecret: '',
            defaultValue: '',
            loginAttempts: '',
            severalDevices: false,
            nip: '',
            bdo: '',
            bankName: '',
            bankAccountNumber: '',
            invoiceSchema: '',
            companyServiceEmail: '',
            emailServiceEstimatesHost: '',
            emailServiceEstimatesPort: '',
            emailServiceEstimatesLogin: '',
            emailServiceEstimatesPass: '',
        }
    },
    clearSettingsErrors(state) {
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step].fields) {
                state.modal.errors[step].fields[key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
