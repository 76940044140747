import Rest from './../../../plugins/Rest'
import clone from 'lodash.clone'
import i18n from './../../../plugins/i18next'
import validation from './../../../plugins/validation'
import clearSearch from './../../../plugins/clearSearch'
const state = {
    modal: {
        step: 1,
        fields: {
            name: '',
            lastname: '',
            birthday: '',
            userIndex: '',
            position: '',
            roleName: '',
            mail: '',
            privateMail: '',
            phone: '',
            privatePhone: '',
            login: null,
            password: null,
            passwordAgain: null,
            isAdmin: false,
            isUser: false,
            //STAFF (NEEDED IN DICTIONARIES)
            contract: null,
            dayJob: null,
            employmentStart: null,
            specialRoleName: null,
            employmentEnd: null,
            lastExamination: null,
            endExamination: null,
            startBhp: null,
            endBhp: null,
            dayOfBirth: null,
            placeOfBirth: null,
            needments: null,
            comment: null,
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {
                    name: '',
                    lastname: '',
                    birthday: '',
                    userIndex: '',
                    position: '',
                    roleName: '',
                    mail: '',
                    privateMail: '',
                    phone: '',
                    privatePhone: '',
                },
            },
            stepTwo: {
                validation: true,
                fields: {
                    login: null,
                    password: null,
                    passwordAgain: null,
                    isAdmin: null,
                    role: null,
                },
            },
        },
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: '-createdAt',
        loading: false,
    },
    req: {
        stepOne: ['name', 'lastname', 'mail'],
        stepTwo: [],
    },
    request: {
        populate: [
            {
                localField: 'currentMenager',
                foreignField: '_id',
                from: 'users',
                as: 'currentMenager',
            },
        ],
        searchFields: ['name', 'lastname', 'position'],
        defaultSearchFields: ['name', 'lastname', 'position'],
        select: '_id name lastname position status picture folders',
        where: {
            isDeleted: false,
        },
    },
    fieldsNames: {},
    details: {},
    statuses: [],
    usersList: [],
    registry: {},
    dictFields: {
        position: '',
        roleName: '',
        specialRoleName: '',
        contract: '',
        dayJob: '',
    },
}

const getters = {
    getUsersModal: state => state.modal.fields,
    getUsersError: state => state.modal.errors,
    getUsersDetails: state => state.details,
    getUsersTable: state => state.table,
    getUsersStatuses: state => state.statuses,
    getUsersSearchFields: state => state.request,
    getUsersStep: state => state.modal,
    getUsersList: state => state.usersList,
    getUsersRegistry: state => state.registry,
}

const actions = {
    async fetchUsersTable({ commit }) {
        state.table.loading = true
        try {
            let table = new Rest('GET', 'users')
                .select(state.request.select)
                .limit(state.table.limit)
                .page(state.table.page)
                .where(state.request.where)
                .populate(state.request.populate)
                .search(state.request.searchFields, state.table.search)
                .sort(state.table.sort || state.table.defaultSort)
                .send()

            let registryReq = new Rest('GET', 'registries')
                .select('currentMenager name')
                .where({ url: 'users' })
                .populate([
                    {
                        path: 'currentMenager',
                        select: 'name lastname mail status phone picture',
                    },
                ])
                .send()
            let { 0: response, 1: registry } = await Promise.all([
                table,
                registryReq,
            ])
            commit('setUsersTable', response.data)
            commit('setUserRegistry', registry.data.items)
        } catch (err) {
            console.error(err)
        }
        state.table.loading = false
    },

    // function to fetch users
    async fetchUsers({ commit }, payload) {
        try {
            let response = await new Rest('GET', 'users')
                .select(payload.select)
                .limit(payload.limit)
                .page(payload.page)
                .search(payload.searchFields, payload.search)
                .where(payload.where)
                .populate(payload.populate)
                .sort(payload.sort)
                .send()
            commit('setUsersList', response.data.items)
        } catch (err) {
            console.error(err)
        }
    },

    async fetchUser({ state, commit }, payload) {
        try {
            let user = await new Rest('GET', `users/${payload.id}`)
                .select('-password -staff -folders')
                .populate([
                    {
                        path: 'createdBy',
                        select: 'name lastname mail status phone picture',
                    },
                    {
                        path: 'role',
                        select: 'name',
                    },
                ])
                .send()

            let statusesReq = new Rest('GET', `registries/users/statuses`)
                .select('name _id isDeleted color state')
                .where({ isDeleted: false })
                .send()

            let registryReq = new Rest('GET', 'registries')
                .select('currentMenager name')
                .where({ url: 'users' })
                .populate([
                    {
                        path: 'currentMenager',
                        select: 'name lastname mail status phone picture',
                    },
                ])
                .send()

            let { 0: response, 1: statuses, 2: registry } = await Promise.all([
                user,
                statusesReq,
                registryReq,
            ])
            commit('setUserDetails', response.data)
            commit('setUserStatuses', statuses.data.items)
            commit('setUserRegistry', registry.data.items)

            if (payload.next) payload.next()
        } catch (err) {
            console.error(err)
        }
    },

    async createUser({ commit, dispatch }, payload) {
        try {
            await new Rest('POST', 'users').setBody(state.modal.fields).send()
            commit('clearUserModal')
            commit('clearUsersErrors')
            dispatch('fetchUsersTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            //HANDLE ERRORS
            validation.setErrors(state.modal.errors, err)
            if (err.response.data.errors.licences) {
                commit('setSnackbars', {
                    type: 'error',
                    text: `${i18n.t('users:licences')}!`,
                })
            } else if (err.response.data.errors.password) {
                commit('setSnackbars', {
                    type: 'error',
                    text: i18n.t(
                        `profile:${err.response.data.errors.password}`
                    ),
                })
            } else {
                commit('setSnackbars', { type: 'error' })
            }
        }
    },

    async updateUser({ commit, state, dispatch }, payload) {
        try {
            let response = await new Rest('PUT', `users/${payload.id}`)
                .setBody(state.modal.fields)
                .populate([
                    {
                        path: 'role',
                        select: 'name',
                    },
                ])
                .send()
            commit('clearUserModal')
            commit('clearUsersErrors')
            dispatch('fetchUser', payload)
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            //HANDLE ERRORS
            validation.setErrors(state.modal.errors, err)
            commit('setSnackbars', { type: 'error' })
        }
    },

    async deleteUser({ commit, dispatch }, payload) {
        try {
            await new Rest('DELETE', `users/${payload.id}`).send()
            dispatch('fetchUsersTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            console.error(err)
            commit('setSnackbars', { type: 'error' })
        }
    },

    async updateUserStatus({ commit, dispatch }, payload) {
        commit('setUserModal')
        commit('setUserStatus', payload.status)
        try {
            let response = await new Rest('PUT', `users/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            commit('setSnackbars', { type: 'success' })
        } catch (err) {
            console.error(err)
            commit('setSnackbars', { type: 'error' })
        }
    },
}

const mutations = {
    setUsersTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
    setUserDetails(state, payload) {
        state.details = payload
    },
    setUserModal(state, payload) {
        let obj = payload ? payload : clone(state.details)
        state.modal.fields = obj
    },
    clearUserModal(state, payload) {
        state.modal.step = 1
        state.modal.fields = {
            name: '',
            lastname: '',
            birthday: '',
            userIndex: '',
            position: '',
            roleName: '',
            mail: '',
            privateMail: '',
            phone: '',
            privatePhone: '',
            login: null,
            password: null,
            passwordAgain: null,
            isAdmin: false,
            isUser: false,
        }
    },
    setUserStatuses(state, payload) {
        state.statuses = payload
    },
    setUserStatus(state, payload) {
        state.modal.fields.status = payload
    },
    clearUsersErrors(state) {
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step].fields) {
                state.modal.errors[step].fields[key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
    workerIsUser(state, payload) {
        if (payload == true) {
            state.req.stepTwo = ['login', 'password', 'passwordAgain']
        }
    },
    setUserRegistry(state, payload) {
        state.registry = payload[0]
    },
    setUsersList(state, payload) {
        if (payload) {
            state.usersList = payload.filter(e => {
                return e.status.state !== 'CLOSED'
            })
        }
    },
    clearUsersTable(state) {
        clearSearch.clearSearchTable(state)
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    i18nOptions: { namespaces: ['users', 'global'] },
}
