<template>
    <v-container class="central pa-1">
        <v-row>
            <v-col cols="12" class="px-2">
                <v-text-field
                    hide-details
                    single-line
                    v-model="search"
                    :label="$t('global:search')"
                    ref="search"
                    @input="$emit('search', search)"
                >
                    <template v-slot:prepend>
                        <v-icon @click="setFocus()" color="secondary"
                            >mdi-magnify</v-icon
                        >
                    </template>
                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-data-table
                    :items="items"
                    height="!this.isTablet() ? 'calc(100vh - 362px)' : 'calc(100vh - 300px)'"
                    fixed-header
                    :search="search"
                    :items-per-page="11"
                    class="central__table mt-4 pa-0"
                    hide-default-footer
                >
                    <template v-slot:body>
                        <!-- @slot Use this slot to place the table records -->
                        <slot name="columns"></slot>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
/**
 * Central Table
 * @displayName Central Table
 */
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        items: {
            /**
             * Items in Table
             */
            type: Array,
            required: false,
        },
        /**
         * Headers in Table
         */
        headers: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            search: '',
        }
    },
    methods: {
        setFocus() {
            this.$refs.search.focus()
        },
    },
}
</script>
<style lang="scss">
.central__table {
    .v-data-table__progress {
        [role='progressbar'] {
            .v-progress-linear__indeterminate {
                background-color: #19aa8d !important;
            }
        }
    }
}
</style>
