<template>
    <v-container class="p-relative">
        <v-row>
            <v-col cols="12" class="py-0">
                <TableContacts
                    :items="contacts"
                    :headers="headers"
                    :defaultFields="getContactsBoxSearchFields.searchFields"
                    @search="setSearch"
                >
                    <Columns slot="columns" :items="contacts" />
                </TableContacts>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Columns from './Columns'
import TableContacts from './Table'
import store from './../../../../store/index'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    props: ['contacts'],
    i18nOptions: { namespaces: ['contacts', 'global'] },
    components: { Columns, TableContacts },
    computed: {
        ...mapGetters(['getContactsBoxSearchFields']),
    },

    methods: {
        ...mapActions(['fetchContactsBoxTable']),
        ...mapMutations(['setContactsBoxTable', 'getContactsBoxTable']),

        setSearch(search) {
            this.setContactsBoxTable({ search, page: 1 })
            this.fetchContactsBoxTable()
        },
    },

    data: () => ({
        open: false,
        headers: [
            {
                text: 'Nazwa',
                value: 'name',
            },
        ],
    }),
}
</script>
