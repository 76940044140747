<template>
    <v-navigation-drawer
        right
        src="@/assets/img/global/menu-background.jpg"
        app
        v-if="$route.name !== 'dashboard' && !getNav.mini"
        clipped
        touchless
        permanent
        mini-variant-width="50"
        :mini-variant.sync="mini"
        width="384"
        class="rightBox"
    >
        <!-- {{ getTasksPersonal.items }} -->
        <v-tabs
            slider-color="secondary"
            slider-size="3"
            vertical
            class="rightBox__tabs"
            icons-and-text
            v-model="model"
        >
            <!-- WYŁĄCZENIE ZADAŃ W APLIKACJI  -->
            <!-- <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-tab v-on="on" class="rightBox__panel" href="#tasks">
                        <v-badge
                            v-if="
                                getTasksPersonal.activeTasks &&
                                getTasksPersonal.activeTasks.length > 0
                            "
                            class="badgeRB"
                            color="#ffcb00"
                            offset-x="-4"
                            offset-y="-16"
                            overlap
                        ></v-badge>
                        <v-icon color="white">mdi-format-list-checks</v-icon>
                    </v-tab>
                </template>
                <span>{{ $t('global:tasks') }} </span>
            </v-tooltip> -->

            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-tab v-on="on" class="rightBox__panel" href="#alerts">
                        <v-badge
                            v-if="alerts.length > 0"
                            class="badgeRB"
                            color="#ffcb00"
                            offset-x="-4"
                            offset-y="-16"
                            overlap
                        ></v-badge>
                        <v-icon color="white">mdi-bell-ring</v-icon>
                    </v-tab>
                </template>
                <span>{{ $t('global:alerts') }}</span>
            </v-tooltip>

            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-tab
                        @click="fetchContacts()"
                        v-on="on"
                        class="rightBox__panel"
                        href="#contacts"
                    >
                        <v-icon color="white">mdi-account-box</v-icon>
                    </v-tab>
                </template>
                <span>{{ $t('global:contacts') }}</span>
            </v-tooltip>

            <v-tooltip left>
                <template
                    v-slot:activator="{ on }"
                    @dragover.prevent
                    @drop.prevent
                >
                    <v-tab
                        v-on="on"
                        class="rightBox__panel"
                        href="#storage"
                        @dragover.prevent
                        @drop.prevent
                        @drop="handleFileUpload"
                        @dragover="hoverStorage"
                        @dragleave="removeHoverEffect"
                        id="storageTab"
                    >
                        <v-icon color="white">mdi-safe-square-outline</v-icon>
                    </v-tab>
                </template>
                <span>{{ $t('global:storage') }}</span>
            </v-tooltip>

            <v-tab-item class="rightBox__height" value="tasks">
                <div class="headline font-weight-light mb-2">
                    {{ $t('global:tasks').toUpperCase() }}
                </div>
                <transition name="fade" v-on:enter="enter">
                    <Tasks v-if="model == 'tasks' && mini == false" />
                </transition>
            </v-tab-item>
            <v-tab-item class="rightBox__height" value="alerts">
                <div class="headline font-weight-light mb-2">
                    {{ $t('global:alerts').toUpperCase() }}
                </div>
                <!-- {{ this.getProfileDetails.alerts }} -->
                <transition name="fade" v-on:enter="enter">
                    <Alerts
                        @showBadge="isNotSeenAlerts"
                        :items="rightBoxAlerts.items"
                    />
                </transition>
            </v-tab-item>

            <v-tab-item class="rightBox__height" value="contacts">
                <div class="headline font-weight-light mb-2">
                    {{ $t('global:contacts').toUpperCase() }}
                </div>

                <transition name="fade" v-on:enter="enter">
                    <Contacts :contacts="this.getContactsBoxTable.items" />
                </transition>
            </v-tab-item>

            <v-tab-item class="rightBox__height" value="storage">
                <div class="headline font-weight-light mb-2">
                    {{ $t('global:storage').toUpperCase() }}
                </div>

                <transition name="fade" v-on:enter="enter">
                    <Storage v-if="model == 'storage' && mini == false" />
                </transition>
            </v-tab-item>
        </v-tabs>
        <v-btn class="rightBox__btnRight" icon @click.stop="mini = !mini">
            <v-icon color="white" v-if="mini">mdi-chevron-left</v-icon>
            <v-icon color="white" v-else>mdi-chevron-right</v-icon>
        </v-btn>
    </v-navigation-drawer>
</template>

<script>
import Tasks from './Tasks/Card'
import Alerts from './Alerts/Card'
import Contacts from './Contacts/Card'
import Storage from './Storage/Card'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    i18nOptions: { namespaces: ['global'] },
    data() {
        return {
            notificationToDelete: false,
            mini: true,
            alerts: [],
            rightBoxAlerts: [],
            tasks: [],
            overlap: true,
            show: false,
            model: 'tasks',
        }
    },
    computed: {
        ...mapGetters([
            'getProfileDetails',
            'getContactsBoxTable',
            'getStorageModal',
            'getTasksPersonal',
            'getProfileDetails',
            'getNav',
            'getAlertsTable',
        ]),
        contacts: {
            get() {
                return this.getContactsTable.items
            },
        },
    },
    components: { Tasks, Alerts, Contacts, Storage },
    methods: {
        ...mapActions([
            'fetchContactsBoxTable',
            'createStorageFile',
            'fetchTasksCountPersonal',
            'fetchTasksTable',
            'fetchAlertsTable',
        ]),
        ...mapMutations(['clearStorageFields']),
        fetchContacts() {
            this.fetchContactsBoxTable()
        },

        enter: function (el, done) {
            var that = this
            setTimeout(function () {
                that.mini = false
            }, 200)
        },
        async fetchAlerts() {
            this.rightBoxAlerts = await this.fetchAlertsTable({
                where: {
                    'to.users._id': this.getProfileDetails._id,
                    active: true,
                },
            })
        },
        isNotSeenAlerts() {
            if (this.rightBoxAlerts.items) {
                this.alerts = this.rightBoxAlerts.items.filter(
                    a => a.seen == false
                )
            }
            if (this.alerts.length > 0) {
                return true
            } else {
                return false
            }
        },

        handleFileUpload(e) {
            this.getStorageModal.attachmentsComputer = [
                ...e.dataTransfer.files,
                this.getStorageModal.attachmentsComputer,
            ].flat()
            this.createStorageFile()
            this.clearStorageFields()
            this.removeHoverEffect()
            this.clearStorageErrors()
        },
        hoverStorage() {
            document.querySelector('#storageTab').style.backgroundColor =
                'rgba(25, 170, 141, .5)'
        },
        removeHoverEffect() {
            document.querySelector('#storageTab').style.backgroundColor =
                'transparent'
        },
    },
    beforeUpdate() {
        this.isNotSeenAlerts()
    },
    beforeMount() {
        this.fetchAlerts()
    },
}
</script>
