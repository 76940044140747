import Rest from './../../../plugins/Rest'
import clone from 'lodash.clone'
import validation from './../../../plugins/validation'
import moment from 'moment'
import i18n from '../../../plugins/i18next'
import sendMail from './../../../plugins//sendInfoMail'
import clearSearch from './../../../plugins/clearSearch'
const state = {
    modal: {
        fields: {
            title: '',
            archiveDate: '',
            info: '',
            priority: '',
            to: {
                users: [],
                departments: [],
            },
            responsible: '',
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {
                    title: '',
                    info: '',
                    to: '',
                },
            },
        },
        steps: 1,
    },
    request: {
        unwindExeptions: ['responsible'],
        populate: [
            {
                localField: 'createdBy',
                foreignField: '_id',
                from: 'users',
                as: 'createdBy',
            },
            {
                localField: 'responsible',
                foreignField: '_id',
                from: 'users',
                as: 'responsible',
            },
        ],
        searchFields: ['title'],
        defaultSearchFields: ['title'],
        select:
            '_id title archiveDate info status priority index createdAt priorityType createdBy responsible.name responsible.lastname responsible.phone responsible.mail to folders',
        where: {
            isDeleted: false,
        },
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: '-createdAt',
        loading: false,
    },
    searchFields: ['title', 'createdAt'],
    req: {
        stepOne: ['to', 'title', 'info'],
    },
    registry: {},
    details: {},
    statuses: [],
    dictFields: {
        priorityType: '',
    },
}

const getters = {
    getServiceInfosModal: state => state.modal.fields,
    getServiceInfosErrors: state => state.modal.errors,
    getServiceInfosDetails: state => state.details,
    getServiceInfosStep: state => state.modal.fields,
    getServiceInfosStatuses: state => state.statuses,
    getServiceInfosTable: state => state.table,
    getServiceInfosSearchFields: state => state.request,
    getServiceInfosRegistry: state => state.registry,
}

const actions = {
    async sendInfoMail() {
        let payload = {
            case: 'serviceInfoMessage',
            subject: 'Entelli - nowa informacja',
            route: 'service-info',
            details: {
                title: state.details.title,
                info: state.details.info,
                createdBy: `${state.details.createdBy.name} ${state.details.createdBy.lastname}`,
            },
        }
        sendMail.sendInformationMail(state, payload)
    },

    async fetchServiceInfosRegistry({ commit }) {
        let registry = await new Rest('GET', 'registries')
            .select('currentMenager name')
            .where({ url: 'service-info' })
            .populate([
                {
                    path: 'currentMenager',
                    select: 'name lastname mail status phone picture',
                },
            ])
            .send()
        commit('setServiceInfosRegistry', registry.data.items)
    },

    async fetchServiceInfosTable({ commit, dispatch }) {
        state.table.loading = true
        try {
            let table = await new Rest('GET', 'service-info')
                .select(state.request.select)
                .limit(state.table.limit)
                .page(state.table.page)
                .where(state.request.where)
                .populate(state.request.populate)
                .unwindExeptions(state.request.unwindExeptions)
                .search(state.request.searchFields, state.table.search)
                .sort(state.table.sort || state.table.defaultSort)
                .send()
            dispatch('fetchServiceInfosRegistry')
            commit('setServiceInfosTable', table.data)
        } catch (err) {
            console.error(err)
        }
        state.table.loading = false
    },
    async fetchServiceInfos({ commit, dispatch }, payload) {
        try {
            let Info = await new Rest('GET', `service-info/${payload.id}`)
                .populate([
                    {
                        path: 'to.users',
                        select: 'name lastname phone mail',
                    },
                    {
                        path: 'to.departments',
                        select: 'users name',
                        populate: {
                            path: 'users',
                            select: 'mail',
                        },
                    },
                    { path: 'createdBy', select: 'name lastname phone mail' },
                    { path: 'responsible', select: 'name lastname phone mail' },
                ])
                .select(
                    '_id title archiveDate status info priority to.departments.users.mail to.users.name to.users.lastname to.departments index createdAt priorityType createdBy'
                )
                .send()

            let statusesReq = new Rest(
                'GET',
                `registries/service-info/statuses`
            )
                .select('name _id isDeleted color state')
                .where({ isDeleted: false })
                .send()

            let { 0: response, 1: statuses } = await Promise.all([
                Info,
                statusesReq,
            ])

            dispatch('fetchServiceInfosRegistry')
            commit('setServiceInfosDetails', response.data)
            commit('setServiceInfosStatuses', statuses.data.items)

            if (payload.next) payload.next()
        } catch (err) {
            console.error(err)
        }
    },
    async createServiceInfos({ commit, dispatch, state }) {
        validation.frontVal(state.modal, state.req, 'service-info')
        if (state.modal.errors.stepOne.validation) {
            try {
                await new Rest('POST', 'service-info')
                    .setBody(state.modal.fields)
                    .send()
                commit('clearServiceInfosModal')
                commit('clearServiceInfosErrors')
                dispatch('fetchServiceInfosTable')
                commit('setSnackbars', { type: 'success' })
                return true
            } catch (err) {
                //HANDLE ERRORS
                if (err) {
                    commit('setSnackbars', { type: 'error' })
                    validation.setErrors(state.modal.errors, err)
                }
            }
        }
    },
    async updateServiceInfos({ commit, dispatch }, payload) {
        validation.frontVal(state.modal, state.req, 'service-info')
        if (state.modal.errors.stepOne.validation) {
            try {
                await new Rest('PUT', `service-info/${payload.id}`)
                    .setBody(state.modal.fields)
                    .send()
                dispatch('fetchServiceInfos', { id: payload.id })
                commit('clearServiceInfosModal')
                commit('clearServiceInfosErrors')
                return true
            } catch (err) {
                //HANDLE ERRORS
                validation.setErrors(state.modal.errors, err)
                commit('setSnackbars', { type: 'error' })
            }
        }
    },

    async updateServiceInfosStatus({ commit }, payload) {
        commit('setServiceInfosModal')
        commit('setServiceInfosStatus', payload.status)
        try {
            await new Rest('PUT', `service-info/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            commit('setSnackbars', { type: 'success' })
        } catch (err) {
            commit('setSnackbars', { type: 'error' })
        }
    },

    async deleteServiceInfos({ commit, dispatch }, payload) {
        try {
            await new Rest('DELETE', `service-info/${payload.id}`).send()
            dispatch('fetchServiceInfosTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            console.error(err)
        }
    },
}

const mutations = {
    setServiceInfosTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
    setServiceInfosSearch(state, payload) {
        state.request.searchFields = payload
    },
    setServiceInfosDetails(state, payload) {
        state.details = payload
    },
    setServiceInfosStatuses(state, payload) {
        state.statuses = payload
    },
    setServiceInfosStatus(state, payload) {
        state.modal.fields.status = payload
    },
    setServiceInfosModal(state, payload) {
        let obj = payload ? payload : clone(state.details)
        state.modal.fields = obj
        state.modal.fields.archiveDate = obj.archiveDate
            ? moment(obj.archiveDate).format('YYYY-MM-DD')
            : ''
    },
    setServiceInfosRegistry(state, payload) {
        state.registry = payload[0]
    },
    clearServiceInfosModal(state, payload) {
        state.modal.step = 1
        state.modal.fields = {
            title: '',
            archiveDate: '',
            info: '',
            priority: '',
            to: {
                users: [],
                departments: [],
            },
        }
    },
    clearServiceInfosErrors(state) {
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step].fields) {
                state.modal.errors[step].fields[key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
    clearServiceInfosTable(state) {
        clearSearch.clearSearchTable(state)
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
