<template>
    <div class="pa-5 confirmModal">
        <v-row>
            <v-col cols="12">
                <div class="d-flex justify-center py-5">
                    <img
                        width="100"
                        height="100"
                        src="@/assets/img/global/warning.svg"
                        alt="warning"
                    />
                </div>

                <!-- <v-card-title class="d-flex justify-center pa-2">Czy chcesz wyenerować nową fakturę</v-card-title> -->
                <v-card-text v-if="isInvoiceAvailable" class="d-flex justify-center pa-2">
                    <span>{{$t('rent-contracts:createNewInvoiceQuoteNext')}}</span>
                </v-card-text>
                <v-card-text  v-if="!isInvoiceAvailable" class="d-flex justify-center pa-2">
                     <span>{{$t('rent-contracts:createNewInvoiceIfNotExist')}}</span>
                </v-card-text>
                <v-row> </v-row>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    props: {
        type: {
            type: String,
        },
        isInvoiceAvailable: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            confirmType: this.type,
            menu: false,
            text: '',
            date: '',
        }
    },
    methods: {
        clearConfirmModal() {
            this.text = ''
            this.date = ''
        },
    },
    computed: {
        confirmText: {
            get(type) {
                if (type._props.type == 'valuation')
                    return `${this.$t('service:confirmValuation')}`
                else if (type._props.type == 'expertise')
                    return `${this.$t('service:confirmExpertise')}`
                else if (type._props.type == 'continuation')
                    return `${this.$t('service:confirmContinuation')}`
                else if (type._props.type == 'claim')
                    return `${this.$t('service:confirmClaim')}`
                else if (type._props.type == 'invoice')
                    return `${this.$t('service:confirmInvoice')}`
                else if (type._props.type == 'price')
                    return `${this.$t('service:confirmPrice')}`
            },
        },
        dateLabel: {
            get(type) {
                if (type._props.type == 'invoice') {
                    return `${this.$t('service:reckoningDateOuter')}`
                }
            },
        },
        dataLabel: {
            get(type) {
                if (type._props.type == 'invoice')
                    return `${this.$t('service:documentNumber')}`
                else if (type._props.type == 'claim')
                    return `${this.$t('service:claimNumber')}`
            },
        },
    },
    mounted() {
        this.$root.$on('clearModal', () => {
            this.clearConfirmModal()
        })
    },
}
</script>
