var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.getRentSettlementErrors.step),callback:function ($$v) {_vm.$set(_vm.getRentSettlementErrors, "step", $$v)},expression:"getRentSettlementErrors.step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () =>
                                    _vm.getRentSettlementErrors.stepOne
                                        .validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('global:basicInfo')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{staticClass:"pb-0 pt-4",attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t(_vm.$t('global:basicInfo'))}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-combobox',{ref:"selectDevice",attrs:{"disabled":_vm.isThatLastSettlement(),"color":"red","label":`${_vm.$t(
                                            'rent-contracts:rent-annex.device.sn'
                                        )}`,"hint":"Zacznij pisać...","items":_vm.devices,"item-text":"sn","return-object":"","readonly":"","append-icon":""},on:{"keyup":function($event){return _vm.findDevices($event)},"change":_vm.onChange},model:{value:(
                                            _vm.getRentSettlementModal.device
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentSettlementModal, "device", $$v)},expression:"\n                                            getRentSettlementModal.device\n                                        "}})],1)],1),(_vm.getRentSettlementModal.device.sn)?_c('v-row',{staticStyle:{"min-height":"100px"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"disabled":_vm.isThatLastSettlement(),"label":_vm.$t(
                                                'rent-contracts:rent-annex.device.model.manufacturer'
                                            ),"readonly":""},model:{value:(
                                            _vm.getRentSettlementModal.device
                                                .manufacturer
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentSettlementModal.device
                                                , "manufacturer", $$v)},expression:"\n                                            getRentSettlementModal.device\n                                                .manufacturer\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"disabled":_vm.isThatLastSettlement(),"label":_vm.$t(
                                                'rent-contracts:rent-annex.device.model.model'
                                            ),"readonly":""},model:{value:(
                                            _vm.getRentSettlementModal.device
                                                .model.model
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentSettlementModal.device
                                                .model, "model", $$v)},expression:"\n                                            getRentSettlementModal.device\n                                                .model.model\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"disabled":_vm.isThatLastSettlement(),"label":_vm.$t(
                                                'rent-contracts:rent-annex.device.model.deviceType'
                                            ),"readonly":""},model:{value:(
                                            _vm.getRentSettlementModal.device
                                                .model.deviceType
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentSettlementModal.device
                                                .model, "deviceType", $$v)},expression:"\n                                            getRentSettlementModal.device\n                                                .model.deviceType\n                                        "}})],1)],1):_c('v-row',{staticStyle:{"min-height":"100px"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"disabled":_vm.isThatLastSettlement(),"readonly":"","label":_vm.$t(
                                                'rent-contracts:rent-annex.prevMonoCounter'
                                            )},on:{"keypress":_vm.integralNumbers},model:{value:(
                                            _vm.getRentSettlementModal.prevMonoCounter
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentSettlementModal, "prevMonoCounter", $$v)},expression:"\n                                            getRentSettlementModal.prevMonoCounter\n                                        "}})],1),_c('v-col',{attrs:{"cols":"3","id":"CurrentCounters"}},[_c('v-text-field',{attrs:{"disabled":_vm.isThatLastSettlement(),"label":_vm.$t(
                                                'rent-contracts:rent-annex.currentMonoCounter'
                                            ),"autofocus":""},on:{"keypress":_vm.integralNumbers,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab"))return null;$event.preventDefault();return _vm.goToNextField(
                                                _vm.$refs.currentColorCounter
                                            )}},model:{value:(
                                            _vm.getRentSettlementModal.currentMonoCounter
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentSettlementModal, "currentMonoCounter", $$v)},expression:"\n                                            getRentSettlementModal.currentMonoCounter\n                                        "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"disabled":_vm.isThatLastSettlement(),"label":_vm.$t(
                                                'rent-contracts:rent-annex.monthlyMono'
                                            ),"readonly":"","error-messages":_vm.getRentSettlementErrors.stepOne
                                                .fields.monthlyMono},on:{"keypress":_vm.integralNumbers},model:{value:(
                                            _vm.getRentSettlementModal.monthlyMono
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentSettlementModal, "monthlyMono", $$v)},expression:"\n                                            getRentSettlementModal.monthlyMono\n                                        "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"disabled":_vm.isThatLastSettlement(),"label":_vm.$t(
                                                'rent-contracts:rent-annex.monthlyMonoCost'
                                            ),"readonly":"","value":_vm.cost(
                                                _vm.getRentSettlementModal.monthlyMono,
                                                _vm.getRentSettlementPrice
                                                    .device.monoPrice
                                            ) + ' PLN'}},[_c('template',{slot:"append"},[(
                                                    _vm.getRentSettlementModal
                                                        .rentDevices
                                                        .monoBundle > 0 ||
                                                    (_vm.getRentDeviceTable
                                                        .items[0] &&
                                                        _vm.getRentDeviceTable
                                                            .items[0]
                                                            .monoBundle > 0)
                                                )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                        on,
                                                    }){return [_c('v-icon',_vm._g({staticClass:"cursor-pointer",attrs:{"size":"20","color":"lightgrey"}},on),[_vm._v("mdi-information")])]}}],null,false,3915141442)},[_c('span',[_vm._v(_vm._s(_vm.$t( 'rent-contracts:rent-settlement.packageInformation' )))])]):_vm._e()],1)],2)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"disabled":_vm.isThatLastSettlement(),"label":_vm.$t(
                                                'rent-contracts:rent-annex.prevColorCounter'
                                            ),"readonly":""},on:{"keypress":_vm.integralNumbers},model:{value:(
                                            _vm.getRentSettlementModal.prevColorCounter
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentSettlementModal, "prevColorCounter", $$v)},expression:"\n                                            getRentSettlementModal.prevColorCounter\n                                        "}})],1),_c('v-col',{attrs:{"cols":"3","id":"CurrentCounters"}},[_c('v-text-field',{ref:"currentColorCounter",attrs:{"label":_vm.$t(
                                                'rent-contracts:rent-annex.currentColorCounter'
                                            ),"disabled":_vm.isThatLastSettlement()},on:{"keypress":_vm.integralNumbers,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab"))return null;$event.preventDefault();return _vm.goToNextField(
                                                _vm.$refs.currentScanCounter
                                            )}},model:{value:(
                                            _vm.getRentSettlementModal.currentColorCounter
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentSettlementModal, "currentColorCounter", $$v)},expression:"\n                                            getRentSettlementModal.currentColorCounter\n                                        "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"disabled":_vm.isThatLastSettlement(),"label":_vm.$t(
                                                'rent-contracts:rent-annex.monthlyColor'
                                            ),"readonly":"","error-messages":_vm.getRentSettlementErrors.stepOne
                                                .fields.monthlyColor},on:{"keypress":_vm.integralNumbers},model:{value:(
                                            _vm.getRentSettlementModal.monthlyColor
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentSettlementModal, "monthlyColor", $$v)},expression:"\n                                            getRentSettlementModal.monthlyColor\n                                        "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"disabled":_vm.isThatLastSettlement(),"label":_vm.$t(
                                                'rent-contracts:rent-annex.monthlyColorCost'
                                            ),"readonly":"","value":_vm.cost(
                                                _vm.getRentSettlementModal.monthlyColor,
                                                _vm.getRentSettlementPrice
                                                    .device.colorPrice
                                            ) + ' PLN'}},[(
                                                _vm.getRentSettlementModal
                                                    .rentDevices
                                                    .colorBundle > 0 ||
                                                (_vm.getRentDeviceTable
                                                    .items[0] &&
                                                    _vm.getRentDeviceTable
                                                        .items[0]
                                                        .colorBundle > 0)
                                            )?_c('template',{slot:"append"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                        on,
                                                    }){return [_c('v-icon',_vm._g({staticClass:"cursor-pointer",attrs:{"size":"20","color":"lightgrey"}},on),[_vm._v("mdi-information")])]}}],null,false,3915141442)},[_c('span',[_vm._v(_vm._s(_vm.$t( 'rent-contracts:rent-settlement.packageInformation' )))])])],1):_vm._e()],2)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"disabled":_vm.isThatLastSettlement(),"readonly":"","label":_vm.$t(
                                                'rent-contracts:rent-annex.prevScanCounter'
                                            )},on:{"keypress":_vm.integralNumbers},model:{value:(
                                            _vm.getRentSettlementModal.prevScanCounter
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentSettlementModal, "prevScanCounter", $$v)},expression:"\n                                            getRentSettlementModal.prevScanCounter\n                                        "}})],1),_c('v-col',{attrs:{"cols":"3","id":"CurrentCounters"}},[_c('v-text-field',{ref:"currentScanCounter",attrs:{"disabled":_vm.isThatLastSettlement(),"label":_vm.$t(
                                                'rent-contracts:rent-annex.currentScanCounter'
                                            )},on:{"keypress":_vm.integralNumbers},model:{value:(
                                            _vm.getRentSettlementModal.currentScanCounter
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentSettlementModal, "currentScanCounter", $$v)},expression:"\n                                            getRentSettlementModal.currentScanCounter\n                                        "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"disabled":_vm.isThatLastSettlement(),"label":_vm.$t(
                                                'rent-contracts:rent-annex.monthlyCosts'
                                            ),"readonly":"","error-messages":_vm.getRentSettlementErrors.stepOne
                                                .fields.monthlyScan},on:{"keypress":_vm.integralNumbers},model:{value:(
                                            _vm.getRentSettlementModal.monthlyScan
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentSettlementModal, "monthlyScan", $$v)},expression:"\n                                            getRentSettlementModal.monthlyScan\n                                        "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"disabled":_vm.isThatLastSettlement(),"label":_vm.$t(
                                                'rent-contracts:rent-annex.monthlyScanCost'
                                            ),"readonly":"","value":_vm.cost(
                                                _vm.getRentSettlementModal.monthlyScan,
                                                _vm.getRentSettlementPrice
                                                    .device.scanPrice
                                            ) + ' PLN'}})],1),_c('v-col',{attrs:{"cols":"12"}},[(
                                            _vm.getRentSettlementModal.rentSettlementsInstance &&
                                            _vm.getRentSettlementModal
                                                .rentSettlementsInstance
                                                .createdBy
                                        )?_c('div',{staticClass:"accounted--font mt-5"},[(_vm.isSettlementManualMode())?_c('div',[_c('span',[_vm._v(_vm._s(`${_vm.$t( 'rent-contracts:rent-annex.accounted' )}:`))]),_c('span',{staticClass:"mx-1"},[_vm._v(_vm._s(`${_vm.$get( _vm.getProfileDetails, 'name', '' )} ${_vm.$get( _vm.getProfileDetails, 'lastname', '' )}`)+" ")]),_c('span',{staticClass:"mx-1"},[_vm._v(_vm._s(`- ${_vm.$moment().format( `HH:mm` )}`))]),_c('span',{staticClass:"mx-1"},[_vm._v(_vm._s(`${_vm.$moment().format( `DD/MM/YYYY` )}`))])]):_vm._e(),(!_vm.isSettlementManualMode())?_c('div',[_c('span',[_vm._v(_vm._s(`${_vm.$t( 'rent-contracts:rent-annex.accounted' )}:`))]),_c('span',{staticClass:"mx-1"},[_vm._v(_vm._s(`${_vm.$get( _vm.getRentSettlementModal, 'rentSettlementsInstance.createdBy.name', '' )} ${_vm.$get( _vm.getRentSettlementModal, 'rentSettlementsInstance.createdBy.lastname', '' )}`)+" ")]),_c('span',{staticClass:"mx-1"},[_vm._v(_vm._s(`- ${_vm.$moment( _vm.getRentSettlementModal .rentSettlementsInstance .createdAt ).format(`HH:mm`)}`))]),_c('span',{staticClass:"mx-1"},[_vm._v(_vm._s(`${_vm.$moment( _vm.getRentSettlementModal .rentSettlementsInstance .createdAt ).format(`DD/MM/YYYY`)}`))])]):_vm._e()]):_vm._e()])],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }