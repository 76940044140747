<template>
    <div>
        <v-alert
            border="left"
            colored-border
            class="py-2"
            color="blue"
            elevation="2"
            width="310"
            v-for="item in getTasksPersonal.items"
            :key="item._id"
        >
            <p class="ma-0 rightBox__card__title">
                {{ item.taskName }}
            </p>
            <span class="rightBox__card__date"
                ><b>{{ $t('global:term') }}:</b>
                {{ item.taskTime }}
                {{ $moment(item.taskDate).format(`DD-MM-YYYY`) }}</span
            >
            <p class="mt-2 mb-1 rightBox__card__description">
                {{ item.taskDescription }}
            </p>
            <div>
                <span>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                size="15"
                                v-on="on"
                                color="primary"
                                class="mr-1"
                                >mdi-account</v-icon
                            >
                            <span
                                class="cursor-pointer rightBox__card__summary"
                                v-on="on"
                                >{{ item.from.name }}
                                {{ item.from.lastname }}</span
                            >
                        </template>
                        <span>{{ $t('tasks:created') }}</span>
                    </v-tooltip>
                </span>
                <span class="rightBox__card__span">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                v-on="on"
                                size="15"
                                color="primary"
                                class="mr-1"
                                >mdi-calendar-month</v-icon
                            >
                            <span
                                class="cursor-pointer rightBox__card__summary"
                                v-on="on"
                                >{{
                                    $moment(item.createdAt).format(
                                        `HH:mm DD-MM-YYYY`
                                    )
                                }}</span
                            >
                        </template>
                        <span>{{ $t('tasks:createdDate') }}</span>
                    </v-tooltip>
                </span>
            </div>
            <v-btn
                small
                icon
                class="rightBox__card__arrow"
                @click="showTask(item._id)"
            >
                <v-icon size="18" color="white">mdi-magnify</v-icon>
            </v-btn>
        </v-alert>
        <Modal :title="modal.title" :open="modal.open" v-on:close="closeModal">
            <AEContent
                slot="AEContent"
                :action="modal.action"
                :isPersonal="true"
            />
            <Buttons
                :action="modal.action"
                slot="buttons"
                @closeModal="closeModal"
            />
        </Modal>
        <v-btn
            fab
            dark
            small
            class="buttons--add addButtonPosition"
            @click="openCreateModal"
        >
            <v-icon>mdi-plus</v-icon>
        </v-btn>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Columns from './../../../../components/Global/Details/Tabs/Tasks/Details/Columns'
import AEContent from './../../../../components/Global/Details/Tabs/Tasks/Modal/Content'
import Buttons from './../../../../components/Global/Details/Tabs/Tasks/Modal/Buttons'
export default {
    props: {
        items: {
            type: Array,
        },
    },
    data() {
        return {
            modal: {
                open: false,
                action: '',
                title: '',
            },
        }
    },
    components: {
        Columns,
        Buttons,
        AEContent,
    },
    computed: {
        ...mapGetters([
            'getProfileDetails',
            'getTasksTable',
            'getTasksPersonal',
        ]),
    },
    methods: {
        ...mapActions([
            'fetchTasksTable',
            'fetchUsers',
            'fetchDepartments',
            'fetchTask',
            'fetchComments',
        ]),
        ...mapMutations([
            'setCommentsTaskId',
            'clearTasksModal',
            'clearTasksErrors',
            'clearCommentsModal',
        ]),

        async showTask(id) {
            await void this.fetchUsers({
                select: 'name lastname mail status isDeleted',
                where: {
                    isUser: true,
                    'status.state': 'ACTIVE',
                    isDeleted: false,
                },
            })
            await void this.fetchDepartments({
                select: 'name isDeleted shortName',
                where: { isDeleted: false },
            })
            await this.fetchTask({ id: id })
            this.modal.action = 'edit'
            this.modal.title = this.$t('tasks:editTask')
            this.modal.open = true

            this.setCommentsTaskId(id)

            await this.fetchComments()
        },
        async openCreateModal() {
            this.clearTasksModal()
            this.clearTasksErrors()
            this.clearCommentsModal()
            await void this.fetchUsers({
                select: 'name lastname mail status isDeleted',
                where: {
                    isUser: true,
                    'status.state': 'ACTIVE',
                    isDeleted: false,
                },
            })
            await void this.fetchDepartments({
                select: 'name isDeleted shortName',
                where: { isDeleted: false },
            })
            this.modal.action = 'create'
            this.modal.title = this.$t('tasks:additionTask')
            this.modal.open = true
        },
        closeModal() {
            this.clearTasksModal()
            this.clearTasksErrors()
            this.clearCommentsModal()
            this.modal.open = false
        },
    },
}
</script>

<style lang="sass" scoped>
.addButtonPosition
    position: absolute
    bottom: 10px
    right: 10px
</style>
