import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';
import pl from 'vuetify/es5/locale/pl';
Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                iconGray: '#ababab',
                textGrey: '#777777',
                success: '#4caf50',
                errors: '#ff4c4c',
                primary: '#445D75', // #E53935
                secondary: '#19aa8d', // #19AA8D - GREEN
                accent: colors.indigo.base, // #3F51B5
            }
        },
    },
    lang: {
        locales: {
            pl
        },
        current: 'pl',
    },
});