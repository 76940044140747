<template>
    <div>
        <v-btn
            @click="action == 'create' ? save() : edit()"
            class="buttons buttons--add"
            >{{ $t('global:save') }}</v-btn
        >
    </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        action: String,
    },
    computed: {
        ...mapGetters(['getNoteModal']),
    },
    data() {
        return {
            disabled: false,
        }
    },

    methods: {
        ...mapActions(['createNote', 'updateNote']),

        async save() {
            this.disabled = true
            let success = await this.createNote({ id: this.$route.params.id, url: this.$route.name })
            if (success) this.$emit('closeModal')
            this.disabled = false
        },
        async edit() {
            let success = await this.updateNote({
                id: this.$route.params.id,
                recId: this.getNoteModal._id,
                url: this.$route.name
            })
            if (success) this.$emit('closeModal')
            this.disabled = false
        },
    },
}
</script>
