<template>
    <v-navigation-drawer
        :mini-variant="getNav.mini"
        mini-variant-width="65"
        absolute
        dark
        v-click-outside="onClickOutside"
        app
        touchless
        :permanent="true"
        src="@/assets/img/global/menu-background.jpg"
    >
        <div class="navigation__bg">
            <router-link tag="div" to="/dashboard">
                <div :class="`navigation__logo${getNav.mini ? '__small' : ''}`">
                    <img
                        v-if="!getNav.mini"
                        src="@/assets/img/global/repairs/e_r_logo_dark.png"
                        alt="Logo Entelli"
                    />
                    <img
                        class="pa-2"
                        v-if="getNav.mini"
                        src="@/assets/img/global/logos_e.png"
                        alt="Logo"
                    />
                </div>
            </router-link>
            <!-- === PERSON === -->
            <router-link tag="div" to="/profile">
                <div
                    class="navigation__avatar"
                    :class="!getNav.mini ? 'pl-5' : 'pl-3'"
                >
                    <v-avatar
                        color="secondary"
                        :size="!getNav.mini ? '50' : '40'"
                    >
                        <img
                            v-if="getProfilePicture.url"
                            :src="getProfilePicture.url"
                            :alt="getProfileDetails.name"
                        />
                        <span v-else class="white--text"
                            >{{ getProfileDetails.name[0]
                            }}{{ getProfileDetails.lastname[0] }}</span
                        >
                    </v-avatar>

                    <transition name="fade">
                        <div v-if="!getNav.mini">
                            <p class="pa-0 ma-0 mt-1 white--text">
                                {{ getProfileDetails.name }}
                                {{ getProfileDetails.lastname }}
                            </p>

                            <span class="pa-0 ma-0">{{
                                getProfileDetails.position
                            }}</span>
                        </div>
                    </transition>
                </div>
            </router-link>

            <v-stepper
                v-model="getNav.stepper"
                class="navigation__list elevation-0"
            >
                <v-stepper-items>
                    <v-stepper-content step="1" class="pa-0 pa-0">
                        <v-list
                            nav
                            dark
                            flat
                            class="elevation-0 active-menu"
                            data-cy="list-main"
                        >
                            <v-list-item
                                v-for="(item, index) in stepOne(items)"
                                :key="index"
                                :to="item.link"
                                @click="goToStepper(item.step)"
                            >
                                <v-list-item-icon>
                                    <v-icon>mdi-{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title class="font-weight-light">{{
                                    item.name
                                }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-stepper-content>
                    <v-stepper-content step="3" class="pa-0 pt-0 pr-0">
                        <div
                            class="pa-2 pl-4 d-flex align-center cursor-pointer"
                            @click="getNav.stepper = 1"
                        >
                            <v-icon color="secondary">mdi-arrow-left</v-icon>
                            <span
                                v-if="getNav.mini == false"
                                class="title ml-2 font-weight-light"
                                >{{ $t('global:personal') }}</span
                            >
                        </div>
                        <v-list
                            nav
                            dark
                            flat
                            class="elevation-0 active-menu"
                            data-cy="list-personal"
                        >
                            <v-list-item
                                v-for="(item, index) in personal"
                                :key="index"
                                :to="item.link"
                            >
                                <v-list-item-icon>
                                    <v-icon>mdi-{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title class="font-weight-light">{{
                                    item.name
                                }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-stepper-content>
                    <v-stepper-content step="2" class="pa-0 pt-0 pr-0">
                        <div
                            class="pa-2 pl-4 d-flex align-center cursor-pointer"
                            @click="getNav.stepper = 1"
                        >
                            <v-icon color="secondary">mdi-arrow-left</v-icon>
                            <span
                                v-if="getNav.mini == false"
                                class="title ml-2 font-weight-light"
                                >{{ $t('global:registries') }}</span
                            >
                        </div>
                        <v-list
                            nav
                            dark
                            flat
                            class="elevation-0 active-menu"
                            data-cy="list-registries"
                        >
                            <v-list-item
                                v-for="item in registries"
                                :key="item._id"
                                :to="`/${item.url}`"
                                class="my-0"
                            >
                                <v-list-item-icon>
                                    <v-icon>{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title class="font-weight-light">{{
                                    item.name
                                }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-stepper-content>
                    <v-stepper-content step="4" class="pa-0 pt-0 pr-0">
                        <div
                            class="pa-2 pl-4 d-flex align-center cursor-pointer"
                            @click="getNav.stepper = 1"
                        >
                            <v-icon color="secondary">mdi-arrow-left</v-icon>
                            <span
                                v-if="getNav.mini == false"
                                class="title ml-2 font-weight-light"
                                >{{ $t('global:admin') }}</span
                            >
                        </div>
                        <v-list
                            nav
                            dark
                            flat
                            class="elevation-0 active-menu"
                            data-cy="list-admin"
                        >
                            <v-list-item
                                v-for="(item, index) in admin"
                                :key="index"
                                :to="`/${item.link}`"
                                class="my-0"
                            >
                                <v-list-item-icon>
                                    <v-icon>mdi-{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title class="font-weight-light">{{
                                    item.name
                                }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-stepper-content>
                    <!-- SUPERADMIN -->
                    <!-- <v-stepper-content step="5" class="pa-0 pt-0 pr-0">
                        <div
                            class="pa-2 pl-4 d-flex align-center cursor-pointer"
                            @click="getNav.stepper = 1"
                        >
                            <v-icon color="secondary">mdi-arrow-left</v-icon>
                            <span
                                v-if="getNav.mini == false"
                                class="title ml-2 font-weight-light"
                                >{{ $t('global:superAdmin') }}</span
                            >
                        </div>
                        <v-list nav dark flat class="elevation-0">
                            <v-list-item
                                v-for="(item, index) in superAdmin"
                                :key="index"
                                :to="`/${item.link}`"
                                class="my-0"
                            >
                                <v-list-item-icon>
                                    <v-icon>mdi-{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title class="font-weight-light">{{
                                    item.name
                                }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-stepper-content> -->
                </v-stepper-items>
            </v-stepper>
            <div class="navigation__burger">
                <v-app-bar-nav-icon
                    @click="getNav.mini = !getNav.mini"
                ></v-app-bar-nav-icon>
            </div>
            <TodayDate />
        </div>
    </v-navigation-drawer>
</template>

<script>
import TodayDate from './Data'
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    data() {
        return {
            nav: {
                stepper: 1,
            },
            windowWidth: window.innerWidth,
            txt: '',
            active: false,
            items: [
                {
                    name: this.$t('global:personal'),
                    icon: 'account',
                    step: '3',
                    active: true,
                },
                {
                    name: this.$t('global:registries'),
                    icon: 'book',
                    step: '2',
                    active: true,
                },
                {
                    name: this.$t('global:contacts'),
                    icon: 'card-account-details',
                    step: '1',
                    link: '/contacts',
                    active: true,
                },
                {
                    name: this.$t('global:storage'),
                    icon: 'safe-square-outline',
                    step: '1',
                    link: '/storage',
                    active: true,
                },
                {
                    name: this.$t('global:admin'),
                    icon: 'tune-vertical',
                    step: '4',
                    value: 'Admin',
                    active: false,
                },
                // {
                //     name: this.$t('global:superAdmin'),
                //     icon: 'shield-key',
                //     value: 'SuperAdmin',
                //     step: '5',
                //     active: false,
                // },
            ],
            personal: [
                {
                    name: this.$t('global:profile'),
                    icon: 'account-search',
                    link: '/profile',
                },
                // {
                //     name: this.$t('global:tasks'),
                //     icon: 'note-multiple-outline',
                //     link: '/tasks',
                // },
                {
                    name: this.$t('global:alerts'),
                    icon: 'bell-ring-outline',
                    link: '/alerts',
                },
                {
                    name: this.$t('global:storage'),
                    icon: 'safe-square-outline',
                    link: '/storage',
                },
            ],
            admin: [
                {
                    name: this.$t('global:registries'),
                    icon: 'book-variant',
                    link: 'registries',
                },
                {
                    name: this.$t('global:roles'),
                    icon: 'account-group',
                    link: 'roles',
                },
                {
                    name: this.$t('global:dictionaries'),
                    icon: 'book-alphabet',
                    link: 'dictionaries',
                },
                {
                    name: this.$t('global:users-activity'),
                    icon: 'account-convert',
                    link: 'users-activity',
                },
                {
                    name: this.$t('global:translations'),
                    icon: 'google-translate',
                    link: 'translations',
                },
                {
                    name: this.$t('global:settings'),
                    icon: 'cog',
                    link: 'settings',
                },
            ],
            // superAdmin: [],
        }
    },
    components: {
        TodayDate,
    },
    computed: {
        ...mapGetters([
            'getNav',
            'getProfileRegistries',
            'getProfileDetails',
            'getProfilePicture',
        ]),
        registries() {
            return this.getProfileRegistries.sort((a, b) =>
                a.name > b.name ? 1 : -1
            )
        },
    },
    methods: {
        stepOne(items) {
            let arr = []
            items.forEach(item => {
                if (this.getProfileDetails.isSuperAdmin == true) {
                    item.active = true
                } else if (
                    this.getProfileDetails.isAdmin == true &&
                    item.value == 'Admin'
                ) {
                    item.active = true
                }
                if (item.active == true) {
                    arr.push(item)
                }
            })
            return arr
        },
        goToStepper(step) {
            this.getNav.stepper = step
        },
        checkWindowSize() {
            this.windowWidth < 1200
                ? (this.getNav.mini = true)
                : (this.getNav.mini = false)
        },
        onClickOutside() {
            if (this.windowWidth < 1200) {
                this.getNav.mini = true
            }
        },
    },
    beforeMount() {
        this.checkWindowSize()
    },
}
</script>
