<template>
    <div>
        <v-tooltip right>
            <template v-slot:activator="{ on }">
                <v-btn
                    class="buttons--add"
                    v-on="on"
                    fab
                    @click="$emit('open-agree-modal')"
                    :color="btnType.color"
                    small
                    :disabled="!isManager()"
                >
                    <v-icon size="18">{{ btnType.icon }}</v-icon>
                </v-btn>
            </template>
            <span>{{ btnType.tooltip }}</span>
        </v-tooltip>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        buttonType: { type: String, required: true },
        disabled: { type: Boolean },
    },
    data() {
        return {
            btnType: {},
        }
    },
    computed: {
        ...mapGetters(['getProfileDetails', 'getRentInfoRegistry']),
    },
    methods: {
        type(buttonType) {
            switch (buttonType) {
                case 'todaySettlements':
                    return {
                        icon: 'mdi-at',
                        tooltip: 'Pobierz bazę mailingową',
                    }
            }
        },
        isManager() {
            const { _id, isAdmin, isSuperAdmin } = this.getProfileDetails
         
            const managerId = this.getRentInfoRegistry?.currentMenager?._id;
            return (
                _id === managerId ||
                isAdmin ||
                isSuperAdmin
            )
        },
    },
    beforeMount() {
        this.btnType = this.type(this.buttonType)
    },
}
</script>

<style></style>
