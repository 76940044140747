import Rest from './../../../plugins/Rest'
import clone from 'lodash.clone'
import validation from './../../../plugins/validation'
import moment from 'moment'
const state = {
    modal: {
        step: 1,
        fields: {
            to: {
                users: [],
                departments: [],
            },
            alertDate: '',
            alertTime: '',
            priority: '',
            repetitionNumber: '',
            repetitionTime: '',
            reminderNumber: '',
            reminderTime: '',
            description: '',
            alertSource: '',
            active: true,
        },
        users: [],
        errors: {
            stepOne: {
                validation: true,
                fields: {
                    alertDate: '',
                    repetitionNumber: '',
                    reminderNumber: '',
                    alertTime: '',
                    description: '',
                },
            },
            stepTwo: {
                validation: true,
                fields: {
                    to: '',
                },
            },
        },
    },
    request: {
        populate: [
            {
                from: 'users',
                localField: 'to.users',
                foreignField: '_id',
                as: 'to.users',
            },
            {
                from: 'departments',
                localField: 'to.departments',
                foreignField: '_id',
                as: 'to.departments',
            },
        ],
        searchFields: [
            'description',
            'to',
            'from',
            'to.users.name',
            'to.users.lastname',
            'from.name',
            'from.lastname',
            'alertSource.index',
        ],
        select:
            'from to.users.name active to.users.lastname to.departments description alertDate createdBy alertTime itemId seen priority repetitionNumber repetitionTime alertSource',
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: '-alertDate',
        sort: '',
    },
    details: {},
    req: {
        stepOne: ['alertDate', 'alertTime'],
        stepTwo: ['to'],
    },
    dictFields: { priority: '' },
    count: 0,
}

const getters = {
    getAlertModal: state => state.modal.fields,
    getAlertStep: state => state.modal,
    getAlertErrors: state => state.modal.errors,
    getUsers: state => state.modal.users,
    getAlertsSearchFields: state => state.request,
    getAlertsTable: state => state.table,
    getAlertsSearchFields: state => state.request,
    getAlertsCount: state => state.count,
}

const actions = {
    setAlertDescription({ state, commit, dispatch }, payload) {
        state.modal.fields.description = payload
    },

    async createAlert({ state, commit, dispatch }, payload) {
        validation.frontVal(state.modal, state.req, 'alerts')
        if (state.modal.errors.stepTwo.validation)
            try {
                await new Rest('POST', '/alerts')
                    .setBody({
                        ...state.modal.fields,
                        itemId: payload.id,
                        url: payload.url,
                    })
                    .send()
                await dispatch('fetchAlertsTable', {
                    id: payload.id,
                    where: { 'to.users._id': payload.userId, active: true },
                })
                commit('setSnackbars', { type: 'success' })
                commit('clearAlertsErrors')
                return true
            } catch (err) {
                //HANDLE ERRORS
                console.error(err)
                commit('setSnackbars', { type: 'error' })
            }
        else commit('setSnackbars', { type: 'error' })
    },

    async fetchAlertsTable({ state, commit, dispatch }, payload) {
        let where = payload.id
            ? { itemId: payload.id }
            : payload.where
            ? payload.where
            : {}
        try {
            let response = await new Rest('GET', '/alerts')
                .select(state.request.select)
                .limit(state.table.limit)
                .page(state.table.page)
                .search(state.request.searchFields, state.table.search)
                .where(where)
                .sort(state.table.sort || state.table.defaultSort)
                .populate(state.request.populate)
                .send()

            commit('setAlertsTable', response.data)
            return response.data
        } catch (err) {
            //HANDLE ERRORS
            console.error(err)
        }
    },

    async fetchAlert({ state, commit, dispatch }, payload) {
        try {
            let response = await new Rest('GET', `/alerts/${payload.id}`)
                .populate('to.users to.departments')
                .send()
            commit('setAlertsModal', response.data)
        } catch (err) {
            //HANDLE ERRORS
            console.error(err.response)
        }
    },

    async updateAlert({ state, commit, dispatch }, payload) {
        // console.log(state.modal.fields._id)
        // return
        const data = payload.id ? payload.body : state.modal.fields

        try {
            await new Rest(
                'PUT',
                `/alerts/${payload.id || state.modal.fields._id}`
            )
                .setBody({
                    ...data,
                    url: payload.url,
                })
                .send()
            //    isSeen
            if (payload.routeID) {
                dispatch('fetchAlertsTable', { id: payload.routeID })
            } else {
                dispatch('fetchAlertsTable', {
                    where: {
                        'to.users._id': payload.body.userId,
                        active: true,
                    },
                })
            }
            return true
        } catch (err) {
            //HANDLE ERRORS
            console.error(err.response.data)
        }
    },

    async fetchAlertsCount({ state, commit, dispatch }, payload) {
        try {
            let response = await new Rest(
                'GET',
                `alerts/${payload.id}/count`
            ).send()
            commit('setAlertsCount', response.data)
        } catch (error) {
            console.error(error)
        }
    },
}

const mutations = {
    clearAlertsModal(state, payload) {
        state.modal.fields = {
            to: {
                users: [],
                departments: [],
            },
            alertDate: '',
            alertTime: '',
            priority: '',
            repetitionNumber: '',
            repetitionTime: '',
            reminderNumber: '',
            reminderTime: '',
            description: '',
            active: true,
        }
    },
    clearAlertsErrors(state) {
        state.modal.step = 1
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step].fields) {
                state.modal.errors[step].fields[key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
    setAlertsModal(state, payload) {
        state.modal.fields = payload
        state.modal.fields.alertDate = state.modal.fields.alertDate
            ? moment(state.modal.fields.alertDate).format('YYYY-MM-DD')
            : ''
    },
    setAlertsTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
    setAlertsSearch(state, payload) {
        state.request.searchFields = payload
    },
    setAlertsCount(state, payload) {
        state.count = payload
    },
    clearAlertsTable(state) {
        state.table.search = ''
        state.table.page = 1
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
