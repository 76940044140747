<template>
    <tbody>
        <tr v-for="(item, index) in items" :key="index">
            <!-- === === FROM / TO === === -->
            <td class="truncate">
                <DoubleLine
                    :firtstLine="setFrom(item.from)"
                    :secondLine="setUsers(item.to.users)"
                />
            </td>

            <!-- === === NAME === === -->
            <td class="truncate">
                <DoubleLine
                    :firtstLine="item.taskName || $t('global:empty')"
                    :secondLine="item.taskDescription || $t('global:empty')"
                />
            </td>

               <!-- === === TASKSOURCE === === -->
            <td class="truncate" v-if="$router.history.current.name==='tasks'">
                <div style="cursor:pointer;" @click="goToSource(item)">
                <DoubleLine
                    :firtstLine="$get(item,'taskSource.type',false)?$t(item.taskSource.type):''"
                    :secondLine="$get(item,'taskSource.index','')"
                />
                </div>
            </td>

            <!-- === === TASK DATE === === -->
            <td
                class="truncate"
                :style="
                    isToday(item.taskDate) === true
                        ? 'background: #f6f7e6'
                        : 'background: none'
                "
            >
                <DoubleLine
                    :firtstLine="
                        $moment(item.taskDate).format(`DD-MM-YYYY`) ||
                        $t('global:empty')
                    "
                    :secondLine="item.taskTime || $t('global:empty')"
                />
            </td>

            <!-- === === CATEGORY === === -->

            <td class="truncate">
                <span v-if="item.category.backgroundColor">
                    <v-chip
                        :color="
                            $get(item, 'category.backgroundColor', '#555555')
                        "
                        :style="`color: ${$get(
                            item,
                            'category.textColor',
                            '#FFFFFF'
                        )}`"
                        dark
                        label
                        small
                        >{{
                            $get(item, 'category.field', $t('global:empty'))
                        }}</v-chip
                    >
                </span>
                <span v-else>{{ item.category.field }}</span>
            </td>

            <!-- === === PRIORITY === === -->
            <td>
                <span v-if="item.priority.backgroundColor">
                    <v-chip
                        :color="
                            $get(item, 'priority.backgroundColor', '#555555')
                        "
                        :style="`color: ${$get(
                            item,
                            'priority.textColor',
                            '#FFFFFF'
                        )}`"
                        dark
                        label
                        small
                        >{{
                            $get(item, 'priority.field', $t('global:empty'))
                        }}</v-chip
                    >
                </span>
                <span v-else>{{ item.priority.field }}</span>
            </td>

            <!-- === === STATUS === === -->
            <td>
                <v-chip
                    :color="setStatusColor(item.status)"
                    :style="`color: ${$get(
                        item,
                        'status.color.font',
                        '#FFFFFF'
                    )}`"
                    dark
                    label
                    small
                    >{{ setStatus(item.status) }}</v-chip
                >
            </td>
            <td class="d-flex align-center justify-end">
                <v-btn icon small @click="$emit('edit-task', item._id)">
                    <v-icon color="secondary" size="20">mdi-pencil</v-icon>
                </v-btn>
            </td>
        </tr>
    </tbody>
</template>
<script>
export default {
    props: ['items'],
    data() {
        return {
            statuses: [
                { name: 'Otwarty', value: 'OPEN', background: '#2A5C35' },
                {
                    name: 'W trakcie',
                    value: 'IN PROGRESS',
                    background: '#11125E',
                },
                { name: 'Zakończono', value: 'DONE', background: '#525252' },
            ],
        }
    },

    methods: {
        goToSource(data){
            this.$router.push(`${data.taskSource?.type}/${data.itemId}`)
        },
        isToday(date) {
            if (
                this.$moment(date).format(`DD-MM-YYYY`) ===
                this.$moment(new Date()).format(`DD-MM-YYYY`)
            )
                return true
            else return false
        },
        setFrom(item) {
            if (item.name && item.lastname)
                return `${item.name} ${item.lastname}`
            return this.$t('global:empty')
        },
        setUsers(items) {
            let text = ''
            if (items.length > 0) {
                items.forEach(
                    user => (text += `${user.name} ${user.lastname}, `)
                )
            } else this.$t('global:empty')

            return text
        },

        setStatus(status) {
            if (!status) return this.$t('global:empty')
            else {
                let statusToFind = this.statuses.filter(
                    item => item.value == status
                )
                return statusToFind[0].name
            }
        },
        setStatusColor(payload) {
            let status = this.statuses.find(item => item.value === payload)
            return status.background
        },
    }
}
</script>
