import i18next from 'i18next'
import Rest from '../../plugins/Rest'
import { get, set, unset } from 'lodash'
import moment from 'moment'
import crypto from 'crypto-js'

const state = {
    loadLang: false,
    langRange: [],
    translationValues: {},
    namespaceValues: {
        namespace: '',
    },
    translationsTable: {
        namespace: '',
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: 'key',
    },
    namespacesTable: {
        lang: 'pl',
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: 'key',
    },
    request: {
        searchFields: ['key'],
    },
    translationErrors: {
        stepOne: {
            validation: true,
            key: '',
        },
    },
    namespaceErrors: {
        stepOne: {
            validation: true,
            namespace: '',
        },
    },
}

const getters = {
    getLanguageState: state => state.loadLang,
    getNamespacesTable: state => state.namespacesTable,
    getNamespacesSearchFields: state => state.request,
    getTranslationsTable: state => state.translationsTable,
    getTranslationsFields: state => state.request,
    getLangRange: state => state.langRange,
    getNameSpace: state => state.translationsTable.namespace,
    getTranslationValues: state => state.translationValues,
    getNamespaceValues: state => state.namespaceValues,
    getTranslationErrors: state => state.translationErrors,
    getNamespaceErrors: state => state.namespaceErrors,
}

const actions = {
    async deleteTranslation({ state, commit }, payload) {
        try {
            const secret = crypto
                .HmacSHA512(
                    `/api/v2/language/delete/${
                        state.translationsTable.namespace
                    }/${payload.key}${moment().format('DD-MM-YYYY mm')}`,
                    process.env.VUE_APP_LANGUAGE_SECRET
                )
                .toString()
            const response = await fetch(
                `${process.env.VUE_APP_TRANSLATION_SERVER}/api/v2/language/delete/${state.translationsTable.namespace}/${payload.key}`,
                {
                    headers: {
                        authorization: secret,
                    },
                    method: 'DELETE',
                }
            )
            if (response.status === 200) {
                state.langRange.forEach(el => {
                    const resources = i18next.getResourceBundle(
                        el,
                        state.translationsTable.namespace
                    )
                    i18next.removeResourceBundle(
                        el,
                        state.translationsTable.namespace
                    )
                    if (payload.key.split('.').length > 1) {
                        unset(resources, payload.key)
                    } else {
                        delete resources[payload.key]
                    }
                    i18next.addResourceBundle(
                        el,
                        state.translationsTable.namespace,
                        resources
                    )
                })
                commit('setTranslationsTable')
                commit('setSnackbars', { type: 'success' })
            } else {
                commit('setSnackbars', { type: 'error' })
            }
        } catch (e) {
            console.error(e)
            commit('setSnackbars', { type: 'error' })
        }
        return true
    },

    async createTranslation({ state, commit }) {
        try {
            state.translationValues.key = state.translationValues.key.replaceAll(
                /\s/g,
                ''
            )
            const secret = crypto
                .HmacSHA512(
                    `/api/v2/language/upload/${
                        state.translationsTable.namespace
                    }${moment().format('DD-MM-YYYY mm')}`,
                    process.env.VUE_APP_LANGUAGE_SECRET
                )
                .toString()
            const response = await fetch(
                `${process.env.VUE_APP_TRANSLATION_SERVER}/api/v2/language/upload/${state.translationsTable.namespace}`,
                {
                    headers: {
                        authorization: secret,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        translation: state.translationValues,
                    }),
                    method: 'POST',
                }
            )
           
            if (response.status === 200) {
                state.langRange.forEach(el => {
                    i18next.addResourceBundle(
                        el,
                        state.translationsTable.namespace,
                        set(
                            {},
                            state.translationValues.key,
                            state.translationValues[el]
                        ),
                        true,
                        true
                    )
                })
                commit('setTranslationsTable')
                commit('setSnackbars', { type: 'success' })
            } else {
                commit('setSnackbars', { type: 'error' })
            }
        } catch (e) {
            console.error(e)
            commit('setSnackbars', { type: 'error' })
        }
        return true
    },

    async createNamespace({ state, commit }) {
        try {
            const secret = crypto
                .HmacSHA512(
                    `/api/v2/language/upload/${
                        state.namespaceValues.namespace
                    }${moment().format('DD-MM-YYYY mm')}`,
                    process.env.VUE_APP_LANGUAGE_SECRET
                )
                .toString()
            const response = await fetch(
                `${process.env.VUE_APP_TRANSLATION_SERVER}/api/v2/language/upload/${state.namespaceValues.namespace}`,
                {
                    headers: {
                        authorization: secret,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        translation: state.translationValues,
                    }),
                    method: 'POST',
                }
            )
            if (response.status === 200) {
                state.langRange.forEach(el => {
                    i18next.addResourceBundle(
                        el,
                        state.namespaceValues.namespace,
                        {}
                    )
                })
                commit('setNamespaceTable')
                commit('setSnackbars', { type: 'success' })
            } else {
                commit('setSnackbars', { type: 'error' })
            }
        } catch (e) {
            console.error(e)
            commit('setSnackbars', { type: 'error' })
        }
        return true
    },
}

const mutations = {
    setTranslationErrors(state) {
        if (!state.translationValues.key) {
            state.translationErrors.stepOne.validation = false
            state.translationErrors.stepOne.key = `${i18next.t(
                'global:errorFields'
            )}!`
        }
        if (!state.namespaceValues.namespace) {
            state.namespaceErrors.stepOne.validation = false
            state.namespaceErrors.stepOne.namespace = `${i18next.t(
                'global:errorFields'
            )}!`
        }
    },

    clearTranslationErrors(state) {
        state.translationErrors.stepOne.key = ''
        state.namespaceErrors.stepOne.namespace = ''
        state.translationErrors.stepOne.validation = true
        state.namespaceErrors.stepOne.validation = true
    },

    setLanguageState(state, payload) {
        state.loadLang = payload
    },

    setTranslationKey(state, payload) {
        state.translationValues['key'] = payload.key || ''
    },

    setTranslationValues(state) {
        state.langRange.forEach(el => {
            state.translationValues[el] =
                el &&
                state.translationsTable.namespace &&
                state.translationValues.key
                    ? i18next.getResource(
                          el,
                          state.translationsTable.namespace,
                          state.translationValues.key
                      )
                    : ''
        })
    },

    clearTranslationValues(state) {
        state.translationValues = {}
    },

    clearNamespaceValues(state) {
        state.namespaceValues.namespace = ''
    },

    setNamespaceTable(state, payload) {
        state.langRange = Object.keys(i18next.store.data) || state.langRange
        state.namespacesTable.page = payload?.page || state.namespacesTable.page
        state.namespacesTable.limit = state.namespacesTable.limit
        state.namespacesTable.items = Object.keys(
            i18next.store.data[state.namespacesTable.lang]
        )
            .map(el => {
                return { name: el }
            })
            .filter(el => el.name.includes(payload?.search || ''))

        if (payload?.sort) {
            if (payload.sort === 'name') {
                state.namespacesTable.items.sort((a, b) =>
                    a.name < b.name ? -1 : 1
                )
            } else {
                state.namespacesTable.items.sort((a, b) =>
                    a.name > b.name ? -1 : 1
                )
            }
        }

        state.namespacesTable.total = state.namespacesTable.items.length
        state.namespacesTable.items = state.namespacesTable.items.slice(
            0 + state.namespacesTable.limit * (state.namespacesTable.page - 1),
            state.namespacesTable.limit * state.namespacesTable.page
        )
        state.namespacesTable.pages = Math.ceil(
            state.namespacesTable.total / state.translationsTable.limit
        )
    },

    setTranslationsTable(state, payload) {
        state.langRange = Object.keys(i18next.store.data) || state.langRange
        if (payload?.sort != undefined)
            state.translationsTable.defaultSort = payload.sort

        state.translationsTable.page =
            payload?.page || state.translationsTable.page
        state.translationsTable.limit = state.translationsTable.limit
        state.translationsTable.namespace =
            payload?.namespace || state.translationsTable.namespace

        state.translationsTable.items = Object.keys(
            i18next.store.data[state.namespacesTable.lang][
                state.translationsTable.namespace
            ]
        ).map(el => {
            return checkIsNest(el)
        })

        state.translationsTable.items = flatMapFormatter(
            state.translationsTable.items
        ).filter(el => el.key.includes(payload?.search || ''))

        if (state.translationsTable.defaultSort) {
            if (state.translationsTable.defaultSort === 'key') {
                state.translationsTable.items.sort((a, b) =>
                    a.key < b.key ? -1 : 1
                )
            } else {
                state.translationsTable.items.sort((a, b) =>
                    a.key > b.key ? -1 : 1
                )
            }
        }

        state.translationsTable.total = state.translationsTable.items.length
        state.translationsTable.items = state.translationsTable.items.slice(
            0 +
                state.translationsTable.limit *
                    (state.translationsTable.page - 1),
            state.translationsTable.limit * state.translationsTable.page
        )

        state.translationsTable.pages = Math.ceil(
            state.translationsTable.total / state.translationsTable.limit
        )
    },

    clearNamespaceTable(state, payload) {
        state.namespacesTable.page = 1
    },
    clearTranslationsTable(state, payload) {
        state.translationsTable.page = 1
    },
}

const checkIsNest = el => {
    const keys = get(
        i18next.store.data[state.namespacesTable.lang][
            state.translationsTable.namespace
        ],
        `${el}`,
        undefined
    )
    if (keys instanceof Object) {
        return Object.keys(keys).map(elm => {
            return checkIsNest(`${el}.${elm}`)
        })
    } else {
        return { key: el }
    }
}

const flatMapFormatter = el => {
    return el.flatMap(elm => {
        if (Array.isArray(elm)) {
            return flatMapFormatter(elm)
        }
        return elm
    })
}

export default {
    state,
    getters,
    actions,
    mutations,
}
