<template>
    <div>
        <v-tooltip right>
            <template v-slot:activator="{ on }">
                <v-btn
                    v-on="on"
                    fab
                    @click="$emit('raport-function')"
                    :color="btnType.color"
                    small
                    :disabled="disabled"
                >
                    <v-icon size="18">{{ btnType.icon }}</v-icon>
                </v-btn>
            </template>
            <span>{{ btnType.tooltip }}</span>
        </v-tooltip>
    </div>
</template>
<script>
export default {
    props: {
        buttonType: { type: String, required: true },
        disabled: { type: Boolean },
    },
    data() {
        return {
            btnType: {},
        }
    },
    methods: {
        type(buttonType) {
            switch (buttonType) {
                case 'todaySettlements':
                    return {
                        color: 'warning',
                        icon: 'mdi-calendar-today',
                        tooltip: this.$t('rent-contracts:todaySettlements'),
                    }              
            }
        },
    },
    beforeMount() {
        this.btnType = this.type(this.buttonType)
    },
}
</script>
