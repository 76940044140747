<template>
    <v-container class="p-relative">
        <v-row>
            <v-col cols="12" md="12" class="py-0">
                <RegistryHeader
                    :title="getRentInfoRegistry.name"
                    :avatarData="getRentInfoRegistry.currentMenager"
                    @clearSearch="clearTable"
                />

                <Table
                    :title="getRentInfoRegistry.name"
                    :items="getRentInfoTable.items"
                    :headers="headers"
                    :length="getRentInfoTable.pages"
                    :page="getRentInfoTable.page"
                    @search="setSearch"
                    @search-fields="setSearchFields"
                    @change-page="setPage"
                    @sort="setSort"
                    :defaultFields="getRentInfoSearchFields.searchFields"
                    :loading="getRentInfoTable.loading"
                    @clear-table="clearTable"
                >
                    <template v-slot:columns="{ isExpanded, expand }">
                        <Columns
                            :headers="headers"
                            :items="getRentInfoTable.items"
                            :isExpanded="isExpanded"
                            :expand="expand"
                        />
                    </template>
                </Table>
                <Modal
                    :title="$t('rent-contracts:rent-info.adding')"
                    :open="open"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        :action="'create'"
                        slot="buttons"
                        @closeModal="open = false"
                    />
                </Modal>
                <AlertModalAnnex
                    :open="openAlertModal"
                    :onClose="onCloseAlert"
                    :annexCnt="annexCnt"
                    nameSpaceI18n="rent-contracts"
                />

                <div class="buttons--switch d-flex ml-2">
                    <v-switch
                        v-model="bottomSwitch"
                        color="success"
                        value="success"
                    ></v-switch>
                    <RaportButtons
                        class="ml-1 mt-2"
                        v-if="bottomSwitch"
                        @raport-function="showUnsettled()"
                        :buttonType="'todaySettlements'"
                        :disabled="
                            getTodayRent && getTodayRent.length > 0
                                ? false
                                : true
                        "
                    />
                    <FilterContractBeforeDateBtn
                        @filter-contract-before-date="
                            filterContractBeforeDate()
                        "
                        class="ml-1 mt-2"
                        :color="'error'"
                        :icon="'mdi-exclamation-thick'"
                        :tooltip="
                            'Nierozliczone przed ' +
                            getCurrentDay() +
                            ' dniem miesiąca'
                        "
                        v-if="bottomSwitch"
                    />
                    <FilterContractAfterDateBtn
                        @filter-contract-after-date="filterContractAfterDate()"
                        class="ml-1 mt-2"
                        :color="'#6571B1'"
                        :icon="'mdi-currency-usd'"
                        :tooltip="
                            'Do rozliczenia po ' +
                            getCurrentDay() +
                            ' dniu miesiąca'
                        "
                        v-if="bottomSwitch"
                    />
                    <DownloadRepresentativesButton
                        v-if="bottomSwitch"
                        class="ml-2 mt-2"
                        @open-agree-modal="openCSVRepresentativeModal()"
                        :buttonType="'todaySettlements'"
                        :disabled="false"
                    />
                </div>
                <div class="d-flex justify-end buttons--action">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                data-cy="addEntry"
                                fab
                                dark
                                v-on="on"
                                small
                                v-if="add()"
                                @click="addRentInfo()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('rent-contracts:addRentInfo') }}</span>
                    </v-tooltip>
                </div>
            </v-col>
        </v-row>
        <ConfirmModal
            :width="'500'"
            :openConfirm="openDownloadRepresentativeCSV"
        >
            <div slot="main">
                <DownloadRepresentativeModalContent />
            </div>
            <div slot="buttons">
                <CSVRepresenetativeModalButtons
                    :data="getDataToCSVRepresentativeModal()"
                    @close-csv-representative-modal="
                        closeCSVRepresentativeModal
                    "
                />
            </div>
        </ConfirmModal>
        <v-overlay :value="getCSVFileLoading" @dblclick.native="closeOnClick">
            <div>
                <div style="display: grid; justify-items: center;">
                    <span style="font-size: 1.3em;">
                        Trwa rozliczanie z pliku CSV
                    </span>
                    <v-progress-circular
                        :size="20"
                        :width="2"
                        indeterminate
                        color="white"
                    ></v-progress-circular>
                </div>
            </div>
        </v-overlay>
    </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import store from './../../../store/index'
import Columns from '../../../components/Registries/Rent-contracts/Table/Columns.vue'
import AEContent from './../../../components/Registries/Rent-contracts/Modal/Content.vue'
import Buttons from './../../../components/Registries/Rent-contracts/Modal/Buttons.vue'
import AlertModalAnnex from '../../../components/Registries/Rent-contracts/Modal/AlertModal/AlertModalAnnex.vue'
import RaportButtons from '../../../components/Registries/Rent-contracts/ButtonsRaport/RaportButtons.vue'
import DownloadRepresentativesButton from '../../../components/Registries/Rent-contracts/ButtonsRaport/DownloadRepresentativesButton.vue'
import DownloadRepresentativeModalContent from '../../../components/Registries/Rent-contracts/CSVDownloadRepresentative/CSVRepresenetativeModalContent.vue'
import CSVRepresenetativeModalButtons from '../../../components/Registries/Rent-contracts/CSVDownloadRepresentative/CSVRepresenetativeModalButtons.vue'
import FilterContractBeforeDateBtn from '../../../components/Registries/Rent-contracts/ButtonsRaport/FilterContractBeforeDateBtn.vue'
import FilterContractAfterDateBtn from '../../../components/Registries/Rent-contracts/ButtonsRaport/FilterContractAfterDateBtn.vue'

export default {
    data() {
        return {
            bottomSwitch: false,
            openDownloadRepresentativeCSV: false,
            annexCnt: 0,
            openAlertModal: false,
            unsettled: false,
            open: false,
            headers: [
                {
                    text: this.$t('rent-contracts:rent-info.index'),
                    value: 'index',
                    width: '10%',
                    showSearch: true,
                },
                {
                    text: this.$t('rent-contracts:rent-info.contractNo'),
                    value: 'contractNo',
                    width: '15%',
                    showSearch: true,
                },
                {
                    text: this.$t('rent-contracts:rent-info.contractor'),
                    value: 'rentAnnexs.contractor.name',
                    width: 'auto',
                    showSearch: true,
                },
                {
                    text: this.$t('rent-contracts:rent-info.expirationDate'),
                    value: 'annex.expirationDate',
                    width: '10%',
                    // sortable: false,
                },
                {
                    text: this.$t('rent-contracts:rent-info.customerCarer'),
                    value: 'annex.customerCarer',
                    width: '12%',
                    showSearch: true,
                },
                {
                    text: this.$t('rent-contracts:rent-info.accountingDay'),
                    value: 'annex.accountingDay',
                    showSearch: false,
                    width: '10%',
                    showSearch: true,
                    // sortable: false,
                },
                {
                    text: this.$t('rent-contracts:rent-info.status'),
                    value: 'rentAnnexs.status.name',
                    showSearch: true,
                    width: '8%',
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: '8%',
                    sortable: false,
                },
            ],
        }
    },
    components: {
        Columns,
        AEContent,
        Buttons,
        AlertModalAnnex,
        RaportButtons,
        DownloadRepresentativesButton,
        DownloadRepresentativeModalContent,
        CSVRepresenetativeModalButtons,
        FilterContractBeforeDateBtn,
        FilterContractAfterDateBtn,
    },
    computed: {
        ...mapGetters([
            'getRentInfoRegistry',
            'getRentInfoTable',
            'getRentInfoSearchFields',
            'getRentInfoModal',
            'getLastValueRentInfo',
            'getSearch',
            'getTodayRent',
            'getUsersList',
            'getUnsettledContractsBeforeDate',
            'getCSVFileLoading',
        ]),
    },
    methods: {
        ...mapActions([
            'fetchRentInfoTable',
            'fetchUsers',
            'fetchLastRentInfo',
            'findUnsettledLastMonth',
        ]),
        ...mapMutations([
            'clearRentInfoModal',
            'clearRentInfoErrors',
            'setRentInfoTable',
            'clearRentInfoTable',
            'setRentInfoSearch',
            'setRentInfoWhere',
            'SET_CURRENT_TABLE_MODE',
            'SET_CSV_FILE_LOADING',
        ]),
        closeOnClick() {
            this.SET_CSV_FILE_LOADING(false)
        },
        getCurrentDay() {
            return this.$moment().format('D')
        },
        async filterContractBeforeDate() {
            this.SET_CURRENT_TABLE_MODE('BEFORE')
            this.$route.params.unsettled = false
            this.getSearch.text = ''
            this.unsettled = false
            this.unsettledBeforeCurrentDay = false
            this.unsettledAfterCurrentDay = false
            this.clearRentInfoTable()
            this.unsettledBeforeCurrentDay = true
            this.setRentInfoTable({ sort: '-annex.accountingDay' })
            await this.fetchRentInfoTable({ unsettledBeforeCurrentDay: true })
        },
        async filterContractAfterDate() {
            this.SET_CURRENT_TABLE_MODE('AFTER')
            this.$route.params.unsettled = false
            this.getSearch.text = ''
            this.unsettled = false
            this.unsettledBeforeCurrentDay = false
            this.unsettledAfterCurrentDay = false
            this.clearRentInfoTable()
            this.unsettledAfterCurrentDay = true
            this.setRentInfoTable({ sort: '-annex.accountingDay' })
            await this.fetchRentInfoTable({ unsettledAfterCurrentDay: true })
        },

        getDataToCSVRepresentativeModal() {
            const rentInfoTable = this.getRentInfoTable.items

            return rentInfoTable
        },

        async openCSVRepresentativeModal() {
            this.openDownloadRepresentativeCSV = true
            await store.dispatch('fetchPreparedDataFromRentContractsItems')
        },
        closeCSVRepresentativeModal() {
            this.openDownloadRepresentativeCSV = false
        },
        async onCloseAlert() {
            await this.fetchRentInfoTable({ unsettledLastMonth: true })
            this.openAlertModal = false
        },
        add() {
            return this.checkPermissions('CREATE')
        },
        closeModal() {
            this.open = false
        },

        getNextIndex(lastIndex) {
            const splitedIndex = lastIndex?.split('/')
            if (splitedIndex && splitedIndex.length === 3) {
                return (
                    splitedIndex[0] +
                    '/' +
                    splitedIndex[1] +
                    '/' +
                    (+splitedIndex[2] + 1)
                )
            }

            return `${this.getRentInfoRegistry.index}/${this.$moment().format(
                'YYYY'
            )}/1`
        },

        async addRentInfo() {
            await this.fetchLastRentInfo()
            await this.fetchUsers({
                select: 'name lastname mail status',
                where: {
                    isUser: true,
                    'status.state': 'ACTIVE',
                    isDeleted: false,
                },
            })

            this.open = true
            this.clearRentInfoErrors()
            this.clearRentInfoModal()
            const lastIndex = this.getLastValueRentInfo?.contractNo
            const newIndex = this.getNextIndex(lastIndex)
            if (newIndex) this.getRentInfoModal.contractNo = newIndex

            this.getRentInfoModal.annex.accountingDay = '1'
        },

        setSearchFields(searchFields) {
            this.setRentInfoSearch(searchFields)
        },
        setSearch(search) {
            this.setRentInfoTable({ search, page: 1 })
            this.fetchRentInfoTable()
        },
        async setPage(page) {
            this.setRentInfoTable({ page })
            if (this.unsettledBeforeCurrentDay) {
                this.setRentInfoTable({ sort: '-annex.accountingDay' })
                await this.fetchRentInfoTable({
                    unsettledBeforeCurrentDay: this.unsettledBeforeCurrentDay,
                })
            } else if (this.unsettledAfterCurrentDay) {
                this.setRentInfoTable({ sort: '-annex.accountingDay' })
                await this.fetchRentInfoTable({
                    unsettledAfterCurrentDay: this.unsettledAfterCurrentDay,
                })
            } else {
                await this.fetchRentInfoTable({ unsettled: this.unsettled })
            }
        },
        async setSort(sort) {
            this.setRentInfoTable({ sort })
            if (this.unsettledBeforeCurrentDay) {
                await this.fetchRentInfoTable({
                    unsettledBeforeCurrentDay: this.unsettledBeforeCurrentDay,
                })
            } else if (this.unsettledAfterCurrentDay) {
                await this.fetchRentInfoTable({
                    unsettledAfterCurrentDay: this.unsettledAfterCurrentDay,
                })
            } else if (this.unsettled) {
                await this.fetchRentInfoTable({ unsettled: this.unsettled })
            } else {
                this.fetchRentInfoTable()
            }
        },
        clearTable() {
            this.$route.params.unsettled = false
            this.getSearch.text = ''
            this.unsettled = false
            this.unsettledBeforeCurrentDay = false
            this.unsettledAfterCurrentDay = false
            this.clearRentInfoTable()
            this.fetchRentInfoTable()
        },
        showUnsettled() {
            this.SET_CURRENT_TABLE_MODE('TODAY')
            this.$route.params.unsettled = false
            this.getSearch.text = ''
            this.unsettled = false
            this.unsettledBeforeCurrentDay = false
            this.unsettledAfterCurrentDay = false
            this.clearRentInfoTable()
            this.unsettled = true
            this.fetchRentInfoTable({ unsettled: this.unsettled })
        },
    },

    beforeRouteEnter(to, from, next) {
        if (from.matched[1] && from.matched[1].path !== '/rent-info/:id') {
            store.commit('clearRentInfoTable')
        }

        if (to.params.unsettled == true) {
            store.dispatch('fetchRentInfoTable', { unsettled: true })
            next()
        } else {
            store.dispatch('fetchRentInfoTable')
            next()
        }
    },
}
</script>
