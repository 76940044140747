var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () =>
                                    _vm.getStorageAttachmentsItems.errors
                                        .stepOne.validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('attachments:addFromStorage')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('attachments:addFromStorage')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.getStorageTable.items,"multiple":"","label":`${_vm.$t(
                                            'attachments:chooseFiles'
                                        )}*`,"item-value":"_id","return-object":"","error-messages":_vm.getStorageAttachmentsItems
                                                .errors.stepOne.files},scopedSlots:_vm._u([{key:"selection",fn:function(selection){return [_c('span',[_vm._v(_vm._s(selection.item.name))]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(selection.item.lastname))]),(
                                                    _vm.getStorageModal.users
                                                        .length > 1
                                                )?_c('span',{staticClass:"mr-1"},[_vm._v(",")]):_vm._e()]}},{key:"item",fn:function(item){return [_c('div',{staticClass:"doubleLine"},[_c('span',{staticClass:"firstLine"},[_vm._v(_vm._s(item.item.name))]),_c('span',{staticClass:"secondLine"},[_vm._v(_vm._s(item.item.description))])])]}}]),model:{value:(
                                            _vm.getStorageAttachmentsItems.items
                                        ),callback:function ($$v) {_vm.$set(_vm.getStorageAttachmentsItems, "items", $$v)},expression:"\n                                            getStorageAttachmentsItems.items\n                                        "}})],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }