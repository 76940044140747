<template>
    <div>
        <p
            v-if="getNav.mini == false"
            class="navigation__calendar"
            data-cy="calendar"
        >
            {{ $t('todayIs') }}
            {{ $moment(new Date()).format(`dddd, DD/MM/YYYY`) }}
        </p>
        <p v-else class="navigation__calendar" data-cy="calendar">
            <span>{{ $moment(new Date()).format('dd') }}</span>
            <span>{{ $moment(new Date()).format('DD/MM') }}</span>
        </p>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
/**
 * Central Table
 * @displayName Data - Navigation Drawers
 */
export default {
    i18nOptions: { namespaces: ['global'] },
    computed: {
        ...mapGetters(['getNav']),
    },
}
</script>
