import Rest from './../../../plugins/Rest'
import clone from 'lodash.clone'
import i18n from './../../../plugins/i18next'
import validation from './../../../plugins/validation'
import moment from 'moment'
import clearSearch from './../../../plugins/clearSearch'
const state = {
    modal: {
        fields: {
            sn: '',
            model: '',
            manufacturer: '',
            deviceType: '',
            description: '',
            serviceBase: '',
            priority: '',
            purchaseDate: '',
            guaranteeFrom: '',
            guaranteeTo: '',
            partGuarantee: '',
            client: '',
            branch: '',
            floor: '',
            representatives: '',
            deliverer: null,
            owner: '',
            comment: '',
            purchaseInvoice: '',
            // OSP: '',
            // dedicatedPrice: false,
            // survey: {
            //     standard: 0,
            //     dedicated: 0,
            // },
            // installation: {
            //     standard: 0,
            //     dedicated: 0,
            // },
            // itService: {
            //     standard: 0,
            //     dedicated: 0,
            // },
            // repair: {
            //     standard: 0,
            //     dedicated: 0,
            // },
            // diagnostics: {
            //     standard: 0,
            //     dedicated: 0,
            // },
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {
                    sn: '',
                    model: '',
                    serviceBase: '',
                    client: '',
                    branch: '',
                    deliverer: '',
                },
            },
            stepTwo: {
                validation: true,
            },
        },
        step: 1,
    },
    request: {
        populate: [
            {
                localField: 'client',
                foreignField: '_id',
                from: 'contacts',
                as: 'client',
            },
            {
                localField: 'model',
                foreignField: '_id',
                from: 'models',
                as: 'model',
            },
            {
                localField: 'model.servicePrice',
                foreignField: '_id',
                from: 'service-prices',
                as: 'model.servicePrice',
            },
            {
                localField: 'createdBy',
                foreignField: '_id',
                from: 'users',
                as: 'createdBy',
            },
            {
                localField: 'anotherDevice.contractsNumber',
                foreignField: '_id',
                from: 'contracts',
                as: 'anotherDevice.contractsNumber',
            },
        ],
        unwindExeptions: [],
        searchFields: [
            'sn',
            // 'model',
            // 'floor',
            // 'model.model',
            // 'model.manufacturer',
            // 'client.NIP',
            // 'client.NIP',
            'client.city',
            'client.street',
            'client.name',
            'client.branches.name join client.branches.city join client.branches.address',
            // 'model.deviceType',
            'identificationNumber',
        ],
        defaultSearchFields: [
            'sn',
            'client.city',
            'client.street',
            'client.name',
            'client.branches.name join client.branches.city join client.branches.address',
            'identificationNumber',
        ],
        select:
            'partGuarantee anotherDevice.contractsNumber._id anotherDevice.contractsNumber.number client.name client.branches client.postCode client.city client.street client.houseNumber model.model model.manufacturer model.deviceType model.servicePrice.name isDeleted manufacturer createdAt status serviceBase branch deviceType floor sn guaranteeFrom guaranteeTo leasing identificationNumber',
        where: {
            isDeleted: false,
        },
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: '-createdAt',
        loading: false,
    },
    statuses: [],
    details: {},
    registry: {},
    dictFields: { serviceBase: '', priority: '', owner: '' },
}

const getters = {
    getDeviceModal: state => state.modal.fields,
    getDeviceError: state => state.modal.errors,
    getDeviceStep: state => state.modal,
    getDevicesTable: state => state.table,
    getDeviceStatuses: state => state.statuses,
    getDevicesSearchFields: state => state.request,
    getDeviceDetails: state => state.details,
    getDevicesRegistry: state => state.registry,
}

const actions = {
    async fetchDevicesRegistry({ commit }) {
        let registry = await new Rest('GET', 'registries')
            .select('currentMenager name availableStatuses icon')
            .where({ url: 'devices' })
            .populate([
                {
                    path: 'currentMenager',
                    select: 'name lastname mail status phone picture',
                },
            ])
            .send()

        // commit('setDevicesStatuses', registry.data.items[0].availableStatuses)
        commit('setDevicesRegistry', registry.data.items)
    },

    async fetchDevicesTable({ state, commit, dispatch }) {
        state.table.loading = true
        try {
            let response = await new Rest('GET', 'devices')
                .select(state.request.select)
                .limit(state.table.limit)
                .page(state.table.page)
                .where(state.request.where)
                .unwindExeptions(state.request.unwindExeptions)
                .populate(state.request.populate)
                .search(state.request.searchFields, state.table.search)
                .sort(state.table.sort || state.table.defaultSort)
                .send()

            dispatch('fetchDevicesRegistry')
            commit('setDevicesTable', response.data)
        } catch (err) {
            console.error(err)
        }
        state.table.loading = false
    },

    async createDevice({ commit, dispatch }, payload) {
        try {
            const device = await new Rest('POST', 'devices')
                .setBody(state.modal.fields)
                .send()
            commit('clearDevicesModal')
            commit('clearDeviceErrors')
            dispatch('fetchDevicesTable')
            commit('setSnackbars', { type: 'success' })
            return device.data
        } catch (err) {
            //HANDLE ERRORS
            if (err) {
                commit('setSnackbars', { type: 'error' })
                validation.setErrors(state.modal.errors, err)
            }
        }
    },

    async fetchDevice({ state, commit }, payload) {
        try {
            let device = await new Rest('GET', `devices/${payload.id}`)
                .populate([
                    {
                        path: 'client',
                    },
                    {
                        path: 'model',
                        populate: { path: 'servicePrice' },
                    },
                    {
                        path: 'createdBy',
                        select: 'name lastname mail status phone picture',
                    },
                    {
                        path: 'leasing',
                        select: 'leasingNumber',
                    },
                ])

                .select('-folders')
                .send()

            let statusesReq = new Rest('GET', `registries/devices/statuses`)
                .select('name _id isDeleted color state')
                .where({ isDeleted: false })
                .send()

            let registryReq = new Rest('GET', 'registries')
                .select('currentMenager name')
                .where({ url: 'devices' })
                .populate([
                    {
                        path: 'currentMenager',
                        select: 'name lastname mail status phone picture',
                    },
                ])
                .send()
            let { 0: response, 1: statuses, 2: registry } = await Promise.all([
                device,
                statusesReq,
                registryReq,
            ])
            commit('setDeviceDetails', response.data)
            commit('setDeviceStatuses', statuses.data.items)
            commit('setDevicesRegistry', registry.data.items)

            if (payload.next) payload.next()
        } catch (err) {
            console.error(err)
        }
    },

    async fetchDeviceBySN({ state, commit }, payload) {
        try {
            let device = await new Rest('GET', `devices`)
                .where({ sn: payload, isDeleted: false })
                .select('-folder')
                .send()
            if (
                device &&
                device.data &&
                device.data.items &&
                device.data.items.length > 0
            ) {
                return await device.data.items[0]
            }
        } catch (err) {
            console.error(err)
        }
    },

    async setPrintDevice({ state, commit, dispatch }, payload) {
        try {
            await new Rest('PUT', `devices/${payload.id}`)
                .setBody({ ...state.details, QRprinted: payload.QRprinted })
                .send()
            dispatch('fetchDevice', { id: payload.id })
            commit('clearDevicesModal')
            commit('clearDeviceErrors')
            return true
        } catch (err) {
            //HANDLE ERRORS
            validation.setErrors(state.modal.errors, err)
            commit('setSnackbars', { type: 'error' })
        }
    },

    async updateDevice({ state, commit, dispatch }, payload) {
        try {
            await new Rest('PUT', `devices/${payload.id}`)
                .setBody({
                    ...state.modal.fields,
                    QRprinted:
                        state.details.QRprinted &&
                        state.details.sn === state.modal.fields.sn,
                })
                .send()
            dispatch('fetchDevice', { id: payload.id })
            commit('clearDevicesModal')
            commit('clearDeviceErrors')
            return true
        } catch (err) {
            //HANDLE ERRORS
            validation.setErrors(state.modal.errors, err)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async updateDeviceStatus({ commit, dispatch }, payload) {
        commit('setDeviceModal')
        commit('setDeviceStatus', payload.status)
        try {
            let response = await new Rest('PUT', `devices/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            commit('setSnackbars', { type: 'success' })
        } catch (err) {
            console.error(err)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async deleteDevice({ commit, dispatch }, payload) {
        try {
            await new Rest('DELETE', `devices/${payload.id}`).send()
            dispatch('fetchDevicesTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            console.error(err)
        }
    },
}

const mutations = {
    setDevicesTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },

    clearDevicesModal(state, payload) {
        state.modal.step = 1
        state.modal.fields = {
            sn: '',
            model: '',
            manufacturer: '',
            deviceType: '',
            description: '',
            serviceBase: '',
            priority: '',
            purchaseDate: '',
            guaranteeFrom: '',
            guaranteeTo: '',
            partGuarantee: '',
            client: '',
            branch: '',
            floor: '',
            representatives: '',
            deliverer: '',
            asset: '',
            owner: '',
            options: '',
            OSP: '',
            comment: '',
            dedicatedPrice: false,
            survey: {
                standard: 0,
                dedicated: 0,
            },
            installation: {
                standard: 0,
                dedicated: 0,
            },
            itService: {
                standard: 0,
                dedicated: 0,
            },
            repair: {
                standard: 0,
                dedicated: 0,
            },
            diagnostics: {
                standard: 0,
                dedicated: 0,
            },
        }
    },
    setDevicesSearch(state, payload) {
        state.request.searchFields = payload
    },
    setDeviceDetails(state, payload) {
        state.details = payload
        state.details.representatives =
            payload.client.representatives &&
            payload.client.representatives.length > 0
                ? payload.client.representatives.find(
                      r => r._id === payload.representatives
                  )
                : undefined
        state.details.branch = payload.client.branches
            ? payload.client.branches.find(r => r._id === payload.branch)
            : undefined
    },
    setDeviceStatus(state, payload) {
        state.modal.fields.status = payload
    },
    setDeviceStatuses(state, payload) {
        state.statuses = payload
    },
    setDeviceModal(state, payload) {
        let obj = payload ? payload : clone(state.details)
        state.modal.fields = obj
        state.modal.fields.purchaseDate = obj.purchaseDate
            ? moment(obj.purchaseDate).format('YYYY-MM-DD')
            : ''
        state.modal.fields.guaranteeFrom = obj.guaranteeFrom
            ? moment(obj.guaranteeFrom).format('YYYY-MM-DD')
            : ''
        state.modal.fields.guaranteeTo = obj.guaranteeTo
            ? moment(obj.guaranteeTo).format('YYYY-MM-DD')
            : ''
        state.modal.fields.partGuarantee = obj.partGuarantee
            ? moment(obj.partGuarantee).format('YYYY-MM-DD')
            : ''
    },

    setDevicesRegistry(state, payload) {
        state.registry = payload[0]
    },
    clearDeviceErrors(state) {
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step].fields) {
                state.modal.errors[step].fields[key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
    clearDevicesTable(state) {
        clearSearch.clearSearchTable(state)
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
