<template>
    <div>
        <div class="pt-0 details__attachments__folders__headers">
            <v-icon
                data-cy="addFolder"
                size="18"
                @click="$emit('open-folder-modal', { type: 'create' })"
                >mdi-folder-plus</v-icon
            >
            <span>{{ $t('global:folderName') }}</span>
        </div>
        <v-list class="pa-0">
            <v-list-item-group
                mandatory
                @change="getAttachments"
                v-model="currentFolder"
            >
                <draggable
                    data-cy="folders"
                    v-model="dragList"
                    v-bind="dragOptions"
                    @start="dragging = true"
                    @end="dragging = false"
                >
                    <v-list-item
                        v-for="item in dragList"
                        :key="item._id"
                        :value="item._id"
                    >
                        <v-list-item-icon class="ml-0">
                            <v-icon size="18">mdi-folder</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <DoubleLine
                                            data-cy="singleFolder"
                                            v-on="on"
                                            :firtstLine="item.name"
                                            :secondLine="item.description"
                                        />
                                    </template>
                                    <span>{{ item.name }}</span>
                                </v-tooltip>
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon small v-on="on">
                                    <v-icon
                                        color="iconGray"
                                        small
                                        v-if="update()"
                                        @click="
                                            $emit('open-folder-modal', {
                                                type: 'edit',
                                                item,
                                            })
                                        "
                                        >mdi-pencil</v-icon
                                    >
                                </v-btn>
                            </template>
                            <span>{{ $t('attachments:editFolder') }}</span>
                        </v-tooltip>
                        <v-btn icon small>
                            <v-icon
                                color="red"
                                small
                                v-if="remove()"
                                @click="
                                    $emit('remove-folder-modal', {
                                        id: item._id,
                                    })
                                "
                                >mdi-delete</v-icon
                            >
                        </v-btn>
                    </v-list-item>
                </draggable>
            </v-list-item-group>
        </v-list>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    components: { draggable },
    data() {
        return {
            dragging: false,
        }
    },
    computed: {
        ...mapGetters(['getFoldersItems', 'getCurrentFolder']),
        dragOptions() {
            return {
                animation: 200,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost',
            }
        },
        dragList: {
            get() {
                return this.getFoldersItems
            },
            set(value) {
                this.changeFolderPosition({
                    data: value,
                    additional: {
                        routeName: this.$route.name,
                        routeId: this.$route.params.id,
                    },
                }).then(() => {
                    this.setFolders(value)
                })
            },
        },
        currentFolder: {
            get() {
                return this.getCurrentFolder
            },
            set(value) {
                this.setFilesFolderID(value)
            },
        },
    },
    methods: {
        ...mapActions(['fetchFiles', 'changeFolderPosition']),
        ...mapMutations(['setFolders', 'setFilesFolderID', 'setFilesFolderID']),
        async getAttachments(e) {
            this.$emit('clearSearch')
            await this.fetchFiles({
                routeName: this.$route.name,
                routeId: this.$route.params.id,
                folderID: e || null,
            })
        },
        update() {
            return this.checkPermissions('UPDATE')
        },
        remove() {
            return this.checkPermissions('DELETE')
        },
    },
    beforeDestroy() {
        this.setFolders([])
        this.setFilesFolderID('')
    },
}
</script>
