var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.getRentDeviceErrors.step),callback:function ($$v) {_vm.$set(_vm.getRentDeviceErrors, "step", $$v)},expression:"getRentDeviceErrors.step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () =>
                                    _vm.getRentDeviceErrors.stepOne.validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('global:basicInfo')))]),_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () =>
                                    _vm.getRentDeviceErrors.stepTwo.validation,
                            ],"editable":"","step":"2"}},[_vm._v(_vm._s(_vm.$t('global:additionalInfo')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{staticClass:"pb-0 pt-4",attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('global:basicInfo')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[(_vm.getRentDeviceModal.device)?_c('v-combobox',{attrs:{"label":`${_vm.$t(
                                            'rent-contracts:rent-annex.device.sn'
                                        )}`,"hint":"Zacznij pisać...","items":_vm.devices,"item-text":"sn","return-object":"","error-messages":_vm.getRentDeviceErrors.stepOne
                                                .fields.device,"readonly":_vm.getRentDeviceModal.device &&
                                            _vm.edit,"append-icon":''},on:{"keyup":function($event){return _vm.findDevices($event)},"change":_vm.onChange,"click":_vm.onSelect},model:{value:(_vm.getRentDeviceModal.device),callback:function ($$v) {_vm.$set(_vm.getRentDeviceModal, "device", $$v)},expression:"getRentDeviceModal.device"}}):_c('v-combobox',{attrs:{"label":`${_vm.$t(
                                            'rent-contracts:rent-annex.device.sn'
                                        )}`,"hint":"Zacznij pisać...","items":_vm.devices,"item-text":"sn","return-object":"","error-messages":_vm.getRentDeviceErrors.stepOne
                                                .fields.device,"readonly":_vm.getRentDeviceModal.device &&
                                            _vm.edit},on:{"keyup":function($event){return _vm.findDevices($event)},"change":_vm.onChange,"click":_vm.onSelect},model:{value:(_vm.getRentDeviceModal.device),callback:function ($$v) {_vm.$set(_vm.getRentDeviceModal, "device", $$v)},expression:"getRentDeviceModal.device"}})],1),_c('v-col',{attrs:{"offset":"4"}},[_c('money-field',{staticClass:"margin--input",attrs:{"id":"money","disabled":false,"readonly":_vm.checkIsReadOnly(),"label":`${_vm.$t(
                                            'rent-contracts:rent-annex.device.rent'
                                        )}`},model:{value:(_vm.getRentDeviceModal.rent),callback:function ($$v) {_vm.$set(_vm.getRentDeviceModal, "rent", $$v)},expression:"getRentDeviceModal.rent"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t(
                                                'rent-contracts:rent-annex.device.model.manufacturer'
                                            ),"value":_vm.$get(
                                                _vm.getRentDeviceModal,
                                                'device.model.manufacturer'
                                            ),"readonly":""}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t(
                                                'rent-contracts:rent-annex.device.model.model'
                                            ),"value":_vm.$get(
                                                _vm.getRentDeviceModal,
                                                'device.model.model'
                                            ),"readonly":""}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('money-field',{staticClass:"margin--input",attrs:{"precision":2,"label":`${_vm.$t(
                                            'rent-contracts:rent-annex.device.monitoringCost'
                                        )}`},model:{value:(
                                            _vm.getRentDeviceModal.monitoringCost
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentDeviceModal, "monitoringCost", $$v)},expression:"\n                                            getRentDeviceModal.monitoringCost\n                                        "}})],1)],1),_c('v-row',{staticClass:"background--total mb-2 background--grey"},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text--settlement"},[_vm._v(_vm._s(_vm.$t( 'rent-contracts:rent-annex.device.backgroundMono' )))])]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-checkbox',{staticClass:"mx-2",attrs:{"readonly":_vm.checkIsReadOnly(),"label":_vm.$t(
                                                'rent-contracts:rent-annex.device.calculated'
                                            )},on:{"click":function($event){return _vm.clearFields('isMono')}},model:{value:(_vm.getRentDeviceModal.isMono),callback:function ($$v) {_vm.$set(_vm.getRentDeviceModal, "isMono", $$v)},expression:"getRentDeviceModal.isMono"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('money-field',{staticClass:"margin--input",attrs:{"id":"money","disabled":!_vm.getRentDeviceModal.isMono,"readonly":_vm.checkIsReadOnly(),"precision":3,"label":`${_vm.$t(
                                            'rent-contracts:rent-annex.device.monoPrice'
                                        )}`},model:{value:(
                                            _vm.getRentDeviceModal.monoPrice
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentDeviceModal, "monoPrice", $$v)},expression:"\n                                            getRentDeviceModal.monoPrice\n                                        "}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":_vm.$t(
                                                'rent-contracts:rent-annex.device.monoBundle'
                                            ),"disabled":!_vm.getRentDeviceModal.isMono,"readonly":_vm.checkIsReadOnly()},on:{"keypress":_vm.integralNumbers},model:{value:(
                                            _vm.getRentDeviceModal.monoBundle
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentDeviceModal, "monoBundle", $$v)},expression:"\n                                            getRentDeviceModal.monoBundle\n                                        "}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('money-field',{staticClass:"margin--input",attrs:{"id":"money","disabled":!_vm.getRentDeviceModal.isMono,"readonly":this.readonly,"label":`${_vm.$t(
                                            'rent-contracts:rent-annex.device.monoBundleCost'
                                        )}`,"errorMessage":_vm.getRentDeviceErrors.stepOne
                                                .fields.monoBundleCost},model:{value:(
                                            _vm.getRentDeviceModal.monoBundleCost
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentDeviceModal, "monoBundleCost", $$v)},expression:"\n                                            getRentDeviceModal.monoBundleCost\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t(
                                                'rent-contracts:rent-annex.device.initMonoCounter'
                                            ),"disabled":!_vm.getRentDeviceModal.isMono,"readonly":_vm.checkIsReadOnly()},on:{"keypress":_vm.integralNumbers},model:{value:(
                                            _vm.getRentDeviceModal.initMonoCounter
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentDeviceModal, "initMonoCounter", $$v)},expression:"\n                                            getRentDeviceModal.initMonoCounter\n                                        "}})],1)],1),_c('v-row',{staticClass:"background--total mb-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text--settlement"},[_vm._v(_vm._s(_vm.$t( 'rent-contracts:rent-annex.device.backgroundColor' )))])]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-checkbox',{staticClass:"mx-2",attrs:{"readonly":_vm.checkIsReadOnly(),"label":_vm.$t(
                                                'rent-contracts:rent-annex.device.calculated'
                                            )},on:{"click":function($event){return _vm.clearFields('isColor')}},model:{value:(_vm.getRentDeviceModal.isColor),callback:function ($$v) {_vm.$set(_vm.getRentDeviceModal, "isColor", $$v)},expression:"getRentDeviceModal.isColor"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('money-field',{staticClass:"margin--input",attrs:{"id":"money","disabled":!_vm.getRentDeviceModal.isColor,"readonly":_vm.checkIsReadOnly(),"precision":3,"label":`${_vm.$t(
                                            'rent-contracts:rent-annex.device.colorPrice'
                                        )}`},model:{value:(
                                            _vm.getRentDeviceModal.colorPrice
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentDeviceModal, "colorPrice", $$v)},expression:"\n                                            getRentDeviceModal.colorPrice\n                                        "}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":_vm.$t(
                                                'rent-contracts:rent-annex.device.colorBundle'
                                            ),"readonly":_vm.checkIsReadOnly(),"disabled":!_vm.getRentDeviceModal.isColor},on:{"keypress":_vm.integralNumbers},model:{value:(
                                            _vm.getRentDeviceModal.colorBundle
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentDeviceModal, "colorBundle", $$v)},expression:"\n                                            getRentDeviceModal.colorBundle\n                                        "}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('money-field',{staticClass:"margin--input",attrs:{"id":"money","disabled":!_vm.getRentDeviceModal.isColor,"readonly":_vm.checkIsReadOnly(),"label":`${_vm.$t(
                                            'rent-contracts:rent-annex.device.colorBundleCost'
                                        )}`,"errorMessage":_vm.getRentDeviceErrors.stepOne
                                                .fields.colorBundleCost},model:{value:(
                                            _vm.getRentDeviceModal.colorBundleCost
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentDeviceModal, "colorBundleCost", $$v)},expression:"\n                                            getRentDeviceModal.colorBundleCost\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t(
                                                'rent-contracts:rent-annex.device.initColorCounter'
                                            ),"disabled":!_vm.getRentDeviceModal.isColor,"readonly":_vm.checkIsReadOnly()},on:{"keypress":_vm.integralNumbers},model:{value:(
                                            _vm.getRentDeviceModal.initColorCounter
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentDeviceModal, "initColorCounter", $$v)},expression:"\n                                            getRentDeviceModal.initColorCounter\n                                        "}})],1)],1),_c('v-row',{staticClass:"background--total background--grey"},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text--settlement"},[_vm._v(_vm._s(_vm.$t( 'rent-contracts:rent-annex.device.scans' )))])]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-checkbox',{staticClass:"mx-2",attrs:{"readonly":_vm.checkIsReadOnly(),"label":_vm.$t(
                                                'rent-contracts:rent-annex.device.calculated'
                                            )},on:{"click":function($event){return _vm.clearFields('isScaner')}},model:{value:(
                                            _vm.getRentDeviceModal.isScaner
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentDeviceModal, "isScaner", $$v)},expression:"\n                                            getRentDeviceModal.isScaner\n                                        "}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('money-field',{staticClass:"margin--input",attrs:{"id":"moneyScan","disabled":!_vm.getRentDeviceModal.isScaner,"readonly":_vm.checkIsReadOnly(),"precision":3,"label":`${_vm.$t(
                                            'rent-contracts:rent-annex.device.scanPrice'
                                        )}`},model:{value:(
                                            _vm.getRentDeviceModal.scanPrice
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentDeviceModal, "scanPrice", $$v)},expression:"\n                                            getRentDeviceModal.scanPrice\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t(
                                                'rent-contracts:rent-annex.device.initScanCounter'
                                            ),"disabled":!_vm.getRentDeviceModal.isScaner,"readonly":_vm.checkIsReadOnly()},on:{"keypress":_vm.integralNumbers},model:{value:(
                                            _vm.getRentDeviceModal.initScanCounter
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentDeviceModal, "initScanCounter", $$v)},expression:"\n                                            getRentDeviceModal.initScanCounter\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-combobox',{attrs:{"label":_vm.$t(
                                                'rent-contracts:rent-annex.device.subRent'
                                            ),"readonly":_vm.checkIsReadOnly(),"items":[
                                            _vm.getRentDeviceModal.subRent,
                                        ],"item-text":"number","item-value":"_id","return-object":"","append-icon":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [(
                                                    _vm.getRentDeviceModal.subRent &&
                                                    _vm.getRentDeviceModal
                                                        .subRent._id
                                                )?_c('v-btn',{attrs:{"icon":"","small":"","router":"","to":`/contracts/${_vm.getRentDeviceModal.subRent._id}`}},[_c('v-icon',{attrs:{"color":"secondary","size":"18"}},[_vm._v("mdi-arrow-right-thick")])],1):_vm._e()]},proxy:true}]),model:{value:(_vm.getRentDeviceModal.subRent),callback:function ($$v) {_vm.$set(_vm.getRentDeviceModal, "subRent", $$v)},expression:"getRentDeviceModal.subRent"}})],1)],1)],1),_c('v-stepper-content',{staticClass:"pb-0 pt-4",attrs:{"step":"2"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('global:additionalInfo')}})],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"label":_vm.$t(
                                                'rent-contracts:rent-annex.device.notes'
                                            ),"no-resize":"","auto-grow":"","rows":"1"},model:{value:(_vm.getRentDeviceModal.notes),callback:function ($$v) {_vm.$set(_vm.getRentDeviceModal, "notes", $$v)},expression:"getRentDeviceModal.notes"}})],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }