<template>
    <v-container id="InnerTableContainer" class="py-0">
        <v-row>
            <v-col cols="12" md="12" class="py-0">
                <Table :title="getRentSettlementInstanceTable.title" :items="getRentSettlementInstanceTable.items"
                    :headers="headers" :length="getRentSettlementInstanceTable.pages"
                    :page="getRentSettlementInstanceTable.page" isSearchDisabled @search="setSearch"
                    @search-fields="setSearchFields" @change-page="setPage" @sort="setSort" :defaultFields="
                        getRentSettlementInstanceSearchFields.searchFields
                    " id="InnerTable" height="100%">
                    <template v-slot:columns="{ isExpanded, expand }">
                        <Columns :headers="headers" :items="getRentSettlementInstanceTable.items"
                            @open-settlement-modal="openSettlementModal" @set-id="settlementID"
                            @open-confirm-modal="openConfirmModal" :isExpanded="isExpanded" :expand="expand"  />
                    </template>
                </Table>
                <Modal :title="
                    $t('rent-contracts:rent-settlements.editSettlements')
                " :open="open" :height="'640'" v-on:close="closeModal" >
                    <AEContent slot="AEContent" :lastSettlementInfo="getRentSettlementInstanceTable.items[0]" edit />
                    <Buttons :action="'edit'" :id="settelmentId" slot="buttons" @closeModal="closeModal" />
                </Modal>
                <ConfirmModal :openConfirm="openConfirm" @close-confirm="closeConfirmModal">
                    <div slot="main">
                        <ConfirmContent />
                    </div>
                    <div slot="buttons">
                        <div class="d-flex justify-center">
                            <!-- @click="closeConfirmModal()" -->
                            <v-btn color="rgb(117, 117, 117)" width="110" height="40" class="white--text mx-2">{{
                                    $t('global:cancel')
                            }}</v-btn>
                            <!-- @click="deleteItem()" -->
                            <v-btn width="110" height="40" color="#445d75" class="white--text mx-2">{{
                                    $t('global:remove')
                            }}</v-btn>
                        </div>
                    </div>
                </ConfirmModal>

                <!-- <div class="buttons--raport d-flex buttons--action--start">

                    <RaportButtons @raport-function="showUnsettled()" :buttonType="'todaySettlements'" />


                </div> -->
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ConfirmContent from '../../ConfirmModal/ConfirmContent.vue'
import Columns from './../Rent-settlements/Table/Columns.vue'
import AEContent from './../Rent-settlements/Modal/Content.vue'
import Buttons from './../Rent-settlements/Modal/Buttons.vue'
import RaportButtons from '../../ButtonsRaport/RaportButtons.vue'

export default {
    data() {
        return {
            findLastSettlement: '',
            open: false,
            settelmentId: '',
            openConfirm: false,
            headers: [
                {
                    text: this.$t(
                        'rent-contracts:rent-settlements.contractAnnexNo'
                    ),
                    value: 'contractAnnexNo',
                    width: '10%',
                },
                {
                    text: this.$t('rent-contracts:rent-settlements.startDate'),
                    value: 'startDate',
                    width: '10%',
                },
                {
                    text: this.$t('rent-contracts:rent-settlements.contractNo'),
                    value: 'contractNo',
                    width: '10%',
                },
                {
                    text: this.$t('rent-contracts:rent-settlements.status'),
                    value: 'status.name',
                    width: '10%',
                },
                {
                    text: this.$t(
                        'rent-contracts:rent-settlements.monthlyCosts'
                    ),
                    value: 'monthlyCosts',
                    width: '10%',
                },

                { text: '', value: 'data-table-expand', width: '7%' },
            ],
        }
    },
    components: {
        Columns,
        AEContent,
        Buttons,
        ConfirmContent,
        RaportButtons
    },
    computed: {
        ...mapGetters([
            'getRentSettlementInstanceTable',
            'getRentSettlementInstanceSearchFields',
        ]),
    },
    methods: {
        ...mapActions([
            'fetchRentSettlement',
            'fetchRentSettlementInstanceTable',
            'fetchRentInfoTable'
        ]),
        ...mapMutations([
            'clearRentSettlementErrors',
            'clearRentSettlementModal',
            'setRentSettlementModal',
            'setRentSettlementInstanceTable',
            'setRentSettlementInstanceSearch',
        ]),

        setSearchFields(searchFields) {
            this.setRentSettlementInstanceSearch(searchFields)
        },
        setSearch(search) {
            this.setRentSettlementInstanceTable({ search, page: 1 })
            this.fetchRentSettlementInstanceTable({ id: this.$route.params.id })
        },
        setPage(page) {
            this.setRentSettlementInstanceTable({ page })
            this.fetchRentSettlementInstanceTable({ id: this.$route.params.id })
        },
        setSort(sort) {
            this.setRentSettlementInstanceTable({ sort })
            this.fetchRentSettlementInstanceTable({ id: this.$route.params.id })
        },
        clearTable() {
            this.setRentSettlementInstanceTable()
            this.fetchRentSettlementInstanceTable({ id: this.$route.params.id })
        },
        openConfirmModal() {
            this.openConfirm = true
        },
        closeConfirmModal() {
            this.openConfirm = false
        },
        settlementID(item) {
            this.settelmentId = item._id
        },
        async openSettlementModal(id) {

            this.clearRentSettlementErrors()
            await this.fetchRentSettlement({ id: id })
            this.clearRentSettlementModal()
            this.setRentSettlementModal()
            this.open = true
        },
        closeModal() {
            this.open = false
            this.openEdit = false
            this.openSettlement = false
        },
        showUnsettled() {
            this.$router.push({ name: 'rent-info', params: { unsettled: true } });
            // this.fetchRentInfoTable({ unsettled: true})
        },
    },
}
</script>
<style lang="scss">
#InnerTable {
    padding: 0;

    &>.row {
        &>div {
            box-shadow: 0 0 0 transparent !important;
            height: auto;
        }
    }
}

#InnerTableContainer {
    .buttons--action {
        bottom: -50px !important;
        right: -2px !important;
    }

    .buttons--action--start {
        bottom: -30px !important;
        left: -2px !important;
    }
}
</style>
