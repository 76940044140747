import Vue from 'vue'
import Vuex from 'vuex'
import store from '../index'
Vue.use(Vuex)

const state = {
    navigation: {
        mini: false,
        stepper: 1,
    },
    snackbars: {
        snackbar: false,
        type: '',
        text: '',
    },
    logoutModal: {
        open: false,
    },
    url: '',
    search: {
        text: '',
    },
}
const getters = {
    getNav: state => state.navigation,
    getSnackbars: state => state.snackbars,
    getUrl: state => state.url,
    getSearch: state => state.search,
    getLogoutModal: state => state.logoutModal,
}
const mutations = {
    setSnackbars(state, payload) {
        state.snackbars.snackbar = true
        state.snackbars.type = payload.type
        state.snackbars.text = payload.text
    },
    closeSnackbars(state) {
        state.snackbars.snackbar = false
    },
    assignUrl(state, payload) {
        state.url = payload
    },
    clearSearchText(state) {
        state.search.text = ''
    },
}
const actions = {}

export default {
    state,
    getters,
    mutations,
    actions,
}
