import Rest from './../../../plugins/Rest'
import clone from 'lodash.clone'
import i18n from './../../../plugins/i18next'
import validation from './../../../plugins/validation'
import moment from 'moment'
import clearSearch from './../../../plugins/clearSearch'
const state = {
    modal: {
        fields: {
            model: '',
            modelSeries: '',
            manufacturer: '',
            manufacturerIndex: '',
            deviceType: '',
            servicePrice: '',
            competenciesLevel: '',
            options: '',
            description: '',
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {
                    model: '',
                    manufacturer: '',
                    deviceType: '',
                    servicePrice: '',
                },
            },
        },
        step: 1,
    },
    request: {
        populate: [
            {
                localField: 'servicePrice',
                foreignField: '_id',
                from: 'service-prices',
                as: 'servicePrice',
            },
        ],
        searchFields: ['model'],
        defaultSearchFields: ['model'],
        select:
            '_id model manufacturer deviceType servicePrice status folders options isDeleted createdAt',
        where: {
            isDeleted: false,
        },
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: '-createdAt',
        loading: false,
    },
    input: {
        items: [],
        search: '',
        limit: 25,
        sort: 'name',
        defaultSort: 'name',
    },
    statuses: [],
    details: {},
    registry: {},
    dictFields: {
        modelSeries: '',
        manufacturer: '',
        deviceType: '',
        options: '',
    },
    servicePrices: [],
}

const getters = {
    getModelModal: state => state.modal.fields,
    getModelError: state => state.modal.errors,
    getModelsInput: state => state.input,
    getModelStep: state => state.modal,
    getModelsTable: state => state.table,
    getModelStatuses: state => state.statuses,
    getModelsSearchFields: state => state.request,
    getModelDetails: state => state.details,
    getModelsRegistry: state => state.registry,
    getServicePricesItems: state => state.servicePrices,
}

const actions = {
    async fetchModelsTable({ commit }) {
        state.table.loading = true
        try {
            let table = new Rest('GET', 'models')
                .select(state.request.select)
                .limit(state.table.limit)
                .page(state.table.page)
                .where(state.request.where)
                .populate(state.request.populate)
                .search(state.request.searchFields, state.table.search)
                .sort(state.table.sort || state.table.defaultSort)
                .send()

            let registryReq = new Rest('GET', 'registries')
                .select('currentMenager name')
                .where({ url: 'models' })
                .populate([
                    {
                        path: 'currentMenager',
                        select: 'name lastname mail status phone picture',
                    },
                ])
                .send()
            let { 0: response, 1: registry } = await Promise.all([
                table,
                registryReq,
            ])
            commit('setModelsTable', response.data)
            commit('setModelsRegistry', registry.data.items)
        } catch (err) {
            console.error(err)
        }
        state.table.loading = false
    },

    async fetchModelsInputs({ commit, dispatch }) {
        try {
            let response = await new Rest('GET', 'models')
                .select(state.request.select)
                .limit(state.input.limit)
                .search(state.request.searchFields, state.input.search)
                .where(state.request.where)
                .populate(state.request.populate)
                .sort(state.input.sort || state.input.defaultSort)
                .send()
            commit('setModelsInput', response.data)
        } catch (err) {
            console.error(err)
        }
    },
    // fetch SERVICE PIRCES
    async fetchServicePricesItems({ commit }, payload) {
        try {
            let response = await new Rest('GET', 'service-prices')
                .select(payload.select)
                .limit(payload.limit)
                .page(payload.page)
                .search(payload.searchFields, payload.search)
                .where(payload.where)
                .populate(payload.populate)
                .sort(payload.sort)
                .send()
            commit('setServicePrices', response.data.items)
            return response.data.items
        } catch (err) {
            console.error(err)
        }
    },

    async createModel({ commit, dispatch }, payload) {
        try {
            await new Rest('POST', 'models').setBody(state.modal.fields).send()
            commit('clearModelsModal')
            commit('clearModelErrors')
            dispatch('fetchModelsTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            //HANDLE ERRORS
            if (err) {
                commit('setSnackbars', { type: 'error' })
                validation.setErrors(state.modal.errors, err)
            }
        }
    },

    async fetchModel({ state, commit }, payload) {
        try {
            let models = await new Rest('GET', `models/${payload.id}`)
                .populate([
                    {
                        path: 'createdBy',
                        select: 'name lastname mail status picture',
                    },
                    {
                        path: 'servicePrice',
                        select: '',
                    },
                ])
                .select('-folders')
                .send()

            let statusesReq = new Rest('GET', `registries/models/statuses`)
                .select('name _id isDeleted color state')
                .where({ isDeleted: false })
                .send()

            let registryReq = new Rest('GET', 'registries')
                .select('currentMenager name')
                .where({ url: 'models' })
                .populate([
                    {
                        path: 'currentMenager',
                        select: 'name lastname mail status phone picture',
                    },
                ])
                .send()
            let { 0: response, 1: statuses, 2: registry } = await Promise.all([
                models,
                statusesReq,
                registryReq,
            ])

            commit('setModelDetails', response.data)
            commit('setModelStatuses', statuses.data.items)
            commit('setModelsRegistry', registry.data.items)

            if (payload.next) payload.next()
        } catch (err) {
            console.error(err)
        }
    },
    async updateModel({ commit, dispatch }, payload) {
        try {
            let response = await new Rest('PUT', `models/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            dispatch('fetchModel', { id: payload.id })
            commit('clearModelsModal')
            commit('clearModelErrors')
            return true
        } catch (err) {
            //HANDLE ERRORS
            validation.setErrors(state.modal.errors, err)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async updateModelStatus({ commit, dispatch }, payload) {
        commit('setModelModal')
        commit('setModelStatus', payload.status)
        try {
            let response = await new Rest('PUT', `models/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            commit('setSnackbars', { type: 'success' })
        } catch (err) {
            console.error(err)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async deleteModel({ commit, dispatch }, payload) {
        try {
            await new Rest('DELETE', `models/${payload.id}`).send()
            dispatch('fetchModelsTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            console.error(err)
        }
    },
}

const mutations = {
    setModelsTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
    setModelsInput(state, payload) {
        state.input.items = payload.items || state.input.items
        if (payload.search != undefined) state.input.search = payload.search
        state.input.limit = payload.limit || state.input.limit
        if (payload.sort != undefined) state.input.sort = payload.sort
    },
    clearModelsModal(state, payload) {
        state.modal.step = 1
        state.modal.fields = {
            model: '',
            typeOfModel: '',
            registrationNumber: '',
            dateOfFirstRegistration: '',
            registrationIDNumber: '',
            insuranceUpTo: '',
            dateOfTheNextInspection: '',
            vin: '',
            yearOfProduction: '',
            tireSize: '',
            warrantyUntil: '',
            property: '',
            driver: '',
            description: '',
        }
    },
    setModelsSearch(state, payload) {
        state.request.searchFields = payload
    },
    setModelDetails(state, payload) {
        state.details = payload
    },
    setModelStatus(state, payload) {
        state.modal.fields.status = payload
    },
    setModelStatuses(state, payload) {
        state.statuses = payload
    },
    setModelModal(state, payload) {
        let obj = payload ? payload : clone(state.details)
        state.modal.fields = obj
        state.modal.fields.dateOfFirstRegistration = obj.dateOfFirstRegistration
            ? moment(obj.dateOfFirstRegistration).format('YYYY-MM-DD')
            : ''
        state.modal.fields.insuranceUpTo = obj.insuranceUpTo
            ? moment(obj.insuranceUpTo).format('YYYY-MM-DD')
            : ''
        state.modal.fields.dateOfTheNextInspection = obj.dateOfTheNextInspection
            ? moment(obj.dateOfTheNextInspection).format('YYYY-MM-DD')
            : ''
        state.modal.fields.warrantyUntil = obj.warrantyUntil
            ? moment(obj.warrantyUntil).format('YYYY-MM-DD')
            : ''
    },

    setModelsRegistry(state, payload) {
        state.registry = payload[0]
    },
    clearModelErrors(state) {
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step].fields) {
                state.modal.errors[step].fields[key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
    clearModelsTable(state) {
        clearSearch.clearSearchTable(state)
    },
    setServicePrices(state, payload) {
        state.servicePrices = payload
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
