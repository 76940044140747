<template>
    <div>
        <v-btn
            @click="action == 'create' ? save() : edit()"
            class="buttons buttons--add"
            :disabled="disabled"
            >{{ $t('global:save') }}</v-btn
        >
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        action: {
            type: String,
        },
        alertId: {
            type: String,
        },
    },
    data() {
        return {
            disabled: false,
            id: this.alertId,
        }
    },
    computed: { ...mapGetters(['getProfileDetails']) },
    methods: {
        ...mapActions(['createAlert', 'updateAlert']),

        async save() {
            this.disabled = true
            let success = await this.createAlert({
                id: this.$route.params.id,
                userId: this.getProfileDetails._id,
                url: this.$route.name,
            })
            if (success) this.$emit('closeModal')
            this.disabled = false
        },
        async edit(payload = {}) {
            this.disabled = true
            let success = await this.updateAlert({
                // routeId: this.id || payload.id,
                routeID: this.$route.params.id,
                // userId: this.getProfileDetails._id,
                body: { seen: true, userId: this.getProfileDetails._id },
            })
            if (success) this.$emit('closeModal')
            this.disabled = false
        },
    },
}
</script>
