import Rest from '../../../plugins/Rest'
import i18n from './../../../plugins/i18next'
import test from './../../Admin/registries'


function filterFilesWithStatusesHelper(filesArray) {
    const selectedExcludedStatuses = ['ARCHIVE', 'CONFIDENTIAL', 'DRAFT']
    return filesArray.filter(file => !selectedExcludedStatuses.includes(file.status))
}

const state = {
    modal: {
        fields: {
            folder: '',
            // Zmienna jest zmieniana przez funkcję attachmentStatus() w pliku Attachments.vue
            status: 'DRAFT',
            archiveDate: '',
            description: '',
            comment: '',
            url: '',
            files: [],
        },
        errors: {
            stepOne: {
                validation: true,
            },
            stepTwo: {
                validation: true,
                folder: '',
                name: '',
                url: '',
            },
        },
    },
    storage: {
        attachmentsStatus: 'DRAFT',
        items: [],
        errors: {
            stepOne: {
                validation: true,
                files: '',
            },
        },
    },
    templates: {
        attachmentsStatus: 'DRAFT',
        items: [],
        files: [],
        errors: {
            stepOne: {
                validation: true,
                files: '',
            },
        },
    },
    items: [],
    folderID: '',
    menager: '',
    progress: {
        sent: 0,
        length: 0,
        totalSize: 0,
        sentSize: 0,
    },
}

const getters = {
    getFilesModalFields: state => state.modal.fields,
    getFilesModalErrors: state => state.modal.errors,
    getFilesItems: state => state.items,
    getCurrentFolder: state => state.folderID,
    getStorageAttachmentsItems: state => state.storage,
    getTemplateAttachments: state => state.templates,
    getFilesRegistryMenager: state => state.menager,
    getProgressFiles: state => state.progress,
}

const actions = {
    async createFile({ commit, dispatch }, payload) {

        commit('setFilesModalErrors')
        let done = false
        if (
            state.modal.errors.stepOne.validation &&
            state.modal.errors.stepTwo.validation
        ) {
            state.progress.length = state.modal.fields.files.length
            let promises = []

            if (state.modal.fields.url) {
                state.progress.length++
                let body = { ...state.modal.fields }

                delete body.files
                promises.push(
                    new Rest(
                        'POST',
                        `folders/${state.modal.fields.folder}/files`
                    )
                        .setBody(body)
                        .additional(payload)
                        .send()
                        .then(response => {
                            if (state.modal.fields.folder == state.folderID)
                                commit('pushFileItems', response.data)
                        })
                )
            }
            commit('setFilesFolderID', state.modal.fields.folder)

            state.progress.totalSize = state.modal.fields.files.reduce(
                (accumulator, item) => accumulator + item.size,
                0
            )

            state.modal.fields.files.forEach((file, index) => {
                let formData = new FormData()
                formData.append('status', state.modal.fields.status)
                formData.append('archiveDate', state.modal.fields.archiveDate)
                formData.append('description', state.modal.fields.description)
                formData.append('comment', state.modal.fields.comment)
                formData.append('file', file)
                promises.push(
                    new Rest(
                        'POST',
                        `folders/${state.modal.fields.folder}/files`
                    )
                        .setBody(formData)
                        .additional(payload)
                        .setConfig({
                            onUploadProgress: progressEvent => {
                                state.progress.sentSize += progressEvent.loaded
                            },
                        })
                        .send()
                        .then(response => {
                            state.progress.sent++
                            if (state.modal.fields.folder == state.folderID)
                                commit('pushFileItems', response.data)
                        })
                )
            })
            await dispatch('fetchFiles', {
                routeName: payload.routeName,
                routeId: payload.routeId,
                folderID: state.modal.fields.folder || null,
            })
            await Promise.all(promises).then(() => {
                done = true
            })
            if (done) {
                commit('setSnackbars', { type: 'success' })
                return true
            } else {
                commit('setSnackbars', { type: 'warning' })
            }
        } else {
            commit('setSnackbars', { type: 'error' })
        }
    },
    async fetchFiles({ commit, dispatch }, payload) {
        try {
            if (payload.folderID) {
                await new Rest('GET', '/webdav/refresh').send()
                commit('setFilesFolderID', payload.folderID)
                let response = await new Rest(
                    'GET',
                    `folders/${payload.folderID}/files`
                )
                    .additional(payload)
                    .populate([{ path: 'folders.files.createdBy' }, { path: "folders.files.versions.updatedBy", select: "name lastname" }])
                    .send()
                commit('setFilesItems', response.data)
            } else {
                commit('setFilesItems', [])
            }
        } catch (error) {
            console.error(error)
        }
    },

    async updateFile({ commit, dispatch }, payload) {
        if (state.modal.fields.name) {
            try {
                let response = await new Rest(
                    'PUT',
                    `folders/${state.modal.fields.folder}/files/${state.modal.fields._id}`
                )
                    .setBody(state.modal.fields)
                    .additional(payload)
                    .send()
                commit('setSnackbars', { type: 'success' })
                commit('setFileItem', response.data)
                await dispatch('fetchFiles', {
                    routeName: payload.routeName,
                    routeId: payload.routeId,
                    folderID: state.modal.fields.folder || null,
                })
                return true
            } catch (error) {
                commit('setSnackbars', { type: 'error' })
                console.error(error)
            }
        } else {
            commit('setSnackbars', { type: 'error' })
            state.modal.errors.stepTwo.validation = false
            state.modal.errors.stepTwo.name = `${i18n.t('global:errorFields')}!`
        }
    },

    async removeFile({ commit }, payload) {
        try {
            let response = await new Rest(
                'DELETE',
                `folders/${state.folderID}/files/${payload.id}`
            )
                .additional(payload)
                .send()
            commit('setSnackbars', { type: 'success' })
            commit('removeFileItem', response.data)
            return true
        } catch (error) {
            commit('setSnackbars', { type: 'error' })
            console.error(error)
        }
    },

    async moveToFolder({ commit }, payload) {
        try {
            let response = await new Rest(
                'PUT',
                `folders/${state.folderID}/files/${payload.fileID}/moveTo`
            )
                .setBody({ moveTo: payload.folderID })
                .additional(payload.additional)
                .send()

            commit('setSnackbars', { type: 'success' })

            commit('removeFileItem', response.data)
        } catch (error) {
            commit('setSnackbars', { type: 'error' })
            console.error(error)
        }
    },

    async copyToFolder({ commit }, payload) {
        try {
            await new Rest(
                'PUT',
                `folders/${state.folderID}/files/${payload.fileID}/copyTo`
            )
                .setBody({ copyTo: payload.folderID })
                .additional(payload.additional)
                .send()
            commit('setSnackbars', { type: 'success' })
        } catch (error) {
            commit('setSnackbars', { type: 'error' })
            console.error(error)
        }
    },

    async copyFromStorage({ commit, dispatch }, payload) {
        try {
            if (state.storage.items.length > 0) {
                let promises = []

                state.storage.items.forEach(file => {
                    file.status = state.storage.attachmentsStatus
                    promises.push(
                        new Rest(
                            'PUT',
                            `folders/${state.folderID}/files/${file._id}/copyFromStorage`
                        )
                            .setBody({ file })
                            .additional(payload.additional)
                            .send()
                            .then(response =>
                                commit('pushFileItems', response.data)
                            )
                    )
                })
                await Promise.all(promises)

                return true
            } else {
                state.storage.errors.stepOne.validation = false
                state.storage.errors.stepOne.files = `${i18n.t(
                    'global:errorFields'
                )}!`
                commit('setSnackbars', { type: 'error' })
            }
        } catch (error) {
            commit('setSnackbars', { type: 'error' })
            console.error(error)
        }
    },



    async fetchTemplates({ commit, dispatch }, payload) {
        try {
            let response = await new Rest('GET', `/templates/${payload}`).send()

            let folders = response.data.items;

            for (let folder of folders) {

                if (folder?.files?.length > 0) {
                    const folderFiles = folder.files;
                    folder.files = filterFilesWithStatusesHelper(folderFiles)
                }
            }

            commit('setTemplates', response.data.items)
        } catch (error) {
            console.error(error)
        }
    },

    async copyFromTemplates({ commit }, payload) {
        try {
            if (state.templates.files.length > 0) {
                let promises = []
                state.templates.files.forEach(file => {
                    file.status = state.storage.attachmentsStatus
                    promises.push(
                        new Rest(
                            'PUT',
                            `folders/${state.folderID}/files/${file._id}/copyFromTemplates`
                        )
                            .setBody({ file })
                            .additional(payload.additional)
                            .send()
                            .then(response =>
                                commit('pushFileItems', response.data)
                            )
                    )
                })

                await Promise.all(promises)

                return true
            }
        } catch (error) {
            commit('setSnackbars', { type: 'error' })
            console.error(error)
        }
    },

    async fetchFilesRegistryMenager({ commit }, payload) {
        try {
            let response = await new Rest('GET', 'registries')
                .where({ url: payload.routeName })
                .select('currentMenager')
                .send()

            commit('setFilesRegistryMenager', response.data.items[0])
        } catch (error) {
            console.error(error)
        }
    },
}

const mutations = {

    setFilesModalErrors(state) {
        if (
            state.modal.fields.files.length < 1 &&
            state.modal.fields.url == ''
        ) {
            state.modal.errors.stepOne.validation = false
            state.modal.errors.stepTwo.validation = false
            state.modal.errors.stepTwo.url = `${i18n.t(
                'attachments:fileOrUrlIsRequired'
            )}!`
        } else if (state.modal.fields.folder == '') {
            state.modal.errors.stepTwo.validation = false
            state.modal.errors.stepTwo.folder = `${i18n.t(
                'global:errorFields'
            )}!`
        } else {
            state.modal.errors.stepOne.validation = true
            state.modal.errors.stepTwo.validation = true
            state.modal.errors.stepTwo.url = ''
        }
    },
    setFilesItems(state, payload) {
        state.items = payload
    },
    setFilesFolderID(state, payload) {
        state.folderID = payload
    },
    pushFileItems(state, payload) {
        state.items = [payload, ...state.items]
    },
    clearFilesModal(state) {
        state.modal.fields = {
            folder: '',
            status: 'DRAFT',
            archiveDate: '',
            description: '',
            comment: '',
            files: [],
            url: '',
        }
        state.modal.errors = {
            stepOne: {
                validation: true,
            },
            stepTwo: {
                validation: true,
                folder: '',
                name: '',
                url: '',
            },
        }

        state.progress.length = 0
        state.progress.sent = 0
        state.progress.sentSize = 0
        state.progress.sent = 0
        state.progress.totalSize = 0
    },
    setFileModal(state, payload) {

        state.modal.fields.status = payload.status
        state.modal.fields.name = payload.name
        state.modal.fields.archiveDate = payload.archiveDate
            ? new Date(payload.archiveDate).toISOString().slice(0, 10)
            : ''
        state.modal.fields.comment = payload.comment
        state.modal.fields.description = payload.description
        state.modal.fields._id = payload._id
        state.modal.fields.folder = state.folderID
        if (payload.url) state.modal.fields.url = payload.url
        state.modal.fields.versions = payload.versions
        state.modal.fields.fakeName = payload.fakeName
        state.modal.fields.createdBy = payload.createdBy
        state.modal.fields.createdAt = payload.createdAt
    },
    setFileItem(state, payload) {
        state.items = state.items.map(item =>
            item._id == payload._id ? payload : item
        )
    },
    removeFileItem(state, payload) {
        state.items = state.items.filter(item => item._id != payload._id)
    },

    setCurrentFolder(state) {
        state.modal.fields.folder = state.folderID
    },
    setTemplates(state, payload) {
        state.templates.items = payload
    },
    clearTemplates(state) {
        state.templates.items = []
        state.templates.files = []
    },
    removeModalFileItem(state, payload) {
        state.modal.fields.files = state.modal.fields.files.filter(
            item => item != payload
        )
    },
    setFilesRegistryMenager(state, payload) {
        state.menager = payload.currentMenager
    },
    setPastedFile(state, payload) {

        state.modal.fields.files = payload.files;
        state.modal.fields.folder = payload.folder;
        state.modal.fields.status = payload.status;
    }
}

export default { state, getters, mutations, actions }
