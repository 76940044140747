<template>
    <v-container class="py-0">
        <v-row class="flex-column">
            <v-data-table
                :headers="headers"
                :items="items"
                fixed-header
                :search="searchDetails"
                class="details__table pa-0 px-4"
                :items-per-page="11"
                height="calc(100vh - 360px)"
                hide-default-footer
                :options.sync="options"
            >
                <template v-slot:body>
                    <!-- @slot Use this slot to place the table records -->
                    <slot name="columns"></slot>
                </template>
            </v-data-table>
            <div class="d-flex justify-end">
                <v-col cols="12" lg="3" xl="2" md="4" sm="5">
                    <v-text-field
                        data-cy="search"
                        :label="$t('global:search')"
                        v-model="searchDetails"
                        single-line
                        ref="search"
                        hide-details
                        @input="$emit('search', searchDetails)"
                    >
                        <template v-slot:prepend>
                            <v-icon @click="setFocus()" color="secondary"
                                >mdi-magnify</v-icon
                            >
                        </template></v-text-field
                    >
                </v-col>
            </div>
            <v-pagination
                circle
                :length="length"
                :total-visible="5"
                v-model="pg"
            ></v-pagination>
        </v-row>
    </v-container>
</template>

<script>
/**
 * Details Table
 * @displayName Details Table (Alerts, Tasks, Note, Logs)
 */
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        /**
         * Items in Table
         */
        items: {
            type: Array,
            required: false,
        },
        /**
         * Headers in Table
         */
        headers: {
            type: Array,
            required: true,
        },
        /**
         * Table Length
         */
        length: {
            type: Number,
            required: true,
        },
        page: { type: Number, required: true },
    },
    data: () => ({
        searchDetails: '',
        options: {},
    }),
    computed: {
        pg: {
            get: function () {
                return this.page
            },
            set: function (val) {
                this.$emit('change-page', val)
            },
        },
    },

    methods: {
        setFocus() {
            this.$refs.search.focus()
        },
    },

    watch: {
        options: {
            handler() {
                let sortBy = this.options.sortBy[0]
                let sortDesc = this.options.sortDesc[0]
                let sort = sortBy
                if (sortDesc) sort = `-${sortBy}`
                if (sort == undefined) sort = ''
                this.$emit('sort', sort)
            },
            deep: true,
        },
    },
}
</script>
