import Rest from './../../../plugins/Rest'
import validation from './../../../plugins/validation'
import { clone } from 'lodash'
import store from '../../../store/index'
import clearSearch from './../../../plugins/clearSearch'
const state = {
    modal: {
        fields: {
            notes: '',
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {},
            },
        },
        step: 1,
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: '-createdAt',
        loading: false,
    },
    request: {
        unwindExeptions: ['responsible'],
        populate: [
            {
                localField: 'responsible',
                foreignField: '_id',
                from: 'users',
                as: 'responsible',
            },
        ],
        searchFields: ['title'],
        defaultSearchFields: ['title'],
        select:
            '_id title createdAt updatedAt status notes index contractor folders content contact responsible isDeleted',
        where: {
            isDeleted: false,
        },
    },
    dictFields: {},
    statuses: [],
    details: {},
    registry: {},
}

const getters = {
    getWebSiteContactModal: state => state.modal.fields,
    getWebSiteContactRegistry: state => state.registry,
    getWebSiteContactTable: state => state.table,
    getWebSiteContactSearchFields: state => state.request,
    getWebSiteContactErrors: state => state.modal.errors,
    getWebSiteContactStep: state => state.modal,
    getWebSiteContactStatuses: state => state.statuses,
    getWebSiteContactDetails: state => state.details,
}

const actions = {
    async fetchWebSiteContactTable({ commit, dispatch }) {
        state.table.loading = true
        try {
            let table = new Rest('GET', 'webSiteContact')
                .select(state.request.select)
                .limit(state.table.limit)
                .page(state.table.page)
                .search(state.request.searchFields, state.table.search)
                .where(state.request.where)
                .populate(state.request.populate)
                .unwindExeptions(state.request.unwindExeptions)
                .sort(state.table.sort || state.table.defaultSort)
                .send()

            let response = await table
            console.log(response.data)
            dispatch('fetchWebSiteContactRegistry')
            commit('setWebSiteContactTable', response.data)
        } catch (error) {
            console.error(error)
        }
        state.table.loading = false
    },

    async updateWebSiteContact({ commit, dispatch }, payload) {
        try {
            await new Rest('PUT', `webSiteContact/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            dispatch('fetchWebSiteContact', { id: payload.id })
            commit('clearWebSiteContactModal')
            commit('clearWebSiteContactErrors')
            return true
        } catch (err) {
            //HANDLE ERRORS
            validation.setErrors(state.modal.errors, err)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async fetchWebSiteContact({ state, commit, dispatch }, payload) {
        try {
            let priceList = await new Rest(
                'GET',
                `webSiteContact/${payload.id}`
            )
                .populate({
                    path: 'responsible',
                    match: { isDeleted: { $eq: false } },
                    select: 'name lastname mail status isDeleted',
                })
                .select(
                    '_id title createdAt updatedAt status notes index contractor folders content contact responsible'
                )
                .send()

            let statusesReq = new Rest(
                'GET',
                `registries/webSiteContact/statuses`
            )
                .select('name _id isDeleted color state')
                .where({ isDeleted: false })
                .send()

            let { 0: response, 1: statuses } = await Promise.all([
                priceList,
                statusesReq,
            ])
            dispatch('fetchWebSiteContactRegistry')
            commit('setWebSiteContactDetails', response.data)
            commit('setWebSiteContactStatuses', statuses.data.items)

            if (payload.next) payload.next()
            return response.data
        } catch (err) {
            console.error(err)
        }
    },

    async fetchWebSiteContactRegistry({ commit, dispatch }, payload) {
        let registry = await new Rest('GET', 'registries')
            .select('currentMenager name')
            .where({ url: 'webSiteContact' })
            .populate([
                {
                    path: 'currentMenager',
                    select: 'name lastname mail status phone picture',
                },
            ])
            .send()

        commit('setWebSiteContactRegistry', registry.data.items)
    },

    async updateWebSiteContactStatus({ commit, dispatch }, payload) {
        try {
            commit('setWebSiteContactModal', payload.webSiteContact)
            commit('setWebSiteContactStatus', payload.status)

            const response = await new Rest(
                'PUT',
                `webSiteContact/${payload.id}`
            )
                .setBody(payload.webSiteContact)
                .send()

            if (response.status === 200) {
                commit('setSnackbars', {
                    type: 'success',
                    text: `Zmieniono status na ${response.data.webSiteContact.status.name}`,
                })
                commit('setWebSiteContactDetails', response.data.webSiteContact)
            } else {
                throw new Error('Błąd zmiany statusu')
            }
        } catch (err) {
            console.error(err)
            commit('setSnackbars', { type: 'error', text: err })
        }
    },
    async deleteWebSiteContact({ commit, dispatch }, payload) {
        try {
            await new Rest('DELETE', `webSiteContact/${payload.id}`).send()
            dispatch('fetchWebSiteContactTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            console.error(err)
        }
    },
}

const mutations = {
    setWebSiteContactTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
    setWebSiteContactDetails(state, payload) {
        state.details = payload
    },
    setWebSiteContactStatuses(state, payload) {
        state.statuses = payload
    },
    setWebSiteContactModal(state, payload) {
        let obj = payload ? payload : clone(state.details)
        state.modal.fields = obj
        store.commit('setRepresentativesInput', {
            items: obj?.contractor?.representatives,
        })
        state.modal.fields.representative = obj?.contractor?.representatives?.find(
            e => e._id === obj.representative
        )
    },
    setWebSiteContactStatus(state, payload) {
        state.modal.fields.status = payload
    },
    setWebSiteContactRegistry(state, payload) {
        state.registry = payload[0]
    },
    clearWebSiteContactModal() {
        state.modal.step = 1
        state.modal.fields = {
            title: '',
            contractor: '',
            representative: '',
            description: '',
        }
    },
    clearWebSiteContactErrors(state) {
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step].fields) {
                state.modal.errors[step].fields[key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
    clearWebSiteContactTable(state) {
        clearSearch.clearSearchTable(state)
    },
    setWebSiteContactSearch(state, payload) {
        state.request.searchFields = payload
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
