<template>
    <v-container class="pt-0 pb-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="step" class="elevation-0 pb-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                editable
                                step="1"
                                :rules="[
                                    () =>
                                        getFilesModalErrors.stepTwo.validation,
                                ]"
                                >{{ $t('global:information') }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col cols="12" class="pt-0">
                                        <v-select
                                            data-cy="folderName"
                                            :label="$t('global:folderName')"
                                            v-model="getFilesModalFields.folder"
                                            :items="getFoldersItems"
                                            disabled
                                            item-value="_id"
                                            item-text="name"
                                            :error-messages="
                                                getFilesModalErrors.stepTwo
                                                    .folder
                                            "
                                        ></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-0">
                                        <v-text-field
                                            data-cy="fileName"
                                            :label="$t('global:fileName')"
                                            v-model="getFilesModalFields.name"
                                            :error-messages="
                                                getFilesModalErrors.stepTwo.name
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            data-cy="url"
                                            :label="$t('global:url')"
                                            v-model="getFilesModalFields.url"
                                            v-if="getFilesModalFields.url"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-select
                                            data-cy="status"
                                            :label="$t('global:status')"
                                            v-model="getFilesModalFields.status"
                                            :items="statuses"
                                            item-text="name"
                                            item-value="value"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-menu
                                            v-model="datepicker"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            nudge-bottom="45"
                                            min-width="290px"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    v-model="
                                                        getFilesModalFields.archiveDate
                                                    "
                                                    :label="
                                                        $t(
                                                            'attachments:archiveDate'
                                                        )
                                                    "
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="
                                                    getFilesModalFields.archiveDate
                                                "
                                                no-title
                                                @input="datepicker = false"
                                                first-day-of-week="1"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea
                                            data-cy="Description"
                                            :label="
                                                $t(
                                                    'attachments:attachmentDescription'
                                                )
                                            "
                                            v-model="
                                                getFilesModalFields.description
                                            "
                                            no-resize
                                            rows="1"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea
                                            data-cy="comment"
                                            :label="$t('attachments:comment')"
                                            v-model="
                                                getFilesModalFields.comment
                                            "
                                            no-resize
                                            rows="1"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <div class="attachments--info">
                                            <span>
                                                {{
                                                    $t('attachments:createdBy')
                                                }}
                                                {{
                                                    `${$get(
                                                        getFilesModalFields,
                                                        'createdBy.name',
                                                        ''
                                                    )} ${$get(
                                                        getFilesModalFields,
                                                        'createdBy.lastname',
                                                        ''
                                                    )}`
                                                }}</span
                                            >
                                            <span class="mx-1">/</span>
                                            <span>
                                                {{
                                                    $t('attachments:createdAt')
                                                }}
                                                {{
                                                    $moment(
                                                        getFilesModalFields.createdAt
                                                    ).format(`DD-MM-YYYY HH:mm`)
                                                }}</span
                                            >
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { GlobalFilesStatus } from './../../../../../../../plugins/constsTypes'
export default {
    data() {
        return {
            step: 1,
            datepicker: false,
            statuses: [
                {
                    name: this.$t('attachments:current'),
                    value: GlobalFilesStatus.current,
                },
                {
                    name: this.$t('attachments:archive'),
                    value: GlobalFilesStatus.archive,
                },
                {
                    name: this.$t('attachments:draft'),
                    value: GlobalFilesStatus.draft,
                },
                {
                    name: this.$t('attachments:confidential'),
                    value: GlobalFilesStatus.confidential,
                },
            ],
        }
    },
    computed: {
        ...mapGetters([
            'getFoldersItems',
            'getFilesModalFields',
            'getFilesModalErrors',
        ]),
    },
}
</script>
<style lang="sass" scoped>
.attachments--info
    color: grey !important
    font-size: 12px !important
</style>
