var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () =>
                                    _vm.getTemplateAttachments.errors.stepOne
                                        .validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('attachments:addFromTemplate')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t(
                                                'attachments:addFromTemplate'
                                            )}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"data-cy":"chooseFolder","items":_vm.getTemplateAttachments.items,"label":`${_vm.$t(
                                            'attachments:chooseFolder'
                                        )}*`,"item-value":"_id","return-object":""},on:{"change":_vm.setFiles},scopedSlots:_vm._u([{key:"selection",fn:function(selection){return [_c('span',[_vm._v(_vm._s(selection.item.name))])]}},{key:"item",fn:function(item){return [_c('DoubleLine',{attrs:{"firtstLine":item.item.name,"secondLine":item.item.description}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"data-cy":"chooseFiles","items":_vm.files,"label":`${_vm.$t(
                                            'attachments:chooseFiles'
                                        )}*`,"item-value":"_id","return-object":"","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(selection){return [_c('span',[_vm._v(_vm._s(selection.item.name))])]}},{key:"item",fn:function(item){return [_c('div',{staticClass:"doubleLine"},[_c('span',{staticClass:"firstLine"},[_vm._v(_vm._s(item.item.name))]),_c('span',{staticClass:"secondLine"},[_vm._v(_vm._s(item.item.description))])])]}}]),model:{value:(
                                            _vm.getTemplateAttachments.files
                                        ),callback:function ($$v) {_vm.$set(_vm.getTemplateAttachments, "files", $$v)},expression:"\n                                            getTemplateAttachments.files\n                                        "}})],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }