<template>
    <tbody>
        <template v-for="(item, index) in items">
            <tr
                :class="`${isStatusClosed(item) ? 'disabled' : ''}`"
                :key="'a' + index"
                @click="closeRows(item)"
            >
                <!-- === === CONTRACT_NR === === -->
                <td class="truncate">
                    {{
                        $get(item, 'contract[0].contractNo') ||
                        $get(
                            item,
                            'contract[0].externalContractNo',
                            $t('global:empty')
                        )
                    }}
                </td>
                <!-- === === CONTRACT_NR === === -->
                <td class="truncate">
                    {{ $get(item, 'contractAnnexNo') }}
                </td>
                <!-- === === START_DATE === === -->
                <td class="truncate">
                    <DoubleLine
                        :firtstLine="
                            item.startDate
                                ? $moment(item.startDate).format(`DD/MM/YYYY`)
                                : $t('global:empty')
                        "
                    />
                </td>

                <!-- === === EXPIRATIONDATE === === -->
                <td class="truncate">
                    <DoubleLine
                        :firtstLine="
                            item.expirationDate
                                ? $moment(item.expirationDate).format(
                                      `DD/MM/YYYY`
                                  )
                                : $t('global:empty')
                        "
                    />
                </td>
                <!-- DEVICES COUNT -->
                <td class="truncate">
                    {{ getDevicesInRentAnnex(item) }}
                </td>
                <!-- === === CUSTOMER_CARER === === -->
                <td class="truncate">
                    {{
                        `${$get(
                            item,
                            'customerCarer.name',
                            $t('global:empty')
                        )} ${$get(item, 'customerCarer.lastname', '')}`
                    }}
                </td>
                <!-- === === STATUS === === -->
                <td>
                    <v-chip
                        :color="
                            $get(item, 'status.color.background', '#555555')
                        "
                        :style="`color: ${$get(
                            item,
                            'status.color.font',
                            '#FFFFFF'
                        )}`"
                        dark
                        label
                        small
                        >{{
                            $get(item, 'status.name', $t('users:empty'))
                        }}</v-chip
                    >
                </td>
                <td class="d-flex align-center justify-end">
                    <SettlementButton :item="item" :closeRows="closeRows" />
                    <v-tooltip v-if="item.status.state != 'CLOSED'" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                small
                                icon
                                @click="onAddDevice(item)"
                                :disabled="disableDeviceAdding()"
                            >
                                <v-icon color="secondary"
                                    >mdi-plus-circle-outline
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Dodaj urządzenie do umowy/aneksu</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="item.status.state != 'CLOSED'">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                small
                                icon
                                @click="onEditAnnex(item._id)"
                            >
                                <v-icon color="secondary" small
                                    >mdi-pencil</v-icon
                                >
                            </v-btn>
                        </template>
                        <span>Edytuj umowę/aneks</span>
                    </v-tooltip>

                    <v-menu
                        nudge-left="90"
                        nudge-top="50"
                        bottom
                        v-if="item.status.state != 'CLOSED'"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon small v-bind="attrs" v-on="on">
                                <v-icon color="iconGray" small
                                    >mdi-dots-vertical</v-icon
                                >
                            </v-btn>
                        </template>

                        <v-list dense>
                            <!-- === === CSV === === -->
                            <CSVFileButtons :item="item" />
                        </v-list>
                    </v-menu>

                    <v-btn small icon>
                        <v-icon :id="item._id" @click="onExpand(item)"
                            >$expand</v-icon
                        >
                    </v-btn>
                </td>
            </tr>
            <tr :key="'b' + index">
                <td
                    v-if="isExpanded(item)"
                    :colspan="headers.length"
                    id="CollapsedTable"
                >
                    <v-data-table
                        v-if="
                            getRentDeviceTable.fetched &&
                            getRentDeviceTable.items.length > 0
                        "
                        hide-default-footer
                        :headers="deviceHeaders"
                        :items="getRentDeviceTable.items"
                        :items-per-page="-1"
                    >
                        <template v-slot:body="{ items }">
                            <Columns
                                :lastAnnex="findLastAnnex()"
                                :items="items"
                                :item="item"
                                @settlement-modal="settlementModal"
                                @onOpenEdit="onEditDevice"
                                :loading="loading"
                            />
                        </template>
                    </v-data-table>
                    <v-data-table
                        v-if="!getRentDeviceTable.fetched"
                        hide-default-footer
                        :headers="deviceHeaders"
                        loading
                    >
                    </v-data-table>
                    <v-data-table
                        v-if="
                            getRentDeviceTable.fetched &&
                            getRentDeviceTable.items.length <= 0
                        "
                        hide-default-footer
                        :headers="deviceHeaders"
                        :items="[]"
                        :no-data-text="$t('global:empty')"
                    >
                    </v-data-table>
                </td>
            </tr>
        </template>
        <ConfirmModal
            :openConfirm="openConfirm"
            @close-confirm="closeConfirmModal"
        >
            <div slot="main">
                <ConfirmContent />
            </div>
            <div slot="buttons">
                <div class="d-flex justify-center">
                    <v-btn
                        color="rgb(117, 117, 117)"
                        width="110"
                        height="40"
                        @click="closeConfirmModal()"
                        class="white--text mx-2"
                        >{{ $t('global:cancel') }}</v-btn
                    >
                    <v-btn
                        width="110"
                        height="40"
                        color="#445d75"
                        class="white--text mx-2"
                        @click="deleteItem()"
                        >{{ $t('global:remove') }}</v-btn
                    >
                </div>
            </div>
        </ConfirmModal>
        <Modal
            :title="$t('rent-contracts:rent-annex.addDevice')"
            :open="open"
            height="1100"
            width="1000"
            v-on:close="closeModal"
        >
            <AEContent slot="AEContent" />
            <Buttons
                :target="targetItem"
                :action="GlobalActions.create"
                slot="buttons"
                @closeModal="open = false"
            />
        </Modal>
        <Modal
            :title="$t('rent-contracts:rent-annex.editDevice')"
            :open="openEdit"
            height="1000"
            width="1000"
            v-on:close="closeModal"
        >
            <AEContent slot="AEContent" edit />
            <Buttons
                :target="targetItem"
                :action="GlobalActions.update"
                slot="buttons"
                @closeModal="openEdit = false"
            />
        </Modal>
    </tbody>
</template>
<script>
import ConfirmContent from '../../../ConfirmModal/ConfirmContent.vue'
import Columns from '../../../../Rent-contracts/Tabs/Rent-annex/Tabs/Rent-devices/Table/Columns.vue'
import AEContent from '../../Rent-annex/Tabs/Rent-devices/Modal/Content.vue'
import Buttons from '../../Rent-annex/Tabs/Rent-devices/Modal/Buttons.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { GlobalActions } from '../../../../../../plugins/constsTypes'
import SettlementButton from './Buttons/SettlementButton.vue'
import CSVFileButtons from './Buttons/CSVFileButtons.vue'

export default {
    props: ['items', 'isExpanded', 'expand', 'headers', 'loading'],
    data() {
        return {
            GlobalActions: GlobalActions,
            openConfirm: false,
            removeItemID: '',
            open: false,
            openEdit: false,
            targetItem: '',
            deviceHeaders: [
                {
                    text: this.$t('rent-info:sn'),
                    value: 'device.sn',
                    width: '10%',
                    class: 'CustomHeader',
                },
                {
                    value: 'circles',
                    width: '4%',
                    sortable: false,
                },
                {
                    text: this.$t('rent-info:devices'),
                    value: 'device.model.manufacturer',
                    width: '15%',
                    class: 'CustomHeader',
                },
                {
                    text: this.$t('rent-info:rent'),
                    value: 'rent',
                    width: '8%',
                    class: 'CustomHeader',
                    sortable: false,
                },
                {
                    text: this.$t('rent-info:monoPrintPrice'),
                    value: 'monoPrice',
                    width: '11%',
                    class: 'CustomHeader',
                    sortable: false,
                },
                {
                    text: this.$t('rent-info:monoPackagePrice'),
                    value: 'monoBundleCost',
                    width: '7%',
                    class: 'CustomHeader',
                    sortable: false,
                },
                {
                    text: this.$t('rent-info:colorPrintPrice'),
                    value: 'colorPrice',
                    width: '11%',
                    class: 'CustomHeader',
                    sortable: false,
                },
                {
                    text: this.$t('rent-info:colorPackagePrice'),
                    value: 'colorBundleCost',
                    width: '7%',
                    class: 'CustomHeader',
                    sortable: false,
                },
                {
                    text: this.$t('rent-info:scanPrice'),
                    value: 'scanPrice',
                    width: '10%',
                    class: 'CustomHeader',
                    sortable: false,
                },
                {
                    text: this.$t('rent-info:monitoringCost'),
                    value: 'monitoringCost',
                    width: '9%',
                    class: 'CustomHeader',
                    sortable: true,
                },
                {
                    text: this.$t('rent-info:settlementType'),
                    value: 'settlementType',
                    width: '5%',
                    class: 'CustomHeader',
                    sortable: false,
                },
                {
                    text: '',
                    value: 'arrow',
                    width: '7%',
                    sortable: false,
                },
            ],
        }
    },
    components: {
        ConfirmContent,
        Columns,
        Buttons,
        AEContent,
        SettlementButton,
        CSVFileButtons,
    },
    computed: {
        ...mapGetters([
            'getProfileDetails',
            'getRentDeviceTable',
            'getRentAnnexTable',
            'getLastValueRentInfo',
            'getRentDeviceModal',
        ]),
    },
    methods: {
        ...mapMutations([
            'clearRentDeviceTable',
            'clearRentDeviceModal',
            'clearRentDeviceErrors',
            'setSnackbars',
        ]),
        ...mapActions([
            'fetchDevicesTable',
            'fetchRentDeviceTable',
            'fetchRentSettlementInstanceTable',
            'fetchRentAnnexTable',
        ]),
        findLastAnnex() {
            return this.getRentAnnexTable.items.slice(-1)[0]
        },
        getDevicesInRentAnnex(rentDevices) {
            if (rentDevices?.rentDevicesUnpacked) {
                const flitered = rentDevices.rentDevicesUnpacked.filter(
                    e => e.isDeleted == false
                )

                return flitered.length || 0
            }
            return 0
        },
        checkIsShouldBeDisabled() {
            if (
                this.getRentInfoRegistry.currentMenager.mail ===
                this.getProfileDetails.mail
            ) {
                return false
            } else if (this.isSettlementAll === 'red') {
                return true
            }
        },
        closeModal() {
            this.open = false
            this.openEdit = false
            this.clearRentDeviceErrors()
        },
        onAddDevice(item) {
            this.clearRentDeviceErrors()
            this.clearRentDeviceModal()
            this.targetItem = item._id
            this.fetchDevicesTable({ contractId: item._id })
            this.open = true
        },
        onEditDevice(item) {
            this.targetItem = item
            this.openEdit = true
        },
        onEditAnnex(id) {
            this.$emit('onOpenEdit', id)
        },
        onExpand(item) {
            this.clearRentDeviceTable()
            this.fetchRentDeviceTable({ contractId: item._id })
            this.expand(item, !this.isExpanded(item))
            for (const el of this.items) {
                if (el._id !== item._id) this.expand(el, false)
                const element = document.getElementById(el._id)
                if (this.isExpanded(el)) {
                    element.style.transform = 'rotate(180deg)'
                } else {
                    element.style.transform = 'rotate(0deg)'
                }
            }
        },

        findRepresentative(item) {
            const id = item.representative
            if (id) {
                const reprezentative = item.contractor?.representatives?.find(
                    e => e._id === id
                )
                return `${this.$get(
                    reprezentative,
                    'name',
                    'Brak'
                )} ${this.$get(reprezentative, 'lastname', '')} ${
                    reprezentative && reprezentative.email ? '•' : ''
                } ${this.$get(reprezentative, `email`, '')} ${
                    reprezentative && reprezentative.phoneNumber ? '•' : ''
                } ${this.$get(reprezentative, 'phoneNumber', '')}`
            }
            return ''
        },
        ...mapActions(['deleteRentInfo']),
        openConfirmModal(id) {
            this.removeItemID = id
            this.openConfirm = true
        },
        closeConfirmModal() {
            this.openConfirm = false
        },
        async deleteItem() {
            let result = await this.deleteRentInfo({ id: this.removeItemID })
            if (result)
                if (result) {
                    this.openConfirm = false
                }
        },
        remove() {
            return this.checkPermissions('DELETE')
        },
        closeRows(item) {
            for (const el of this.items) {
                const element = document.getElementById(el._id)
                if (el._id !== item?._id) this.expand(el, false)
                if (this.isExpanded(el)) {
                    element.style.transform = 'rotate(180deg)'
                } else {
                    element.style.transform = 'rotate(0deg)'
                }
            }
        },
        settlementModal(id) {
            this.$emit('open-settlement-modal', id)
        },
        disableDeviceAdding() {
            if (
                this.getLastValueRentInfo.annex?.status?.name === 'Aktywny' ||
                this.getLastValueRentInfo.annex?.status?.name === 'Wygasły'
            ) {
                return true
            }
            return false
        },
    },

    watch: {
        'getRentAnnexTable.page': function () {
            for (const el of this.items) {
                const element = document.getElementById(el._id)
                this.expand(el, false)
                if (this.isExpanded(el)) {
                    element.style.transform = 'rotate(180deg)'
                } else {
                    element.style.transform = 'rotate(0deg)'
                }
            }
        },
    },
}
</script>
<style lang="sass" scoped>
.CustomHeader
    white-space: nowrap !important
    padding-top: 5px
#CollapsedTable
    padding: 10px 10px
    border-bottom: 2px solid #19aa8d
    &:hover
        background: #FFFFFF !important
</style>
