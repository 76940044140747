import Rest from './../../../plugins/Rest'
import validation from './../../../plugins/validation'
import { clone } from 'lodash'
import store from '../../../store/index'
import clearSearch from './../../../plugins/clearSearch'
const state = {
    modal: {
        fields: {
            title: '',
            contractor: '',
            representative: '',
            description: '',
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {
                    title: '',
                    contractor: '',
                },
            },
        },
        step: 1,
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: '-createdAt',
        loading: false,
    },
    request: {
        populate: [
            {
                localField: 'contractor',
                foreignField: '_id',
                from: 'contacts',
                as: 'contractor',
            },
            {
                localField: 'createdBy',
                foreignField: '_id',
                from: 'users',
                as: 'createdBy',
            },
        ],
        searchFields: ['title'],
        defaultSearchFields: ['title'],
        select:
            '_id title representative createdAt updatedAt status description index contractor folders isDeleted createdBy',
        where: {
            isDeleted: false,
        },
    },
    dictFields: {},
    statuses: [],
    details: {},
    registry: {},
}

const getters = {
    getPriceListModal: state => state.modal.fields,
    getPriceListRegistry: state => state.registry,
    getPriceListTable: state => state.table,
    getPriceListSearchFields: state => state.request,
    getPriceListErrors: state => state.modal.errors,
    getPriceListStep: state => state.modal,
    getPriceListStatuses: state => state.statuses,
    getPriceListDetails: state => state.details,
}

const actions = {
    async fetchPriceListTable({ commit, dispatch }) {
        state.table.loading = true
        try {
            let table = new Rest('GET', 'price-list')
                .select(state.request.select)
                .limit(state.table.limit)
                .page(state.table.page)
                .where(state.request.where)
                .populate(state.request.populate)
                .search(state.request.searchFields, state.table.search)
                .sort(state.table.sort || state.table.defaultSort)
                .send()

            let response = await table
            dispatch('fetchPriceListRegistry')
            commit('setPriceListTable', response.data)
        } catch (error) {
            console.error(error)
        }
        state.table.loading = false
    },
    async createPriceList({ commit, dispatch }, payload) {
        try {
            await new Rest('POST', 'price-list')
                .setBody(state.modal.fields)
                .send()
            commit('clearPriceListModal')
            commit('clearPriceListErrors')
            dispatch('fetchPriceListTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            //HANDLE ERRORS
            if (err) {
                commit('setSnackbars', { type: 'error' })
                validation.setErrors(state.modal.errors, err)
            }
        }
    },

    async updatePriceList({ commit, dispatch }, payload) {
        try {
            await new Rest('PUT', `price-list/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            dispatch('fetchPriceList', { id: payload.id })
            commit('clearPriceListModal')
            commit('clearPriceListErrors')
            return true
        } catch (err) {
            //HANDLE ERRORS
            validation.setErrors(state.modal.errors, err)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async fetchPriceList({ state, commit, dispatch }, payload) {
        try {
            let priceList = await new Rest('GET', `price-list/${payload.id}`)
                .populate(['contractor', 'createdBy'])
                .select(
                    '_id title representative createdAt updatedAt status description index contractor'
                )
                .send()

            let statusesReq = new Rest('GET', `registries/price-list/statuses`)
                .select('name _id isDeleted color state')
                .where({ isDeleted: false })
                .send()

            let { 0: response, 1: statuses } = await Promise.all([
                priceList,
                statusesReq,
            ])
            dispatch('fetchPriceListRegistry')
            commit('setPriceListDetails', response.data)
            commit('setPriceListStatuses', statuses.data.items)

            if (payload.next) payload.next()
        } catch (err) {
            console.error(err)
        }
    },

    async fetchPriceListRegistry({ commit, dispatch }, payload) {
        let registry = await new Rest('GET', 'registries')
            .select('currentMenager name')
            .where({ url: 'price-list' })
            .populate([
                {
                    path: 'currentMenager',
                    select: 'name lastname mail status phone picture',
                },
            ])
            .send()

        commit('setPriceListRegistry', registry.data.items)
    },

    async updatePriceListStatus({ commit, dispatch }, payload) {
        commit('setPriceListModal')
        commit('setPriceListStatus', payload.status)
        try {
            await new Rest('PUT', `price-list/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            commit('setSnackbars', { type: 'success' })
        } catch (err) {
            console.error(err)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async deletePriceList({ commit, dispatch }, payload) {
        try {
            await new Rest('DELETE', `price-list/${payload.id}`).send()
            dispatch('fetchPriceListTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            console.error(err)
        }
    },
}

const mutations = {
    setPriceListTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
    setPriceListDetails(state, payload) {
        state.details = payload
    },
    setPriceListStatuses(state, payload) {
        state.statuses = payload
    },
    setPriceListModal(state, payload) {
        let obj = payload ? payload : clone(state.details)
        state.modal.fields = obj
        store.commit('setRepresentativesInput', {
            items: obj?.contractor?.representatives,
        })
        state.modal.fields.representative = obj?.contractor?.representatives?.find(
            e => e._id === obj.representative
        )
    },
    setPriceListStatus(state, payload) {
        state.modal.fields.status = payload
    },
    setPriceListRegistry(state, payload) {
        state.registry = payload[0]
    },
    clearPriceListModal() {
        state.modal.step = 1
        state.modal.fields = {
            title: '',
            contractor: '',
            representative: '',
            description: '',
        }
    },
    clearPriceListErrors(state) {
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step].fields) {
                state.modal.errors[step].fields[key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
    clearPriceListTable(state) {
        clearSearch.clearSearchTable(state)
    },
    setPriceListSearch(state, payload) {
        state.request.searchFields = payload
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
