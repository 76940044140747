<template>
    <div class="d-flex justify-center">
        <v-btn
            color="#F27171"
            width="110"
            height="40"
            @click="$emit('close-csv-representative-modal')"
            class="white--text mx-2"
            >{{ $t('global:no') }}</v-btn
        >
        <!-- <div>
            <vue-json-to-csv
                :json-data="getPreparedData"
                :labels="preparedDataLabels"
            >
                <v-btn
                    color="#19AA8D"
                    width="110"
                    height="40"
                    @click="$emit('close-csv-representative-modal')"
                    class="white--text mx-2"
                    >{{ $t('global:yes') }}</v-btn
                >
            </vue-json-to-csv>
        </div> -->
        <div>
            <vue-json-to-csv
                :json-data="this.getPreparedDataFromRentContractsItems"
                :labels="preparedDataLabels"
                :disabled="getPreparedDataFromRentContractsItemsLoading"
            >
                <v-btn
                    color="#19AA8D"
                    width="110"
                    height="40"
                    @click="$emit('close-csv-representative-modal')"
                    class="white--text mx-2"
                         :disabled="getPreparedDataFromRentContractsItemsLoading"
                    >{{ $t('global:yes') }}</v-btn
                >
            </vue-json-to-csv>
        </div>
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import VueJsonToCsv from 'vue-json-to-csv'
export default {
    props: ['data'],
    data() {
        return {
            jsonToDownload: '',
            preparedDataLabels: {
                index: { title: 'Indeks' },
                contractNo: { title: 'Numer umowy' },
                contractorName: { title: 'Kontrahent' },
                representativeName: { title: 'Reprezentant - imię' },
                representativeLastName: { title: 'Reprezentant - nazwisko' },
                representativeEmail: { title: 'Reprezentant - e-mail' },
                representativePhoneNumber: {
                    title: 'Reprezentant - numer telefonu',
                },
            },
        }
    },
    components: {
        VueJsonToCsv,
    },
    computed: {
        ...mapGetters(['getPreparedDataFromRentContractsItems', 'getPreparedDataFromRentContractsItemsLoading']),

      
    },
    methods: {
        
    },
}
</script>

<style></style>
