import Rest from './../../../plugins/Rest'
import validation from './../../../plugins/validation'
import { clone } from 'lodash'
import moment from 'moment'
import store from '../../../store/index'

const state = {
    modal: {
        fields: {
            title: '',
            contractor: '',
            representative: '',
            description: '',
            indefinite: false,
            expirationDate: '',
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {
                    title: '',
                    contractor: '',
                    expirationDate: '',
                    indefinite: '',
                },
            },
        },
        step: 1,
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: '-createdAt',
        loading: false,
    },
    request: {
        populate: [
            {
                localField: 'contractor',
                foreignField: '_id',
                from: 'contacts',
                as: 'contractor',
            },
            {
                localField: 'createdBy',
                foreignField: '_id',
                from: 'users',
                as: 'createdBy',
            },
        ],
        searchFields: ['title'],
        defaultSearchFields: ['title'],
        select:
            '_id title representative createdAt updatedAt status description index contractor indefinite expirationDate folders isDeleted',
        where: {
            isDeleted: false,
        },
    },
    dictFields: {},
    statuses: [],
    details: {},
    registry: {},
}

const getters = {
    getBusinessDocumentModal: state => state.modal.fields,
    getBusinessDocumentRegistry: state => state.registry,
    getBusinessDocumentTable: state => state.table,
    getBusinessDocumentSearchFields: state => state.request,
    getBusinessDocumentErrors: state => state.modal.errors,
    getBusinessDocumentStep: state => state.modal,
    getBusinessDocumentStatuses: state => state.statuses,
    getBusinessDocumentDetails: state => state.details,
}

const actions = {
    async fetchBusinessDocumentTable({ commit, dispatch }) {
        state.table.loading = true
        try {
            let table = new Rest('GET', 'business-document')
                .select(state.request.select)
                .limit(state.table.limit)
                .page(state.table.page)
                .where(state.request.where)
                .populate(state.request.populate)
                .search(state.request.searchFields, state.table.search)
                .sort(state.table.sort || state.table.defaultSort)
                .send()

            let response = await table
            dispatch('fetchBusinessDocumentRegistry')
            commit('setBusinessDocumentTable', response.data)
        } catch (error) {
            console.error(error)
        }
        state.table.loading = false
    },
    async createBusinessDocument({ commit, dispatch }, payload) {
        try {
            await new Rest('POST', 'business-document')
                .setBody(state.modal.fields)
                .send()
            commit('clearBusinessDocumentModal')
            commit('clearBusinessDocumentErrors')
            dispatch('fetchBusinessDocumentTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            //HANDLE ERRORS
            if (err) {
                commit('setSnackbars', { type: 'error' })
                validation.setErrors(state.modal.errors, err)
            }
        }
    },

    async updateBusinessDocument({ commit, dispatch }, payload) {
        try {
            await new Rest('PUT', `business-document/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            dispatch('fetchBusinessDocument', { id: payload.id })
            commit('clearBusinessDocumentModal')
            commit('clearBusinessDocumentErrors')
            return true
        } catch (err) {
            //HANDLE ERRORS
            validation.setErrors(state.modal.errors, err)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async fetchBusinessDocument({ state, commit, dispatch }, payload) {
        try {
            let businessDocument = await new Rest(
                'GET',
                `business-document/${payload.id}`
            )
                .populate(['contractor', 'createdBy'])
                .select(
                    '_id title representative createdAt updatedAt status description index contractor indefinite expirationDate'
                )
                .send()

            let statusesReq = new Rest(
                'GET',
                `registries/business-document/statuses`
            )
                .select('name _id isDeleted color state')
                .where({ isDeleted: false })
                .send()

            let { 0: response, 1: statuses } = await Promise.all([
                businessDocument,
                statusesReq,
            ])
            dispatch('fetchBusinessDocumentRegistry')
            commit('setBusinessDocumentDetails', response.data)
            commit('setBusinessDocumentStatuses', statuses.data.items)

            if (payload.next) payload.next()
        } catch (err) {
            console.error(err)
        }
    },

    async fetchBusinessDocumentRegistry({ commit, dispatch }, payload) {
        let registry = await new Rest('GET', 'registries')
            .select('currentMenager name')
            .where({ url: 'business-document' })
            .populate([
                {
                    path: 'currentMenager',
                    select: 'name lastname mail status phone picture',
                },
            ])
            .send()

        commit('setBusinessDocumentRegistry', registry.data.items)
    },

    async updateBusinessDocumentStatus({ commit, dispatch }, payload) {
        commit('setBusinessDocumentModal')
        commit('setBusinessDocumentStatus', payload.status)
        try {
            await new Rest('PUT', `business-document/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            commit('setSnackbars', { type: 'success' })
        } catch (err) {
            console.error(err)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async deleteBusinessDocument({ commit, dispatch }, payload) {
        try {
            await new Rest('DELETE', `business-document/${payload.id}`).send()
            dispatch('fetchBusinessDocumentTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            console.error(err)
        }
    },
}

const mutations = {
    setBusinessDocumentTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
    setBusinessDocumentDetails(state, payload) {
        state.details = payload
    },
    setBusinessDocumentStatuses(state, payload) {
        state.statuses = payload
    },
    setBusinessDocumentModal(state, payload) {
        let obj = payload ? payload : clone(state.details)
        state.modal.fields = obj
        store.commit('setRepresentativesInput', {
            items: obj?.contractor?.representatives,
        })
        state.modal.fields.representative = obj?.contractor?.representatives?.find(
            e => e._id === obj.representative
        )
        state.modal.fields.expirationDate = obj.expirationDate
            ? moment(obj.expirationDate).format('YYYY-MM-DD')
            : ''
    },
    setBusinessDocumentStatus(state, payload) {
        state.modal.fields.status = payload
    },
    setBusinessDocumentRegistry(state, payload) {
        state.registry = payload[0]
    },
    clearBusinessDocumentModal() {
        state.modal.step = 1
        state.modal.fields = {
            title: '',
            contractor: '',
            representative: '',
            description: '',
            indefinite: false,
            expirationDate: '',
        }
    },
    clearBusinessDocumentErrors(state) {
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step].fields) {
                state.modal.errors[step].fields[key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
    clearBusinessDocumentTable(state) {
        state.table.search = ''
        state.table.page = 1
        state.request.where = { isDeleted: false }
        state.table.sort = '-createdAt'
        state.request.searchFields = ['title']
    },
    setBusinessDocumentSearch(state, payload) {
        state.request.searchFields = payload
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
