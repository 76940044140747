export const GlobalStatus = {
    active: 'ACTIVE',
    closed: 'CLOSED',
    archive: 'ARCHIVE',
    draft: 'DRAFT',
}
export const GlobalFilesStatus = {
    current: 'CURRENT',
    confidential: 'CONFIDENTIAL',
    archive: 'ARCHIVE',
    draft: 'DRAFT',
}

export const GlobalPermissions = {
    delete: 'DELETE',
    update: 'UPDATE',
    create: 'CREATE',
}

export const GlobalActions = {
    delete: 'delete',
    update: 'edit',
    create: 'create',
}

export const SettlementsTypes = {
    default: 'DEFAULT',
    autoApi: 'AUTOAPI',
    autoHistory: 'AUTOHISTORY',
    csvFile: 'CSVFILE',
    manual: 'MANUAL',
}
