<template>
    <v-tooltip bottom v-if="statusObject">
        <template v-slot:activator="{ on }">
            <v-avatar
                class="cursor-pointer mr-1"
                v-on="on"
                :color="statusObject.color"
                size="25"
            >
                <span class="white--text">{{
                    statusObject.value
                }}</span></v-avatar
            >
        </template>
        <span>{{ statusObject.tooltip }}</span>
    </v-tooltip>
</template>
<script>
export default {
    props: {
        iconType: { type: String, required: true },
    },
    data() {
        return {
            showIcon: null,
            statusObject: {},
        }
    },
    methods: {
        settlementIcon(type) {
            if (type) {
                switch (type) {
                    case 'MONO':
                        return {
                            color: 'grey',
                            value: 'P',
                            tooltip: this.$t(
                                'rent-contracts:rent-settlement.package.less'
                            ),
                        }
                    case 'MONO+':
                        return {
                            color: 'grey',
                            value: 'P+',
                            tooltip: this.$t(
                                'rent-contracts:rent-settlement.package.more'
                            ),
                        }
                    case 'COLOR':
                        return {
                            color: '#445d75',
                            value: 'P+',
                            tooltip: this.$t(
                                'rent-contracts:rent-settlement.package.less'
                            ),
                        }
                    case 'COLOR+':
                        return {
                            color: '#445d75',
                            value: 'P+',
                            tooltip: this.$t(
                                'rent-contracts:rent-settlement.package.more'
                            ),
                        }
                }
            }
        },
    },
    mounted() {
        this.statusObject = this.settlementIcon(this.iconType)
    },
}
</script>
