var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tbody',_vm._l((_vm.items),function(item,index){return _c('tr',{key:index},[_c('td',{staticClass:"truncate"},[_c('DoubleLine',{attrs:{"firtstLine":_vm.setFrom(item.from),"secondLine":_vm.setUsers(item.to.users)}})],1),_c('td',{staticClass:"truncate"},[_c('DoubleLine',{attrs:{"firtstLine":item.taskName || _vm.$t('global:empty'),"secondLine":item.taskDescription || _vm.$t('global:empty')}})],1),(_vm.$router.history.current.name==='tasks')?_c('td',{staticClass:"truncate"},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.goToSource(item)}}},[_c('DoubleLine',{attrs:{"firtstLine":_vm.$get(item,'taskSource.type',false)?_vm.$t(item.taskSource.type):'',"secondLine":_vm.$get(item,'taskSource.index','')}})],1)]):_vm._e(),_c('td',{staticClass:"truncate",style:(_vm.isToday(item.taskDate) === true
                    ? 'background: #f6f7e6'
                    : 'background: none')},[_c('DoubleLine',{attrs:{"firtstLine":_vm.$moment(item.taskDate).format(`DD-MM-YYYY`) ||
                    _vm.$t('global:empty'),"secondLine":item.taskTime || _vm.$t('global:empty')}})],1),_c('td',{staticClass:"truncate"},[(item.category.backgroundColor)?_c('span',[_c('v-chip',{style:(`color: ${_vm.$get(
                        item,
                        'category.textColor',
                        '#FFFFFF'
                    )}`),attrs:{"color":_vm.$get(item, 'category.backgroundColor', '#555555'),"dark":"","label":"","small":""}},[_vm._v(_vm._s(_vm.$get(item, 'category.field', _vm.$t('global:empty'))))])],1):_c('span',[_vm._v(_vm._s(item.category.field))])]),_c('td',[(item.priority.backgroundColor)?_c('span',[_c('v-chip',{style:(`color: ${_vm.$get(
                        item,
                        'priority.textColor',
                        '#FFFFFF'
                    )}`),attrs:{"color":_vm.$get(item, 'priority.backgroundColor', '#555555'),"dark":"","label":"","small":""}},[_vm._v(_vm._s(_vm.$get(item, 'priority.field', _vm.$t('global:empty'))))])],1):_c('span',[_vm._v(_vm._s(item.priority.field))])]),_c('td',[_c('v-chip',{style:(`color: ${_vm.$get(
                    item,
                    'status.color.font',
                    '#FFFFFF'
                )}`),attrs:{"color":_vm.setStatusColor(item.status),"dark":"","label":"","small":""}},[_vm._v(_vm._s(_vm.setStatus(item.status)))])],1),_c('td',{staticClass:"d-flex align-center justify-end"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit('edit-task', item._id)}}},[_c('v-icon',{attrs:{"color":"secondary","size":"20"}},[_vm._v("mdi-pencil")])],1)],1)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }