export function fillEmptyFields(object) {
    if (!object.transportConfig) {
        object.transportConfig = {
            settleFromTheBaseAutomatically: true,
            settleReturn: false,
            settleFromThePreviousPoint: false,
            kilometersToBeSettled: 0,
            typeOfAccountingKilometers: 'Stawka za 1 km transportu',
            transportCostToBeSettled: 0
        }
    }
}
