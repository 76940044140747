import Rest from './../../../plugins/Rest'
import clone from 'lodash.clone'
import validation from './../../../plugins/validation'
import clearSearch from './../../../plugins/clearSearch'
const state = {
    modal: {
        fields: {
            name: '',
            shortName: '',
            status: null,
            location: '',
            category: '',
            description: '',
            superiorDepartment: null,
            leader: null,
            users: [],
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {
                    name: '',
                    status: '',
                },
            },
        },
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: 'name',
        loading: false,
    },
    request: {
        populate: {
            localField: 'leader',
            foreignField: '_id',
            from: 'users',
            as: 'leader',
        },

        searchFields: ['name', 'leader.name'],
        defaultSearchFields: ['name', 'leader.name'],
        select:
            'name shortName leader.name leader.lastname status isDeleted folders',
        where: { isDeleted: false },
    },
    details: {
        name: '',
        shortName: '',
        status: null,
        location: '',
        category: '',
        description: '',
        superiorDepartment: null,
        leader: null,
        users: [],
    },
    departments: [],
    users: [],
    statuses: [],
    registry: {},
    departmentsList: [],
    dictFields: { category: '' },
}
const getters = {
    getDepartmentModal: state => state.modal.fields,
    getDepartmentError: state => state.modal.errors,
    getDepartmentDetails: state => state.details,
    getDepartmentTable: state => state.table,
    getAllDepartments: state => state.departments,
    getDepartmentsUsers: state => state.users,
    getDepartmentsStatuses: state => state.statuses,
    getDepartmentsRegistry: state => state.registry,
    getDepartmentsSearchFields: state => state.request,
    getDepartmentsList: state => state.departmentsList,
}

const actions = {
    async fetchDepartmentsTable({ commit }) {
        state.table.loading = true
        try {
            let table = new Rest('GET', 'departments')
                .select(state.request.select)
                .limit(state.table.limit)
                .page(state.table.page)
                .where(state.request.where)
                .populate(state.request.populate)
                .search(state.request.searchFields, state.table.search)
                .sort(state.table.sort || state.table.defaultSort)
                .send()

            let registryReq = new Rest('GET', 'registries')
                .select('currentMenager name')
                .where({ url: 'departments' })
                .populate([
                    {
                        path: 'currentMenager',
                        select: 'name lastname mail status phone picture',
                    },
                ])
                .send()
            let { 0: response, 1: registry } = await Promise.all([
                table,
                registryReq,
            ])
            commit('setDepartmentsTable', response.data)
            commit('setDepartmentRegistry', registry.data.items)
        } catch (err) {
            console.error(err)
        }
        state.table.loading = false
    },

    async fetchDepartments({ commit }, payload) {
        try {
            let response = await new Rest('GET', 'departments')
                .select(payload.select)
                .limit(payload.limit)
                .page(payload.page)
                .where(payload.where)
                .populate(payload.populate)
                .search(payload.searchFields, payload.search)
                .sort(payload.sort)
                .send()

            commit('setDepartmentsList', response.data.items)

            return response.data.items
        } catch (error) {
            console.error(error)
        }
    },

    async createDepartment({ state, commit, dispatch }, payload) {
        try {
            await new Rest('POST', 'departments')
                .setBody(state.modal.fields)
                .send()
            commit('clearDepartmentModal')
            commit('clearDepartmentsErrors')
            dispatch('fetchDepartmentsTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            //HANDLE ERRORS
            validation.setErrors(state.modal.errors, err)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async fetchAllDepartments({ commit }) {
        try {
            let deparmentsReq = await new Rest('GET', 'departments')
                .where({ isDeleted: false })
                .select('name isDeleted users shortName')

                .send()
            commit('setAllDepartments', deparmentsReq.data.items)
        } catch (err) {
            console.error(err)
        }
    },
    async fetchDepartmentsUsers({ commit }) {
        try {
            let usersReq = await new Rest('GET', 'users')
                .select('name lastname')
                .where({ isDeleted: false, 'status.state': 'ACTIVE' })
                .send()
            commit('setDepartmentsUsers', usersReq.data.items)
        } catch (err) {
            console.error(err)
        }
    },
    async fetchDepartmentsStatuses({ commit }) {
        try {
            let statusesReq = await new Rest(
                'GET',
                'registries/departments/statuses'
            )
                .select('name _id isDeleted color state')
                .where({ isDeleted: false })
                .send()
            commit('setDepartmentsStatuses', statusesReq.data.items)
        } catch (err) {
            console.error(err)
        }
    },
    async updateDepartment({ state, commit, dispatch }, payload) {
        try {
            await new Rest('PUT', `departments/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            commit('clearDepartmentModal')
            commit('clearDepartmentsErrors')
            dispatch('fetchDepartment', { id: payload.id })
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            console.error(err)
            //HANDLE ERRORS
            validation.setErrors(state.modal.errors, err)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async fetchDepartment({ state, commit }, payload) {
        try {
            let department = await new Rest('GET', `departments/${payload.id}`)
                .populate([
                    {
                        path: 'superiorDepartment',
                        select: 'name',
                    },
                    {
                        path: 'users',
                        select: 'name lastname',
                    },
                    {
                        path: 'leader',
                        select: 'name lastname',
                    },
                    {
                        path: 'createdBy',
                        select: 'name lastname mail status phone picture',
                    },
                ])
                .select('-folders')
                .send()

            let statusesReq = new Rest('GET', `registries/departments/statuses`)
                .select('name _id isDeleted color state')
                .where({ isDeleted: false })
                .send()

            let registryReq = new Rest('GET', 'registries')
                .select('currentMenager name')
                .where({ url: 'departments' })
                .populate([
                    {
                        path: 'currentMenager',
                        select: 'name lastname mail status phone picture',
                    },
                ])
                .send()

            let { 0: response, 1: statuses, 2: registry } = await Promise.all([
                department,
                statusesReq,
                registryReq,
            ])
            commit('setDepartmentDetails', response.data)
            commit('setDepartmentsStatuses', statuses.data.items)
            commit('setDepartmentRegistry', registry.data.items)

            if (payload.next) payload.next()
        } catch (err) {
            console.error(err)
        }
    },

    async updateDepartmentStatus({ commit, dispatch }, payload) {
        commit('setDepartmentModal')
        commit('setDepartmentStatus', payload.status)
        try {
            await new Rest('PUT', `departments/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            dispatch('fetchDepartment', { id: payload.id })
            commit('setSnackbars', { type: 'error' })
        } catch (err) {
            console.error(err)
            commit('setSnackbars', { type: 'error' })
        }
    },

    async deleteDepartment({ commit, dispatch }, payload) {
        try {
            await new Rest('DELETE', `departments/${payload.id}`).send()
            dispatch('fetchDepartmentsTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            console.error(err)
        }
    },
}

const mutations = {
    setDepartmentsTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
    setAllDepartments(state, payload) {
        state.departments = payload
    },
    setDepartmentsUsers(state, payload) {
        state.users = payload
    },
    setDepartmentsStatuses(state, payload) {
        state.statuses = payload
    },
    setDepartmentStatus(state, payload) {
        state.modal.fields.status = payload
    },
    clearDepartmentModal(state) {
        state.modal.fields = {
            name: '',
            shortName: '',
            comment: '',
            superiorDepartment: null,
            leader: null,
            users: [],
            status: null,
            location: '',
            category: '',
        }
    },
    setDepartmentDetails(state, payload) {
        state.details = payload
    },
    setDepartmentModal(state, payload) {
        let obj = payload ? payload : clone(state.details)
        state.modal.fields = obj
    },
    setDepartmentRegistry(state, payload) {
        state.registry = payload[0]
    },
    setDepartmentsList(state, payload) {
        state.departmentsList = payload
    },
    setDepartmentsSearch(state, payload) {
        state.request.searchFields = payload
    },
    clearDepartmentsErrors(state) {
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step].fields) {
                state.modal.errors[step].fields[key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
    clearDepartmentsTable(state) {
        clearSearch.clearSearchTable(state)
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
