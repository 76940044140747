<template>
    <div class="avatar-photo">
    <v-menu nudge-left nudge-width="300" :close-on-content-click="false">
        <template v-slot:activator="{ on: menu }">
            <v-tooltip class="tooltip_avatar" top>
                <template v-slot:activator="{ on: tooltip }">
                    <v-avatar
                        size="32"
                        dark
                        v-on="{ ...tooltip, ...menu }"
                        class="cursor-pointer mt-4 header-dimensions-media"
                        color="teal"
                    >
                        <img
                            v-if="picture"
                            :src="avatarPicture(picture)"
                            alt="avatar"
                        />
                        <span v-else class="white--text caption"
                            >{{ name[0] }}{{ lastname[0] }}</span
                        >
                    </v-avatar>
                </template>
                <span>{{ tooltip }}</span>
            </v-tooltip>
        </template>
        <v-card class="pa-4 flex-column central__title__card">
            <div class="d-flex">
                <v-avatar color="teal" size="40">
                    <img
                        v-if="picture"
                        :src="avatarPicture(picture)"
                        alt="avatar"
                    />
                    <span v-else class="white--text caption"
                        >{{ name[0] }}{{ lastname[0] }}</span
                    >
                </v-avatar>
                <div class="d-flex flex-column ml-3">
                    <span class="black--text">{{ name }} {{ lastname }}</span>
                    <span>{{ tooltip }}</span>
                </div>
            </div>
            <div class="d-flex flex-column">
                <v-divider class="my-3"></v-divider>
                <p class="ma-0">
                    <b>{{ $t('global:email') }}:</b>
                    {{ email }}
                </p>
                <p class="ma-0">
                    <b>{{ $t('global:phone') }}:</b>
                    {{ phone }}
                </p>
            </div>
        </v-card>
    </v-menu>
    </div>
</template>

<script>
/**
 * Central Table
 * @displayName  Avatar Header
 */

export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        tooltip: {
            type: String,
            required: true,
        },
        email: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        lastname: {
            type: String,
            required: true,
        },
        phone: {
            type: String,
            required: true,
        },
        picture: {
            type: String,
        },
    },
    methods: {
        avatarPicture(payload) {
            let picture = `${process.env.VUE_APP_SERVER}/secured/profile/${payload}`
            return picture
        },
    },
}
</script>
<style lang="scss" scoped>
$avatar-size: 1.3rem;
@media only screen and (max-width: 1440px) {
    .header-dimensions-media {
        margin: 3px -5px 3px 0px !important;
        min-width: $avatar-size !important;
        height: $avatar-size !important;
        width: $avatar-size !important;
        padding: 10px !important;
    }
}
</style>