import Rest from './../../../plugins/Rest'
import clone from 'lodash.clone'
import moment from 'moment'
const state = {
    modal: {
        fields: {
            contract: '',
            dayJob: '',
            employmentStart: '',
            employmentEnd: '',
            lastExamination: '',
            endExamination: '',
            startBhp: '',
            endBhp: '',
            dayOfBirth: '',
            placeOfBirth: '',
            needments: '',
            comment: '',
        },
        errors: {
            stepOne: {
                validation: true,
                contract: '',
                dayJob: '',
                employmentStart: '',
                employmentEnd: '',
                lastExamination: '',
                endExamination: '',
                startBhp: '',
                endBhp: '',
                dayOfBirth: '',
                placeOfBirth: '',
            },
            stepTwo: {
                validation: true,
                needments: '',
                comment: '',
            },
        },
    },

    details: {},
}
const getters = {
    getUsersStaffModal: state => state.modal.fields,
    getUsersStaffError: state => state.modal.errors,
    getUsersStaffDetails: state => state.details,
}

const actions = {
    async fetchUserStaff({ state, commit }, payload) {
        try {
            let response = await new Rest(
                'GET',
                `users/${payload.id}/staff`
            ).send()
            commit('setUserStaff', response.data)
            if (payload.next) payload.next()
        } catch (error) {
            // console.error(error)
        }
    },

    async editUserStaff({ state, commit }, payload) {
        try {
            let response = await new Rest('PUT', `users/${payload.id}/staff`)
                .setBody(state.modal.fields)
                .send()
            commit('setUserStaff', response.data)
            commit('setSnackbars', {type: 'success'})
        } catch (error) {
            // console.error(error)
            commit('setSnackbars', {type: 'error'})
        }
    },
}

const mutations = {
    clearUserStaff(state){
        state.details={};
    },
    setUserStaff(state, payload) {
        state.details = payload 
    },
    setUserStaffModal(state, payload) {
        let obj = payload ? payload : clone(state.details)
        state.modal.fields = obj        
        state.modal.fields.employmentStart = state.details.employmentStart ? moment(state.details.employmentStart).format('YYYY-MM-DD') : ''        
        state.modal.fields.employmentEnd = state.details.employmentEnd ? moment(state.details.employmentEnd).format('YYYY-MM-DD') : ''
        state.modal.fields.lastExamination =state.details.lastExamination ? moment(state.details.lastExamination).format('YYYY-MM-DD') : ''
        state.modal.fields.endExamination = state.modal.fields.endExamination ? moment(state.details.endExamination).format('YYYY-MM-DD') : ''
        state.modal.fields.startBhp = state.modal.fields.startBhp ? moment(state.details.startBhp).format('YYYY-MM-DD') : ''
        state.modal.fields.endBhp = state.modal.fields.endBhp ? moment(state.details.endBhp).format('YYYY-MM-DD') : ''
        state.modal.fields.dayOfBirth = state.modal.fields.dayOfBirth? moment(state.details.dayOfBirth).format('YYYY-MM-DD') : ''
    }, 
}

export default {
    state,
    getters,
    actions,
    mutations,
}
