import Rest from './../../../plugins/Rest'
import clone from 'lodash.clone'
import validation from './../../../plugins/validation'
const state = {
    modal: {
        fields: {
            taskId: '',
            text: '',
        },
        errors: {
            text: '',
        },
    },
    items: [],
}

const getters = {
    getCommentFields: state => state.modal.fields,
    getCommentItems: state => state.items,
}

const actions = {
    async createComment({ commit }) {
        try {
            let response = await new Rest(
                'POST',
                `tasks/${state.modal.fields.taskId}/comments`
            )
                .setBody(state.modal.fields)
                .send()

            commit('addNewComment', response.data)
        } catch (error) {
            console.error(error)
        }
    },

    async fetchComments({ commit }) {
        try {
            let response = await new Rest(
                'GET',
                `tasks/${state.modal.fields.taskId}/comments`
            ).send()
            commit('setCommentsItems', response.data)
        } catch (error) {
            console.error(error)
        }
    },

    async removeComment({ commit, dispatch }, payload) {
        try {
            await new Rest(
                'DELETE',
                `tasks/${state.modal.fields.taskId}/comments/${payload.id}`
            ).send()
            await dispatch('fetchComments')
        } catch (error) {
            console.error(error)
        }
    },
}

const mutations = {
    setCommentsTaskId(state, payload) {
        state.modal.fields.taskId = payload
    },
    setCommentsItems(state, payload) {
        state.items = payload.reverse()
    },
    clearCommentsModal(state) {
        state.modal.fields.text = ''
        state.modal.fields.taskId = ''
        state.items = []
    },
    addNewComment(state, payload) {
        state.items = [payload, ...state.items]
        state.modal.fields.text = ''       
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
