import { render, staticRenderFns } from "./ConfirmButtons.vue?vue&type=template&id=2c19600a"
import script from "./ConfirmButtons.vue?vue&type=script&lang=js"
export * from "./ConfirmButtons.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports