import Rest from '../../plugins/Rest'
import validation from './../../plugins/validation'

const state = {
    modal: {
        fields: {
            name: '',
            registries: [],
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {
                    name: '',
                },
            },
        },
        steps: '',
    },
    fieldsNames: {},
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: 'name',
    },
    request: {
        populate: [
            {
                path: 'createdBy',
                select: 'name lastname',
            },
        ],
        searchFields: ['name'],
        select: 'name createdAt updatedAt isDeleted registries',
        where: { isDeleted: false },
    },
    rolesList: [],
}
const getters = {
    getRoles: state => state.modal.fields,
    getRolesTable: state => state.table,
    getRolesRegistries: state => state.modal.fields.registries,
    getRoleErrors: state => state.modal.errors,
    getRoleSearchFields: state => state.request,
    getRolesList: state => state.rolesList,
}
const actions = {
    async fetchRolesTable({ commit }, payload) {
        try {
            let tableReq = new Rest('GET', 'roles')
                .select(state.request.select)
                .populate(state.request.populate)
                .where(state.request.where)
                .search(state.request.searchFields, state.table.search)
                .limit(state.table.limit)
                .page(state.table.page)
                .send()

            let { 0: table } = await Promise.all([tableReq])

            commit('setRolesTable', table.data)
        } catch (err) {
            //HANDLE ERRORS
            console.error(err)
        }
    },

    async fetchRolesList({ commit }, payload) {
        try {
            let response = await new Rest('GET', 'roles')
                .select(payload.select)
                .limit(payload.limit)
                .page(payload.page)
                .search(payload.searchFields, payload.search)
                .where(payload.where)
                .populate(payload.populate)
                .sort(payload.sort)
                .send()
            commit('setRolesList', response.data.items)
        } catch (err) {
            console.error(err)
        }
    },

    async fetchRole({ commit }, payload) {
        try {
            let responseReq = new Rest('GET', `roles/${payload.id}`)
                .select('name registries')
                .send()
            let registriesReq = new Rest('GET', 'registries')
                .select('name url icon')
                .where({ visible: true })
                .send()

            let { 0: registries, 1: response } = await Promise.all([
                registriesReq,
                responseReq,
            ])
            commit('setRolesRegistries', registries.data.items)
            commit('setRolesDetails', response.data)
        } catch (err) {
            console.error(err)
        }
    },

    async fetchRoleRegistries({ commit }) {
        let registriesReq = await new Rest('GET', 'registries')
            .select('name url icon')
            .where({ visible: true })
            .send()
        commit('setRolesRegistries', registriesReq.data.items)
    },

    chooseOperation({}, payload) {
        let items = [...state.modal.fields.registries]
        let index = items.findIndex(item => item.url == payload.url)
        if (items[index].operations)
            if (!items[index].operations.includes(payload.operation))
                items[index].operations.push(payload.operation)
            else {
                let opIndex = items[index].operations.indexOf(payload.operation)
                items[index].operations.splice(opIndex, 1)
            }
        else if (!items[index].operations)
            items[index].operations = [payload.operation]

        state.modal.fields.registries = items
    },

    async createRole({ commit, dispatch }) {
        try {
            await new Rest('POST', 'roles').setBody(state.modal.fields).send()
            commit('clearRoleModal')
            dispatch('fetchRolesTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            //HANDLE ERRORS
            validation.setErrors(state.modal.errors, err)
            commit('setSnackbars', { type: 'error' })
        }
    },

    async updateRole({ commit, dispatch }, payload) {
        try {
            await new Rest('PUT', `roles/${state.modal._id}`)
                .setBody(state.modal.fields)
                .send()
            commit('clearRoleModal')
            dispatch('fetchRolesTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            //HANDLE ERRORS
            validation.setErrors(state.modal.errors, err)
            commit('setSnackbars', { type: 'error' })
        }
    },

    async deleteRole({ commit, dispatch }, payload) {
        try {
            await new Rest('DELETE', `roles/${payload.id}`).send()
            dispatch('fetchRolesTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            console.error(err)
            commit('setSnackbars', { type: 'error' })
        }
    },
}
const mutations = {
    setRoleItems(state) {
        state.registries.forEach(item => {
            let index = state.items.findIndex(i => i.url == item.url)
            if (index > -1) state.items[index].operations = item.operations
        })
        state.correctItems = state.items
    },
    setRolesTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
    },
    setRolesRegistries(state, payload) {
        state.modal.fields.registries = payload
    },
    setCheckRole(state, payload) {
        state.modal.fields.registries.forEach(element => {
            if (element.url == payload.url) {
                if (element.operations.includes(payload.action)) return true
                else return false
            }
        })
    },
    clearRoleModal(state) {
        state.modal.fields.name = ''
        state.modal.errors = {
            stepOne: {
                validation: true,
                fields: {
                    name: '',
                },
            },
        }
    },

    setRolesDetails(state, payload) {
        state.modal.fields.name = payload.name
        state.modal._id = payload._id
        state.modal.fields.registries = state.modal.fields.registries.filter(
            item => {
                let index = payload.registries.findIndex(
                    v => v.url === item.url
                )
                if (index > -1)
                    item.operations = payload.registries[index].operations
                return item
            }
        )
    },

    setRolesList(state, payload) {
        state.rolesList = payload
    },
    clearRolesTable(state) {
        state.table.search = ''
        state.table.page = 1
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
