<template>
    <v-progress-circular
        :size="15"
        :width="2"
        indeterminate
        color="#19aa8d"
    >
    </v-progress-circular>
</template>

<script>
export default {}
</script>

<style></style>
