var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"details__card"},[_c('v-col',{staticClass:"pa-5",attrs:{"cols":"3","xl":"3","lg":"2","md":"12"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12"}},[_c('p',{staticClass:"font-weight-bold d-flex flex-column headline pa-0 ma-0 mb-5"},[_vm._v(" "+_vm._s(_vm.$get( _vm.getLastValueRentInfo, 'annex.contractor.name', _vm.$t('global:empty') ))+" ")]),_c('span',[_c('b',[_vm._v(_vm._s(`${_vm.$t( 'rent-contracts:rent-info.contractNo' )}`)+":")]),_vm._v(" "+_vm._s(_vm.$get( _vm.getLastValueRentInfo, 'contractNo', false ) || _vm.$get( _vm.getLastValueRentInfo, 'externalContractNo', _vm.$t('g lobal:empty') )))]),_c('span',[_c('b',[_vm._v(_vm._s(`${_vm.$t( 'rent-contracts:rent-info.expirationDate' )}`)+":")]),_vm._v(" "+_vm._s(_vm.getLastValueRentInfo.annex.expirationDate ? _vm.$moment( _vm.getLastValueRentInfo.annex .expirationDate ).format(`DD/MM/YYYY`) : _vm.$t('global:empty')))]),(_vm.getAnnexStatus() === 'Aktywny OP')?_c('span',{staticClass:"annexStartBundleText"},[_vm._v(_vm._s(_vm.$t('rent-contracts:rent-info.welcomeBundleText')))]):_vm._e(),(_vm.getAnnexStatus() === 'Aktywny OP')?_c('span',{staticClass:"annexStartBundleText"},[_vm._v(_vm._s(_vm.$t('rent-contracts:rent-info.numberOfMonth'))+": "+_vm._s(_vm.getAnnexStartBundleSize()))]):_vm._e()])],1)],1),_c('v-col',{staticClass:"pa-5",attrs:{"cols":"6","xl":"6","lg":"8","md":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.$get(_vm.getLastValueRentInfo, 'annex.title', ''),"label":_vm.$t('rent-contracts:rent-info.title')}})],1)],1),_c('v-row',{staticClass:"px-3"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-row',[(_vm.getLastValueRentInfo.contractNo)?_c('v-text-field',{attrs:{"readonly":"","label":`${_vm.$t(
                                'rent-contracts:rent-info.contractNo'
                            )}`},model:{value:(_vm.getLastValueRentInfo.contractNo),callback:function ($$v) {_vm.$set(_vm.getLastValueRentInfo, "contractNo", $$v)},expression:"getLastValueRentInfo.contractNo"}}):_c('v-text-field',{attrs:{"readonly":"","label":`${_vm.$t(
                                'rent-contracts:rent-info.externalContractNo'
                            )}`},model:{value:(
                                _vm.getLastValueRentInfo.externalContractNo
                            ),callback:function ($$v) {_vm.$set(_vm.getLastValueRentInfo, "externalContractNo", $$v)},expression:"\n                                getLastValueRentInfo.externalContractNo\n                            "}})],1)],1),_c('v-col',{attrs:{"cols":"4","offset":"4"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-checkbox',{staticClass:"shrink mr-2 mt-0",attrs:{"hide-details":"","disabled":""},model:{value:(
                                _vm.getLastValueRentInfo.annex.contractAnnexNo
                            ),callback:function ($$v) {_vm.$set(_vm.getLastValueRentInfo.annex, "contractAnnexNo", $$v)},expression:"\n                                getLastValueRentInfo.annex.contractAnnexNo\n                            "}}),_c('v-text-field',{attrs:{"readonly":"","disabled":!_vm.getLastValueRentInfo.annex.contractAnnexNo,"label":`${_vm.$t(
                                'rent-contracts:rent-info.contractAnnex'
                            )}`},model:{value:(
                                _vm.getLastValueRentInfo.annex.contractAnnexNo
                            ),callback:function ($$v) {_vm.$set(_vm.getLastValueRentInfo.annex, "contractAnnexNo", $$v)},expression:"\n                                getLastValueRentInfo.annex.contractAnnexNo\n                            "}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.$get(
                                _vm.getLastValueRentInfo,
                                'annex.contractor.name',
                                ''
                            ),"label":_vm.$t('rent-contracts:rent-info.contractor')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.findRepresentative(_vm.getLastValueRentInfo),"label":_vm.$t('rent-contracts:rent-info.representative')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"readonly":"","append-icon":"","items":[_vm.getLastValueRentInfo.annex.contractType],"label":_vm.$t('rent-contracts:rent-info.contractType'),"return-object":"","item-value":"field"},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [(
                                    data.item && data.item.backgroundColor
                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item.backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                            ? data.item.textColor
                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item && data.item.field)+" ")])]}}]),model:{value:(_vm.getLastValueRentInfo.annex.contractType),callback:function ($$v) {_vm.$set(_vm.getLastValueRentInfo.annex, "contractType", $$v)},expression:"getLastValueRentInfo.annex.contractType"}})],1),_c('v-col',{attrs:{"cols":"4"}}),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.getLastValueRentInfo.annex.responseTime.field,"label":_vm.$t('rent-contracts:rent-info.responseTime')}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('money-field',{staticClass:"margin--input",attrs:{"disabled":false,"readonly":true,"value":_vm.getLastValueRentInfo.annex.implementationCost,"label":`${_vm.$t(
                            'rent-contracts:rent-info.implementationCost'
                        )}`}})],1),_c('v-col',{attrs:{"cols":"4"}}),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.getLastValueRentInfo.annex
                                .representativeSelectedEmail,"label":_vm.$t('rent-contracts:represnetativeEmail')}})],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{staticClass:"NoteArea",attrs:{"label":_vm.$t('rent-contracts:rent-info.notes'),"rows":"3","no-resize":"","readonly":""},model:{value:(_vm.getLastValueRentInfo.annex.notes),callback:function ($$v) {_vm.$set(_vm.getLastValueRentInfo.annex, "notes", $$v)},expression:"getLastValueRentInfo.annex.notes"}})],1)],1)],1),_c('v-col',{staticClass:"pa-5",attrs:{"cols":"3","xl":"3","lg":"2","md":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.getLastValueRentInfo.annex.startDate
                                ? _vm.$moment(
                                      _vm.getLastValueRentInfo.annex.startDate
                                  ).format('DD-MM-YYYY')
                                : _vm.$t('global:empty'),"label":_vm.$t('rent-contracts:rent-info.startDate')}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.getLastValueRentInfo.annex.expirationDate
                                ? _vm.$moment(
                                      _vm.getLastValueRentInfo.annex
                                          .expirationDate
                                  ).format('DD-MM-YYYY')
                                : _vm.$t('global:empty'),"label":_vm.$t('rent-contracts:rent-info.expirationDate')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.getLastValueRentInfo.annex.accountingDay,"label":_vm.$t('rent-contracts:rent-info.accountingDay')}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.getLastValueRentInfo.annex.paymentDeadline
                                ? _vm.getLastValueRentInfo.annex.paymentDeadline
                                : _vm.$t('global:empty'),"label":'Termin zapłaty'}})],1)],1),_c('v-row',{staticClass:"background--total"},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text--settlement"},[_vm._v(_vm._s(_vm.$t('rent-contracts:rent-info.lastAccounting.header')))])]),_c('v-col',{attrs:{"cols":"11"}}),_c('v-col',{attrs:{"cols":"11"}},[_c('v-text-field',{attrs:{"dense":"","readonly":"","value":_vm.getLastValueRentInfo.lastSettlement &&
                            _vm.getLastValueRentInfo.lastSettlement.createdBy
                                ? `${_vm.getLastValueRentInfo.lastSettlement.createdBy.name} ${_vm.getLastValueRentInfo.lastSettlement.createdBy.lastname}`
                                : '',"label":_vm.$t(
                                'rent-contracts:rent-info.lastAccounting.user'
                            )}})],1),_c('v-col',{attrs:{"cols":"11"}},[_c('v-text-field',{attrs:{"dense":"","readonly":"","value":_vm.$get(
                                _vm.getLastValueRentInfo,
                                'annex.settlementStatus',
                                ''
                            ),"label":_vm.$t(
                                'rent-contracts:rent-info.lastAccounting.status'
                            )}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"11"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.getLastValueRentInfo.annex.BIDProject,"label":_vm.$t('rent-contracts:rent-info.BIDProject')}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }