import Rest from '../../plugins/Rest'

const state = {
    dictionaries: [],
    registryDictionaries: {},
}

const getters = {
    getDictionaries: state => state.dictionaries,
    getRegistryDictionaries: state => state.registryDictionaries,
}

const actions = {
    async fetchDictionaries({ commit, dispatch }, payload) {
        try {
            let response = await new Rest('GET', 'dictionaries').send()
            
            for (let dict of response.data.items) {
                if (dict.entries.length > 0) {
                    if (dict.field) {
                        dict.entries.sort(function (a, b) {
                            if (a[dict.field] && b[dict.field]) {
                                var textA = a[dict.field].toUpperCase()
                                var textB = b[dict.field].toUpperCase()
                                return textA < textB
                                    ? -1
                                    : textA > textB
                                    ? 1
                                    : 0
                            }
                        })
                    } else {
                        dict.entries.sort(function (a, b) {
                            if (a?.field && b?.field) {
                                var textA = a.field.toUpperCase()
                                var textB = b.field.toUpperCase()
                                return textA < textB
                                    ? -1
                                    : textA > textB
                                    ? 1
                                    : 0
                            }
                        })
                    }
                }
            }
            commit('setDictionaries', response.data)
            if (payload.next) payload.next()
        } catch (error) {
            console.error(error)
        }
    },
    async fetchDictionary({ commit, dispatch }, payload) {
        try {
            let url = payload.id ? payload.id : payload.url
            let response = await new Rest('GET', `dictionaries/${url}`).send()
            return response.data
        } catch (error) {
            console.error(error)
        }
    },
    async updateDictionary({ commit, dispatch }, payload) {
      
        try {
            await new Rest('PUT', `dictionaries/${payload.id}`)
                .setBody(payload.body)
                .send()
            commit('setSnackbars', { type: 'success' })
        } catch (error) {
            console.error(error)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async createDictionary({ commit, dispatch }, payload) {
        try {
            await new Rest('POST', `dictionaries`)
                .setBody({
                    name: payload.name,
                    field: payload.field,
                    tab: payload.tab,
                })
                .send()
            dispatch('fetchDictionaries')
            commit('setSnackbars', { type: 'success' })
        } catch (error) {
            console.error()
            commit('setSnackbars', { type: 'error' })
        }
    },

    async fetchRegistryDictionaries({ commit, dispatch }, payload) {
        if (!payload.dictionaries) payload.dictionaries = {}
        let setOfDictionaryIds = new Set(Object.values(payload.dictionaries))
        let entitiesDictionaries = payload.entities
            .filter(entity => entity.dictionaries)
            .map(entity => entity.dictionaries)

        for (let obj of entitiesDictionaries)
            Object.values(obj).forEach(id => setOfDictionaryIds.add(id))
        let where = { $or: [] }
        setOfDictionaryIds.forEach(id => where.$or.push({ _id: id }))
        for (let entity of payload.entities)
            if (entity.dictionaries)
                payload.dictionaries[entity.url] = entity.dictionaries
        let response = await new Rest('GET', `dictionaries`)
            .where(where)
            .select('entries name')
            .send()
        let dicts = response.data.items.map(o => {
            return { data: o }
        })
        commit('setRegistryDictionaries', {
            responses: dicts,
            fields: payload.dictionaries,
        })
    },
}

const mutations = {
    setDictionaries(state, payload) {
        state.dictionaries = payload.items
    },
    setRegistryDictionaries(state, payload) {
        for (let field in payload.fields) {
            if (typeof payload.fields[field] === 'object') {
                state.registryDictionaries[field] = {}
                for (let entityField in payload.fields[field]) {
                    state.registryDictionaries[field][
                        entityField
                    ] = payload.responses.find(
                        response =>
                            response.data._id ==
                            payload.fields[field][entityField]
                    ).data.entries
                }
            } else
                state.registryDictionaries[field] = payload.responses.find(
                    response => response.data._id == payload.fields[field]
                ).data.entries
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
