import Rest from './../../../plugins/Rest'
import validation from './../../../plugins/validation'
import { clone } from 'lodash'
import store from '../../../store/index'
import moment from 'moment'
import clearSearch from './../../../plugins/clearSearch'
import Rest2 from '../../../plugins/Rest2'

const getDefaultState = () => {
    return {
        modal: {
            fields: {
                contractNo: '',
                externalContractNo: '',
                annex: {
                    title: '',
                    contractAnnex: '',
                    contractor: '',
                    contractorBuyer: '',
                    representative: {},
                    contractType: '',
                    responseTime: '',
                    implementationCost: '',
                    // monitoringCost: '',
                    customerCarer: null,
                    BIDProject: '',
                    startBundle: null,
                    monoBundle: '',
                    colorBundle: '',
                    colorBundleCost: '',
                    constMonthlyBundle: null,
                    constMonoBundle: '',
                    constMonoBundlePrice: '',
                    constColorBundle: '',
                    constColorBundlePrice: '',
                    expirationDate: '',
                    accountingDay: '',
                    lastAccountingDate: '',
                    lastAccountingUser: null,
                    lastAccountingStatus: '',
                    notes: '',
                    //
                    paymentDeadline: '',
                    numberOfPromotionalPeriods: '',
                },
            },
            errors: {
                stepOne: {
                    validation: true,
                    fields: {
                        contractType: '',
                        contractNo: '',
                        contractor: '',
                        externalContractNo: '',
                    },
                },
                stepTwo: {
                    validation: true,
                    fields: { numberOfPromotionalPeriods: '' },
                },
                stepThree: {
                    validation: true,
                    fields: {},
                },
            },
            step: 1,
        },
        table: {
            items: [],
            total: '',
            search: '',
            page: 1,
            limit: 11,
            pages: 1,
            defaultSort: '-createdAt',
            loading: false,
            currentMode: '',
        },
        request: {
            populate: [
                {
                    from: 'rent-annex',
                    localField: 'rentAnnexs',
                    foreignField: '_id',
                    as: 'rentAnnexs',
                },
                {
                    from: 'contacts',
                    localField: 'rentAnnexs.contractor',
                    foreignField: '_id',
                    as: 'rentAnnexs.contractor',
                },
                {
                    from: 'contacts',
                    localField: 'contractorBuyer',
                    foreignField: '_id',
                    as: 'annex.contractorBuyer',
                },
                {
                    from: 'users',
                    localField: 'annex.customerCarer',
                    foreignField: '_id',
                    as: 'annex.customerCarer',
                },
                {
                    from: 'users',
                    localField: 'createdBy',
                    foreignField: '_id',
                    as: 'createdBy',
                },
            ],
            unwindExeptions: ['rentAnnexs'],
            searchFields: [
                'contractNo',
                'externalContractNo',
                'rentAnnexs.contractor.name',
            ],
            defaultSearchFields: [
                'contractNo',
                'externalContractNo',
                'rentAnnexs.contractor.name',
            ],
            select:
                '_id index contractNo  annex rentAnnexs representative externalContractNo expirationDate status folders createdAt createdBy',
            where: {
                isDeleted: false,
            },
            unsettled: false,
            unsettledLastMonth: false,
            unsettledBeforeCurrentDay: false,
            unsettledAfterCurrentDay: false,
        },
        dictFields: {
            contractType: '',
            responseTime: '',
        },
        statuses: [],
        registry: {},
        lastValue: {},
        todayRent: [],
        preparedDataFromRentContractsItems: [],
        preparedDataFromRentContractsItemsLoading: false,
        csvFileLoading: false,
    }
}

const state = getDefaultState()

const getters = {
    getRentInfoModal: state => state.modal.fields,
    getRentInfoRegistry: state => state.registry,
    getRentInfoTable: state => state.table,
    getRentInfoSearchFields: state => state.request,
    getRentInfoErrors: state => state.modal.errors,
    getRentInfoStep: state => state.modal,
    getRentInfoStatuses: state => state.statuses,
    getLastValueRentInfo: state => state.lastValue,
    getTodayRent: state => state.todayRent,
    getPreparedDataFromRentContractsItems: state =>
        state.preparedDataFromRentContractsItems,
    getPreparedDataFromRentContractsItemsLoading: state =>
        state.preparedDataFromRentContractsItemsLoading,
    getCSVFileLoading: state => state.csvFileLoading,
}

const actions = {
    async fetchLastRentInfo({ commit, dispatch }, payload) {
        try {
            if (payload?.id) {
                const rentInfo = await new Rest2(
                    'GET',
                    'rent-info/' + payload.id
                )
                    .select(state.request.select)
                    .limit(1)
                    .where({
                        ...state.request.where,
                        $or: [
                            { contractNo: { $ne: null } },
                            { externalContractNo: { $ne: null } },
                        ],
                    })
                    .populate(['rentAnnexs', 'createdBy'])
                    .search(state.request.searchFields, state.table.search)
                    .sort('-createdAt')
                    .send()

                let statusesReq = new Rest2(
                    'GET',
                    `registries/rent-info/statuses`
                )
                    .select('name _id isDeleted color state')
                    .where({ isDeleted: false })
                    .send()

                let { 0: response, 1: statuses } = await Promise.all([
                    rentInfo,
                    statusesReq,
                ])

                commit('setRentInfoStatuses', statuses.data.items)
                commit('setLastValue', response.data)
            } else {
                const rentInfo = await new Rest2('GET', 'rent-info')
                    .select(state.request.select)
                    .limit(1)
                    .where({
                        ...state.request.where,
                        $or: [
                            { contractNo: { $ne: null } },
                            { externalContractNo: { $ne: null } },
                        ],
                    })
                    .populate(state.request.populate)
                    .search(state.request.searchFields, state.table.search)
                    .sort('-createdAt')
                    .send()

                let statusesReq = new Rest2(
                    'GET',
                    `registries/rent-info/statuses`
                )
                    .select('name _id isDeleted color state')
                    .where({ isDeleted: false })
                    .send()

                let { 0: response, 1: statuses } = await Promise.all([
                    rentInfo,
                    statusesReq,
                ])

                commit('setLastValue', response.data.items.pop())
                commit('setRentInfoStatuses', statuses.data.items)
            }

            if (payload?.next) {
                payload.next()
            }
        } catch (err) {
            console.log(err)
            return err
        }
    },

    async findUnsettledLastMonth() {
        // try {
        //     let response = await new Rest('GET', 'rent-info')
        //         .select(state.request.select)
        //         .unwindExeptions(state.request.unwindExeptions)
        //         .limit(state.table.limit)
        //         .page(state.table.page)
        //         .where(state.request.where)
        //         .populate(state.request.populate)
        //         .search(state.request.searchFields, state.table.search)
        //         .sort(state.table.sort || state.table.defaultSort)
        //         .addParams({
        //             unsettledLastMonth: true,
        //         })
        //         .send()
        //     if (response.data.total > 0) {
        //         state.request.unsettledLastMonth = true
        //     } else {
        //         state.request.unsettledLastMonth = false
        //     }
        //     return response.data.total
        // } catch (error) {
        //     console.error(error)
        // }
    },

    async fetchRentInfoTable({ commit, dispatch }, payload) {
        state.table.loading = true

        try {
            let response = await new Rest2('GET', 'rent-info')
                .select(state.request.select)
                .unwindExeptions(state.request.unwindExeptions)
                .limit(state.table.limit)
                .page(state.table.page)
                .where(state.request.where)
                .populate(state.request.populate)
                .search(state.request.searchFields, state.table.search)
                .sort(state.table.sort || state.table.defaultSort)
                .addParams({
                    unsettled:
                        (payload && payload.unsettled) ||
                        state.request.unsettled,
                    // unsettledLastMonth:
                    //     (payload && payload.unsettledLastMonth) ||
                    //     state.request.unsettledLastMonth,
                    unsettledBeforeCurrentDay:
                        (payload && payload.unsettledBeforeCurrentDay) ||
                        state.request.unsettledBeforeCurrentDay,
                    unsettledAfterCurrentDay:
                        (payload && payload.unsettledAfterCurrentDay) ||
                        state.request.unsettledAfterCurrentDay,
                })
                .send()

            dispatch('fetchRentInfoRegistry')

            if (!payload) {
                dispatch('isSettleToday')
            }

            commit('setRentInfoTable', response.data)
        } catch (error) {
            console.error(error)
        }
        state.table.loading = false
    },

    async fetchPreparedDataFromRentContractsItems(
        { commit, dispatch },
        payload
    ) {
        state.preparedDataFromRentContractsItemsLoading = true

        try {
            let response = await new Rest2('GET', 'rent-info')
                .select(state.request.select)
                .unwindExeptions(state.request.unwindExeptions)
                .where(state.request.where)
                .populate(state.request.populate)
                .search(state.request.searchFields, state.table.search)
                .sort(state.table.sort || state.table.defaultSort)
                .addParams({
                    unsettled:
                        (payload && payload.unsettled) ||
                        state.request.unsettled,
                })
                .send()

            if (response.data.items) {
                commit(
                    'setPreparedDataFromRentContractsItems',
                    response.data.items
                )
            }
        } catch (error) {
            console.error(error)
        }
        state.preparedDataFromRentContractsItemsLoading = false
    },

    async isSettleToday({ commit, dispatch }, payload) {
        try {
            let response = await new Rest2('GET', 'rent-info/isSettleToday')
                .select(state.request.select)
                .unwindExeptions(state.request.unwindExeptions)
                .limit(state.table.limit)
                .page(state.table.page)
                .where(state.request.where)
                .populate(state.request.populate)
                .search(state.request.searchFields, state.table.search)
                .sort(state.table.sort || state.table.defaultSort)
                .addParams({
                    unsettled:
                        (payload && payload.unsettled) ||
                        state.request.unsettled,
                    // unsettledLastMonth:
                    //     (payload && payload.unsettledLastMonth) ||
                    //     state.request.unsettledLastMonth,
                })
                .send()

            commit('setRentIsToday', response.data.todayIs)
        } catch (err) {
            console.log(err)
        }
    },

    async createRentInfo({ commit, dispatch }, payload) {
        try {
            await new Rest2('POST', 'rent-info')
                .setBody(state.modal.fields)
                .send()
            commit('clearRentInfoModal')
            commit('clearRentInfoErrors')
            dispatch('fetchRentInfoTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            //HANDLE ERRORS
            if (err) {
                console.log(state.modal.errors)
                commit('setSnackbars', { type: 'error' })
                validation.setErrors(state.modal.errors, err)
            }
        }
    },

    async updateRentInfo({ commit, dispatch, rootState }, payload) {
        try {
            await new Rest2('PUT', `rent-info/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            dispatch('fetchLastRentInfo', { id: payload.id })
            commit('clearRentInfoModal')
            commit('clearRentInfoErrors')
            dispatch('fetchLastRentInfo', { id: payload.id })
            return true
        } catch (err) {
            //HANDLE ERRORS
            validation.setErrors(state.modal.errors, err)
            commit('setSnackbars', { type: 'error' })
        }
    },

    async fetchRentInfoRegistry({ commit, dispatch }, payload) {
        let registry = await new Rest2('GET', 'registries')
            .select('currentMenager name index')
            .where({ url: 'rent-info' })
            .populate([
                {
                    path: 'currentMenager',
                    select: 'name lastname mail status phone picture',
                },
                {
                    path: 'availableMenagers',
                    select: 'name lastname mail status phone picture',
                },
            ])
            .send()
        commit('setRentInfoRegistry', registry.data.items)
    },

    async updateRentInfoStatus({ commit, dispatch }, payload) {
        commit('setRentInfoModal')
        commit('setRentInfoStatus', payload.status)
        try {
            await new Rest2('PUT', `rent-info/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            dispatch('fetchLastRentInfo', { id: payload.id })
            commit('setSnackbars', { type: 'success' })
        } catch (err) {
            console.error(err)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async deleteRentInfo({ commit, dispatch }, payload) {
        try {
            await new Rest2('DELETE', `rent-info/${payload.id}`).send()
            dispatch('fetchRentInfoTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            console.error(err)
        }
    },
}

const mutations = {
    SET_CSV_FILE_LOADING(state, payload) {
        state.csvFileLoading = payload
    },
    setLastValue(state, payload) {
        state.lastValue = payload
    },
    setRentInfoTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || 1
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },

    setPreparedDataFromRentContractsItems(state, payload) {
        let loopArray = []
        for (let item of payload) {
            const selectedRepresntative = item.annex?.contractor?.representatives.filter(
                e => e._id === item.annex?.representative
            )

            if (selectedRepresntative?.length > 0) {
                let preparedData = {
                    index: item.index,
                    contractNo: item.contractNo,
                    contractorName: item.annex.contractor.name,
                    representativeName:
                        selectedRepresntative[0].name || 'Brak podanego',
                    representativeLastName:
                        selectedRepresntative[0].lastname || 'Brak podanego',
                    representativeEmail:
                        selectedRepresntative[0].email || 'Brak podanego',
                    representativePhoneNumber:
                        selectedRepresntative[0].phoneNumber || 'Brak podanego',
                }
                loopArray.push(preparedData)
            }
        }

        state.preparedDataFromRentContractsItems = loopArray
    },
    setRentIsToday(state, payload) {
        state.todayRent = payload
    },
    setRentInfoStatuses(state, payload) {
        state.statuses = payload
    },
    setRentInfoModal(state, payload) {
        let obj = payload ? payload : clone(state.lastValue)

        state.modal.fields = obj
        store.commit('setRepresentativesInput', {
            items: obj?.annex?.contractor?.representatives,
        })
        state.modal.fields.annex.representative = obj.annex?.contractor?.representatives?.find(
            e => e._id === obj.annex?.representative
        )
        state.modal.fields.annex.startDate = obj.annex?.startDate
            ? moment(obj.annex?.startDate).format('YYYY-MM-DD')
            : ''

        state.modal.fields.annex.expirationDate = obj.annex?.expirationDate
            ? moment(obj.annex?.expirationDate).format('YYYY-MM-DD')
            : ''
    },
    setRentInfoStatus(state, payload) {
        state.modal.fields.annex.status = payload
    },
    setRentInfoRegistry(state, payload) {
        state.registry = payload[0]
    },
    clearRentInfoModal() {
        ;(state.modal.fields = {
            contractNo: '',
            externalContractNo: '',
            annex: {
                title: '',
                contractAnnex: '',
                contractor: '',
                representative: {},
                contractType: '',
                responseTime: '',
                implementationCost: '',
                // monitoringCost: '',
                customerCarer: null,
                BIDProject: '',
                startBundle: null,
                monoBundle: '',
                colorBundle: '',
                colorBundleCost: '',
                constMonthlyBundle: null,
                constMonoBundle: '',
                constMonoBundlePrice: '',
                constColorBundle: '',
                constColorBundlePrice: '',
                expirationDate: '',
                accountingDay: '',
                lastAccountingDate: '',
                lastAccountingUser: null,
                lastAccountingStatus: '',
                notes: '',
            },
        }),
            (state.modal.step = 1)
    },
    clearRentInfoErrors(state) {
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step].fields) {
                state.modal.errors[step].fields[key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
    setRentInfoWhere(state, payload) {
        state.request.where = payload
    },
    clearRentInfoTable(state, payload) {
        clearSearch.clearSearchTable(state)
    },

    setRentInfoSearch(state, payload) {
        state.request.searchFields = payload
    },
    SET_CURRENT_TABLE_MODE(state, payload) {
        state.table.currentMode = payload
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
