var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tbody',[_vm._l((_vm.items),function(item,index){return [_c('tr',{key:'a' + index,class:`${_vm.isStatusClosed(item) ? 'disabled' : ''}`,on:{"click":function($event){return _vm.closeRows(item)}}},[_c('td',{staticClass:"truncate"},[_vm._v(" "+_vm._s(_vm.$get(item, 'contract[0].contractNo') || _vm.$get( item, 'contract[0].externalContractNo', _vm.$t('global:empty') ))+" ")]),_c('td',{staticClass:"truncate"},[_vm._v(" "+_vm._s(_vm.$get(item, 'contractAnnexNo'))+" ")]),_c('td',{staticClass:"truncate"},[_c('DoubleLine',{attrs:{"firtstLine":item.startDate
                            ? _vm.$moment(item.startDate).format(`DD/MM/YYYY`)
                            : _vm.$t('global:empty')}})],1),_c('td',{staticClass:"truncate"},[_c('DoubleLine',{attrs:{"firtstLine":item.expirationDate
                            ? _vm.$moment(item.expirationDate).format(
                                  `DD/MM/YYYY`
                              )
                            : _vm.$t('global:empty')}})],1),_c('td',{staticClass:"truncate"},[_vm._v(" "+_vm._s(_vm.getDevicesInRentAnnex(item))+" ")]),_c('td',{staticClass:"truncate"},[_vm._v(" "+_vm._s(`${_vm.$get( item, 'customerCarer.name', _vm.$t('global:empty') )} ${_vm.$get(item, 'customerCarer.lastname', '')}`)+" ")]),_c('td',[_c('v-chip',{style:(`color: ${_vm.$get(
                        item,
                        'status.color.font',
                        '#FFFFFF'
                    )}`),attrs:{"color":_vm.$get(item, 'status.color.background', '#555555'),"dark":"","label":"","small":""}},[_vm._v(_vm._s(_vm.$get(item, 'status.name', _vm.$t('users:empty'))))])],1),_c('td',{staticClass:"d-flex align-center justify-end"},[_c('SettlementButton',{attrs:{"item":item,"closeRows":_vm.closeRows}}),(item.status.state != 'CLOSED')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","disabled":_vm.disableDeviceAdding()},on:{"click":function($event){return _vm.onAddDevice(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-plus-circle-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Dodaj urządzenie do umowy/aneksu")])]):_vm._e(),(item.status.state != 'CLOSED')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.onEditAnnex(item._id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary","small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edytuj umowę/aneks")])]):_vm._e(),(item.status.state != 'CLOSED')?_c('v-menu',{attrs:{"nudge-left":"90","nudge-top":"50","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"iconGray","small":""}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('CSVFileButtons',{attrs:{"item":item}})],1)],1):_vm._e(),_c('v-btn',{attrs:{"small":"","icon":""}},[_c('v-icon',{attrs:{"id":item._id},on:{"click":function($event){return _vm.onExpand(item)}}},[_vm._v("$expand")])],1)],1)]),_c('tr',{key:'b' + index},[(_vm.isExpanded(item))?_c('td',{attrs:{"colspan":_vm.headers.length,"id":"CollapsedTable"}},[(
                        _vm.getRentDeviceTable.fetched &&
                        _vm.getRentDeviceTable.items.length > 0
                    )?_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.deviceHeaders,"items":_vm.getRentDeviceTable.items,"items-per-page":-1},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [_c('Columns',{attrs:{"lastAnnex":_vm.findLastAnnex(),"items":items,"item":item,"loading":_vm.loading},on:{"settlement-modal":_vm.settlementModal,"onOpenEdit":_vm.onEditDevice}})]}}],null,true)}):_vm._e(),(!_vm.getRentDeviceTable.fetched)?_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.deviceHeaders,"loading":""}}):_vm._e(),(
                        _vm.getRentDeviceTable.fetched &&
                        _vm.getRentDeviceTable.items.length <= 0
                    )?_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.deviceHeaders,"items":[],"no-data-text":_vm.$t('global:empty')}}):_vm._e()],1):_vm._e()])]}),_c('ConfirmModal',{attrs:{"openConfirm":_vm.openConfirm},on:{"close-confirm":_vm.closeConfirmModal}},[_c('div',{attrs:{"slot":"main"},slot:"main"},[_c('ConfirmContent')],1),_c('div',{attrs:{"slot":"buttons"},slot:"buttons"},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"white--text mx-2",attrs:{"color":"rgb(117, 117, 117)","width":"110","height":"40"},on:{"click":function($event){return _vm.closeConfirmModal()}}},[_vm._v(_vm._s(_vm.$t('global:cancel')))]),_c('v-btn',{staticClass:"white--text mx-2",attrs:{"width":"110","height":"40","color":"#445d75"},on:{"click":function($event){return _vm.deleteItem()}}},[_vm._v(_vm._s(_vm.$t('global:remove')))])],1)])]),_c('Modal',{attrs:{"title":_vm.$t('rent-contracts:rent-annex.addDevice'),"open":_vm.open,"height":"1100","width":"1000"},on:{"close":_vm.closeModal}},[_c('AEContent',{attrs:{"slot":"AEContent"},slot:"AEContent"}),_c('Buttons',{attrs:{"slot":"buttons","target":_vm.targetItem,"action":_vm.GlobalActions.create},on:{"closeModal":function($event){_vm.open = false}},slot:"buttons"})],1),_c('Modal',{attrs:{"title":_vm.$t('rent-contracts:rent-annex.editDevice'),"open":_vm.openEdit,"height":"1000","width":"1000"},on:{"close":_vm.closeModal}},[_c('AEContent',{attrs:{"slot":"AEContent","edit":""},slot:"AEContent"}),_c('Buttons',{attrs:{"slot":"buttons","target":_vm.targetItem,"action":_vm.GlobalActions.update},on:{"closeModal":function($event){_vm.openEdit = false}},slot:"buttons"})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }