import Rest from '../../../plugins/Rest'

const state = {
    request: {
        populate: [],
        select:
            'user.name user.lastname fieldName fieldValue action itemId date tab file folder mail',
        searchFields: 'fieldName action tab folder',
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: '-date',
        sort: '',
    },
}

const getters = {
    getLogsTable: state => state.table,
}

const actions = {
    async fetchLogs({ state, commit }, payload) {
        let response = await new Rest('GET', 'logs')
            .select(state.request.select)
            .limit(state.table.limit)
            .page(state.table.page)
            .search(state.request.searchFields, state.table.search)
            .where({ itemId: payload.id })
            .addParams({ url: payload.url })
            .sort(state.table.sort || state.table.defaultSort)
            .populate(state.request.populate)
            .send()
        commit('setLogsTable', response.data)
    },
}

const mutations = {
    setLogsTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
}

export default { state, getters, mutations, actions }
