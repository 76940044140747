import Rest from './../../../plugins/Rest'
import clone from 'lodash.clone'
import i18n from './../../../plugins/i18next'
import moment from 'moment'
const state = {
    modal: {
        fields: {
            name: '',
            lastname: '',
            position: '',
            phoneNumber: '',
            email: '',
            dayOfBirth: '',
            dayOfName: '',
            comments: '',
            isDisabled: false,
        },
        errors: {
            stepOne: {
                validation: true,
                name: '',
                lastname: '',
                position: '',
                phoneNumber: '',
                email: '',
                dayOfBirth: '',
                dayOfName: '',
                comments: '',
            },
        },
        steps: '',
    },
    details: {
        name: '',
        lastname: '',
        position: '',
        phoneNumber: '',
        email: '',
        dayOfBirth: '',
        dayOfName: '',
        comments: '',
    },
    request: {
        populate: [],
        searchFields: ['lastname', 'name'],
        select: '',
        where: {},
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: 'lastname',
    },
    input: {
        items: [],
        search: '',
        limit: 0,
        sort: 'lastname',
        defaultSort: 'lastname',
    },
}

const getters = {
    getRepresentativeModal: state => state.modal.fields,
    getRepresentativeError: state => state.modal.errors,
    getRepresentativeInput: state => state.input,
    getRepresentativesDetails: state => state.details,
    getRepresentativesTable: state => state.table,
}

const actions = {
    async fetchRepresentativesTable({ commit, dispatch }, payload) {
        try {
            let response = await new Rest(
                'GET',
                `contacts/${payload.id}/representatives`
            )
                .select(state.request.select)
                .limit(state.table.limit)
                .page(state.table.page)
                .search(state.request.searchFields, state.table.search)
                .where(state.request.where)
                .populate(state.request.populate)
                .sort(state.table.sort || state.table.defaultSort)
                .send()
            commit('setRepresentativesTable', response.data)
            if (payload.next) payload.next()
        } catch (error) {
            console.error(error)
        }
    },
    async fetchRepresentativesInput({ commit, dispatch }, payload) {
        try {
            let response = await new Rest(
                'GET',
                `contacts/${payload.id}/representatives`
            )
                .select(state.request.select)
                .limit(payload.limit || state.input.limit)
                .search(state.request.searchFields, state.input.search)
                .where(payload.where || state.request.where)
                .populate(state.request.populate)
                .sort(
                    payload.sort || state.input.sort || state.input.defaultSort
                )
                .send()
            commit('setRepresentativesInput', response.data)
        } catch (err) {
            console.error(err)
        }
    },

    async createRepresentative({ state, commit, dispatch }, payload) {
        try {
            await new Rest('POST', `contacts/${payload.id}/representatives`)
                .setBody(state.modal.fields)
                .send()
                await commit('clearRepresentsativeModal'),
                commit('clearRepresentativesErrors'),
                await dispatch('fetchRepresentativesTable', { id: payload.id })
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            console.error(err)
            commit('setErrorsRepresentatives', err)
            commit('setSnackbars', { type: 'error' })
        }
    },

    async updateRepresentative({ state, commit, dispatch }, payload) {
        try {
            await new Rest(
                'PUT',
                `contacts/${payload.contactId}/representatives/${state.modal.fields._id}`
            )
                .setBody(state.modal.fields)
                .send()
            commit('clearRepresentativesErrors'),
                await dispatch('fetchRepresentativesTable', {
                    id: payload.contactId,
                })
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            console.error(err)
            commit('setErrorsRepresentatives', err)
            commit('setSnackbars', { type: 'error' })
        }
    },
}

const mutations = {
    setRepresentativesTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
    setRepresentativesInput(state, payload) {
        state.input.items = payload.items || state.input.items
        if (payload.search != undefined) state.input.search = payload.search
        state.input.limit = payload.limit || state.input.limit
        if (payload.sort != undefined) state.input.sort = payload.sort
        state.input.items = state.input.items.filter(e => !e.isDisabled)
    },
    setRepresentativeModal(state, payload) {
        let obj = payload ? clone(payload) : clone(state.details)
        state.modal.fields = obj
        state.modal.fields.dayOfName = obj.dayOfName
            ? moment(obj.dayOfName).format('YYYY-MM-DD')
            : ''
        state.modal.fields.dayOfBirth = obj.dayOfBirth
            ? moment(obj.dayOfBirth).format('YYYY-MM-DD')
            : ''
    },
    clearRepresentsativeModal(state, payload) {
        state.modal.fields = {
            name: '',
            lastname: '',
            position: '',
            phoneNumber: '',
            email: '',
            dayOfBirth: '',
            dayOfName: '',
            comments: '',
            isDisabled: false,
        }
    },
    clearRepresentativesErrors() {
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step]) {
                state.modal.errors[step][key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
    clearRepresentativesInput(state) {
        state.input.items = []
    },
    setErrorsRepresentatives(state, payload) {
        for (const step in state.modal.errors) {
            for (const y in state.modal.errors[step]) {
                state.modal.errors[step][y] = ''
                state.modal.errors[step].validation = true
            }
            for (const x in payload.response.data.errors) {
                let number = /\d+/
                state.modal.errors[step][
                    x.replace(`representatives.${x.match(number)}.`, '')
                ] = `${i18n.t('global:errorFields')}!`
                state.modal.errors[step].validation = false
            }
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
