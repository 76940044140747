<template>
    <div>
        <v-btn
            @click="action == 'create' ? save() : edit()"
            :disabled="disabled"
            class="buttons buttons--add"
        >
            {{ $t('global:save') }}
        </v-btn>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        contractId: {
            type: String,
        },
        action: {
            type: String,
        },
        id: {
            type: String,
        },
    },
    data() {
        return {
            disabled: false,
            settelmentID: this.id,
        }
    },

    methods: {
        ...mapActions([
            'createRentSettlement',
            'updateRentSettlement',
            'fetchRentSettlementInstanceTable',
            'fetchRentAnnexTable',
            'fetchRentSettlementTable',
        ]),

        async save() {
            this.disabled = true
            let success = await this.createRentSettlement({
                id: this.$route.params.id,
                contractId: this.contractId,
            })
            if (success) {
                this.fetchRentAnnexTable({
                    id: this.$route.params.id,
                })
                this.$emit('closeModal')
            }
            this.disabled = false
        },
        async edit() {
            this.disabled = true
            let success = await this.updateRentSettlement()
            if (success) {
                this.fetchRentSettlementTable({ instanceId: this.settelmentID })
                this.fetchRentSettlementInstanceTable({
                    id: this.$route.params.id,
                })
                this.$emit('closeModal')
            }
            this.disabled = false
        },
    },
}
</script>
