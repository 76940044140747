var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.getAlertStep.step),callback:function ($$v) {_vm.$set(_vm.getAlertStep, "step", $$v)},expression:"getAlertStep.step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () => _vm.getAlertErrors.stepOne.validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('alerts:detailsAlerts')))]),_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () => _vm.getAlertErrors.stepTwo.validation,
                            ],"editable":"","step":"2"}},[_vm._v(_vm._s(_vm.$t('alerts:assignPersons')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('alerts:addNewAlerts')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                on,
                                                attrs,
                                            }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"data-cy":"alertDate","clearable":"","label":`${_vm.$t(
                                                    'alerts:alertDate'
                                                )}*`,"prepend-icon":"mdi-calendar","readonly":"","error-messages":_vm.getAlertErrors.stepOne
                                                        .fields.alertDate},on:{"click:clear":function($event){_vm.getAlertModal.alertDate = null}},model:{value:(
                                                    _vm.getAlertModal.alertDate
                                                ),callback:function ($$v) {_vm.$set(_vm.getAlertModal, "alertDate", $$v)},expression:"\n                                                    getAlertModal.alertDate\n                                                "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}},[_c('v-date-picker',{attrs:{"data-cy":"datePicker","first-day-of-week":"1","min":new Date()
                                                    .toISOString()
                                                    .substr(0, 10)},on:{"input":function($event){_vm.data1 = false}},model:{value:(
                                                _vm.getAlertModal.alertDate
                                            ),callback:function ($$v) {_vm.$set(_vm.getAlertModal, "alertDate", $$v)},expression:"\n                                                getAlertModal.alertDate\n                                            "}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-menu',{ref:"timer",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({attrs:{"data-cy":"alertTime","label":`${_vm.$t(
                                                    'alerts:alertTimie'
                                                )}*`,"disabled":!_vm.getAlertModal.alertDate,"prepend-icon":"mdi-clock-outline","readonly":"","error-messages":_vm.getAlertErrors.stepOne
                                                        .fields.alertTime},model:{value:(
                                                    _vm.getAlertModal.alertTime
                                                ),callback:function ($$v) {_vm.$set(_vm.getAlertModal, "alertTime", $$v)},expression:"\n                                                    getAlertModal.alertTime\n                                                "}},on))]}}]),model:{value:(_vm.timePicker),callback:function ($$v) {_vm.timePicker=$$v},expression:"timePicker"}},[(_vm.timePicker)?_c('v-time-picker',{attrs:{"data-cy":"timePicker","min":_vm.getAlertModal.alertDate == _vm.$moment().format(
                                                    'YYYY-MM-DD'
                                                ) ? _vm.$moment().format(`HH:mm`) : null,"full-width":"","format":"24hr"},on:{"click:minute":function($event){return _vm.$refs.timer.save(
                                                    _vm.getAlertModal.alertTime
                                                )}},model:{value:(
                                                _vm.getAlertModal.alertTime
                                            ),callback:function ($$v) {_vm.$set(_vm.getAlertModal, "alertTime", $$v)},expression:"\n                                                getAlertModal.alertTime\n                                            "}}):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-textarea',{attrs:{"data-cy":"alertMessage","label":_vm.$t('alerts:contents'),"rows":"1","maxlength":_vm.maxlength,"error-messages":this.getAlertModal.description
                                                .length >= _vm.maxlength
                                                ? `${_vm.$t(
                                                      'alerts:maxLength'
                                                  )}`
                                                : '',"no-resize":""},model:{value:(_vm.getAlertModal.description),callback:function ($$v) {_vm.$set(_vm.getAlertModal, "description", $$v)},expression:"getAlertModal.description"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","offset":"8"}},[_c('v-select',{attrs:{"label":_vm.$t('alerts:priority'),"items":_vm.getRegistryDictionaries.alerts
                                                ? _vm.getRegistryDictionaries
                                                      .alerts.priority
                                                : [],"return-object":"","item-value":"field"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(_vm.getAlertModal.priority),callback:function ($$v) {_vm.$set(_vm.getAlertModal, "priority", $$v)},expression:"getAlertModal.priority"}},[_c('template',{slot:"append-outer"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                        on,
                                                    }){return [_c('v-icon',_vm._g({staticClass:"mt-4 cursor-pointer",attrs:{"color":"#bbb","size":"15"},on:{"click":function($event){_vm.getAlertModal.priority =
                                                                ''}}},on),[_vm._v("mdi-window-close")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('alerts:clear')))])])],1)],2)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"border--green"},[_c('p',{staticClass:"ma-0 ml-5 primary--text"},[_vm._v(" "+_vm._s(_vm.$t('alerts:alertOptions'))+" ")])])])],1),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-wrap mt-2",attrs:{"cols":"5"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"textGrey--text"},[_c('v-icon',[_vm._v("mdi-calendar-refresh-outline")]),_vm._v(" "+_vm._s(_vm.$t('alerts:repeat')))],1)]),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"4","md":"4"}},[_c('v-text-field',{attrs:{"error-messages":_vm.getAlertErrors.stepOne
                                                    .fields.repetitionNumber,"label":_vm.$t('alerts:number')},on:{"change":function($event){_vm.getAlertModal.repetitionNumber ==
                                                ''
                                                    ? (_vm.getAlertModal.repetitionTime =
                                                          '')
                                                    : _vm.getAlertModal.repetitionNumber &&
                                                      (_vm.getAlertModal.repetitionTime =
                                                          _vm.time[0])},"keyup":function($event){return _vm.checkNumber(
                                                    $event,
                                                    'repetitionNumber'
                                                )}},model:{value:(
                                                _vm.getAlertModal.repetitionNumber
                                            ),callback:function ($$v) {_vm.$set(_vm.getAlertModal, "repetitionNumber", $$v)},expression:"\n                                                getAlertModal.repetitionNumber\n                                            "}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"8","md":"8"}},[_c('v-select',{attrs:{"items":_vm.time,"item-text":"name","disabled":!_vm.getAlertModal.repetitionNumber,"label":_vm.$t('alerts:periodOfTime')},model:{value:(
                                                _vm.getAlertModal.repetitionTime
                                            ),callback:function ($$v) {_vm.$set(_vm.getAlertModal, "repetitionTime", $$v)},expression:"\n                                                getAlertModal.repetitionTime\n                                            "}},[_c('template',{slot:"append-outer"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                            on,
                                                        }){return [_c('v-icon',_vm._g({staticClass:"mt-4 cursor-pointer",attrs:{"size":"15","color":"#bbb"},on:{"click":function($event){;(_vm.getAlertModal.repetitionTime =
                                                                    ''),
                                                                    (_vm.getAlertModal.repetitionNumber =
                                                                        '')}}},on),[_vm._v("mdi-window-close")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('alerts:clear')))])])],1)],2)],1)],1),_c('v-col',{staticClass:"d-flex flex-wrap pb-0 mt-2",attrs:{"cols":"5"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"textGrey--text"},[_c('v-icon',[_vm._v("mdi-bell")]),_vm._v(" "+_vm._s(_vm.$t('alerts:notifyBefore')))],1)]),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"4","md":"4"}},[_c('v-text-field',{attrs:{"error-messages":_vm.getAlertErrors.stepOne
                                                    .fields.reminderNumber,"label":_vm.$t('alerts:number')},on:{"change":function($event){_vm.getAlertModal.reminderNumber ==
                                                ''
                                                    ? (_vm.getAlertModal.reminderTime =
                                                          '')
                                                    : _vm.getAlertModal.reminderNumber},"keyup":function($event){return _vm.checkNumber(
                                                    $event,
                                                    'reminderNumber'
                                                )}},model:{value:(
                                                _vm.getAlertModal.reminderNumber
                                            ),callback:function ($$v) {_vm.$set(_vm.getAlertModal, "reminderNumber", $$v)},expression:"\n                                                getAlertModal.reminderNumber\n                                            "}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"8","md":"8"}},[_c('v-select',{attrs:{"items":_vm.time,"item-text":"name","disabled":!_vm.getAlertModal.reminderNumber,"label":_vm.$t('alerts:periodOfTime')},model:{value:(
                                                _vm.getAlertModal.reminderTime
                                            ),callback:function ($$v) {_vm.$set(_vm.getAlertModal, "reminderTime", $$v)},expression:"\n                                                getAlertModal.reminderTime\n                                            "}},[_c('template',{slot:"append-outer"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                            on,
                                                        }){return [_c('v-icon',_vm._g({staticClass:"mt-4 cursor-pointer",attrs:{"color":"#bbb","size":"15"},on:{"click":function($event){;(_vm.getAlertModal.reminderTime =
                                                                    ''),
                                                                    (_vm.getAlertModal.reminderNumber =
                                                                        '')}}},on),[_vm._v("mdi-window-close")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('alerts:clear')))])])],1)],2)],1)],1),_c('v-col',[_c('v-checkbox',{attrs:{"label":_vm.$t('alerts:active')},model:{value:(_vm.getAlertModal.active),callback:function ($$v) {_vm.$set(_vm.getAlertModal, "active", $$v)},expression:"getAlertModal.active"}})],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('alerts:assignTo')}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-5",attrs:{"cols":"3","md":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"textGrey--text",attrs:{"small":"","elevation":"0","color":"white"},on:{"click":function($event){return _vm.toMe()}}},on),[_c('v-icon',{attrs:{"color":"textGrey","size":"35"}},[_vm._v("mdi-account")]),_vm._v(_vm._s(_vm.$t('alerts:toMe')))],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('alerts:toMe')))])])],1),_c('v-col',{staticClass:"mt-5",attrs:{"cols":"3","md":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"textGrey--text",attrs:{"small":"","elevation":"0","color":"white"},on:{"click":function($event){return _vm.toAllUsers()}}},on),[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"textGrey","size":"35"}},[_vm._v("mdi-account-group")]),_vm._v(_vm._s(_vm.$t('alerts:allUsers')))],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('alerts:allUsers')))])])],1),_c('v-col',{attrs:{"cols":"12","offset":"1","md":"7"}},[_c('v-autocomplete',{staticClass:"box-autocomplete",attrs:{"data-cy":"assignTo","label":_vm.$t('alerts:to'),"item-value":"_id","items":_vm.value === 'users'
                                                ? _vm.getUsersList
                                                : _vm.getAllDepartments,"error-messages":_vm.getAlertErrors.stepTwo.fields.to,"return-object":"","multiple":"","filter":_vm.filter},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [(_vm.value === 'users')?_c('div',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(` ${data.item.name} ${data.item.lastname},`)+" ")]):_c('div',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(`${data.item.name},`)+" ")])]}},{key:"item",fn:function(data){return [(_vm.value === 'users')?_c('v-list-item-avatar',[_c('v-avatar',{staticClass:"mx-2",attrs:{"size":"35","color":"secondary"}},[(
                                                            !data.item
                                                                .picture
                                                        )?_c('span',{staticClass:"white--text central__avatarFont"},[_vm._v(" "+_vm._s(_vm.avatarNick( data.item .name, data.item .lastname ))+" ")]):_c('img',{attrs:{"src":`${data.item.picture}`}})])],1):_c('v-list-item-avatar',[_c('v-avatar',{attrs:{"size":"35","color":"#445D75"}},[_c('span',{staticClass:"white--text central__avatarFont"},[_vm._v(" "+_vm._s(data.item.shortName.toUpperCase())+" ")])])],1),(_vm.value === 'users')?_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(data.item.lastname)+" "+_vm._s(data.item.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(data.item.job)+" ")])],1):_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(data.item.name)+" ")])],1)]}}]),model:{value:(_vm.getAlertModal.to[_vm.value]),callback:function ($$v) {_vm.$set(_vm.getAlertModal.to, _vm.value, $$v)},expression:"getAlertModal.to[value]"}},[_c('v-tabs',{staticClass:"elevation-1 select--fields",attrs:{"slot":"prepend-item","centered":""},slot:"prepend-item"},[_c('v-tab',{on:{"click":function($event){_vm.value = 'users'}}},[_vm._v(_vm._s(_vm.$t('alerts:users')))]),_c('v-tab',{on:{"click":function($event){_vm.value = 'departments'}}},[_vm._v(_vm._s(_vm.$t('alerts:departments')))])],1)],1)],1)],1),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"border--green"},[_c('p',{staticClass:"ma-0 ml-5 primary--text"},[_vm._v(" "+_vm._s(_vm.$t('alerts:alertsTo'))+" ")])])]),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"list-box"},[_c('span',{staticClass:"list-box__label"},[_vm._v(_vm._s(_vm.$t('alerts:users')))]),_c('div',{staticClass:"list-box__content"},_vm._l((_vm.getAlertModal.to
                                                    .users),function(item,index){return _c('div',{key:index,staticClass:"d-flex align-center mx-2 list-box__users"},[_c('v-avatar',{attrs:{"size":"40","color":"secondary"}},[(item.picture)?_c('img',{attrs:{"src":item.picture,"alt":"John"}}):_c('span',{staticClass:"white--text body-2"},[_vm._v(_vm._s(item.name[0])+_vm._s(item.lastname[0]))])]),_c('div',[_c('span',{staticClass:"mx-2 body-2"},[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"body-2"},[_vm._v(_vm._s(item.lastname))])])],1)}),0)])]),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"list-box"},[_c('span',{staticClass:"list-box__label"},[_vm._v(_vm._s(_vm.$t('alerts:departments')))]),_c('div',{staticClass:"list-box__content"},_vm._l((_vm.getAlertModal.to
                                                    .departments),function(item,index){return _c('div',{key:index,staticClass:"d-flex align-center mx-2 list-box__users"},[_c('v-avatar',{attrs:{"size":"40","color":"primary"}},[_c('span',{staticClass:"white--text body-2"},[_vm._v(_vm._s(item.shortName))])]),_c('div',[_c('span',{staticClass:"mx-2 body-2"},[_vm._v(_vm._s(item.name))])])],1)}),0)])])],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }