import clone from 'lodash.clone'
import Rest from '../../../plugins/Rest'
import { get } from 'lodash'

const state = {
    modal: {
        fields: {
            objectId: '',
            name: '',
            objectType: '',
            notes: '',
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {
                    objectId: '',
                },
            },
        },
        step: 1,
    },
    request: { searchFields: ['objectId.text', 'name'] },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 10,
        pages: 1,
        defaultSort: '-createdAt',
    },
    dictFields: {},
    statuses: [],
    details: {},
    registry: {},
}

const getters = {
    getLeasingStuffModal: state => state.modal.fields,
    getLeasingStuffError: state => state.modal.errors,
    getLeasingStuffStep: state => state.modal,
    getLeasingStuffTable: state => state.table,
    getLeasingStuffStatuses: state => state.statuses,
    getLeasingStuffSearchFields: state => state.request,
    getLeasingStuffDetails: state => state.details,
}

const actions = {
    async searchObject({}, { data } = payload) {
        const response = await new Rest('GET', 'findLeasingObject')
            .addParams({ data })
            .send()
        return response.data.items
    },

    async fetchLeasingStuffTable({ rootGetters, commit, dispatch }, payload) {
        await dispatch('fetchLeasing', { id: payload.id, next: payload.next })
        let data = rootGetters.getLeasingDetails?.leasingStuff
        if (data && data.length > 0) {
            data = data
                .filter(e =>
                    e.car?.isDeleted || e.device?.isDeleted ? false : true
                )
                .map(e => {
                    return {
                        ...e,
                        objectId: { text: e.car?.vin || e.device?.sn },
                        name: e.car?.model || e.device?.model?.model,
                        objectType:
                            e.car?.typeOfCar || e.device?.model?.deviceType,
                    }
                })
            data = state.request.searchFields.flatMap(e =>
                data.filter(el =>
                    get(el, e, '')
                        ?.toLowerCase()
                        .includes(state.table.search?.toLowerCase())
                )
            )
            data = data.filter((val, idx, arr) => {
                return arr.indexOf(val) === idx
            })
        }
        commit('setLeasingStuffTable', { items: data })
    },

    async editLeasingStuff({ state, dispatch, commit, rootGetters }, payload) {
        const leasingStuff = rootGetters.getLeasingDetails.leasingStuff
        leasingStuff[
            leasingStuff.indexOf(
                leasingStuff.find(e => e._id === state.modal.fields._id)
            )
        ] = state.modal.fields
        const tmp = { leasingStuff: leasingStuff }

        try {
            await new Rest('PUT', `leasings/${payload.id}`).setBody(tmp).send()
            await dispatch('fetchLeasingStuffTable', { id: payload.id })
            commit('clearLeasingStuffModal')
            commit('clearLeasingStuffErrors')
            return true
        } catch (err) {
            //HANDLE ERRORS
            console.error(err)
            commit('setSnackbars', { type: 'error' })
        }
    },

    async createLeasingStuff(
        { state, dispatch, commit, rootGetters },
        payload
    ) {
        const leasing = rootGetters.getLeasingDetails.leasingStuff
        let tmp = {}
        if (leasing && leasing.length > 0) {
            tmp = { leasingStuff: [...leasing, state.modal.fields] }
        } else {
            tmp = { leasingStuff: [state.modal.fields] }
        }

        try {
            await new Rest('PUT', `leasings/${payload.id}`).setBody(tmp).send()

            if (Object.keys(state.modal.fields).includes('device')) {
                const { _id } = state.modal.fields.objectId.value
                const leasingId = rootGetters.getLeasingDetails._id
                const value = { anotherDevice: { leasingAgreement: leasingId } }
                await new Rest('PUT', `devices/${_id}`).setBody(value).send()
            }

            if (Object.keys(state.modal.fields).includes('car')) {
                const { _id } = state.modal.fields.objectId.value
                const leasingId = rootGetters.getLeasingDetails._id
                const value = { leasing: leasingId }
                await new Rest('PUT', `cars/${_id}`).setBody(value).send()
            }

            await dispatch('fetchLeasingStuffTable', { id: payload.id })
            commit('clearLeasingStuffModal')
            commit('clearLeasingStuffErrors')
            return true
        } catch (err) {
            //HANDLE ERRORS
            console.error(err)
            commit('setSnackbars', { type: 'error' })
        }
    },

    async deleteLeasingStuff(
        { state, dispatch, commit, rootGetters },
        payload
    ) {
        let leasing = rootGetters.getLeasingDetails.leasingStuff
        const deletedItem = leasing.find(el => el._id === payload.itemId)
        leasing = leasing.filter(el => el._id !== payload.itemId)
        try {
            await new Rest('PUT', `leasings/${payload.id}`)
                .setBody({ leasingStuff: leasing })
                .send()
            const deviceValue = { anotherDevice: { leasingAgreement: null } }

            if (Object.keys(deletedItem).includes('device')) {
                await new Rest('PUT', `devices/${deletedItem.device._id}`)
                    .setBody(deviceValue)
                    .send()
            }
            const value = { leasing: null }

            if (Object.keys(deletedItem).includes('car')) {
                await new Rest('PUT', `cars/${deletedItem.car._id}`)
                    .setBody(value)
                    .send()
            }
            dispatch('fetchLeasingStuffTable', { id: payload.id })
            commit('clearLeasingStuffModal')
            commit('clearLeasingStuffErrors')
            return true
        } catch (err) {
            //HANDLE ERRORS
            console.error(err)
            commit('setSnackbars', { type: 'error' })
        }
    },
}

const mutations = {
    setLeasingStuffTable(state, payload) {
        state.table.total = payload.items?.length || state.table.total
        state.table.items = payload.items || state.table.items
        state.table.items = state.table.items.slice(
            0 + state.table.limit * (state.table.page - 1),
            state.table.limit * state.table.page
        )
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
    setLeasingStuffRegistry(state, payload) {
        state.registry = payload[0]
    },
    setLeasingStuffSearch(state, payload) {
        state.request.searchFields = payload
    },
    setLeasingStuffDetails(state, payload) {
        state.details = payload
    },
    setLeasingStuffStatuses(state, payload) {
        state.statuses = payload
    },
    setLeasingStuffStatus(state, payload) {
        state.modal.fields.status = payload
    },
    setLeasingStuffModal(state, payload) {
        let obj = payload ? payload : clone(state.details)
        state.modal.fields = obj
        state.modal.fields.objectId = {
            text: obj.objectId.text,
            value: obj?.car || obj?.device,
        }
    },
    clearLeasingStuffModal(state, payload) {
        state.modal.step = 1
        state.modal.fields = {
            objectId: '',
            name: '',
            objectType: '',
            notes: '',
        }
    },
    clearLeasingStuffErrors(state) {
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step].fields) {
                state.modal.errors[step].fields[key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
    clearLeasingStuffTable(state) {
        state.table = {
            items: [],
            total: '',
            search: '',
            page: 1,
            limit: 10,
            pages: 1,
            defaultSort: '-createdAt',
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
