export const numberRules = v => {
    if (v) {
        if (!v.trim()) return true
        if (v.match('^[0-9]+$')) return true
        return 'Wymagana liczba'
    }
    return true
}

export const nipMask = [
    /[0-9A-Z]/,
    /[0-9A-Z]/,
    /[0-9A-Z]/,
    /[0-9A-Z]/,
    /[0-9A-Z]/,
    /[0-9A-Z]/,
    /[0-9A-Z]/,
    /[0-9A-Z]/,
    /[0-9A-Z]/,
    /[0-9A-Z]/,
    /[0-9A-Z]/,
    /[0-9A-Z]/,
    /[0-9A-Z]/,
    /[0-9A-Z]/,
    /[0-9A-Z]/,
]
