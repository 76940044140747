<template>
    <div v-if="items.length > 0 && this.$route.query.isPrice == 'true'" class="page">
        <!-- v-if="this.data" -->
        <div class="subpage">
            <div class="header">
                <div class="logo">
                    <img
                        v-if="getSettingsDetails.logo"
                        :src="`${path}/client_logo.png`"
                        alt="entelli logo"
                    />
                </div>
                <div class="header__order-wrapper">
                    <div class="header__order-wrapper-info">
                        <span>ID:</span>
                        <span>
                            {{
                                `${$get(getServiceDetails, 'index', '')}`
                            }}</span
                        >
                    </div>
                </div>
            </div>
            <div class="title">
                <div class="title__block-1"></div>
                <div class="title__block-2">
                    <span>PROTOKÓŁ USŁUGI SERWISU</span>
                </div>
                <div class="title__block-3"></div>
            </div>
            <div class="central-table-wrapper">
                <div style="font-size: 15px; margin-bottom: 15px;">
                    <p>Zestawienie części:</p>
                </div>
                <table class="counter">
                    <tr>
                        <th class="column-to-center">Lp.</th>
                        <th class="column-to-left">Nazwa towaru</th>
                        <th>Ilość</th>
                        <th>J.m</th>
                        <th>VAT</th>
                        <th>Cena netto</th>
                        <th>Wartość brutto</th>
                    </tr>
                    <tr
                        class="main-counter"
                        v-for="(item, index) in items"
                        :key="index"
                    >
                        <td class="column-to-center"></td>
                        <td class="column-to-left">{{ item.name }}</td>
                        <td>{{ item.quantity }}</td>
                        <td>szt.</td>
                        <td>{{ `${vat}%` }}</td>
                        <td>
                            {{ (item.price * item.quantity).toFixed(2) }} PLN
                            <!-- <money
                                v-bind="money"
                                readonly
                                :value="item.price * item.quantity"
                            ></money> -->
                        </td>
                        <td>
                            {{ (item.price * item.quantity * 1.23).toFixed(2) }}
                            PLN
                            <!-- <money
                                v-bind="money"
                                readonly
                                :value="item.price * item.quantity * 1.23"
                            ></money> -->
                        </td>
                    </tr>
                </table>
            </div>
            <div class="footer">
                <span>{{ getSettingsDetails.website }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import store from '../../../../store'

export default {
    data() {
        return {
            path: `${process.env.VUE_APP_SERVER}/secured/logo/`,
            vat: 23,
            items: [],
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: ' PLN',
                precision: 2,
                masked: false,
            },
        }
    },

    computed: {
        ...mapGetters(['getServiceDetails', 'getSettingsDetails']),
    },
    methods: {
        setParts() {
            const arr = []
            if (this.getServiceDetails.parts) {
                for (
                    let index = 0;
                    index < this.getServiceDetails.parts.partsTable.length;
                    index++
                ) {
                    const element = this.getServiceDetails.parts.partsTable[
                        index
                    ]

                    if (element.wasUsed == true) {
                        arr.push(element)
                    }
                }
                this.items = arr
            }
        },
    },

    beforeMount() {
        store.dispatch('fetchSettings', {
            type: 'add',
        }),
            this.setParts()
    },
}
</script>

<style lang="scss" scoped>
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    color: #4a4a4a;
}

* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.page {
    width: 210mm;
    height: 297mm;
    margin: 10mm auto;
    border: 1px #d3d3d3 solid;
    border-radius: 5px;
    position: relative;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.mt-5 {
    margin-top: 15px;
}
tr > td > input {
    font-size: 1em !important;
    text-align: right;
    max-width: 80px;
}
.subpage {
    height: 100%;

    .header {
        padding-top: 40px;
        padding-left: 40px;
        padding-right: 30px;
        display: grid;
        grid-template-columns: 2fr 1fr;

        .logo {
            padding-left: 10px;

            img {
                max-width: 203px;
                max-height: 45px;
                display: block;
            }
        }

        &__invoice-wrapper-info {
            display: flex;
            justify-content: space-between;
            font-size: 0.625em;
        }
    }

    .header {
        &__order-wrapper {
            font-size: 0.75em;
        }
    }

    .title {
        margin-top: 30px;
        display: flex;
        font-size: 1.75em;
        font-weight: 400;

        &__block-1 {
            width: 500px;
            height: 50px;
            background-color: #4c517d;
            display: block;
        }

        &__block-2 {
            display: flex;
            height: 50px;
            padding-left: 34px;
            padding-right: 34px;
            line-height: 25px;
            width: 242px;
            text-align: center;
            font-size: 1em;
        }

        &__block-3 {
            height: 50px;
            float: left;
            flex-grow: 1;
            width: 90px;
            background-color: #4c517d;
            display: block;
        }
    }

    .central-table-wrapper {
        margin-left: 50px;
        margin-top: 50px;
        font-size: 1.2em;

        .column-to-left {
            text-align: start;
        }
        .column-to-center {
            text-align: center;
        }
        .mb-5 {
            margin-bottom: 10px;
        }
        .px-4 {
            padding: 0 10px;
        }
        table {
            border-collapse: collapse;
            border-spacing: 0;
            width: 95%;
            border: 1px solid rgb(128, 128, 128);
            font-size: 0.625em;
        }

        table {
            counter-reset: rowNumber;
        }

        table .main-counter {
            counter-increment: rowNumber;
        }

        .counter tr td:first-child::before {
            content: counter(rowNumber);
        }

        th {
            background-color: #4c517d;
            color: #f5f5f5;
        }

        th,
        td {
            text-align: end;
            height: 38px;
            max-height: 80px;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-right: 15px;
        }

        tr:nth-child(2n-1) {
            background-color: #f5f5f5;
        }
    }

    .footer {
        margin-left: -1px;
        width: 210mm;
        height: 25px;
        margin-bottom: 20px;
        color: white;
        text-align: center;
        position: absolute;
        bottom: 0;
        background-color: #4c517d;
        span {
            display: block;
            margin-top: 2px;
        }
    }
}

@page {
    size: A4;
    margin: 0;
}

@media print {
    html,
    body {
        width: 210mm;
        height: 297mm;
        padding: 0px !important;
    }

    .page {
        margin: 2px;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
        -webkit-print-color-adjust: exact;
    }

    .subpage {
        height: 256mm;
    }
}
</style>
