import Rest from '../../plugins/Rest'
import i18n from './../../plugins/i18next'
const state = {
    mailBody: {
        case: '',
        subject: '',
        mail: '',
        details: {},
    },
}

const getters = {
    getMailBody: state => state.mailBody,
    getMailDetails: state => state.mailBody.details,
}

const actions = {
    async sendMail({ state, commit }, payload) {
        try {
            const postSend = await new Rest('POST', '/mailService')
                .setBody(payload.mailBody || state.mailBody)
                .send()
       
            return true
        } catch (err) {
            console.log(err);
            return false
        }
    },
}

const mutations = {
    setMailBody(state, payload) {
        state.mailBody = payload
    },

    setMailDetails(state, payload) {
        state.mailBody.details = payload
    },
}

export default { state, getters, mutations, actions }
