import Rest from './../../../plugins/Rest'
import clone from 'lodash.clone'
import validation from './../../../plugins/validation'
const state = {
    modal: {
        fields: {
            note: '',
            isDisabled: '',
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {
                    note: '',
                },
            },
        },
    },
    request: {
        populate: [
            {
                path: 'createdBy',
                select: 'name lastname',
            },
        ],
        searchFields: ['note'],
        select: '',
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: '-createdAt',
        sort: 'note createdBy',
    },
    count: 0,
}

const getters = {
    getNoteModal: state => state.modal.fields,
    getNoteErrors: state => state.modal.errors,
    getNotesSearchFields: state => state.request,
    getNotesTable: state => state.table,
    getNotesSearchFields: state => state.request,
    getNotesCount: state => state.count,
}

const actions = {
    async createNote({ state, commit, dispatch }, payload) {
        try {
            await new Rest('POST', '/notes')
                .setBody({
                    ...state.modal.fields,
                    itemId: payload.id,
                    url: payload.url,
                })
                .send()
            await dispatch('fetchNotesTable', { id: payload.id })
            commit('setSnackbars', { type: 'success' })
            commit('clearNotesModal')
            commit('clearNoteErrors')
            return true
        } catch (err) {
            //HANDLE ERRORS
            commit('setSnackbars', { type: 'error' })
            validation.setErrors(state.modal.errors, err)
            console.error(err)
        }
    },
    async fetchNotesTable({ state, commit, dispatch }, payload) {
        try {
            let response = await new Rest('GET', '/notes')
                .select(state.request.select)
                .limit(state.table.limit)
                .page(state.table.page)
                .search(state.request.searchFields, state.table.search)
                .where({ itemId: payload.id })
                .populate(state.request.populate)
                .sort(state.table.sort || state.table.defaultSort)
                .send()
            commit('setNotesTable', response.data)
        } catch (err) {
            //HANDLE ERRORS
            console.error(err)
        }
    },

    // async fetchAlert({ state, commit, dispatch }, payload) {
    //     try {
    //         let response = await new Rest('GET', `/notes/${payload.id}`).send()
    //         commit('setNoteDetails', response.data)
    //     } catch (err) {
    //         //HANDLE ERRORS
    //         console.error(err)
    //     }
    // },

    async updateNote({ state, commit, dispatch }, payload) {
        try {
            await new Rest('PUT', `/notes/${payload.recId}`)
                .setBody({
                    ...state.modal.fields,
                    itemId: payload.id,
                    url: payload.url,
                })
                .populate([])
                .send()
            await dispatch('fetchNotesTable', { id: payload.id })
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            //HANDLE ERRORS
            commit('setSnackbars', { type: 'error' })
            validation.setErrors(state.modal.errors, err)
            console.error(err)
        }
    },

    async fetchNotesCount({ state, commit, dispatch }, payload) {
        try {
            let response = await new Rest(
                'GET',
                `notes/${payload.id}/count`
            ).send()
            commit('setNotesCount', response.data)
        } catch (error) {
            console.error(error)
        }
    },
}

const mutations = {
    clearNotesModal(state, payload) {
        state.modal.fields = {
            note: '',
        }
    },
    setNoteModal(state, payload) {
        let obj = payload ? clone(payload) : clone(state.details)
        state.modal.fields = obj
    },

    clearNoteErrors(state) {
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step].fields) {
                state.modal.errors[step].fields[key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
    setNotesTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
    setNotesCount(state, payload) {
        state.count = payload
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
