<template>
    <div class="page">
        <div class="subpage">
            <div class="header">
                <div class="logo">
                    <img
                        v-if="getSettingsDetails.logo"
                        :src="`${path}/client_logo.png`"
                        alt="entelli logo"
                    />
                </div>
                <div class="header__order-wrapper">
                    <div class="header__order-wrapper-info">
                        <span>ID:</span>
                        <span>
                            {{
                                `${$get(getServiceDetails, 'index', '')}`
                            }}</span
                        >
                    </div>
                    <div class="header__order-wrapper-info">
                        <span>Data przyjęcia:</span>
                        <span>
                            {{
                                $moment(getServiceDetails.createdAt).format(
                                    `DD-MM-YYYY`
                                )
                            }}
                        </span>
                    </div>
                    <div class="header__order-wrapper-info">
                        <span>Planowana data usługi:</span>
                        <span>
                            {{
                                `${
                                    getServiceDetails.plannedServiceDate
                                        ? $moment(
                                              getServiceDetails.plannedServiceDate
                                          ).format(`DD-MM-YYYY`)
                                        : 'Brak'
                                }
                                
                                `
                            }}</span
                        >
                    </div>
                    <div class="header__order-wrapper-info">
                        <span>Data realizacji usługi:</span>
                        <span>
                            {{
                                `${
                                    getServiceDetails.realization &&
                                    getServiceDetails.realization.endDate
                                        ? $moment(
                                              getServiceDetails.realization
                                                  .endDate
                                          ).format(`DD-MM-YYYY`)
                                        : 'Brak'
                                }
                                
                                `
                            }}</span
                        >
                    </div>
                </div>
            </div>
            <div class="title">
                <div class="title__block-1"></div>
                <div class="title__block-2">
                    <span>KARTA OBIEGOWA</span>
                </div>
                <div class="title__block-3"></div>
            </div>
            <div class="clientDescription">
                <div class="column-left">
                    <div class="row-1">
                        <div>
                            <span class="clientDescription--title"
                                >SPRZEDAWCA:</span
                            >
                        </div>
                        <div class="column-left__item-wrapper">
                            <span>{{ getSettingsDetails.companyName }}</span>
                            <span
                                >{{ `${$t('service:str')} `
                                }}{{ getSettingsDetails.companyAddress }}
                            </span>
                            <span>NIP: {{ getSettingsDetails.nip }}</span>
                            <span>{{
                                getSettingsDetails.companyServiceEmail
                            }}</span>
                            <span>{{ getSettingsDetails.website }}</span>
                            <div class="realizationPerson mt-5">
                                <div>
                                    Przyjmujący zlecenie:
                                    {{
                                        `${$get(
                                            getServiceDetails,
                                            'createdBy.name',
                                            ''
                                        )} ${$get(
                                            getServiceDetails,
                                            'createdBy.lastname',
                                            ''
                                        )}`
                                    }}
                                </div>
                                <div>
                                    Technik realizujący:
                                    {{
                                        `${$get(
                                            getServiceDetails,
                                            'technician.name',
                                            ''
                                        )} ${$get(
                                            getServiceDetails,
                                            'technician.lastname',
                                            ''
                                        )}`
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="column-right">
                    <div class="row-1">
                        <div>
                            <span class="clientDescription--title"
                                >KLIENT:
                                {{
                                    `${$get(
                                        getServiceDetails,
                                        'client.contactId',
                                        ''
                                    )}`
                                }}</span
                            >
                        </div>
                        <div class="column-right__item-wrapper">
                            <span>
                                {{
                                    `${$get(
                                        getServiceDetails,
                                        'client.name',
                                        ''
                                    )}`
                                }}
                            </span>
                            <span
                                >{{ `${$t('service:str')} ` }}
                                {{
                                    `${$get(
                                        getServiceDetails,
                                        'device.client.street',
                                        ''
                                    )}`
                                }}
                                {{
                                    `${$get(
                                        getServiceDetails,
                                        'device.client.houseNumber',
                                        ''
                                    )}`
                                }}
                                <span
                                    v-if="
                                        `${$get(
                                            getServiceDetails,
                                            'device.client.apartmentNumber',
                                            ''
                                        )}`
                                    "
                                    >/
                                    {{
                                        `${$get(
                                            getServiceDetails,
                                            'device.client.apartmentNumber',
                                            ''
                                        )}`
                                    }},</span
                                >

                                {{
                                    `${$get(
                                        getServiceDetails,
                                        'device.client.postCode',
                                        ''
                                    )}`
                                }}
                                {{
                                    `${$get(
                                        getServiceDetails,
                                        'device.client.city',
                                        ''
                                    )}`
                                }}
                            </span>
                            <span
                                >NIP:
                                {{
                                    `${$get(
                                        getServiceDetails,
                                        'device.client.NIP',
                                        ''
                                    )}`
                                }}
                            </span>
                            <span
                                >Zlecił:

                                <span
                                    v-if="
                                        getServiceDetails.createdByHelpDeskUsers
                                    "
                                >
                                    <span
                                        >{{
                                            `${$get(
                                                getServiceDetails,
                                                'createdByHelpDeskUsers.name',
                                                ''
                                            )} ${$get(
                                                getServiceDetails,
                                                'createdByHelpDeskUsers.lastName',
                                                ''
                                            )}
                                            ${$get(
                                                getServiceDetails,
                                                'createdByHelpDeskUsers.login',
                                                ''
                                            )}`
                                        }}
                                    </span>
                                    <span
                                        v-if="
                                            `${$get(
                                                getServiceDetails,
                                                'createdByHelpDeskUsers.phoneNumber',
                                                ''
                                            )}`
                                        "
                                        >{{ $t('service:tel')
                                        }}{{
                                            `${$get(
                                                getServiceDetails,
                                                'createdByHelpDeskUsers.phoneNumber',
                                                ''
                                            )}`
                                        }}</span
                                    >
                                    <span
                                        v-if="
                                            `${$get(
                                                getServiceDetails,
                                                'contractor.email',
                                                ''
                                            )}`
                                        "
                                    >
                                        {{ $t('service:email') }}
                                        {{
                                            `${$get(
                                                getServiceDetails,
                                                'contractor.email',
                                                ''
                                            )}`
                                        }}</span
                                    >
                                </span>
                                <span v-else>
                                    <span
                                        >{{
                                            `${$get(
                                                getServiceDetails,
                                                'contractor.name',
                                                ''
                                            )} ${$get(
                                                getServiceDetails,
                                                'contractor.lastname',
                                                ''
                                            )}`
                                        }}
                                    </span>
                                    <span
                                        v-if="
                                            `${$get(
                                                getServiceDetails,
                                                'contractor.phoneNumber',
                                                ''
                                            )}`
                                        "
                                        >{{ $t('service:tel')
                                        }}{{
                                            `${$get(
                                                getServiceDetails,
                                                'contractor.phoneNumber',
                                                ''
                                            )}`
                                        }}</span
                                    >
                                    <span
                                        v-if="
                                            `${$get(
                                                getServiceDetails,
                                                'contractor.email',
                                                ''
                                            )}`
                                        "
                                    >
                                        {{ $t('service:email') }}
                                        {{
                                            `${$get(
                                                getServiceDetails,
                                                'contractor.email',
                                                ''
                                            )}`
                                        }}</span
                                    >
                                </span>
                            </span>
                            <span
                                >Lokalizacja:
                                {{ `${$get(location, 'address', '')}` }},
                                {{ `${$get(location, 'postCode', '')}` }}
                                {{ `${$get(location, 'city', '')}` }}
                                <span
                                    v-if="
                                        `${$get(
                                            getServiceDetails,
                                            'device.floor',
                                            ''
                                        )}`
                                    "
                                    >{{
                                        `${$get(
                                            getServiceDetails,
                                            'device.floor',
                                            ''
                                        )}`
                                    }}</span
                                >
                            </span>
                        </div>
                    </div>
                    <div class="row-2">
                        <div class="d-flex flex-column">
                            <div class="clientDescription--title">
                                PRZEDMIOT USŁUGI:
                            </div>
                            <div>
                                {{
                                    `${$get(
                                        getServiceDetails,
                                        'device.model.manufacturer',
                                        ''
                                    )}`
                                }}
                                {{
                                    `${$get(
                                        getServiceDetails,
                                        'device.model.model',
                                        ''
                                    )}`
                                }}

                                - SN:
                                {{
                                    `${$get(
                                        getServiceDetails,
                                        'device.sn',
                                        ''
                                    )}`
                                }}
                            </div>
                        </div>
                        <div class="service-info mt-5 pr-5">
                            <div class="clientDescription--title">
                                Podstawa serwisu:
                                <span>{{
                                    $get(
                                        getServiceDetails,
                                        'device.serviceBase.field',
                                        ''
                                    )
                                }}</span>
                            </div>
                            <div
                                class="clientDescription--title d-flex justify-space-between"
                            >
                                <div>
                                    Gwarancja:
                                    <span
                                        v-if="
                                            warranty(
                                                $get(
                                                    getServiceDetails,
                                                    'device'
                                                )
                                            )
                                        "
                                        >TAK</span
                                    >
                                    <span v-else>NIE</span>
                                </div>
                                <div>
                                    Licznik urządzenia mono:
                                    <span
                                        v-if="
                                            `${$get(
                                                getServiceDetails,
                                                'realization.counters.monoDeviceStart',
                                                ''
                                            )}` !== 'null'
                                        "
                                        >{{
                                            `${$get(
                                                getServiceDetails,
                                                'realization.counters.monoDeviceStart',
                                                ''
                                            )}`
                                        }}</span
                                    >
                                </div>
                            </div>

                            <div
                                class="clientDescription--title d-flex justify-space-between"
                            >
                                <div>
                                    Kontynuacja:
                                    <span
                                        v-if="
                                            $get(
                                                getServiceDetails,
                                                'realizationCheckboxes.continuation',
                                                false
                                            )
                                        "
                                    >
                                        TAK
                                    </span>
                                    <span v-else>NIE</span>
                                </div>
                                <div>
                                    Licznik urządzenia kolor:
                                    <span
                                        v-if="
                                            `${$get(
                                                getServiceDetails,
                                                'realization.counters.colorDeviceStart',
                                                ''
                                            )}` !== 'null'
                                        "
                                        >{{
                                            `${$get(
                                                getServiceDetails,
                                                'realization.counters.colorDeviceStart',
                                                ''
                                            )}`
                                        }}</span
                                    >
                                </div>
                            </div>
                        </div>

                        <div class="service-info">
                            <span class="clientDescription--title">
                                Pakiet usług:
                            </span>
                            <span>12DR</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="central-table-wrapper">
                <table class="mb-5">
                    <tr>
                        <th class="column-to-left px-4">Opis Klienta</th>
                    </tr>
                    <tr>
                        <td class="column-to-left px-4">
                            {{
                                `${$get(
                                    getServiceDetails,
                                    'clientDescription',
                                    ''
                                )}`
                            }}
                        </td>
                    </tr>
                </table>
                <table class="mb-5">
                    <tr>
                        <th class="column-to-left px-4">
                            Opis technika serwisu
                        </th>
                    </tr>
                    <tr>
                        <td class="column-to-left px-4">
                            {{
                                `${$get(
                                    getServiceDetails,
                                    'realization.interventionRange',
                                    ''
                                )}`
                            }}
                        </td>
                    </tr>
                </table>
                <table class="mb-5">
                    <tr>
                        <th class="column-to-left px-4">
                            Uwagi z ostatniej interwencji
                        </th>
                    </tr>
                    <tr>
                        <td class="column-to-left px-4">
                            {{
                                $get(
                                    getPrevIntervention,
                                    'realization.technicianComments',
                                    ''
                                )
                            }}
                        </td>
                    </tr>
                </table>
                <table class="mb-5">
                    <tr>
                        <th class="column-to-left px-4">Opis logistyka</th>
                    </tr>
                    <tr>
                        <td class="column-to-left px-4">
                            {{
                                `${$get(
                                    getServiceDetails,
                                    'logisticianDescription',
                                    ''
                                )}`
                            }}
                        </td>
                    </tr>
                </table>
            </div>

            <div class="footer">
                <span>{{ getSettingsDetails.website }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import store from '../../../../store'

export default {
    data() {
        return {
            path: `${process.env.VUE_APP_SERVER}/secured/logo/`,
            vat: 23,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: ' PLN',
                precision: 2,
                masked: false,
            },
        }
    },

    computed: {
        ...mapGetters([
            'getServiceDetails',
            'getSettingsDetails',
            'getPrevIntervention',
        ]),
        location: {
            get() {
                let location = this.getServiceDetails.device.client.branches.find(
                    el => el._id === this.getServiceDetails.device.branch
                )
                return location
            },
        },
    },

    methods: {
        warranty(item) {
            return (
                (item.guaranteeTo &&
                    this.$moment(item.guaranteeTo).isSameOrAfter(
                        new Date(),
                        'days'
                    )) ||
                (item.partGuarantee &&
                    this.$moment(item.partGuarantee).isSameOrAfter(
                        new Date(),
                        'days'
                    ))
            )
        },
    },

    beforeMount() {
        store.dispatch('fetchSettings', {
            type: 'add',
        })
    },
}
</script>

<style lang="scss" scoped>
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    color: #4a4a4a;
}

* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.page {
    width: 210mm;
    height: 297mm;
    margin: 10mm auto;
    border: 1px #d3d3d3 solid;
    border-radius: 5px;
    position: relative;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.mt-5 {
    margin-top: 15px;
}
tr > td > input {
    font-size: 1em !important;
    text-align: right;
    max-width: 80px;
}
.subpage {
    height: 100%;

    .header {
        padding-top: 40px;
        padding-left: 40px;
        padding-right: 30px;
        display: grid;
        grid-template-columns: 2fr 1fr;

        .logo {
            padding-left: 10px;

            img {
                max-width: 203px;
                max-height: 45px;
                display: block;
            }
        }

        &__invoice-wrapper-info {
            display: flex;
            justify-content: space-between;
            font-size: 0.625em;
        }
    }

    .header {
        &__order-wrapper {
            font-size: 0.75em;
        }
    }

    .title {
        margin-top: 30px;
        display: flex;
        font-size: 1.75em;
        font-weight: 400;

        &__block-1 {
            width: 500px;
            height: 50px;
            background-color: #4c517d;
            display: block;
        }

        &__block-2 {
            display: flex;
            height: 50px;
            padding-left: 34px;
            padding-right: 34px;
            line-height: 25px;
            width: 242px;
            text-align: center;
            font-size: 1em;
        }

        &__block-3 {
            height: 50px;
            float: left;
            flex-grow: 1;
            width: 90px;
            background-color: #4c517d;
            display: block;
        }
    }

    .clientDescription {
        margin-top: 50px;
        display: grid;
        grid-template-columns: 400px 1fr;
        font-size: 0.75em;

        &--title {
            font-weight: bold;
            span {
                font-weight: normal;
            }
        }
        .column-left {
            display: grid;
            padding-left: 50px;

            &__item-wrapper {
                padding: 0px;
                margin: 0px;
                display: grid;
                grid-template: column;
            }
        }

        .column-right {
            display: grid;
            padding-right: 20px;

            &__item-wrapper {
                margin: 0px;

                display: grid;
                grid-template: column;
            }

            .row-2 {
                margin-top: 20px;
            }
        }
    }

    .middle-text {
        padding-left: 50px;
        font-size: 0.625em;
    }

    .central-table-wrapper {
        margin-left: 50px;
        margin-top: 25px;
        font-size: 1.2em;

        .column-to-left {
            text-align: start;
        }
        .column-to-center {
            text-align: center;
        }
        .mb-5 {
            margin-bottom: 10px;
        }
        .px-4 {
            padding: 0 10px;
        }
        table {
            border-collapse: collapse;
            border-spacing: 0;
            width: 95%;
            border: 1px solid rgb(128, 128, 128);
            font-size: 0.625em;
        }

        table {
            counter-reset: rowNumber;
        }

        table .main-counter {
            counter-increment: rowNumber;
        }

        .counter tr td:first-child::before {
            content: counter(rowNumber);
        }

        th {
            background-color: #4c517d;
            color: #f5f5f5;
        }

        th,
        td {
            text-align: end;
            height: 38px;
            max-height: 80px;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-right: 15px;
        }

        tr:nth-child(2n-1) {
            background-color: #f5f5f5;
        }
    }

    .before-summary-wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-left: 50px;
        padding-right: 38px;
        margin-top: 10px;
        .thanks-text {
            font-size: 0.75em;
        }

        .total-netto {
            font-weight: 700;
            line-height: 14px;
            font-size: 0.75em;
            color: #4a4a4a;
            justify-self: end;
        }
        .total-netto > span > span:nth-child(2n) {
            margin-left: 20px;
        }
    }

    .summary-wrapper {
        margin-top: 60px;
        padding-top: 10px;
        padding-left: 50px;
        padding-right: 38px;
        display: grid;
        grid-template-columns: 1fr 1fr;

        .col-left {
            font-size: 0.75em;
            .payment-condition {
                margin-top: 20px;
                display: flex;
                flex-direction: column;

                &__inner-wrapper {
                    margin-top: 2px;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                }
            }
        }

        .col-right {
            display: grid;
            text-align: right;

            .total-brutto {
                margin-top: 5px;
                width: 80%;
                height: 52px;
                justify-self: end;
                display: grid;
                grid-template-columns: 1fr 1fr;
                align-items: center;
                padding: 5px 15px 5px 15px;
                background-color: #4c517d;
                text-align: start;
                color: white;
            }
            .numtoword {
                margin-top: 5px;
                font-size: 0.5em;
                text-align: left;
                justify-self: end;
            }

            .mini-summary {
                text-align: right;
                margin-top: 15px;

                table {
                    width: 65%;
                    float: right;
                }

                th,
                td {
                    font-size: 0.5em;
                    padding: 0px 0px 0px 0px;
                }
            }
        }
    }

    .significant {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-top: 90px;
        padding-left: 60px;
        margin-bottom: 10px;
        font-size: 0.625em;

        .col-left {
            display: flex;
            flex-direction: column;

            align-items: flex-start;
        }

        .col-right {
            display: flex;
            flex-direction: column;
            padding-top: 20px;
            padding-left: 30px;
            align-items: flex-start;

            hr.line {
                border: 1px solid rgb(1, 1, 1, 0.5);
                margin-bottom: 10px;
                width: 70%;
            }
        }
    }
    .signature-wrapper {
        display: flex;
        position: absolute;
        bottom: 80px;
        width: 100%;
        justify-content: flex-end;

        padding-right: 110px;
        img {
            width: 90px;
        }
        .signature {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            padding: 1em 3em;
            font-size: 0.75em;
        }
    }

    .footer {
        margin-left: -1px;
        width: 210mm;
        height: 25px;
        margin-bottom: 20px;
        color: white;
        text-align: center;
        position: absolute;
        bottom: 0;
        background-color: #4c517d;
        span {
            display: block;
            margin-top: 2px;
        }
    }
}

@page {
    size: A4;
    margin: 0;
}

@media print {
    html,
    body {
        width: 210mm;
        height: 297mm;
        padding: 0px !important;
    }

    .page {
        margin: 2px;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
        -webkit-print-color-adjust: exact;
    }

    .subpage {
        height: 256mm;
    }
}
</style>
