<template>
    <v-card class="mx-auto pa-2">
        <div class="d-flex justify-space-between pa-2">
            <span class="dictionary__title">{{ dictionaryName }}</span>
            <v-menu
                :close-on-content-click="false"
                v-model="menus.open"
                top
                origin="center"
            >
                <template v-slot:activator="{ on }">
                    <div>
                        <v-btn
                            data-cy="addEntryToDictionary"
                            small
                            @click="openMenus('add')"
                            icon
                            v-on="on"
                        >
                            <v-icon size="20">mdi-plus</v-icon>
                        </v-btn>
                        <v-btn
                            data-cy="pinDictionaryToRegistry"
                            small
                            @click="openPinModal()"
                            icon
                        >
                            <v-icon size="20">mdi-pin</v-icon>
                        </v-btn>
                    </div>
                </template>
                <v-card :width="width" class="d-flex flex-column">
                    <span
                        class="dictionary__modal--title py-3 text-center ma-0"
                    >
                        {{
                            menus.action == 'add'
                                ? $t('global:addition')
                                : $t('global:edit')
                        }}
                    </span>
                    <v-divider></v-divider>
                    <!-- @slot Use this slot to add dictionary content-->
                    <v-card-text class="d-flex flex-column pb-0">
                        {{ item.backgroundColor }}
                        <div class="d-flex">
                            <v-text-field
                                data-cy="entryName"
                                :label="$t('dictionaries:value')"
                                v-model="item.field"
                            ></v-text-field>
                            <v-checkbox
                                class="mx-4"
                                label="Chip"
                                v-model="chip"
                                @change="chipActive()"
                            >
                            </v-checkbox>
                        </div>
                        <div class="d-flex" v-if="chip === true">
                            <div>
                                <span>{{
                                    $t('dictionaries:backgroundColor')
                                }}</span>
                                <v-color-picker
                                    width="160"
                                    dot-size="15"
                                    canvas-height="90"
                                    v-model="backgroundColor"
                                    @update:color="
                                        item.backgroundColor = backgroundColor
                                    "
                                    class="mr-1"
                                    mode="hexa"
                                ></v-color-picker>
                            </div>
                            <div>
                                <span>{{
                                    $t('dictionaries:backgroundMono')
                                }}</span>
                                <span>{{ $t('dictionaries:textColor') }}</span>
                                <v-color-picker
                                    dot-size="15"
                                    canvas-height="90"
                                    v-model="textColor"
                                    @update:color="item.textColor = textColor"
                                    mode="hexa"
                                    class="ml-1"
                                    width="160"
                                ></v-color-picker>
                            </div>
                        </div>
                    </v-card-text>
                    <v-footer class="d-flex flex-end pa-2">
                        <v-btn
                            class="buttons buttons--add buttons--dictionaries"
                            @click="addEntry()"
                            >{{
                                menus.action == 'add'
                                    ? $t('global:add')
                                    : $t('global:save')
                            }}</v-btn
                        >
                    </v-footer>
                </v-card>
            </v-menu>
        </div>
        <div class="dictionary__header">{{ $t('dictionaries:name') }}</div>
        <v-list dense class="dictionary__list">
            <v-list-item
                class="pa-0 px-2 dictionary__item"
                v-for="(item, i) in itemsDictionaries"
                :key="i"
            >
                <v-list-item-content>
                    <!-- <v-list-item-title v-if="item.textColor">
                        <v-chip
                            small
                            class="ma-2"
                            :color="item.backgroundColor"
                            :text-color="item.textColor"
                            label
                        >
                            {{ item.field }}
                        </v-chip>
                    </v-list-item-title> -->
                    <v-list-item-title>{{ item.field }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action class="d-flex flex-row">
                    <v-btn @click="openMenus(item)" small icon>
                        <v-icon small color="text--grey lighten-1"
                            >mdi-pencil</v-icon
                        >
                    </v-btn>
                    <v-btn
                        @click="
                            removingEntry = item
                            openConfirm = true
                        "
                        small
                        icon
                    >
                        <v-icon small color="red lighten-1">mdi-delete</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-list>
        <ConfirmModal
            :openConfirm="openConfirm"
            v-on:close-confirm="closeConfirmModal"
        >
            <div slot="main">
                <ConfirmContent />
            </div>
            <div slot="buttons">
                <div class="d-flex justify-center">
                    <v-btn
                        color="rgb(117, 117, 117)"
                        width="100"
                        height="40"
                        @click="closeConfirmModal()"
                        class="white--text mx-2"
                        >{{ $t('global:cancel') }}</v-btn
                    >
                    <v-btn
                        width="100"
                        height="40"
                        color="primary"
                        class="white--text mx-2"
                        @click="removeEntry()"
                        >{{ $t('global:remove') }}</v-btn
                    >
                </div>
            </div>
        </ConfirmModal>
        <Modal
            :title="$t('dictionaries:pinningDictionary')"
            :open="open"
            :height="'400'"
            v-on:close="clearStepper()"
            ><template slot="AEContent">
                <v-stepper v-model="stepper" class="elevation-0">
                    <v-stepper-header>
                        <v-divider></v-divider>
                        <v-stepper-step step="1" editable>
                            {{ $t('dictionaries:checkRegistry') }}
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="2">
                            {{ $t('dictionaries:checkField') }}
                        </v-stepper-step>
                        <v-divider></v-divider>
                    </v-stepper-header>

                    <v-stepper-items class="cursor-pointer">
                        <v-stepper-content step="1">
                            <v-list data-cy="pinDictionary" flat>
                                <v-list-item
                                    v-for="(item,
                                    i) in getRegistriesTable.items"
                                    :key="i._id"
                                >
                                    <v-list-item-content
                                        @click="
                                            stepperSelect(item, 'registries')
                                        "
                                    >
                                        <v-list-item-title
                                            v-text="item.name"
                                        ></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-for="(item, i) in entities"
                                    :key="i._id"
                                >
                                    <v-list-item-content
                                        @click="stepperSelect(item, 'entities')"
                                    >
                                        <v-list-item-title
                                            v-text="item.name"
                                        ></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-stepper-content>

                        <v-stepper-content step="2">
                            <v-list flat>
                                <v-list-item-group
                                    v-model="selectedFields"
                                    active-class="blue--text"
                                    multiple
                                >
                                    <template
                                        v-for="(item,
                                        i) in stepperRegistry.fields"
                                    >
                                        <v-list-item
                                            :key="i"
                                            :disabled="alreadyPinned(item)"
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title
                                                    v-text="
                                                        $t(
                                                            `${stepperRegistry.url}:${item}`
                                                        )
                                                    "
                                                ></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-list-item-group>
                            </v-list>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </template>
            <template slot="buttons">
                <div>
                    <v-btn @click="pinDict()" class="buttons buttons--add">
                        {{ $t('global:save') }}
                    </v-btn>
                </div>
            </template>
        </Modal>
    </v-card>
</template>
<script>
import ConfirmContent from './../../../components/Admin/Dictionaries/ConfirmModal/ConfirmModal'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import store from '../../../store/index'
/**
 * Dictionaries
 * @displayName Dictionaries
 */
export default {
    i18nOptions: { namespaces: ['global', 'users'] },
    props: {
        /**
         * Dictionary Name
         */
        dictionaryName: {
            type: String,
            required: true,
        },
        /**
         * Dictionary Item (table)
         */
        itemsDictionaries: {
            type: Array,
            required: true,
        },
        /**
         * Dictionary AE Modal width
         */
        width: {
            type: Number,
            default: 360,
        },
        dictionaryField: {
            type: String,
        },
        dictionaryId: { type: String },
    },

    data: () => ({
        openConfirm: false,
        menus: {
            open: false,
            action: '',
        },
        item: {
            field: '',
        },
        textColor: '',
        backgroundColor: '',
        chip: false,
        removingEntry: '',
        open: false,
        stepper: 1,
        stepperRegistry: {},
        selectedFields: [],
        selectedRegistry: '',
        entities: [],
        type: null,
    }),

    computed: {
        ...mapGetters(['getRegistriesTable']),
    },

    methods: {
        ...mapActions(['fetchRegistriesTable', 'updateRegistryDictionaries']),
        chipActive() {
            if (this.chip === false) {
                this.item.textColor = ''
                this.item.backgroundColor = ''
            }
        },
        color(color) {
            this.item = color
        },
        openMenus(action) {
            this.menus.action = action
            this.menus.open = true
            if (action == 'add') {
                this.field =
                    action === 'edit' ? Object.values(this.editingField)[0] : ''
            } else {
                this.item = action
                if (action.backgroundColor) {
                    this.chip = true
                    this.backgroundColor = action.backgroundColor
                    this.textColor = action.textColor
                } else {
                    this.chip = false
                    this.backgroundColor = ''
                    this.textColor = ''
                }
            }
        },
        openConfirmModal() {
            this.openConfirm = true
        },
        closeConfirmModal() {
            this.openConfirm = false
        },
        getHeaders() {
            let arr = []
            arr.push({
                text: this.$t(`users:${this.dictionaryField}`),
                value: this.dictionaryField,
            })
            arr.push({
                text: this.$t(`global:action`),
                value: 'action',
                width: '112',
                align: 'center',
                sortable: false,
            })
            return arr
        },
        addEntry() {
            this.$emit('add-entry', this.item)
            this.menus.open = false
            this.item = {}
        },
        editEntry() {
            this.$emit('edit-entry', {
                editingField: this.editingField,
                field: this.field,
            })
            this.menus.open = false
        },
        removeEntry() {
            this.$emit('remove-entry', { removingEntry: this.removingEntry })
            this.openConfirm = false
        },
        async openPinModal() {
            let registriesReq = this.fetchRegistriesTable({
                select: 'name url _id dictionaries',
                limit: Infinity,
                page: null,
                where: null,
                search: null,
                searchFields: null,
                populate: null,
                sort: null,
            })
            let entitiesReq = new this.Rest('GET', 'entities')
                .where({name: 'Alerty'})
                .select('name _id dictionaries url')
                .send()
            let { 0: entities } = await Promise.all([
                entitiesReq,
                registriesReq,
            ])
            this.entities = entities.data.items
            this.open = true
        },
        stepperSelect(item, type) {
            item.fields = Object.keys(store.state[item.url].dictFields)
            this.stepperRegistry = item
            this.selectedFields = []
            this.type = type
            for (let [index, field] of Object.entries(
                this.stepperRegistry.fields
            )) {
                if (
                    this.stepperRegistry.dictionaries &&
                    Object.keys(this.stepperRegistry.dictionaries).includes(
                        field
                    ) &&
                    this.stepperRegistry.dictionaries[field] ==
                        this.dictionaryId
                ) {
                    this.selectedFields.push(Number(index))
                }
            }
            this.stepper = 2
        },
        async pinDict() {
            let newPins = {}
            for (let field of this.selectedFields.map(
                el => this.stepperRegistry.fields[el]
            )) {
                newPins[field] = this.dictionaryId
            }
            if (!this.stepperRegistry.dictionaries)
                this.stepperRegistry.dictionaries = {}
            Object.entries(this.stepperRegistry.dictionaries)
                .filter(el => el[1] != this.dictionaryId)
                .forEach(el => (newPins[el[0]] = el[1]))
            let regId = this.stepperRegistry._id
            if (this.type === 'registries')
                await this.updateRegistryDictionaries({
                    id: regId,
                    dictionaries: newPins,
                })
            else
                await new this.Rest('PUT', `entities/${regId}/dictionaries`)
                    .setBody({ dictionaries: newPins })
                    .send()
            this.clearStepper()
        },
        alreadyPinned(item) {
            return (
                this.stepperRegistry.dictionaries &&
                this.stepperRegistry.dictionaries[item] &&
                this.stepperRegistry.dictionaries[item] != this.dictionaryId
            )
        },
        clearStepper() {
            this.open = false
            this.stepper = 1
            this.stepperRegistry = {}
            this.selectedFields = []
            this.selectedRegistry = ''
        },
    },
    components: {
        ConfirmContent,
    },
}
</script>
