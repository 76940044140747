<template>
    <v-app class="app-container">
        <v-main>
            <v-container  fluid>
                <Menu />

                <MyAppBar />

                <v-slide-x-transition mode="out-in">
                    <!-- <router-view></router-view> -->
                    <router-view
                        :route_name="$route.name"
                        :key="$route.fullPath"
                    />
                </v-slide-x-transition>

                <RightBox />
                <Snackbars />
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import Menu from './../../components/Global/Overlay/Menu/Menu'
import MyAppBar from './../../components/Global/Overlay/AppBar'
import RightBox from './../../components/Global/RightBox/RightBox'
import { mapMutations } from 'vuex'
export default {
    data: () => ({
        //
    }),
    methods: {
        ...mapMutations(['clearSearchText']),
    },
    watch: {
        $route: {
            handler: function (to, from) {
                if (from && from.name !== to.name) {
                    this.clearSearchText()
                }
            },
            deep: true,
            immediate: true,
        },
    },
    components: {
        Menu,
        MyAppBar,
        RightBox,
    },
}
</script>

<style lang="scss">

</style>
