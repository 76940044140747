<template>
    <v-tooltip bottom v-if="settlementType && statusObject">
        <template v-slot:activator="{ on }">
            <v-avatar
                class="cursor-pointer ma-1"
                v-on="on"
                :color="statusObject.color"
                size="25"
            >
                <span class="white--text">{{
                    statusObject.value
                }}</span></v-avatar
            >
        </template>
        <span>{{ statusObject.tooltip }}</span>
    </v-tooltip>
</template>
<script>
import { defaultFormat } from 'moment'
import { SettlementsTypes } from '../../../../../../../../../plugins/constsTypes'

export default {
    props: {
        item: { type: Object, required: true },
    },
    data() {
        return {
            settlementType: null,
            statusObject: {},
        }
    },
    methods: {
        getSettlementType(item) {
            let rentSettlements = this.$get(item, 'rentSettlementObj', [item])
            
            if (rentSettlements && rentSettlements.length > 0) {
                rentSettlements = rentSettlements.sort(
                    (a, b) =>
                        this.$moment(b.createdAt).valueOf() -
                        this.$moment(a.createdAt).valueOf()
                )
            }
            this.settlementType = this.$get(
                rentSettlements,
                '[0].settlementType',
                null
            )
        },
        selectSetlementType(settlementType) {
            switch (settlementType) {
                case SettlementsTypes.manual:
                    return {
                        color: '#19aa8d',
                        value: 'L',
                        tooltip: this.$t(
                            'rent-contracts:rent-settlement.types.manual'
                        ),
                    }
                case SettlementsTypes.autoApi:
                    return {
                        color: '#19273b',
                        value: 'A',
                        tooltip: this.$t(
                            'rent-contracts:rent-settlement.types.autoApi'
                        ),
                    }
                case SettlementsTypes.autoHistory:
                    return {
                        color: '#9c8b2d',
                        value: 'S',
                        tooltip: this.$t(
                            'rent-contracts:rent-settlement.types.autoHistory'
                        ),
                    }
                case SettlementsTypes.csvFile:
                    return {
                        color: '#b297ba',
                        value: 'C',
                        tooltip: this.$t(
                            'rent-contracts:rent-settlement.types.csvFile'
                        ),
                    }
                default:
                    return {
                        color: '#b58686',
                        value: 'D',
                        tooltip: this.$t(
                            'rent-contracts:rent-settlement.types.default'
                        ),
                    }
            }
        },
    },
    mounted() {
        this.getSettlementType(this.item)
    },
    watch: {
        item: function (val) {
            this.getSettlementType(val)
        },
        settlementType: function (val) {
            this.statusObject = this.selectSetlementType(val)
        },
    },
}
</script>
