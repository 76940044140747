import Rest from './../../../plugins/Rest'
import clone from 'lodash.clone'

const state = {
    request: {
        populate: [
            {
                localField: 'technician',
                foreignField: '_id',
                from: 'users',
                as: 'technician',
            },
        ],
        searchFields: [
            'index',
            'realization.interventionRange',
            'technician.lastname',
            'technician.name',
            'contractor.name',
            'contractor.lastname',
            'contractor.phoneNumber',
        ],
        select:
            '_id device isDeleted index realization.interventionRange realization.technicianComments technician.name technician.lastname createdAt contractor.name contractor.lastname contractor.phoneNumber',
        where: {
            isDeleted: false,
        },
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: '-createdAt',
    },
}

const getters = {
    getServiceArchivesTable: state => state.table,
}

const actions = {
    async fetchArchiveServices({ commit, dispatch }, payload) {
        let response = await new Rest('GET', 'service')
            .where({ device: payload.id, isDeleted: false })
            .select(state.request.select)
            .limit(state.table.limit)
            .page(state.table.page)
            .populate(state.request.populate)
            .search(state.request.searchFields, state.table.search)
            .sort(state.table.sort || state.table.defaultSort)
            .send()
        commit('setServiceArchivesTable', response.data)
    },
}

const mutations = {
    setServiceArchivesTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
