import Rest from '../../plugins/Rest'
import clone from 'lodash.clone'
import validation from './../../plugins/validation'
const state = {
    modal: {
        fields: {
            name: '',
            folderName: '',
            index: '',
            url: '',
            icon: '',
            visible: '',
            currentMenager: [],
            availableMenagers: [],
            defaultStatus: [],
            availableStatuses: [],
            availableFolders: [],
            changeArchive: false,
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {
                    folderName: '',
                    name: '',
                    index: '',
                    url: '',
                    icon: '',
                    visible: '',
                    currentMenager: [],
                    availableMenagers: [],
                    defaultStatus: [],
                    availableStatuses: [],
                    availableFolders: [],
                    statusName: '',
                    
                },
            },
        },
        steps: '',
        possibleMenagers: [],
    },
    fieldsNames: {},
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        sort: '',
        defaultSort: 'name',
    },
    request: {
        populate: [
            {
                path: 'currentMenager',
                select: 'name lastname',
            },
        ],
        searchFields: ['name', 'index'],
        select:
            'name _id index currentMenager.name createdAt availableFolders folderName updatedAt visible folders',
    },
    details: {
        name: '',
        index: '',
        url: '',
        icon: '',
        visible: '',
        currentMenager: [],
        availableMenagers: [],
        defaultStatus: [],
        availableStatuses: [],
        availableFolders: [],
    },
    req: {
        stepOne: [
            'name',
            'index',
            'url',
            'icon',
            'visible',
            'currentMenager',
            'defaultStatus',
            'availableFolders',
            'currentMenager',
            'availableMenagers',
        ],
        stepTwo: [],
    },
    filesCount: 0,
}

const getters = {
    getRegistry: state => state.modal.fields,
    getRegistryPossibleMenagers: state => state.modal.possibleMenagers,
    getRegistryErrors: state => state.modal.errors,
    getRegistryDetails: state => state.details,
    getPossibleStatuses: state =>
        state.modal.fields.availableStatuses?.filter(
            item => item.isDeleted == false
        ),
    getPossibleFolders: state =>
        state.modal.fields.availableFolders?.filter(
            item => item.isDeleted == false
        ),
    getRegistriesTable: state => state.table,
    getRegistriesSearchFields: state => state.request,
    getRegistryFilesCount: state => state.filesCount,
}

const actions = {
    async fetchRegistriesTable({ commit }, payload) {
        try {
    
            let table = new Rest('GET', 'registries')
                .select(payload.select || state.request.select)
                .limit(payload.limit || state.table.limit)
                .page(payload.page || state.table.page)
                .where(payload.where || state.request.where)
                .search(
                    payload.searchFields || state.request.searchFields,
                    payload.search || state.table.search
                )
                .populate(payload.populate || state.request.populate)
                .sort(state.table.sort || state.table.defaultSort)
                .send()
            let usersReq = new Rest('GET', 'users')
                .select('_id name lastname')
                .where({ isDeleted: false })
                .send()
            let { 0: users, 1: response } = await Promise.all([usersReq, table])
            commit('setRegistryPossibleMenagers', users.data.items)
            commit('setRegistriesTable', response.data)
        } catch (err) {
            //HANDLE ERRORS
            console.error(err)
        }
    },

    async fetchRegistry({ commit }, payload) {
        try {
            let responseReq = new Rest('GET', `registries/${payload.id}`)
                .populate([
                    {
                        path: 'currentMenager',
                        select: 'name lastname _id picture',
                    },
                    {
                        path: 'availableMenagers',
                        select: 'name lastname _id',
                    },
                ])
                .send()
            let usersReq = new Rest('GET', 'users')
                .select('_id name lastname')
                .where({ isDeleted: false })
                .send()
            let { 0: users, 1: response } = await Promise.all([
                usersReq,
                responseReq,
            ])

            commit('setRegistryDetails', response.data)
            commit('setRegistryPossibleMenagers', users.data.items)
            commit('readDefaultStatus', response.data.availableStatuses)
            if (payload.next) payload.next()
        } catch (err) {
            //HANDLE ERRORS
            console.error(err)
        }
    },

    async fetchRegistryStatus({}, payload) {
        try {
            let responseReq = new Rest('GET', 'registries')
                .select(payload.select)
                .where(payload.where)
                .send()
            let { 0: response } = await Promise.all([responseReq])
            return response.data.items
        } catch (err) {
            //HANDLE ERRORS
            console.error(err)
        }
    },

    async createRegistry({ commit, dispatch }, payload) {
        commit('setDefaultStatus')
        validation.frontVal(state.modal, state.req)
        if (state.modal.errors.stepOne.validation) {
            try {
                await new Rest('POST', 'registries')
                    .setBody(state.modal.fields)
                    .send()
                commit('clearRegistryModal')
                dispatch('fetchRegistriesTable', {})
                commit('setSnackbars', { type: 'success' })
                return true
            } catch (err) {
                //HANDLE ERRORS
                console.error(err)
            }
        } else commit('setSnackbars', { type: 'error' })
    },

    async updateRegistry({ commit, dispatch }, payload) {
        commit('setDefaultStatus')
        // validation.frontVal(state.modal, state.req)
        if (state.modal.errors.stepOne.validation === true) {
            try {
                let response = await new Rest('PUT', `registries/${payload.id}`)
                    .setBody(state.modal.fields)
                    .populate([])
                    .send()
                commit('clearRegistryModal')
                commit('setSnackbars', { type: 'success' })
                dispatch('fetchRegistry', { id: payload.id })
                return true
            } catch (err) {
                //HANDLE ERRORS
                commit('setSnackbars', { type: 'error' })
            }
        } else {
            commit('setSnackbars', { type: 'error' })
        }
    },

    async updateRegistryDictionaries({ commit, dispatch }, payload) {
        try {
            let response = await new Rest(
                'PUT',
                `registries/${payload.id}/dictionaries`
            )
                .setBody({ dictionaries: payload.dictionaries })
                .send()
            return true
        } catch (err) {
            //HANDLE ERRORS
            console.error(err)
        }
    },

    async fetchRegistryFilesCount({ commit, dispatch }, payload) {
        try {
            let response = await new Rest(
                'GET',
                `registries/${payload.url}/item/${payload.id}/countFiles`
            ).send()
            commit('setRegistryFilesCount', response.data)
        } catch (error) {
            // console.error(error)
        }
    },

    async fetchRegistryDateFields({ commit, dispatch }, payload) {
        try {
            let response = await new Rest(
                'GET',
                `/registries/${payload.url}/getDateFields`
            ).send()
            return response.data
        } catch (error) {
            console.error(error)
        }
    },
}

const mutations = {
    addNewStatus(state, payload) {
        if (!state.modal.fields.availableStatuses)
            state.modal.fields.availableStatuses = []
        state.modal.fields.availableStatuses.push(payload)
    },
    addNewFolder(state, payload) {
        if (!state.modal.fields.availableFolders)
            state.modal.fields.availableFolders = []
        state.modal.fields.availableFolders.push(payload)
    },
    updateFolder(state, payload) {
        let arr = state.modal.fields.availableFolders.filter(item => {
            return item.isDeleted == false
        })
    },
    updatePossibleStatus(state, payload) {
        let arr = state.modal.fields.availableMenagers.filter(item => {
            return item.isDeleted == false
        })
    },
    setRegistriesTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
    setRegistryDetails(state, payload) {
        state.details = payload
    },
    setRegistryModal(state, payload) {
        let obj = payload ? payload : clone(state.details)
        state.modal.fields = obj
    },
    clearRegistryErrors(state) {
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step].fields) {
                state.modal.errors[step].fields[key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
    clearRegistryModal(state) {
        state.modal.fields = {
            name: '',
            index: '',
            url: '',
            icon: '',
            visible: '',
            folderName: '',
            currentMenager: [],
            availableMenagers: [],
            defaultStatus: [],
            availableStatuses: [],
            availableFolders: [],
            changeArchive: false,
        }
        state.modal.errors = {
            stepOne: {
                validation: true,
                fields: {
                    name: '',
                    index: '',
                    url: '',
                    icon: '',
                    folderName: '',
                    visible: '',
                    currentMenager: [],
                    availableMenagers: [],
                    defaultStatus: [],
                    availableStatuses: [],
                    availableFolders: [],
                },
            },
        }
    },
    setDefaultStatus(state, payload) {
        if (state.modal.fields.availableStatuses)
            for (let status of state.modal.fields.availableStatuses) {
                status.isDefault = false
                if (status == state.modal.fields.defaultStatus)
                    status.isDefault = true
            }
    },
    setRegistryPossibleMenagers(state, payload) {
        state.modal.possibleMenagers = payload
    },
    readDefaultStatus(state, payload) {
        for (let status of payload) {
            if (status.isDefault == true) {
                state.modal.fields.defaultStatus = status
                state.details.defaultStatus = status
            }
        }
    },
    setRegistryFilesCount(state, payload) {
        state.filesCount = payload
    },
    clearRegistriesTable(state) {
        state.table.search = ''
        state.table.page = 1
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
