import Rest from './../../../plugins/Rest'
import clone from 'lodash.clone'
import i18n from './../../../plugins/i18next'
import validation from './../../../plugins/validation'
import i18next from 'i18next'
import moment from 'moment'

const state = {
    modal: {
        fields: {
            from: '',
            taskName: '',
            to: {
                users: [],
                departments: [],
            },
            taskDate: '',
            taskTime: '',
            taskDescription: '',
            priority: '',
            category: '',
            attachmentsComputer: [],
            attachmentsRegistry: [],
            comments: [],
            status: 'OPEN',
            taskSource: '',
            type: '',
            itemId: '',
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {
                    taskName: '',
                    taskDate: '',
                },
            },
            stepTwo: {
                validation: true,
                fields: {
                    to: '',
                },
            },
            stepThree: {
                validation: true,
            },
        },
        steps: '',
    },
    request: {
        populate: [
            {
                from: 'users',
                localField: 'to.users',
                foreignField: '_id',
                as: 'to.users',
            },
        ],
        searchFields: ['taskName'],
        select:
            'category priority status taskName taskDescription itemId from taskDate taskTime to.users.name to.users.lastname to.departments description createdAt taskSource',
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: '-taskDate -taskTime',
        sort: '',
    },
    personal: {
        items: [],
        count: 0,
    },
    req: {
        stepOne: ['taskDate', 'taskName'],
        stepTwo: ['to'],
    },
    dictFields: { priority: '', category: '' },
    count: 0,
}

const getters = {
    getTaskModal: state => state.modal.fields,
    getTaskErrors: state => state.modal.errors,
    getTasksSearchFields: state => state.request,
    getTasksTable: state => state.table,
    getTasksPersonal: state => state.personal,
    getTasksCount: state => state.count,
}

const actions = {
    async createTask({ commit, dispatch }, payload) {
        validation.frontVal(state.modal, state.req, 'tasks')
        let where = payload.id
            ? { id: payload.id }
            : {
                  where: { 'to.users._id': payload.userId },
              }
        if (state.modal.errors.stepTwo.validation)
            try {
                let response = await new Rest('POST', '/tasks')
                    .setBody({
                        ...state.modal.fields,
                        itemId: payload.id,
                        url: payload.registry,
                    })
                    .send()

                await dispatch('addFilesToTask', { id: response.data._id })

                await dispatch('fetchTasksTable', where)
                commit('setSnackbars', { type: 'success' })
                return true
            } catch (err) {
                //HANDLE ERRORS
                validation.setErrors(state.modal.errors, err)
                commit('setSnackbars', { type: 'error' })
            }
        else commit('setSnackbars', { type: 'error' })
    },

    async fetchTasksTable({ state, commit, dispatch }, payload) {
        let where = payload.id
            ? { itemId: payload.id }
            : payload.where
            ? payload.where
            : {}

        try {
            let response = await new Rest('GET', '/tasks')
                .select(state.request.select)
                .limit(payload.limit || state.table.limit)
                .page(state.table.page)
                .search(state.request.searchFields, state.table.search)
                .where(where)
                .sort(state.table.sort || state.table.defaultSort)
                .populate(state.request.populate)
                .send()
            if (payload.personal) {
                await commit('setTasksTablePersonal', response.data)
                await commit('setTasksPersonal', response.data)
            } else {
                commit('setTasksTable', response.data)
            }
        } catch (err) {
            //HANDLE ERRORS
            console.error(err)
        }
    },

    async fetchTask({ commit }, payload) {
        try {
            let response = await new Rest('GET', `/tasks/${payload.id}`)
                .populate([
                    {
                        path: 'to.users',
                        select: 'name lastname _id mail picture',
                    },
                    { path: 'to.departments', select: 'name _id shortName' },
                ])
                .send()
            commit('setTasksModal', response.data)
        } catch (error) {
            console.error(error)
        }
    },

    async updateTask({ commit, dispatch }, payload) {
        try {
            let response = await new Rest(
                'PUT',
                `/tasks/${state.modal.fields._id}`
            )
                .additional({ registry: payload.registry })
                .setBody({ ...state.modal.fields, url: payload.registry })
                .send()
            commit('setTasksItem', response.data)
            await dispatch('addFilesToTask', { id: response.data._id })
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            //HANDLE ERRORS
            validation.setErrors(state.modal.errors, err)
            if (err.response.data.error.Done)
                commit('setSnackbars', {
                    type: 'error',
                    text: i18n.t('tasks:updateErrorDone'),
                })
            else commit('setSnackbars', { type: 'error' })
        }
    },

    async addFilesToTask({ commit }, payload) {
        if (state.modal.fields.attachmentsComputer.length > 0) {
            let formData = new FormData()
            state.modal.fields.attachmentsComputer.forEach(file => {
                formData.append('attachmentsComputer', file)
            })
            let response = await new Rest('PUT', `tasks/${payload.id}/files`)
                .setBody(formData)
                .send()
        }
    },
    // async fetchTasksCountPersonal({ state, commit, dispatch }, payload) {
    //     try {
    //         let response = await new Rest('GET', `tasks/count`)
    //             .where(payload.where)
    //             .select('to.users')
    //             .send()

    //         commit('setTasksCountPersonal', response.data)
    //     } catch (error) {
    //         console.error(error)
    //     }
    // },

    async fetchTasksCount({ state, commit, dispatch }, payload) {
        try {
            let response = await new Rest(
                'GET',
                `tasks/${payload.id}/count`
            ).send()
            commit('setTasksCount', response.data)
        } catch (error) {
            console.error(error)
        }
    },
}

const mutations = {
    clearTasksModal(state, payload) {
        state.modal.fields = {
            from: '',
            taskName: '',
            to: {
                users: [],
                departments: [],
            },
            taskDate: '',
            taskTime: '',
            taskDescription: '',
            priority: '',
            category: '',
            attachmentsComputer: [],
            attachmentsRegistry: [],
            comment: '',
            comments: [],
            status: 'OPEN',
        }
    },
    clearTasksErrors(state) {
        state.modal.step = 1
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step].fields) {
                state.modal.errors[step].fields[key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
    setTasksTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
    setTasksTablePersonal(state, payload) {
        state.personal.items = payload.items
    },
    setTasksModal(state, payload) {
        state.modal.fields = { ...payload }
        state.modal.fields.files = [
            ...payload.attachmentsComputer,
            ...payload.attachmentsRegistry,
        ]
        state.modal.fields.itemId = payload.itemId
        state.modal.fields.attachmentsComputer = []
        state.modal.fields.attachmentsRegistry = []
        state.modal.fields.taskDate = payload.taskDate
            ? moment(payload.taskDate).format('YYYY-MM-DD')
            : ''
        state.modal.fields.type = payload.taskSource?.type
        state.modal.fields.taskSource = `${i18next.t(
            `global:${payload.taskSource.type}`
        )}${
            payload.taskSource && payload.taskSource.index
                ? ' - ' + payload.taskSource.index
                : ''
        }`
    },
    setTasksItem(state, payload) {
        state.table.items = state.table.items.map(item =>
            item._id == payload._id ? payload : item
        )
    },
    setTasksSearch(state, payload) {
        state.request.searchFields = payload
    },
    setTasksCount(state, payload) {
        state.count = payload
    },
    setTasksPersonal(state, payload) {
        state.personal.activeTasks = payload.items.filter(
            a =>
                moment(a.taskDate).format('YYYY-MM-DD') ==
                moment(new Date()).format('YYYY-MM-DD')
        )
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
