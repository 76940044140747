import Vue from 'vue'
import VueSignaturePad from 'vue-signature-pad'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import qs from 'qs'
import axios from 'axios'
import vueAxios from 'vue-axios'
import Rest from './plugins/Rest.js'
import money from 'v-money'
import moneyField from './components/Global/MoneyField/MoneyField.vue'

import myMixin from './plugins/myMixin'
import Separator from './components/Global/Separator/Separator.vue'
import Table from './components/Global/Table/Table.vue'
import RegistryHeader from './components/Global/Table/Header.vue'
import Header from './components/Global/Details/Header'
import Main from './components/Global/Details/Main'
import TableDetails from './components/Global/Details/Table/Table.vue'
import Modal from './components/Global/Modal/Modal.vue'
import ModalToCRMAnalysis from './components/Global/Modal/ModalToCRMAnalysis.vue'
import AdminHeader from './components/Admin/Header/Header.vue'
import ConfirmModal from './components/Global/ConfirmModal/ConfirmModal.vue'

import Dictionary from './components/Global/Dictionaries/Content'
import Snackbars from './components/Global/Snackbars/Snackbars'
import DoubleLine from './components/Global/Table/DoubleLine'
import SummaryLine from './components/Global/SummaryLine/SummaryLine'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueDraggableResizable from 'vue-draggable-resizable'
import PortalVue from 'portal-vue'

import { VueMaskDirective } from 'v-mask'

import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

//LogDisabled
if (process.env.NODE_ENV !== 'development') {
    console.log = () => {}
    console.error = () => {}
    console.warn = () => {}
}

// SIGNATURE PAD
Vue.use(VueSignaturePad)
// === === GOOGLE MAPS === === //
Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAP_API,
    },
})

Vue.use(PortalVue)

// MOMENT JS
import moment from 'moment'
Vue.prototype.$moment = moment
moment.locale('pl')

// LODASH
import get from 'lodash.get'
Vue.prototype.$get = get

// AXIOS SETUP
axios.defaults.paramsSerializer = params => qs.stringify(params)
axios.defaults.baseURL = `${process.env.VUE_APP_SERVER}/api/v2`
axios.defaults.withCredentials = true
axios.defaults.mode = 'no-cors'
axios.defaults.credentials = 'same-origin'
Vue.use(vueAxios, axios)
// Snackbars
Vue.component('Snackbars', Snackbars)
// Separator
Vue.component('Separator', Separator)
// Table
Vue.component('Table', Table)
Vue.component('RegistryHeader', RegistryHeader)
// Details
Vue.component('Header', Header)
Vue.component('Main', Main)
//Table Details
Vue.component('TableDetails', TableDetails)
//Modal
Vue.component('Modal', Modal)
Vue.component('ModalToCRMAnalysis', ModalToCRMAnalysis);
// Admin Header
Vue.component('AdminHeader', AdminHeader)
// Confirme Modal
Vue.component('ConfirmModal', ConfirmModal)

// Dictionaries
Vue.component('Dictionary', Dictionary)
// DoubleLine
Vue.component('DoubleLine', DoubleLine)
//MoneyField
Vue.component('money-field', moneyField)
// SummaryLine
Vue.component('SummaryLine', SummaryLine)

Vue.mixin(myMixin)

Vue.use(money, { precision: 4 })

Vue.component('vue-draggable-resizable', VueDraggableResizable)

Vue.directive('mask', VueMaskDirective)


Vue.use(Toast, {
    draggable: false,
})

Vue.prototype.$qs = qs
Vue.prototype.Rest = Rest

Vue.config.productionTip = false

// I18N SETUP
import i18n from './plugins/i18next'

import './registerServiceWorker'

new Vue({
    i18n: i18n,
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app')
