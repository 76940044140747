import Rest from '../../../plugins/Rest'
import clone from 'lodash.clone'
import validation from './../../../plugins/validation'
import { GlobalStatus } from '../../../plugins/constsTypes'
const state = {
    modal: {
        fields: {
            name: '',
            shortName: '',
            contactId: '',
            NIP: '',
            state: '',
            city: '',
            postCode: '',
            post: '',
            street: '',
            apartmentNumber: '',
            houseNumber: '',
            email: '',
            phoneNumber: '',
            website: '',
            contactType: '',
            clientGuardian: '',
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {
                    name: '',
                    shortName: '',
                    contactId: '',
                    NIP: '',
                    state: '',
                    city: '',
                    postCode: '',
                    post: '',
                    street: '',
                    apartmentNumber: '',
                    houseNumber: '',
                },
            },
            stepTwo: {
                validation: true,
                fields: {
                    email: '',
                    phoneNumber: '',
                    website: '',
                    email: '',
                    contactType: '',
                    clientGuardian: '',
                },
            },
        },
    },
    details: {
        name: '',
        shortName: '',
        contactId: '',
        NIP: '',
        state: '',
        city: '',
        postCode: '',
        post: '',
        street: '',
        apartmentNumber: '',
        houseNumber: '',
        email: '',
        phoneNumber: '',
        website: '',
        contactType: '',
        clientGuardian: '',
    },
    request: {
        populate: [
            {
                localField: 'clientGuardian',
                foreignField: '_id',
                from: 'users',
                as: 'clientGuardian',
            },
        ],
        searchFields: ['name', 'contactId'],
        defaultSearchFields: ['name'],
        select:
            'isDeleted _id name NIP city street phoneNumber email representatives folders houseNumber apartmentNumber postCode branches clientGuardian status contactId',
        where: {
            isDeleted: false,
        },
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        sort: 'name',
        pages: 1,
        defaultSort: 'name',
        loading: false,
    },
    input: {
        items: [],
        search: '',
        limit: 20,
        sort: 'name',
        defaultSort: 'name',
    },
    statuses: [],
    registry: {},
    dictFields: { contactType: '' },
}

const getters = {
    getContactModal: state => state.modal.fields,
    getContactError: state => state.modal.errors,
    getContactsTable: state => state.table,
    getContactsInput: state => state.input,
    getContactStatuses: state => state.statuses,
    getContactsSearchFields: state => state.request,
    getContactsRegistry: state => state.registry,
    getContactDetails: state => state.details,
}

const actions = {
    async checkIsNipAvailable({ commit, dispatch }, payload) {
        try {
            const nip = payload.nip

            const res = await new Rest(
                'GET',
                `contacts/checkIsNipAvailable/${nip}`
            ).send()
            return res.data?.checkIsNipAvailable
        } catch (err) {
            console.log(err)
        }
    },
    async fetchContactsTable({ commit, dispatch }) {
        state.table.loading = true
        try {
            let response = await new Rest('GET', 'contacts')
                .select(state.request.select)
                .limit(state.table.limit)
                .page(state.table.page)
                .search(state.request.searchFields, state.table.search)
                .where(state.request.where)
                .populate(state.request.populate)
                .sort(state.table.sort || state.table.defaultSort)
                .send()
            dispatch('fetchContactsMenager')
            commit('setContactsTable', response.data)
        } catch (err) {
            console.error(err)
        }
        state.table.loading = false
    },
    async fetchContactsInputs({ commit, dispatch }, payload) {
        try {
            let response = await new Rest('GET', 'contacts')
                .select(state.request.select)
                .limit(state.input.limit)
                .search(state.request.searchFields, state.input.search)
                .where(state.request.where)
                .populate(state.request.populate)
                .sort(payload || state.input.sort || state.input.defaultSort)
                .send()

            await commit('setContactsInput', response.data)
            return true
        } catch (err) {
            console.error(err)
        }
    },
    async fetchContactsMenager({ commit }) {
        try {
            let response = await new Rest('GET', 'registries')
                .select('currentMenager name')
                .where({ url: 'contacts' })
                .populate([
                    {
                        path: 'currentMenager',
                        select: 'name lastname mail status phone picture',
                    },
                ])
                .send()
            commit('setContactsMenager', response.data.items)
        } catch (err) {
            console.error(err)
        }
    },
    async fetchContact({ commit, dispatch }, payload) {
        try {
            let contactReq = new Rest('GET', `contacts/${payload.id}`)
                .populate([
                    {
                        path: 'createdBy',
                        select: 'name lastname mail status phone picture',
                    },
                    {
                        path: 'clientGuardian',
                        select: 'name lastname',
                    },
                ])
                .select('-folders -representatives')
            let statusesReq = new Rest('GET', `registries/contacts/statuses`)
                .select('name _id isDeleted state')
                .where({ isDeleted: false })
            let { 1: statuses, 0: response } = await Promise.all([
                contactReq.send(),
                statusesReq.send(),
            ])

            commit('setContactDetails', response.data)
            commit('setContactStatuses', statuses.data.items)
            dispatch('fetchContactsMenager')
            if (payload.next) payload.next()
        } catch (err) {
            //HANDLE ERRORS
            console.error(err)
        }
    },

    async createContact({ commit, dispatch }, payload) {
        try {
            await new Rest('POST', 'contacts')
                .setBody(state.modal.fields)
                .send()
            commit('clearContactModal')
            commit('clearContactsErrors')
            await dispatch('fetchContactsTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            //HANDLE ERRORS
            validation.setErrors(state.modal.errors, err)
            commit('setSnackbars', { type: 'error' })
        }
    },

    async updateContact({ state, commit, dispatch }, payload) {
        if (state.modal.errors.stepOne.validation) {
            try {
                await new Rest('PUT', `contacts/${payload.id}`)
                    .setBody(state.modal.fields)
                    .send()
                commit('clearContactModal')
                commit('clearContactsErrors')
                dispatch('fetchContact', { id: payload.id })
                commit('setSnackbars', { type: 'success' })
                return true
            } catch (err) {
                console.error(err)
                //HANDLE ERRORS
                validation.setErrors(state.modal.errors, err)
                commit('setSnackbars', { type: 'error' })
            }
        }
    },

    async updateContactStatus({ commit, dispatch }, payload) {
        commit('setContactModal')
        commit('setContactStatus', payload.status)
        try {
            let response = await new Rest('PUT', `contacts/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            commit('setSnackbars', { type: 'success' })
        } catch (err) {
            console.error(err)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async deleteContact({ commit, dispatch }, payload) {
        try {
            await new Rest('DELETE', `contacts/${payload.id}`).send()
            dispatch('fetchContactsTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            console.error(err)
        }
    },
}

const mutations = {
    setContactsTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
    setContactsInput(state, payload) {
        state.input.items = payload.items || state.input.items
        if (payload.search != undefined) state.input.search = payload.search
        state.input.limit = payload.limit || state.input.limit
        if (payload.sort != undefined) state.input.sort = payload.sort
        state.input.items = state.input.items
            .filter(e => e.status.state != GlobalStatus.closed)
            .flatMap(e => {
                return {
                    ...e,
                    branches: e.branches.filter(el => !el.isDisabled),
                    representatives: e.representatives.filter(
                        el => !el.isDisabled
                    ),
                }
            })
    },
    setContactsSearch(state, payload) {
        state.request.searchFields = payload
    },

    setContactStatuses(state, payload) {
        state.statuses = payload
    },

    setContactDetails(state, payload) {
        state.details = payload
    },

    setContactModal(state, payload) {
        let obj = payload ? payload : clone(state.details)
        state.modal.fields = obj
    },
    clearContactModal(state, payload) {
        state.modal.fields = {}
    },
    clearContactInput(state) {
        state.input.items = []
    },
    setContactStatus(state, payload) {
        state.modal.fields.status = payload
    },
    setContactsMenager(state, payload) {
        state.registry = payload[0]
    },
    clearContactsErrors(state) {
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step].fields) {
                state.modal.errors[step].fields[key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
    clearContactsTable(state) {
        state.table.page = 1
        state.table.search = ''
        state.request.where = { isDeleted: false }
        state.table.sort = 'name'
        state.request.searchFields = ['name']
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}

//const modernProblems = require('modern-solutions')
