import Rest from './../../../plugins/Rest'
import clone from 'lodash.clone'
import i18n from './../../../plugins/i18next'
import validation from './../../../plugins/validation'
import moment from 'moment'
import clearSearch from './../../../plugins/clearSearch'
const state = {
    modal: {
        fields: {
            name: '',
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {
                    manufacturerIndex: '',
                    model: '',
                },
            },
            stepTwo: {
                validation: true,
                fields: {},
            },
        },
        step: 1,
    },
    request: {
        populate: [
            {
                localField: 'model',
                foreignField: '_id',
                from: 'models',
                as: 'model',
            },
            {
                from: 'devices',
                localField: 'device',
                foreignField: '_id',
                as: 'device',
            },
        ],
        searchFields: [''],
        defaultSearchFields: [''],
        select: '_id content',
        where: {
            isDeleted: false,
        },
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: '-createdAt',
        loading: false,
    },
    statuses: [],
    details: {},
    registry: {},
    dictFields: { manufacturer: '', modelSeries: '' },
}

const getters = {
    getInvoiceModal: state => state.modal.fields,
    getInvoiceError: state => state.modal.errors,
    getInvoiceStep: state => state.modal,
    getInvoiceTable: state => state.table,
    getInvoiceStatuses: state => state.statuses,
    getInvoiceSearchFields: state => state.request,
    getInvoiceDetails: state => state.details,
    getInvoiceRegistry: state => state.registry,
}

const actions = {
    async createInvoice({ commit, dispatch }, payload) {
        try {
            const resp = await new Rest('POST', 'invoices')
                .setBody(payload)
                .send()

            commit('setSnackbars', { type: 'success' })
            return resp.data
        } catch (err) {
            //HANDLE ERRORS
            if (err) {
                commit('setSnackbars', { type: 'error' })
                validation.setErrors(state.modal.errors, err)
            }
        }
    },

    async findLastInvoiceForSettlement({ commit, dispatch }, payload) {
        try {
            const resp = await new Rest(
                'POST',
                'invoices/findLastInvoiceForSettlement'
            )
                .setBody(payload)
                .send()

            return resp.data
        } catch (err) {
            //HANDLE ERRORS
            if (err) {
                commit('setSnackbars', { type: 'error' })
                validation.setErrors(state.modal.errors, err)
            }
        }
    },
}

const mutations = {}

export default {
    state,
    getters,
    actions,
    mutations,
}
