<template>
    <!--
    Close confirme modala 
    @event close-confirm 
    -->
    <v-dialog v-model="openConfirm" :width="width" @click:outside="$emit('close-confirm')">
        <v-card class="pa-5">
            <!-- @slot Use this slot to add confirme modal content-->
            <slot name="main"></slot>
            <!-- @slot Use this slot to add confirme modal buttons-->
            <slot name="buttons"></slot>
        </v-card>
    </v-dialog>
</template>

<script>
import Vue from 'vue'
/**
 * Confirme Modal
 * @displayName Confirme Modal
 */
export default {
    props: {
        /**
         * Open Confirme modal
         */
        openConfirm: {
            type: Boolean,
            default: false,
        },
        width: {
            type: String,
            default: '500'
        }
    },
}
</script>
