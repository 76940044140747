<template>
    <div class="box__wrapper">
        <div class="box__header">
            <div class="box__header__text">
                <span style="margin-right: -5px;"
                    ><button @click="goToServices()">
                        {{ this.$t('service:service').toUpperCase() }}
                    </button></span
                ><span>-</span>
                <span style="margin-left: -5px;"
                    ><button @click="resetCurrentWeek()">
                        {{ this.$t('service:reviewOfTheWeek').toUpperCase() }}
                    </button>
                </span>
            </div>
            <div class="box__header__arrows">
                <button @click="prev" class="prev">
                    <v-icon medium> mdi-forward </v-icon>
                </button>

                <button @click="next" class="next">
                    <v-icon medium> mdi-forward </v-icon>
                </button>
            </div>
            <div class="box__header__open">
                <button @click="toggleOpenServices">
                    <v-icon v-bind:class="{ active: status }" medium
                        >mdi-alpha-o-circle</v-icon
                    >
                </button>
            </div>
            <div class="box__header__exit">
                <button @click="closeCompnent">
                    <v-icon medium> mdi-close-circle </v-icon>
                </button>
            </div>
        </div>

        <div v-if="servicesForWeek.length > 0" class="container">
            <div
                v-for="[date, servicesContainer] of servicesForWeek"
                :key="date"
            >
                <task-driver-sheet
                    :date="date"
                    :services="servicesContainer"
                ></task-driver-sheet>
            </div>
        </div>
        <div class="container" v-else>
            <div v-for="index in 7" :key="index">
                <task-driver-sheet
                    :date="[]"
                    :services="[]"
                ></task-driver-sheet>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import TaskDriverSheet from './TaskDriverSheet.vue'
import { mapActions, mapMutations } from 'vuex'
const STATUSES = ['Otwarty', 'Otwarty E.N']
export default {
    name: 'TaskDriver',
    data() {
        return {
            momentMonday: '',
            date: '',
            endDate: this.$moment()
                .add(7, 'days')
                .format('YYYY-MM-DD')
                .toString(),
            dateType: 'plannedServiceDate',
            servicesForWeek: [],
            status: '',
        }
    },
    components: {
        TaskDriverSheet,
    },
    methods: {
        ...mapActions(['fetchServiceTable']),
        ...mapMutations(['setServiceWhere']),
        async next() {
            const nextMonday = this.$moment(this.date, 'YYYY-MM-DD').add(
                7,
                'days'
            )
            this.date = this.$moment(nextMonday).format('YYYY-MM-DD').toString()
            this.endDate = this.$moment(nextMonday)
                .add(7, 'days')
                .format('YYYY-MM-DD')
                .toString()

            await this.serviceContainerByTechnician()
        },

        async prev() {
            const nextMonday = this.$moment(this.date, 'YYYY-MM-DD').subtract(
                7,
                'days'
            )
            this.date = this.$moment(nextMonday).format('YYYY-MM-DD').toString()

            this.endDate = this.$moment(nextMonday)
                .add(7, 'days')
                .format('YYYY-MM-DD')
                .toString()

            await this.serviceContainerByTechnician()
        },

        async serviceContainerByTechnician() {
            this.servicesForWeek = []
            const res = await this.createWeekServiceMapPerDay()

            const groupBy = []

            for (let serviceMap of res) {
                const servicePerDay = new Map()

                for (let service of serviceMap[1]) {
                    if (service.technician) {
                        if (servicePerDay.has(service.technician.mail)) {
                            const servicesContainer = servicePerDay.get(
                                service.technician.mail
                            )
                            servicesContainer.push(service)
                            servicePerDay.set(
                                service.technician.mail,
                                servicesContainer
                            )
                        } else {
                            servicePerDay.set(service.technician.mail, [
                                service,
                            ])
                        }
                    }
                }

                serviceMap[1] = servicePerDay

                groupBy.push(serviceMap)
            }

            this.servicesForWeek = groupBy

            await this.filterTableByCurrentStatus()
        },

        async createWeekServiceMapPerDay() {
            let res = await this.fetchAvailableWeekServices()

            let fakeDateSet = new Map()

            let fakeStartDate = this.$moment(this.date)

            for (let i = 0; i < 7; i++) {
                fakeDateSet.set(
                    fakeStartDate.format('YYYY-MM-DD') + 'T00:00:00.000Z',
                    []
                )
                fakeStartDate = fakeStartDate.add(1, 'days')
            }

            let servicesMap = new Map()

            for (const service of res.data.items) {
                if (servicesMap.has(service.plannedServiceDate)) {
                    const serviceContainer = servicesMap.get(
                        service.plannedServiceDate
                    )
                    serviceContainer.push(service)
                    servicesMap.set(
                        service.plannedServiceDate,
                        serviceContainer
                    )
                } else {
                    servicesMap.set(service.plannedServiceDate, [service])
                }
            }

            for (let value of fakeDateSet) {
                if (!servicesMap.has(value[0])) servicesMap.set(value[0], [])
            }

            servicesMap = Array.from(servicesMap)
            servicesMap.sort((a, b) => {
                return new Date(a[0]) - new Date(b[0])
            })

            return servicesMap
        },

        async fetchAvailableWeekServices() {
            this.servicesForWeek = []
            let where = {}

            where[this.dateType] = {
                $gte: new Date(new Date(this.date).setHours(0, 0, 0)),
                $lt: this.endDate,
            }
            let response = await new this.Rest('GET', 'service')
                .where(where)
                .select(
                    'realizationCheckboxes plannedServiceDate index technician.name technician.lastname status.name technician.mail '
                )
                .populate([
                    {
                        localField: 'technician',
                        foreignField: '_id',
                        from: 'users',
                        as: 'technician',
                    },
                    {
                        localField: 'device',
                        foreignField: '_id',
                        from: 'devices',
                        as: 'device',
                    },
                    {
                        localField: 'device.model',
                        foreignField: '_id',
                        from: 'models',
                        as: 'device.model',
                    },
                    {
                        localField: 'device.client',
                        foreignField: '_id',
                        from: 'contacts',
                        as: 'device.client',
                    },
                ])
                .send()

            return response
        },
        closeCompnent() {
            const draggableItem = document.getElementById('task__draggable-box')
            draggableItem.style.visibility = 'hidden'
        },
        goToServices() {
            this.$router.push('/service')
        },
        async resetCurrentWeek() {
            const findMonday = this.$moment().startOf('week')

            this.momentMonday = findMonday
            const dateOfMonday = this.$moment(findMonday._d)
                .format('YYYY-MM-DD')
                .toString()
            this.date = dateOfMonday
            this.endDate = this.momentMonday
                .add(7, 'days')
                .format('YYYY-MM-DD')
                .toString()
            await this.serviceContainerByTechnician()
        },

        async filterTableByCurrentStatus() {
            if (this.status === STATUSES[0]) {
                this.setServiceWhere({
                    isDeleted: false,
                    $or: [
                        { 'status.name': 'Otwarty' },
                        { 'status.name': 'Otwarty E.N' },
                    ],
                })
                await this.fetchServiceTable()
            } else {
                this.setServiceWhere({
                    isDeleted: false,
                })
                await this.fetchServiceTable()
            }
        },

        async toggleOpenServices() {
            this.status = this.status === STATUSES[0] ? '' : STATUSES[0]

            await this.filterTableByCurrentStatus()
        },
    },

    mounted() {
        this.resetCurrentWeek()
        this.$root.$on('onServiceAdd', async () => {
            await this.serviceContainerByTechnician()
            await this.filterTableByCurrentStatus()
        })
    },
}
</script>
<style lang="sass" scoped>
.box__header
    display: flex
    flex-direction: row
    // margin-left: calc(50% - 24px)
    &__text
        display: flex
        flex-grow: 1
        margin-left: 12px
        gap: 10px
        color: rgb(140, 140, 140)
        button
            font-size: 12px
        :hover
            color: rgb(25, 170, 141,0.99)
    &__arrows
        flex-grow: 2
        text-align: left
        padding-left: 185px
        padding-top: 5px
        button.next
            transition: scaleX(1.2)
            .v-icon
                color: rgba(189, 189, 189)
        button.prev
            .v-icon
                transform: scaleX(-1)
                color: rgba(189, 189, 189)
        button.prev
            .v-icon:hover
                color: rgb(25, 170, 141,0.99)
        button.next
            .v-icon:hover
                color: rgb(25, 170, 141,0.99)
                transition: 0.6s ease-in
                transition-timing-function: ease-out
    &__exit
        .v-icon
            color: rgba(189, 189, 189)
            font-size: 16px
            padding-right: 10px
        .v-icon:hover
            color: rgba(255,255, 255)
    &__open
        .v-icon
            color: rgba(189, 189, 189)
            font-size: 16px
            padding-right: 10px
            &.active
                color: rgb(107, 146, 111)
            &.active:hover
                color: rgb(107, 146, 111)
        .v-icon:hover
            color: rgba(189, 189, 189)

.box__wrapper
    min-width: 800px
    background: rgba(245, 245, 245, 1)
    border-radius: 4px

.container
    padding-top: 0px
    padding-right: 0.6rem
    padding-left: 0.6rem
    padding-bottom: 10px
    font-size: 0.8rem
    display: flex
    gap: 6px
</style>
