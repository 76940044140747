<template >
    <div v-if="open" class="Content">
        <div class="Header">
            <h1>{{$t(`${nameSpaceI18n}:alertHeader`)}}</h1>
        </div>
        <div class="Central">
            <span
                >{{$t(`${nameSpaceI18n}:alertDescription`)}}</span
            >
            <span>{{$t(`${nameSpaceI18n}:alertRedContent`)}}</span>
            <span>{{`${$t(`${nameSpaceI18n}:alertMainContent`)}: ${annexCnt}`}}</span>
            <v-btn  color="warning" elevation="2" @click="onClose">{{$t(`${nameSpaceI18n}:alertButton`)}}</v-btn>
            <span>{{$t(`${nameSpaceI18n}:alertInfo`)}}</span>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    },
    props:{
        open:{
            type:Boolean,
            required:true,
            default:false
        },
        nameSpaceI18n:{
            type:String
        },
        annexCnt:{
            type:Number
        },
        onClose:{
            type:Function,
            // required:true
        }
    }
}
</script>
<style lang="scss">
.Content {
    z-index: 999999;
    width: 40vmax;
    background: white;
    border: solid 3px rgb(255, 211, 65);
    box-shadow: 0 0 20px 0;
    border-radius: 10px;
    position: fixed;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    padding: 10px;
    .Header {
        margin: 0 0 20px 0;
        h1 {
            text-align: center;
        }
    }
    .Central {
        > :nth-child(1) {
            display: block;
            text-align: center;
            font-weight: 600;
            font-size: 15px;
            margin: 0 0 15px 0;
        }
        > :nth-child(2) {
            display: inline-block;
            font-weight: 600;
            font-size: 15px;
            width: 100%;
            color: rgb(255, 111, 111);
            margin: 0 0 80px 0;
        }
        > :nth-child(3) {
            display: inline-block;
            width: 100%;
        }
        > :nth-child(4) {
           display: block;
           margin: 20px auto;            
        }
        > :nth-child(5) {
           display: block;
           margin: 40px 0 0 0;            
        }        
    }
}
</style>
