import Rest from './../../../plugins/Rest'
import validation from './../../../plugins/validation'
import { clone, cloneDeep } from 'lodash'
import moment from 'moment'
import store from '../../../store/index'
import clearSearch from './../../../plugins/clearSearch'
import representatives from '../Contacts/representatives'

const state = {
    validationSteps: {
        mainRecordValidationStepOne: false,
        mainRecordValidationStepTwo: false,
        editInformationValidationStepOne: false,
        editInformationValidationStepThree: false,
        crmEventValidationStepOne: false,
    },
    modal: {
        fields: {
            title: null,
            contractor: null,
            offerType: null,
            customerType: null,
            leadBoolean: false,
            prognosis: null,
            forecasting: '',
            threats: '',
            currentSupplier: '',
            finalEvaluationOfTheProcess: '',
            sourceOfAcquisition: '',
            crmEvent: {
                _id: null,
                description: null,
                representative: null,
                eventType: null,
                nextEventDate: null,
                nextEventTime: null,
                nextEventType: null,
                offerBoolean: false,
                offerStatus: null,
                representativeDecisionType: null,
            },
            CRMParticipants: [],
            evaluation: {
                devicesAmount: '',
                devicesLocation: '',
                websiteOpinion: '',
                socialMedia: '',
                emailFormat: '',
                customerShareCapital: '',
                scopeActivities: '',
                industry: '',
                comments: '',
                summary: '',
            },

            reportPage: {
                comments: '',
                lockValue: false,
            },
            __v: null,
        },
        newEventModal: {
            crmEvent: {
                description: null,
                representative: null,
                eventType: null,
                representativeDecisionType: null,
                nextEventDate: null,
                nextEventTime: null,
                nextEventType: null,
                offerBoolean: false,
                offerStatus: null,
            },
        },
        newReportItemModal: {
            crmReportItem: {
                model: null,
                deviceState: null,
                isAnnex: 'Nie',
                subscriptionType: null,
                fttrValue: 'Nie',
                durationOfTheContract: null,
                blankingContract: null,
            },
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {},
            },
            stepTwo: {
                validation: true,
                fields: {},
            },
            stepThree: {
                validation: true,
                fields: {},
            },
            evaluationStepOne: {
                validation: true,
                fields: {},
            },
            evaluationStepTwo: {
                validation: true,
                fields: {},
            },
        },
        contractorError: {
            contractor: '',
        },
        step: 1,
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: '-createdAt',
        loading: false,
    },
    request: {
        populate: [
            {
                localField: 'contractor',
                foreignField: '_id',
                from: 'contacts',
                as: 'contractor',
            },
            {
                localField: 'createdBy',
                foreignField: '_id',
                from: 'users',
                as: 'createdBy',
            },
            {
                localField: 'crmEvent.createdBy',
                foreignField: '_id',
                from: 'users',
                as: 'crmEvent[0].createdBy',
            },
        ],
        searchFields: ['title', 'contractor.name'],
        defaultSearchFields: ['title', 'contractor.name'],
        select:
            '_id __v title reportPage folders crmReports representative previousProcessWithTheClient evaluation CRMParticipants sourceOfAcquisition finalEvaluationOfTheProcess forecasting threats currentSupplier prognosis customerType offerBoolean leadBoolean crmEvent createdAt expirationDate status offerType clientType notes index webForm isDeleted contractor createdBy',
        where: {
            isDeleted: false,
        },
    },
    dictFields: {
        crmCustomerType: '',
        crmOfferType: '',
        crmEventType: '',
        crmEventPrognosis: '',
        crmReportDeviceSate: '',
        subscriptionType: '',
        durationOfTheContractDictionary: '',
    },
    statuses: [],
    details: {},
    registry: {},
}

const getters = {
    getCRMModal: state => state.modal.fields,
    getCRMRegistry: state => state.registry,
    getCRMTable: state => state.table,
    getCRMSearchFields: state => state.request,
    getCRMErrors: state => state.modal.errors,
    getCRMContractorErrors: state => state.modal.contractorError,
    getCRMStep: state => state.modal,
    getCRMStatuses: state => state.statuses,
    getCRMDetails: state => state.details,
    getCRMEventsData: state => state.details.crmEvent,
    getCRMNewEventModal: state => state.modal.newEventModal,
    getCRMNewReportItemModal: state => state.modal.newReportItemModal,
    getCRMParticipants: state => state.modal.fields.CRMParticipants,
    getValidationSteps: state => state.validationSteps,
    getCRMReportItems: state => state.details.crmReports,
}

const actions = {
    async createReportForLockedRecord({ commit, dispatch }, payload) {
        const data = {
            startDate: payload.startDate,
            endDate: payload.endDate,
            selectedUser: payload.selectedUser,
        }

        try {
            const response = await new Rest(
                'POST',
                'crm/createReportForLockedRecord'
            )
                .setBody(data)
                .send()

            if (response) {
                return response.data
            }
        } catch (err) {
            commit('setSnackbars', {
                type: 'error',
                text: 'Błąd generowania raportów',
            })
            console.log(err)
        }
    },

    async createReportForSelectedUserAndDate({ commit, dispatch }, payload) {
        const data = {
            startDate: payload.startDate,
            endDate: payload.endDate,
            selectedUser: payload.selectedUser,
        }

        try {
            const response = await new Rest(
                'POST',
                'crm/createReportForSelectedUserAndDate'
            )
                .setBody(data)
                .send()

            if (response) {
                return response.data
            }
        } catch (err) {
            commit('setSnackbars', {
                type: 'error',
                text: 'Błąd generowania raportu',
            })
            console.log(err)
        }
    },

    async setCRMValidation({ commit, dispatch }, payload) {
        commit('SET_CRM_VALIDATION', payload)
    },

    async createCRMAccessAlert({ commit, dispatch }, payload) {
        const mailArray = [payload.alertTo.mail]

        let alertDate = new Date()

        // alertDate = alertDate.getMinutes() + 1;
        const response = await new Rest('POST', '/alerts')
            .setBody({
                alertDate: new Date().setHours(1, 0, 0, 0),
                alertTime: moment()
                    .add(1, 'hour')
                    .add(1, 'minute')
                    .format('HH:mm'),
                mail: mailArray,
                description: payload.previousProcessIndex,
                to: {
                    users: [payload.alertTo],
                },
                itemId: payload.id,
                url: payload.url,
                content: {
                    action: 'ASK_CMR_ACCESS',
                    previousProcessIndex: payload.previousProcessIndex,
                    previousProcessId: payload.previousProcessId,
                },
            })
            .send()

        return true
    },
    async createNewEvent({ commit, dispatch }, payload) {
        try {
            const id = payload
            const response = await new Rest('POST', `crm/addEvent/${id}`)
                .setBody(state.modal.newEventModal)
                .send()
            return true
        } catch (err) {
            console.error(err)
        }
    },

    async createNewReportItem({ commit, dispatch }, payload) {
        try {
            const id = payload
            const response = await new Rest('POST', `crm/addReportItem/${id}`)
                .setBody(state.modal.newReportItemModal)
                .send()

            return true
        } catch (err) {
            console.error(err)
        }
    },

    async editReportItem({ commit, dispatch }, payload) {
        try {
            const id = payload

            const response = await new Rest('POST', `crm/editReportItem/${id}`)
                .setBody(state.modal.newReportItemModal)
                .send()

            return true
        } catch (err) {
            console.log(err)
        }
    },

    async editEvent({ commit, dispatch }, payload) {
        try {
            const id = payload

            const response = await new Rest('POST', `crm/editEvent/${id}`)
                .setBody(state.modal.newEventModal)
                .send()

            return true
        } catch (err) {
            console.log(err)
        }
    },

    async findAllEventsBySelectedDate(
        { state, dispatch, commit, rootGetters },
        payload
    ) {
        state.table.loading = true

        const startDate = payload.startDate
        const endDate = payload.endDate
        try {
            let response = await new Rest('GET', 'crm/eventsByDate')
                .select(state.request.select)
                .setParams({ startDate: startDate, endDate: endDate })
                .limit(state.table.limit)
                .page(state.table.page)
                .where(state.request.where)
                .populate(state.request.populate)
                .search(state.request.searchFields, state.table.search)
                .sort(state.table.sort || state.table.defaultSort)
                .send()

            dispatch('fetchCRMRegistry')
            commit('setCRMTable', response.data)
        } catch (error) {
            console.error(error)
        }
        state.table.loading = false
    },

    async fetchCRMTable({ state, dispatch, commit, rootGetters }) {
        state.table.loading = true

        try {
            let response = await new Rest('GET', 'crm')
                .select(state.request.select)
                .limit(state.table.limit)
                .page(state.table.page)
                .where(state.request.where)
                .populate(state.request.populate)
                .search(state.request.searchFields, state.table.search)
                .sort(state.table.sort || state.table.defaultSort)
                .send()

            dispatch('fetchCRMRegistry')
            commit('setCRMTable', response.data)
        } catch (error) {
            console.error(error)
        }
        state.table.loading = false
    },

    // ACTION - FETCH REGISTRY NAME FROM REGISTIRES COLLECTION
    async fetchCRMRegistry({ commit, dispatch }, payload) {
        let registry = await new Rest('GET', 'registries')
            .select('currentMenager name')
            .where({ url: 'crm' })
            .populate([
                {
                    path: 'currentMenager availableMenagers',
                    select: 'name lastname mail status phone picture',
                },
            ])
            .send()

        commit('setCRMRegistry', registry.data.items)
    },
    async createCRM({ state, commit, dispatch }, payload) {
        try {
            await new Rest('POST', 'crm').setBody(state.modal.fields).send()
            commit('CLEAR_CRM_MODAL')
            // commit('clearOfferErrors')
            dispatch('fetchCRMTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            //HANDLE ERRORS
            if (err) {
                commit('setSnackbars', { type: 'error' })
            }
        }
    },
    async editCRM({ commit, dispatch }, payload) {
        try {
            const resposne = await new Rest('PUT', `crm/${payload.id}`)
                .setBody(state.modal.fields)
                .send()

            return true
        } catch (err) {
            console.error(err)
            commit('setSnackbars', { type: 'error' })
            return false
        }
    },
    async deleteCRM({ commit, dispatch }, payload) {
        try {
            await new Rest('DELETE', `crm/${payload.id}`).send()
            dispatch('fetchCRMTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            console.error(err)
        }
    },
    async fetchCRMRecord({ state, commit, dispatch }, payload) {
        try {
            let crmRecord = await new Rest('GET', `crm/${payload.id}`)
                .populate(['contractor', 'createdBy'])
                .select(state.request.select)
                .send()

            let statusesReq = new Rest('GET', `registries/crm/statuses`)
                .select('name _id isDeleted color state')
                .where({ isDeleted: false })
                .send()

            let { 0: response, 1: statuses } = await Promise.all([
                crmRecord,
                statusesReq,
            ])

            dispatch('fetchCRMRegistry')
            dispatch('findUsersWithAccessToCRM')

            commit('SET_CRMParticipants', response.data.CRMParticipants)

            commit('setCRMDetails', response.data)
            commit('setCRMStatuses', statuses.data.items)

            if (payload.next) payload.next()
        } catch (err) {
            console.error(err)
        }
    },

    async findUsersWithAccessToCRM({ state, commit, dispatch }, payload) {
        let response = await new Rest('GET', 'crm/accessToCRM').send()
        return response.data
    },
    async checkUserCanEnterToCRM({ state, commit, dispatch }, payload) {
        let response = await new Rest('POST', 'crm/accessToCRM')
            .setBody(payload)
            .send()
        if (response.data === 'OK') return true
        return false
    },

    async updateCRMStatus({ commit, dispatch }, payload) {
        commit('setCRMModal')
        commit('setCRMStatus', payload.status)
        try {
            await new Rest('PUT', `crm/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            commit('setSnackbars', { type: 'success' })
        } catch (err) {
            console.error(err)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async setCRMStep({ commit, dispatch }, payload) {
        commit('SET_CRM_STEP', payload)
    },
    async deleteReportItemFunc({ commit, dispatch }, payload) {
        const idToDelete = payload.itemId
        const routeId = payload.routeId

        try {
            const response = await new Rest('POST', `crm/delete/${routeId}`)
                .setBody({
                    routeId,
                    idToDelete,
                })
                .send()
            return true
        } catch (err) {
            console.log(err)
            return false
        }
    },
}

const mutations = {
    SET_LOCK_VALUE(state, payload) {
        state.modal.fields.reportPage.lockValue = true
        state.modal.fields.reportPage.lockDate = new Date()
    },
    SET_UNLOCK_VALUE(state, payload) {
        state.modal.fields.reportPage.lockValue = false
        state.modal.fields.reportPage.lockDate = null
    },
    SET_CRM_VALIDATION(state, payload) {
        state.validationSteps[payload.validationName] = payload.value
        console.log(state.validationSteps)
    },
    SET_CRME_STEP(state, payload) {
        state.modal.step = payload
    },
    CLEAR_CRM_REPORT_NEW_ITEM_MODAL() {
        state.modal.newReportItemModal.crmReportItem = {
            model: null,
            deviceState: null,
            isAnnex: 'Nie',
            subscriptionType: null,
            fttrValue: 'Nie',
            durationOfTheContract: null,
            blankingContract: null,
        }
    },
    CLEAR_CRM_EVENT_MODAL() {
        state.modal.step = 1
        state.modal.newEventModal.crmEvent = {
            description: null,
            representative: null,
            eventType: null,
            representativeDecisionType: null,
            nextEventDate: null,
            nextEventTime: null,
            nextEventType: null,
            offerBoolean: false,
            offerStatus: null,
        }
    },
    CLEAR_CRM_MODAL() {
        state.modal.step = 1
        state.modal.fields = {
            title: null,
            contractor: null,
            offerType: null,
            customerType: null,
            leadBoolean: false,
            prognosis: null,
            forecasting: '',
            threats: '',
            currentSupplier: '',
            finalEvaluationOfTheProcess: '',
            sourceOfAcquisition: '',
            crmEvent: {
                _id: null,
                description: null,
                representative: null,
                eventType: null,
                nextEventDate: null,
                nextEventType: null,
                offerBoolean: false,
                representativeDecisionType: null,
            },
            CRMParticipants: [],
            evaluation: {
                devicesAmount: '',
                devicesLocation: '',
                websiteOpinion: '',
                socialMedia: '',
                emailFormat: '',
                customerShareCapital: '',
                scopeActivities: '',
                industry: '',
                comments: '',
                summary: '',
            },
            reportPage: {
                comments: '',
                lockValue: false,
            },
        }
    },
    setCRMTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total

        if (payload.search != undefined) state.table.search = payload.search

        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
    setCRMRegistry(state, payload) {
        state.registry = payload[0]
    },
    setCRMStatuses(state, payload) {
        state.statuses = payload
    },
    setCRMStatus(state, payload) {
        state.modal.fields.status = payload
    },
    setCRMDetails(state, payload) {
        state.details = payload
    },
    SET_CRM_NEW_EVENT_MODAL(state, payload) {
        state.modal.newEventModal.crmEvent = cloneDeep(payload)
        if (
            payload.representative !== null &&
            payload.representative.length > 0
        ) {
            state.modal.newEventModal.crmEvent.representative =
                payload.representative[0]
        }
        // DATES FORMATS
        if (state.modal.newEventModal.crmEvent.nextEventDate !== null) {
            state.modal.newEventModal.crmEvent.nextEventDate = moment(
                state.modal.newEventModal.crmEvent.nextEventDate
            ).format('YYYY-MM-DD')
        }
    },
    SET_CRM_REPORT_MODAL(state, payload) {
        state.modal.newReportItemModal.crmReportItem = payload
    },
    setCRMModal(state, payload) {
        let obj = payload ? payload : cloneDeep(state.details)

        state.modal.fields = obj

        store.commit('setRepresentativesInput', {
            items: obj?.contractor?.representatives,
        })
        state.modal.fields.representative = obj?.contractor?.representatives?.find(
            e => e._id === obj.representative
        )
        state.modal.fields.expirationDate = obj.expirationDate
            ? moment(obj.expirationDate).format('YYYY-MM-DD')
            : ''
    },
    SET_CRMParticipants(state, payload) {
        state.modal.fields.CRMParticipants = payload
    },

    SET_CRM_EVENT_DATA(state, payload) {
        state.details.crmEvent = payload
    },
    setCRMSearch(state, payload) {
        state.request.searchFields = payload
    },
    clearCRMTable(state, payload) {
        clearSearch.clearSearchTable(state)
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
