<template>
    <tbody>
        <tr
            :class="`${isDisabled(item) ? 'disabled' : ''}`"
            v-for="(item, index) in items"
            :key="index"
        >
            <td class="truncate">
                <DoubleLine
                    :firtstLine="
                        $moment(item.createdAt).format(`DD-MM-YYYY`) ||
                        $t('global:empty')
                    "
                    :secondLine="
                        $moment(item.createdAt).format(`HH:mm`) ||
                        $t('global:empty')
                    "
                />
            </td>

            <td class="truncate">
                <span
                    >{{ $get(item, 'createdBy.name', '') }}
                    {{ $get(item, 'createdBy.lastname', '') }}</span
                >
            </td>
            <td class="py-2">
                <span>{{ item.note }}</span>
            </td>

            <td>
                <v-btn
                    @click="$emit('details', item)"
                    :action="'edit'"
                    icon
                    small
                >
                    <v-icon size="20" color="secondary">mdi-pencil</v-icon>
                </v-btn>
            </td>
        </tr>
    </tbody>
</template>
<script>
export default {
    props: ['items'],
    methods: {
        isDisabled(item) {
            return item.isDisabled
        },
    },
}
</script>
