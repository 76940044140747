import Rest from './../../../plugins/Rest'
import findIndex from 'lodash.findindex'
import differenceBy from 'lodash.differenceby'
import moment from 'moment'

const state = {
    servicePartsList: { items: [] },
    badge: false,
    modal: {
        fields: {
            partsTable: [],
            soldPartsInvoiceNumber: '',
            description: '',
            internalPaymentDate: '',
            internalDocumentNumber: '',
            externalPaymentDate: '',
            externalDocumentNumber: '',
            sum: 0,
        },
    },
    details: {
        partsTable: [],
        soldPartsInvoiceNumber: '',
        description: '',
        internalPaymentDate: '',
        internalDocumentNumber: '',
        externalPaymentDate: '',
        externalDocumentNumber: '',
        sum: 0,
    },
}

const getters = {
    getServicePartsList: state => state.servicePartsList,
    getServicePartsTable: state => state.modal.fields.partsTable,
    getServicePartsFields: state => state.modal.fields,
    getServicePartsDetails: state => state.details,
    getServicrPartsBadge: state => state.badge,
}

const actions = {
    async fetchServiceParts({ commit }, payload) {
        try {
            let response = await new Rest('GET', 'parts')
                .select('manufacturerIndex name price substitute')
                .search(payload.searchFields, payload.search)
                .send()

            commit('setServicePartsList', response.data.items)
            return response.data.items
        } catch (err) {
            console.error(err)
        }
    },
    async fetchServicePartsTab({ commit }, payload) {
        state.badge = false
        try {
            let serviceReq = new Rest('GET', `service/${payload.id}`).select(
                'parts'
            )
            let { 0: response } = await Promise.all([serviceReq.send()])
            commit('setServicePartsDetails', response.data.parts)
            if (payload.badge) {
                commit('setServicePartsBadge', response.data.parts)
            }
        } catch (error) {
            console.error(error)
        }
    },
    async updateServiceParts({ commit, dispatch }, payload) {
        let temp = [0]

        const table = [...state.modal.fields.partsTable]

        try {
            table.forEach(item => {
                if (item.wasUsed) temp.push(item.price * item.quantity)
            })

            // let sum = parseInt(temp.reduce((a, b) => a + b))
            let sum = temp.reduce((a, b) => a + b)
            state.modal.fields.sum = sum
            await new Rest('PUT', `service/${payload.id}`)
                .setBody({ parts: state.modal.fields })
                .send()
            dispatch('fetchServicePartsTab', { id: payload.id })
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            commit('setSnackbars', { type: 'error' })
        }
    },
}

const mutations = {
    setServicePartsList(state, payload) {
        if (state.modal.fields.partsTable.length > 0) {
            state.servicePartsList.items = payload
        } else state.servicePartsList.items = payload
    },
    setServicePartsTable(state, payload) {
        if (payload) {
            payload.quantity = 1
            if (payload._id) {
                state.modal.fields.partsTable = [
                    payload,
                    ...state.modal.fields.partsTable,
                ]
            }
        }
    },
    setServicePartsDetails(state, payload) {
        state.details = {
            partsTable: [],
            soldPartsInvoiceNumber: '',
            description: '',
            internalPaymentDate: '',
            internalDocumentNumber: '',
            externalPaymentDate: '',
        }
        if (payload) {
            state.details = { ...state.details, ...payload }
        }
    },
    setPartInfo(state, payload) {
        let arr = state.modal.fields.partsTable
        let index = findIndex(arr, e => e._id == payload.id)
        arr[index].price = payload.price
        arr[index].quantity = payload.quantity
        arr[index].wasUsed = payload.wasUsed
        arr[index].sum = payload.quantity * payload.price
    },
    removeTablePart(state, payload) {
        let arr = state.modal.fields.partsTable
        let index = arr.findIndex(arr => arr._id == payload._id)
        arr.splice(index, 1)
    },
    setServicePartsModal(state) {
        state.modal.fields = { ...state.details }
        if (state.modal.fields.internalPaymentDate) {
            state.modal.fields.internalPaymentDate = moment(
                state.modal.fields.internalPaymentDate
            ).format('YYYY-MM-DD')
        }

        if (state.modal.fields.externalPaymentDate) {
            state.modal.fields.externalPaymentDate = moment(
                state.modal.fields.externalPaymentDate
            ).format('YYYY-MM-DD')
        }
    },
    setServicePartsBadge(state, payload) {
        if (payload && payload.partsTable && payload.partsTable.length > 0)
            state.badge = true
        else state.badge = false
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    // i18nOptions: { namespaces: ['', 'global'] },
}
