import Vue from 'vue'
import Router from 'vue-router'
import Overlay from './views/Global/Overlay'
import Rest from './plugins/Rest'
import store from './store/index'
import i18next from 'i18next'
import RentContractsCentral from './views/Registries/Rent-contracts/Central.vue'
import RentContractDetails from './views/Registries/Rent-contracts/Details.vue'
import PrintOrder from './../src/components/Registries/Service/PrintOrder/DocumentGenerator.vue'

Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            redirect: 'dashboard',
            meta: {
                requiresAuth: false,
            },
            component: Overlay,
            children: [
                {
                    path: 'dashboard',
                    name: 'dashboard',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () => import('./views/Global/Dashboard.vue'),
                },
                // === PROFILE ===
                {
                    path: 'profile',
                    name: 'profile',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Personal/Profile/Central.vue'),
                },
                // WYŁĄCZENIE ZADAŃ W APLIKACJI
                // === TASKS ===
                // {
                //     path: 'tasks',
                //     name: 'tasks',
                //     meta: {
                //         requiresAuth: true,
                //     },
                //     component: () =>
                //         import('./views/Personal/Tasks/Central.vue'),
                // },
                // === ALERTS ===
                {
                    path: 'alerts',
                    name: 'alerts',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Personal/Alerts/Central.vue'),
                },
                // === STORAGE ===
                {
                    path: 'storage',
                    name: 'storage',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Personal/Storage/Central.vue'),
                },
                // === CONTACTS ===
                {
                    path: 'contacts',
                    name: 'contacts',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Contacts/Central.vue'),
                },
                {
                    path: 'contacts/:id',
                    name: 'contacts',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Contacts/Details.vue'),
                },
                // === USERS ===
                {
                    path: 'users',
                    name: 'users',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Users/Central.vue'),
                },
                {
                    path: 'users/:id',
                    name: 'users',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Users/Details.vue'),
                },
                // === REGISTRIES ===
                {
                    path: 'registries',
                    name: 'registries',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Admin/Registries/Central.vue'),
                },
                {
                    path: 'registries/:id',
                    name: 'registries',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Admin/Registries/Details.vue'),
                },
                // === RENT_CONTRACTS === //
                {
                    path: 'rent-info',
                    name: 'rent-info',
                    meta: {
                        requiresAuth: true,
                    },
                    params: true,
                    component: RentContractsCentral,
                },
                {
                    path: 'rent-info/:id',
                    name: 'rent-info',
                    meta: {
                        requiresAuth: true,
                    },
                    component: RentContractDetails,
                },
                // === DEPARTMENTS ===
                {
                    path: 'departments',
                    name: 'departments',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Departments/Central.vue'),
                },
                {
                    path: 'departments/:id',
                    name: 'departments',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Departments/Details.vue'),
                },
                // === ROLES ===
                {
                    path: 'roles',
                    name: 'roles',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () => import('./views/Admin/Roles/Central.vue'),
                },
                {
                    path: 'dictionaries',
                    name: 'dictionaries',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Admin/Dictionaries/Dictionaries.vue'),
                },
                // === TRANSLATIONS ===
                {
                    path: 'translations',
                    name: 'translations',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Admin/Translations/Central.vue'),
                },
                {
                    path: 'translations/:namespace',
                    name: 'translations',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Admin/Translations/Details.vue'),
                },
                // === SESSION LOGS ===
                {
                    path: 'users-activity',
                    name: 'users-activity',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Admin/SessionLogs/Central.vue'),
                },
                {
                    path: 'templates',
                    name: 'templates',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () => import('./views/Global/Dashboard.vue'),
                },
                // === CARS ===
                {
                    path: 'cars',
                    name: 'cars',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Cars/Central.vue'),
                },
                {
                    path: 'cars/:id',
                    name: 'cars',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Cars/Details.vue'),
                },

                // === PARTS ===
                {
                    path: 'parts',
                    name: 'parts',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Parts/Central.vue'),
                },
                {
                    path: 'parts/:id',
                    name: 'parts',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Parts/Details.vue'),
                },

                // === SERVICE PRICE ===
                {
                    path: 'service-prices',
                    name: 'service-prices',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Service-prices/Central.vue'),
                },

                {
                    path: 'service-prices/:id',
                    name: 'service-prices',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Service-prices/Details.vue'),
                },

                // === MODELS ===
                {
                    path: 'models',
                    name: 'models',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Models/Central.vue'),
                },
                {
                    path: 'models/:id',
                    name: 'models',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Models/Details.vue'),
                },
                // === LEASING ===
                {
                    path: 'leasings',
                    name: 'leasings',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Leasing/Central.vue'),
                },
                {
                    path: 'leasings/:id',
                    name: 'leasings',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Leasing/Details.vue'),
                },
                // === OFFERS ===
                {
                    path: 'offers',
                    name: 'offers',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Offers/Central.vue'),
                },
                ,
                {
                    path: 'offers/:id',
                    name: 'offers',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Offers/Details.vue'),
                },
                {
                    path: 'crm',
                    name: 'crm',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/CRM/Central.vue'),
                },
                {
                    path: 'crm/:id',
                    name: 'crm',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/CRM/Details.vue'),
                },
                // === PRICE-LIST ===
                {
                    path: 'price-list',
                    name: 'price-list',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Price-list/Central.vue'),
                },
                {
                    path: 'price-list/:id',
                    name: 'price-list',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Price-list/Details.vue'),
                },
                // === OPERATION-LOCK ===
                {
                    path: 'operation-lock',
                    name: 'operation-lock',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Operation-lock/Central.vue'),
                },
                {
                    path: 'operation-lock/:id',
                    name: 'operation-lock',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Operation-lock/Details.vue'),
                },
                // === BUSINESS-DOCUMENTS ===
                {
                    path: 'business-document',
                    name: 'business-document',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import(
                            './views/Registries/Business-documents/Central.vue'
                        ),
                },
                {
                    path: 'business-document/:id',
                    name: 'business-document',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import(
                            './views/Registries/Business-documents/Details.vue'
                        ),
                },
                // === NEWSLETTER ===
                {
                    path: 'newsletter',
                    name: 'newsletter',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Newsletter/Central.vue'),
                },
                {
                    path: 'newsletter/:id',
                    name: 'newsletter',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Newsletter/Details.vue'),
                },
                // === CONTRACTS ===
                {
                    path: 'contracts',
                    name: 'contracts',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Contracts/Central.vue'),
                },
                {
                    path: 'contracts/:id',
                    name: 'contracts',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Contracts/Details.vue'),
                },
                // === WEB_SITE_CONTRACTS ===
                {
                    path: 'webSiteContact',
                    name: 'webSiteContact',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/WebSiteContact/Central.vue'),
                },
                {
                    path: 'webSiteContact/:id',
                    name: 'webSiteContact',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/WebSiteContact/Details.vue'),
                },
                // === TENDERS ===
                {
                    path: 'tenders',
                    name: 'tenders',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Tenders/Central.vue'),
                },
                {
                    path: 'tenders/:id',
                    name: 'tenders',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Tenders/Details.vue'),
                },
                // === ENFORCEMENTS ===
                {
                    path: 'enforcements',
                    name: 'enforcements',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Enforcements/Central.vue'),
                },
                {
                    path: 'enforcements/:id',
                    name: 'enforcements',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Enforcements/Details.vue'),
                },
                // === DEVICES ===
                {
                    path: 'devices',
                    name: 'devices',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Devices/Central.vue'),
                },
                {
                    path: 'devices/:id',
                    name: 'devices',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Devices/Details.vue'),
                },

                // === SERVICES ===
                {
                    path: 'service',
                    name: 'service',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Service/Central.vue'),
                },
                {
                    path: 'service/:id',
                    name: 'service',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Service/Details.vue'),
                },
                // === SERVICE--INFO ===
                {
                    path: 'service-info',
                    name: 'service-info',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Service-info/Central.vue'),
                },
                {
                    path: 'service-info/:id',
                    name: 'service-info',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Service-info/Details.vue'),
                },
                {
                    path: 'help-desk-users',
                    name: 'help-desk-users',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Notify-users/Central.vue'),
                },
                {
                    path: 'help-desk-users/:id',
                    name: 'help-desk-users',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Registries/Notify-users/Details.vue'),
                },
                // === SUPER ADMIN SETTINGS INFORMATION ===
                {
                    path: 'settings',
                    name: 'settings',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Admin/Settings/Details.vue'),
                },
            ],
        },
        // === SERVICE--PRINT ===
        {
            path: '/service-document-generator',
            name: 'service-document-generator',
            meta: {
                requiresAuth: false,
            },
            component: PrintOrder,
        },
        {
            path: '/print',
            name: 'print',
            meta: {
                requiresAuth: false,
            },
            component: () =>
                import('./views/Registries/Service/PrintOrder.vue'),
        },
        {
            path: '/rent-document-generator',
            name: 'anexDocumentGenerator',
            meta: {
                requiresAuth: false,
            },
            props: true,
            component: () =>
                import(
                    './views/Registries/Rent-contracts/DocumentGenerator.vue'
                ),
        },
        {
            path: '/estimate-document-generator',
            name: 'estimateDocumentGenerator',
            meta: {
                requiresAuth: false,
            },
            component: () =>
                import('./views/Registries/Service/DocumentGenerator.vue'),
        },

        {
            path: '/login',
            name: 'Login',

            meta: {
                requiresAuth: false,
            },
            component: () => import('./views/Global/Login.vue'),
        },
        {
            path: '/404',
            name: '404',
            meta: {
                requiresAuth: true,
            },
            component: () => import('./views/Global/404.vue'),
        },
        {
            path: '/403',
            name: '403',
            meta: {
                requiresAuth: true,
            },
            component: () => import('./views/Global/403.vue'),
        },
    ],
})

const checkLoad = next => {
    if (store.getters.getLanguageState) next()
}
//waiting for translations
router.beforeEach((to, from, next) => {
    checkLoad(next)
    store.watch(
        state => state.translations.loadLang,
        () => {
            checkLoad(next)
        }
    )
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        //  === ASSIGN URL TO GETTERS ===
        store.commit('assignUrl', to.name)
        let pic = !store.state['profile'].picture.blob
        new Rest('GET', 'session')
            .addParams({ withPicture: pic })
            .send()
            .then(response => {
                if (response.data.session) {
                    // access for superAdmin
                    store.commit('setUserSession', {
                        time: response.data.session.time,
                        id: response.data.session.id,
                    })
                    if (!response.data.user.isSuperAdmin)
                        store.dispatch('fetchRole', {
                            id: response.data.user.role._id,
                        })

                    // user informations
                    store.commit('setUserSession', response.data.session)

                    store.commit('setUserData', response.data.user)
                    if (response.data.picture)
                        store.commit('setPicture', response.data.picture.data)

                    // user language
                    i18next.changeLanguage(response.data.user.language)

                    // access to registry and fatch Registry Dictionary
                    store.commit(
                        'setProfileRegistries',
                        response.data.registries
                    )

                    let reg = response.data.registries.find(
                        registry => registry.url === to.name
                    )

                    let ent = response.data.entities.find(
                        entities => entities.url === to.name
                    )
                    store.dispatch('fetchAlertsTable', {
                        where: {
                            'to.users._id': response.data.user._id,
                            active: true,
                        },
                    })
                    // WYŁĄCZENIE ZADAŃ W APLIKACJI
                    // store.dispatch('fetchTasksTable', {
                    //     where: {
                    //         'to.users._id': response.data.user._id,
                    //     },
                    //     personal: true,
                    // })
                    if (reg) {
                        store
                            .dispatch('fetchRegistryDictionaries', {
                                dictionaries: reg.dictionaries,
                                entities: response.data.entities,
                            })
                            .then(() => next())
                    } else if (
                        ent ||
                        to.name == 'profile' ||
                        to.name == 'storage' ||
                        to.name == 'dashboard' ||
                        to.name == '404'
                    )
                        store
                            .dispatch('fetchRegistryDictionaries', {
                                entities: response.data.entities,
                            })
                            .then(() => next())
                    else {
                        if (
                            response.data.user.isAdmin ||
                            response.data.user.isSuperAdmin
                        )
                            next()
                        else
                            next({
                                path: '/404',
                            })
                    }
                }
            })
            .catch(err => {
                next({
                    path: '/login',
                })
            })
    } else if (to.matched.length === 0)
        next({
            path: '/404',
        })
    else next()
})

export default router
