import Vue from 'vue'
import Vuex from 'vuex'

import central from './Global/central'
import mailService from './Global/mailClient'

import registries from './Admin/registries'
import roles from './Admin/roles'
import dictionaries from './Admin/dictionaries'
import usersActivity from './Admin/usersActivity'
import settings from './Admin/settings'

import alerts from './Global/Tabs/alerts'
import tasks from './Global/Tabs/tasks'
import notes from './Global/Tabs/notes'
import folders from './Global/Tabs/folders'
import files from './Global/Tabs/files'
import logs from './Global/Tabs/logs'

import profile from './Personal/profile'
import storage from './Personal/storage'

import contacts from './Registries/Contacts/contacts'
import representatives from './Registries/Contacts/representatives'
import branches from './Registries/Contacts/branches'
import departments from './Registries/Departments/departments'
import users from './Registries/Users/users'
import staff from './Registries/Users/staff'
import cars from './Registries/Cars/cars'

import pricelist from './Registries/Price-list/price-list'
import offers from './Registries/Offers/offers'
import leasings from './Registries/Leasing/leasing'
import servicePrice from './Registries/Service-prices/service-prices'
import models from './Registries/Models/models'
import enforcements from './Registries/Enforcements/enforcements'
import tenders from './Registries/Tenders/tenders'
import contracts from './Registries/Contracts/contracts'
import webSiteContact from './Registries/WebSiteContact/webSiteContact'

import devices from './Registries/Devices/devices'
import implementation from './Registries/Devices/implementation'
import archive from './Registries/Devices/archive'
import parts from './Registries/Parts/parts'
import service from './Registries/Service/service'
import serviceParts from './Registries/Service/parts'
import serviceArchive from './Registries/Service/serviceArchive'
import serviceInfo from './Registries/Service-info/service-info'
import operationLock from './Registries/Operation-lock/operation-lock'
import businessDocument from './Registries/Business-document/business-document'

import rightBoxContacts from './Global/RightBox/contactsCart'

import comments from './Global/Tabs/comments'
import translations from './Admin/translations'
import leasingStuff from './Registries/Leasing/leasingStuff'
import rentInfo from './Registries/Rent-contracts/rent-info'
import rentAnnex from './Registries/Rent-contracts/rent-annex'
import rentSettlements from './Registries/Rent-contracts/rent-settlements'
import rentSettlementsInstance from './Registries/Rent-contracts/rent-settlements-instance'

import createLogger from 'vuex/dist/logger'
import rentDevices from './Registries/Rent-contracts/rent-devices'
import deviceAnother from './Registries/Devices/another'
import newsletter from './Registries/Newsletter/newsletter'

import notifyUsers from './Registries/Notify-users/notify-users'
import estimates from './Registries/Service/estimates'
import invoices from './Registries/Invoices/invoices'
import crm from './Registries/CRM/crm'

const debug = process.env.NODE_ENV !== 'production'
const plugins = debug
    ? [
          createLogger({
              filter(mutations) {
                  return (
                      mutations.type !== 'setTimer' &&
                      mutations.type !== 'decrementSessionTime' &&
                      mutations.type !== 'decrementSessionTime'
                  )
              },
          }),
      ]
    : []

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        dialog: false,
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        translations,
        central,
        mailService,
        contacts,
        registries,
        profile,
        users,
        roles,
        departments,
        staff,
        representatives,
        branches,
        dictionaries,
        alerts,
        storage,
        tasks,
        notes,
        folders,
        files,
        cars,
        settings,
        rightBoxContacts,
        comments,
        offers,
        pricelist,
        leasings,
        servicePrice,
        logs,
        models,
        'users-activity': usersActivity,
        devices,
        implementation,
        archive,
        parts,
        service,
        'service-parts': serviceParts,
        'service-archive': serviceArchive,
        'service-info': serviceInfo,
        enforcements,
        tenders,
        contracts,
        'operation-lock': operationLock,
        businessDocument,
        leasingStuff,
        webSiteContact,
        webSiteContact,
        'rent-info': rentInfo,
        'rent-annex': rentAnnex,
        'rent-settlement': rentSettlements,
        'rent-settlements-instance': rentSettlementsInstance,
        'rent-devices': rentDevices,
        deviceAnother,
        newsletter,
        'notify-users': notifyUsers,
        estimates,
        invoices,
        crm
    },
    // strict: debug,
    // plugins: plugins,
})
