<template>
    <div>
        <v-btn
            @click="action == 'create' ? save() : edit()"
            :disabled="disabled"
            class="buttons buttons--add"
            >{{ $t('global:save') }}</v-btn
        >
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        action: String,
    },
    computed: {
        ...mapGetters(['getProfileDetails']),
    },
    data() {
        return {
            disabled: false,
        }
    },

    methods: {
        ...mapActions(['createTask', 'updateTask']),
        async save() {
            this.disabled = true
            let success = await this.createTask({
                id: this.$route.params.id,
                userId: this.getProfileDetails._id,
                registry: this.$route.name,
            })
            if (success) this.$emit('closeModal', { created: true })
            this.disabled = false
        },
        async edit() {
            this.disabled = true
            let success = await this.updateTask({ registry: this.$route.name })
            if (success) this.$emit('closeModal')
            this.disabled = false
        },
    },
}
</script>
