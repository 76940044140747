<template>
    <v-data-table
        :headers="headers"
        fixed-header
        class="details__table pl-0 pt-0"
        :items-per-page="11"
        hide-default-footer
        :items="items"
        height="calc(100vh - 360px)"
    >
        <template v-slot:body>
            <slot name="columns"></slot>
        </template>
    </v-data-table>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
    props: {
        headers: {
            type: Array,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        search: {
            type: String,
        },
    },
    data() {
        return {}
    },
    methods: {
        ...mapMutations(['setFilesItems']),
    },
    beforeDestroy() {
        this.setFilesItems([])
    },
}
</script>
