<template>
    <v-container id="InnerTableContainer" class="py-0">
        <v-row>
            <v-col cols="12" md="12" class="py-0">
                <Table
                    :title="getRentAnnexTable.title"
                    :items="getRentAnnexTable.items"
                    :headers="headers"
                    :length="getRentAnnexTable.pages"
                    :page="getRentAnnexTable.page"
                    isSearchDisabled
                    @search="setSearch"
                    @search-fields="setSearchFields"
                    @change-page="setPage"
                    @sort="setSort"
                    :defaultFields="getRentAnnexSearchFields.searchFields"
                    height="100%"
                    id="InnerTable"
                >
                    <template v-slot:columns="{ isExpanded, expand }">
                        <Columns
                            :headers="headers"
                            :items="getRentAnnexTable.items"
                            :isExpanded="isExpanded"
                            :expand="expand"
                            @onOpenEdit="editRentAnnex"
                            @open-settlement-modal="openSettlementModal"
                            :loading="loading"
                        />
                    </template>
                </Table>
                <AlertModalAnnex
                    :open="openAlertModal"
                    nameSpaceI18n="rent-contracts"
                />
                <Modal
                    :title="$t('rent-contracts:rent-annex.addAnnex')"
                    :open="open"
                    :height="'650'"
                    :width="'900'"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        :action="GlobalActions.create"
                        slot="buttons"
                        @closeModal="open = false"
                    />
                </Modal>
                <Modal
                    :title="$t('rent-contracts:rent-annex.editAnnex')"
                    :open="openEdit"
                    :height="'650'"
                    :width="'900'"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        :action="GlobalActions.update"
                        slot="buttons"
                        @closeModal="openEdit = false"
                    />
                </Modal>
                <Modal
                    :title="
                        $t('rent-contracts:rent-settlements.addSettlements')
                    "
                    :open="openSettlement"
                    :height="'640'"
                    v-on:close="closeModal"
                >
                    <AEContentSettlement
                        slot="AEContent"
                        :currentMode="'SETTLEMENT_MANUAL'"
                    />
                    <ButtonsSettlement
                        :action="'create'"
                        slot="buttons"
                        :contractId="contractId"
                        @closeModal="openSettlement = false"
                    />
                </Modal>

                <div class="d-flex justify-end buttons--action">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                dark
                                v-on="on"
                                small
                                v-if="add()"
                                @click="addRentAnnex()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('rent-contracts:rent-annex.add') }}</span>
                    </v-tooltip>
                </div>
            </v-col>
        </v-row>
        <v-overlay :value="getCSVFileLoading" @dblclick.native="closeOnClick">
            <div>
                <div style="display: grid; justify-items: center;">
                    <span style="font-size: 1.3em;">
                        Trwa rozliczanie z pliku CSV
                    </span>
                    <v-progress-circular
                        :size="20"
                        :width="2"
                        indeterminate
                        color="white"
                    ></v-progress-circular>
                </div>
            </div>
        </v-overlay>
    </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Columns from './../../../Rent-contracts/Tabs/Rent-annex/Table/Columns.vue'
import AEContent from './../../Tabs/Rent-annex/Modal/Content.vue'
import Buttons from './../../Tabs/Rent-annex/Modal/Buttons.vue'
import AEContentSettlement from './../../Tabs/Rent-settlements/Modal/Content.vue'
import ButtonsSettlement from './../../Tabs/Rent-settlements/Modal/Buttons.vue'
import { GlobalActions } from '../../../../../plugins/constsTypes'
import AlertModalAnnex from './../../Modal/AlertModal/AlertModalAnnex.vue'

export default {
    data() {
        return {
            openAlertModal: false,
            GlobalActions: GlobalActions,
            open: false,
            openEdit: false,
            openSettlement: false,
            contractId: '',
            sn: '',
            headers: [
                {
                    text: this.$t('rent-contracts:rent-annex.contractNo'),
                    value: 'contractNo',
                    width: '15%',
                    sortable: false,
                },
                {
                    text: this.$t('rent-contracts:rent-annex.contractAnnexNo'),
                    value: 'contractAnnexNo',
                    width: '15%',
                    sortable: false,
                },
                {
                    text: this.$t('rent-contracts:rent-annex.startDate'),
                    value: 'startDate',
                    width: '15%',
                    sortable: false,
                },
                {
                    text: this.$t('rent-contracts:rent-annex.expirationDate'),
                    value: 'expirationDate',
                    width: '15%',
                    sortable: false,
                },
                {
                    text: this.$t('rent-contracts:rent-info.countedDevice'),
                    value: 'expirationDate',
                    width: '15%',
                    sortable: false,
                },
                {
                    text: this.$t('rent-contracts:rent-annex.customerCarer'),
                    value: 'customerCarer',
                    width: '15%',
                    sortable: false,
                },
                {
                    text: this.$t('rent-contracts:rent-annex.status'),
                    value: 'status.name',
                    width: '15%',
                    sortable: false,
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: '5%',
                    sortable: false,
                },
            ],
            loading: false,
        }
    },
    components: {
        Columns,
        AEContent,
        Buttons,
        AEContentSettlement,
        ButtonsSettlement,
        AlertModalAnnex,
    },
    computed: {
        ...mapGetters([
            'getRentInfoRegistry',
            'getRentAnnexTable',
            'getRentAnnexSearchFields',
            'getRentAnnexModal',
            'getRentAnnexDetails',
            'getLastValueRentInfo',
            'lastRentSettlement',
            'getRentSettlementModal',
            'getSettingsDetails',
            'getRentSettlementPrice',
            'getCSVFileLoading',
        ]),
    },
    methods: {
        ...mapActions([
            'fetchRentAnnexTable',
            'fetchUsers',
            'fetchRentAnnex',
            'fetchLastRentInfo',
            'fetchLastRentSettlement',
            'fetchSettings',
            'fetchAPIDevice',
        ]),
        ...mapMutations([
            'clearRentSettlementErrors',
            'clearRentAnnexModal',
            'clearRentAnnexErrors',
            'setRentAnnexTable',
            'clearRentAnnexTable',
            'setRentAnnexSearch',
            'setRentAnnexModal',
            'setRentAnnexModal',
            'setSnackbars',
            'SET_CSV_FILE_LOADING'
        ]),
        closeOnClick(){
            this.SET_CSV_FILE_LOADING(false);
        },
        add() {
            return this.checkPermissions('CREATE')
        },
        closeModal() {
            this.open = false
            this.openEdit = false
            this.openSettlement = false
            this.contractId = ''
        },

        getNextIndex() {
            const lastIndex = this.getLastValueRentInfo?.annex?.contractAnnexNo
            if (lastIndex?.includes(this.getRentInfoRegistry?.index + 'A')) {
                const splitedIndex = lastIndex.split('/')
                return (
                    (splitedIndex[0].includes('A')
                        ? splitedIndex[0]
                        : splitedIndex[0] + 'A') +
                    '/' +
                    splitedIndex[1] +
                    '/' +
                    (+splitedIndex[2] + 1)
                )
            }

            return `${this.getRentInfoRegistry?.index}A/${this.$moment().format(
                'YYYY'
            )}/1`
        },

        async addRentAnnex() {
            await this.fetchLastRentInfo({ id: this.$route.params.id })

            await this.fetchUsers({
                select: 'name lastname mail status',
                where: {
                    isUser: true,
                    'status.state': 'ACTIVE',
                    isDeleted: false,
                },
            })

            this.open = true
            this.clearRentAnnexErrors()
            this.clearRentAnnexModal()
            const data = {
                ...this.getLastValueRentInfo.annex,
            }
            this.setRentAnnexModal(data)

            const newIndex = this.getNextIndex()
            if (newIndex) this.getRentAnnexModal.contractAnnexNo = newIndex
        },

        async editRentAnnex(id) {
            await this.fetchLastRentInfo({ id: this.$route.params.id })
            await this.fetchUsers({
                select: 'name lastname mail status',
                where: {
                    isUser: true,
                    'status.state': 'ACTIVE',
                    isDeleted: false,
                },
            })

            this.clearRentAnnexErrors()
            this.clearRentAnnexModal()

            await this.fetchRentAnnex({ id: id })
            this.setRentAnnexModal()
            this.openEdit = true
        },

        setSearchFields(searchFields) {
            this.setRentAnnexSearch(searchFields)
        },
        setSearch(search) {
            this.setRentAnnexTable({ search, page: 1 })
            this.fetchRentAnnexTable({ id: this.$route.params.id })
        },
        setPage(page) {
            this.setRentAnnexTable({ page })
            this.fetchRentAnnexTable({ id: this.$route.params.id })
        },
        setSort(sort) {
            this.setRentAnnexTable({ sort })
            this.fetchRentAnnexTable({ id: this.$route.params.id })
        },
        clearTable() {
            this.clearRentAnnexTable()
            this.fetchRentAnnexTable({ id: this.$route.params.id })
        },
        async openSettlementModal(item) {
            this.loading = item.id

            this.contractId = item.contractId
            this.setSnackbars({
                type: 'warning',
                text: `${this.$t('rent-contracts:rent-annex.APItries')}`,
            })
            await this.initValues(item.id)

            this.clearRentSettlementErrors()
            this.openSettlement = true
            this.loading = null
        },
        async initValues(id) {
            await this.fetchSettings({ type: 'add' })
            const defaultValue = this.getSettingsDetails?.defaultValue || 2000

            const lastRentSettlement = await this.fetchLastRentSettlement({
                rentDeviceId: id,
            })

            this.getRentSettlementPrice.device =
                lastRentSettlement[0].rentDevices

            const sn = this.$get(
                lastRentSettlement,
                '[0].rentDevices.device.sn',
                false
            )
            let APIDevice = await this.fetchAPIDevice({
                sn: [sn],
            })
            if (APIDevice.length > 0) {
                this.setSnackbars({
                    type: 'success',
                    text: `${this.$t('rent-contracts:rent-annex.APISuccess')}`,
                })
            } else {
                this.setSnackbars({
                    type: 'error',
                    text: `${this.$t('rent-contracts:rent-annex.APIError')}`,
                })
            }

            const prevMonoCounter = lastRentSettlement[0]?.currentMonoCounter
                ? lastRentSettlement[0]?.currentMonoCounter
                : lastRentSettlement[0]?.rentDevices?.initMonoCounter
                ? lastRentSettlement[0]?.rentDevices?.initMonoCounter
                : 0

            const prevColorCounter = lastRentSettlement[0]?.currentColorCounter
                ? lastRentSettlement[0]?.currentColorCounter
                : lastRentSettlement[0]?.rentDevices?.initColorCounter
                ? lastRentSettlement[0]?.rentDevices?.initColorCounter
                : 0

            const prevScanCounter = lastRentSettlement[0]?.currentScanCounter
                ? lastRentSettlement[0]?.currentScanCounter
                : lastRentSettlement[0]?.rentDevices?.initScanCounter
                ? lastRentSettlement[0]?.rentDevices?.initScanCounter
                : 0

            this.getRentSettlementModal.prevMonoCounter = prevMonoCounter
            this.getRentSettlementModal.prevColorCounter = prevColorCounter
            this.getRentSettlementModal.prevScanCounter = prevScanCounter

            if (lastRentSettlement[0].rentSettlementsInstance) {
                this.getRentSettlementModal.rentSettlementsInstance =
                    lastRentSettlement[0]?.rentSettlementsInstance
            }

            const currentMonoCounter = this.$get(
                lastRentSettlement,
                '[0].rentDevices.isMono',
                false
            )
                ? APIDevice?.length > 0
                    ? this.$get(APIDevice, '[0].counters.monoSideCount')
                    : prevMonoCounter + defaultValue
                : 0
            const currentColorCounter = this.$get(
                lastRentSettlement,
                '[0].rentDevices.isColor',
                false
            )
                ? APIDevice?.length > 0
                    ? this.$get(APIDevice, '[0].counters.colorSideCount')
                    : prevColorCounter + defaultValue
                : 0
            const currentScanCounter = this.$get(
                lastRentSettlement,
                '[0].rentDevices.isScaner',
                false
            )
                ? APIDevice?.length > 0
                    ? this.$get(APIDevice, '[0].counters.copySideCount')
                    : prevScanCounter + defaultValue
                : 0

            this.getRentSettlementModal.currentMonoCounter = currentMonoCounter
            this.getRentSettlementModal.currentColorCounter = currentColorCounter
            this.getRentSettlementModal.currentScanCounter = currentScanCounter

            this.getRentSettlementModal.monthlyMono =
                currentMonoCounter - prevMonoCounter
            this.getRentSettlementModal.monthlyColor =
                currentColorCounter - prevColorCounter
            this.getRentSettlementModal.monthlyScan =
                currentScanCounter - prevScanCounter
        },
    },
    beforeMount() {
        this.fetchRentAnnexTable({ id: this.$route.params.id })
    },
}
</script>
<style lang="scss">
#InnerTable {
    padding: 0;
    position: relative;

    .central__table {
        position: relative;

        & > .v-data-table__wrapper {
            height: calc(100vh - 360px) !important;
            overflow: auto !important;
        }
    }

    & > .row {
        position: relative;

        & > div {
            box-shadow: 0 0 0 transparent !important;
        }
    }
}

#InnerTableContainer {
    .buttons--action {
        bottom: -50px !important;
        right: -2px !important;
    }
}
</style>
