var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0",staticStyle:{"height":"100%"}},[_c('v-row',{staticStyle:{"height":"100%"}},[_c('v-col',{staticClass:"pa-0",staticStyle:{"height":"100%"},attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",staticStyle:{"height":"100%"},model:{value:(_vm.getRentInfoErrors.step),callback:function ($$v) {_vm.$set(_vm.getRentInfoErrors, "step", $$v)},expression:"getRentInfoErrors.step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () => _vm.getRentInfoErrors.stepOne.validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('global:basicInfo')))]),_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () => _vm.getRentInfoErrors.stepTwo.validation,
                            ],"editable":"","step":"2"}},[_vm._v(_vm._s(_vm.$t('rent-contracts:rent-info.contractInfo')))]),_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () =>
                                    _vm.getRentInfoErrors.stepThree.validation,
                            ],"editable":"","step":"3"}},[_vm._v("Ustawienia")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () =>
                                    _vm.getRentInfoErrors.stepThree.validation,
                            ],"editable":"","step":"4"}},[_vm._v(_vm._s(_vm.$t('rent-contracts:rent-info.notes')))])],1),_c('v-stepper-items',{staticClass:"itemsContent",staticStyle:{"height":"100%"}},[_c('v-stepper-content',{staticClass:"pb-0 pt-4",staticStyle:{"height":"100%","display":"flex","flex-direction":"column"},attrs:{"step":"1"}},[_c('Separator',{attrs:{"text":_vm.$t(_vm.$t('global:basicInfo'))}}),_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"dense":"","label":`${_vm.$t(
                                                    'rent-contracts:rent-info.title'
                                                )}`},model:{value:(
                                                    _vm.getRentInfoModal.annex
                                                        .title
                                                ),callback:function ($$v) {_vm.$set(_vm.getRentInfoModal.annex
                                                        , "title", $$v)},expression:"\n                                                    getRentInfoModal.annex\n                                                        .title\n                                                "}})],1),_c('v-col',{attrs:{"cols":"3","offset":"1"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","nudge-bottom":"45","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                        on,
                                                        attrs,
                                                    }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","label":_vm.$t(
                                                                'rent-contracts:rent-info.startDate'
                                                            ),"readonly":"","prepend-icon":"mdi-calendar"},model:{value:(
                                                            _vm.getRentInfoModal
                                                                .annex
                                                                .startDate
                                                        ),callback:function ($$v) {_vm.$set(_vm.getRentInfoModal
                                                                .annex
                                                                , "startDate", $$v)},expression:"\n                                                            getRentInfoModal\n                                                                .annex\n                                                                .startDate\n                                                        "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datepickerStart),callback:function ($$v) {_vm.datepickerStart=$$v},expression:"datepickerStart"}},[_c('v-date-picker',{on:{"input":function($event){_vm.datepickerStart = false}},model:{value:(
                                                        _vm.getRentInfoModal
                                                            .annex.startDate
                                                    ),callback:function ($$v) {_vm.$set(_vm.getRentInfoModal
                                                            .annex, "startDate", $$v)},expression:"\n                                                        getRentInfoModal\n                                                            .annex.startDate\n                                                    "}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","nudge-bottom":"45","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                        on,
                                                        attrs,
                                                    }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","label":_vm.$t(
                                                                'rent-contracts:rent-info.expirationDate'
                                                            ),"readonly":"","prepend-icon":"mdi-calendar"},model:{value:(
                                                            _vm.getRentInfoModal
                                                                .annex
                                                                .expirationDate
                                                        ),callback:function ($$v) {_vm.$set(_vm.getRentInfoModal
                                                                .annex
                                                                , "expirationDate", $$v)},expression:"\n                                                            getRentInfoModal\n                                                                .annex\n                                                                .expirationDate\n                                                        "}},'v-text-field',attrs,false),on))]}}]),model:{value:(
                                                    _vm.datepickerExpiration
                                                ),callback:function ($$v) {
                                                    _vm.datepickerExpiration
                                                =$$v},expression:"\n                                                    datepickerExpiration\n                                                "}},[_c('v-date-picker',{on:{"input":function($event){_vm.datepickerExpiration = false}},model:{value:(
                                                        _vm.getRentInfoModal
                                                            .annex
                                                            .expirationDate
                                                    ),callback:function ($$v) {_vm.$set(_vm.getRentInfoModal
                                                            .annex
                                                            , "expirationDate", $$v)},expression:"\n                                                        getRentInfoModal\n                                                            .annex\n                                                            .expirationDate\n                                                    "}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"disabled":_vm.contractNoDisabled,"readonly":!_vm.isManager(),"label":`${_vm.$t(
                                                    'rent-contracts:rent-info.contractNo'
                                                )}*`,"error-messages":_vm.getRentInfoErrors
                                                        .stepOne.fields
                                                        .contractNo},model:{value:(
                                                    _vm.getRentInfoModal.contractNo
                                                ),callback:function ($$v) {_vm.$set(_vm.getRentInfoModal, "contractNo", $$v)},expression:"\n                                                    getRentInfoModal.contractNo\n                                                "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":`${_vm.$t(
                                                    'rent-contracts:rent-info.externalContractNo'
                                                )}*`,"error-messages":_vm.getRentInfoErrors
                                                        .stepOne.fields
                                                        .externalContractNo},model:{value:(
                                                    _vm.getRentInfoModal.externalContractNo
                                                ),callback:function ($$v) {_vm.$set(_vm.getRentInfoModal, "externalContractNo", $$v)},expression:"\n                                                    getRentInfoModal.externalContractNo\n                                                "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-combobox',{attrs:{"dense":"","error-messages":_vm.getRentInfoErrors
                                                        .stepOne.fields
                                                        .contractor,"label":`${_vm.$t(
                                                    'rent-contracts:rent-info.contractor'
                                                )}*`,"hint":"Zacznij pisać...","hide-no-data":_vm.hideContacts,"items":_vm.getContactsInput.items,"item-text":"name","item-value":"_id","return-object":""},on:{"keyup":function($event){return _vm.setContacts(
                                                        $event.target.value
                                                    )},"input":function($event){return _vm.assignID()}},model:{value:(
                                                    _vm.getRentInfoModal.annex
                                                        .contractor
                                                ),callback:function ($$v) {_vm.$set(_vm.getRentInfoModal.annex
                                                        , "contractor", $$v)},expression:"\n                                                    getRentInfoModal.annex\n                                                        .contractor\n                                                "}})],1),_c('v-col',[_c('v-checkbox',{staticClass:"push-top",attrs:{"label":`${_vm.$t(
                                                    'rent-contracts:rent-info.buyer'
                                                )}`},model:{value:(_vm.purchaserCheckbox),callback:function ($$v) {_vm.purchaserCheckbox=$$v},expression:"purchaserCheckbox"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-combobox',{attrs:{"label":`${_vm.$t(
                                                    'rent-contracts:rent-info.representative'
                                                )}`,"item-text":_vm.text,"return-object":"","append-outer-icon":"mdi-account-plus","item-value":"_id","items":_vm.getRepresentativeInput.items,"dense":""},on:{"keyup":function($event){return _vm.setRepresentatives(
                                                        $event.target.value
                                                    )}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.name)+" "+_vm._s(data.item .lastname)+" "),(
                                                                _vm.$get(
                                                                    data.item,
                                                                    'email',
                                                                    ''
                                                                )
                                                            )?_c('span',[_vm._v(" • "+_vm._s(_vm.$get( data.item, 'email', '' )))]):_vm._e(),(
                                                                _vm.$get(
                                                                    data.item,
                                                                    'phoneNumber',
                                                                    ''
                                                                )
                                                            )?_c('span',[_vm._v(" • "+_vm._s(_vm.$get( data.item, 'phoneNumber', '' )))]):_vm._e()])]}},{key:"item",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.name)+" "+_vm._s(data.item .lastname)+" "),(
                                                                _vm.$get(
                                                                    data.item,
                                                                    'email',
                                                                    ''
                                                                )
                                                            )?_c('span',[_vm._v(" • "+_vm._s(_vm.$get( data.item, 'email', '' )))]):_vm._e(),(
                                                                _vm.$get(
                                                                    data.item,
                                                                    'phoneNumber',
                                                                    ''
                                                                )
                                                            )?_c('span',[_vm._v(" • "+_vm._s(_vm.$get( data.item, 'phoneNumber', '' )))]):_vm._e()])]}}]),model:{value:(
                                                    _vm.getRentInfoModal.annex
                                                        .representative
                                                ),callback:function ($$v) {_vm.$set(_vm.getRentInfoModal.annex
                                                        , "representative", $$v)},expression:"\n                                                    getRentInfoModal.annex\n                                                        .representative\n                                                "}},[_c('template',{slot:"append-outer"},[_c('v-menu',{attrs:{"close-on-content-click":false,"close-on-click":false,"max-width":"300","min-width":"300","origin":"center"},on:{"input":v =>
                                                                v ||
                                                                _vm.clearMiniModal()},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                                on: menu,
                                                            }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                                        on: tooltip,
                                                                    }){return [_c('v-btn',{attrs:{"small":"","icon":""}},[_c('v-icon',_vm._g({on:{"click":function($event){_vm.showMenu = true}}},{
                                                                                ...menu,
                                                                                ...tooltip,
                                                                            }),[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t( 'global:addRepresentative' )))])])]}}]),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-card',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v(" "+_vm._s(`${_vm.$t( 'global:additionRepresentative' )}`)+" ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":`${_vm.$t(
                                                                                'global:representativeName'
                                                                            )}`},model:{value:(
                                                                                _vm.getRepresentativeModal.name
                                                                            ),callback:function ($$v) {_vm.$set(_vm.getRepresentativeModal, "name", $$v)},expression:"\n                                                                                getRepresentativeModal.name\n                                                                            "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":`${_vm.$t(
                                                                                'global:representativeLastname'
                                                                            )}`},model:{value:(
                                                                                _vm.getRepresentativeModal.lastname
                                                                            ),callback:function ($$v) {_vm.$set(_vm.getRepresentativeModal, "lastname", $$v)},expression:"\n                                                                                getRepresentativeModal.lastname\n                                                                            "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":`${_vm.$t(
                                                                                'global:representativeEmail'
                                                                            )}`},model:{value:(
                                                                                _vm.getRepresentativeModal.email
                                                                            ),callback:function ($$v) {_vm.$set(_vm.getRepresentativeModal, "email", $$v)},expression:"\n                                                                                getRepresentativeModal.email\n                                                                            "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":`${_vm.$t(
                                                                                'global:representativePhoneNumber'
                                                                            )}`},model:{value:(
                                                                                _vm.getRepresentativeModal.phoneNumber
                                                                            ),callback:function ($$v) {_vm.$set(_vm.getRepresentativeModal, "phoneNumber", $$v)},expression:"\n                                                                                getRepresentativeModal.phoneNumber\n                                                                            "}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0 my-0 d-flex justify-end",attrs:{"md":"12"}},[_c('v-btn',{staticClass:"buttons buttons--cancel mr-2",on:{"click":function($event){return _vm.clearMiniModal()}}},[_vm._v(_vm._s(_vm.$t( 'cancel' )))]),_c('v-btn',{staticClass:"buttons buttons--add",attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.additionRepresentative()}}},[_vm._v(_vm._s(_vm.$t( 'add' )))])],1)],1)],1)],1)],1)],1)],2)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[(!_vm.purchaserCheckbox)?_c('v-combobox',{attrs:{"dense":"","error-messages":_vm.getRentInfoErrors
                                                        .stepOne.fields
                                                        .contractor,"label":`Kontrahent (nabywca)`,"hint":"Zacznij pisać...","hide-no-data":_vm.hideContacts,"items":_vm.getContactsInput.items,"item-text":"name","item-value":"_id","return-object":""},on:{"keyup":function($event){return _vm.setContacts(
                                                        $event.target.value
                                                    )},"input":function($event){return _vm.assignID()}},model:{value:(
                                                    _vm.getRentInfoModal.annex
                                                        .contractorBuyer
                                                ),callback:function ($$v) {_vm.$set(_vm.getRentInfoModal.annex
                                                        , "contractorBuyer", $$v)},expression:"\n                                                    getRentInfoModal.annex\n                                                        .contractorBuyer\n                                                "}}):_vm._e()],1)],1)],1)],1),_c('v-row',{staticStyle:{"display":"flex"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.getRegistryDictionaries.contractType
                                                ? _vm.getRegistryDictionaries.contractType
                                                : [],"dense":"","label":`${_vm.$t(
                                            'rent-contracts:rent-info.contractType'
                                        )}*`,"return-object":"","item-value":"field","error-messages":_vm.getRentInfoErrors.stepOne.fields
                                                .contractType},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(
                                            _vm.getRentInfoModal.annex
                                                .contractType
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentInfoModal.annex
                                                , "contractType", $$v)},expression:"\n                                            getRentInfoModal.annex\n                                                .contractType\n                                        "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":_vm.$t(
                                                'rent-contracts:rent-info.customerCarer'
                                            ),"dense":"","items":_vm.getUsersList,"item-value":"_id","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(selection){return [_c('span',[_vm._v(_vm._s(selection.item.name))]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(selection.item.lastname))])]}},{key:"item",fn:function(item){return [_c('span',[_vm._v(_vm._s(item.item.name))]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(item.item.lastname))])]}}]),model:{value:(
                                            _vm.getRentInfoModal.annex
                                                .customerCarer
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentInfoModal.annex
                                                , "customerCarer", $$v)},expression:"\n                                            getRentInfoModal.annex\n                                                .customerCarer\n                                        "}})],1)],1)],1),_c('v-stepper-content',{staticClass:"pb-0 pt-4",attrs:{"step":"2"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t(
                                                'rent-contracts:rent-info.contractInfo'
                                            )}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{attrs:{"label":_vm.$t(
                                                'rent-contracts:rent-info.promotionalPeriod'
                                            )},model:{value:(
                                            _vm.getRentInfoModal.annex
                                                .startBundle
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentInfoModal.annex
                                                , "startBundle", $$v)},expression:"\n                                            getRentInfoModal.annex\n                                                .startBundle\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"disabled":!_vm.getRentInfoModal.annex
                                                .startBundle,"label":_vm.$t(
                                                'rent-contracts:rent-info.numberOfPromotionalPeriod'
                                            ),"error-messages":_vm.getRentInfoErrors.stepTwo.fields
                                                .numberOfPromotionalPeriods},model:{value:(
                                            _vm.getRentInfoModal.annex
                                                .numberOfPromotionalPeriods
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentInfoModal.annex
                                                , "numberOfPromotionalPeriods", $$v)},expression:"\n                                            getRentInfoModal.annex\n                                                .numberOfPromotionalPeriods\n                                        "}})],1)],1)],1),_c('v-stepper-content',{staticClass:"pb-0 pt-4",attrs:{"step":"3"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":'Ustawienia'}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{ref:"accountingDay",attrs:{"dense":"","label":`${_vm.$t(
                                            'rent-contracts:rent-info.accountingDay'
                                        )}`},on:{"keypress":_vm.integralNumbers},model:{value:(
                                            _vm.getRentInfoModal.annex
                                                .accountingDay
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentInfoModal.annex
                                                , "accountingDay", $$v)},expression:"\n                                            getRentInfoModal.annex\n                                                .accountingDay\n                                        "}})],1),_c('v-col',{attrs:{"offset":"1","cols":"3"}},[_c('v-text-field',{attrs:{"dense":"","label":`${_vm.$t(
                                            'rent-contracts:rent-info.paymentDeadline'
                                        )}`,"prepend-icon":"mdi-calendar"},model:{value:(
                                            _vm.getRentInfoModal.annex
                                                .paymentDeadline
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentInfoModal.annex
                                                , "paymentDeadline", $$v)},expression:"\n                                            getRentInfoModal.annex\n                                                .paymentDeadline\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.getRegistryDictionaries.responseTime
                                                ? _vm.getRegistryDictionaries.responseTime
                                                : [],"label":`${_vm.$t(
                                            'rent-contracts:rent-info.responseTime'
                                        )}`,"return-object":"","item-value":"field"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(
                                            _vm.getRentInfoModal.annex
                                                .responseTime
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentInfoModal.annex
                                                , "responseTime", $$v)},expression:"\n                                            getRentInfoModal.annex\n                                                .responseTime\n                                        "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('money-field',{staticClass:"margin--input",attrs:{"disabled":false,"readonly":false,"label":`${_vm.$t(
                                            'rent-contracts:rent-info.implementationCost'
                                        )}`},model:{value:(
                                            _vm.getRentInfoModal.annex
                                                .implementationCost
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentInfoModal.annex
                                                , "implementationCost", $$v)},expression:"\n                                            getRentInfoModal.annex\n                                                .implementationCost\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"dense":"","label":`${_vm.$t(
                                            'rent-contracts:rent-info.BIDProject'
                                        )}`},model:{value:(
                                            _vm.getRentInfoModal.annex
                                                .BIDProject
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentInfoModal.annex
                                                , "BIDProject", $$v)},expression:"\n                                            getRentInfoModal.annex\n                                                .BIDProject\n                                        "}})],1)],1)],1),_c('v-stepper-content',{staticClass:"pb-0 pt-4",attrs:{"step":"4"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t(
                                                'rent-contracts:rent-info.notes'
                                            )}})],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"label":_vm.$t(
                                                'rent-contracts:rent-info.notes'
                                            ),"no-resize":"","rows":"5","auto-grow":""},model:{value:(
                                            _vm.getRentInfoModal.annex.notes
                                        ),callback:function ($$v) {_vm.$set(_vm.getRentInfoModal.annex, "notes", $$v)},expression:"\n                                            getRentInfoModal.annex.notes\n                                        "}})],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }