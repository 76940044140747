import Rest from './../../../plugins/Rest'
import validation from './../../../plugins/validation'
import { clone } from 'lodash'
import store from '../../../store/index'
import moment from 'moment'
import {numberRules} from '../../../validators/formValidation'
import Rest2 from '../../../plugins/Rest2'
const state = {
    modal: {
        fields: {
            contractAnnexNo: '',
            title: '',
            contractAnnex: '',
            contractor: '',
            contractorBuyer: '',
            representative: {},
            contractType: '',
            responseTime: '',
            implementationCost: 0,
            // monitoringCost: 0,
            customerCarer: null,
            BIDProject: '',
            startBundle: null,
            monoBundle: '',
            colorBundle: '',
            colorBundleCost: 0,
            constMonthlyBundle: null,
            constMonoBundle: '',
            constMonoBundlePrice: 0,
            constColorBundle: '',
            constColorBundlePrice: '',
            expirationDate: '',
            accountingDay: '',
            lastAccountingDate: '',
            lastAccountingUser: null,
            lastAccountingStatus: '',
            notes: '',
            paymentDeadline: '',
            numberOfPromotionalPeriods: '',
            representativeSelectedEmail: ''
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {
                    contractAnnexNo: '',
                    contractor: '',
                    contractType: '',
                },
            },
            stepTwo: {
                validation: true,
                fields: {
                    numberOfPromotionalPeriods: ''
                },
            },
            stepThree: {
                validation: true,
                fields: {},
            },
        },
        step: 1,
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: Infinity,
        pages: 1,
        defaultSort: 'createdAt',
    },
    request: {
        select: '',
        populate: [
            {
                from: 'rent-info',
                localField: 'rentInfo',
                foreignField: '_id',
                as: 'contract',
            },
            {
                from: 'contacts',
                localField: 'contractorBuyer',
                foreignField: '_id',
                as: 'contractorBuyer',
            },
            {
                from: 'users',
                localField: 'customerCarer',
                foreignField: '_id',
                as: 'customerCarer',
            },

            {
                from: "rent-device",
                localField: "rentDevices",
                foreignField: '_id',
                as: "rentDevicesUnpacked"
            },

            {
                from: "rent-settlements",
                localField: "rentDevicesUnpacked.settlementId",
                foreignField: '_id',
                as: "rentSettlementUnpacked"
            },



        ],
        searchFields: [],
        unwindExeptions: ['rentDevices'],
        where: {
            isDeleted: false,
        },
    },
    dictFields: {},
    statuses: [],
    details: {},
    registry: {},
    usedDevices: []
}

const getters = {
    getRentAnnexModal: state => state.modal.fields,
    getRentAnnexRegistry: state => state.registry,
    getRentAnnexTable: state => state.table,
    getRentAnnexSearchFields: state => state.request,
    getRentAnnexErrors: state => state.modal.errors,
    getRentAnnexStep: state => state.modal,
    getRentAnnexStatuses: state => state.statuses,
    getRentAnnexDetails: state => state.details,
    getRentAnnexModalRepresentative: state => state.modal.fields.representative,
    getUsedDevices: state => state.usedDevices,
}

const actions = {
    async fetchRentAnnexTable({ commit, dispatch }, payload) {

        try {
            let response = await new Rest2('GET', `rent-annex/${payload.id}`)
                .select(state.request.select)
                .limit(state.table.limit)
                .unwindExeptions(state.request.unwindExeptions)
                .page(state.table.page)
                .where(state.request.where)
                .populate(state.request.populate)
                .search(state.request.searchFields, state.table.search)
                .sort(state.table.sort || state.table.defaultSort)
                .send()


            dispatch('fetchRentAnnexRegistry')

            commit('setRentAnnexTable', response.data)



            if (response.data.items.length > 0) {
                const tempArr = [...response.data.items]
                const lastAnnex = (tempArr.sort(
                    function (a, b) {
                        return new Date(b.createdAt) - new Date(a.createdAt)
                    }
                ))[0]


                commit('SET_USED_DEVICES', lastAnnex.rentSettlementUnpacked)
            }



        } catch (error) {
            console.error(error)
        }
    },
    async createRentAnnex({ commit, dispatch }, payload) {
        try {
       
            dispatch('fetchRentAnnexTable', { id: payload.id })
            await new Rest2('POST', '/rent-annex/' + payload.id)
                .setBody(state.modal.fields)
                .send()
            commit('clearRentAnnexModal')
            commit('clearRentAnnexErrors')
            dispatch('fetchRentAnnexTable', { id: payload.id })
            dispatch('fetchLastRentInfo', { id: payload.id })
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            //HANDLE ERRORS
            if (err) {
                console.log(state.modal.errors);
                commit('setSnackbars', { type: 'error' })
                validation.setErrors(state.modal.errors, err)
            }
        }
    },

    async updateRentAnnex({ commit, dispatch, rootState }, payload) {
 

        try {
            const id = rootState['rent-annex'].details._id
            await new Rest2('PUT', `rent-annex/${id}`)
                .setBody(state.modal.fields)
                .send()
            dispatch('fetchRentAnnex', { id })

            commit('clearRentAnnexModal')
            commit('clearRentAnnexErrors')
            return true
        } catch (err) {
            //HANDLE ERRORS
            validation.setErrors(state.modal.errors, err)
    
            commit('setSnackbars', { type: 'error' })
        }
    },

    

    async fetchRentAnnex({ state, commit, dispatch }, payload) {
        try {
            let priceList = await new Rest2(
                'GET',
                `rent-annex/one/${payload.id}`
            )
                .populate(['contractor', 'createdBy', 'customerCarer'])
                .send()

            let statusesReq = new Rest2('GET', `registries/rent-info/statuses`)
                .select('name _id isDeleted color state')
                .where({ isDeleted: false })
                .send()

            let { 0: response, 1: statuses } = await Promise.all([
                priceList,
                statusesReq,
            ])
            dispatch('fetchRentAnnexRegistry')
            commit('setRentAnnexDetails', response.data)
            commit('setRentAnnexStatuses', statuses.data.items)


            if (payload.next) payload.next()
        } catch (err) {
            console.error(err)
        }
    },

    async fetchRentAnnexRegistry({ commit, dispatch }, payload) {
        let registry = await new Rest2('GET', 'registries')
            .select('currentMenager name')
            .where({ url: 'rent-info' })
            .populate([
                {
                    path: 'currentMenager',
                    select: 'name lastname mail status phone picture',
                },
            ])
            .send()
        commit('setRentAnnexRegistry', registry.data.items)
    },

    async updateRentAnnexStatus({ commit, dispatch }, payload) {
        commit('setRentAnnexModal')
        commit('setRentAnnexStatus', payload.status)
        try {
            await new Rest2('PUT', `rent-annex/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            commit('setSnackbars', { type: 'success' })
        } catch (err) {
            console.error(err)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async deleteRentAnnex({ commit, dispatch }, payload) {
        try {
            await new Rest2('DELETE', `rent-annex/${payload.id}`).send()
            dispatch('fetchRentAnnexTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            console.error(err)
        }
    },

}

const mutations = {
    setRentAnnexTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
    setRentAnnexDetails(state, payload) {
        state.details = payload
    },
    setRentAnnexStatuses(state, payload) {
        state.statuses = payload
    },
    setRentAnnexModal(state, payload) {
        let obj = payload ? payload : clone(state.details)
        state.modal.fields = obj
        store.commit('setRepresentativesInput', {
            items: obj?.contractor?.representatives,
        })
        state.modal.fields.representative = obj?.contractor?.representatives?.find(
            e => e._id === obj.representative
        )
        state.modal.fields.startDate = obj.startDate
            ? moment(obj.startDate).format('YYYY-MM-DD')
            : ''
        state.modal.fields.expirationDate = obj.expirationDate
            ? moment(obj.expirationDate).format('YYYY-MM-DD')
            : ''

        if (payload && obj.startDate) state.modal.fields.startDate = null
        if (payload && obj.expirationDate)
            state.modal.fields.expirationDate = null
        if (payload && obj.accountingDay) {
            state.modal.fields.accountingDay = '1'
        }
    },
    setRentAnnexStatus(state, payload) {
        state.modal.fields.status = payload
    },
    setRentAnnexRegistry(state, payload) {
        state.registry = payload[0]
    },
    clearRentAnnexModal() {
        state.modal.step = 1
        state.modal.fields = {
            contractAnnexNo: '',
            title: '',
            contractAnnex: '',
            contractor: '',
            representative: {},
            contractType: '',
            responseTime: '',
            implementationCost: 0,
            // monitoringCost: 0,
            customerCarer: null,
            BIDProject: '',
            startBundle: null,
            monoBundle: '',
            colorBundle: '',
            colorBundleCost: 0,
            constMonthlyBundle: null,
            constMonoBundle: '',
            constMonoBundlePrice: 0,
            constColorBundle: '',
            constColorBundlePrice: '',
            expirationDate: '',
            accountingDay: '',
            lastAccountingDate: '',
            lastAccountingUser: null,
            lastAccountingStatus: '',
            notes: '',
            paymentDeadline: '',
            numberOfPromotionalPeriods: ''
        }
    },
    clearRentAnnexErrors(state) {
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step].fields) {
                state.modal.errors[step].fields[key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
    clearRentAnnexTable(state) {
        state.table.search = ''
        state.table.page = 1
        state.table.pages = 1
    },
    setRentAnnexSearch(state, payload) {
        state.request.searchFields = payload
    },
    SET_USED_DEVICES(state, payload) {
        const devicesFromRentSettlement = payload.map(e => e.rentDevices)
        state.usedDevices = devicesFromRentSettlement
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}
