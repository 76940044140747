<template>
    <v-stepper v-model="stepper" class="elevation-0">
        <v-stepper-header>
            <v-divider></v-divider>
            <v-stepper-step step="1" editable :rules="[() => !error]">
                {{ $t('global:scannQR') }}
            </v-stepper-step>
            <v-divider></v-divider>
        </v-stepper-header>

        <v-stepper-items>
            <v-stepper-content step="1">
                <v-row>
                    <v-col>
                        <Separator :text="$t('global:scannQR')" />
                    </v-col>
                </v-row>
                <v-row class="QRArea" ref="QRArea" :error="error">
                    <v-col>
                        <StreamBarcodeReader @decode="onDecode" />
                    </v-col>
                </v-row>
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
</template>
<script>
import { StreamBarcodeReader } from 'vue-barcode-reader'
import { mapActions, mapGetters } from 'vuex'

export default {
    data() {
        return {
            stepper: 1,
            error: '',
        }
    },
    components: {
        StreamBarcodeReader,
    },
    computed: {
        ...mapGetters(['getDeviceDetails']),
    },
    methods: {
        ...mapActions(['fetchDeviceBySN']),
        async onDecode(decode) {
            if (decode) {
                try {
                    const device = await this.fetchDeviceBySN(decode)
                    if (!device) {
                        this.error = this.$t('global:QRError')
                        this.$refs.QRArea.classList.add('QRError')
                        return
                    }
                    this.error = ''
                    this.$refs.QRArea.classList.remove('QRError')
                    if (
                        !this.$router.history.current.fullPath.includes(
                            device._id.toString()
                        )
                    ) {
                        this.$router.push(`devices/${device._id}`)
                    }
                    this.$emit('close')
                } catch (e) {
                    console.error(e)
                    this.error = this.$t('global:QRError')
                    this.$refs.QRArea.classList.add('QRError')
                }
            }
        },
    },
}
</script>
<style lang="scss">
.QRArea {
    border: solid 1px;
    position: relative;
    max-height: 350px;
    min-height: 310px;

    video {
        max-height: 350px;
        min-height: 310px;
        object-fit: cover;
        width: 100%;
        min-width: 100%;
    }

    .overlay-element {
        --color: rgba(0, 0, 0, 0.342);
        background: var(--color);
    }

    &.QRError {
        margin-bottom: 25px;
        border: #ff5252 solid 1px;
        &::after {
            content: attr(error);
            position: absolute;
            bottom: -25px;
            text-align: center;
            width: 100%;
            color: #ff5252;
        }
        .overlay-element {
            --color: rgba(255, 0, 0, 0.247);
        }
    }
}
</style>
