<template>
    <tbody>
        <template v-for="(item, index) in items">
            <tr
                :class="`${isStatusClosed(item) ? 'disabled' : ''}`"
                :key="'a' + index"
            >
                <!-- === === Index === === -->
                <td class="truncate">
                    {{ $get(item, 'index') }}
                </td>
                <!-- === === SETTLEMENTS_DATE === === -->
                <td class="truncate">
                    {{
                        item.createdAt
                            ? $moment(item.createdAt).format('DD-MM-YYYY')
                            : '-'
                    }}
                </td>
                <!-- === === CONTRACT NO=== === -->
                <td class="truncate">
                    {{
                        `${$get(
                            item,
                            'rentInfoData[0].contractNo',
                            $get(item, 'rentInfoData[0].externalContractNo', '')
                        )} ${
                            $get(item, 'rentAnnex.contractAnnexNo', false)
                                ? '| ' + $get(item, 'rentAnnex.contractAnnexNo')
                                : ''
                        }`
                    }}
                </td>
                <!-- === === STATE=== === -->
                <td class="truncate">
                    {{ $get(item, 'settlementStatus') }}
                </td>
                <!-- === === MONTHLY COST=== === -->
                <td class="truncate">
                    {{
                        item.monthlyCosts && item.monthlyCosts !== 0
                            ? `${item.monthlyCosts.toFixed(2)} PLN`
                            : '-'
                    }}
                </td>
                <!-- === === MORE=== === -->
                <td class="d-flex align-center justify-end">
                    <v-btn small icon>
                        <v-icon :id="item._id" @click="onExpand(item)"
                            >$expand</v-icon
                        >
                    </v-btn>
                </td>
            </tr>
            <tr :key="'b' + index">
                <td
                    id="CollapsedTable"
                    v-if="isExpanded(item)"
                    style="
                        padding: 10px 10px;
                        border-bottom: 2px solid #19aa8d;
                    "
                    :colspan="headers.length"
                >
                    <v-data-table
                        hide-default-footer
                        :headers="deviceHeaders"
                        :items="getRentSettlementTable.items"
                        :items-per-page="-1"
                    >
                        <template v-slot:body>
                            <Columns
                                @settlement-modal="editSettlement"
                                @confirm-modal="confirmModal"
                                :items="getRentSettlementTable.items"
                                :settlement="item"
                              
                            />
                        </template>
                    </v-data-table>
                </td>
            </tr>
        </template>
    </tbody>
</template>
<script>
import Columns from '../../Rent-settlements/Tabs/Table/Columns.vue'
import Buttons from '../../Rent-annex/Tabs/Rent-devices/Modal/Buttons.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { GlobalActions } from '../../../../../../plugins/constsTypes'
export default {
    props: ['items', 'isExpanded', 'expand', 'headers'],
    data() {
        return {
            lastSettlementId: '',
            show: false,
            GlobalActions: GlobalActions,
            openConfirm: false,
            removeItemID: '',
            open: false,
            openEdit: false,
            targetItem: '',
            deviceHeaders: [
                {
                    text: this.$t('rent-info:devices'),
                    value: 'device.model',
                    width: '9%',
                    class: 'CustomHeader',
                },
                {
                    text: this.$t('rent-info:rent'),
                    value: 'device',
                    width: '7%',
                    class: 'CustomHeader',
                },
                {
                    text: this.$t(
                        'rent-info:numberMonoPrintsandMonoPrintPrice'
                    ),
                    value: 'monoPackageAndMonoPrice',
                    width: '8%',
                    sortable: false,
                    class: ['CustomHeader maxWidth'],
                },
                {
                    text: this.$t('rent-info:monoPackage'),
                    value: 'monoPackage',
                    width: '4%',
                    class: 'CustomHeader',
                },
                {
                    text: this.$t(
                        'rent-info:numberColorPrintsandColorPrintPrice'
                    ),
                    value: 'colorPackageAndColorPrice',
                    width: '8%',
                    sortable: false,
                    class: ['CustomHeader maxWidth'],
                },
                {
                    text: this.$t('rent-info:colorPackage'),
                    value: 'colorPackage',
                    width: '4%',
                    class: 'CustomHeader',
                },
                {
                    text: this.$t(
                        'rent-info:numberScanPrintsandScanPrintPrice'
                    ),
                    value: 'scanPackageAndScanPrice',
                    width: '7%',
                    class: 'CustomHeader',
                },
                {
                    text: this.$t('rent-info:monitoringPrice'),
                    value: 'monitoringPrice',
                    width: '6%',
                    class: 'CustomHeader',
                },
                {
                    text: this.$t('rent-info:overallPrice'),
                    value: 'overallPrice',
                    width: '8%',
                    class: 'CustomHeader',
                },
                {
                    text: this.$t('rent-info:settlementType'),
                    value: 'settlementType',
                    width: '10%',
                    class: 'CustomHeader',
                    sortable: false,
                },
                {
                    text: '',
                    value: 'arrow',
                    width: '4%',
                    sortable: false,
                },
            ],
        }
    },
    components: {
        Columns,
        Buttons,
    },
    computed: {
        ...mapGetters(['getRentSettlementTable']),
    },

    methods: {
        ...mapMutations(['clearRentSettlementTable']),
        ...mapActions(['fetchRentSettlementTable']),

        

        editSettlement(id) {
            this.$emit('open-settlement-modal', id)
        },

        confirmModal(id) {
            this.$emit('open-confirm-modal', id)
        },
        onExpand(item) {
            this.$emit('set-id', item)
            this.clearRentSettlementTable()
            this.fetchRentSettlementTable({ instanceId: item._id })
            this.expand(item, !this.isExpanded(item))
            for (const el of this.items) {
                if (el._id !== item._id) this.expand(el, false)
                const element = document.getElementById(el._id)
                if (this.isExpanded(el)) {
                    element.style.transform = 'rotate(180deg)'
                } else {
                    element.style.transform = 'rotate(0deg)'
                }
            }
        },
    },
}
</script>
<style lang="sass" scoped>
.CustomHeader
    white-space: nowrap !important

#CollapsedTable
    padding: 10px 10px
    border-bottom: 2px solid #19aa8d
    &:hover
        background: #FFFFFF !important
</style>
``