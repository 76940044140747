<template>
    <tbody>
        <tr v-for="(item, index) in items" :key="index">
            <td class="truncate">
                <DoubleLine
                    :firtstLine="
                        $moment(item.date).format(`DD-MM-YYYY`) ||
                        $t('global:empty')
                    "
                    :secondLine="
                        $moment(item.date).format(`HH:mm`) || $t('global:empty')
                    "
                />
            </td>
            <td class="truncate">
                {{ evaluateFields(item)[0] }}
                <!-- <DoubleLine
                    :firtstLine="item.fieldName || $t('global:empty')"
                    :secondLine="item.taskDescription || $t('global:empty')"
                /> -->
            </td>
            <td class="truncate">
                <span>{{ evaluateFields(item)[1] }}</span>
            </td>
            <td class="truncate">
                <span>{{
                    item.user
                        ? item.user.name + ' ' + item.user.lastname
                        : $t('global:userDeleted')
                }}</span>
            </td>
        </tr>
    </tbody>
</template>
<script>
import isIsoDate from 'is-iso-date'
export default {
    props: ['items'],
    methods: {
        evaluateFields(item) {
            if(item.mail && item.action)
                return [ this.$t(`logs:${item.action}`), item.mail.reduce((a,b)=>{return `${a}, ${b}`}) ]
            if (item.file || item.folder) {
                let actionCon =
                    item.action == 'added'
                        ? 'to'
                        : item.action == 'removed'
                        ? 'from'
                        : 'in'
                return [
                    `[${this.$t(`logs:${item.tab}`)}]`,
                    `[${this.$t(`logs:${item.action}`)}] ${item.file} ${this.$t(
                        `logs:${actionCon}`
                    ).toLowerCase()} ${item.folder}`,
                ]
            }
            if (isIsoDate(item.fieldValue)) {
                item.fieldValue = this.$moment(item.fieldValue).format(
                    'YYYY-MM-DD hh:mm:ss'
                )
            }
            if (item.fieldName && item.fieldValue && item.fieldValue.field)
                return [
                    this.$t(`${this.$route.name}:${item.fieldName}`),
                    item.fieldValue.field,
                ]
            if (item.fieldName && (item.fieldValue || item.fieldValue === '')) {
                // sprawdza czy tłumaczenie nie jest zagnieżdżone, gdy jest zagnieżdżone dopisuje do tłumaczenia odwołanie '.default'
                const isNested =
                    this.$t(`${this.$route.name}:${item.fieldName}`, {
                        returnObjects: true,
                    }) instanceof Object
                return [
                    isNested
                        ? this.$t(`${this.$route.name}:${item.fieldName}.default`)
                        : this.$t(`${this.$route.name}:${item.fieldName}`), 
                    item.fieldValue instanceof Object
                        ? item.fieldValue.name
                        : item.fieldValue,
                ]
            }
            if (item.tab)
                return [
                    `[${this.$t(`logs:${item.tab}`)}]`,
                    `[${this.$t(`logs:${item.action}`)}] ${item.fieldValue}`,
                ]
            if (!item.fieldName && !item.fieldValue && item.action)
                return [this.$t(`logs:${item.action}`), '']
            if (item.fieldName && !item.fieldValue && item.action)
                return [
                    this.$t(`${this.$route.name}:${item.fieldName}`),
                    `[${this.$t(`logs:${item.action}`)}]`,
                ]
            if (item.fieldValue && item.action)
                return [this.$t(`logs:${item.action}`), item.fieldValue]
        },
    },
}
</script>
