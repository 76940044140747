import Rest2 from '../../../plugins/Rest2'
import Rest from './../../../plugins/Rest'
import validation from './../../../plugins/validation'

const state = {
    modal: {
        fields: {},
        errors: {
            stepOne: {
                validation: true,
                fields: {},
            },
        },
        step: 1,
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: Infinity,
        pages: 1,
        defaultSort: '-createdAt',
    },
    //TU BEDZIE POPULATE 
    request: {
        populate: [
            {
                from: 'users',
                localField: 'createdBy',
                foreignField: '_id',
                as: 'createdBy',
            },
            {
                from: 'rent-info',
                localField: 'rentInfo',
                foreignField: '_id',
                as: 'rentInfoData',
            },
            {
                from: 'rent-settlements',
                localField: 'rentSettlement',
                foreignField: '_id',
                as: 'rentSettlement'
            },
            {
                from: 'rent-device',
                localField: 'device',
                foreignField: '_id',
                as: 'rentDevices'
            }
           
        ],
        searchFields: ['index'],
        select: '',
        where: {
            isDeleted: false,
        },
    },
    dictFields: {},
    statuses: [],
    details: {},
    registry: {},
    selectedSettlementToPdf: {}
}

const getters = {
    getRentSettlementInstanceTable: state => state.table,
    getRentSettlementInstanceSearchFields: state => state.request,
    getSelectedSettlementToPdf: state => state.selectedSettlementToPdf
}

const actions = {
    async fetchRentSettlementInstanceTable(
        { commit, dispatch, rootState },
        params
    ) {
        try {
            let response = await new Rest2(
                'GET',
                `rent-settlements-instance/${params.id}`
            )
                .select(state.request.select)
                .limit(state.table.limit)
                .page(state.table.page)
                .where(state.request.where)
                .populate(state.request.populate)
                .search(state.request.searchFields, state.table.search)
                .sort(state.table.sort || state.table.defaultSort)
                .send()
            commit('setRentSettlementInstanceTable', response.data)
            
            
        } catch (error) {
            console.error(error)
        }
    },
    async createRentSettlementInstance({ commit, dispatch }, payload) {
        try {
            await new Rest2('POST', 'rent-settlements')
                .setBody(state.modal.fields)
                .send()
            commit('clearRentSettlementInstanceModal')
            commit('clearRentSettlementInstanceErrors')
            dispatch('fetchRentSettlementInstanceTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            //HANDLE ERRORS
            if (err) {
                commit('setSnackbars', { type: 'error' })
                validation.setErrors(state.modal.errors, err)
            }
        }
    },

    async fetchRentSettlementInstance({ state, commit, dispatch }, payload) {
        try {
            let response = await new Rest2(
                'GET',
                `rent-settlements/${payload.id}`
            )
                .populate(['contractor', 'createdBy', 'customerCarer'])
                .send()

            commit('setRentSettlementInstanceDetails', response.data)

            if (payload.next) payload.next()
        } catch (err) {
            console.error(err)
        }
    },
    async setSelectedSettlementToPdf({ state, commit, dispatch }, payload) {
        
        commit('SET_SELECTED_SETTLEMENT_TO_PDF', payload)
        
    }
}

const mutations = {
    setRentSettlementInstanceTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
    clearRentSettlementInstanceTable(state) {
        state.table.search = ''
        state.table.page = 1
        state.table.pages = 1
        state.table.items = []
        state.table.total = ''
    },
    setRentSettlementInstanceSearch(state, payload) {
        state.request.searchFields = payload
    },
    SET_SELECTED_SETTLEMENT_TO_PDF(state, payload){
     
        state.selectedSettlementToPdf = payload;
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}
