<template>
    <tbody>
        <tr
            v-for="(item, index) in items"
            :key="index"
            :style="!item.active ? 'color: #C6C6C6' : ''"
        >
            <td>
                <v-avatar
                    @click="setSeen(item)"
                    v-if="!item.seen"
                    class="cursor-pointer"
                    color="#ffcb00"
                    size="20"
                >
                    <span class="white--text">N</span>
                </v-avatar>
            </td>
            <td>
                {{ `${$get(item.from, 'name', '')}` }}
                {{ `${$get(item.from, 'lastname', '')}` }}
            </td>

            <td class="truncate">
                <span v-if="item.to && item.to.users">
                    <span v-for="user in item.to.users" :key="user._id"
                        >{{ user.name }} {{ user.lastname }}
                        <span v-if="item.to.users.length > 1" class="mr-1 comma"
                            >,
                        </span>
                    </span>
                </span>
                <span
                    v-if="
                        item.to &&
                        item.to.departments &&
                        item.to.departments.length > 0
                    "
                >
                    <span v-for="item in item.to.departments" :key="item._id"
                        >{{ item.name }}
                    </span>
                </span>
            </td>
            <td class="truncate">
                <span>{{ item.description }}</span>
            </td>
            <td
                class="truncate"
                v-if="$router.history.current.name === 'alerts'"
            >
                <span style="cursor: pointer;" @click="goToSource(item)">
                    <DoubleLine
                        :firtstLine="
                            item.alertSource
                                ? $t(`${item.alertSource.type}`)
                                : ''
                        "
                        :secondLine="
                            item.alertSource ? item.alertSource.index : ''
                        "
                    />
                </span>
            </td>
            <td
                class="truncate"
                :style="
                    isToday(item.alertDate) === true
                        ? 'background: #f6f7e6'
                        : 'background: none'
                "
            >
                <DoubleLine
                    :firtstLine="$moment(item.alertDate).format(`DD-MM-YYYY`)"
                    :secondLine="item.alertTime"
                />
            </td>
            <td>
                <span v-if="item.priority && item.priority.backgroundColor"
                    ><v-chip
                        :style="`color: ${$get(
                            item,
                            'priority.textColor',
                            '#555'
                        )}`"
                        :color="`${$get(
                            item,
                            'priority.backgroundColor',
                            '#FFFFFF'
                        )}`"
                        dark
                        label
                        small
                        >{{ `${$get(item, 'priority.field', '')}` }}</v-chip
                    ></span
                >
                <span v-else>{{ `${$get(item, 'priority.field', '')}` }}</span>
            </td>
            <td>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon
                            v-if="typeof item.repetitionNumber == 'number'"
                            class="cursor-pointer"
                            v-on="on"
                            small
                            color="errors"
                            size="35"
                            >mdi-calendar-refresh</v-icon
                        >
                    </template>
                    <span>{{ $t('alerts:recurringAlert') }}</span>
                </v-tooltip>
            </td>

            <td class="d-flex align-center justify-end">
                <v-btn
                    v-if="
                        typeof item.repetitionNumber == 'number' ||
                        item.from._id === getProfileDetails._id
                    "
                    icon
                    small
                    @click="$emit('open-modal'), $emit('edit-alert', item._id)"
                >
                    <v-icon color="secondary" small
                        >mdi-arrow-right-thick</v-icon
                    >
                </v-btn>
            </td>
        </tr>
    </tbody>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    props: {
        items: {
            type: Array,
        },
    },
    computed: {
        ...mapGetters(['getProfileDetails']),
    },
    methods: {
        ...mapActions(['updateAlert']),
        isToday(date) {
            if (
                this.$moment(date).format(`DD-MM-YYYY`) ===
                this.$moment(new Date()).format(`DD-MM-YYYY`)
            )
                return true
            else return false
        },
        async setSeen(item) {
            let success = await this.updateAlert({
                id: item._id,
                body: {
                    seen: true,
                    routeId: item.itemId,
                    userId: this.getProfileDetails._id,
                },
            })
            if (success) item.seen = true
        },
        goToSource(data) {
            this.$router.push(`${data.alertSource?.type}/${data.itemId}`)
        },
    },
}
</script>
