import Rest from './../../../plugins/Rest'
import findIndex from 'lodash.findindex'
import moment from 'moment'
import { cloneDeep } from 'lodash'

const getDefaultState = () => {
    return {
        details: {
            status: '',
            chooseService: {
                selectedService: '',
                estimatesServices: [],
                total: 0,
            },
            parts: {
                estimatesParts: [],
                total: 0,
            },

            transportConfig: {
                checkBoxAuto: true,
                kilometersToBeSettled: 0,
                typeOfKm: '',
                costOfTransport: 0.0,
                checkBoxReturn: '',
            },
            comments: {
                estimateValidTo: '',
                estimateAdditionalContent: 'Brak uwag',
                remarks: '',
            },
            estimatesBtns: {
                download: {
                    clicked: false,
                },
                print: {
                    clicked: false,
                },
                email: {
                    clicked: false,
                },
            },
        },
    }
}

const state = getDefaultState()

const getters = {
    getEstimates: state => state.details,
    getEstimatesDetailsTable: state => state.details?.parts?.estimatesParts,
    getEstimatesComments: state => state.details.comments,
    getEstimatesChooseService: state => state.details.chooseService,
    getEstimatesStatus: state => state.details.status,
    getEstimatesBtns: state => state.details.estimatesBtns,
}

const actions = {
    async updateServiceEstimate({ commit, dispatch }, payload) {
        let temp = [0]
     
        state.details.parts.estimatesParts.forEach(item => {
            const sum = item.price * item.quantity
 
            temp.push(sum)
        })

        const totalSum = parseFloat(temp.reduce((acc, val) => acc + val))
        state.details.parts.total = totalSum

        let temp2 = [0]
        state.details.chooseService?.estimatesServices.forEach(item => {
            const sum = item.price * item.quantity
            temp2.push(sum)
        })
        const totalSum2 = parseFloat(temp2.reduce((acc, val) => acc + val))
        state.details.chooseService.total = totalSum2

        try {
            const resp = await new Rest('PUT', `service/${payload.id}`)
                .setBody({ estimates: state.details })
                .send()
         
            if (resp.status === 200) {
                dispatch('fetchServiceEstimatesTab', { id: payload.id })
                commit('setSnackbars', { type: 'success' })
                return true;
            } else {

                return false;
            }
           
        } catch (err) {
            console.log(err);
            commit('setSnackbars', { type: 'error' })
        }
    },

    async setEstimateDetails({ commit, dispatch }, payload) {
        try {
            commit('SET_ESTIMATES_DETAILS', payload)
        } catch (err) {
            console.log(err)
        }
    },

    async fetchServiceEstimatesTab({ commit, dispatch }, payload) {
        try {
            let serviceReq = new Rest('GET', `service/${payload.id}`).select(
                'estimates'
            )

            let { 0: response } = await Promise.all([serviceReq.send()])

            if (!response.data?.estimates) {
                dispatch('resetState')

                response.data.estimates = cloneDeep(state.details)
            }

            if (response.data?.estimates?.transportConfig?.costOfTransport) {
                let costOfTransport =
                    response.data.estimates.transportConfig.costOfTransport

                response.data.estimates.transportConfig.costOfTransport = costOfTransport
            }

            if (!response.data?.estimates?.comments?.estimateValidTo) {
                const date = moment(new Date(), 'DD-MM-YYYY')
                    .add(3, 'days')
                    .toISOString()
                    .substr(0, 10)

                response.data.estimates.comments.estimateValidTo = date
            }

            dispatch('setEstimateDetails', response.data.estimates)
        } catch (error) {
            console.error(error)
        }
    },

    async fetchGoogleLocationData({ commit, dispatch }, payload) {
        const clientLocation = payload.clientLocation
        const serviceDetails = payload.serviceDetails
        const companyLoaction = payload.companyLoaction

        try {
            const response = await new Rest('POST', '/getGeoLocation')
                .setBody({
                    clientLocation,
                    serviceDetails,
                    companyLoaction,
                })
                .send()

            if (response?.data?.status === 'OK') {
                return response.data
            } else {
                return response
            }
        } catch (err) {
            return err
        }
    },
    async setEstimatesModalTransportConfig({ commit, dispatch }, payload) {
        commit('SET_ESTIMATES_MODAL_TRANSPORT_CONFIG', payload)
    },
    setEstimatesModalComments({ commit, dispatch }, payload) {
        commit('SET_ESTIMATES_MODAL_COMMENTS', payload)
    },
    setEstimatesModalChooseService({ commit, dispatch }, payload) {
        commit('SET_ESTIMATES_MODAL_CHOOSE_SERVICE', payload)
    },
    setEstimatesModalChooseTable({ commit, dispatch }, payload) {
        commit('SET_ESTIMATES_MODAL_SERVICE_TABLE', payload)
    },
    setEstimateModalParts({ commit, dispatch }, payload) {
        commit('SER_ESTIMATE_MODAL_PARTS', payload)
    },
    setEstimateStatus({ commit, dispatch }, payload) {
        commit('SET_ESTIMATE_STATUS', payload)
    },
    setBtns({ commit, dispatch }, payload) {
        commit('SET_BTNS', payload)
    },
    resetState({ commit }) {
        commit('RESET_STATE')
    },
}

const mutations = {
    SET_ESTIMATES_DETAILS(state, payload) {
        state.details = payload
    },

    SET_ESTIMATES_MODAL_TRANSPORT_CONFIG(state, payload) {
        state.details.transportConfig = payload
    },
    SET_ESTIMATES_MODAL_COMMENTS(state, payload) {
        state.details.comments = payload
    },
    SET_ESTIMATES_MODAL_CHOOSE_SERVICE(state, payload) {
        state.details.chooseService = payload
    },

    SET_ESTIMATES_MODAL_SERVICE_TABLE(state, payload) {
        if (payload.field) {
            state.details.chooseService?.estimatesServices.push(payload)
        }
    },
    SER_ESTIMATE_MODAL_PARTS(state, payload) {
        state.details.parts.estimatesParts = payload
    },
    SET_ESTIMATE_STATUS(state, payload) {
        state.details.status = payload
    },
    SET_BTNS(state, payload) {
        if (payload === 'email') {
            state.details.estimatesBtns.email.clicked = true
        }
        if (payload === 'print') {
            state.details.estimatesBtns.print.clicked = true
        }
        if (payload === 'download') {
            state.details.estimatesBtns.download.clicked = true
        }
    },
    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
