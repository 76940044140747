var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tbody',_vm._l((_vm.items),function(item,index){return _c('tr',{key:index,style:(!item.active ? 'color: #C6C6C6' : '')},[_c('td',[(!item.seen)?_c('v-avatar',{staticClass:"cursor-pointer",attrs:{"color":"#ffcb00","size":"20"},on:{"click":function($event){return _vm.setSeen(item)}}},[_c('span',{staticClass:"white--text"},[_vm._v("N")])]):_vm._e()],1),_c('td',[_vm._v(" "+_vm._s(`${_vm.$get(item.from, 'name', '')}`)+" "+_vm._s(`${_vm.$get(item.from, 'lastname', '')}`)+" ")]),_c('td',{staticClass:"truncate"},[(item.to && item.to.users)?_c('span',_vm._l((item.to.users),function(user){return _c('span',{key:user._id},[_vm._v(_vm._s(user.name)+" "+_vm._s(user.lastname)+" "),(item.to.users.length > 1)?_c('span',{staticClass:"mr-1 comma"},[_vm._v(", ")]):_vm._e()])}),0):_vm._e(),(
                    item.to &&
                    item.to.departments &&
                    item.to.departments.length > 0
                )?_c('span',_vm._l((item.to.departments),function(item){return _c('span',{key:item._id},[_vm._v(_vm._s(item.name)+" ")])}),0):_vm._e()]),_c('td',{staticClass:"truncate"},[_c('span',[_vm._v(_vm._s(item.description))])]),(_vm.$router.history.current.name === 'alerts')?_c('td',{staticClass:"truncate"},[_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.goToSource(item)}}},[_c('DoubleLine',{attrs:{"firtstLine":item.alertSource
                            ? _vm.$t(`${item.alertSource.type}`)
                            : '',"secondLine":item.alertSource ? item.alertSource.index : ''}})],1)]):_vm._e(),_c('td',{staticClass:"truncate",style:(_vm.isToday(item.alertDate) === true
                    ? 'background: #f6f7e6'
                    : 'background: none')},[_c('DoubleLine',{attrs:{"firtstLine":_vm.$moment(item.alertDate).format(`DD-MM-YYYY`),"secondLine":item.alertTime}})],1),_c('td',[(item.priority && item.priority.backgroundColor)?_c('span',[_c('v-chip',{style:(`color: ${_vm.$get(
                        item,
                        'priority.textColor',
                        '#555'
                    )}`),attrs:{"color":`${_vm.$get(
                        item,
                        'priority.backgroundColor',
                        '#FFFFFF'
                    )}`,"dark":"","label":"","small":""}},[_vm._v(_vm._s(`${_vm.$get(item, 'priority.field', '')}`))])],1):_c('span',[_vm._v(_vm._s(`${_vm.$get(item, 'priority.field', '')}`))])]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(typeof item.repetitionNumber == 'number')?_c('v-icon',_vm._g({staticClass:"cursor-pointer",attrs:{"small":"","color":"errors","size":"35"}},on),[_vm._v("mdi-calendar-refresh")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('alerts:recurringAlert')))])])],1),_c('td',{staticClass:"d-flex align-center justify-end"},[(
                    typeof item.repetitionNumber == 'number' ||
                    item.from._id === _vm.getProfileDetails._id
                )?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.$emit('open-modal'), _vm.$emit('edit-alert', item._id)}}},[_c('v-icon',{attrs:{"color":"secondary","small":""}},[_vm._v("mdi-arrow-right-thick")])],1):_vm._e()],1)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }