<template>
    <div class="d-flex justify-center">
        <v-btn
            color="rgb(117, 117, 117)"
            width="110"
            height="40"
            @click="$emit('close-confirm')"
            class="white--text mx-2"
            >{{ $t('cancel') }}</v-btn
        >
        <v-btn
            width="110"
            height="40"
            color="#445d75"
            class="white--text mx-2"
            @click="remove()"
            >{{ $t('remove') }}</v-btn
        >
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    props: ['removeId'],
    methods: {
        ...mapActions(['removeFolder']),
        async remove() {
            let success = await this.removeFolder({
                id: this.removeId,
                routeId: this.$route.params.id,
                routeName: this.$route.name,
            })
            if (success) this.$emit('close-confirm')
        },
    },
}
</script>
