import Rest from './../../../plugins/Rest'
import validation from './../../../plugins/validation'
import { clone } from 'lodash'
import store from '../../../store/index'
import moment from 'moment'
import Rest2 from '../../../plugins/Rest2'

const state = {
    modal: {
        device: {},
        fields: {
            device: '',
            prevMonoCounter: 0,
            currentMonoCounter: 0,
            prevColorCounter: 0,
            currentColorCounter: 0,
            prevScanCounter: 0,
            currentScanCounter: 0,
            monthlyMono: 0,
            monthlyColor: 0,
            monthlyScan: 0,
            rentDevices: '',
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {
                    monthlyMono: '',
                    monthlyColor: '',
                    monthlyScan: '',
                },
            },
        },
        step: 1,
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 10,
        pages: 1,
        defaultSort: '-createdAt',
    },
    request: {
        populate: [
            {
                from: 'rent-device',
                localField: 'rentDevices',
                foreignField: '_id',
                as: 'rentDevices',
            },
            {
                from: 'devices',
                localField: 'rentDevices.device',
                foreignField: '_id',
                as: 'rentDevices.device',
            },
            {
                from: 'models',
                localField: 'rentDevices.device.model',
                foreignField: '_id',
                as: 'rentDevices.device.model',
            },
        ],
        searchFields: ['index'],
        select: '',
        where: {
            isDeleted: false,
        },
    },
    dictFields: {},
    statuses: [],
    details: {},
    registry: {},
}

const getters = {
    getRentSettlementModal: state => state.modal.fields,
    getRentSettlementPrice: state => state.modal.device,
    getRentSettlementRegistry: state => state.registry,
    getRentSettlementTable: state => state.table,
    getRentSettlementSearchFields: state => state.request,
    getRentSettlementErrors: state => state.modal.errors,
    getRentSettlementStep: state => state.modal,
    getRentSettlementStatuses: state => state.statuses,
    getRentSettlementDetails: state => state.details,
}

const actions = {
    setIsSettlementedManually({ state }, payload) {
        state.modal.fields.settlementType = 'MANUAL'
    },

    async fetchRentSettlementTable({ commit, dispatch, rootState }, payload) {
        try {
            let response = await new Rest2(
                'GET',
                `rent-settlements/${payload.instanceId}`
            )
                .select(state.request.select)
                .where(state.request.where)
                .populate(state.request.populate)
                .sort(state.table.sort || state.table.defaultSort)
                .send()
            commit('setRentSettlementTable', response.data)
        } catch (error) {
            console.error(error)
        }
    },

    async createRentSettlement({ commit, dispatch }, params) {
        try {
            await new Rest2('POST', `rent-settlements`)
                .setBody({ settlement: state.modal.fields })
                .send()
            commit('clearRentSettlementModal')
            commit('clearRentSettlementErrors')
            await dispatch('fetchRentSettlementInstanceTable', params)
            await dispatch('fetchRentDeviceTable', {
                contractId: params.contractId,
            })
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            //HANDLE ERRORS
            if (err) {
                commit('setSnackbars', { type: 'error' })
                validation.setErrors(state.modal.errors, err)
            }
        }
    },

    async createRentSettlementFromApi({ commit, dispatch }, payload) {
        const currentTableMode = store.getters.getRentInfoTable.currentMode
        
        try {

            await new Rest2('POST', '/rent-settlements/createFromAPI')
                .setBody({ item: payload.item })
                .send()
            await dispatch('fetchRentSettlementInstanceTable', payload)
            await dispatch('fetchRentAnnexTable', payload)

            if (currentTableMode === 'BEFORE') {
                await dispatch('fetchRentInfoTable', {
                    unsettledBeforeCurrentDay: true,
                })
            } else if (currentTableMode === 'AFTER') {
                await dispatch('fetchRentInfoTable', {
                    unsettledAfterCurrentDay: true,
                })
            } else if (currentTableMode === 'TODAY') {
                await dispatch('fetchRentInfoTable', {
                    unsettled: true,
                })
            } else {
                await dispatch('fetchRentInfoTable')
            }

            await dispatch('fetchRentDeviceTable', {
                contractId: payload.item._id,
            })
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            //HANDLE ERRORS
            if (err) {
                if (err?.response?.data?.message) {
                    if (err?.response?.data?.message === 'NoActiveAnex') {
                        commit('setSnackbars', {
                            type: 'error',
                            text: 'Brak aktywnego aneksu',
                        })
                        return
                    }
                    commit('setSnackbars', {
                        type: 'error',
                        text: err?.response?.data?.message,
                    })
                } else {
                    commit('setSnackbars', { type: 'error' })
                }
            }
            return false
        }
    },

    async createRentSettlementAll({ commit, dispatch }, payload) {
        try {
            await new Rest2('POST', '/rent-settlements/createFromHistory')
                .setBody({ item: payload.item })
                .send()
            await dispatch('fetchRentSettlementInstanceTable', payload)
            await dispatch('fetchRentAnnexTable', payload)
            await dispatch('fetchRentInfoTable')
            await dispatch('fetchRentDeviceTable', {
                contractId: payload.item._id,
            })
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            //HANDLE ERRORS
            if (err) {
                if (err?.response?.data?.message) {
                    if (err?.response?.data?.message === 'NoActiveAnex') {
                        commit('setSnackbars', {
                            type: 'error',
                            text: 'Brak aktywnego aneksu',
                        })
                        return
                    }
                    commit('setSnackbars', {
                        type: 'error',
                        text: err?.response?.data?.message,
                    })
                } else {
                    commit('setSnackbars', { type: 'error' })
                }
            }
            return false
        }
    },

    async updateRentSettlement({ commit, dispatch }) {
        try {
            await new Rest2('PUT', `rent-settlements/${state.details._id}`)
                .setBody(state.modal.fields)
                .send()
            dispatch('fetchRentSettlement', { id: state.details._id })
            commit('clearRentSettlementModal')
            commit('clearRentSettlementErrors')
            return true
        } catch (err) {
            //HANDLE ERRORS
            validation.setErrors(state.modal.errors, err)
            commit('setSnackbars', { type: 'error' })
        }
    },

    async fetchAPIDevice({}, payload) {
        try {
            const { sn } = payload

            let response = await new Rest2(
                'GET',
                `rent-settlements/getLexmarkAPIDevice`
            )
                .setParams({
                    sn,
                })
                .send()
            return response.data
        } catch (err) {
            console.error(err)
        }
    },

    async fetchLastRentSettlement({ state }, payload) {
        try {
            const { rentDeviceId } = payload

            let response = await new Rest2(
                'GET',
                `rent-settlements/last/${rentDeviceId}`
            )
                .select(state.request.select)
                .populate([
                    {
                        path: 'rentDevices',
                        populate: { path: 'device', populate: 'model' },
                    },
                    {
                        path: 'rentSettlementsInstance',
                        populate: {
                            path: 'createdBy',
                            select: 'name lastname createdAt',
                        },
                    },
                ])
                .send()
            return response.data
        } catch (err) {
            console.error(err)
        }
    },

    async fetchRentSettlement({ state, commit }, payload) {
        try {
            const { id } = payload
            
            let response = await new Rest2('GET', `rent-settlements/one/${id}`)
                .select(state.request.select)
                .populate([
                    {
                        path: 'rentDevices',
                        populate: { path: 'device', populate: 'model' },
                    },
                    {
                        path: 'rentSettlementsInstance',
                        populate: {
                            path: 'createdBy',
                            select: 'name lastname createdAt',
                        },
                    },
                ])
                .send()
                
            commit('setRentSettlementDetails', response.data)
        } catch (err) {
            console.error(err)
        }
    },

    async updateRentSettlementStatus({ commit, dispatch }, payload) {
        commit('setRentSettlementModal')
        commit('setRentSettlementStatus', payload.status)
        try {
            await new Rest2('PUT', `rent-settlements/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            commit('setSnackbars', { type: 'success' })
        } catch (err) {
            console.error(err)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async deleteRentSettlement({ commit, dispatch }, payload) {
        try {
            await new Rest2('DELETE', `rent-settlements/${payload.id}`).send()
            dispatch('fetchRentSettlementTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            console.error(err)
        }
    },
}

const mutations = {
    setRentSettlementTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
    setRentSettlementDetails(state, payload) {
        state.modal.device.device = payload.rentDevices
        state.details = payload
       
    },
    setRentSettlementStatuses(state, payload) {
        state.statuses = payload
    },
    setRentSettlementModal(state, payload) {
        let obj = payload ? payload : clone(state.details)
        obj.device = obj.rentDevices.device
        state.modal.fields = obj
        store.commit('setRepresentativesInput', {
            items: obj?.contractor?.representatives,
        })
        state.modal.fields.representative = obj?.contractor?.representatives?.find(
            e => e._id === obj.representative
        )
        if (obj.startDate)
            state.modal.fields.startDate = moment(obj.startDate).format(
                'YYYY-MM-DD'
            )
        if (obj.expirationDate)
            state.modal.fields.expirationDate = moment(
                obj.expirationDate
            ).format('YYYY-MM-DD')
    },
    setRentSettlementStatus(state, payload) {
        state.modal.fields.status = payload
    },
    setRentSettlementRegistry(state, payload) {
        state.registry = payload[0]
    },
    clearRentSettlementModal() {
        state.modal.step = 1
        state.modal.fields = {
            device: '',
            prevMonoCounter: 0,
            currentMonoCounter: 0,
            prevColorCounter: 0,
            currentColorCounter: 0,
            prevScanCounter: 0,
            currentScanCounter: 0,
            monthlyMono: 0,
            monthlyColor: 0,
            monthlyScan: 0,
            rentDevices: '',
        }
    },
    clearRentSettlementErrors(state) {
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step].fields) {
                state.modal.errors[step].fields[key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
    clearRentSettlementTable(state) {
        state.table.search = ''
        state.table.page = 1
        state.table.pages = 1
    },
    setRentSettlementSearch(state, payload) {
        state.request.searchFields = payload
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
