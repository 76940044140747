<template>
    <v-container class="d-flex justify-space-between px-2 py-0">
        <div class="central__title">
            <router-link tag="div" :to="`/${registryUrl}`">
                <h1
                    @click="$emit('clearSearch'), clearSearchText()"
                    class="font-weight-light cursor-pointer mt-1"
                >
                    {{ registryTitle }}
                </h1>
            </router-link>
        </div>
    </v-container>
</template>
<script>
/**
 * Admin Header (Registries, Groups, Dictionaries, Logs, Templates, Resources)
 * @displayName  Admin Header
 */
import { mapGetters } from 'vuex'
export default {
    props: {
        /**
         * Main view / Title
         */
        registryTitle: {
            required: true,
        },
        /**
         * Registry Url
         */
        registryUrl: {
            type: String,
            // required: true,
        },
    },
    methods: {
        clearSearchText() {
            this.getSearch.text = ''
        },
    },
    computed: {
        ...mapGetters(['getSearch']),
    },
}
</script>
