<template>
    <v-app>
        <div>
            <PDFServiceProtocol
                ref="protocol"
                v-if="this.$route.query.isTechnic == 'true'"
            />
            <PDFClientProtocol ref="protocol" v-else />
            <PDFServicePartsProtocol
                ref="parts"
                v-if="this.$route.query.isTechnic == 'true'"
            />
            <PDFClientPartsProtocol ref="parts" v-else />
            <PDFButtons :refProps="this.$refs" />
            <Snackbars />
        </div>
    </v-app>
</template>
<script>
import PDFServiceProtocol from './PDFServiceProtocol.vue'
import PDFServicePartsProtocol from './PDFServicePartsProtocol.vue'
import PDFClientProtocol from './PDFClientProtocol.vue'
import PDFClientPartsProtocol from './PDFClientPartsProtocol.vue'
import PDFButtons from './PDFButtons.vue'

import store from './../../../../store/index'

export default {
    data() {
        return {}
    },

    components: {
        PDFServiceProtocol,
        PDFServicePartsProtocol,
        PDFClientProtocol,
        PDFClientPartsProtocol,
        PDFButtons,
    },

    beforeRouteEnter(to, from, next) {
        store.dispatch('fetchService', { id: to.query.id, next })
    },
}
</script>
