<template>
    <div v-if="isSettlementInLoading">
        <div style="justify-content: center; text-align: center;">
            <v-progress-circular
                :size="15"
                :width="2"
                color="primary"
                indeterminate
            ></v-progress-circular>
        </div>
    </div>
    <div v-else>
        <v-list-item :disabled="getIsDisabled()" @click="openCSVFile">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <span
                        class="d-flex"
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-list-item-icon
                            :disabled="getIsDisabled()"
                            class="mr-0"
                        >
                            <input
                                ref="CSVFileInput"
                                type="file"
                                style="display: none;"
                                @change="
                                    e => selectFileCSV(e, item, item.rentInfo)
                                "
                                accept=".csv,.xls "
                            />
                            <v-icon
                                :disabled="getIsDisabled()"
                                size="16"
                                color="#19aa8d"
                            >
                                mdi-file-document</v-icon
                            >
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t('rent-contracts:rent-annex.rentFromCSV')
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </span>
                </template>

                <span>{{
                    $t('rent-contracts:rent-annex.rentFromCSVTooltip')
                }}</span>
            </v-tooltip>
        </v-list-item>
    </div>
</template>
<script>
import Rest from '../../../../../../../plugins/Rest'
import { mapGetters } from 'vuex'
import { selectFileCSV, isSettlement } from '../../../../Utils/RentDevicesUtils'
import Spinner from '../../../../../../Global/Table/Spinner.vue'
const MANAGER_ACCESS_BUTTON_COLOR = 'warning';
export default {
    props: {
        item: { type: Object, required: true },
    },
    data() {
        return {
            selectFileCSV,
            isDisabled: true,
            isSettlementInLoading: false,
        }
    },
    components: {
        Spinner,
    },
    computed: {
        ...mapGetters(['getProfileDetails', 'getRentInfoRegistry']),
    },
    methods: {
        openCSVFile() {
            this.$refs.CSVFileInput.click()
        },
        async isSettlementIn(item) {
            this.isSettlementInLoading = true
            let {
                data: { items },
            } = await new Rest('GET', 'rent-device/' + item._id)
                .select('rentSettlementObj contract')
                .where({ isDeleted: false })
                .populate([
                    {
                        from: 'rent-annex',
                        localField: 'contractId',
                        foreignField: '_id',
                        as: 'contract',
                    },
                    {
                        from: 'rent-settlements',
                        localField: 'settlementId',
                        foreignField: '_id',
                        as: 'rentSettlementObj',
                    },
                ])
                .unwindExeptions(['settlementId'])
                .send()
                this.isSettlementInLoading = false
            if (!items.length > 0) {
            
                return false
            }
            if (items.some(el => 'yellow' === isSettlement(el))) {
                return '#fb8c00'
            }
            if (items.every(el => 'red' === isSettlement(el))) {
                return MANAGER_ACCESS_BUTTON_COLOR
            }
          
            return 'green'
        },
        getIsDisabled() {
            
            if(this.item && this.item.status.state === 'DRAFT'){
                return true;
            }

            if (
                this.getRentInfoRegistry.currentMenager.mail ===
                this.getProfileDetails.mail
            ) {
                return false
            }
           
            return this.isDisabled
        },
    },

    watch: {
        item: async function (val) {
            const resp = await this.isSettlementIn(this.item);

            this.isDisabled = !(await this.isSettlementIn(val)) === MANAGER_ACCESS_BUTTON_COLOR
        },
    },
    async mounted() {
        const resp = await this.isSettlementIn(this.item);
        
        this.isDisabled = (await this.isSettlementIn(this.item)) === MANAGER_ACCESS_BUTTON_COLOR
    },
}
</script>
