<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="3" xl="3" lg="2" md="12" class="pa-5">
                <v-row>
                    <v-col cols="12" class="d-flex flex-column">
                        <p
                            class="font-weight-bold d-flex flex-column headline pa-0 ma-0 mb-5"
                        >
                            {{
                                $get(
                                    getLastValueRentInfo,
                                    'annex.contractor.name',
                                    $t('global:empty')
                                )
                            }}
                        </p>
                        <span
                            ><b
                                >{{
                                    `${$t(
                                        'rent-contracts:rent-info.contractNo'
                                    )}`
                                }}:</b
                            >
                            {{
                                $get(
                                    getLastValueRentInfo,
                                    'contractNo',
                                    false
                                ) ||
                                $get(
                                    getLastValueRentInfo,
                                    'externalContractNo',
                                    $t('g lobal:empty')
                                )
                            }}</span
                        >
                        <span
                            ><b
                                >{{
                                    `${$t(
                                        'rent-contracts:rent-info.expirationDate'
                                    )}`
                                }}:</b
                            >
                            {{
                                getLastValueRentInfo.annex.expirationDate
                                    ? $moment(
                                          getLastValueRentInfo.annex
                                              .expirationDate
                                      ).format(`DD/MM/YYYY`)
                                    : $t('global:empty')
                            }}</span
                        >
                        <span
                            class="annexStartBundleText"
                            v-if="getAnnexStatus() === 'Aktywny OP'"
                            >{{
                                $t('rent-contracts:rent-info.welcomeBundleText')
                            }}</span
                        >
                        <span
                            class="annexStartBundleText"
                            v-if="getAnnexStatus() === 'Aktywny OP'"
                            >{{ $t('rent-contracts:rent-info.numberOfMonth') }}:
                            {{ getAnnexStartBundleSize() }}</span
                        >
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="6" xl="6" lg="8" md="12" class="pa-5">
                <v-row>
                    <v-col cols="12"
                        ><v-text-field
                            readonly
                            :value="
                                $get(getLastValueRentInfo, 'annex.title', '')
                            "
                            :label="$t('rent-contracts:rent-info.title')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="px-3">
                    <v-col cols="4">
                        <v-row>
                            <v-text-field
                                v-if="getLastValueRentInfo.contractNo"
                                readonly
                                v-model="getLastValueRentInfo.contractNo"
                                :label="`${$t(
                                    'rent-contracts:rent-info.contractNo'
                                )}`"
                            >
                            </v-text-field>
                            <v-text-field
                                v-else
                                readonly
                                v-model="
                                    getLastValueRentInfo.externalContractNo
                                "
                                :label="`${$t(
                                    'rent-contracts:rent-info.externalContractNo'
                                )}`"
                            >
                            </v-text-field>
                        </v-row>
                    </v-col>
                    <v-col cols="4" offset="4">
                        <v-row align="center">
                            <v-checkbox
                                hide-details
                                disabled
                                v-model="
                                    getLastValueRentInfo.annex.contractAnnexNo
                                "
                                class="shrink mr-2 mt-0"
                            ></v-checkbox>
                            <v-text-field
                                readonly
                                v-model="
                                    getLastValueRentInfo.annex.contractAnnexNo
                                "
                                :disabled="
                                    !getLastValueRentInfo.annex.contractAnnexNo
                                "
                                :label="`${$t(
                                    'rent-contracts:rent-info.contractAnnex'
                                )}`"
                            >
                            </v-text-field>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                        ><v-text-field
                            readonly
                            :value="
                                $get(
                                    getLastValueRentInfo,
                                    'annex.contractor.name',
                                    ''
                                )
                            "
                            :label="$t('rent-contracts:rent-info.contractor')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="8"
                        ><v-text-field
                            readonly
                            :value="findRepresentative(getLastValueRentInfo)"
                            :label="
                                $t('rent-contracts:rent-info.representative')
                            "
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <v-select
                            readonly
                            append-icon=""
                            v-model="getLastValueRentInfo.annex.contractType"
                            :items="[getLastValueRentInfo.annex.contractType]"
                            :label="$t('rent-contracts:rent-info.contractType')"
                            return-object
                            item-value="field"
                        >
                            <template v-slot:selection="data">
                                <span
                                    v-if="
                                        data.item && data.item.backgroundColor
                                    "
                                >
                                    <v-chip
                                        class="mx-2"
                                        :color="data.item.backgroundColor"
                                        small
                                        label
                                        :text-color="
                                            data.item.textColor
                                                ? data.item.textColor
                                                : '#4d4d4d'
                                        "
                                    >
                                        {{ data.item.field }}
                                    </v-chip>
                                </span>
                                <span class="truncate-list" v-else>
                                    {{ data.item && data.item.field }}
                                </span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="4"> </v-col>
                    <v-col cols="4">
                        <v-text-field
                            readonly
                            :value="
                                getLastValueRentInfo.annex.responseTime.field
                            "
                            :label="$t('rent-contracts:rent-info.responseTime')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <money-field
                            class="margin--input"
                            :disabled="false"
                            :readonly="true"
                            :value="
                                getLastValueRentInfo.annex.implementationCost
                            "
                            :label="`${$t(
                                'rent-contracts:rent-info.implementationCost'
                            )}`"
                        ></money-field>
                    </v-col>
                    <v-col cols="4"> </v-col>

                    <v-col cols="4"
                        ><v-text-field
                            readonly
                            :value="
                                getLastValueRentInfo.annex
                                    .representativeSelectedEmail
                            "
                            :label="$t('rent-contracts:represnetativeEmail')"
                        ></v-text-field>
                    </v-col>
                    <!-- <v-col cols="4">
                        <money-field
                            class="margin--input"
                            :disabled="false"
                            :readonly="false"
                            :value="getLastValueRentInfo.annex.monitoringCost"
                            :label="`${$t(
                                'rent-contracts:rent-info.monitoringCost'
                            )}`"
                        ></money-field>
                    </v-col> -->
                </v-row>
                <v-row>
                    <v-col>
                        <v-textarea
                            class="NoteArea"
                            :label="$t('rent-contracts:rent-info.notes')"
                            rows="3"
                            v-model="getLastValueRentInfo.annex.notes"
                            no-resize
                            readonly
                        >
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="3" xl="3" lg="2" md="12" class="pa-5">
                <v-row>
                    <v-col cols="8">
                        <v-text-field
                            readonly
                            :value="
                                getLastValueRentInfo.annex.startDate
                                    ? $moment(
                                          getLastValueRentInfo.annex.startDate
                                      ).format('DD-MM-YYYY')
                                    : $t('global:empty')
                            "
                            :label="$t('rent-contracts:rent-info.startDate')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field
                            readonly
                            :value="
                                getLastValueRentInfo.annex.expirationDate
                                    ? $moment(
                                          getLastValueRentInfo.annex
                                              .expirationDate
                                      ).format('DD-MM-YYYY')
                                    : $t('global:empty')
                            "
                            :label="
                                $t('rent-contracts:rent-info.expirationDate')
                            "
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="5">
                        <v-text-field
                            readonly
                            :value="getLastValueRentInfo.annex.accountingDay"
                            :label="
                                $t('rent-contracts:rent-info.accountingDay')
                            "
                        ></v-text-field
                    ></v-col>
                    <v-col cols="5">
                        <v-text-field
                            readonly
                            :value="
                                getLastValueRentInfo.annex.paymentDeadline
                                    ? getLastValueRentInfo.annex.paymentDeadline
                                    : $t('global:empty')
                            "
                            :label="'Termin zapłaty'"
                        ></v-text-field
                    ></v-col>
                </v-row>
                <v-row class="background--total">
                    <v-col cols="12">
                        <span class="text--settlement">{{
                            $t('rent-contracts:rent-info.lastAccounting.header')
                        }}</span></v-col
                    >
                    <v-col cols="11"> </v-col>
                    <v-col cols="11">
                        <v-text-field
                            dense
                            readonly
                            :value="
                                getLastValueRentInfo.lastSettlement &&
                                getLastValueRentInfo.lastSettlement.createdBy
                                    ? `${getLastValueRentInfo.lastSettlement.createdBy.name} ${getLastValueRentInfo.lastSettlement.createdBy.lastname}`
                                    : ''
                            "
                            :label="
                                $t(
                                    'rent-contracts:rent-info.lastAccounting.user'
                                )
                            "
                        ></v-text-field
                    ></v-col>
                    <v-col cols="11">
                        <v-text-field
                            dense
                            readonly
                            :value="
                                $get(
                                    getLastValueRentInfo,
                                    'annex.settlementStatus',
                                    ''
                                )
                            "
                            :label="
                                $t(
                                    'rent-contracts:rent-info.lastAccounting.status'
                                )
                            "
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="11">
                        <v-text-field
                            readonly
                            :value="getLastValueRentInfo.annex.BIDProject"
                            :label="$t('rent-contracts:rent-info.BIDProject')"
                        ></v-text-field
                    ></v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    data() {
        return {
            checked: false,
            countedDevice: 0,
        }
    },
    computed: {
        ...mapGetters(['getLastValueRentInfo']),
    },
    methods: {
        ...mapActions(['updateRentInfoStatus']),
        findRepresentative(item) {
            const id = item.annex?.representative
            if (id) {
                const representative = item.annex?.contractor?.representatives?.find(
                    e => e._id === id
                )

                return `${this.$get(
                    representative,
                    'name',
                    'Brak'
                )} ${this.$get(representative, 'lastname', '')} ${
                    representative && representative.email ? '•' : ''
                } ${this.$get(representative, `email`, '')} ${
                    representative && representative.phoneNumber ? '•' : ''
                } ${this.$get(representative, 'phoneNumber', '')}`
            }
            return ''
        },
        findRepresentativeEmail(item) {
            const id = item.annex?.representative
            if (id) {
                const representative = item.annex?.contractor?.representatives?.find(
                    e => e._id === id
                )

                const email = `${this.$get(
                    representative,
                    'email',
                    'Brak podanego e-mail'
                )}`
                if (email !== '') {
                    return email
                }
                if (email === '') {
                    return 'Brak podanego e-mail'
                }
            }
            return ''
        },
        getAnnexStartBundle() {
            return this.getLastValueRentInfo?.annex?.startBundle
        },
        getAnnexStatus() {
            return this.getLastValueRentInfo?.annex?.status?.name
        },
        getAnnexStartBundleSize() {
            return this.getLastValueRentInfo?.annex?.numberOfPromotionalPeriods
        },
    },
}
</script>
<style lang="scss">
.NoteArea {
    textarea {
        max-height: 100px !important;
    }
}
.background--total {
    background-color: #eaecd1;
    font-size: 12px;
    padding: 3px;
}
.annexStartBundleText {
    margin-top: 5px;
    color: #d2770b;
    font-weight: 600;
}
</style>
