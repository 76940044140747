<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getNoteErrors.stepOne.validation,
                                ]"
                                editable
                                step="1"
                                >{{
                                    $t('notes:contentofTheNote')
                                }}</v-stepper-step
                            >

                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <Separator
                                            :text="$t(`${action}`) + ' ' + $t(`notes:notes`)"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea
                                            rows="1"
                                            auto-grow
                                            no-resize
                                            :error-messages="
                                                getNoteErrors.stepOne.fields.note
                                            "
                                            :label="`${$t('notes:note')}`"
                                            v-model="getNoteModal.note"
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                     <v-col v-if="action==GlobalActions.update" >
                                        <v-checkbox class="my-0 mx-3"
                                            v-model="getNoteModal.isDisabled"
                                            :label="$t('global:isDisabled')"
                                            hide-details
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { GlobalActions } from '../../../../../../plugins/constsTypes'

export default {
    i18nOptions: { namespaces: ['global', 'notes'] },
    data() {
        return {
            step: 1,
            editable: true,
            GlobalActions
        }
    },
    props:{
        action:String
    },
    computed: {
        ...mapGetters(['getNoteErrors', 'getNoteModal']),
    },
    methods: {}
}
</script>
