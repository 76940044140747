<template>
    <div class="doubleLine">
        <span>{{ firtstLine }}</span>
        <span v-if="typeof secondLine == 'string'" :class="`${!black?'grey--text':''}`">{{
            secondLine
        }}</span>
        <span v-else :class="`${!black?'grey--text':''}`">
            <span
                class="doubleLine__array"
                v-for="item in secondLine"
                :key="item._id"
                >{{ item.name }} {{ item.lastname }},
            </span>
        </span>
    </div>
</template>
<script>
export default {
    props: {
        firtstLine: {
            // type: String,
        },
        secondLine: {
            // type: String,
        },
        black:{
            type:Boolean
        }
    },
}
</script>
