import Rest from '../../../plugins/Rest'
import clone from 'lodash.clone'
import validation from './../../../plugins/validation'
const state = {
    modal: {
        fields: {
            name: null,
            shortName: null,
            contactId: null,
            NIP: null,
            state: null,
            city: null,
            postCode: null,
            post: null,
            street: null,
            apartmentNumber: null,
            houseNumber: null,
            email: null,
            phoneNumber: null,
            website: null,
            contactType: null,
            clientGuardian: null,
        },
        errors: {
            stepOne: {
                validation: true,
                name: null,
                shortName: null,
                contactId: null,
                NIP: null,
                state: null,
                city: null,
                postCode: null,
                post: null,
                street: null,
                apartmentNumber: null,
                houseNumber: null,
            },
            stepTwo: {
                validation: true,
                email: null,
                phoneNumber: null,
                website: null,
                contactType: null,
                clientGuardian: null,
            },
        },
    },
    details: {
        name: null,
        shortName: null,
        contactId: null,
        NIP: null,
        state: null,
        city: null,
        postCode: null,
        post: null,
        street: null,
        apartmentNumber: null,
        houseNumber: null,
        email: null,
        phoneNumber: null,
        website: null,
        contactType: null,
        clientGuardian: null,
    },
    request: {
        populate: [],
        searchFields: ['name'],
        select: '_id name NIP city street phoneNumber email',
        where: {
            isDeleted: false,
        },
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        sort: '',
        pages: 1,
        defaultSort: '',
    },
    statuses: [],
    registry: {},
}

const getters = {
    getContactsBoxTable: state => state.table,
    getContactsBoxSearchFields: state => state.request,
}

const actions = {
    async fetchContactsBoxTable({ commit, dispatch }) {
        try {
            let response = await new Rest('GET', 'contacts')
                .select(state.request.select)
                .limit(state.table.limit)
                .page(state.table.page)
                .search(state.request.searchFields, state.table.search)
                .where(state.request.where)
                .populate(state.request.populate)
                .sort(state.table.sort)
                .send()
            commit('setContactsBoxTable', response.data)
        } catch (err) {
            console.error(err)
        }
    },
}

const mutations = {
    setContactsBoxTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },

    setContactsSearch(state, payload) {
        state.request.searchFields = payload
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
