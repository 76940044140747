<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getTaskErrors.stepOne.validation,
                                ]"
                                editable
                                step="1"
                                >{{ $t('tasks:detailsTasks') }}</v-stepper-step
                            >

                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getTaskErrors.stepTwo.validation,
                                ]"
                                editable
                                step="2"
                                >{{ $t('tasks:assignPersons') }}</v-stepper-step
                            >
                            <v-divider v-if="action != 'create'"></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getTaskErrors.stepThree.validation,
                                ]"
                                v-if="action != 'create'"
                                editable
                                step="3"
                                >{{ $t('tasks:comments') }}</v-stepper-step
                            >
                            <v-divider v-if="action != 'create'"></v-divider>
                            <v-stepper-step
                                v-if="action != 'create'"
                                editable
                                step="4"
                                >{{ $t('tasks:files') }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <Separator
                                            :text="$t('tasks:addNewTasks')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <v-menu
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                            v-model="datePicker"
                                            ><template
                                                v-slot:activator="{
                                                    on,
                                                    attrs,
                                                }"
                                            >
                                                <v-text-field
                                                    data-cy="taskDate"
                                                    v-model="
                                                        getTaskModal.taskDate
                                                    "
                                                    clearable
                                                    @click:clear="
                                                        getTaskModal.taskDate = null
                                                    "
                                                    :label="`${$t(
                                                        'tasks:taskDate'
                                                    )}`"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    :error-messages="
                                                        getTaskErrors.stepOne
                                                            .fields.taskDate
                                                    "
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                data-cy="datePicker"
                                                first-day-of-week="1"
                                                :min="
                                                    new Date()
                                                        .toISOString()
                                                        .substr(0, 10)
                                                "
                                                v-model="getTaskModal.taskDate"
                                                @input="data1 = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-menu
                                            ref="timer"
                                            v-model="timePicker"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    data-cy="taskTime"
                                                    clearable
                                                    v-model="
                                                        getTaskModal.taskTime
                                                    "
                                                    :label="`${$t(
                                                        'tasks:taskTimie'
                                                    )}`"
                                                    :disabled="
                                                        !getTaskModal.taskDate
                                                    "
                                                    prepend-icon="mdi-clock-outline"
                                                    readonly
                                                    :error-messages="
                                                        getTaskErrors.stepOne
                                                            .fields.taskTime
                                                    "
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-time-picker
                                                v-if="timePicker"
                                                v-model="getTaskModal.taskTime"
                                                full-width
                                                format="24hr"
                                                @click:minute="
                                                    $refs.timer.save(
                                                        getTaskModal.taskTime
                                                    )
                                                "
                                            ></v-time-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <v-textarea
                                            data-cy="taskName"
                                            rows="1"
                                            auto-grow
                                            no-resize
                                            :label="`${$t('tasks:taskName')}`"
                                            v-model="getTaskModal.taskName"
                                            :error-messages="
                                                getTaskErrors.stepOne.fields
                                                    .taskName
                                            "
                                        >
                                        </v-textarea>
                                    </v-col>

                                    <v-col cols="8">
                                        <v-textarea
                                            data-cy="taskDescription"
                                            :label="`${$t(
                                                'tasks:taskDescription'
                                            )}`"
                                            rows="1"
                                            no-resize
                                            :maxlength="maxlength"
                                            :error-messages="
                                                this.getTaskModal
                                                    .taskDescription.length >=
                                                maxlength
                                                    ? `${$t('tasks:maxLength')}`
                                                    : ''
                                            "
                                            auto-grow
                                            v-model="
                                                getTaskModal.taskDescription
                                            "
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <v-select
                                            data-cy="status"
                                            :label="`${$t('tasks:status')}`"
                                            :items="statuses"
                                            item-text="name"
                                            item-value="value"
                                            v-model="getTaskModal.status"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-select
                                            data-cy="category"
                                            :label="`${$t('tasks:category')}`"
                                            :items="
                                                getRegistryDictionaries.tasks
                                                    ? getRegistryDictionaries
                                                          .tasks.category
                                                    : []
                                            "
                                            return-object
                                            v-model="getTaskModal.category"
                                            item-value="field"
                                        >
                                            <template v-slot:item="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                            <template v-slot:selection="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-select
                                            data-cy="priority"
                                            :label="`${$t('tasks:priority')}`"
                                            :items="
                                                getRegistryDictionaries.tasks
                                                    ? getRegistryDictionaries
                                                          .tasks.priority
                                                    : []
                                            "
                                            return-object
                                            v-model="getTaskModal.priority"
                                            item-value="field"
                                            ><template v-slot:item="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                            <template v-slot:selection="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col
                                        style="position: relative;"
                                        cols="6"
                                        v-if="
                                            $router.history.current.name ===
                                                'tasks' &&
                                            getTaskModal.taskSource
                                        "
                                    >
                                        <div
                                            style="
                                                cursor: pointer;
                                                position: absolute;
                                                width: 100%;
                                                height: 80%;
                                                z-index: 1;
                                            "
                                            @click="goToSource"
                                        ></div>

                                        <v-text-field
                                            style="z-index: 0;"
                                            v-model="getTaskModal.taskSource"
                                            :label="$t('tasks:taskSource')"
                                        ></v-text-field
                                    ></v-col>
                                </v-row>
                                <v-row
                                    class="mt-5"
                                >
                                    <v-col cols="12" md="12" class="mt-5 pt-2"
                                        ><div class="border--green">
                                            <p class="ma-0 ml-5 primary--text">
                                                {{ $t('tasks:addAttachments') }}
                                            </p>
                                        </div></v-col
                                    >
                                </v-row>
                                <v-row
                                    ><v-col cols="6">
                                        <form enctype="multipart/form-data">
                                            <v-file-input
                                                color="#445D75"
                                                counter
                                                multiple
                                                small-chips
                                                :placeholder="`${$t(
                                                    'tasks:attachmentsComputer'
                                                )}`"
                                                prepend-icon="mdi-paperclip"
                                                :show-size="1000"
                                                v-model="
                                                    getTaskModal.attachmentsComputer
                                                "
                                                :error-messages="
                                                    getTaskErrors.stepThree
                                                        .attachmentsComputer
                                                "
                                                accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, , application/pdf, image/*"
                                            >
                                                <template
                                                    v-slot:selection="{
                                                        index,
                                                        text,
                                                    }"
                                                >
                                                    <v-chip
                                                        v-if="index < 1"
                                                        color="#445D75"
                                                        dark
                                                        label
                                                        small
                                                        >{{ text }}</v-chip
                                                    >

                                                    <span
                                                        v-else-if="index === 1"
                                                        class="overline grey--text text--darken-3 mx-2"
                                                    >
                                                        +{{
                                                            getTaskModal
                                                                .attachmentsComputer
                                                                .length - 1
                                                        }}
                                                    </span>
                                                </template>
                                            </v-file-input>
                                        </form></v-col
                                    >

                                    <v-col cols="6">
                                        <v-select
                                            v-model="
                                                getTaskModal.attachmentsRegistry
                                            "
                                            :error-messages="
                                                getTaskErrors.stepThree
                                                    .attachmentsRegistry
                                            "
                                            :items="setAttachments"
                                            item-text="name"
                                            return-object
                                            multiple
                                            item-value="_id"
                                            :label="`${$t(
                                                'tasks:attachmentsRegistry'
                                            )}`"
                                            v-if="!isPersonal"
                                        ></v-select>
                                        <!-- <v-file-input
                                            color="#445D75"
                                            small-chips
                                            counter
                                            multiple
                                            :placeholder="`${$t(
                                                'tasks:attachmentsRegistry'
                                            )}`"
                                            prepend-icon="mdi-paperclip"
                                            :show-size="1000"
                                            v-model="
                                                getTaskModal.attachmentsRegistry
                                            "
                                            :error-messages="
                                                getTaskErrors.stepThree
                                                    .attachmentsRegistry
                                            "
                                            accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, , application/pdf, image/*"
                                        >
                                            <template
                                                v-slot:selection="{
                                                    index,
                                                    text,
                                                }"
                                            >
                                                <v-chip
                                                    v-if="index < 1"
                                                    color="#445D75"
                                                    dark
                                                    label
                                                    small
                                                    >{{ text }}</v-chip
                                                >

                                                <span
                                                    v-else-if="index === 1"
                                                    class="overline grey--text text--darken-3 mx-2"
                                                >
                                                    +{{
                                                        getTaskModal
                                                            .attachmentsRegistry
                                                            .length - 1
                                                    }}
                                                </span>
                                            </template>
                                        </v-file-input> -->
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content step="2">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <Separator
                                            :text="$t('tasks:assignTo')"
                                        />
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="3" md="2" class="mt-5">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    small
                                                    elevation="0"
                                                    v-on="on"
                                                    @click="toMe()"
                                                    color="white"
                                                    class="textGrey--text"
                                                >
                                                    <v-icon
                                                        color="textGrey"
                                                        size="35"
                                                        >mdi-account</v-icon
                                                    >{{
                                                        $t('tasks:toMe')
                                                    }}</v-btn
                                                >
                                            </template>
                                            <span>{{ $t('tasks:toMe') }}</span>
                                        </v-tooltip>
                                    </v-col>
                                    <v-col cols="3" md="2" class="mt-5">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    small
                                                    v-on="on"
                                                    elevation="0"
                                                    @click="toAllUsers()"
                                                    color="white"
                                                    class="textGrey--text"
                                                >
                                                    <v-icon
                                                        color="textGrey"
                                                        class="mr-2"
                                                        size="35"
                                                        >mdi-account-group</v-icon
                                                    >{{
                                                        $t('tasks:allUsers')
                                                    }}</v-btn
                                                >
                                            </template>
                                            <span>{{
                                                $t('tasks:allUsers')
                                            }}</span>
                                        </v-tooltip></v-col
                                    >
                                    <v-col cols="12" offset="1" md="7">
                                        <v-autocomplete
                                            data-cy="assignTo"
                                            :label="$t('tasks:to')"
                                            item-value="_id"
                                            :items="to[value]"
                                            return-object
                                            v-model="getTaskModal.to[value]"
                                            multiple
                                            :error-messages="
                                                getTaskErrors.stepTwo.fields.to
                                            "
                                            class="box-autocomplete"
                                            :filter="filter"
                                            @focus="getData"
                                        >
                                            <!-- <template slot="append-outer">
                                                <v-tooltip top>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                        }"
                                                    >
                                                        <v-icon v-on="on"
                                                            >mdi-account-group</v-icon
                                                        >
                                                    </template>
                                                    <span>{{
                                                        $t(
                                                            'tasks:chooseEveryone'
                                                        )
                                                    }}</span>
                                                </v-tooltip>
                                            </template> -->
                                            <template v-slot:selection="data">
                                                <div
                                                    v-if="value === 'users'"
                                                    class="mr-1"
                                                >
                                                    {{
                                                        ` ${data.item.name} ${data.item.lastname},`
                                                    }}
                                                </div>
                                                <div v-else>
                                                    {{ data.item.name }}
                                                </div>
                                            </template>
                                            <template v-slot:item="data">
                                                <v-list-item-avatar
                                                    v-if="value === 'users'"
                                                >
                                                    <v-avatar
                                                        size="35"
                                                        color="secondary"
                                                        class="mx-2"
                                                    >
                                                        <span
                                                            v-if="
                                                                !data.item
                                                                    .picture
                                                            "
                                                            class="white--text central__avatarFont"
                                                        >
                                                            {{
                                                                avatarNick(
                                                                    data.item
                                                                        .name,
                                                                    data.item
                                                                        .lastname
                                                                )
                                                            }}
                                                        </span>
                                                        <img
                                                            :src="`${data.item.picture}`"
                                                            v-else
                                                        />
                                                    </v-avatar>
                                                </v-list-item-avatar>
                                                <v-list-item-avatar v-else>
                                                    <v-avatar
                                                        size="35"
                                                        color="#445D75"
                                                    >
                                                        <span
                                                            class="white--text central__avatarFont"
                                                        >
                                                            {{
                                                                data.item.shortName.toUpperCase()
                                                            }}
                                                        </span>
                                                    </v-avatar>
                                                </v-list-item-avatar>
                                                <!-- === === CONTENT === === -->
                                                <!-- === USERS ===  -->
                                                <v-list-item-content
                                                    v-if="value === 'users'"
                                                >
                                                    <v-list-item-title>
                                                        {{ data.item.lastname }}
                                                        {{ data.item.name }}
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        {{ data.item.job }}
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                                <!-- === DEPARTMENTS === -->
                                                <v-list-item-content v-else>
                                                    <v-list-item-title>
                                                        {{ data.item.name }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                            <v-tabs
                                                slot="prepend-item"
                                                centered
                                                class="elevation-1 select--fields"
                                            >
                                                <v-tab
                                                    @click="value = 'users'"
                                                    >{{
                                                        $t('tasks:users')
                                                    }}</v-tab
                                                >
                                                <v-tab
                                                    @click="
                                                        value = 'departments'
                                                    "
                                                    >{{
                                                        $t('tasks:departments')
                                                    }}</v-tab
                                                >
                                            </v-tabs>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-5">
                                    <v-col cols="12" md="12"
                                        ><div class="border--green">
                                            <p class="ma-0 ml-5 primary--text">
                                                {{ $t('tasks:tasksTo') }}
                                            </p>
                                        </div></v-col
                                    >
                                    <v-col class="py-1" cols="12" md="6">
                                        <div class="list-box">
                                            <span class="list-box__label">{{
                                                $t('tasks:users')
                                            }}</span>
                                            <div class="list-box__content">
                                                <div
                                                    v-for="(item,
                                                    index) in getTaskModal.to
                                                        .users"
                                                    :key="index"
                                                    class="d-flex align-center mx-2 list-box__users"
                                                >
                                                    <v-avatar
                                                        size="40"
                                                        color="secondary"
                                                    >
                                                        <img
                                                            v-if="item.picture"
                                                            :src="item.picture"
                                                            alt="John"
                                                        />
                                                        <span
                                                            class="white--text body-2"
                                                            v-else
                                                            >{{ item.name[0]
                                                            }}{{
                                                                item.lastname[0]
                                                            }}</span
                                                        >
                                                    </v-avatar>
                                                    <div>
                                                        <span
                                                            class="mx-2 body-2"
                                                            >{{
                                                                item.name
                                                            }}</span
                                                        >
                                                        <span class="body-2">{{
                                                            item.lastname
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col class="py-1" cols="12" md="6">
                                        <div class="list-box">
                                            <span class="list-box__label">{{
                                                $t('tasks:departments')
                                            }}</span>
                                            <div class="list-box__content">
                                                <div
                                                    v-for="(item,
                                                    index) in getTaskModal.to
                                                        .departments"
                                                    :key="index"
                                                    class="d-flex align-center mx-2 list-box__users"
                                                >
                                                    <v-avatar
                                                        size="40"
                                                        color="secondary"
                                                    >
                                                        <span
                                                            class="white--text body-2"
                                                            >{{
                                                                item.shortName
                                                            }}</span
                                                        >
                                                    </v-avatar>
                                                    <div>
                                                        <span
                                                            class="mx-2 body-2"
                                                            >{{
                                                                item.name
                                                            }}</span
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                        <v-stepper-items>
                            <v-stepper-content step="3">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <Separator
                                            :text="$t('tasks:addNewComment')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row
                                    ><v-col cols="12" class="pt-0">
                                        <v-textarea
                                            v-model="getCommentFields.text"
                                            no-resize
                                            rows="1"
                                            counter
                                            maxlength="250"
                                            :label="`${$t('global:comment')}`"
                                        ></v-textarea></v-col
                                ></v-row>
                                <v-row>
                                    <v-col cols="4" class="pt-0"
                                        ><v-btn
                                            class="buttons buttons--comment-add"
                                            x-small
                                            @click="createComment"
                                            >{{ $t('global:add') }}</v-btn
                                        ><v-btn
                                            class="mx-2 buttons--comment-cancel"
                                            @click="getTaskModal.comment = ''"
                                            x-small
                                            >{{ $t('global:cancel') }}</v-btn
                                        ></v-col
                                    >
                                </v-row>
                                <v-row>
                                    <v-col cols="10" offset="1">
                                        <v-list three-line class="comments">
                                            <template
                                                v-for="item in getCommentItems"
                                            >
                                                <v-list-item
                                                    class="comments__content"
                                                    :key="item._id"
                                                >
                                                    <v-list-item-avatar>
                                                        <v-avatar
                                                            size="40"
                                                            color="secondary"
                                                        >
                                                            <img
                                                                v-if="
                                                                    item.from
                                                                        .picture
                                                                "
                                                                :src="`${path}${item.from.picture}`"
                                                                alt="John"
                                                            />
                                                            <span
                                                                class="white--text body-2"
                                                                v-else
                                                                >{{
                                                                    item.from
                                                                        .name[0]
                                                                }}{{
                                                                    item.from
                                                                        .lastname[0]
                                                                }}</span
                                                            >
                                                        </v-avatar>
                                                    </v-list-item-avatar>

                                                    <v-list-item-content>
                                                        <v-list-item-title
                                                            >{{
                                                                item.from.name
                                                            }}
                                                            {{
                                                                item.from
                                                                    .lastname
                                                            }}
                                                            <span
                                                                class="comments__date"
                                                                >{{
                                                                    setCorrectDate(
                                                                        item.createdAt
                                                                    )
                                                                }}</span
                                                            >
                                                        </v-list-item-title>

                                                        <v-list-item-subtitle>{{
                                                            item.text
                                                        }}</v-list-item-subtitle>
                                                        <v-list-item-icon
                                                            class="comments__icons"
                                                        >
                                                            <!-- <v-checkbox>
                                                            </v-checkbox> -->
                                                            <v-btn
                                                                icon
                                                                class="ml-3"
                                                            >
                                                                <v-icon
                                                                    size="15"
                                                                    color="errors"
                                                                    @click="
                                                                        removeComment(
                                                                            {
                                                                                id:
                                                                                    item._id,
                                                                            }
                                                                        )
                                                                    "
                                                                    >mdi-delete</v-icon
                                                                ></v-btn
                                                            >
                                                        </v-list-item-icon>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                        </v-list>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                        <v-stepper-items>
                            <v-stepper-content step="4" class="tasks__stepFour">
                                <v-list>
                                    <v-list-item
                                        v-for="item in getTaskModal.files"
                                        :key="item._id"
                                        :href="setUrl(item.fakeName)"
                                        target="_blank"
                                    >
                                        <v-icon size="20" class="mr-2"
                                            >mdi-file</v-icon
                                        >
                                        <v-list-item-content
                                            class="tasks__attachments"
                                        >
                                            <v-list-item-title
                                                class="tasks__attachments__title"
                                            >
                                                {{
                                                    item.name
                                                }}</v-list-item-title
                                            >
                                            <v-list-item-subtitle
                                                class="tasks__attachments__date"
                                                >{{
                                                    $moment(
                                                        item.createdAt
                                                    ).format(`DD-MM-YYYY HH:mm`)
                                                }}</v-list-item-subtitle
                                            >
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    i18nOptions: { namespaces: ['global', 'tasks'] },
    props: ['action', 'isPersonal'],
    data() {
        return {
            isImportant: false,
            date: '',
            value: 'users',
            step: 1,
            datePicker: false,
            timePicker: '',
            data1: '',
            editable: true,
            maxlength: 250,
            statuses: [
                { name: 'Otwarty', value: 'OPEN' },
                { name: 'W trakcie', value: 'IN PROGRESS' },
                { name: 'Zakończono', value: 'DONE' },
            ],
            to: {
                users: [],
                departments: [],
            },
            path: `${process.env.VUE_APP_SERVER}/secured/profile/`,
        }
    },

    computed: {
        ...mapGetters([
            'getTaskErrors',
            'getTaskModal',
            'getProfileDetails',
            'getUsersList',
            'getDepartmentsList',
            'getRegistryDictionaries',
            'getCommentFields',
            'getCommentItems',
            'getFoldersItems',
        ]),

        setAttachments() {
            let arr = []
            this.getFoldersItems.forEach(item => {
                item.files.forEach(file => {
                    if (!file.isDeleted && !file.url) arr.push(file)
                })
            })
            return arr
        },
    },
    methods: {
        ...mapActions(['createComment', 'fetchComments', 'removeComment']),
        ...mapMutations(['setCommentString']),
        avatarNick(name, lastname) {
            let nick = name[0] + lastname[0]
            return nick.toUpperCase()
        },
        goToSource() {
            this.$router.push(`${this.getTaskModal.type}/${this.getTaskModal.itemId}`)
        },
        toAllUsers() {
            this.getTaskModal.to.users = this.to.users
        },
        toMe() {
            this.getTaskModal.to.users = [
                {
                    _id: this.getProfileDetails._id,
                    name: this.getProfileDetails.name,
                    lastname: this.getProfileDetails.lastname,
                },
            ]
        },
        filter(item, queryText, itemText) {
            if (item.lastname)
                return (
                    item.name.toLowerCase().includes(queryText.toLowerCase()) ||
                    item.lastname
                        .toLowerCase()
                        .includes(queryText.toLowerCase())
                )
            else if (item.fullName) {
                return item.fullName
                    .toLowerCase()
                    .includes(queryText.toLowerCase())
            } else
                return item.name.toLowerCase().includes(queryText.toLowerCase())
        },
        getData() {
            this.to.users = this.getUsersList
            this.to.departments = this.getDepartmentsList
        },
        setCorrectDate(date) {
            return date ? new Date(date).toISOString().slice(0, 10) : ''
        },
        setUrl(fakeName) {
            return `${process.env.VUE_APP_SERVER}/secured/tasks/${fakeName}`
        },
    },
    mounted() {
        this.getData()
    },
}
</script>
