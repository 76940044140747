<template>
    <!-- === === TOP TOOLBAR === === -->
    <div>
        <div style="resize: both; overflow: auto;">
            <div id="task__draggable-box">
                <task-driver ref="taskDriver"></task-driver>
            </div>
        </div>
        <v-app-bar app clipped-right color="white" class="header-bar">
            <v-spacer></v-spacer>
            <!-- <v-btn text @click="refresh()" :style="`color:${color}`">{{
                counter
            }}</v-btn> -->
            <v-btn
                v-if="showQr()"
                text
                icon
                color="grey lighten-1"
                @click="openQrCode = true"
            >
                <v-icon>mdi-qrcode-scan</v-icon>
            </v-btn>

            <v-btn
                text
                icon
                color="grey lighten-1"
                router
                @click="getNav.stepper = 1"
                to="/dashboard"
                data-cy="home"
            >
                <v-icon>mdi-home</v-icon>
            </v-btn>
            <v-btn
                v-if="checkIsServiceRoute() && !isTablet()"
                text
                icon
                color="grey lighten-1"
                @click="clickTaskDriverModal"
            >
                <v-icon>mdi-application-array-outline</v-icon>
            </v-btn>

            <v-btn
                text
                icon
                color="grey lighten-1"
                @click="logout"
                data-cy="logout"
            >
                <v-icon>mdi-power</v-icon>
            </v-btn>
            <Modal
                :open="openQrCode"
                :title="$t('global:QrScannerModal')"
                :height="'540'"
                :width="'480'"
                @close="closeModal"
            >
                <QRCodeScanner slot="AEContent" @close="closeModal" />
            </Modal>
            <ConfirmModal
                :openConfirm="openSessionModal"
                @close-confirm="closeConfirmModal"
            >
                <div slot="main">
                    <div
                        class="pa-5 confirmModal d-flex flex-column align-center"
                    >
                        <v-progress-circular
                            class="my-3"
                            rotate="270"
                            size="140"
                            :value="(getProfileSession / 15) * 5"
                            color="secondary"
                            width="8"
                        >
                            <span class="session--time">{{ counter }}</span>
                        </v-progress-circular>

                        <v-card-title class="d-flex justify-center pa-2">{{
                            $t('global:session')
                        }}</v-card-title>

                        <v-card-text class="d-flex justify-center pa-2">{{
                            $t('global:sessionInformation')
                        }}</v-card-text>
                    </div>
                </div>
                <div slot="buttons">
                    <div class="d-flex justify-center">
                        <v-btn
                            color="rgb(117, 117, 117)"
                            width="110"
                            height="40"
                            @click="closeConfirmModal()"
                            class="white--text mx-2"
                            >{{ $t('global:close') }}</v-btn
                        >
                        <v-btn
                            width="140"
                            height="40"
                            color="#445d75"
                            class="white--text mx-2"
                            @click="refresh()"
                            >{{ $t('global:extension') }}</v-btn
                        >
                    </div>
                </div>
            </ConfirmModal>
            <ConfirmModal :openConfirm="openTaskDriverModal">
                <div slot="main">
                    <div class="pa-5 confirmModal">
                        <div class="d-flex justify-center py-5">
                            <img
                                width="100"
                                height="100"
                                src="@/assets/img/global/warning.svg"
                                alt="warning"
                            />
                        </div>

                        <v-card-text class="d-flex justify-center pa-2">{{
                            $t('service:displayOpenOrders')
                        }}</v-card-text>
                    </div>
                </div>

                <div slot="buttons">
                    <div class="d-flex justify-center">
                        <v-btn
                            color="rgb(117, 117, 117)"
                            width="110"
                            height="40"
                            @click="openTaskDriver('')"
                            class="white--text mx-2"
                            >{{ $t('global:no') }}</v-btn
                        >
                        <v-btn
                            width="110"
                            height="40"
                            color="#445d75"
                            class="white--text mx-2"
                            @click="openTaskDriver($t('service:opened'))"
                            >{{ $t('global:yes') }}</v-btn
                        >
                    </div>
                </div>
            </ConfirmModal>
        </v-app-bar>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import QRCodeScanner from './QRCode/QRCodeScanner.vue'
import TaskDriver from './TaskDriver/TaskDriver.vue'
import ConfirmContent from './../../../components/Personal/Storage/Modals/ConfirmModal/ConfirmContent'

import Rest from '../../../plugins/Rest'
export default {
    data: () => {
        return {
            counter: '00:00:00',
            color: 'black',
            openSessionModal: false,
            openQrCode: false,
            openTaskDriverModal: false,
        }
    },
    components: {
        QRCodeScanner,
        TaskDriver,
        ConfirmContent,
    },
    computed: {
        ...mapGetters([
            'getNav',
            'getProfileSession',
            'getTimer',
            'getLogoutModal',
            'getProfileRegistries',
            'getSessionId'
        ]),
        // counter() {// },
    },
    methods: {
        ...mapMutations([
            'clearProfile',
            'setUserSession',
            'setTimer',
        ]),
        closeModal() {
            this.openQrCode = false
        },
        closeConfirmModal() {
            this.openSessionModal = false
        },
        async logout() {
            this.getNav.stepper = 1
            try {
                await new this.Rest('POST', 'logout').send()
                this.$router.push('login')
            } catch (error) {
                if (error.response.status === 401) this.$router.push('login')
            }

            this.clearProfile()
        },
        showQr() {
            //dostęp urzytkownika do modółów oraz typ urządzenia
            if (
                (this.getProfileRegistries
                    .map(e => e.url)
                    .includes(this.$i18n.t('service')) ||
                    this.getProfileRegistries
                        .map(e => e.url)
                        .includes(this.$i18n.t('devices'))) &&
                (navigator.userAgent.includes('Android') ||
                    navigator.userAgent.includes('iPhone') ||
                    navigator.userAgent.includes('iPad') ||
                    navigator.userAgent.includes('Mac'))
            ) {
                return true
            }
            return false
        },
        timer() {
            let interval = 1000
            let expected = Date.now() + interval
            let id

            function step() {
              const sessTime = window.localStorage.getItem(this.getSessionId);
                if (sessTime == 300) {
                    this.color = 'red'
                    this.openSessionModal = true
                }
                let dt = Date.now() - expected
                expected += interval
                window.localStorage.setItem(this.getSessionId,sessTime - 1)
                if (sessTime <= 0) {
                    this.getLogoutModal.open = true
                    return this.logout()
                }
                id = setTimeout(
                    () => step.apply(this),
                    Math.max(0, interval - dt)
                )
                this.setTimer(id)
                this.counter = new Date(sessTime * 1000)
                .toISOString()
                .slice(11, -5)
            }
            id = setTimeout(() => step.apply(this), interval)
            this.setTimer(id)
        },
        async refresh() {
            this.color = 'black'
            this.openSessionModal = false
            let response = await new Rest('GET', 'session').send()
            this.setUserSession({ time: response.data.session.time,  id: response.data.session.id })
        },
        async closeDetect() {
            window.onbeforeunload = e => {
                e.preventDefault()
                return false
            }
            window.onunload = async () => {
                await this.logout()
            }
        },
        clickTaskDriverModal() {
            this.openTaskDriverModal = true
        },
        openTaskDriver(mode) {
            this.$refs.taskDriver.status = mode
            this.openTaskDriverModal = false

            this.$refs.taskDriver.serviceContainerByTechnician()

            const draggableItem = document.getElementById('task__draggable-box')

            if (
                draggableItem.style.visibility == 'hidden' ||
                draggableItem.style.visibility == ''
            ) {
                draggableItem.style.visibility = 'visible'
                this.dragElement(draggableItem)
            }
        },

        dragElement(elmnt) {
            var pos1 = 0,
                pos2 = 0,
                pos3 = 0,
                pos4 = 0

            if (document.getElementById('task__draggable-box')) {
                /* if present, the header is where you move the DIV from:*/
                document.getElementById(
                    'task__draggable-box'
                ).onmousedown = dragMouseDown
            } else {
                /* otherwise, move the DIV from anywhere inside the DIV:*/
                elmnt.onmousedown = dragMouseDown
            }

            function dragMouseDown(e) {
                e = e || window.event
                e.preventDefault()
                // get the mouse cursor position at startup:
                pos3 = e.clientX
                pos4 = e.clientY
                document.onmouseup = closeDragElement
                // call a function whenever the cursor moves:
                document.onmousemove = elementDrag
            }

            function elementDrag(e) {
                e = e || window.event
                e.preventDefault()
                // calculate the new cursor position:
                pos1 = pos3 - e.pageX
                pos2 = pos4 - e.pageY
                pos3 = e.clientX
                pos4 = e.clientY
                // set the element's new position:
                elmnt.style.top = elmnt.offsetTop - pos2 + 'px'
                elmnt.style.left = elmnt.offsetLeft - pos1 + 'px'
            }

            function closeDragElement() {
                /* stop moving when mouse button is released:*/
                document.onmouseup = null
                document.onmousemove = null
            }
        },
        checkIsServiceRoute() {
            return this.$route.path === '/service'
        },
    },

    mounted() {
        if (this.getTimer) clearTimeout(this.getTimer)
        this.timer()
        this.checkIsServiceRoute()
    },
    
    beforeMount() {
        this.closeDetect()
    },
}
</script>
<style lang="sass">
.session--time
    font-size: 30px
#task__draggable-box
    position: absolute
    z-index: 10
    text-align: center
    visibility: hidden
</style>
