<template>
    <div class="separator">
        <p>
            {{ text }}
            <span v-if="subtext">{{ subtext }}</span>
            <span class="ma-0" v-if="clientID">{{ clientID }}</span>
        </p>
        <span><slot name="other"></slot></span>
    </div>
</template>

<script>
export default {
    // props: ['text', 'subtext', 'clientID'],
    props: {
        text: {
            type: String,
            required: true,
        },
        subtext: {
            type: String,
        },
        clientID: {
            type: String,
        },
    },
}
</script>
