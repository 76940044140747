import Rest from './../../../plugins/Rest'
import validation from './../../../plugins/validation'
import { clone } from 'lodash'
import store from '../../../store/index'
import clearSearch from './../../../plugins/clearSearch'
const state = {
    modal: {
        fields: {
            title: '',
            contractor: '',
            representative: '',
            description: '',
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {
                    title: '',
                    contractor: '',
                },
            },
        },
        step: 1,
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: '-createdAt',
        loading: false,
    },
    request: {
        populate: [
            {
                localField: 'contractor',
                foreignField: '_id',
                from: 'contacts',
                as: 'contractor',
            },
            {
                localField: 'createdBy',
                foreignField: '_id',
                from: 'users',
                as: 'createdBy',
            },
        ],
        searchFields: ['title', 'contractor.name '],
        defaultSearchFields: ['title', 'contractor.name'],
        select:
            '_id title representative contractor createdAt status description index folders isDeleted',
        where: {
            isDeleted: false,
        },
    },
    dictFields: {},
    statuses: [],
    details: {},
    registry: {},
}

const getters = {
    getEnforcementsModal: state => state.modal.fields,
    getEnforcementsRegistry: state => state.registry,
    getEnforcementsTable: state => state.table,
    getEnforcementsSearchFields: state => state.request,
    getEnforcementsErrors: state => state.modal.errors,
    getEnforcementsStep: state => state.modal,
    getEnforcementsStatuses: state => state.statuses,
    getEnforcementsDetails: state => state.details,
}

const actions = {
    async fetchEnforcementsTable({ commit, dispatch }) {
        state.table.loading = true
        try {
            let table = new Rest('GET', 'enforcements')
                .select(state.request.select)
                .limit(state.table.limit)
                .page(state.table.page)
                .where(state.request.where)
                .populate(state.request.populate)
                .search(state.request.searchFields, state.table.search)
                .sort(state.table.sort || state.table.defaultSort)
                .send()

            let response = await table
            dispatch('fetchEnforcementsRegistry')
            commit('setEnforcementsTable', response.data)
        } catch (error) {
            console.error(error)
        }
        state.table.loading = false
    },
    async createEnforcement({ commit, dispatch }, payload) {
        try {
            await new Rest('POST', 'enforcements')
                .setBody(state.modal.fields)
                .send()
            commit('clearEnforcementsModal')
            commit('clearEnforcementsErrors')
            dispatch('fetchEnforcementsTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            //HANDLE ERRORS
            if (err) {
                commit('setSnackbars', { type: 'error' })
                validation.setErrors(state.modal.errors, err)
            }
        }
    },

    async updateEnforcement({ commit, dispatch }, payload) {
        try {
            await new Rest('PUT', `enforcements/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            dispatch('fetchEnforcement', { id: payload.id })
            commit('clearEnforcementsModal')
            commit('clearEnforcementsErrors')
            return true
        } catch (err) {
            //HANDLE ERRORS
            validation.setErrors(state.modal.errors, err)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async fetchEnforcement({ state, commit, dispatch }, payload) {
        try {
            let enforcement = await new Rest(
                'GET',
                `enforcements/${payload.id}`
            )
                .populate(['contractor', 'createdBy'])
                .select(
                    'title representative createdAt status contractor description index'
                )
                .send()

            let statusesReq = new Rest(
                'GET',
                `registries/enforcements/statuses`
            )
                .select('name _id isDeleted color state')
                .where({ isDeleted: false })
                .send()

            let { 0: response, 1: statuses } = await Promise.all([
                enforcement,
                statusesReq,
            ])
            dispatch('fetchEnforcementsRegistry')
            commit('setEnforcementsDetails', response.data)
            commit('setEnforcementsStatuses', statuses.data.items)

            if (payload.next) payload.next()
        } catch (err) {
            console.error(err)
        }
    },

    async fetchEnforcementsRegistry({ commit, dispatch }, payload) {
        let registry = await new Rest('GET', 'registries')
            .select('currentMenager name')
            .where({ url: 'enforcements' })
            .populate([
                {
                    path: 'currentMenager',
                    select: 'name lastname mail status phone picture',
                },
            ])
            .send()

        commit('setEnforcementsRegistry', registry.data.items)
    },

    async updateEnforcementsStatus({ commit, dispatch }, payload) {
        commit('setEnforcementsModal')
        commit('setEnforcementsStatus', payload.status)
        try {
            await new Rest('PUT', `enforcements/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            commit('setSnackbars', { type: 'success' })
        } catch (err) {
            console.error(err)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async deleteEnforcements({ commit, dispatch }, payload) {
        try {
            await new Rest('DELETE', `enforcements/${payload.id}`).send()
            dispatch('fetchEnforcementsTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            console.error(err)
        }
    },
}

const mutations = {
    setEnforcementsTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
    setEnforcementsDetails(state, payload) {
        state.details = payload
    },
    setEnforcementsStatuses(state, payload) {
        state.statuses = payload
    },
    setEnforcementsModal(state, payload) {
        let obj = payload ? payload : clone(state.details)
        state.modal.fields = obj
        store.commit('setRepresentativesInput', {
            items: obj?.contractor?.representatives,
        })
        state.modal.fields.representative = obj.representative
    },
    setEnforcementsStatus(state, payload) {
        state.modal.fields.status = payload
    },
    setEnforcementsRegistry(state, payload) {
        state.registry = payload[0]
    },
    clearEnforcementsModal() {
        state.modal.step = 1
        state.modal.fields = {
            title: '',
            status: '',
            contractor: '',
            representative: '',
            description: '',
        }
    },
    clearEnforcementsErrors(state) {
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step].fields) {
                state.modal.errors[step].fields[key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
    clearEnforcementsTable(state) {
        clearSearch.clearSearchTable(state)
    },
    setEnforcementsSearch(state, payload) {
        state.request.searchFields = payload
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
