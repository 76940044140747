<template>
    <v-container class="p-relative py-0">
        <v-row>
            <v-col cols="12" class="py-0">
                <TableDetails
                    :headers="headers"
                    :length="getAlertsTable.pages"
                    :page="getAlertsTable.page"
                    @sort="setSort"
                    @search="search"
                    @change-page="setPage"
                >
                    <Columns
                        slot="columns"
                        :activeColumn="activeColumn"
                        @open-modal="openModal('edit')"
                        @edit-alert="editAlert"
                        :items="getAlertsTable.items"
                    />
                </TableDetails>
                <Modal
                    :title="$t('global:addition')"
                    :open="open"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        :action="action"
                        :alertId="alertId"
                        slot="buttons"
                        @closeModal="closeModal"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--details">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                dark
                                v-on="on"
                                small
                                @click="openModal('create')"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('alerts:createAlerts') }}</span>
                    </v-tooltip>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Columns from './Columns'
import AEContent from './Modal/Content'
import Buttons from './Modal/Buttons'
import { mapMutations, mapActions, mapGetters } from 'vuex'

export default {
    i18nOptions: { namespaces: ['global'] },
    data() {
        return {
            open: false,
            length: 15,
            alertId: '',
            action: 'create',
            activeColumn: false,
            headers: [
                {
                    text: '',
                    value: 'new',
                    align: 'right',
                    width: '1%',
                    sortable: false,
                },
                {
                    text: this.$t('global:from'),
                    value: 'from',
                    width: '18%',
                },
                {
                    text: this.$t('global:to'),
                    value: 'to',
                    width: '18%',
                },
                {
                    text: this.$t('global:content'),
                    value: 'content',
                    width: '39%',
                },
                {
                    text: this.$t('global:term'),
                    value: 'term',
                    width: '8%',
                },
                {
                    text: this.$t('global:priority'),
                    value: 'priority',
                    width: '8%',
                },
                {
                    text: '',
                    value: 'recurringAlert',
                    align: 'right',
                    width: '4%',
                    sortable: false,
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: '4%',
                    sortable: false,
                },
            ],
        }
    },
    methods: {
        ...mapMutations([
            'clearAlertsModal',
            'clearAlertsErrors',
            'setAlertsTable',
        ]),
        closeModal() {
            this.clearAlertsErrors()
            this.open = false
        },
        ...mapActions([
            'fetchAlertsTable',
            'fetchUsers',
            'fetchAllDepartments',
            'fetchAlert',
        ]),
        search(value) {
            this.setAlertsTable({ search: value })
            this.fetchAlertsTable({ id: this.$route.params.id })
        },
        setSort(sort) {
            this.setAlertsTable({ sort })
            this.fetchAlertsTable({ id: this.$route.params.id })
        },
        setPage(page) {
            this.setAlertsTable({ page })
            this.fetchAlertsTable({ id: this.$route.params.id })
        },

        async openModal(type) {
            await void this.fetchAllDepartments({ select: 'users' })
            await void this.fetchUsers({
                select: 'name lastname mail status',
                where: {
                    isUser: true,
                    'status.state': 'ACTIVE',
                    isDeleted: false,
                },
            })
            this.open = true
            if (type == 'edit') this.action = 'edit'
            else this.action = 'create'
            this.clearAlertsModal()
        },
        async editAlert(id) {
            this.alertId = id
            this.fetchAlert({ id: id })
        },
    },
    components: {
        Columns,
        AEContent,
        Buttons,
    },
    computed: {
        ...mapGetters(['getAlertsTable']),
    },
    async beforeMount() {
        await this.fetchAlertsTable({ id: this.$route.params.id })
    },
}
</script>
