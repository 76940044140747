<template>
    <div
        v-if="items.length > 0 && this.$route.query.isPrice == 'true'"
        class="page"
    >
        <!-- v-if="this.data" -->
        <div class="subpage">
            <div class="header">
                <div class="logo">
                    <img
                        v-if="getSettingsDetails.logo"
                        :src="`${path}/client_logo.png`"
                        alt="entelli logo"
                    />
                </div>
                <div class="header__order-wrapper">
                    <div class="header__order-wrapper-info">
                        <span>ID:</span>
                        <span>
                            {{
                                `${$get(getServiceDetails, 'index', '')}`
                            }}</span
                        >
                    </div>
                </div>
            </div>
            <div class="title">
                <div class="title__block-1"></div>
                <div class="title__block-2">
                    <span>KARTA OBIEGOWA</span>
                </div>
                <div class="title__block-3"></div>
            </div>
            <div class="central-table-wrapper">
                <table
                    v-if="this.$route.query.isPrice == 'true'"
                    class="counter"
                >
                    <tr>
                        <th class="column-to-center">Lp.</th>
                        <th class="column-to-left">Nazwa towaru / usługi</th>
                        <th>Cena netto</th>
                        <th>VAT</th>
                        <th>Wartość VAT</th>
                        <th>Wartość brutto</th>
                    </tr>
                    <tr class="main-counter">
                        <td class="column-to-center"></td>
                        <td class="column-to-left">
                            Części
                        </td>
                        <td>
                            {{
                                getServiceDetails.parts
                                    ? setTotalWasUsedSum(
                                          getServiceDetails.parts.partsTable
                                      ).toFixed(2) + ' PLN'
                                    : (0).toFixed(2) + ' PLN'
                            }}
                            <!-- <money
                                v-bind="money"
                                readonly
                                :value="
                                    getServiceDetails.parts
                                        ? setTotalWasUsedSum(
                                              getServiceDetails.parts.partsTable
                                          ).toFixed(2)
                                        : 0
                                "
                            ></money> -->
                        </td>

                        <td>{{ `${vat}%` }}</td>
                        <td>
                            {{
                                getServiceDetails.parts
                                    ? vatValue(
                                          this.setTotalWasUsedSum(
                                              this.getServiceDetails.parts
                                                  .partsTable
                                          )
                                      ) + ' PLN'
                                    : (0).toFixed(2) + ' PLN'
                            }}
                            <!-- <money
                                v-bind="money"
                                readonly
                                :value="
                                    getServiceDetails.parts
                                        ? vatValue(
                                              this.setTotalWasUsedSum(
                                                  this.getServiceDetails.parts
                                                      .partsTable
                                              )
                                          )
                                        : 0
                                "
                            ></money> -->
                        </td>
                        <td>
                            {{
                                getServiceDetails.parts
                                    ? (
                                          setTotalWasUsedSum(
                                              getServiceDetails.parts.partsTable
                                          ) +
                                          0.23 *
                                              setTotalWasUsedSum(
                                                  getServiceDetails.parts
                                                      .partsTable
                                              )
                                      ).toFixed(2) + ' PLN'
                                    : (0).toFixed(2) + ' PLN'
                            }}
                            <!-- <money
                                v-bind="money"
                                class="table--font"
                                readonly
                                :value="
                                    getServiceDetails.parts
                                        ? (
                                              setTotalWasUsedSum(
                                                  getServiceDetails.parts
                                                      .partsTable
                                              ) +
                                              0.23 *
                                                  setTotalWasUsedSum(
                                                      getServiceDetails.parts
                                                          .partsTable
                                                  )
                                          ).toFixed(2)
                                        : 0
                                "
                            ></money> -->
                        </td>
                    </tr>
                    <tr class="main-counter">
                        <td class="column-to-center"></td>
                        <td class="column-to-left">Usługi serwisu</td>
                        <td>
                            {{ getPrice('sum').toFixed(2) + ' PLN' }}
                            <!-- <money
                                v-bind="money"
                                readonly
                                :value="getPrice('sum')"
                            ></money> -->
                        </td>
                        <td>{{ `${vat}%` }}</td>
                        <td>
                            {{
                                this.getServiceDetails.parts
                                    ? vatValue(getPrice('sum')) + ' PLN'
                                    : (0).toFixed(2) + ' PLN'
                            }}
                            <!-- <money
                                v-bind="money"
                                readonly
                                :value="
                                    this.getServiceDetails.parts
                                        ? vatValue(getPrice('sum'))
                                        : 0
                                "
                            ></money> -->
                        </td>
                        <td>
                            {{
                                (
                                    getPrice('sum') +
                                    0.23 * getPrice('sum')
                                ).toFixed(2) + ' PLN'
                            }}
                            <!-- <money
                                v-bind="money"
                                readonly
                                :value="
                                    getPrice('sum') + 0.23 * getPrice('sum')
                                "
                            ></money> -->
                        </td>
                    </tr>
                    <tr class="main-counter">
                        <td class="column-to-center"></td>
                        <td class="column-to-left">Transport</td>
                        <td>
                            {{ carNetto().toFixed(2) + ' PLN' }}
                            <!-- <money
                                v-bind="money"
                                readonly
                                :value="carNetto()"
                            ></money> -->
                        </td>
                        <td>{{ `${vat}%` }}</td>
                        <td>
                            {{
                                getServiceDetails.realization.counters
                                    ? vatValue(carNetto()) + ' PLN'
                                    : 0 + ' PLN'
                            }}
                            <!-- <money
                                v-bind="money"
                                class="table--font"
                                readonly
                                :value="
                                    getServiceDetails.realization.counters
                                        ? vatValue(carNetto())
                                        : 0
                                "
                            ></money> -->
                        </td>
                        <td>
                            {{
                                (carNetto() + 0.23 * carNetto()).toFixed(2) +
                                ' PLN'
                            }}
                            <!-- <money
                                v-bind="money"
                                class="table--font"
                                readonly
                                :value="carNetto() + 0.23 * carNetto()"
                            ></money> -->
                        </td>
                    </tr>
                </table>
                <div
                    style="
                        font-size: 15px;
                        margin-bottom: 15px;
                        margin-top: 15px;
                    "
                >
                    <p>Zestawienie części:</p>
                </div>
                <table class="counter">
                    <tr>
                        <th class="column-to-center">Lp.</th>
                        <th class="column-to-left">Nazwa towaru</th>
                        <th>Ilość</th>
                        <th>J.m</th>
                        <th>VAT</th>
                        <th>Cena netto</th>
                        <th>Wartość brutto</th>
                    </tr>
                    <tr
                        class="main-counter"
                        v-for="(item, index) in items"
                        :key="index"
                    >
                        <td class="column-to-center"></td>
                        <td class="column-to-left">
                            {{ `${item.manufacturerIndex} - ${item.name}` }}
                        </td>
                        <td>{{ item.quantity }}</td>
                        <td>szt.</td>
                        <td>{{ `${vat}%` }}</td>
                        <td>
                            {{ (item.price * item.quantity).toFixed(2) }} PLN
                            <!-- <money
                                v-bind="money"
                                readonly
                                :value="item.price * item.quantity"
                            ></money> -->
                        </td>
                        <td>
                            {{ (item.price * item.quantity * 1.23).toFixed(2) }}
                            PLN
                            <!-- <money
                                v-bind="money"
                                readonly
                                :value="item.price * item.quantity * 1.23"
                            ></money> -->
                        </td>
                    </tr>
                </table>
            </div>
            <div class="footer">
                <span>{{ getSettingsDetails.website }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import store from '../../../../store'

export default {
    data() {
        return {
            path: `${process.env.VUE_APP_SERVER}/secured/logo/`,
            vat: 23,
            items: [],
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: ' PLN',
                precision: 2,
                masked: false,
            },
        }
    },

    computed: {
        ...mapGetters(['getServiceDetails', 'getSettingsDetails']),
    },
    methods: {
        setParts() {
            this.items = this.getServiceDetails.parts.partsTable
        },
        vatValue(netto) {
            const brutto = netto + 0.23 * netto
            const sum = brutto - netto
            return sum.toFixed(2)
        },
        carNetto() {
            const cost = 1
            let distance = 0
            if (
                this.getServiceDetails.realization.counters &&
                this.getServiceDetails.realization.counters.carEnd &&
                this.getServiceDetails.realization.counters.carStart
            ) {
                distance =
                    this.getServiceDetails.realization.counters.carEnd -
                    this.getServiceDetails.realization.counters.carStart
            }
            return distance * cost
        },
        getPrice(type) {
            let sum
            let {
                repair,
                survey,
                itService,
                diagnostics,
                installation,
            } = this.getServiceDetails.device.model.servicePrice
            if (
                this.getServiceDetails.device.dedicatedPrice &&
                type !== 'sum'
            ) {
                sum =
                    this.getServiceDetails.device[type].dedicated *
                    this.getServiceDetails.realization[type]
            } else if (type !== 'sum')
                sum =
                    this.getServiceDetails.device.model.servicePrice[type]
                        .price * this.getServiceDetails.realization[type]
            if (type === 'sum') {
                sum =
                    Number(this.getPrice('repair')) +
                    Number(this.getPrice('survey')) +
                    Number(this.getPrice('itService')) +
                    Number(this.getPrice('diagnostics')) +
                    Number(this.getPrice('installation'))
                this.getServiceDetails.realization.standardPrice = sum
            }
            if (!Number.isNaN(sum)) return sum
            else return ''
        },
        setTotalWasUsedSum(items) {
            const discount = this.$get(
                this.getServiceDetails,
                'device.model.servicePrice.partsDiscountInPercent.price',
                ''
            )

            if (!discount) {
                let temp = [0]
                items.forEach(item => {
                    const sum = item.price * item.quantity
                    if (item.wasUsed) temp.push(sum)
                })
                const totalSum = parseInt(temp.reduce((acc, val) => acc + val))
                return totalSum
            } else {
                let temp = [0]
                items.forEach(item => {
                    const sum = item.price * item.quantity
                    temp.push(sum)
                })
                let totalSum = parseInt(temp.reduce((acc, val) => acc + val))
                totalSum = totalSum - totalSum * (discount * 0.01)
                return totalSum
            }
        },
    },

    beforeMount() {
        store.dispatch('fetchSettings', {
            type: 'add',
        }),
            this.setParts()
    },
}
</script>

<style lang="scss" scoped>
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    color: #4a4a4a;
}

* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.page {
    width: 210mm;
    height: 297mm;
    margin: 10mm auto;
    border: 1px #d3d3d3 solid;
    border-radius: 5px;
    position: relative;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.mt-5 {
    margin-top: 15px;
}
tr > td > input {
    font-size: 1em !important;
    text-align: right;
    max-width: 80px;
}
.subpage {
    height: 100%;

    .header {
        padding-top: 40px;
        padding-left: 40px;
        padding-right: 30px;
        display: grid;
        grid-template-columns: 2fr 1fr;

        .logo {
            padding-left: 10px;

            img {
                max-width: 203px;
                max-height: 45px;
                display: block;
            }
        }

        &__invoice-wrapper-info {
            display: flex;
            justify-content: space-between;
            font-size: 0.625em;
        }
    }

    .header {
        &__order-wrapper {
            font-size: 0.75em;
        }
    }

    .title {
        margin-top: 30px;
        display: flex;
        font-size: 1.75em;
        font-weight: 400;

        &__block-1 {
            width: 500px;
            height: 50px;
            background-color: #4c517d;
            display: block;
        }

        &__block-2 {
            display: flex;
            height: 50px;
            padding-left: 34px;
            padding-right: 34px;
            line-height: 25px;
            width: 242px;
            text-align: center;
            font-size: 1em;
        }

        &__block-3 {
            height: 50px;
            float: left;
            flex-grow: 1;
            width: 90px;
            background-color: #4c517d;
            display: block;
        }
    }

    .central-table-wrapper {
        margin-left: 50px;
        margin-top: 50px;
        font-size: 1.2em;

        .column-to-left {
            text-align: start;
        }
        .column-to-center {
            text-align: center;
        }
        .mb-5 {
            margin-bottom: 10px;
        }
        .px-4 {
            padding: 0 10px;
        }
        table {
            border-collapse: collapse;
            border-spacing: 0;
            width: 95%;
            border: 1px solid rgb(128, 128, 128);
            font-size: 0.625em;
        }

        table {
            counter-reset: rowNumber;
        }

        table .main-counter {
            counter-increment: rowNumber;
        }

        .counter tr td:first-child::before {
            content: counter(rowNumber);
        }

        th {
            background-color: #4c517d;
            color: #f5f5f5;
        }

        th,
        td {
            text-align: end;
            height: 38px;
            max-height: 80px;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-right: 15px;
        }

        tr:nth-child(2n-1) {
            background-color: #f5f5f5;
        }
    }

    .footer {
        margin-left: -1px;
        width: 210mm;
        height: 25px;
        margin-bottom: 20px;
        color: white;
        text-align: center;
        position: absolute;
        bottom: 0;
        background-color: #4c517d;
        span {
            display: block;
            margin-top: 2px;
        }
    }
}

@page {
    size: A4;
    margin: 0;
}

@media print {
    html,
    body {
        width: 210mm;
        height: 297mm;
        padding: 0px !important;
    }

    .page {
        margin: 2px;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
        -webkit-print-color-adjust: exact;
    }

    .subpage {
        height: 256mm;
    }
}
</style>
