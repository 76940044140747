<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper
                    v-model="getRentDeviceErrors.step"
                    class="elevation-0"
                >
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () =>
                                        getRentDeviceErrors.stepOne.validation,
                                ]"
                                editable
                                step="1"
                                >{{ $t('global:basicInfo') }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () =>
                                        getRentDeviceErrors.stepTwo.validation,
                                ]"
                                editable
                                step="2"
                                >{{
                                    $t('global:additionalInfo')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content class="pb-0 pt-4" step="1">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            :text="$t('global:basicInfo')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <v-combobox
                                            v-if="getRentDeviceModal.device"
                                            v-model="getRentDeviceModal.device"
                                            :label="`${$t(
                                                'rent-contracts:rent-annex.device.sn'
                                            )}`"
                                            hint="Zacznij pisać..."
                                            :items="devices"
                                            item-text="sn"
                                            return-object
                                            @keyup="findDevices($event)"
                                            @change="onChange"
                                            :error-messages="
                                                getRentDeviceErrors.stepOne
                                                    .fields.device
                                            "
                                            @click="onSelect"
                                            :readonly="
                                                getRentDeviceModal.device &&
                                                edit
                                            "
                                            :append-icon="''"
                                        >
                                        </v-combobox>
                                        <v-combobox
                                            v-else
                                            v-model="getRentDeviceModal.device"
                                            :label="`${$t(
                                                'rent-contracts:rent-annex.device.sn'
                                            )}`"
                                            hint="Zacznij pisać..."
                                            :items="devices"
                                            item-text="sn"
                                            return-object
                                            @keyup="findDevices($event)"
                                            @change="onChange"
                                            :error-messages="
                                                getRentDeviceErrors.stepOne
                                                    .fields.device
                                            "
                                            @click="onSelect"
                                            :readonly="
                                                getRentDeviceModal.device &&
                                                edit
                                            "
                                        >
                                        </v-combobox>
                                    </v-col>

                                    <v-col offset="4">
                                        <money-field
                                            id="money"
                                            class="margin--input"
                                            :disabled="false"
                                            :readonly="checkIsReadOnly()"
                                            v-model="getRentDeviceModal.rent"
                                            :label="`${$t(
                                                'rent-contracts:rent-annex.device.rent'
                                            )}`"
                                        ></money-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <v-text-field
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-annex.device.model.manufacturer'
                                                )
                                            "
                                            :value="
                                                $get(
                                                    getRentDeviceModal,
                                                    'device.model.manufacturer'
                                                )
                                            "
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-annex.device.model.model'
                                                )
                                            "
                                            :value="
                                                $get(
                                                    getRentDeviceModal,
                                                    'device.model.model'
                                                )
                                            "
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <money-field
                                            class="margin--input"
                                            v-model="
                                                getRentDeviceModal.monitoringCost
                                            "
                                            :precision="2"
                                            :label="`${$t(
                                                'rent-contracts:rent-annex.device.monitoringCost'
                                            )}`"
                                        ></money-field>
                                    </v-col>
                                </v-row>
                                <v-row
                                    class="background--total mb-2 background--grey"
                                >
                                    <v-col cols="12">
                                        <span class="text--settlement">{{
                                            $t(
                                                'rent-contracts:rent-annex.device.backgroundMono'
                                            )
                                        }}</span>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-checkbox
                                            class="mx-2"
                                            @click="clearFields('isMono')"
                                            :readonly="checkIsReadOnly()"
                                            v-model="getRentDeviceModal.isMono"
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-annex.device.calculated'
                                                )
                                            "
                                        >
                                        </v-checkbox>
                                    </v-col>
                                    <v-col cols="2">
                                        <money-field
                                            class="margin--input"
                                            id="money"
                                            :disabled="
                                                !getRentDeviceModal.isMono
                                            "
                                            :readonly="checkIsReadOnly()"
                                            v-model="
                                                getRentDeviceModal.monoPrice
                                            "
                                            :precision="3"
                                            :label="`${$t(
                                                'rent-contracts:rent-annex.device.monoPrice'
                                            )}`"
                                        ></money-field>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-annex.device.monoBundle'
                                                )
                                            "
                                            :disabled="
                                                !getRentDeviceModal.isMono
                                            "
                                            :readonly="checkIsReadOnly()"
                                            @keypress="integralNumbers"
                                            v-model="
                                                getRentDeviceModal.monoBundle
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="2">
                                        <money-field
                                            class="margin--input"
                                            id="money"
                                            :disabled="
                                                !getRentDeviceModal.isMono
                                            "
                                            :readonly="this.readonly"
                                            v-model="
                                                getRentDeviceModal.monoBundleCost
                                            "
                                            :label="`${$t(
                                                'rent-contracts:rent-annex.device.monoBundleCost'
                                            )}`"
                                            :errorMessage="
                                                getRentDeviceErrors.stepOne
                                                    .fields.monoBundleCost
                                            "
                                        ></money-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-annex.device.initMonoCounter'
                                                )
                                            "
                                            :disabled="
                                                !getRentDeviceModal.isMono
                                            "
                                            :readonly="checkIsReadOnly()"
                                            @keypress="integralNumbers"
                                            v-model="
                                                getRentDeviceModal.initMonoCounter
                                            "
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="background--total mb-2">
                                    <v-col cols="12">
                                        <span class="text--settlement">{{
                                            $t(
                                                'rent-contracts:rent-annex.device.backgroundColor'
                                            )
                                        }}</span>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-checkbox
                                            class="mx-2"
                                            @click="clearFields('isColor')"
                                            v-model="getRentDeviceModal.isColor"
                                            :readonly="checkIsReadOnly()"
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-annex.device.calculated'
                                                )
                                            "
                                        >
                                        </v-checkbox>
                                    </v-col>
                                    <v-col cols="2">
                                        <money-field
                                            class="margin--input"
                                            id="money"
                                            :disabled="
                                                !getRentDeviceModal.isColor
                                            "
                                            :readonly="checkIsReadOnly()"
                                            v-model="
                                                getRentDeviceModal.colorPrice
                                            "
                                            :precision="3"
                                            :label="`${$t(
                                                'rent-contracts:rent-annex.device.colorPrice'
                                            )}`"
                                        ></money-field>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field
                                            class=""
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-annex.device.colorBundle'
                                                )
                                            "
                                            :readonly="checkIsReadOnly()"
                                            :disabled="
                                                !getRentDeviceModal.isColor
                                            "
                                            @keypress="integralNumbers"
                                            v-model="
                                                getRentDeviceModal.colorBundle
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="2">
                                        <money-field
                                            class="margin--input"
                                            id="money"
                                            :disabled="
                                                !getRentDeviceModal.isColor
                                            "
                                            :readonly="checkIsReadOnly()"
                                            v-model="
                                                getRentDeviceModal.colorBundleCost
                                            "
                                            :label="`${$t(
                                                'rent-contracts:rent-annex.device.colorBundleCost'
                                            )}`"
                                            :errorMessage="
                                                getRentDeviceErrors.stepOne
                                                    .fields.colorBundleCost
                                            "
                                        ></money-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-annex.device.initColorCounter'
                                                )
                                            "
                                            :disabled="
                                                !getRentDeviceModal.isColor
                                            "
                                            :readonly="checkIsReadOnly()"
                                            @keypress="integralNumbers"
                                            v-model="
                                                getRentDeviceModal.initColorCounter
                                            "
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row
                                    class="background--total background--grey"
                                >
                                    <v-col cols="12">
                                        <span class="text--settlement">{{
                                            $t(
                                                'rent-contracts:rent-annex.device.scans'
                                            )
                                        }}</span>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-checkbox
                                            class="mx-2"
                                            @click="clearFields('isScaner')"
                                            v-model="
                                                getRentDeviceModal.isScaner
                                            "
                                            :readonly="checkIsReadOnly()"
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-annex.device.calculated'
                                                )
                                            "
                                        >
                                        </v-checkbox>
                                    </v-col>
                                    <v-col cols="2">
                                        <money-field
                                            class="margin--input"
                                            id="moneyScan"
                                            :disabled="
                                                !getRentDeviceModal.isScaner
                                            "
                                            :readonly="checkIsReadOnly()"
                                            v-model="
                                                getRentDeviceModal.scanPrice
                                            "
                                            :precision="3"
                                            :label="`${$t(
                                                'rent-contracts:rent-annex.device.scanPrice'
                                            )}`"
                                        ></money-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-annex.device.initScanCounter'
                                                )
                                            "
                                            :disabled="
                                                !getRentDeviceModal.isScaner
                                            "
                                            @keypress="integralNumbers"
                                            v-model="
                                                getRentDeviceModal.initScanCounter
                                            "
                                            :readonly="checkIsReadOnly()"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="3">
                                        <v-combobox
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-annex.device.subRent'
                                                )
                                            "
                                            :readonly="checkIsReadOnly()"
                                            v-model="getRentDeviceModal.subRent"
                                            :items="[
                                                getRentDeviceModal.subRent,
                                            ]"
                                            item-text="number"
                                            item-value="_id"
                                            return-object
                                            append-icon=""
                                        >
                                            <template v-slot:append-outer>
                                                <v-btn
                                                    v-if="
                                                        getRentDeviceModal.subRent &&
                                                        getRentDeviceModal
                                                            .subRent._id
                                                    "
                                                    icon
                                                    small
                                                    router
                                                    :to="`/contracts/${getRentDeviceModal.subRent._id}`"
                                                >
                                                    <v-icon
                                                        color="secondary"
                                                        size="18"
                                                        >mdi-arrow-right-thick</v-icon
                                                    >
                                                </v-btn>
                                            </template>
                                        </v-combobox>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content class="pb-0 pt-4" step="2">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            :text="$t('global:additionalInfo')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-textarea
                                            :label="
                                                $t(
                                                    'rent-contracts:rent-annex.device.notes'
                                                )
                                            "
                                            v-model="getRentDeviceModal.notes"
                                            no-resize
                                            auto-grow
                                            rows="1"
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { GlobalStatus } from '../../../../../../../../plugins/constsTypes'
export default {
    data() {
        return {
            devices: [],
            readonly: false,
        }
    },
    props: ['edit'],
    computed: {
        ...mapGetters([
            'getRentDeviceErrors',
            'getRentDeviceModal',
            'getDevicesTable',
            'getRegistryErrors',
        ]),
    },
    methods: {
        ...mapMutations(['clearRentDeviceErrors']),
        ...mapActions([]),
        checkIsReadOnly() {
            if (
                this.getRentDeviceModal?.contractId?.status?.state ===
                GlobalStatus.active
            ) {
                return true
            } else {
                return false
            }
        },
        clearFields(type) {
            switch (type) {
                case 'isMono':
                    this.getRentDeviceModal.monoPrice = 0
                    this.getRentDeviceModal.monoBundle = 0
                    this.getRentDeviceModal.monoBundleCost = 0
                    this.getRentDeviceModal.initMonoCounter = 0
                    break
                case 'isColor':
                    this.getRentDeviceModal.colorPrice = 0
                    this.getRentDeviceModal.colorBundle = 0
                    this.getRentDeviceModal.colorBundleCost = 0
                    this.getRentDeviceModal.initColorCounter = 0
                    break
                case 'isScaner':
                    this.getRentDeviceModal.scanPrice = 0
                    this.getRentDeviceModal.initScanCounter = 0
                    break
            }
        },
        async findDevices(e) {
            e.preventDefault()
            const search = e.target.value
            if (search.length > 3) {
                let response = await new this.Rest('GET', 'devices')
                    .select(
                        'status manufacturer model deviceType priority servicePackage serviceBase floor client._id client.representatives client.name client.branches sn deliverer branch'
                    )
                    .populate([
                        {
                            localField: 'model',
                            foreignField: '_id',
                            from: 'models',
                            as: 'model',
                        },
                        {
                            localField: 'model.servicePrice',
                            foreignField: '_id',
                            from: 'service-prices',
                            as: 'model.servicePrice',
                        },
                        {
                            localField: 'client',
                            foreignField: '_id',
                            from: 'contacts',
                            as: 'client',
                        },
                    ])
                    .search(['sn'], search)
                    .send()

                this.devices = response.data.items
            } else this.devices = []
        },
        onChange() {
            this.devices = []
        },
        integralNumbers($event) {
            if ($event.target.value[0] == 0)
                $event.target.value = $event.target.value.slice(1)
            let keyCode = $event.keyCode ? $event.keyCode : $event.which
            if (keyCode < 48 || keyCode > 57) $event.preventDefault()
        },
        onSelect() {
            this.clearRentDeviceErrors()
        },
    },
    watch: {
        'getRentDeviceModal.device': function (val) {
            if (val?.anotherDevice?.contractsNumber?.number) {
                this.getRentDeviceModal.subRent =
                    val.anotherDevice?.contractsNumber
            } else {
                this.getRentDeviceModal.subRent = null
            }
        },
    },
    mounted() {
        if (
            this.$get(
                this.getRentDeviceModal,
                'lastSettlementDevice.currentColorCounter'
            )
        )
            this.getRentDeviceModal.initColorCounter = this.$get(
                this.getRentDeviceModal,
                'lastSettlementDevice.currentColorCounter'
            )
        if (
            this.$get(
                this.getRentDeviceModal,
                'lastSettlementDevice.currentMonoCounter'
            )
        )
            this.getRentDeviceModal.initMonoCounter = this.$get(
                this.getRentDeviceModal,
                'lastSettlementDevice.currentMonoCounter'
            )
        if (
            this.$get(
                this.getRentDeviceModal,
                'lastSettlementDevice.currentScanCounter'
            )
        )
            this.getRentDeviceModal.initScanCounter = this.$get(
                this.getRentDeviceModal,
                'lastSettlementDevice.currentScanCounter'
            )
        if (
            this.getRentDeviceModal.device?.anotherDevice?.contractsNumber
                ?.number
        ) {
            this.getRentDeviceModal.subRent = this.getRentDeviceModal.device?.anotherDevice?.contractsNumber
        } else {
            this.getRentDeviceModal.subRent = null
        }
    },
}
</script>
<style lang="scss">
#money {
    label {
        margin-top: 6px !important;
    }
}

#moneyScan {
    label {
        margin-top: 4px !important;
    }
}
</style>
<style lang="scss" scoped>
.text--settlement {
    color: #445d75;
    font-size: 17px;
}

.background--grey {
    background-color: #f2f2f2;
}
</style>
