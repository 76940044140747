<template>
    <div class="pa-5 confirmModal">
        <v-row>
            <v-col cols="12">
                <div class="d-flex justify-center py-2">
                    <img
                        width="100"
                        height="100"
                        src="@/assets/img/global/warning.svg"
                        alt="warning"
                    />
                </div>

                <div class="d-flex justify-center pa-2">
                    <v-card-title>Komunikat:</v-card-title>
                </div>
                <v-card-text
                    v-if="getPreparedDataFromRentContractsItemsLoading"
                    style="text-align: center;"
                    ><span>Trwa pobieranie bazy reprezentantów </span>
                    <div class="mt-5">
                        <Spinner/>
                    </div>
                </v-card-text>

                <v-card-text
                    v-if="!getPreparedDataFromRentContractsItemsLoading"
                    style="text-align: center;"
                    ><span
                        >Czy chcesz pobrać bazę mailingową reprezentantów?</span
                    >
                </v-card-text>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Spinner from '../../../Global/Table/Spinner.vue'
export default {
    props: {
        type: {
            type: String,
        },
    },
    data() {
        return {
            menu: false,
            text: "",
            date: "",
        };
    },
    methods: {
        clearConfirmModal() {
            this.text = "";
            this.date = "";
        },
    },
    computed: {
        ...mapGetters(["getPreparedDataFromRentContractsItemsLoading"]),
    },
    mounted() {
        this.$root.$on("clearModal", () => {
            this.clearConfirmModal();
        });
    },
    components: { Spinner }
}
</script>
