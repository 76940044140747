<template>
    <v-container class="setPadding">
        <v-row>
            <v-col cols="12">
                <v-text-field
                    data-cy-folders="name"
                    :label="$t('global:folderName')"
                    v-model="getFoldersModalFields.name"
                    :error-messages="getFoldersModalErrors.name"
                ></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-textarea
                    data-cy-folders="description"
                    :label="$t('global:folderDescription')"
                    v-model="getFoldersModalFields.description"
                    no-resize
                    auto-grow
                    rows="1"
                ></v-textarea>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters(['getFoldersModalFields', 'getFoldersModalErrors']),
    },
}
</script>

<style lang="sass" scoped>
.setPadding
    padding: 0 24px
</style>
