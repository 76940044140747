<template>
    <tbody>
        <tr
            v-for="(item, index) in items"
            :key="index"
            :class="`${isStatusClosed(item) ? 'disabled' : ''}`"
        >
            <!-- === === SN === === -->
            <td class="truncate">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <span class="truncate" v-on="on">{{
                            $get(item, 'device.sn', '')
                        }}</span>
                    </template>
                    <span>{{ $get(item, 'device.sn', '') }}</span>
                </v-tooltip>
            </td>
            <!-- === === CIRCLES === === -->
            <!-- JEŚLI DANE URZĄDZENIE JEST PODNAJMOWANE, CZYLI ZAWIERA JAKIEŚ INFORMACJĘ W BAZIE URZĄDZEŃ -> INNE -> UMOWA NAJMU Z DOSTAWCĄ -->
            <td>
                <v-tooltip v-if="checkIsDeviceIsSubRent(item)" bottom>
                    <template v-slot:activator="{ on }">
                        <v-avatar
                            class="cursor-pointer ma-1"
                            v-on="on"
                            color="rgb(0, 159, 255)"
                            size="25"
                        >
                            <span class="white--text pa-1">P</span>
                        </v-avatar>
                    </template>
                    <span>{{ $t('rent-contracts:rent-annex.isSubRent') }}</span>
                </v-tooltip>
            </td>

            <!-- === === DEVICES === === -->
            <td class="truncate">
                <DoubleLine
                    :firtstLine="`${$get(
                        item,
                        'device.model.manufacturer',
                        ''
                    )} ${$get(item, 'device.model.model', '')}`"
                    :secondLine="`${$get(item, 'device.model.deviceType', '')}`"
                />
            </td>
            <!-- === === RENT === === -->
            <td class="truncate">
                {{ +item.rent !== 0 ? `${(+item.rent).toFixed(2)} PLN` : '-' }}
            </td>
            <!-- === === MONO_PRICE === === -->
            <td class="truncate">
                {{
                    +item.monoPrice !== 0
                        ? `${(+item.monoPrice).toFixed(3)} PLN`
                        : '-'
                }}
            </td>
            <!-- === === MONO_BUNDLE === === -->
            <td class="truncate">
                <DoubleLine
                    :firtstLine="$get(item, 'monoBundle') || '-'"
                    :secondLine="
                        +item.monoBundleCost !== 0
                            ? `${(+item.monoBundleCost).toFixed(2)} PLN`
                            : '-'
                    "
                />
            </td>
            <!-- === === MONO_COST === === -->
            <!-- <td class="truncate">
                {{
                    +item.monoBundleCost !== 0
                        ? `${(+item.monoBundleCost).toFixed(2)} PLN`
                        : '-'
                }}
            </td> -->
            <!-- === === COLOR_PRICE === === -->
            <td class="truncate">
                {{
                    +item.colorPrice !== 0
                        ? `${(+item.colorPrice).toFixed(3)} PLN`
                        : '-'
                }}
            </td>
            <!-- === === COLOR_BUNDLE === === -->
            <td class="truncate">
                <DoubleLine
                    :firtstLine="$get(item, 'colorBundle') || '-'"
                    :secondLine="
                        +item.monoBundleCost !== 0
                            ? `${(+item.colorBundleCost).toFixed(2)} PLN`
                            : '-'
                    "
                />
            </td>
            <!-- === === SCAN_PRICE === === -->
            <td class="truncate">
                {{
                    +item.scanPrice !== 0
                        ? `${(+item.scanPrice).toFixed(3)} PLN`
                        : '-'
                }}
            </td>

            <!-- === === MONITORING_COST === === -->
            <td class="truncate">
                {{ `${$get(item, 'monitoringCost', 0)} PLN` }}
            </td>

            <!-- === === RENT_SETTLEMENT_TYPE === === -->
            <td class="truncate">
                <SettlementTypeIcon :item="item" />
            </td>

            <td class="d-flex align-center justify-end">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-if="isActive(item)"
                            small
                            icon
                            :disabled="
                                isSettlement(item) === 'red' ||
                                loading === item._id
                            "
                            @click="openSettlementModal(item)"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <Spinner v-if="loading === item._id" />
                            <v-icon v-else :color="getCollor(item)" small
                                >mdi-cash-multiple</v-icon
                            >
                        </v-btn>
                    </template>
                    <span
                        >Rozlicz urządzenie z API, średniej lub
                        definiowanej</span
                    >
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            :disabled="checkIsDeviceCanBeDeleted(item._id)"
                            small
                            v-if="isActive(item)"
                            icon
                            @click="openConfirmModal(item)"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon color="red" small>mdi-delete</v-icon>
                        </v-btn>
                    </template>
                    <span>Usuń</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            small
                            v-if="isActive(item)"
                            icon
                            @click="onEdit(item)"
                        >
                            <v-icon color="secondary" small>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Edytuj</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            small
                            v-if="item.device && item.device._id"
                            @click="openExternalWindow(item.device._id)"
                            icon
                        >
                            <v-icon color="secondary" small
                                >mdi-arrow-right-thick</v-icon
                            >
                        </v-btn>
                    </template>
                    <span>Więcej o urządzeniu</span>
                </v-tooltip>
            </td>
        </tr>
        <ConfirmModal
            :openConfirm="openConfirm"
            @close-confirm="closeConfirmModal"
        >
            <div slot="main">
                <ConfirmContent />
            </div>
            <div slot="buttons">
                <div class="d-flex justify-center">
                    <v-btn
                        color="rgb(117, 117, 117)"
                        width="110"
                        height="40"
                        @click="closeConfirmModal()"
                        class="white--text mx-2"
                        >{{ $t('global:cancel') }}</v-btn
                    >
                    <v-btn
                        width="110"
                        height="40"
                        color="#445d75"
                        class="white--text mx-2"
                        @click="deleteItem()"
                        >{{ $t('global:remove') }}</v-btn
                    >
                </div>
            </div>
        </ConfirmModal>
    </tbody>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { GlobalPermissions } from '../../../../../../../../plugins/constsTypes'
import ConfirmContent from '../../../../../ConfirmModal/ConfirmContent.vue'
import { isSettlement } from '../../../../../Utils/RentDevicesUtils.js'
import SettlementTypeIcon from './Utils/SettlementTypeIcon.vue'
import Rest from '../../../../../../../../plugins/Rest'
import Spinner from '../../../../../../../Global/Table/Spinner.vue'
export default {
    props: ['items', 'loading', 'item', 'lastAnnex'],
    data: () => ({
        openConfirm: false,
        isSettlementAll: '',
        removeItem: '',
        GlobalPermissions,
        isSettlement,
    }),
    components: {
        ConfirmContent,
        SettlementTypeIcon,
        Spinner,
    },
    computed: {
        ...mapGetters([
            'getProfileDetails',
            'getRentSettlementModal',
            'getUsedDevices',
        ]),
    },
    methods: {
        ...mapActions([
            'fetchDevicesTable',
            'fetchRentDevice',
            'deleteRentDevice',
            'fetchRentAnnexTable',
        ]),
        ...mapMutations([
            'clearRentSettlementModal',
            'setRentDeviceModal',
            'clearRentDeviceModal',
            'clearRentDeviceErrors',
        ]),
        getCollor(item) {
            if (isSettlement(item) === 'yellow') return '#fb8c00'

            return isSettlement(item)
        },

        checkIsDeviceIsSubRent(item) {
            if (item.device?.anotherDevice?.contractsNumber) {
                return true
            } else {
                return false
            }
        },

        async isSettlementIn(item) {
            let {
                data: { items },
            } = await new Rest('GET', 'rent-device/' + item._id)
                .select('rentSettlementObj contract')
                .where({ isDeleted: false })
                .populate([
                    {
                        from: 'rent-annex',
                        localField: 'contractId',
                        foreignField: '_id',
                        as: 'contract',
                    },
                    {
                        from: 'rent-settlements',
                        localField: 'settlementId',
                        foreignField: '_id',
                        as: 'rentSettlementObj',
                    },
                ])
                .unwindExeptions(['settlementId'])
                .send()

            if (!items.length > 0) {
                return false
            }
            if (items.some(el => 'yellow' === isSettlement(el))) {
                return '#fb8c00'
            }
            if (items.some(el => 'red' === isSettlement(el))) {
                return 'red'
            }
            return 'green'
        },
        async onEdit(item) {
            this.clearRentDeviceModal()
            this.clearRentDeviceErrors()
            await this.fetchRentDevice({ id: item._id })
            await this.fetchDevicesTable()
            this.setRentDeviceModal()
            this.$emit('onOpenEdit', item)
        },
        openConfirmModal(item) {
            this.removeItem = item
            this.openConfirm = true
        },
        openSettlementModal(item) {
            this.clearRentSettlementModal()
            this.getRentSettlementModal.device = item.device
            this.getRentSettlementModal.rentDevices = item._id
            this.$emit('settlement-modal', {
                id: item._id,
                contractId: this.$get(item, 'contract[0]._id', null),
            })
        },
        closeConfirmModal() {
            this.openConfirm = false
        },
        branch(branch, branches) {
            if (branches) {
                let res = branches.find(el => el._id === branch)
                if (res) {
                    if (res.address.at(-1) == '/') {
                        return res.address.replace('/', '')
                    }
                    return `${res.postCode} ${res.city} ${res.address.replace(
                        '/undefined',
                        ''
                    )}`
                }
            }
        },
        async deleteItem() {
            let result = await this.deleteRentDevice({
                id: this.removeItem._id,
                contractId: this.removeItem.contractId,
                rentInfoId: this.$route.params.id,
            })
            if (result) {
                this.openConfirm = false

                this.fetchRentAnnexTable({ id: this.$route.params.id })
                this.$forceUpdate()
            }
        },
        remove() {
            return this.checkPermissions('DELETE')
        },
        isActive(item) {
            const tmp = this.$get(item, 'contract[0].status.state')
            if (!tmp) return false
            return tmp != 'CLOSED'
        },
        openExternalWindow(deviceId) {
            if (deviceId) {
                const win = window.open(
                    `#/devices/${deviceId}`,
                    '_blank'
                    // 'height=900, width=1400'
                )
                win.onload = e => {
                    const timeOut = setTimeout(() => {
                        e.currentTarget.onbeforeunload = () => {}
                        e.currentTarget.onunload = () => {}
                        clearTimeout(timeOut)
                    }, 500)
                }
            }
        },
        checkIsDeviceCanBeDeleted(deviceId) {
            const lastAnnexStatus = this.lastAnnex.status.name
            const result =
                this.getUsedDevices.includes(deviceId) ||
                lastAnnexStatus !== 'Draft'
            return result
        },
    },
    async mounted() {
        this.isSettlementAll = await this.isSettlementIn(this.item)
    },
    watch: {
        item: async function (element) {
            this.isSettlementAll = await this.isSettlementIn(element)
        },
    },
}
</script>
<style style="scss" scoped>
.truncate {
    font-size: clamp(0.6rem, 0.715rem, 0.85rem) !important;
}

.doubleLine > :nth-child(1n),
.doubleLine > :nth-child(2n) {
    font-size: clamp(0.6rem, 0.715rem, 0.85rem) !important;
}

@media screen and (max-width: 1180px) {
    .v-data-table td {
        font-size: clamp(0.55rem, 0.65rem, 0.85rem) !important;
    }
}
</style>
