import Rest from './../../../plugins/Rest'
import clone from 'lodash.clone'
import i18n from './../../../plugins/i18next'
import validation from './../../../plugins/validation'
import moment from 'moment'
import clearSearch from './../../../plugins/clearSearch'
const state = {
    modal: {
        fields: {
            manufacturerIndex: '',
            manufacturer: '',
            name: '',
            substitute: false,
            partCondition: '',
            price: '',
            modelSeries: '',
            model: '',
            comment: '',
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {
                    manufacturerIndex: '',
                    model: '',
                },
            },
            stepTwo: {
                validation: true,
                fields: {},
            },
        },
        step: 1,
    },
    request: {
        populate: [
            {
                localField: 'model',
                foreignField: '_id',
                from: 'models',
                as: 'model',
            },
        ],
        searchFields: ['manufacturerIndex'],
        defaultSearchFields: ['manufacturerIndex'],
        select:
            '_id status folder manufacturerIndex manufacturer name substitute model.model folders ',
        where: {
            isDeleted: false,
        },
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: '-createdAt',
        loading: false,
    },
    statuses: [],
    details: {},
    registry: {},
    dictFields: { manufacturer: '', modelSeries: '' },
}

const getters = {
    getPartModal: state => state.modal.fields,
    getPartError: state => state.modal.errors,
    getPartStep: state => state.modal,
    getPartsTable: state => state.table,
    getPartStatuses: state => state.statuses,
    getPartsSearchFields: state => state.request,
    getPartDetails: state => state.details,
    getPartsRegistry: state => state.registry,
}

const actions = {
    async createPart({ commit, dispatch }, payload) {
        try {
            await new Rest('POST', 'parts').setBody(state.modal.fields).send()
            commit('clearPartsModal')
            commit('clearPartsErrors')
            dispatch('fetchPartsTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            //HANDLE ERRORS
            if (err) {
                commit('setSnackbars', { type: 'error' })
                validation.setErrors(state.modal.errors, err)
            }
        }
    },
    async fetchPartsTable({ commit }) {
        state.table.loading = true
        try {
            let table = new Rest('GET', 'parts')
                .select(state.request.select)
                .limit(state.table.limit)
                .page(state.table.page)
                .where(state.request.where)
                .populate(state.request.populate)
                .search(state.request.searchFields, state.table.search)
                .sort(state.table.sort || state.table.defaultSort)
                .send()

            let registryReq = new Rest('GET', 'registries')
                .select('currentMenager name')
                .where({ url: 'parts' })
                .populate([
                    {
                        path: 'currentMenager',
                        select: 'name lastname mail status phone picture',
                    },
                ])
                .send()
            let { 0: response, 1: registry } = await Promise.all([
                table,
                registryReq,
            ])
            commit('setPartsTable', response.data)
            commit('setPartsRegistry', registry.data.items)
        } catch (err) {
            console.error(err)
        }
        state.table.loading = false
    },
    async fetchPart({ state, commit }, payload) {
        try {
            let part = await new Rest('GET', `parts/${payload.id}`)
                .populate([
                    {
                        path: 'createdBy',
                        select: 'name lastname mail status phone picture',
                    },
                    {
                        path: 'model',
                        select: 'model',
                    },
                ])
                .select('-folders')
                .send()

            let statusesReq = new Rest('GET', `registries/parts/statuses`)
                .select('name _id isDeleted color state')
                .where({ isDeleted: false })
                .send()

            let registryReq = new Rest('GET', 'registries')
                .select('currentMenager name')
                .where({ url: 'parts' })
                .populate([
                    {
                        path: 'currentMenager',
                        select: 'name lastname mail status phone picture',
                    },
                ])
                .send()
            let { 0: response, 1: statuses, 2: registry } = await Promise.all([
                part,
                statusesReq,
                registryReq,
            ])

            commit('setPartDetails', response.data)
            commit('setPartStatuses', statuses.data.items)
            commit('setPartsRegistry', registry.data.items)

            if (payload.next) payload.next()
        } catch (err) {
            console.error(err)
        }
    },
    async updatePart({ commit, dispatch }, payload) {
        try {
            let response = await new Rest('PUT', `parts/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            dispatch('fetchPart', { id: payload.id })

            return true
        } catch (err) {
            //HANDLE ERRORS
            validation.setErrors(state.modal.errors, err)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async updatePartStatus({ commit, dispatch }, payload) {
        commit('setPartModal')
        commit('setPartStatus', payload.status)
        try {
            let response = await new Rest('PUT', `parts/${payload.id}`)
                .setBody(state.modal.fields)
                .send()

            commit('clearPartsModal')
            commit('clearPartsErrors')

            commit('setSnackbars', { type: 'success' })
        } catch (err) {
            console.error(err)
            commit('setSnackbars', { type: 'error' })
        }
    },

    async deletePart({ commit, dispatch }, payload) {
        try {
            await new Rest('DELETE', `parts/${payload.id}`).send()
            dispatch('fetchPartsTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            console.error(err)
        }
    },
}

const mutations = {
    setPartsTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
    clearPartsModal(state, payload) {
        state.modal.step = 1
        state.modal.fields = {
            manufacturerIndex: '',
            manufacturer: '',
            name: '',
            substitute: false,
            partCondition: '',
            price: '',
            modelSeries: '',
            model: '',
            comment: '',
        }
    },
    setPartsSearch(state, payload) {
        state.request.searchFields = payload
    },
    setPartDetails(state, payload) {
        state.details = payload
    },
    setPartStatus(state, payload) {
        state.modal.fields.status = payload
    },
    setPartStatuses(state, payload) {
        state.statuses = payload
    },
    setPartModal(state, payload) {
        let obj = payload ? payload : clone(state.details)
        state.modal.fields = obj
    },

    setPartsRegistry(state, payload) {
        state.registry = payload[0]
    },
    clearPartsErrors(state) {
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step].fields) {
                state.modal.errors[step].fields[key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
    clearPartsTable(state) {
        clearSearch.clearSearchTable(state)
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
