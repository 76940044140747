import store from '../store/index'
import { GlobalStatus } from './constsTypes'
export default {
    methods: {
        checkPermissions(payload) {
            const arr = []
            if (
                store.getters.getProfileDetails.isAdmin === true ||
                store.getters.getProfileDetails.isSuperAdmin === true
            )
                return true
            if (store.getters.getRolesRegistries.length > 0)
                store.getters.getRolesRegistries.forEach(element => {
                    // if (
                    //     element ||
                    //     (element.url == store.getters.getUrl &&
                    //         element.operations.includes(payload))
                    // )
                    if (
                        element.url == store.getters.getUrl &&
                        element.operations.includes(payload)
                    )
                        arr.push(element)
                })
            if (arr.length > 0) return true
            else return false
        },

        hasFile(items) {
            let status = false
            if (!items || items.length == 0) return status
            let filtered = items.filter(item => !item.isDeleted)
            for (let i of filtered) {
                for (let j of i.files) {
                    if (!j.isDeleted) status = true
                    if (status) break
                }
                if (status) break
            }
            return status
        },
        isStatusClosed(item) {
            return item.status && item.status.state == GlobalStatus.closed
        },
        isStatusArchive(item) {
            return item.status && item.status == GlobalStatus.archive
        },
        isTablet() {
            if (
                navigator.userAgent.includes('Android') ||
                navigator.userAgent.includes('iPhone') ||
                navigator.userAgent.includes('iPad')
            ) {
                return true
            }
            return false
        },
    },
}
