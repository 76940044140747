import Rest from './../../../plugins/Rest'
import clone from 'lodash.clone'

const state = {
    modal: {
        fields: {
            data: '',
            settings: '',
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {
                    data: '',
                    settings: '',
                },
            },
        },
        step: 1,
    },
    request: {
        populate: [],
        searchFields: [],
        select: '_id ',
        where: {
            isDeleted: false,
        },
    },
    details: {},
}

const getters = {
    getImplementationModal: state => state.modal.fields,
    getImplementationError: state => state.modal.errors,
    getImplementationDetails: state => state.details,
}

const actions = {
    async fetchImplementation({ state, commit }, payload) {
        try {
            let response = await new Rest(
                'GET',
                `/devices/implementation/${payload.id}`
            ).send()
            commit('clearImplementationModal')
            commit('setImplementationDetails', response.data)
            if (payload.next) payload.next()
        } catch (err) {
            console.error(err)
        }
    },
    async implementationUpdate({ state, commit, dispatch }, payload) {
        try {
            let response = await new Rest(
                'PUT',
                `/devices/implementation/${payload.id}`
            )
                .setBody(state.modal.fields)
                .send()
            dispatch('fetchImplementation', { id: payload.id })
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            //HANDLE ERRORS
            validation.setErrors(state.modal.errors, err)
            commit('setSnackbars', { type: 'error' })
        }
    },
}

const mutations = {
    setImplementationDetails(state, payload) {
        if (payload) state.details = payload
    },
    setImplementationModal(state, payload) {
        let obj = payload ? payload : clone(state.details)
        state.modal.fields = obj
    },
    clearImplementationModal(state) {
        state.modal.fields = {
            data: '',
            settings: '',
        }
        state.details = {}
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
