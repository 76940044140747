<template>
    <v-container class="p-relative py-0">
        <v-row>
            <v-col cols="12" class="py-0">
                <TableDetails
                    :headers="headers"
                    :length="getNotesTable.pages"
                    :page="getNotesTable.page"
                    @change-page="setPage"
                    @sort="setSort"
                    @search="search"
                >
                    <Columns
                        slot="columns"
                        @details="editNote"
                        :items="getNotesTable.items"
                    />
                </TableDetails>
                <Modal
                    :title="$t(`${action}`) + ' ' + $t(`notes:notes`)"
                    :open="open"
                    :height="'400'"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" :action="action"  />
                    <Buttons
                        :action="action"
                        slot="buttons"
                        @closeModal="open = false"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--details">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                dark
                                v-on="on"
                                small
                                @click="addNote()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('notes:createNotes') }}</span>
                    </v-tooltip>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Columns from './Details/Columns'
import AEContent from './Modal/Content'
import Buttons from './Modal/Buttons'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    i18nOptions: { namespaces: ['global', 'Notes'] },
    data() {
        return {
            open: false,
            length: 15,
            action: 'create',
            headers: [
                {
                    text: this.$t('global:date'),
                    value: 'date',
                    width: '8%',
                },
                {
                    text: this.$t('global:added'),
                    value: 'added',
                    width: '12%',
                },
                {
                    text: this.$t('global:note'),
                    value: 'note',
                    width: '75%',
                },
                {
                    text: '',
                    value: '',
                    align: 'right',
                    width: '3%',
                    sortable: false,
                },
            ],
        }
    },
    methods: {
        ...mapMutations([
            'clearNotesModal',            
            'setNotesTable',
            'setNoteModal',
            'clearNoteErrors',
        ]),
        ...mapActions(['fetchNotesTable']),
        addNote() {
            this.clearNoteErrors()
            this.clearNotesModal()
            this.open = true
            this.action = 'create'
        },
        editNote(item) {
            this.clearNoteErrors()
            this.setNoteModal(item)
            this.open = true
            this.action = 'edit'
        },
        search(value) {
            this.setNotesTable({ search: value })
            this.fetchNotesTable({ id: this.$route.params.id })
        },
        setPage(page) {
            this.setNotesTable({ page })
            this.fetchNotesTable({ id: this.$route.params.id })
        },
        setSort(sort) {
            this.setNotesTable({ sort })
            this.fetchNotesTable({ id: this.$route.params.id })
        },
        closeModal() {
            this.clearNoteErrors()
            this.open = false
        },
    },
    computed: {
        ...mapGetters(['getNotesTable']),
    },
    components: {
        Columns,
        AEContent,
        Buttons,
    },
    async mounted() {
        await this.fetchNotesTable({ id: this.$route.params.id })
    },
}
</script>
