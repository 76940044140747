import Rest from './../../plugins/Rest'
import clone from 'lodash.clone'
import i18n from './../../plugins/i18next'
const state = {
    modal: {
        fields: {
            attachmentsComputer: [],
            description: '',
            users: [],
            file: {},
        },
        errors: {
            stepOne: {
                validation: true,
                attachmentsComputer: null,
                users: null,
            },
        },
        steps: '',
        removeID: null,
    },
    request: {
        populate: [],
        searchFields: ['name'],
        select:
            'name fakeName size description createdAt _id createdBy._id createdBy.name createdBy.lastname',
        where: { isDeleted: false },
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: '-createdAt',
    },
    progress: {
        sent: 0,
        length: 0,
    },
}

const getters = {
    getStorageModal: state => state.modal.fields,
    getStorageErrors: state => state.modal.errors,
    getStorageTable: state => state.table,
    getStorageSearchFields: state => state.request,
    getProgressStorage: state => state.progress,
}

const actions = {
    async createStorageFile({ commit, dispatch }) {
        try {
            if (state.modal.fields.attachmentsComputer.length > 0) {
                state.progress.length =
                    state.modal.fields.attachmentsComputer.length
                let promises = []
                state.modal.fields.attachmentsComputer.forEach(file => {
                    let formData = new FormData()
                    formData.append(
                        'description',
                        state.modal.fields.description
                    )
                    formData.append('file', file)
                    promises.push(
                        new Rest('POST', '/storage')
                            .setBody(formData)
                            .send()
                            .then(() => {
                                state.progress.sent++
                            })
                    )
                })
                await Promise.all(promises)
                await dispatch('fetchStorage')
                commit('setSnackbars', { type: 'success' })
                return true
            } else {
                commit('setSnackbars', { type: 'error' })
                state.modal.errors.stepOne.validation = false
                state.modal.errors.stepOne.attachmentsComputer = `${i18n.t(
                    'global:errorFields'
                )}!`
            }
        } catch (error) {
            commit('setSnackbars', { type: 'error' })
            console.error(error)
        }
    },

    async fetchStorage({ commit }, payload) {
        let limit = payload && payload.limit ? payload.limit : state.table.limit
        try {
            let response = await new Rest('GET', '/storage')
                .select(state.request.select)
                .limit(limit)
                .page(state.table.page)
                .search(state.request.searchFields, state.table.search)
                .where(state.request.where)
                .populate(state.request.populate)
                .sort(state.table.sort || state.table.defaultSort)
                .send()
            commit('setStorageTable', response.data)
        } catch (error) {
            commit('setSnackbars', { type: 'error' })
            console.error(error)
        }
    },

    async updateStorage({ commit }) {
        try {
            let response = await new Rest(
                'PUT',
                `/storage/${state.modal.fields._id}`
            )
                .setBody({
                    description: state.modal.fields.description,
                    name: state.modal.fields.name,
                })
                .send()
            commit('updateStorageItem', response.data)
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (error) {
            commit('setSnackbars', { type: 'error' })
            console.error(error)
        }
    },

    async removeStorageItem({ commit }, payload) {
        try {
            let response = await new Rest(
                'DELETE',
                `/storage/${state.modal.removeID}`
            ).send()

            commit('unsetStorageItem', response.data)
            commit('setSnackbars', { type: 'success' })
        } catch (error) {
            commit('setSnackbars', { type: 'error' })
            console.error(error)
        }
    },

    async copyToUserStorage({ commit }) {
        try {
            if (state.modal.fields.users.length > 0) {
                let promises = []
                state.modal.fields.users.forEach(item => {
                    promises.push(
                        new Rest(
                            'PUT',
                            `/storage/copyToUserStorage/${item._id}`
                        )
                            .setBody({
                                file: state.modal.fields.file,
                                from: state.modal.fields.from,
                            })
                            .send()
                    )
                })
                await Promise.all(promises)
                commit('setSnackbars', { type: 'success' })
                return true
            } else {
                commit('setSnackbars', { type: 'error' })
                state.modal.errors.stepOne.validation = false
                state.modal.errors.stepOne.users = `${i18n.t(
                    'global:errorFields'
                )}!`
            }
        } catch (error) {
            commit('setSnackbars', { type: 'error' })
            console.error(error)
        }
    },
}

const mutations = {
    setStorageTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },

    setStorageSearch(state, payload) {
        state.request.searchFields = payload
    },

    setStorageItem(state, payload) {
        state.table.items = [payload, ...state.table.items]
    },

    setModalDetail(state, payload) {
        state.modal.fields._id = payload._id
        state.modal.fields.name = payload.name
        state.modal.fields.description = payload.description
    },
    updateStorageItem(state, payload) {
        state.table.items = state.table.items.map(item =>
            item._id == payload._id ? payload : item
        )
    },
    setRemoveIdStorage(state, payload) {
        state.modal.removeID = payload
    },
    unsetStorageItem(state, payload) {
        state.table.items = state.table.items.filter(
            item => item._id != payload._id
        )
    },
    setStorageFileToShare(state, payload) {
        state.modal.fields.file = payload
        state.modal.fields.from = 'attachments/data/storage'
    },
    clearStorageErrors(state) {
        state.modal.errors.stepOne.validation = true
        state.modal.errors.stepOne.attachmentsComputer = null
        state.modal.errors.stepOne.users = null
    },
    clearStorageFields(state) {
        state.modal.fields.users = []
        state.modal.fields.attachmentsComputer = []
        state.modal.fields.name = ''
        state.modal.fields.description = ''
        state.modal.fields.file = {}
        state.progress.length = 0
        state.progress.sent = 0
    },
    setAddFromAttachments(state, payload) {
        state.modal.fields.users.push(payload.id)
        state.modal.fields.file = payload.file
        state.modal.fields.from = payload.from
    },
    clearStorageTable(state) {
        state.table.search = ''
        state.table.page = 1
        state.table.search = ''
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
