import Rest from '../../../plugins/Rest'
import i18n from './../../../plugins/i18next'

const state = {
    modal: {
        fields: {
            name: '',
            description: '',
        },
        errors: {
            name: '',
        },
    },
    items: [],
}

const getters = {
    getFoldersItems: state => state.items,
    getFoldersModalFields: state => state.modal.fields,
    getFoldersModalErrors: state => state.modal.errors,
}

const actions = {
    // === === FOLDERS === === //
    async fetchFolders({ commit }, payload) {
        try {
            let response = await new Rest('GET', '/folders')
                .additional(payload)
                .send()

            commit('setFolders', response.data)
        } catch (error) {
            console.error(error)
        }
    },

    async createFolder({ commit }, payload) {
        try {
            let response = await new Rest('POST', '/folders')
                .additional(payload)
                .setBody(state.modal.fields)
                .send()

            commit('addNewFolder', response.data)
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (error) {
            //HANDLE ERRORS
            commit('setErrorsFolders', error)
            commit('setSnackbars', { type: 'error' })
        }
    },

    async updateFolder({ commit }, payload) {
        try {
            let response = await new Rest(
                'PUT',
                `/folders/${state.modal.fields._id}`
            )
                .additional(payload)
                .setBody(state.modal.fields)
                .send()

            commit('setSnackbars', { type: 'success' })
            commit('updateFolderItem', response.data)
            return true
        } catch (error) {
            //HANDLE ERRORS
            commit('setSnackbars', { type: 'error' })
            commit('setErrorsFolders', error)
            commit('setSnackbars', { type: 'error' })
        }
    },

    async removeFolder({ commit }, payload) {
        try {
            let response = await new Rest('DELETE', `/folders/${payload.id}`)
                .additional(payload)
                .send()

            commit('setSnackbars', { type: 'success' })
            commit('removeFoldersItem', response.data)
            return true
        } catch (error) {
            commit('setSnackbars', { type: 'error' })
        }
    },

    async changeFolderPosition({ commit }, payload) {
        try {
            await new Rest('PUT', '/folders/123/changePosition')
                .setBody({ data: payload.data })
                .additional(payload.additional)
                .send()

            // commit('setFolders', response.data)
            commit('setSnackbars', { type: 'success' })
        } catch (error) {
            commit('setSnackbars', { type: 'error' })
            console.error(error)
        }
    },
}

const mutations = {
    setFolders(state, payload) {
        state.items = payload
    },
    addNewFolder(state, payload) {
        state.items = [payload, ...state.items]
    },
    clearFolderModal(state) {
        state.modal.fields = {
            name: '',
            description: '',
        }
        state.modal.errors = {
            name: '',
        }
    },
    setFoldersModal(state, payload) {
        state.modal.fields._id = payload._id
        state.modal.fields.name = payload.name
        state.modal.fields.description = payload.description
    },
    updateFolderItem(state, payload) {
        state.items = state.items.map(item =>
            item._id == payload._id ? payload : item
        )
    },
    removeFoldersItem(state, payload) {
        state.items = state.items.filter(item => item._id != payload._id)
    },

    setErrorsFolders(state, payload) {
        for (const x in payload.response.data.errors) {
            let number = /\d+/
            state.modal.errors[
                x.replace(`folders.${x.match(number)}.`, '')
            ] = `${i18n.t('global:errorFields')}!`
        }
    },
}

export default { state, getters, mutations, actions }
