import Rest from './../../../plugins/Rest'
import validation from './../../../plugins/validation'
import { clone, get } from 'lodash'
import store from '../../../store/index'
import moment from 'moment'
import Rest2 from '../../../plugins/Rest2'

const state = {
    modal: {
        fields: {
            device: null,
            monitoringCost: 0,
            rent: 0,
            monoPrice: 0,
            monoBundle: '',
            monoBundleCost: 0,
            initMonoCounter: '',
            colorPrice: 0,
            colorBundle: '',
            colorBundleCost: 0,
            initColorCounter: '',
            scanPrice: 0,
            initScanCounter: '',
            subRent: null,
            notes: '',
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {
                    device: '',
                    monoBundleCost: '',
                    colorBundleCost: ''
                },
            },
            stepTwo: {
                validation: true,
                fields: {},
            },
        },
        step: 1,
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: Infinity,
        pages: 1,
        defaultSort: '-createdAt',
        fetched: false,
    },
    request: {
        populate: [
            {
                from: 'devices',
                localField: 'device',
                foreignField: '_id',
                as: 'device',
            },
            {
                from: 'models',
                localField: 'device.model',
                foreignField: '_id',
                as: 'device.model',
            },
            {
                from: 'users',
                localField: 'createdBy',
                foreignField: '_id',
                as: 'createdBy',
            },
            {
                from: 'rent-annex',
                localField: 'contractId',
                foreignField: '_id',
                as: 'contract',
            },
            {
                from: 'rent-settlements',
                localField: 'settlementId',
                foreignField: '_id',
                as: 'rentSettlementObj',
            },
        ],
        unwindExeptions: ['settlementId'],
        searchFields: ['index'],
        select: '',
        where: {
            isDeleted: false,
        },
    },
    dictFields: {},
    statuses: [],
    details: {},
    registry: {},
}

const getters = {
    getRentDeviceModal: state => state.modal.fields,
    getRentDeviceRegistry: state => state.registry,
    getRentDeviceTable: state => state.table,
    getRentDeviceSearchFields: state => state.request,
    getRentDeviceErrors: state => state.modal.errors,
    getRentDeviceStep: state => state.modal,
    getRentDeviceStatuses: state => state.statuses,
    getRentDeviceDetails: state => state.details,
}

const actions = {
    async fetchRentDeviceTable(
        { commit, dispatch, rootState, state },
        payload
    ) {
        commit('setFetchTable', false)
        try {
            let response = await new Rest2(
                'GET',
                'rent-device/' + payload.contractId
            )
                .select(state.request.select)
                .unwindExeptions(state.request.unwindExeptions)
                .where(payload.where || state.request.where)
                .populate(state.request.populate)
                .sort(state.table.sort || state.table.defaultSort)
                .send()
            if (response.status) {
                commit('setFetchTable', true)
            }
            dispatch('fetchRentDeviceRegistry')
            commit('setRentDeviceTable', response.data)
        } catch (error) {
            console.error(error)
        }
    },
    async createRentDevice({ commit, dispatch }, payload) {
        dispatch('updateValidation')
        if (state.modal.errors.stepOne.validation) {
            try {
                const { contractId, id } = payload
                await new Rest2('POST', 'rent-device/' + contractId)
                    .setBody({
                        ...state.modal.fields,
                        device: state.modal.fields?.device?._id,
                        contractId: contractId,
                    })
                    .send()
                commit('clearRentDeviceModal')
                commit('clearRentDeviceErrors')
                dispatch('fetchRentDeviceTable', { contractId: contractId })
                dispatch('fetchRentSettlementInstanceTable', { id: id })
                dispatch('fetchLastRentInfo', { id: id })
                await dispatch('fetchRentAnnexTable', payload)

                commit('setSnackbars', { type: 'success' })
                return true
            } catch (err) {
                //HANDLE ERRORS
                if (err) {
                    commit('setSnackbars', { type: 'error' })
                    validation.setErrors(state.modal.errors, err)
                }
            }
        }
    },

    async updateRentDevice({ commit, dispatch }, payload) {
        dispatch('updateValidation')
        if (state.modal.errors.stepOne.validation) {
            try {
                await new Rest2('PUT', `rent-device/${payload.id}`)
                    .setBody(state.modal.fields)
                    .send()
                dispatch('fetchRentDevice', { id: payload.id })
                dispatch('fetchRentDeviceTable', { contractId: payload.contractId })
                dispatch('fetchLastRentInfo', { id: payload.rentInfoId })
                commit('clearRentDeviceModal')
                commit('clearRentDeviceErrors')
                return true
            } catch (err) {
                //HANDLE ERRORS
                validation.setErrors(state.modal.errors, err)
                commit('setSnackbars', { type: 'error' })
            }
        }

    },
    async fetchRentDevice({ state, commit, dispatch }, payload) {
        try {
            let RentDev = await new Rest2('GET', `rent-device/one/${payload.id}`)
                .populate([
                    { path: 'createdBy' },
                    {
                        path: 'contractId',
                        populate: [
                            { path: 'rentDevices' },
                            { path: 'rentInfo', populate: 'rentAnnexs' },
                        ],
                    },
                    {
                        path: 'device',
                        select: 'anotherDevice sn model manufacturer',
                        populate: [
                            {
                                path: 'model',
                                select: 'model manufacturer',
                            },
                            {
                                path: 'anotherDevice.contractsNumber ',
                                select: 'number _id manufacturer',
                            },
                        ],
                    },
                ])
                .send()


            let statusesReq = new Rest2('GET', `registries/rent-device/statuses`)
                .select('name _id isDeleted color state')
                .where({ isDeleted: false })
                .send()

            let { 0: response, 1: statuses } = await Promise.all([
                RentDev,
                statusesReq,
            ])
            dispatch('fetchRentDeviceRegistry')
            commit('setRentDeviceDetails', response.data)
            commit('setRentDeviceStatuses', statuses.data.items)

            if (payload.next) payload.next()
        } catch (err) {
            console.error(err)
        }
    },

    async fetchRentDeviceRegistry({ commit, dispatch }, payload) {
        let registry = await new Rest2('GET', 'registries')
            .select('currentMenager name')
            .where({ url: 'rent-device' })
            .populate([
                {
                    path: 'currentMenager',
                    select: 'name lastname mail status phone picture',
                },
            ])
            .send()
        commit('setRentDeviceRegistry', registry.data.items)
    },

    async deleteRentDevice({ commit, dispatch }, payload) {
        try {
            await new Rest2('DELETE', `rent-device/${payload.id}`).send()
            dispatch('fetchRentDeviceTable', { contractId: payload.contractId })
            dispatch('fetchRentSettlementInstanceTable', {
                id: payload.rentInfoId,
            })
            dispatch('fetchLastRentInfo', { id: payload.rentInfoId })
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            console.error(err)
        }
    },
    updateValidation({ commit, dispatch }, payload) {
        state.modal.errors.stepOne.validation = true;
        commit('clearRentDeviceErrors')
        if (state.modal.fields.monoBundle > 0 && state.modal.fields.monoBundleCost <= 0) {
            state.modal.errors.stepOne.fields.monoBundleCost = 'Brakująca cena pakietu mono'
            state.modal.errors.stepOne.validation = false;

        }
        if (state.modal.fields.colorBundle > 0 && state.modal.fields.colorBundleCost <= 0) {
            state.modal.errors.stepOne.fields.colorBundleCost = 'Brakująca cena pakietu kolor'
            state.modal.errors.stepOne.validation = false;

        }

    }
}

const mutations = {
    setFetchTable(state, payload) {
        state.table.fetched = payload
    },
    setRentDeviceTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
    setRentDeviceDetails(state, payload) {
        state.details = payload
    },
    setRentDeviceStatuses(state, payload) {
        state.statuses = payload
    },
    setRentDeviceModal(state, payload) {
        let obj = payload ? payload : clone(state.details)
        state.modal.fields = obj
        store.commit('setRepresentativesInput', {
            items: obj?.contractor?.representatives,
        })
        state.modal.fields.representative = obj?.contractor?.representatives?.find(
            e => e._id === obj.representative
        )
        if (obj.startDate)
            state.modal.fields.startDate = moment(obj.startDate).format(
                'YYYY-MM-DD'
            )

        if (obj.expirationDate)
            state.modal.fields.expirationDate = moment(
                obj.expirationDate
            ).format('YYYY-MM-DD')
    },
    setRentDeviceStatus(state, payload) {
        state.modal.fields.status = payload
    },
    setRentDeviceRegistry(state, payload) {
        state.registry = payload[0]
    },
    clearRentDeviceModal() {
        state.modal.step = 1
        state.modal.fields = {
            device: null,
            rent: 0,
            monitoringCost: 0,
            monoPrice: 0,
            monoBundle: '',
            monoBundleCost: 0,
            initMonoCounter: '',
            colorPrice: 0,
            colorBundle: '',
            colorBundleCost: 0,
            initColorCounter: '',
            scanPrice: 0,
            initScanCounter: '',
            subRent: null,
            notes: '',
        }
    },
    clearRentDeviceErrors(state) {
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step].fields) {
                state.modal.errors[step].fields[key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
    clearRentDeviceTable(state) {
        state.table.items = []
        state.table.search = ''
        state.table.page = 1
    },
    setRentDeviceSearch(state, payload) {
        state.request.searchFields = payload
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
