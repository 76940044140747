import Rest from './../../../plugins/Rest'
import clone from 'lodash.clonedeep'
import i18n from './../../../plugins/i18next'
import validation from './../../../plugins/validation'
import clearSearch from './../../../plugins/clearSearch'
const state = {
    modal: {
        fields: {
            name: '',
            description: '',
            repair: {
                price: '',
                value: 'HOUR',
            },
            survey: {
                price: '',
                value: 'HOUR',
            },
            installation: {
                price: '',
                value: 'HOUR',
            },
            itService: {
                price: '',
                value: 'HOUR',
            },
            diagnostics: {
                price: '',
                value: 'HOUR',
            },
            remote: {
                price: '',
                value: 'HOUR',
            },

            rateOneKmTransport: {
                price: 0,
                value: 'KM',
            },
            staticRateTransport: {
                price: 0,
            },
            minRateTransport: {
                price: 25,
                value: false,
            },
            ownManHoursCost: {
                price: 0,
            },
            partsDiscountInPercent: {
                price: 0,
            },
        },

        errors: {
            stepOne: {
                validation: true,
                fields: {
                    name: '',
                    description: '',
                    repair: '',
                    survey: '',
                    installation: '',
                    itService: '',
                    diagnostics: '',
                    remote: '',
                    rateOneKmTransport: '',
                    staticRateTransport: '',
                    minRateTransport: '',
                    ownManHoursCost: '',
                    partsDiscountInPercent: '',
                },
            },
        },
        steps: '',
    },
    request: {
        populate: [],
        searchFields: ['name'],
        defaultSearchFields: ['name'],
        select: '_id name description survey installation repair folders',
        where: {
            isDeleted: false,
        },
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: '-createdAt',
        loading: false,
    },
    registry: {},
    details: {},
    statuses: [],
}

const getters = {
    getServicePricesModal: state => state.modal.fields,
    getServicePricesErrors: state => state.modal.errors,
    getServicePricesDetails: state => state.details,
    getServicePricesStep: state => state.modal,
    getServicePricesStatuses: state => state.statuses,
    getServicePricesTable: state => state.table,
    getServicePricesSearchFields: state => state.request,
    getServicePricesRegistry: state => state.registry,
}

const actions = {
    async fetchServicePricesTable({ commit }) {
        state.table.loading = true
        try {
            let table = new Rest('GET', 'service-prices')
                .select(state.request.select)
                .limit(state.table.limit)
                .page(state.table.page)
                .where(state.request.where)
                .populate(state.request.populate)
                .search(state.request.searchFields, state.table.search)
                .sort(state.table.sort || state.table.defaultSort)
                .send()
            let registryReq = new Rest('GET', 'registries')
                .select('currentMenager name')
                .where({ url: 'service-prices' })
                .populate([
                    {
                        path: 'currentMenager',
                        select: 'name lastname mail status phone picture',
                    },
                ])
                .send()
            let { 0: response, 1: registry } = await Promise.all([
                table,
                registryReq,
            ])
            commit('setServicePricesTable', response.data)
            commit('setServicePricesRegistry', registry.data.items)
        } catch (err) {
            console.error(err)
        }
        state.table.loading = false
    },
    async fetchServicePrices({ commit }, payload) {
        try {
            let car = new Rest('GET', `service-prices/${payload.id}`)
                .populate([
                    {
                        path: 'createdBy',
                        select: 'name lastname mail status picture phone',
                    },
                    {
                        path: 'driver',
                        select: 'name lastname ',
                    },
                ])
                .send()

            let statusesReq = new Rest(
                'GET',
                `registries/service-prices/statuses`
            )
                .select('name _id isDeleted color state')
                .where({ isDeleted: false })
                .send()

            let registryReq = new Rest('GET', 'registries')
                .select('currentMenager name')
                .where({ url: 'service-prices' })
                .populate([
                    {
                        path: 'currentMenager',
                        select: 'name lastname mail status phone picture',
                    },
                ])
                .send()
            let { 0: response, 1: statuses, 2: registry } = await Promise.all([
                car,
                statusesReq,
                registryReq,
            ])
           
            commit('setServicePriceDetails', response.data)
            commit('setServicePriceStatuses', statuses.data.items)
            commit('setServicePricesRegistry', registry.data.items)

            if (payload.next) payload.next()
        } catch (err) {
            console.error(err)
        }
    },
    async createServicePrices({ commit, dispatch }) {
        try {
            await new Rest('POST', 'service-prices')
                .setBody(state.modal.fields)
                .send()
            commit('clearServicePricesModal')
            commit('clearServicePricesErrors')
            dispatch('fetchServicePricesTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            //HANDLE ERRORS
            if (err) {
                commit('setSnackbars', { type: 'error' })
                validation.setErrors(state.modal.errors, err)
            }
        }
    },
    async updateServicePrices({ commit, dispatch }, payload) {
        try {
            let response = await new Rest('PUT', `service-prices/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
           
            dispatch('fetchServicePrices', { id: payload.id })
            commit('setServicePricesModal', response.data)
            commit('clearServicePricesModal')
            commit('clearServicePricesErrors')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            //HANDLE ERRORS
            validation.setErrors(state.modal.errors, err)
            commit('setSnackbars', { type: 'error' })
        }
    },

    async updateServicePricesStatus({ commit }, payload) {
        commit('setServicePricesModal')
        commit('setServicePriceStatus', payload.status)
        try {
            
            let response = await new Rest('PUT', `service-prices/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            commit('setSnackbars', { type: 'success' })
        } catch (err) {
            commit('setSnackbars', { type: 'error' })
        }
    },
    async deleteServicePrices({ commit, dispatch }, payload) {
        try {
            await new Rest('DELETE', `service-prices/${payload.id}`).send()
            dispatch('fetchServicePricesTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            console.error(err)
        }
    },
}

const mutations = {
    setServicePricesTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },

    setServicePricesSearch(state, payload) {
        state.request.searchFields = payload
    },
    setServicePriceDetails(state, payload) {
        state.details = payload
        
    },
    setServicePriceStatus(state, payload) {
        state.modal.fields.status = payload
    },
    setServicePriceStatuses(state, payload) {
        state.statuses = payload
    },
    setServicePricesModal(state, payload) {
    
        let obj = payload ? payload : clone(state.details)
        
        state.modal.fields = obj
  
    },
    setServicePricesRegistry(state, payload) {
        state.registry = payload[0]
    },
    clearServicePricesModal(state, payload) {
        
        state.modal.step = 1
        state.modal.fields = {
            name: '',
            description: '',
            repair: {
                price: '',
                value: 'HOUR',
            },
            survey: {
                price: '',
                value: 'HOUR',
            },
            installation: {
                price: '',
                value: 'HOUR',
            },
            itService: {
                price: '',
                value: 'HOUR',
            },
            diagnostics: {
                price: '',
                value: 'HOUR',
            },
            remote: {
                price: '',
                value: 'HOUR',
            },
            rateOneKmTransport: {
                price: 0,
                value: 'KM',
            },
            staticRateTransport: {
                price: 0,
            },
            minRateTransport: {
                price: 25,
                value: false,
            },
            ownManHoursCost: {
                price: 0,
            },

            partsDiscountInPercent: {
                price: 0,
            },
             
        }

    },
    clearServicePricesErrors(state) {
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step].fields) {
                state.modal.errors[step].fields[key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
    clearServicePricesTable(state) {
        clearSearch.clearSearchTable(state)
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
