import { cloneDeep } from 'lodash'
import moment from 'moment'
import { get } from 'lodash'
import Rest from '../../../../plugins/Rest'
import store from '../../../../store'

export const isSettlement = item => {
    let accountingDay = get(item, 'contract[0].accountingDay', '01')

    const daysInMonth = moment().daysInMonth()
    if (accountingDay > daysInMonth) {
        accountingDay = daysInMonth
    }
    let settlements = cloneDeep(item?.rentSettlementObj)

    settlements.sort(
        (a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()
    )
    let timeLastSettlement = settlements[0]?.createdAt
        ? moment(settlements[0]?.createdAt)
        : null

    if (timeLastSettlement) {
        const now = moment(
            moment().format('DD-MM-YYYY'),
            'DD-MM-YYYY'
        ).valueOf()
        const nextSettlementMonth = timeLastSettlement
            .add(1, 'month')
            .format('MM-YYYY')
        const nextSettlementDate = moment(
            `${accountingDay}-${nextSettlementMonth}`,
            'DD-MM-YYYY'
        ).valueOf()

        if (now < nextSettlementDate) {
            return 'red'
        }

        // if (
        //     moment(now).add(1, 'month').format('DD-MM-YYYY') ===
        //     moment(nextSettlementDate).format('DD-MM-YYYY')
        // ) {
        //     return 'yellow'
        // }
    }
    return 'green'
}

export const selectFileCSV = async (e, item, id, closeRows) => {
    try {

        store.commit('SET_CSV_FILE_LOADING', true)

        const annex = await new Rest('GET', `rent-annex/one/${item._id}`)
            .populate([
                { path: 'contractor' },
                { path: 'createdBy' },
                { path: 'customerCarer' },
                {
                    path: 'rentSettlement',
                    select: 'rentSettlements rentAnnex createdAt',
                    populate: [
                        {
                            path: 'rentAnnex',
                            select: 'rentDevices',
                        },
                        {
                            path: 'rentSettlements',
                            populate: {
                                path: 'rentDevices',
                                populate: 'device',
                            },
                        },
                    ],
                },
                {
                    path: 'rentDevices',
                    populate: [{ path: 'device', select: 'sn' }],
                },
            ])
            .send()

        const rentSettlement = annex.data.rentSettlement.sort(
            (a, b) =>
                moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()
        )

        const lastSettlement = rentSettlement
            .filter(
                elm =>
                    elm.rentSettlements.length <
                    elm.rentAnnex.rentDevices.length
            )
            .flatMap(el => el.rentSettlements)
            .map(e => String(e.rentDevices))

        const rentDevicesFromAnnex = annex.data.rentDevices.filter(
            elm => !lastSettlement.includes(String(elm._id))
        )

        const file = e.target.files[0]


        const worker = new Worker('./Workers/rentDevicesWorker.js')

        const createSettlement = async data => {
            try {

                await new Rest('POST', `rent-settlements`)
                    .setBody({
                        settlement: data,
                        lastSettlement: rentSettlement[0],
                    })
                    .send()
                store.dispatch('fetchRentSettlementInstanceTable', {
                    id,
                })
            } catch (err) {
                console.error(err)
                store.commit('setSnackbars', { type: 'error' })
                return
            }
        }
        const doneCallback = async (data, entries) => {
            if (!data.done) {
                const rentDevice = data.value[1]
                await createSettlement(rentDevice)
                const _data = entries.next()
                await doneCallback(_data, entries)
            }
        }
        worker.postMessage({
            file: file,
            rentDevices: rentDevicesFromAnnex,
        })
        try {
            worker.onmessage = async e => {
                
                if (e.data.message === 'Done') {
                    try {
                        const devicesToSettlement = e.data.payload
                        const entries = devicesToSettlement.entries()
                        let data = entries.next()
                        await doneCallback(data, entries)
                        if (closeRows) closeRows()

                        await store.dispatch('fetchRentSettlementInstanceTable', { id })
                        await store.dispatch('fetchRentAnnexTable', { id })
                        await store.dispatch('fetchRentInfoTable')
                        await store.dispatch('fetchRentDeviceTable', {
                            contractId: item._id,
                        })

                        e.target.files = new DataTransfer().files
                        store.commit('SET_CSV_FILE_LOADING', false)
                        store.commit('setSnackbars', { type: 'success' })
                    } catch (error) {

                        console.log(error);
                    }
                }
                if (e.data.message === 'Error') {
                    let errorMessage = 'Błąd w rozliczaniu z pliku CSV';
                    if(e.data.payload.message === 'Devices in CSV are duplicated') errorMessage = 'Lista CSV zawiera duplikaty';
                    store.commit('SET_CSV_FILE_LOADING', false)
                    store.commit('setSnackbars', { type: 'error', text: errorMessage })
                    throw e.data.payload
                }

            }


        } catch (error) {
            store.commit('SET_CSV_FILE_LOADING', false)
            store.commit('setSnackbars', { type: 'error' })
            throw new Error(error)

        }

    } catch (err) {
        console.error(err)
        store.commit('SET_CSV_FILE_LOADING', false)
        store.commit('setSnackbars', { type: 'error' })
        return
    }

}
