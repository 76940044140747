<template>
    <div
        class="SettlementButton"
        v-if="isSettlementAll && item.status.state != 'CLOSED'"
    >
        <v-btn v-if="errorSettlementAll" small icon>
            <v-icon color="red">mdi-alert-circle-outline </v-icon>
        </v-btn>
        <v-btn v-if="loading" small icon>
            <v-progress-circular
                :size="15"
                :width="2"
                indeterminate
                color="#19aa8d"
                class="v-progress-circular--visible"
            ></v-progress-circular>
        </v-btn>
        <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    small
                    :disabled="isSettlementAll === 'grey'"
                    @click="settlementAll"
                    icon
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon small :color="isSettlementAll"
                        >mdi-cash-multiple
                    </v-icon>
                </v-btn>
            </template>
            <span>{{ $t('rent-info:settlementAll') }}</span>
        </v-tooltip> -->
        <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    small
                    :disabled="isSettlementAll === 'grey'"
                    @click="settlementAPI"
                    icon
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon small :color="isSettlementAll">mdi-api</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('rent-info:settlementApi') }}</span>
        </v-tooltip> -->
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    small
                    :disabled="checkIsShouldBeDisabled || loading"
                    @click="settlementAPI"
                    icon
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon small :color="isSettlementAll"
                        >mdi-cash-multiple</v-icon
                    >
                </v-btn>
            </template>
            <span>{{ $t('rent-info:settlementApi') }}</span>
        </v-tooltip>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Rest from '../../../../../../../plugins/Rest'
import { isSettlement } from '../../../../Utils/RentDevicesUtils'


const MANAGER_ACCESS_BUTTON_COLOR = 'warning';
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        item: { type: Object, required: true },
        closeRows: { type: Function },
    },
    data() {
        return {
            isSettlementAll: '',
            loading: false,
            errorSettlementAll: false,
        }
    },
    computed: {
        ...mapGetters(['getRentInfoRegistry', 'getProfileDetails']),
        checkIsShouldBeDisabled() {
            if (
                this.getRentInfoRegistry.currentMenager.mail ===
                this.getProfileDetails.mail
            ) {
                return false
            } else if (this.isSettlementAll === MANAGER_ACCESS_BUTTON_COLOR) {
                return true
            }
        },
    },
    methods: {
        ...mapMutations(['setSnackbars']),
        ...mapActions([
            'findUnsettledLastMonth',
            'createRentSettlementAll',
            'createRentSettlementFromApi',
        ]),

        async isSettlementIn(item) {
            let {
                data: { items },
            } = await new Rest('GET', 'rent-device/' + item._id)
                .select('rentSettlementObj contract')
                .where({ isDeleted: false })
                .populate([
                    {
                        from: 'rent-annex',
                        localField: 'contractId',
                        foreignField: '_id',
                        as: 'contract',
                    },
                    {
                        from: 'rent-settlements',
                        localField: 'settlementId',
                        foreignField: '_id',
                        as: 'rentSettlementObj',
                    },
                ])
                .unwindExeptions(['settlementId'])
                .send()

            if (!items.length > 0) {
                return false
            }
            if (items.some(el => 'yellow' === isSettlement(el))) {
                return '#fb8c00'
            }
            if (items.every(el => 'red' === isSettlement(el))) {
                return MANAGER_ACCESS_BUTTON_COLOR
            }
            return 'green'
        },
        async settlementAll() {
            this.loading = true
            const success = await this.createRentSettlementAll({
                id: this.item.rentInfo,
                item: this.item,
            })
            if (this.closeRows) {
                this.closeRows()
            }
            // this.findUnsettledLastMonth()
            this.loading = false

            if (!success) {
                this.errorSettlementAll = true
            } else {
                this.errorSettlementAll = false
            }
        },

        goToLastSettlementPage() {
            const rentInfoId = this.item.rentInfo
            this.$router.push({
                path: `/rent-info/${rentInfoId}`,
                query: { tabs: 'rent-settlements' },
            })
        },

        async settlementAPI() {
            this.setSnackbars({
                type: 'warning',
                text: `${this.$t('rent-contracts:rent-annex.APItries')}`,
            })
            this.loading = true
            const success = await this.createRentSettlementFromApi({
                id: this.item.rentInfo,
                item: this.item,
            })
            if (this.closeRows) {
                this.closeRows()
            }
            // this.findUnsettledLastMonth()

            this.loading = false

            if (!success) {
                this.errorSettlementAll = true
            } else {
                this.errorSettlementAll = false
                // TU BYŁO PRZEJŚCIE DO ZESTAWIENIA PO UDANYM ROZLICZENIU.
                // this.goToLastSettlementPage();
            }
        },
    },
    async mounted() {
        this.isSettlementAll = await this.isSettlementIn(this.item)
    },
    async updated() {
        this.isSettlementAll = await this.isSettlementIn(this.item)
    },
    watch: {
        item: async function (element) {
            this.isSettlementAll = await this.isSettlementIn(element)
        },
    },
}
</script>
<style lang="scss" scoped>
.SettlementButton {
    display: flex;
    justify-content: space-between;
}
</style>
