import Rest from './../../../plugins/Rest'
import validation from './../../../plugins/validation'
import { clone } from 'lodash'
import moment from 'moment'
import store from '../../../store/index'
import clearSearch from './../../../plugins/clearSearch'
const state = {
    modal: {
        fields: {
            title: '',
            contractor: '',
            representative: '',
            offerType: '',
            customerType: '',
            notes: '',
            webForm: '',
            deployment: {
                devicesAmount: '',
                devicesLocation: '',
                websiteOpinion: '',
                socialMedia: '',
                emailFormat: '',
                customerShareCapital: '',
                scopeActivities: '',
                industry: '',
                comments: '',
                summary: '',
            },
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {
                    title: '',
                    contractor: '',
                },
            },
        },
        step: 1,
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 11,
        pages: 1,
        defaultSort: '-createdAt',
        loading: false,
    },
    request: {
        populate: [
            {
                localField: 'contractor',
                foreignField: '_id',
                from: 'contacts',
                as: 'contractor',
            },
            {
                localField: 'createdBy',
                foreignField: '_id',
                from: 'users',
                as: 'createdBy',
            },
        ],
        searchFields: ['title'],
        defaultSearchFields: ['title'],
        select:
            '_id title representative createdAt expirationDate status offerType clientType notes index webForm folders isDeleted contractor createdBy',
        where: {
            isDeleted: false,
        },
    },
    dictFields: {
        customerType: '',
        offerType: '',
    },
    statuses: [],
    details: {},
    registry: {},
}

const getters = {
    getOfferModal: state => state.modal.fields,
    getOffersRegistry: state => state.registry,
    getOffersTable: state => state.table,
    getOffersSearchFields: state => state.request,
    getOfferErrors: state => state.modal.errors,
    getOfferStep: state => state.modal,
    getOfferStatuses: state => state.statuses,
    getOfferDetails: state => state.details,
}

const actions = {
    async fetchOffersTable({ commit, dispatch }) {
        state.table.loading = true
        try {
            let response = await new Rest('GET', 'offers')
                .select(state.request.select)
                .limit(state.table.limit)
                .page(state.table.page)
                .where(state.request.where)
                .populate(state.request.populate)
                .search(state.request.searchFields, state.table.search)
                .sort(state.table.sort || state.table.defaultSort)
                .send()

            dispatch('fetchOfferRegistry')
            commit('setOffersTable', response.data)
        } catch (error) {
            console.error(error)
        }
        state.table.loading = false
    },
    async createOffer({ commit, dispatch }, payload) {
        try {
            await new Rest('POST', 'offers').setBody(state.modal.fields).send()
            commit('clearOffersModal')
            commit('clearOfferErrors')
            dispatch('fetchOffersTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            //HANDLE ERRORS
            if (err) {
                commit('setSnackbars', { type: 'error' })
                validation.setErrors(state.modal.errors, err)
            }
        }
    },

    async updateOffer({ commit, dispatch }, payload) {
        try {
            await new Rest('PUT', `offers/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            dispatch('fetchOffer', { id: payload.id })
            commit('clearOffersModal')
            commit('clearOfferErrors')
            return true
        } catch (err) {
            //HANDLE ERRORS
            validation.setErrors(state.modal.errors, err)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async fetchOffer({ state, commit, dispatch }, payload) {
        try {
            let offer = await new Rest('GET', `offers/${payload.id}`)
                .populate(['contractor', 'createdBy'])
                .select(
                    'title representative createdAt expirationDate status notes offerType customerType deployment index'
                )
                .send()

            let statusesReq = new Rest('GET', `registries/offers/statuses`)
                .select('name _id isDeleted color state')
                .where({ isDeleted: false })
                .send()

            let { 0: response, 1: statuses } = await Promise.all([
                offer,
                statusesReq,
            ])
            dispatch('fetchOfferRegistry')
            commit('setOfferDetails', response.data)
            commit('setOfferStatuses', statuses.data.items)

            if (payload.next) payload.next()
        } catch (err) {
            console.error(err)
        }
    },

    async fetchOfferRegistry({ commit, dispatch }, payload) {
        let registry = await new Rest('GET', 'registries')
            .select('currentMenager name')
            .where({ url: 'offers' })
            .populate([
                {
                    path: 'currentMenager',
                    select: 'name lastname mail status phone picture',
                },
            ])
            .send()

        commit('setOfferRegistry', registry.data.items)
    },

    async updateOfferStatus({ commit, dispatch }, payload) {
        commit('setOfferModal')
        commit('setOfferStatus', payload.status)
        try {
            await new Rest('PUT', `offers/${payload.id}`)
                .setBody(state.modal.fields)
                .send()
            commit('setSnackbars', { type: 'success' })
        } catch (err) {
            console.error(err)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async deleteOffers({ commit, dispatch }, payload) {
        try {
            await new Rest('DELETE', `offers/${payload.id}`).send()
            dispatch('fetchOffersTable')
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            console.error(err)
        }
    },
}

const mutations = {
    setOffersTable(state, payload) {
        state.table.items = payload.items || state.table.items
        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
    setOfferDetails(state, payload) {
        state.details = payload
    },
    setOfferStatuses(state, payload) {
        state.statuses = payload
    },
    setOfferModal(state, payload) {
        let obj = payload ? payload : clone(state.details)
        state.modal.fields = obj
        store.commit('setRepresentativesInput', {
            items: obj?.contractor?.representatives,
        })
        state.modal.fields.representative = obj?.contractor?.representatives?.find(
            e => e._id === obj.representative
        )
        state.modal.fields.expirationDate = obj.expirationDate
            ? moment(obj.expirationDate).format('YYYY-MM-DD')
            : ''
    },
    setOfferStatus(state, payload) {
        state.modal.fields.status = payload
    },
    setOfferRegistry(state, payload) {
        state.registry = payload[0]
    },
    clearOffersModal() {
        state.modal.step = 1
        state.modal.fields = {
            title: '',
            status: '',
            offerType: '',
            contractor: '',
            representative: '',
            notes: '',
            expirationDate: '',
        }
    },
    clearOfferErrors(state) {
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step].fields) {
                state.modal.errors[step].fields[key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
    clearOffersTable(state) {
        clearSearch.clearSearchTable(state)
    },
    setOffersSearch(state, payload) {
        state.request.searchFields = payload
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
