<template>
    <v-container class="pt-0 pb-0" @paste="pasteScreenShot">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="step" class="elevation-0 pb-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                editable
                                step="1"
                                :rules="[
                                    () =>
                                        getFilesModalErrors.stepOne.validation,
                                ]"
                                >{{
                                    $t('attachments:addFileOrUrl')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                            <v-divider></v-divider>
                            <v-stepper-step
                                editable
                                step="2"
                                :rules="[
                                    () =>
                                        getFilesModalErrors.stepTwo.validation,
                                ]"
                                >{{
                                    $t('attachments:additionalInformation')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1" class="pb-0">
                                <form enctype="multipart/form-data">
                                    <label for="files">
                                        <v-icon size="45">mdi-file</v-icon>
                                        <div
                                            class="fileLine"
                                            :style="`--progress:${progress}%`"
                                        ></div>
                                        <span>{{
                                            $t('attachments:dragAndDropText')
                                        }}</span>
                                        <input
                                            type="file"
                                            id="files"
                                            name="file"
                                            multiple
                                            @change="setFiles"
                                        />
                                    </label>
                                </form>

                                <div class="files">
                                    <div
                                        class="files__item"
                                        v-for="(file,
                                        index) in getFilesModalFields.files"
                                        :key="index"
                                    >
                                        <v-btn
                                            icon
                                            class="files__remove"
                                            @click="removeModalFileItem(file)"
                                        >
                                            <v-icon color="red"
                                                >mdi-close</v-icon
                                            >
                                        </v-btn>
                                        <v-icon large>mdi-file</v-icon>
                                        <p class="file__name truncate">
                                            {{ file.name }}
                                        </p>
                                        <p class="file__size">
                                            {{ bytesToMegaBytes(file.size) }}
                                        </p>
                                    </div>
                                </div>
                                <div class="counter">
                                    <p>
                                        {{ $t('attachments:filesLength') }}:
                                        {{ getFilesModalFields.files.length }}
                                    </p>
                                    <p>
                                        {{ $t('attachments:filesSize') }}:
                                        {{ totalFilesSize() }}
                                    </p>
                                </div>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            :label="$t('attachments:url')"
                                            v-model="getFilesModalFields.url"
                                            :error-messages="
                                                getFilesModalErrors.stepTwo.url
                                            "
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content step="2">
                                <v-row>
                                    <v-col cols="12" class="pt-0">
                                        <v-select
                                            :label="
                                                $t('attachments:chooseFolder')
                                            "
                                            v-model="getFilesModalFields.folder"
                                            append-outer-icon="mdi-folder-plus"
                                            :items="getFoldersItems"
                                            item-value="_id"
                                            item-text="name"
                                            :error-messages="
                                                getFilesModalErrors.stepTwo
                                                    .folder
                                            "
                                            @click:append-outer="
                                                $emit('open-folder-modal', {
                                                    type: 'create',
                                                })
                                            "
                                        ></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-select
                                            :label="$t('global:status')"
                                            v-model="getFilesModalFields.status"
                                            :items="statuses"
                                            item-text="name"
                                            item-value="value"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-menu
                                            v-model="datepicker"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            nudge-bottom="45"
                                            min-width="290px"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    v-model="
                                                        getFilesModalFields.archiveDate
                                                    "
                                                    :label="
                                                        $t(
                                                            'attachments:archiveDate'
                                                        )
                                                    "
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="
                                                    getFilesModalFields.archiveDate
                                                "
                                                no-title
                                                @input="datepicker = false"
                                                first-day-of-week="1"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea
                                            :label="
                                                $t(
                                                    'attachments:attachmentDescription'
                                                )
                                            "
                                            v-model="
                                                getFilesModalFields.description
                                            "
                                            no-resize
                                            auto-grow
                                            rows="1"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea
                                            :label="$t('attachments:comment')"
                                            v-model="
                                                getFilesModalFields.comment
                                            "
                                            no-resize
                                            auto-grow
                                            rows="1"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { GlobalFilesStatus } from './../../../../../../../plugins/constsTypes'
export default {
    data() {
        return {
            step: 1,
            datepicker: false,
            progress: 0,
            statuses: [
                {
                    name: this.$t('attachments:current'),
                    value: GlobalFilesStatus.current,
                },
                {
                    name: this.$t('attachments:archive'),
                    value: GlobalFilesStatus.archive,
                },
                {
                    name: this.$t('attachments:draft'),
                    value: GlobalFilesStatus.draft,
                },
                {
                    name: this.$t('attachments:confidential'),
                    value: GlobalFilesStatus.confidential,
                },
            ],
        }
    },
    computed: {
        ...mapGetters([
            'getFoldersItems',
            'getFilesModalFields',
            'getFilesModalErrors',
            'getProgressFiles',
        ]),
    },
    methods: {
        ...mapMutations(['removeModalFileItem']),
        ...mapActions(['fetchRegistry']),
        setFiles(e) {
            this.getFilesModalFields.files = [
                ...e.target.files,
                this.getFilesModalFields.files,
            ].flat()

            e.target.value = ''
        },
        bytesToMegaBytes(a, b = 2) {
            if (0 === a) return '0 Bytes'
            const c = 0 > b ? 0 : b,
                d = Math.floor(Math.log(a) / Math.log(1024))
            return (
                parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
                ' ' +
                ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
            )
        },
        totalFilesSize() {
            let total = this.getFilesModalFields.files.reduce(
                (accumulator, item) => accumulator + item.size,
                0
            )
            return this.bytesToMegaBytes(total)
        },
        pasteScreenShot(e) {
            this.getFilesModalFields.files = [
                ...e.clipboardData.files,
                this.getFilesModalFields.files,
            ].flat()
        },
        calcProgress(current, completed) {
            let percent = Math.round((current * 100) / completed) || 0
            this.progress = percent > 100 ? 100 : percent
        },
    },

    watch: {
        'getProgressFiles.sentSize': function (val) {
            this.calcProgress(val, this.getProgressFiles.totalSize)
        },
    },
}
</script>
<style lang="sass" scoped>
label
    width: 100%
    height: 180px
    display: flex
    // justify-content: center
    align-items: center
    flex-direction: column
    position: relative
    background: #f3f3f3
    color: #445D75
    padding: 15px 30px
    span
        font-size: 22px
        font-weight: bold
    .fileLine
        --progress:0%
        position: relative
        background: white
        width: 100%
        height: 5px
        margin-top: 10px
        margin-bottom: 20px
        &::before
            content:''
            position: absolute
            width: var(--progress)
            height: 100%
            background-color: #19aa8d

input[type=file]
    height: 100%
    width: 100%
    position: absolute
    opacity: 0
    cursor: pointer

.files
    margin-top: 10px
    width: 744px
    max-height: 120px
    min-height: 120px
    overflow-y: auto
    display: flex
    flex-wrap: wrap
    &__item
        height: 100px
        width: calc(20% - 10px)
        margin: 5px
        display: flex
        justify-content: center
        align-items: center
        flex-direction: column
        position: relative
        p
            margin: 0
            max-width: 100%
    &__remove
        position: absolute
        top: 0
        right: 20%
        z-index: 2

.file__size
    font-size: 12px
    color: #ababab
.file__name
    font-size: 14px
.counter
    display: flex
    flex-direction: column
    margin-top: 20px
    margin-bottom: 70px
    p
        margin-bottom: 0
        font-size: 12px
        color: #ababab
</style>
