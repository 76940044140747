<template>
    <v-slide-x-transition mode="out-in">
        <router-view></router-view>
    </v-slide-x-transition>
</template>

<script>
export default {
    name: 'App',

    data: () => ({
        //
    }),
}
</script>
<style lang="sass">
@import './assets/sass/main.sass'
</style>
