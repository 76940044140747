<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () =>
                                        getTemplateAttachments.errors.stepOne
                                            .validation,
                                ]"
                                editable
                                step="1"
                                >{{
                                    $t('attachments:addFromTemplate')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <Separator
                                            :text="
                                                $t(
                                                    'attachments:addFromTemplate'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-select
                                            data-cy="chooseFolder"
                                            :items="
                                                getTemplateAttachments.items
                                            "
                                            :label="`${$t(
                                                'attachments:chooseFolder'
                                            )}*`"
                                            item-value="_id"
                                            return-object
                                            @change="setFiles"
                                        >
                                            <template
                                                v-slot:selection="selection"
                                            >
                                                <span>{{
                                                    selection.item.name
                                                }}</span>
                                            </template>
                                            <template v-slot:item="item">
                                                <DoubleLine
                                                    :firtstLine="item.item.name"
                                                    :secondLine="
                                                        item.item.description
                                                    "
                                                /> </template
                                        ></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-select
                                            data-cy="chooseFiles"
                                            :items="files"
                                            :label="`${$t(
                                                'attachments:chooseFiles'
                                            )}*`"
                                            item-value="_id"
                                            return-object
                                            multiple
                                            v-model="
                                                getTemplateAttachments.files
                                            "
                                        >
                                            <template
                                                v-slot:selection="selection"
                                            >
                                                <span>{{
                                                    selection.item.name
                                                }}</span>
                                            </template>
                                            <template v-slot:item="item">
                                                <div class="doubleLine">
                                                    <span class="firstLine">{{
                                                        item.item.name
                                                    }}</span>
                                                    <span class="secondLine">{{
                                                        item.item.description
                                                    }}</span>
                                                </div></template
                                            ></v-select
                                        >
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    data() {
        return {
            date: '',
            step: 1,
            editable: true,
            files: [],
        }
    },

    computed: {
        ...mapGetters([
            'getStorageErrors',
            'getStorageModal',
            'getStorageTable',
            'getTemplateAttachments',
        ]),
    },

    methods: {
        setFiles(e) {
            this.files = e.files.filter(e => !e.isDeleted)
        },
    },
}
</script>

<style lang="sass" scoped>
.firstLine
    font-size: 14px !important

.secondLine
    color: gray
</style>
