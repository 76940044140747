<template>
    <v-container>
        <Header
            :registryStatus="createStatusesList()"
            :recordIndex="getLastValueRentInfo.index"
            :registryTitle="getRentInfoRegistry.name"
            :registryUrl="'rent-info'"
            :actualStatus="getLastValueRentInfo.annex.status"
            :avatarData="getLastValueRentInfo.createdBy"
            @status-changed="changeStatus"
        ></Header>
        <Main
            :items="items"
            class="p-relative"
            :activeTab="this.$route.query.tabs"
        >
            <v-tab-item
                v-for="item in items"
                :key="item.id"
                slot="tabItems"
                :id="item.id"
            >
                <component :is="item.component"></component>
            </v-tab-item>
            <div slot="information">
                <Information />
                <Modal
                    :title="$t('rent-contracts:rent-info.edition')"
                    :open="open"
                    :height="'650'"
                    v-on:close="closeModal()"
                >
                    <AEContent slot="AEContent" action="edit" />
                    <Buttons
                        @closeModal="open = false"
                        :action="'edit'"
                        slot="buttons"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--details">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                dark
                                v-on="on"
                                v-if="edit()"
                                small
                                @click="editRentInfo()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('rent-contracts:rent-info.edit') }}</span>
                    </v-tooltip>
                </div>
            </div>
        </Main>
        <ConfirmModal
            :width="'700'"
            :openConfirm="openConfirm"
            @close-confirm="closeConfirmModal"
        >
            <div slot="main">
                <IfSelectedStatusDraftConfirm />
            </div>
            <div slot="buttons">
                <div class="d-flex justify-center">
                    <v-btn
                        color="rgb(117, 117, 117)"
                        width="310"
                        height="40"
                        @click="closeConfirmModal()"
                        class="white--text mx-2"
                    >
                        Zamknij
                    </v-btn>
                </div>
            </div>
        </ConfirmModal>
        <ConfirmModal
            :width="'700'"
            :openConfirm="openConfirmAktywnyOp"
            @close-confirm="closeConfirmAktywnyOpModal"
        >
            <div slot="main">
                <IfSelectedStatusAktywnyOpConfirm />
            </div>
            <div slot="buttons">
                <div class="d-flex justify-center">
                    <v-btn
                        color="rgb(25, 170, 141)"
                        width="310"
                        height="40"
                        @click="openAddAlertModal()"
                        class="white--text mx-2"
                    >
                        Ustaw alert
                    </v-btn>
                </div>
            </div>
        </ConfirmModal>
        <Modal
            :title="$t('global:addition')"
            :open="openAlertModal"
            v-on:close="closeAddAlertModal"
        >
            <AEContentAlert slot="AEContent" />
            <ContentButtons
                :action="'create'"
                slot="buttons"
                @closeModal="closeAddAlertModal"
            />
        </Modal>
    </v-container>
</template>

<script>
import store from './../../../store/index'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import Information from '../../../components/Registries/Rent-contracts/Details/Information.vue'
import AEContent from './../../../components/Registries/Rent-contracts/Modal/Content'
import Buttons from './../../../components/Registries/Rent-contracts/Modal/Buttons'
import RentAnnex from '../../../components/Registries/Rent-contracts/Tabs/Rent-annex/Rent-annex.vue'
import RentSettlements from '../../../components/Registries/Rent-contracts/Tabs/Rent-settlements/Rent-settlements.vue'
import IfSelectedStatusDraftConfirm from './IfSelectedStatusDraftConfirm.vue'
import IfSelectedStatusAktywnyOpConfirm from './IfSelectedStatusAktywnyOpConfirm.vue'
import AEContentAlert from '../../../components/Global/Details/Tabs/Alerts/Modal/Content.vue'
import ContentButtons from '../../../components/Global/Details/Tabs/Alerts/Modal/Buttons.vue'
import { cloneDeep } from 'lodash'

export default {
    i18nOptions: { namespaces: ['global'] },
    components: {
        Information,
        AEContent,
        Buttons,
        IfSelectedStatusDraftConfirm,
        IfSelectedStatusAktywnyOpConfirm,
        AEContentAlert,
        ContentButtons,
    },
    data() {
        return {
            open: false,
            annexsAmount: 0,
            items: [
                {
                    name: this.$i18n.t(
                        'rent-contracts:rent-annex.managementHeader'
                    ),
                    id: 'rent-annex',
                    icon: 'mdi-pencil-circle-outline',
                    component: RentAnnex,
                },
                {
                    name: this.$i18n.t(
                        'rent-contracts:rent-settlements.statementsHeader'
                    ),
                    id: 'rent-settlements',
                    icon: 'mdi-cash-multiple',
                    component: RentSettlements,
                },
            ],
            openConfirm: false,
            openConfirmAktywnyOp: false,
            openAlertModal: false,
        }
    },
    computed: {
        ...mapGetters([
            'getRentInfoStatuses',
            'getRentInfoRegistry',
            'getLastValueRentInfo',
            'getRentAnnexTable',
            'getProfileDetails',
        ]),
    },
    methods: {
        ...mapMutations([
            'setRentInfoStatuses',
            'setRentInfoRegistry',
            'setRentInfoModal',
            'clearRentInfoErrors',
            'clearRepresentativesInput',
            'clearRentInfoModal',
            'setRentAnnexDetails',
        ]),
        ...mapActions([
            'fetchRentInfo',
            'updateRentInfoStatus',
            'fetchUsers',
            'fetchRentAnnexTable',
            'fetchLastRentInfo',
            'setAlertDescription',
        ]),

        createStatusesList() {
            return this.getRentInfoStatuses.filter(e => e.state !== 'DRAFT')
        },

        ifStatusChangedToDraft(status) {
            if (status.name === 'Draft') {
                this.openConfirmModal()
            }
        },
        ifStatusChangedToAktywnyOP(status) {
            if (status.name === 'Aktywny OP') {
                this.openConfirmAktywnyOpModal()
                this.setAlertDescription(
                    this.$i18n.t(
                        'rent-contracts:rent-annex.alertModalContentDescription'
                    )
                )
            }
        },

        closeConfirmModal() {
            this.openConfirm = false
        },

        closeConfirmAktywnyOpModal() {
            this.openConfirmAktywnyOp = false
        },

        openConfirmModal() {
            this.openConfirm = true
        },

        openConfirmAktywnyOpModal() {
            this.openConfirmAktywnyOp = true
        },

        openAddAlertModal() {
            this.openAlertModal = true
        },
        closeAddAlertModal() {
            this.openAlertModal = false
            this.closeConfirmAktywnyOpModal()
        },

        async changeStatus(status) {
            this.ifStatusChangedToDraft(status)
            if (status.state !== 'DRAFT') {
                
                this.ifStatusChangedToAktywnyOP(status)

                await this.updateRentInfoStatus({
                    status,
                    id: this.getLastValueRentInfo._id,
                })
                await this.fetchRentAnnexTable({ id: this.$route.params.id })
            }
        },
        edit() {
            return this.checkPermissions('UPDATE') && this.annexsAmount <= 1
        },
        async editRentInfo() {
            this.clearRentInfoModal()
            this.clearRentInfoErrors()

            await this.fetchLastRentInfo({ id: this.$route?.params?.id })
            await this.fetchUsers({
                select: 'name lastname mail status',
                where: {
                    isUser: true,
                    'status.state': 'ACTIVE',
                    isDeleted: false,
                },
            })

            this.open = true
            await this.setRentInfoModal(cloneDeep(this.getLastValueRentInfo))
        },
        closeModal() {
            this.open = false
            this.clearRentInfoErrors()
            this.clearRepresentativesInput()
            this.clearRentInfoModal()
        },
    },

    beforeMount() {
        this.annexsAmount = this.getLastValueRentInfo.rentAnnexs.filter(
            el => !el.isDeleted
        ).length
    },
    beforeRouteEnter(to, from, next) {
        // store.commit('clearRentInfoTable')
        store.commit('clearRentAnnexTable')
        store.commit('clearRentSettlementInstanceTable')

        store.dispatch('fetchRentInfoRegistry').then(() => {
            store
                .dispatch('fetchRentSettlementInstanceTable', {
                    id: to.params.id,
                })
                .then(() => {
                    store
                        .dispatch('fetchRentAnnexTable', { id: to.params.id })
                        .then(() => {
                            store.dispatch('fetchLastRentInfo', {
                                id: to.params.id,
                                next,
                            })
                        })
                })
        })
    },
}
</script>
